import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AdminUsersService } from '~/services/admin-users.service';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';
import { AdminUserActions, AdminUsersActions, CreateAdminUser, UpdateAdminUser } from './admin-users.actions';

@Injectable()
export class AdminUsersEffects {
  constructor(private actions$: Actions<PayloadAction>, private adminUsersService: AdminUsersService) {}

  
  loadAll$ = createEffect(() => generateAsyncEffect(this.actions$, AdminUsersActions, () => this.adminUsersService.getAdminUsers()));

  
  load$ = createEffect(() => generateAsyncEffect(this.actions$, AdminUserActions, action =>
    this.adminUsersService.getAdminUser(action.adminUserId)
  ));

  
  updateUser$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateAdminUser.Save),
    switchMap(action =>
      this.adminUsersService.updateAdminUser(action.payload.adminUser).pipe(
        map(() => UpdateAdminUser.Saved(action.payload)),
        catchError(error => of(UpdateAdminUser.Error(error)))
      )
    )
  ));

  
  createUser$ = createEffect(() => this.actions$.pipe(
    ofType(CreateAdminUser.Save),
    switchMap(action =>
      this.adminUsersService.createAdminUser(action.payload.adminUser).pipe(
        map(() => CreateAdminUser.Saved(action.payload)),
        catchError(error => of(CreateAdminUser.Error(error)))
      )
    )
  ));
}
