<form class="c-card c-card--w700 l-stack u-margin-h-none l-form u-margin-bottom-m" (submit)="submit()">
  <div class="c-card__header">
    <h2 class="c-card__title">Install a new extender</h2>
  </div>
  <div class="c-card__section">
    <div *ngIf="loading">
      <hl-spinner></hl-spinner>
    </div>
    <form class="l-form" *ngIf="!loading">
      <div class="l-form__field">
        <label class="c-label">Extender type</label>
        <pxc-dropdown
          name="selectedExtenderTypeOption"
          [(ngModel)]="state.selectedExtenderTypeOption"
          [options]="extenderTypeOptions"
        ></pxc-dropdown>
      </div>
      <div class="c-helper" *ngIf="!selectedSystem">
        Select an extender type before continuing
      </div>
      <p>Installer and documentation</p>
      <ul class="u-margin-bottom-none">
        <li>
          View the Proxyclick
          <a
            href="https://www.notion.so/Access-Control-Overview-f3868aa23194480397df0b183267972f"
            target="_blank"
            rel="noopener noreferrer"
            >access control integration documentation</a
          >
        </li>
        <li *ngIf="selectedSystem && selectedSystem.isClient">
          <a href="{{ selectedSystem.downloadUrl }}"
            >Download the
            <span class="text-capitalize">{{ selectedSystem.name }}</span>
            installer</a
          ><br />
        </li>
        <li *ngIf="selectedSystem">
          View the
          <a href="{{ selectedSystem.publicDocumentationUrl }}" target="_blank" rel="noopener noreferrer"
            ><span class="text-capitalize">{{ selectedSystem.name }}</span> documentation</a
          >
        </li>
      </ul>
    </form>
  </div>
  <div *ngIf="selectedSystem">
    <div class="c-card__section">
      <div class="l-form__field">
        <label class="c-label">Description</label>
        <input
          type="text"
          class="c-input c-input--s"
          minlength="5"
          maxlength="50"
          required="true"
          name="description"
          [(ngModel)]="state.description"
        />
      </div>
      <div class="l-form__field">
        <label class="c-label">Technical contacts</label>
        <input
          type="text"
          class="c-input c-input--s"
          required="true"
          name="technicalContacts"
          maxlength=""
          [(ngModel)]="state.technicalContacts"
        />
        <div class="c-helper">
          Please provide a list of email addresses (<code>;</code> separated)on the customer side that will be emailed
          when errors occur during the lifetime of our application.
        </div>
      </div>

      <div class="l-form__field">
        <div class="l-bar u-margin-bottom-xs">
          <div class="l-bar__item-start">
            <label class="c-label">Alert Slack on status changes</label>
          </div>
          <div class="l-bar__item-end">
            <pxc-switch [(ngModel)]="state.alertStatus" name="alertStatus"></pxc-switch>
          </div>
        </div>
        <div class="c-helper">
          Alert the slack #accesscontrol-events channel when this client goes offline or online. Highly recommended for
          all customer installations, only disable when the installation is a demo machine that is expected to go
          offline often.
        </div>
      </div>
    </div>
    <hr />
    <div>
      <app-new-extender-client *ngIf="selectedSystem.isClient" [state]="state"> </app-new-extender-client>
      <app-new-extender-brivo *ngIf="selectedSystem.id == 'brivo'" [state]="state"> </app-new-extender-brivo>
      <app-new-extender-openpath *ngIf="selectedSystem.id == 'openpath'" [state]="state"> </app-new-extender-openpath>
      <app-new-extender-genea *ngIf="selectedSystem.id == 'genea'" [state]="state"> </app-new-extender-genea>
    </div>
  </div>
  <div class="c-card__section" *ngIf="errors">
    <div class="c-banner c-banner--danger">
      <dl>
        <ng-container *ngFor="let item of errors.errors | keyvalue">
          <dt>{{ item.key }}</dt>
          <dt>{{ item.value }}</dt>
        </ng-container>
      </dl>
      Trace id <code>{{ errors.traceId }}</code>
    </div>
  </div>
  <div class="c-card__footer" *ngIf="selectedSystem">
    <button type="submit" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
      Setup extender&nbsp;<hl-spinner *ngIf="submitting"></hl-spinner>
    </button>
  </div>
</form>
