import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LdapEffects } from '~/store/user-provisioning/user-provisioning.effects';
import {
  jsonDirectoriesReducer,
  jsonDirectoryReducer,
  LdapDetailsReducer,
  LdapDirectoriesReducer,
} from '~/store/user-provisioning/user-provisioning.reducer';

export const LDAP_REDUCER_TOKEN = new InjectionToken('INTEGRATIONS_REDUCER_TOKEN');
export const LDAP_DETAILS_REDUCER_TOKEN = new InjectionToken('INTEGRATION_REDUCER_TOKEN');
export const JSON_REDUCER_TOKEN = new InjectionToken('JSON_REDUCER_TOKEN');
export const JSON_DETAILS_REDUCER_TOKEN = new InjectionToken('JSON_DETAILS_REDUCER_TOKEN');

@NgModule({
  imports: [
    StoreModule.forFeature('ldap', LDAP_REDUCER_TOKEN),
    StoreModule.forFeature('ldap-details', LDAP_DETAILS_REDUCER_TOKEN),
    StoreModule.forFeature('json', JSON_REDUCER_TOKEN),
    StoreModule.forFeature('json-details', JSON_DETAILS_REDUCER_TOKEN),
    EffectsModule.forFeature([LdapEffects]),
  ],
  providers: [
    { provide: LDAP_REDUCER_TOKEN, useValue: LdapDirectoriesReducer },
    { provide: LDAP_DETAILS_REDUCER_TOKEN, useValue: LdapDetailsReducer },
    { provide: JSON_REDUCER_TOKEN, useValue: jsonDirectoriesReducer },
    { provide: JSON_DETAILS_REDUCER_TOKEN, useValue: jsonDirectoryReducer },
  ],
})
export class LdapStoreModule {}
