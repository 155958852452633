import * as _ from 'lodash';
import { PayloadAction } from '~/utils/async-generator';

export const loadingRegex = /(.*)\/(start|load|save|loaded|done|error)$/;

export const loadingReducer = (state = {}, action: PayloadAction) => {
  let newState = _.cloneDeep(state);
  const command = getLoadingCommand(action);

  if (command) {
    if (command.loadingAction === 'start') {
      newState[command.loadingKey] = true;
    } else {
      delete newState[command.loadingKey];
    }
  }
  
  return newState;
};

export function getLoadingCommand(action: any): { loadingKey: string, loadingAction: 'start' | 'stop'} | null {
  if (!action || !action.type) {
    return null
  };

  if (action.meta?.loadingKey && action.meta?.loadingAction) {
    return action.meta;
  }

  const reg = loadingRegex.exec(action.type);
  
  if (reg) {
    switch (reg[2]) {
      case 'load':
      case 'save':
      case 'start':
        return { loadingKey: reg[1], loadingAction: 'start' };
      case 'error':
      case 'loaded':
      case 'done':
        return { loadingKey: reg[1], loadingAction: 'stop' };
    }
  }

  return null;
}