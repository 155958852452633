import { Component, Input } from '@angular/core';
import {
  CredentialFormatsCredentialFormatResponseV1,
  PartitionsPartitionResponseV1,
} from '@proxyclick/extender-server-genetec-client';
import { v4 as uuidv4 } from 'uuid';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';

@Component({
  selector: 'app-fastpace-company-setup-extenders-genetec',
  templateUrl: './genetec-setup.component.html',
})
export class GenetecSetupComponent {
  @Input() setupState: FastPaceSetupCompanyState;
  constructor(private extenderService: ExtenderService) {}
  async ngOnInit(): Promise<void> {
    this.partitions = await this.extenderService.genetec.partitions.listWithResponse(
      this.setupState.extender.extenderId,
      { requestTracingId: uuidv4() }
    );

    this.cardFormats = (
      await this.extenderService.genetec.credentialFormats.list(this.setupState.extender.extenderId, {
        requestTracingId: uuidv4(),
      })
    ).filter(x => x.isCustomFormat && x.unfixedFields.length > 0);

    this.setPartitionOptions();
  }

  partitions: PartitionsPartitionResponseV1[] = [];
  partitionOptions: PartitionsPartitionResponseV1[] = [];
  partitionOption: PartitionsPartitionResponseV1 | null = null;

  cardFormats: CredentialFormatsCredentialFormatResponseV1[] = [];

  setPartitionOptions() {
    this.partitionOptions = this.partitions;
    this.partitionOption = null;
  }

  partitionOptionChanged() {
    this.setupState.genetecPartition = this.partitionOption;
  }

  canGoNext() {
    return true;
  }
}
