import { OnInit, Input, Directive } from '@angular/core';
import { ErrorResponse } from '~/store/fastpace/fastpace.models';
import { FastPaceService } from '~/services/fastpace.service';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { ControllersCompaniesFastPaceCompanyResponseV1 } from '@proxyclick/fastpace-client';

@Directive()
export abstract class FastPaceBaseSystemSettingsComponent implements OnInit {
  @Input() fastpaceCompany: ControllersCompaniesFastPaceCompanyResponseV1;
  constructor(
    protected fastpaceService: FastPaceService,
    protected companyService: CompaniesService,
    protected extenderService: ExtenderService
  ) {}
  ngOnInit(): void {}
  submitting: boolean | null = null;
  errors: ErrorResponse;
  abstract get canSubmit(): boolean;
  abstract doUpdate(): Promise<void>;
  async submit() {
    this.submitting = true;
    this.errors = undefined;
    try {
      await this.doUpdate();
      this.submitting = false;
    } catch (error) {
      this.handleError(error);
    }
  }
  handleError(error) {
    this.submitting = false;
    if (error && error.statusCode < 500) {
      var errorResponse = error.details as ErrorResponse;
      this.errors = errorResponse;
    } else {
      this.errors = {
        title: error.message,
        errors: undefined,
        status: error.statusCode,
        traceId: error.headers?.get('traceId'),
        type: 'Failure',
      };
    }
  }
}
