import { ICompany } from '@proxyclick/data-model';
import { ICompanyFilter } from '~/models/filters';
import { createAsyncLoadActions, createAsyncLoadReducer, PayloadAction } from '~/utils/async-generator';
import { IDomainFilter } from '../../../../../shared/filters';
import { IAdminCompany, IDomain } from '../../../../../shared/models';
import { _ } from '../../libs';

const DomainCompaniesSortActionType = 'DomainCompaniesSortAction';

export const DomainActions = createAsyncLoadActions<IDomainFilter, IDomain[]>('domains');
export const domainsReducer = createAsyncLoadReducer(DomainActions);

export const DomainDetailsAction = createAsyncLoadActions<string, any>('domain');
export const DomainUpdateAction = createAsyncLoadActions<IDomain, any>('domain/update');

export const domainDetailsReducer = createAsyncLoadReducer(DomainDetailsAction);
export const domainUpdateReducer = createAsyncLoadReducer(DomainUpdateAction);

export const DomainCompaniesAction = createAsyncLoadActions<IDomain, ICompany & IAdminCompany[]>('domain-companies');
const domainCompaniesReducerBase = createAsyncLoadReducer(DomainCompaniesAction);

export const DomainUpdateClearbit = createAsyncLoadActions<IDomain, any>('domain/clearbit');
export const domainUpdateClearbitReducer = createAsyncLoadReducer(DomainUpdateClearbit);

export const DomainCompaniesSortAction = filter => new PayloadAction(filter, DomainCompaniesSortActionType);

function domainCompaniesSortReducer(state = null, action: PayloadAction<ICompanyFilter>) {
  switch (action.type) {
    case DomainCompaniesSortActionType:
      return {
        ...state,
        value: state.value && applyFilter(state.value, action.payload),
      };
    default:
      return state;
  }
}

export const domainCompaniesReducer = (state, action) => {
  return domainCompaniesSortReducer(domainCompaniesReducerBase(state, action), action);
};

function applyFilter(companies: (ICompany & IAdminCompany)[], filter: ICompanyFilter) {
  return filter.sort === 'asc'
    ? _.sortBy(companies, filter.sortBy)
    : _.reverse(_.sortBy(companies, filter.sortBy, 'name'));
}
