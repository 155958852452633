import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RightGuard } from '~/shared/guards/right.guard';
import { SharedModule } from '~/shared/shared.module';
import { UserRights } from '../../../../../shared/models';
import { DomainClearbitComponent } from './domain-details/domain-clearbit/domain-clearbit.component';
import { DomainCompaniesComponent } from './domain-details/domain-companies/domain-companies.component';
import { DomainDetailsPageComponent } from './domain-details/domain-details.page';
import { DomainInfoComponent } from './domain-details/domain-info/domain-info.component';
import { DomainsPageComponent } from './domains.page';

@NgModule({
  declarations: [
    DomainsPageComponent,
    DomainDetailsPageComponent,
    DomainClearbitComponent,
    DomainCompaniesComponent,
    DomainInfoComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'domains',
        canActivate: [RightGuard],
        data: {
          right: UserRights.TabDomains,
          title: 'Domains',
        },
        children: [
          {
            path: '',
            component: DomainsPageComponent,
            data: {
              title: '',
            },
          },
          {
            path: ':id',
            component: DomainDetailsPageComponent,
            data: {
              title: '{{domain.value.name}}',
            },
            children: [
              {
                path: 'info',
                component: DomainInfoComponent,
              },
              {
                path: 'clearbit',
                component: DomainClearbitComponent,
              },
              {
                path: 'companies',
                component: DomainCompaniesComponent,
              },
              {
                path: '',
                redirectTo: 'companies',
                pathMatch: 'full',
              },
            ],
          },
        ],
      },
    ]),
  ],
})
export class DomainsModule {}
