import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUser } from '@proxyclick/data-model';
import { Subscription, Observable } from 'rxjs';
import { AppStore } from '~/store/store';
import { BatchUpdateUserEmails, IEmailChange } from '~/store/users/users.actions';
import { UserService } from '~/services/users.service';

export interface IUserEmailMapping {
  originalEmail: string;
  newEmail: string;
}

@Component({
  selector: 'pxc-batch-email-update-page',
  templateUrl: './batch-email-update.html',
})
export class BatchEmailUpdatePageComponent implements OnInit, OnDestroy {
  users: IUserEmailMapping[] = [{ originalEmail: '', newEmail: '' }];
  usersToBeUpdated = 1;
  existingUsers: IUser[] = [];
  subs: Subscription = new Subscription();
  errors: {key: string, value: string}[] = [];
  isUpdateLoading$: Observable<boolean>;
  updateEmailsModal = {
    open: false,
  };

  constructor(private store: AppStore, private userService: UserService) {}

  ngOnInit() {
    this.subs = this.subs.add(this.store.select('utilities', 'batchUserEmailChange', 'userEmailUpdatedErrors').subscribe(results => 
      {
        if (!results) return;

        this.toggleUpdateEmailsModal();

        const remainingUsers = this.users.filter(x => !results.some(y => x.originalEmail == y.email));

        for (const user of remainingUsers) {
          this.removeRow(user.originalEmail);
        }

        for (const result of results) {
          this.errors.push({key: result.email, value: result.error})
        }

      }));
    this.isUpdateLoading$ = this.store.select('utilities', 'batchUserEmailChange', 'isLoading');
  }

  addEmptyRow() {
    this.users.push({ originalEmail: '', newEmail: '' });
    this.usersToBeUpdated = this.users.length;
  }

  removeRow(email: string) {
    if (!email) return;

    this.users = this.users.filter(x => x.originalEmail != email);

    if(this.users.length == 0)
      this.addEmptyRow();

    this.usersToBeUpdated = this.users.length;
  }

  onInput(user: IEmailChange, event: any) {
    if (event.inputType === 'insertFromPaste') {
      event.preventDefault();
      const content = event.target.value;
      event.target.value = '';
      this.pasteValues(content);
    }
  }
  
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  async updateEmails() {
    this.errors = [];
    const mappedUsers = this.users
      .filter(x => x.originalEmail)
      .map(user => { return { email: user.originalEmail, newEmail: user.newEmail }});

    if (mappedUsers.length > 0)
    {
      this.store.dispatchAsync(BatchUpdateUserEmails({ data: mappedUsers }));
    }
  }

  toggleUpdateEmailsModal() {
    this.updateEmailsModal = !this.updateEmailsModal.open
      ? {
          open: true,
        }
      : {
          open: false,
        };
  }

  private pasteValues(input: string) {
    const users = input.split(' '); // space splitting
    let i = 0;
    for (const user of users) {
      const values = user.split('	'); // tab splitting
      this.users[i] = { originalEmail: values[0], newEmail: values[1] };
      this.usersToBeUpdated = this.users.length;
      i++;
    }
  }
}
