import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { DomainService } from '~/services/domain.service';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';
import {
  DomainActions,
  DomainCompaniesAction,
  DomainDetailsAction,
  DomainUpdateAction,
  DomainUpdateClearbit,
} from './domains.reducer';

@Injectable()
export class DomainsEffects {
  constructor(private actions$: Actions<PayloadAction>, private domains: DomainService) {}

  
  loadAll$ = createEffect(() => generateAsyncEffect(this.actions$, DomainActions, filter => this.domains.getDomains(filter)));

  
  load$ = createEffect(() => generateAsyncEffect(this.actions$, DomainDetailsAction, id => this.domains.getDomainById(id)));

  
  loadCompanies$ = createEffect(() => generateAsyncEffect(this.actions$, DomainCompaniesAction, domain =>
    this.domains.getCompaniesForDomain(domain)
  ));

  
  update$ = createEffect(() => generateAsyncEffect(this.actions$, DomainUpdateAction, domain => this.domains.updateDomain(domain)));

  
  updateClearbit$ = createEffect(() => generateAsyncEffect(this.actions$, DomainUpdateClearbit, domain =>
    this.domains.updateClearbit(domain)
  ));

  
  updatedClearbit$ = createEffect(() => this.actions$.pipe(
    ofType(DomainUpdateClearbit.Loaded.type),
    map(action => DomainDetailsAction.Loaded(action.payload))
  ));

  
  triggerLoadCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(DomainDetailsAction.Loaded.type),
    map(action => DomainCompaniesAction.Load(action.payload))
  ));
}
