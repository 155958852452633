<pxc-breadcrumb [breadcrumbs]="breadcrumbs$ | async"></pxc-breadcrumb>
<div class="d-flex mb-2">
  <form ngNativeValidate (ngSubmit)="onSubmit()" class="form-small mt-4">
    <pxc-field label="Organisation Name">
      <input required type="text" data-cy="create-organisation-name-input" class="form-control" [(ngModel)]="organisation.name" name="organisationName" />
    </pxc-field>
    <pxc-field label="Admin user(s)">
      <textarea
        type="text"
        rows="5"
        required
        placeholder="US-B101&#10;US-C202&#10;US-D303"
        class="form-control"
        [(ngModel)]="organisation.users"
        name="organisationUsers"
        data-cy="create-organisation-admins-textarea"
      >
      </textarea>
    </pxc-field>
    <button data-cy="create-organisation-button" class="c-button c-button--primary c-button--s">Create</button>
  </form>
</div>
