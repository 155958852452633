import { Component, Input } from '@angular/core';
import { ConnectionsConnectionSettings, DevicesDeviceResponseV1 } from '@proxyclick/extender-server-ac2000-client';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { fetchAll } from '~/utils/utils';

@Component({
  selector: 'app-fastpace-company-setup-extenders-ac2000',
  templateUrl: './ac2000-setup.component.html',
})
export class AC2000SetupComponent {
  @Input() setupState: FastPaceSetupCompanyState;

  constructor(private extenderService: ExtenderService) {}
  async ngOnInit(): Promise<void> {
    this.connections = await this.extenderService.ac2000.connections.list(this.setupState.extender.extenderId, {
      requestTracingId: uuidv4(),
    });
  }

  connections: ConnectionsConnectionSettings[] = [];
  connectionOption: ConnectionsConnectionSettings | null = null;

  devices: DevicesDeviceResponseV1[] = [];
  deviceOptions: DevicesDeviceResponseV1[] = [];
  deviceOptionIn: DevicesDeviceResponseV1 | null = null;
  deviceOptionOut: DevicesDeviceResponseV1 | null = null;

  async connectionOptionChanged() {
    if (this.connectionOption && this.setupState.ac2000Connection != this.connectionOption) {
      this.setupState.ac2000Connection = this.connectionOption;
      this.devices = await fetchAll<DevicesDeviceResponseV1>(
        async page =>
          (
            await this.extenderService.ac2000.devices.list(this.setupState.extender.extenderId, {
              requestTracingId: uuidv4(),
              requestConnectionId: this.setupState.ac2000Connection.connectionId,
              requestPage: page,
              requestPageSize: 100,
            })
          ).items
      );
      this.setDeviceOptions();
    }
  }

  setDeviceOptions() {
    const options = this.devices.filter(c => {
      //Filter out custom fields already mapped
      return (
        this.setupState.ac2000DeviceFilterIn.filter(a => a.deviceAddress === c.deviceAddress).length === 0 &&
        this.setupState.ac2000DeviceFilterOut.filter(a => a.deviceAddress === c.deviceAddress).length === 0
      );
    });

    this.deviceOptions = options;
    if (this.deviceOptions.length > 0) {
      this.deviceOptionIn = this.deviceOptions[0];
      this.deviceOptionOut = this.deviceOptions[0];
    } else {
      this.deviceOptionIn = null;
      this.deviceOptionOut = null;
    }
  }

  canAddInMapping(): boolean {
    return (
      this.deviceOptionIn != null &&
      this.setupState.ac2000DeviceFilterIn.filter(d => d.deviceAddress === this.deviceOptionIn.deviceAddress).length < 1
    );
  }
  canAddOutMapping(): boolean {
    return (
      this.deviceOptionOut != null &&
      this.setupState.ac2000DeviceFilterOut.filter(d => d.deviceAddress === this.deviceOptionOut.deviceAddress).length <
        1
    );
  }
  addInMapping() {
    if (this.canAddInMapping()) {
      this.setupState.ac2000DeviceFilterIn.push(this.deviceOptionIn);
      this.setDeviceOptions();
    }
  }

  addOutMapping() {
    if (this.canAddOutMapping()) {
      this.setupState.ac2000DeviceFilterOut.push(this.deviceOptionOut);
      this.setDeviceOptions();
    }
  }

  removeInMapping(deviceAddress: string) {
    this.setupState.ac2000DeviceFilterIn = this.setupState.ac2000DeviceFilterIn.filter(
      d => d.deviceAddress !== deviceAddress
    );
    this.setDeviceOptions();
  }
  removeOutMapping(deviceAddress: string) {
    this.setupState.ac2000DeviceFilterOut = this.setupState.ac2000DeviceFilterOut.filter(
      d => d.deviceAddress !== deviceAddress
    );
    this.setDeviceOptions();
  }

  canGoNext() {
    if (this.setupState.ac2000Connection === undefined) {
      return false;
    }
    return true;
  }
}
