import { Component, Input, OnInit } from '@angular/core';
import { ExtenderService } from '~/services/extender.service';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-fastpace-company-setup-extenders-netbox',
  templateUrl: './netbox-setup.component.html',
})
export class NetboxSetupComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;
  constructor(private extenderService: ExtenderService) {}

  async ngOnInit(): Promise<void> {
    const response = await this.extenderService.netbox.cardFormats.listAll(this.setupState.extender.extenderId, {
      requestTracingId: uuidv4(),
    });
    this.cardFormats = response as unknown as string[];

    this.cardFormatOptions = this.cardFormats.map(x => {
      return { value: x, label: x };
    });
  }

  cardFormats: string[] = [];
  cardFormatOptions: IOption[] = [];
  cardFormatOption: IOption | null = null;

  canGoNext() {
    return (
      this.setupState.netboxVisitorIdField > 0 &&
      this.setupState.netboxVisitorIdField < 21 &&
      this.setupState.netboxVisitorStatusField > 0 &&
      this.setupState.netboxVisitorStatusField < 21 &&
      this.setupState.netboxVisitorIdField !== this.setupState.netboxVisitorStatusField
    );
  }

  switchManageAccessLevels() {
    if (this.setupState.netboxManageAccessLevels === false) {
      this.setupState.netboxUseMultiPartitionAccessLevelGroups = false;
    }
  }
}
