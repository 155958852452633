<div *ngIf="ccureSettings">
  <form (submit)="submit()">
    <div class="l-form__field">
      <label class="c-label">Partition</label>
      <input
        type="text"
        class="c-input c-input--s"
        [(ngModel)]="partition?.name || ccureSettings.partitionId"
        name="partition"
        disabled=""
      />
    </div>
    <hr />
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Card Format (QR Code)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="ccureQrCodeChuidFormat"
            [options]="ccureChuidFormats"
            key="objectId"
            label="name"
            name="ccureQrCodeChuidFormat"
            disabled=""
          ></pxc-obj-dropdown>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Card Format (Access Card Manager)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="ccurePhysicalChuidFormat"
            [options]="ccureChuidFormats"
            key="objectId"
            label="name"
            name="ccurePhysicalChuidFormat"
            allowNull="true"
          ></pxc-obj-dropdown>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Facility code</label>
        </div>
        <div class="l-bar__item-end">
          <input
            type="number"
            class="c-input c-input--s"
            required
            [(ngModel)]="ccureFacilityCode"
            name="ccureFacilityCode"
          />
        </div>
      </div>
      <div class="c-helper">Select the customer code set as default inside the ACS</div>
    </div>
    <div class="l-grid">
      <div class="l-grid__cell l-grid__cell--6">
        <div class="l-form__field">
          <label class="c-label">Use personnel group</label>
          <pxc-switch
            [(ngModel)]="usePersonnelGroup"
            name="usePersonnelGroup"
            (ngModelChange)="switchUsePersonnelGroup()"
          >
          </pxc-switch>
          <div class="c-helper">We can <strong>optionally</strong> add visitors into a personnel group.</div>
        </div>
      </div>
      <div class="l-grid__cell l-grid__cell--6">
        <div class="l-form__field" *ngIf="usePersonnelGroup">
          <div class="l-bar u-margin-bottom-xs">
            <div class="l-bar__item-start">
              <label class="c-label">Personnel Group</label>
            </div>
            <div class="l-bar__item-end">
              <pxc-obj-dropdown
                [(ngModel)]="ccurePersonnelGroupOption"
                [options]="ccurePersonnelGroupOptions"
                (ngModelChange)="ccurePersonnelGroupOptionChanged()"
                name="ccurePersonnelGroupOption"
                key="objectId"
                label="name"
                allowNull="true"
              ></pxc-obj-dropdown>
            </div>
          </div>
          <div class="c-helper">
            Please select the personnel group to be used. Not selecting any personne group will automatically create a
            "Proxyclick visitors" group and use it.
          </div>
        </div>
      </div>
    </div>

    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Map custom fields to CCure fields</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-switch
            [(ngModel)]="mapCustomFields"
            name="mapCustomFields"
            (ngModelChange)="switchEnableCustomFieldMapping()"
          ></pxc-switch>
        </div>
      </div>
    </div>
    <div class="l-grid" *ngIf="mapCustomFields">
      <div class="l-grid__cell l-grid__cell--6">
        <pxc-obj-dropdown
          [(ngModel)]="customFieldOption"
          [options]="customFieldOptions"
          name="customFieldOptions"
          key="id"
          label="name"
        ></pxc-obj-dropdown>
        <div class="c-helper">Select a Proxyclick custom field</div>
      </div>
      <div class="l-grid__cell l-grid__cell--5">
        <pxc-dropdown
          [(ngModel)]="ccureCustomFieldOption"
          [options]="ccureCustomFieldOptions"
          name="ccureCustomFieldOptions"
        ></pxc-dropdown>
        <div class="c-helper">Select the CCure field name you want to map it to.</div>
      </div>
      <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
        <hl-icon icon="add-24" (click)="addMapping()" [ngClass]="{ 'u-color-neutral-light': !canAddMapping() }">
        </hl-icon>
      </div>

      <div *ngIf="customFieldMappings.length > 0" class="l-stack c-box u-padding-all-none u-margin-bottom-s">
        <div class="l-stack__item">
          <div class="c-stacked-list c-stacked-list--s">
            <ul class="c-stacked-list__list">
              <li *ngFor="let customFieldMapping of customFieldMappings" class="c-stacked-list__item">
                <a
                  class="c-stacked-list__inner c-stacked-list__inner--interactive"
                  (click)="removeMapping(customFieldMapping.customField.id)"
                >
                  <div class="c-stacked-list__inner-start">
                    {{ customFieldMapping.customField.name }} => <code>{{ customFieldMapping.fieldName }}</code>
                  </div>
                  <div class="c-stacked-list__inner-end">
                    <span class="c-icon c-icon--m">
                      <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
      Save settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner
      ><hl-icon
        *ngIf="submitting === false && errors === undefined"
        icon="checkmark-circle--color-24"
        width="24"
        height="24"
      ></hl-icon>
    </button>
  </form>
  <h3 *ngIf="errors">Errors during update</h3>
  <div class="u-color-danger" *ngIf="errors">
    <h4>{{ errors.title }}</h4>
    <dl>
      <ng-container *ngFor="let item of errors.errors | keyvalue">
        <dt>{{ item.key }}</dt>
        <dt>
          <ul>
            <li *ngFor="let errorItem of item.value">
              {{ errorItem }}
            </li>
          </ul>
        </dt>
      </ng-container>
    </dl>
    Trace id <code>{{ errors.traceId }}</code>
  </div>
</div>
