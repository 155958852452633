import { Injectable, Injector } from '@angular/core';
import { IPartnerData } from '@proxyclick/data-model';
import { combineLatest, Observable } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import { BaseService } from '~/services/base.service';
import { ObservableCache } from '~/utils/observable-cache';

export interface INewPartnerData {
  name: string;
  alias: string;
  language: string;
  displayName: string;
}

@Injectable()
export class AppPartnersService extends BaseService {
  private cache = new ObservableCache();

  constructor(injector: Injector) {
    super(injector);
  }

  getPartners(): Observable<IPartnerData[]> {
    return this.cache.getObservable('partners', this.apiClient.AdminPartners().doGet());
  }

  getPartner(id): Observable<IPartnerData> {
    return this.cache.getObservable(
      `partner-${id}`,
      combineLatest(
        this.apiClient
          .AdminPartner(id)
          .doGet()
          .pipe(filter(a => !!a)),
        this.apiClient
          .PartnerCompanies(id)
          .doGet()
          .pipe(filter(a => !!a))
      ).pipe(
        map(([partner, linkedCompanies]) => ({
          ...partner,
          linkedCompanies: linkedCompanies,
        }))
      )
    );
  }

  updatePartner(partner: IPartnerData) {
    const data = {
      ...partner,
      language: partner.language.code,
    };
    return this.apiClient
      .AdminPartner(partner.id)
      .doPut(data)
      .pipe(finalize(() => this.cache.invalidate()));
  }

  createPartner(partner: INewPartnerData) {
    return this.apiClient
      .AdminPartners()
      .doPost(partner)
      .pipe(finalize(() => this.cache.invalidate()));
  }

  setPicture(partnerId: number, token: string) {
    return this.apiClient.AdminPartnerPicture(partnerId).doPost({ token: token });
  }

  deletePicture(partnerId: number) {
    return this.apiClient.AdminPartnerPicture(partnerId).doDelete();
  }
}
