import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IntegrationsEffects } from '~/store/integrations/integrations.effects';
import { integrationReducer, integrationsReducer } from '~/store/integrations/integrations.reducer';

export const INTEGRATIONS_REDUCER_TOKEN = new InjectionToken('INTEGRATIONS_REDUCER_TOKEN');
export const INTEGRATION_REDUCER_TOKEN = new InjectionToken('INTEGRATION_REDUCER_TOKEN');

@NgModule({
  imports: [
    StoreModule.forFeature('integrations', INTEGRATIONS_REDUCER_TOKEN),
    StoreModule.forFeature('integration', INTEGRATION_REDUCER_TOKEN),
    EffectsModule.forFeature([IntegrationsEffects]),
  ],
  providers: [
    { provide: INTEGRATIONS_REDUCER_TOKEN, useValue: integrationsReducer },
    { provide: INTEGRATION_REDUCER_TOKEN, useValue: integrationReducer },
  ],
})
export class IntegrationsStoreModule {}
