import { Component, Input, OnInit } from '@angular/core';
import { ExtenderDetailsState } from '../extender.details.state';

@Component({
  selector: 'app-extender-details-brivo',
  templateUrl: './extender.details.brivo.component.html',
})
export class ExtenderDetailsBrivoComponent {
  @Input() state: ExtenderDetailsState;
}
