import { Pipe, PipeTransform } from '@angular/core';
import { IFeatureList } from '@proxyclick/data-model';
import * as _ from 'lodash';

@Pipe({
  name: 'featureListV1',
})
export class FeatureListV1 implements PipeTransform {
  constructor() {}

  transform(featureLists: IFeatureList[]) {
    return featureLists && featureLists[0] && featureLists[0].reference && featureListToText(featureLists[0].reference);
  }
}

function featureListToText(featureList: string) {
  const regex = /vm_features_.*(free|small|medium|large|enterprise|custom|tenant).*(20[0-9][0-9])/;
  const r = regex.exec(featureList);
  if (r) {
    return `${_.capitalize(r[1])} ${r[2]}`;
  } else {
    return featureList.toUpperCase().charAt(0);
  }
}
