<div *pxcHasRight='"tab_users"' class="pxc-page">
  <div class="flex between mb-2">
    <form class="form-inline" (ngSubmit)="loadUsers()">
      <input
        placeholder="Filter users..."
        class="form-control mr-2"
        type="text"
        [(ngModel)]="searchTerm"
        name="searchTerm"
      />
      <pxc-button (click)="loadUsers()" [loading]="loading$">Filter</pxc-button>
    </form>
    <button class="btn btn-outline-primary" (click)="loadRecentUsers()">Recent logs</button>
  </div>
  <pxc-sortable-table [filter]="filter" (onFilterChange)="onFilterChange($event)" [columns]="columns">
    <tbody *ngIf="!(loading$ | async) && (users$ | async)">
      <tr *ngFor="let user of users$ | async">
        <td>{{user.id}}</td>
        <td>
          <img
            class="mr-1"
            *ngIf="user.originalCompany.countryCode"
            src="assets/img/flag/{{user.originalCompany.countryCode.toLowerCase()}}.png"
          />
        </td>

        <td><a routerLink="{{user.id}}">{{user.firstname}} {{user.lastname}}</a></td>
        <td *pxcHasRight="'log_as_user'">
          <div class="pointer"><i (click)="logAsUser(user)" class="fas fa-sign-in-alt"></i></div>
        </td>
        <td><a routerLink="/companies/{{user.originalCompany.id}}">{{user.originalCompany.name}}</a></td>
        <td>{{user.createdAt | time}}</td>
        <td>{{user.lastLoggedAt | time:'fromNow'}}</td>
      </tr>
    </tbody>
  </pxc-sortable-table>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
</div>
