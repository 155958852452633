import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPartner } from '@proxyclick/data-model';
import { Observable } from 'rxjs';
import { DialogService } from '~/shared/components/dialog/dialog.service';
import { IAppState } from '~/store/app-state';
import { PartnerActions, PartnersActions } from '~/store/partners/partners.reducer';

@Component({
  selector: 'pxc-partners',
  templateUrl: './partners.html',
  styleUrls: ['./partners.scss'],
})
export class PartnersPageComponent implements OnInit {
  partners$: Observable<IPartner[]> = this.store.select('partners', 'value');
  loading$ = this.store.select('partners', 'loading');

  constructor(private store: Store<IAppState>, private dialog: DialogService) {}

  ngOnInit() {
    this.store.dispatch(PartnersActions.Load());
  }

  onDelete(partner: IPartner) {
    this.dialog.confirm('Delete partner', `Are you sure you want to delete ${partner.name}?`).then(result => {
      if (result) {
        partner['isDeleting'] = true;
        this.store.dispatch(PartnerActions.DELETE(partner));
      }
    });
  }

  isActive(integration: IPartner) {
    return integration.active ? 'ON' : 'OFF';
  }

  getActiveClass(integration: IPartner) {
    return integration.active ? 'active' : 'inactive';
  }
}
