<pxc-loading [loading]="loading">
  <hr />
  <h3 class="c-heading c-heading--m">User Import Settings</h3>
  <div class="l-form__field">
    <pxc-field label="User Import">
      <pxc-switch [(ngModel)]="userImportEnabled"></pxc-switch>
      <small class="c-helper">
        Use the Access Control as a source for User Provisioning
      </small>
    </pxc-field>
  </div>
  <div class="l-form__field">
    <pxc-field label="Base Company ID">
      <input
        type="text"
        required
        class="form-control"
        name="userImportBaseCompanyId"
        [(ngModel)]="userImportBaseCompanyId"
      />
      <small class="c-helper">
        The ID of the base company
      </small>
    </pxc-field>
  </div>
  <div class="l-form__field">
    <pxc-field label="Notifications">
      <input type="text" required class="form-control" name="userImportEmail" [(ngModel)]="userImportEmail" />
      <small class="c-helper">
        Coma-separated list of email addresses we'll send the import report to
      </small>
    </pxc-field>
  </div>
  <div class="l-form__field">
    <pxc-field label="Is Dry Run">
      <pxc-switch [(ngModel)]="userImportIsDryRun"></pxc-switch>
      <small class="c-helper">
        Simulate an import & produce a report without actually importing data
      </small>
    </pxc-field>
  </div>

  <button (click)="saveSettings()" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
    Save settings&nbsp;<hl-spinner *ngIf="submittingSettings" size="medium"></hl-spinner>
  </button>
  <hr />
  <h3 class="c-heading c-heading--m">User Import Mappings</h3>
  <table class="c-table">
    <thead>
      <th>Company ID</th>
      <th>Field Name</th>
      <th>Comparator</th>
      <th>Field Value</th>
      <th></th>
    </thead>
    <tbody>
      <tr *ngFor="let mapping of mappings">
        <td>
          {{ mapping.companyId }}
        </td>
        <td>
          {{ getFieldName(mapping.fieldId) }}
        </td>
        <td>
          {{ mapping.fieldValueComparison }}
        </td>
        <td>
          {{ getValueName(mapping.fieldId, mapping.fieldValue) }}
        </td>
        <td (click)="removeMapping(mapping.extenderUserSyncFieldMapId)">
          <a class="c-button c-button--s c-button--danger">
            <span class="c-icon c-icon--s c-button__icon-single">
              <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
            </span>
          </a>
        </td>
      </tr>
      <tr>
        <td>
          <input type="text" required class="form-control" name="mappingCompanyId" [(ngModel)]="mappingCompanyId" />
        </td>
        <td>
          <pxc-obj-dropdown
            [(ngModel)]="mappingField"
            [options]="mappingFields"
            name="mappingFieldId"
            key="id"
            label="name"
          ></pxc-obj-dropdown>
        </td>
        <td>
          <pxc-obj-dropdown
            [(ngModel)]="mappingComparison"
            [options]="mappingComparisons"
            name="mappingFieldId"
            key="id"
            label="name"
          ></pxc-obj-dropdown>
        </td>
        <td>
          <pxc-obj-dropdown
            *ngIf="mappingField && mappingField.type === 'List'"
            [(ngModel)]="mappingFieldValueListItem"
            [options]="mappingField.values"
            name="mappingFieldValueListItem"
            key="id"
            label="name"
            (ngModelChange)="mappingFieldValue = mappingFieldValueListItem.id"
          ></pxc-obj-dropdown>
          <input
            *ngIf="mappingField && mappingField.type !== 'List'"
            type="text"
            required
            class="form-control"
            name="mappingFieldValue"
            [(ngModel)]="mappingFieldValue"
          />
        </td>
        <td (click)="addMapping()">
          <a class="c-button c-button--s c-button--primary">
            <span class="c-icon c-icon--s c-button__icon-single">
              <hl-icon
                icon="add-24"
                width="24"
                height="24"
                [ngClass]="{ 'u-color-neutral-light': !canAddMapping }"
              ></hl-icon>
            </span>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <hr />
  <div class="u-flex">
    <h3 class="c-heading c-heading--m">Last 15 imports</h3>
    <button (click)="triggerImport()" class="c-button c-button--primary c-button--s" style="margin-left: auto;">
      Trigger import&nbsp;<hl-icon icon="add-24" width="24" height="24"></hl-icon
      ><hl-spinner *ngIf="submittingImport" size="medium"></hl-spinner>
    </button>
  </div>

  <table class="c-table">
    <caption>
      *Refreshed every 15s
    </caption>
    <thead>
      <th>Start DateTime</th>
      <th>Status</th>
      <th>Completion DateTime</th>
      <th>Base CompanyId</th>
      <th>Is Dry Run</th>
      <th>Error</th>
      <th>Report</th>
    </thead>
    <tbody>
      <tr *ngFor="let latestImportJob of latestImportJobs">
        <td>
          {{ latestImportJob.startDateTime | date: 'short' }}
        </td>
        <td>
          {{ latestImportJob.status }}
        </td>
        <td>
          {{ latestImportJob.completionDateTime | date: 'short' }}
        </td>
        <td>
          {{ latestImportJob.baseCompanyId }}
        </td>
        <td>
          {{ latestImportJob.isDryRun }}
        </td>
        <td>
          {{ latestImportJob.exceptionMessage }}
        </td>
        <td>
          <button
            (click)="downloadReport(latestImportJob.jobKey)"
            class="c-button c-button--primary c-button--s"
            *ngIf="latestImportJob.isReportAvailable"
          >
            &nbsp;<hl-icon icon="arrow-down-24" width="24" height="24"></hl-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</pxc-loading>
