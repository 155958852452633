import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap, withLatestFrom } from 'rxjs/operators';
import { IAppState } from './app-state';
import { ErrorSelectors } from './error/error.selector';
import { LoadingSelectors } from './loading/loading.selector';

@Injectable()
export class AppStore {
  constructor(private store: Store<IAppState>) {}

  dispatch(action) {
    return this.store.dispatch(action);
  }

  select<T = any>(...selector) {
    // @ts-ignore
    return this.store.select<T>(...selector);
  }

  dispatchAsync(action: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(action);
      return this.store
        .select(LoadingSelectors.getByAction(action))
        .pipe(
          withLatestFrom(this.select(ErrorSelectors.getByAction(action))),
          filter(([isLoading, error]) => !isLoading),
          take(1)
        )
        .subscribe(([isLoading, error]) => {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        });
    });
  }
}
