<pxc-loading [loading]="loading.platformTenant$ | async">
  <ng-container *ngrxLet="platformTenant$; let platformTenant">
    <!--ALREADY SETUP DISPLAY DATA-->
    <div *ngIf="platformTenant">
      <form class="form-small mt-4">
        <pxc-field label="Tenant ID">
          <input
            disabled
            class="form-control"
            type="text"
            [(ngModel)]="platformTenant.tenantId"
            name="platformTenantId"
          />
        </pxc-field>
        <pxc-field
          label="Building Mapping {{i+1}}"
          *ngFor="let locationMapping of platformTenant.locationCompanies; let i = index"
        >
          <div class="form-inline">
            <input
              disabled
              type="text"
              class="form-control"
              [(ngModel)]="locationMapping.locationId"
              name="platformTenantLocationId-{{i+1}}"
            />
            &nbsp;
            <input
              disabled
              type="text"
              class="form-control"
              [(ngModel)]="locationMapping.company.id"
              name="platformTenantCompanyId-{{i+1}}"
            />
          </div>
        </pxc-field>
        <pxc-field label="User Synchronization">
          <span ngClass="c-badge c-badge--{{statusMapping[platformTenant.status].color}}"
            >{{platformTenant.status}}&nbsp;<hl-icon
              class="c-button__icon"
              id="platformTenant-usersync-badge-info"
              icon="info-16"
            ></hl-icon
          ></span>
          <hl-tooltip element="#platformTenant-usersync-badge-info">
            {{statusMapping[platformTenant.status].info}}</hl-tooltip
          >
          &nbsp;
          <button
            *ngIf="platformTenant.status === 'NOT_STARTED' || platformTenant.status === 'ERROR'"
            class="c-button c-button--s c-button--link"
            (click)="startSync()"
          >
            Start&nbsp;
            <hl-icon class="c-button__icon" icon="user-16"></hl-icon>
          </button>
          <button class="c-button c-button--s c-button--link" (click)="refresh()">
            Refresh&nbsp;
            <hl-icon class="c-button__icon" icon="recurring-16"></hl-icon>
          </button>
          <br />
        </pxc-field>
      </form>
    </div>

    <!--SETUP-->
    <div *ngIf="!platformTenant">
      <div *ngIf="!configure">
        Platform is not configured for this Organisation
        <br />
        <pxc-button type="primary" (click)="configure=!configure">Configure</pxc-button>
      </div>
      <div *ngIf="configure">
        <form #form="ngForm" ngNativeValidate class="form-small mt-4">
          <pxc-field label="Tenant ID">
            <input
              required
              type="text"
              data-cy="add-platformTenant-tenant-id-input"
              class="form-control"
              placeholder="Tenant ID"
              [(ngModel)]="platformTenantAdd.tenantId"
              name="platformTenantAddTenantId"
            />
          </pxc-field>
          <pxc-field
            label="Building Mapping {{i+1}}"
            *ngFor="let locationMapping of platformTenantAdd.locationMappings; let i = index"
          >
            <div class="form-inline">
              <input
                required
                type="text"
                data-cy="add-platformTenant-location-id-{{i+1}}-input"
                class="form-control"
                placeholder="Location ID"
                [(ngModel)]="locationMapping.locationId"
                name="platformTenantAddLocationId-{{i+1}}"
              />
              &nbsp;
              <input
                required
                type="text"
                data-cy="add-platformTenant-company-id-{{i+1}}-input"
                class="form-control"
                placeholder="Company ID"
                [(ngModel)]="locationMapping.companyId"
                name="platformTenantAddCompanyId-{{i+1}}"
              />
              &nbsp;&nbsp;
              <button class="c-button c-button--s c-button--link" (click)="addEmptyRow()">
                <hl-icon class="c-button__icon" icon="add-circle-24"></hl-icon>
              </button>
              <button
                class="c-button c-button--s c-button--link"
                (click)="removeRow(i)"
                *ngIf="platformTenantAdd.locationMappings.length > 1"
              >
                <hl-icon class="c-button__icon" icon="remove-circle-24"></hl-icon>
              </button>
            </div>
          </pxc-field>
          <button
            class="c-button c-button--s c-button--primary"
            [disabled]="!form.valid"
            (click)="toggleAddPlatformTenantModal()"
          >
            Configure</button
          >&nbsp;
          <button class="c-button c-button--s c-button--danger" (click)="configure = !configure">Cancel</button>
        </form>
      </div>
    </div>

    <div>
      <hl-modal
        *ngIf="addPlatformTenantModal.open"
        class="hl-modal"
        [isOpen]="true"
        [heading]="'Configure platform'"
        (close)="toggleAddPlatformTenantModal()"
      >
        <div>
          <div class="pxc-settings__subheading u-margin-bottom-gutter">
            This will link tenant <code>{{platformTenantAdd.tenantId}}</code> with the selected organisation and link
            the following buildings to these companies:

            <ul>
              <li *ngFor="let mappedBuilding of platformTenantAdd.locationMappings">
                <code>{{mappedBuilding.locationId}}</code> --> <code>{{mappedBuilding.companyId}}</code>
              </li>
            </ul>
            <div *ngIf="error.platformTenantAdd$ | async as addPlatformTenantError">
              <div class="c-helper c-helper--invalid">
                <div *ngIf="addlatformTenantError.status === 400">{{addlatformTenantError.error.message}}</div>
                <div *ngIf="addlatformTenantError.status !== 400">
                  An error occured while trying to add the integration
                </div>
              </div>
            </div>
          </div>
          <footer class="hl-modal__footer u-margin-top-s">
            <div class="c-buttons-group" style="margin-top: -10px">
              <pxc-button
                size="small"
                type="primary"
                (click)="addPlatformTenant()"
                [loading]="loading.platformTenantAdd$"
                >Add</pxc-button
              >
              <pxc-button size="small" type="secondary" (click)="toggleAddPlatformTenantModal()">Close</pxc-button>
            </div>
          </footer>
        </div>
      </hl-modal>
    </div>
  </ng-container>
</pxc-loading>
