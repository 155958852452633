import { Injectable, Injector } from '@angular/core';
import * as PubNub from 'pubnub';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { CompaniesService } from './companies.service';

export interface IPubNubMessage<T> {
  channel: string;
  actualChannel: string;
  subscribedChannel: string;
  timetoken: string;
  message: T;
}

export interface ICheckinMessage {
  type: 'VisitCheckedInEvent';
  companyId: string;
  visitId: string;
}

export interface IDeliveryMessage {
  type: 'DeliveryCreatedEvent';
  companyId: string;
  deliveryId: string;
}

@Injectable()
export class PubNubService extends BaseService {
  private pubNub;

  constructor(injector: Injector) {
    super(injector);
    this.pubNub = new PubNub({
      ssl: true, // enable TLS Tunneling over TCP
      subscribeKey: this.config.getConfig().pubnub.subscribeKey,
      heartbeatInterval: 0,
    } as any);

    injector.get(CompaniesService);
  }

  getMapChannelObservable$() {
    return this.getChannelObservable$(this.config.getConfig().pubnub.channel);
  }

  unsubscribeMapCHannelObservable() {
    return this.unsubscribe([this.config.getConfig().pubnub.channel]);
  }

  private unsubscribe(channels: string[]) {
    this.pubNub.unsubscribe({
      channels: channels,
    });
  }

  private getChannelObservable$(channel: string) {
    const obs: Observable<IPubNubMessage<any>> = Observable.create(observer => {
      this.pubNub.addListener({
        message: function (data) {
          observer.next(data);
        },
      });

      // Subscribe to the channel
      this.pubNub.subscribe({
        channels: [channel],
      });
    });
    return obs;
  }
}
