import { Component, OnInit } from '@angular/core';
import { ICustomField } from '@proxyclick/data-model';
import { AccessPointEntity, CredentialFormatEntity, CustomFieldEntity } from '@proxyclick/extender-server-brivo-client';
import { ControllersCompaniesBrivoCompanySettingsResponseV1 } from '@proxyclick/fastpace-client';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceService } from '~/services/fastpace.service';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';

@Component({
  selector: 'app-fastpace-company-brivo',
  templateUrl: './fastpace.company.brivo.component.html',
})
export class FastPaceCompanyBrivoComponent extends FastPaceBaseSystemSettingsComponent implements OnInit {
  constructor(fastpaceService: FastPaceService, companyService: CompaniesService, extenderService: ExtenderService) {
    super(fastpaceService, companyService, extenderService);
  }
  async ngOnInit(): Promise<void> {
    this.brivoSettings = await this.fastpaceService.getBrivoSettings(this.fastpaceCompany.companyId);
    this.customFields = await this.companyService.getCustomFields(this.fastpaceCompany.companyId).toPromise();
    this.cardFormats = (
      await this.extenderService.brivo.credentials.listAllFormats(this.fastpaceCompany.extenderId)
    ).filter(c => {
      return c.credentialFields.filter(cf => cf.name === 'card_number').length > 0;
    });
    this.brivoCustomFields = await this.extenderService.brivo.customFields.listAll(this.fastpaceCompany.extenderId);
    this.accessPoints = await this.extenderService.brivo.accessPoints.listAll(this.fastpaceCompany.extenderId);
    this.setInitialState();
  }

  //current settings
  brivoSettings: ControllersCompaniesBrivoCompanySettingsResponseV1 | null = null;
  customFieldMappings: Array<{ customField: ICustomField; brivoCustomField: CustomFieldEntity }> = [];

  cardFormats: CredentialFormatEntity[] = [];
  qrCodeCardFormat: CredentialFormatEntity;
  physicalCardFormat: CredentialFormatEntity;
  facilityCode: number | null = null;

  mapCustomFields: boolean;

  brivoCustomFields: CustomFieldEntity[];
  brivoCustomFieldOptions: CustomFieldEntity[] = [];
  brivoCustomFieldOption: CustomFieldEntity | null = null;

  customFields: ICustomField[];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  accessPoints: AccessPointEntity[];
  accessPointOptions: AccessPointEntity[] = [];
  accessPointOptionIn: AccessPointEntity | null = null;
  accessPointOptionOut: AccessPointEntity | null = null;

  accessPointFilterIn: AccessPointEntity[] = [];
  accessPointFilterOut: AccessPointEntity[] = [];

  setInitialState() {
    if (this.brivoSettings != null && this.cardFormats != null && this.cardFormats.length > 0) {
      this.qrCodeCardFormat = this.cardFormats.find(x => x.id == this.brivoSettings.qrCodeCredentialFormatId);
      this.physicalCardFormat = this.cardFormats.find(x => x.id == this.brivoSettings.cardCredentialFormatId) ?? null;
    }
    if (this.brivoSettings != null) {
      this.facilityCode = this.brivoSettings.facilityCode;
    }
    if (
      this.brivoSettings != null &&
      this.customFields != null &&
      this.customFields.length > 0 &&
      this.brivoCustomFields != null &&
      this.brivoCustomFields.length > 0
    ) {
      this.mapCustomFields = this.brivoSettings.customFieldMappings.length > 0;
      this.customFieldMappings = this.brivoSettings.customFieldMappings.map(c => {
        const customField = this.customFields.filter(cf => cf.id === c.customFieldId)[0];
        const brivoCustomField = this.brivoCustomFields.filter(cf => cf.id === c.brivoCustomFieldId)[0];
        return { customField: customField, brivoCustomField: brivoCustomField };
      });

      this.setBrivoCustomFieldOptions();
      this.setCustomFieldOptions();
    }

    if (this.brivoSettings != null && this.accessPoints != null && this.accessPoints.length > 0) {
      this.accessPointFilterIn = this.accessPoints.filter(
        x => this.brivoSettings.accessPointFilterIn.filter(y => y == x.id).length === 1
      );
      this.accessPointFilterOut = this.accessPoints.filter(
        x => this.brivoSettings.accessPointFilterOut.filter(y => y == x.id).length === 1
      );

      this.setAccessPointOptions();
    }
  }

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.customFieldMappings = [];
    }
  }

  setAccessPointOptions() {
    const options = this.accessPoints.filter(c => {
      //Filter out access points assigned
      return (
        this.accessPointFilterIn.filter(a => a.id === c.id).length === 0 &&
        this.accessPointFilterOut.filter(a => a.id == c.id).length === 0
      );
    });

    this.accessPointOptions = options;
    if (this.accessPointOptions.length > 0) {
      this.accessPointOptionIn = this.accessPointOptions[0];
      this.accessPointOptionOut = this.accessPointOptions[0];
    } else {
      this.accessPointOptionIn = null;
      this.accessPointOptionOut = null;
    }
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return this.customFieldMappings.filter(a => a.customField.id === c.id).length === 0;
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = null;
    }
  }

  setBrivoCustomFieldOptions() {
    const options = this.brivoCustomFields.filter(c => {
      //Filter out custom fields already mapped
      return this.customFieldMappings.filter(a => a.brivoCustomField.id === c.id).length === 0;
    });

    this.brivoCustomFieldOptions = options;
    if (this.brivoCustomFieldOptions.length > 0) {
      this.brivoCustomFieldOption = this.brivoCustomFieldOptions[0];
    } else {
      this.brivoCustomFieldOption = null;
    }
  }

  canAddMapping() {
    return (
      this.customFieldOption &&
      this.brivoCustomFieldOption &&
      this.customFieldMappings.filter(
        c => c.customField.id === this.customFieldOption.id || c.brivoCustomField.id == this.brivoCustomFieldOption.id
      ).length === 0
    );
  }
  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }
    this.customFieldMappings.push({
      customField: this.customFieldOption,
      brivoCustomField: this.brivoCustomFieldOption,
    });
    this.setBrivoCustomFieldOptions();
    this.setCustomFieldOptions();
  }

  removeMapping(id: number) {
    this.customFieldMappings = this.customFieldMappings.filter(c => c.customField.id !== id);
    this.setBrivoCustomFieldOptions();
    this.setCustomFieldOptions();
  }

  canAddInMapping() {
    return (
      this.accessPointOptionIn && this.accessPointFilterIn.filter(d => d.id === this.accessPointOptionIn.id).length < 1
    );
  }
  canAddOutMapping() {
    return (
      this.accessPointOptionOut &&
      this.accessPointFilterOut.filter(d => d.id === this.accessPointOptionOut.id).length < 1
    );
  }
  addInMapping() {
    this.accessPointFilterIn.push(this.accessPointOptionIn);
    this.setAccessPointOptions();
  }

  addOutMapping() {
    this.accessPointFilterOut.push(this.accessPointOptionOut);
    this.setAccessPointOptions();
  }

  removeInMapping(accessPointId: number) {
    this.accessPointFilterIn = this.accessPointFilterIn.filter(d => d.id !== accessPointId);
    this.setAccessPointOptions();
  }
  removeOutMapping(accessPointId: number) {
    this.accessPointFilterOut = this.accessPointFilterOut.filter(d => d.id !== accessPointId);
    this.setAccessPointOptions();
  }

  get canSubmit(): boolean {
    return this.submitting !== true;
  }

  async doUpdate(): Promise<void> {
    //No update at the moment
    return this.fastpaceService.updateBrivoSettings(this.fastpaceCompany.companyId, {
      qrCodeCredentialFormatId: this.qrCodeCardFormat.id,
      cardCredentialFormatId: this.physicalCardFormat?.id,
      facilityCode: this.facilityCode,
      customFieldMappings: this.customFieldMappings.map(c => {
        return { customFieldId: c.customField.id, brivoCustomFieldId: c.brivoCustomField.id };
      }),
      accessPointFilterIn: this.accessPointFilterIn.map(c => c.id),
      accessPointFilterOut: this.accessPointFilterOut.map(c => c.id),
    });
  }
}
