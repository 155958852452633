import { Component, Input } from '@angular/core';

@Component({
  selector: 'pxc-field',
  templateUrl: './field.html',
  styleUrls: ['./field.scss'],
})
export class FieldComponent {
  @Input()
  label: string;
}
