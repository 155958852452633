import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PartnersEffects } from '~/store/partners/partners.effects';
import { partnerReducer, partnersReducer } from '~/store/partners/partners.reducer';

export const PARTNERS_REDUCER_TOKEN = new InjectionToken('PARTNERS_REDUCER_TOKEN');
export const PARTNER_REDUCER_TOKEN = new InjectionToken('PARTNER_REDUCER_TOKEN');

@NgModule({
  imports: [
    StoreModule.forFeature('partners', PARTNERS_REDUCER_TOKEN),
    StoreModule.forFeature('partner', PARTNER_REDUCER_TOKEN),
    EffectsModule.forFeature([PartnersEffects]),
  ],
  providers: [
    { provide: PARTNERS_REDUCER_TOKEN, useValue: partnersReducer },
    { provide: PARTNER_REDUCER_TOKEN, useValue: partnerReducer },
  ],
})
export class PartnersStoreModule {}
