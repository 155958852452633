<div *ngIf="geneaSettings">
  <form (submit)="submit()">
    <div class="l-form__field">
      <label class="c-label">Location</label>
      <input
        type="text"
        class="c-input c-input--s"
        [ngModel]="location?.name || geneaSettings.locationUuid"
        name="location"
        disabled=""
      />
    </div>
    <div class="l-form__field" *ngIf="geneaSettings.tenantUuid">
      <label class="c-label">Tenant</label>
      <input
        type="text"
        class="c-input c-input--s"
        [(ngModel)]="tenant?.name || geneaSettings.tenantUuid"
        name="connectionId"
        disabled=""
      />
    </div>
    <hr />

    <div class="l-form__field">
      <label class="c-label">Door IN filter</label>
      <div class="l-grid">
        <div class="l-grid__cell l-grid__cell--11">
          <pxc-obj-dropdown
            [(ngModel)]="doorOptionIn"
            [options]="doorOptions"
            name="doorOptionIn"
            key="uuid"
            label="name"
          ></pxc-obj-dropdown>
        </div>
        <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
          <hl-icon
            icon="add-24"
            (click)="addInMapping()"
            [ngClass]="{ 'u-color-neutral-light': !canAddInMapping() }"
          ></hl-icon>
        </div>
      </div>
      <div class="c-helper">
        To allow visitor events from Genea to be sent to our servers please select at least one door for IN movements.
      </div>
    </div>
    <div class="l-form__field">
      <label class="c-label">Door OUT filter</label>
      <div class="l-grid">
        <div class="l-grid__cell l-grid__cell--11">
          <pxc-obj-dropdown
            [(ngModel)]="doorOptionOut"
            [options]="doorOptions"
            name="doorOptionOut"
            key="uuid"
            label="name"
          ></pxc-obj-dropdown>
        </div>
        <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
          <hl-icon
            icon="add-24"
            (click)="addOutMapping()"
            [ngClass]="{ 'u-color-neutral-light': !canAddOutMapping() }"
          ></hl-icon>
        </div>
      </div>
      <div class="c-helper">
        To allow visitor events from Genea to be sent to our servers please select at least one door for OUT movements.
      </div>
    </div>

    <div
      *ngIf="doorFilterIns.length > 0 || doorFilterOuts.length > 0"
      class="l-stack c-box u-padding-all-none u-margin-bottom-s"
    >
      <div class="l-stack__item">
        <div class="c-stacked-list c-stacked-list--s">
          <ul class="c-stacked-list__list">
            <li *ngFor="let door of doorFilterIns" class="c-stacked-list__item">
              <a class="c-stacked-list__inner c-stacked-list__inner--interactive" (click)="removeInMapping(door.uuid)">
                <div class="c-stacked-list__inner-start">IN: {{ door.name }}</div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
            <li *ngFor="let door of doorFilterOuts" class="c-stacked-list__item">
              <a class="c-stacked-list__inner c-stacked-list__inner--interactive" (click)="removeOutMapping(door.uuid)">
                <div class="c-stacked-list__inner-start">OUT: {{ door.name }}</div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <button type="submit" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
      Save settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
      <hl-icon
        *ngIf="submitting === false && errors === undefined"
        icon="checkmark-circle--color-24"
        width="24"
        height="24"
      ></hl-icon>
    </button>
  </form>
  <h3 *ngIf="errors">Errors during update</h3>
  <div class="u-color-danger" *ngIf="errors">
    <h4>{{ errors.title }}</h4>
    <dl>
      <ng-container *ngFor="let item of errors.errors | keyvalue">
        <dt>{{ item.key }}</dt>
        <dt>
          <ul>
            <li *ngFor="let errorItem of item.value">
              {{ errorItem }}
            </li>
          </ul>
        </dt>
      </ng-container>
    </dl>
    Trace id <code>{{ errors.traceId }}</code>
  </div>
</div>
