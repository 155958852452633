<div class="c-card__section">
  <h3>OpenPath settings</h3>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Organization</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-obj-dropdown
          [(ngModel)]="organizationOption"
          [options]="organizationOptions"
          (ngModelChange)="organizationOptionChanged()"
          key="id"
          label="name"
        ></pxc-obj-dropdown>
      </div>
    </div>
  </div>
  <div *ngIf="organizationOption">
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Card Format (QR Code)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="setupState.openPathQrCodeCardFormat"
            [options]="cardFormats"
            key="id"
            label="name"
          ></pxc-obj-dropdown>
        </div>
      </div>
      <div class="c-helper">
        Card Format used when adding cards. Format must support at least 8 digits (32 data bits)
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Card Format (Access Card Manager)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="setupState.openPathCardCardFormat"
            [options]="cardFormats"
            key="id"
            label="name"
            allowNull="true"
          ></pxc-obj-dropdown>
        </div>
      </div>
      <div class="c-helper">When using access card manager, this card format will be used</div>
    </div>
    <div class="l-form__field">
      <label class="c-label">Facility Code</label>

      <input type="number" class="c-input c-input--s" [(ngModel)]="setupState.openPathFacilityCode" />

      <div class="c-helper">
        When using access card manager, facility code, if required by the selected card format.
      </div>
    </div>
  </div>
  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>
