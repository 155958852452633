import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StatsPageComponent } from '~/pages/stats/stats.page';
import { SharedModule } from '~/shared/shared.module';
import { RightGuard } from '~/shared/guards/right.guard';
import { UserRights } from '../../../../../shared/models';

@NgModule({
  declarations: [StatsPageComponent],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'stats',
        component: StatsPageComponent,
        canActivate: [RightGuard],
        data: {
          right: UserRights.TabStats,
          title: 'Stats',
        },
      },
    ]),
  ],
})
export class StatsModule {}
