import { Injectable, Injector } from '@angular/core';
import {
  Company,
  Organisation,
  OrganisationDomain,
  PaginatedOrganisations,
  TenantOrganisation,
  TenantOrganisationPost,
} from '@proxyclick/api-client';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IOrganisationFilter } from '../../../../shared/filters';
import { BaseService } from './base.service';

export interface ICreateOrganisationForm {
  name: string;
  users: string;
}

export const newOrganisation: ICreateOrganisationForm = {
  name: '',
  users: '',
};

@Injectable()
export class OrganisationsService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  create(organisation: Omit<ICreateOrganisationForm, 'users'>) {
    return this.apiClient.Organisations().doPost(organisation).toPromise();
  }

  linkUserToOrganisation(organisationId: string, userId: string) {
    return this.apiClient.OrganisationUser(organisationId, userId).doPut().toPromise();
  }

  getAll(filter: IOrganisationFilter): Observable<PaginatedOrganisations> {
    return this.apiClient.Organisations().doGet(filter);
  }

  getOrganisation(organisationId: string): Observable<Organisation> {
    return this.apiClient.Organisation(organisationId).doGet();
  }

  getDomainsForOrganisation(organisationId: string): Observable<OrganisationDomain[]> {
    return this.apiClient.OrganisationDomains(organisationId).doGet();
  }

  updateOrganisationDomain(organisationId: string, domain: OrganisationDomain): Observable<void> {
    return this.apiClient.OrganisationDomain(organisationId, domain.domain).doPut(domain);
  }

  deleteOrganisationDomain(organisationId: string, domain: string): Observable<void> {
    return this.apiClient.OrganisationDomain(organisationId, domain).doDelete();
  }

  addOrganisationDomain(
    organisationId: string,
    domain: Pick<OrganisationDomain, 'domain' | 'scimEnabled' | 'samlEnabled' | 'verified'>
  ): Observable<OrganisationDomain> {
    return this.apiClient.OrganisationDomains(organisationId).doPost(domain);
  }

  getCompaniesForOrganisation(organisationId: string): Observable<Company[]> {
    return this.apiClient.OrganisationCompanies(organisationId).doGet();
  }

  unlinkCompany(organisationId: string, companyId: string): Observable<void> {
    return this.apiClient.OrganisationCompany(organisationId, companyId).doDelete();
  }

  addCompany(organisationId: string, companyId: string): Observable<void> {
    return this.apiClient.OrganisationCompany(organisationId, companyId).doPut();
  }

  getPlatformTenantByOrganisationId(organisationId: string): Observable<TenantOrganisation | null> {
    return this.newApiClient.OrganisationPlatformTenant(organisationId).doGet().pipe(this.catch404s());
  }

  addPlatformTenant(organisationId: string, data: TenantOrganisationPost): Observable<TenantOrganisation> {
    return this.newApiClient.OrganisationPlatformTenant(organisationId).doPost(data);
  }

  userSyncPlatformTenant(organisationId: string): Observable<void> {
    return this.newApiClient.OrganisationPlatformTenantUserSync(organisationId).doPost();
  }

  private catch404s = () => {
    return catchError(error => {
      if (error && error.status === 404) {
        return of(null);
      }
      throw error;
    });
  };
}
