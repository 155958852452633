import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IAdminUser } from '../../../../shared/models';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable()
export class AdminUsersService extends BaseService {
  constructor(private http: HttpClient, injector: Injector) {
    super(injector);
  }

  createAdminUser(adminUser: IAdminUser) {
    return this.http.post<IAdminUser>(`/admin/users`, adminUser);
  }

  getAdminUsers(): Observable<IAdminUser[]> {
    return this.http.get<IAdminUser[]>(`/admin/users`);
  }

  getAdminUser(id: number): Observable<IAdminUser> {
    return this.http.get<IAdminUser>(`/admin/user/${id}`);
  }

  updateAdminUser(adminUser: IAdminUser): Observable<IAdminUser> {
    return this.http.patch<IAdminUser>(`/admin/user/${adminUser.id}`, adminUser);
  }
}
