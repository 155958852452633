import { Component, Input } from '@angular/core';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';
import { ICustomField } from '@proxyclick/data-model';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import {
  BadgesBadgeTypeResponseV1,
  SegmentsSegmentationSettingsResponseV1,
  SegmentsSegmentResponseV1,
  TypesTypeResponseV1,
  TypesTypeResponseV1TypePropertyEntity,
} from '@proxyclick/extender-server-onguard-client';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-fastpace-company-setup-extenders-onguard',
  templateUrl: './onguard-setup.component.html',
})
export class OnGuardSetupComponent {
  @Input() setupState: FastPaceSetupCompanyState;
  constructor(private companyService: CompaniesService, private extenderService: ExtenderService) {}
  async ngOnInit(): Promise<void> {
    this.customFields = await this.companyService.getCustomFields(this.setupState.company.id).toPromise();
    this.setCustomFieldOptions();
    this.availableTypes = ['Lnl_Visitor', 'Lnl_CardHolder', 'Lnl_Badge'];

    for await (const availableType of this.availableTypes) {
      var type = await this.extenderService.onguard.types.find(this.setupState.extender.extenderId, {
        requestTypeName: availableType,
        requestTracingId: uuidv4(),
      });
      this.types.push(type);
    }
    this.setTypeOptions();
    this.setPropertyTypeOptions();

    this.segmentationSettings = await this.extenderService.onguard.segments.getSettings(
      this.setupState.extender.extenderId,
      { requestTracingId: uuidv4() }
    );

    if (this.segmentationSettings.segmentationEnabled) {
      this.segments = await this.extenderService.onguard.segments.listSegments(this.setupState.extender.extenderId, {
        requestTracingId: uuidv4(),
      });
    } else {
      this.segmentationInfoMessage = 'Segmentation is not enabled';
    }

    this.setSegmentOptions();

    this.badgeTypes = await this.extenderService.onguard.badges.listTypes(this.setupState.extender.extenderId, {
      requestTracingId: uuidv4(),
    });

    this.loading = false;
  }

  loading: boolean = true;
  segmentationInfoMessage: string;

  segmentationSettings: SegmentsSegmentationSettingsResponseV1 | null = null;

  segments: SegmentsSegmentResponseV1[] = [];
  segmentOptions: SegmentsSegmentResponseV1[] = [];
  segmentOption: SegmentsSegmentResponseV1 | null = null;

  segmentAccessLevelOption: SegmentsSegmentResponseV1 | null = null;

  mapCustomFields: boolean = false;

  badgeTypes: BadgesBadgeTypeResponseV1[] = [];

  availableTypes: string[] = [];
  types: TypesTypeResponseV1[] = [];
  typeOptions: TypesTypeResponseV1[] = [];
  typeOption: TypesTypeResponseV1 | null = null;

  typePropertyOptions: { [type: string]: TypesTypeResponseV1TypePropertyEntity[] } = {};
  propertyOption: TypesTypeResponseV1TypePropertyEntity | null = null;

  customFields: ICustomField[] = [];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.setupState.onGuardCustomFieldMappings = [];
    }
  }

  fieldTypeOptionChanged() {
    if (this.typeOption) {
      var typePropertyOption = this.typePropertyOptions[this.typeOption.typeName];
      if (typePropertyOption.length > 0) {
        this.propertyOption = typePropertyOption[0];
      } else {
        this.propertyOption = null;
      }
    }
  }

  segmentOptionChanged() {
    this.setupState.onGuardCardHolderSegment = this.segmentOption;

    if (!this.segmentationSettings.allowAccessLevelsToBeConfiguredAsAssignableByOtherSegments) {
      this.setupState.onGuardAccessLevelSegement = this.segmentOption;
      this.segmentAccessLevelOption = this.segmentOption;
    }
  }

  segmentAccessLevelOptionChanged() {
    this.setupState.onGuardAccessLevelSegement = this.segmentAccessLevelOption;
  }

  setSegmentOptions() {
    this.segmentOptions = this.segments;
    this.segmentOption = null;
    this.segmentAccessLevelOption = null;
  }

  setTypeOptions() {
    this.typeOptions = this.types;
    this.typeOption = this.typeOptions[0];
  }

  setPropertyTypeOptions() {
    this.typePropertyOptions = {};
    this.types.forEach(type => {
      const options = type.properties.filter(
        p =>
          this.setupState.onGuardCustomFieldMappings.filter(
            m => m.fieldType.typeName === type.typeName && m.fieldProperty.name === p.name
          ).length === 0
      );
      this.typePropertyOptions[type.typeName] = options;
    });
    this.fieldTypeOptionChanged();
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return this.setupState.onGuardCustomFieldMappings.filter(a => a.customField.id === c.id).length === 0;
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = null;
    }
  }

  canAddMapping() {
    return (
      this.customFieldOption !== null &&
      this.typeOption !== null &&
      this.propertyOption !== null &&
      this.setupState.onGuardCustomFieldMappings.filter(
        c =>
          (c.fieldProperty.name === this.propertyOption.name && c.fieldType.typeName === this.typeOption.typeName) ||
          c.customField.id === this.customFieldOption.id
      ).length === 0
    );
  }

  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }
    this.setupState.onGuardCustomFieldMappings.push({
      customField: this.customFieldOption,
      fieldType: this.typeOption,
      fieldProperty: this.propertyOption,
    });
    this.setCustomFieldOptions();
    this.setPropertyTypeOptions();
  }

  removeMapping(id: number) {
    this.setupState.onGuardCustomFieldMappings = this.setupState.onGuardCustomFieldMappings.filter(
      c => c.customField.id !== id
    );
    this.setCustomFieldOptions();
    this.setPropertyTypeOptions();
  }

  canGoNext() {
    if (
      this.loading ||
      !this.segmentationSettingsAreValid() ||
      (this.mapCustomFields && this.setupState.onGuardCustomFieldMappings.length < 1)
    ) {
      return false;
    }
    return true;
  }

  segmentationSettingsAreValid() {
    if (this.segmentationSettings.segmentationEnabled) {
      if (!this.setupState.onGuardAccessLevelSegement) {
        return false;
      }

      if (!this.segmentationSettings.segmentVisitors && this.setupState.onGuardCardHolderSegment) {
        return false;
      }

      if (this.segmentationSettings.segmentVisitors && !this.setupState.onGuardCardHolderSegment) {
        return false;
      }

      if (
        this.segmentationSettings.segmentVisitors &&
        !this.segmentationSettings.allowAccessLevelsToBeConfiguredAsAssignableByOtherSegments &&
        this.setupState.onGuardCardHolderSegment != this.setupState.onGuardAccessLevelSegement
      ) {
        return false;
      }
    } else {
      // segmentation disabled
      if (this.setupState.onGuardCardHolderSegment || this.setupState.onGuardAccessLevelSegement) {
        return false;
      }
    }

    return true;
  }
}
