import { Component, OnInit } from '@angular/core';
import { IUser } from '@proxyclick/data-model';
import { ICompanyUsersFilter } from '~/models/filters';
import { UserService } from '~/services/users.service';
import { CompanyUsersActions } from '~/store/companies/companies.actions';
import { usersWithAdmin } from '~/store/companies/companies.selector';
import { AppStore } from '~/store/store';
import { PxcUserDirectoryActions } from '~/store/users/users.actions';

@Component({
  selector: 'pxc-company-users',
  templateUrl: './company-users.html',
})
export class CompanyUsersComponent implements OnInit {
  admins$ = this.store.select('company', 'admins', 'value');
  users$ = usersWithAdmin(this.store.select('company', 'users', 'value'), this.admins$);
  loadingAdmins$ = this.store.select('company', 'admins', 'loading');
  loadingUsers$ = this.store.select('company', 'users', 'loading');
  userProvisioning$ = this.store.select('company', 'userProvisioning', 'value');
  saml$ = this.store.select('company', 'saml', 'value');
  adminsOnly = false;
  pxcCredentialsModal = false;
  usersWithPxcUserDirectoryEntry = [];
  filter: ICompanyUsersFilter;
  total: number;
  limit: number;
  searchTerm: string;
  companyId: string;

  constructor(private store: AppStore, private Users: UserService) {}

  ngOnInit(): void {
    this.companyId = null;
    this.store.select('company', 'company', 'value', 'id').subscribe(id => {
      this.companyId = id;
      this.initFilter();
    });

    this.users$.subscribe(users => {
      this.total = users.total;
      this.limit = users.limit;
      users.users.filter(user => user.hasPxcDirectoryEntry && this.usersWithPxcUserDirectoryEntry.push(user.id));
    });
  }

  initFilter() {
    this.filter = {
      companyId: this.companyId,
      withMetaData: true,
      page: 1,
      pageSize: 100,
    };
  }

  getUsers() {
    if (this.searchTerm) {
      this.filter.q = this.searchTerm;
    }
    return this.store.dispatchAsync(CompanyUsersActions.Load(this.filter));
  }

  searchUsers() {
    return () => {
      this.adminsOnly = false;
      this.filter.page = 1;
      return this.getUsers();
    };
  }

  onPageChange(page) {
    this.filter.page = page;
    this.getUsers();
  }

  removePxcUserDirectoryForAll() {
    this.store
      .dispatchAsync(
        PxcUserDirectoryActions.removeMultiple({ userIds: this.usersWithPxcUserDirectoryEntry.toString() })
      )
      .then(() => {
        this.usersWithPxcUserDirectoryEntry = [];
        this.togglePxcCredentialsModal();
      });
  }

  async logAsUser(user: IUser) {
    this.Users.logAsUser(user, this.companyId);
  }

  togglePxcCredentialsModal() {
    this.pxcCredentialsModal = !this.pxcCredentialsModal;
  }
}
