<div class="c-card__section">
  <h3>Genetec settings</h3>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Partition</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-obj-dropdown
          [(ngModel)]="partitionOption"
          [options]="partitionOptions"
          (ngModelChange)="partitionOptionChanged()"
          key="guid"
          label="name"
          allowNull="true"
        ></pxc-obj-dropdown>
      </div>
    </div>

    <div class="c-helper">Connections to genetec can <strong>optionally</strong> connect to a specific partition.</div>
  </div>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Credential Format (Access Card Manager)</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-obj-dropdown
          [(ngModel)]="setupState.genetecCardCredentialFormat"
          [options]="cardFormats"
          key="formatId"
          label="name"
          allowNull="true"
        ></pxc-obj-dropdown>
      </div>
    </div>
    <div class="c-helper">When using access card manager, this credential format will be used.</div>
  </div>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Facility code</label>
      </div>
      <div class="l-bar__item-end">
        <input type="number" class="c-input c-input--s" [(ngModel)]="setupState.genetecFacilityCode" />
      </div>
    </div>
    <div class="c-helper">Select the facility code set as default inside the ACS</div>
  </div>

  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>
