import { Component, Input, OnInit } from '@angular/core';
import { NewExtenderState } from '../new-extender.state';

@Component({
  selector: 'app-new-extender-genea',
  templateUrl: './new-extender.genea.component.html',
})
export class NewExtenderGeneaComponent {
  @Input() state: NewExtenderState;
}
