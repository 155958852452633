import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SimplemdeModule  } from 'ngx-simplemde';
import { AppComponent } from '~/app.component';
import { AppPartnersModule } from '~/pages/app-partners/app-partners.module';
import { AuthModule } from '~/pages/auth/auth.module';
import { CompaniesModule } from '~/pages/companies/companies.module';
import { ErrorPageModule } from '~/pages/error/error-page.module';
import { StatsModule } from '~/pages/stats/stats.module';
import { UserProvisioningModule } from '~/pages/user-provisioning/user-provisioning.module';
import { UsersModule } from '~/pages/users/users.module';
import { UtilitiesModule } from '~/pages/utilities/utilities.module';
import { ConfigService } from '~/services/config.service';
import { SharedModule } from '~/shared/shared.module';
import { AppStoreModule } from '~/store/app-store.module';
import { AuthInterceptor } from '~/utils/interceptors/auth.interceptor';
import { ErrorInterceptor } from '~/utils/interceptors/error.interceptor';
import '~/utils/rxjs-extensions';
import { AdminUsersModule } from './pages/admin-users/admin-users.module';
import { DomainsModule } from './pages/domains/domains.module';
import { FastPaceModule } from './pages/fastpace/fastpace.module';
import { HomeModule } from './pages/home/home.module';
import { MapModule } from './pages/map/map.module';
import { MarketplaceModule } from './pages/marketplace/marketplace.module';
import { OrganisationsModule } from './pages/organisations/organisations.module';
import { VisitorsModule } from './pages/visitors/visitors.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    BrowserModule,
    ErrorPageModule,
    AppStoreModule,
    MarketplaceModule,
    MapModule,
    HomeModule,
    OrganisationsModule,
    CompaniesModule,
    UsersModule,
    AdminUsersModule,
    AppPartnersModule,
    UtilitiesModule,
    UserProvisioningModule,
    FastPaceModule,
    StatsModule,
    AuthModule,
    DomainsModule,
    VisitorsModule,
    NgbModule,
    FormsModule,
    SimplemdeModule.forRoot({
      options: {
        showIcons: ['bold', 'italic', 'heading', '|', 'quote'],
      },
    }),
    RouterModule.forRoot([
    {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
    },
    ], { relativeLinkResolution: 'legacy' }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => () => config.initConfig(),
      deps: [ConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
