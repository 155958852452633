import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganisationDomain } from '@proxyclick/api-client';
import { DialogService } from '~/shared/components/dialog/dialog.service';
import {
  OrganisationDomainsActions,
  OrganisationDomainsAddActions,
  OrganisationDomainsDeleteActions,
  OrganisationDomainsUpdateActions,
} from '~/store/organisation/organisation.actions';
import { AppStore } from '~/store/store';

@Component({
  selector: 'pxc-organisation-domains',
  templateUrl: './organisation-domains.html',
})
export class OrganisationDomainsComponent implements OnInit {
  domains$ = this.store.select('organisation', 'domains', 'value');
  loadingDomains$ = this.store.select('organisation', 'domains', 'loading');

  addDomainModalVisible = false;
  newDomains: string = '';
  isDomainsSaving = false;

  private organisationId: string;

  constructor(private store: AppStore, private route: ActivatedRoute, private dialog: DialogService) {}

  ngOnInit(): void {
    this.organisationId = this.route.parent.snapshot.params.id;
    this.store.dispatch(OrganisationDomainsActions.Load());
  }

  update(domain: OrganisationDomain) {
    return () => this.store.dispatchAsync(
      OrganisationDomainsUpdateActions.Save({
        ...domain,
        organisationId: this.organisationId,
      })
    );
  }

  deleteDomain(domain: OrganisationDomain) {
    this.dialog.confirm('Delete domain', `Are you sure you want to delete ${domain.domain}?`).then(result => {
      if (result) {
        this.store.dispatchAsync(
          OrganisationDomainsDeleteActions.Save({
            organisationId: this.organisationId,
            domain: domain.domain,
          })
        );
      }
    });
  }

  addDomain() {
    this.isDomainsSaving = true;
    const domains = this.newDomains
      .split('\n')
      .map(d => d.trim())
      .filter(Boolean);

    if (domains.length === 0) {
      return;
    }

    this.store
      .dispatchAsync(OrganisationDomainsAddActions.Save({ organisationId: this.organisationId, domains }))
      .then(() => {
        this.addDomainModalVisible = false;
        this.newDomains = '';
        this.isDomainsSaving = false;
      });
  }
}
