<div *ngFor="let item of items">
  <pxc-checkbox *ngIf="items.length > 5; else required" [(ngModel)]="item.checked" (ngModelChange)="onUpdate()">
    {{item.name | translate}}
  </pxc-checkbox>
  <ng-template #required>
    <pxc-checkbox [(ngModel)]="item.checked" (ngModelChange)="onUpdate()">
      {{item.name}}
    </pxc-checkbox>
  </ng-template>
</div>
