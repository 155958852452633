<pxc-loading [loading]="loading$ | async">
  <div *ngIf="domain$ | async as domain">
    <pxc-field label="Account Manager">
      <pxc-admins-typeahead
        [(ngModel)]="domain.accountManager"
        (ngModelChange)="updateDomain(domain)"
      ></pxc-admins-typeahead>
    </pxc-field>
    <pxc-field label="Key Contact">
      <pxc-users-typeahead
        [(ngModel)]="domain.keyContact"
        [domain]="domain.domain"
        (ngModelChange)="updateDomain(domain)"
      ></pxc-users-typeahead>
    </pxc-field>
    <pxc-field label="Notes">
      <form #form="ngForm" (ngSubmit)="updateDomain(domain)">
        <textarea class="form-control" rows="3" [(ngModel)]="domain.note" name="note"></textarea>
        <pxc-button class="u-margin-top-xs" [loading]="loadingUpdate$">Update</pxc-button>
      </form>
    </pxc-field>
    <pxc-field label="Relationship">
      <pxc-dropdown
        [(ngModel)]="domain.relationship"
        [options]="relationshipOptions"
        [loading]="loadingUpdate$"
        (ngModelChange)="updateDomain(domain)"
      ></pxc-dropdown>
    </pxc-field>
  </div>
</pxc-loading>
