import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IDelivery, IVisit } from '@proxyclick/data-model';
import * as GoogleMapsLoader from 'google-maps';
import { Subscription } from 'rxjs';
import { MapService } from '~/services/map.service';
import { PubNubService } from '~/services/pubnub.service';
import { createAnimation } from '~/utils/timer';
import { nite } from './nite-overlay.js';
import { darkTheme } from './themes/dark-theme';

(GoogleMapsLoader as any).VERSION = 3.34;
(GoogleMapsLoader as any).KEY = 'AIzaSyBEftYe8AESCtlm2IHEH0r_7EicQj0W-4I';
(GoogleMapsLoader as any).LANGUAGE = 'EN';

const baseZoom = 2;

const mapOptions = {
  backgroundColor: '#292E33',
  center: { lat: 30, lng: -10 },
  zoom: baseZoom,
  styles: darkTheme,
  disableDefaultUI: true,
};

const opacity = [1, 0.7, 0.4];

@Component({
  selector: 'pxc-live-map',
  templateUrl: './live-map.html',
  styleUrls: ['./live-map.scss'],
})
export class LiveMapComponent implements OnInit, OnDestroy {
  @ViewChild('map', { static: true })
  mapElement: ElementRef;

  map: google.maps.Map;

  private subscriptions: Subscription[] = [];
  private timers = [];

  constructor(private mapService: MapService, private pubnub: PubNubService) {}

  ngOnInit() {
    GoogleMapsLoader.load(g => this.initializeMap(g));
    this.subscriptions.push(this.mapService.getCheckinObservable$().subscribe(visit => this.handleCheckin(visit)));
    this.subscriptions.push(
      this.mapService.getDeliveryObservable$().subscribe(delivery => this.handleDelivery(delivery))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.timers.forEach(timer => clearInterval(timer));
    this.pubnub.unsubscribeMapCHannelObservable();
  }

  private handleCheckin(visit: IVisit) {
    if (visit) {
      this.addCircle(visit.company.latitude, visit.company.longitude, '#26DB8E');
    }
  }

  private handleDelivery(delivery: IDelivery) {
    if (delivery) {
      this.addCircle(delivery.company.latitude, delivery.company.longitude, '#3AB9FF');
    }
  }

  private initializeMap(g) {
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions as any);
    nite.init(this.map);
    this.timers.push(
      setInterval(() => {
        nite.refresh();
        // eslint-disable-next-line @typescript-eslint/indent
      }, 1000 * 60)
    ); // Refresh night overlay every minute
  }

  private addCircle(lat, lng, color = '#26DB8E') {
    const commonOptions: google.maps.CircleOptions = {
      map: this.map,
      center: { lat, lng },
      radius: 0,
      strokeWeight: 0,
      fillColor: color,
    };

    const circles = opacity.map(
      o =>
        new google.maps.Circle({
          ...commonOptions,
          fillOpacity: o,
        })
    );
    this.animateCircles(circles);
  }

  private animateCircles(circles: google.maps.Circle[]) {
    const maxSize = [200000, 400000, 900000].map(size => (size * baseZoom) / this.map.getZoom()); // Make space smaller for bigger zoom
    const time = 1500;
    createAnimation({
      duration: time * 2,
      frequency: 10,
      startOffset: 0,
      callback: elapsed => {
        circles.forEach((circle, index) => circle.setRadius(elapsed * maxSize[index]));
      },
    });
    createAnimation({
      duration: time,
      frequency: 10,
      startOffset: time,
      callback: elapsed => {
        circles.forEach((circle, index) =>
          circle.setOptions({
            fillOpacity: opacity[index] * (1 - elapsed),
          })
        );
      },
    });
    setTimeout(() => {
      circles.forEach(circle => {
        circle.setMap(null);
        circle = null;
      });
    }, time * 2);
  }

  private testRandomPoints() {
    setInterval(() => {
      this.addCircle((Math.random() * 2 - 1) * 50, (Math.random() * 2 - 1) * 50, '#3AB9FF');
    }, 500);
  }
}
