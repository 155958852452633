import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { IAppState } from '~/store/app-state';
import { SearchAction } from '~/store/search/search.reducer';
import { searchLoadingSelector, searchValueSelector } from '~/store/search/search.selector';

@Component({
  selector: 'pxc-search-bar',
  templateUrl: './search-bar.html',
})
export class SearchBarComponent implements OnInit {
  termSubject = new Subject<string>();

  values$ = searchValueSelector(this.store.select('search', 'companies'), this.store.select('search', 'users')).pipe(
    map(([companies, users]) => ({
      companies: companies,
      users: users,
    }))
  );

  loading$ = searchLoadingSelector(this.store.select('search', 'companies'), this.store.select('search', 'users'));

  loadingCompanies$ = this.store.select('search', 'companies', 'loading');
  loadingUsers$ = this.store.select('search', 'users', 'loading');

  term: string;
  showSearch = false;

  constructor(private store: Store<IAppState>, private router: Router) {}

  ngOnInit() {
    this.termSubject.pipe(debounceTime(200), distinctUntilChanged()).subscribe(term => {
      this.store.dispatch(SearchAction.Load(term));
    });
  }

  onKeyup(event, term: string) {
    if (event.key !== 'Enter') {
      this.termSubject.next(term);
    } else {
      this.store.dispatch(SearchAction.Load(null));
    }
  }

  submitSearch() {
    if (this.term.startsWith('CO-')) {
      this.store.dispatch(SearchAction.Load(null));
      this.router.navigate(['companies', this.term.trim()]);
    } else if (this.term.startsWith('US-')) {
      this.store.dispatch(SearchAction.Load(null));
      this.router.navigate(['users', this.term.trim()]);
    }
  }
}
