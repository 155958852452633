import { NgModule } from '@angular/core';
import { AppPartnersService } from '~/services/app-partners.service';
import { ApplicationsService } from '~/services/applications.service';
import { AuthService } from '~/services/auth.service';
import { CategoriesService } from '~/services/categories.service';
import { CompaniesService } from '~/services/companies.service';
import { ConfigService } from '~/services/config.service';
import { CountriesService } from '~/services/countries.service';
import { DocumentsService } from '~/services/documents.service';
import { DownloadService } from '~/services/download.service';
import { GoogleMapsService } from '~/services/googlemaps.service';
import { IntegrationsService } from '~/services/integrations.service';
import { KioskService } from '~/services/kiosk.service';
import { LanguageService } from '~/services/languages.service';
import { UserProvisioningService } from '~/services/ldap.service';
import { PartnersService } from '~/services/partners.service';
import { PubNubService } from '~/services/pubnub.service';
import { PxcConverter } from '~/services/pxc-converter.service';
import { RegionsService } from '~/services/regions.services';
import { RightService } from '~/services/rights.service';
import { SearchService } from '~/services/search.service';
import { StatsService } from '~/services/stats.service';
import { UserService } from '~/services/users.service';
import { UtilService } from '~/services/util.service';
import { VisitService } from '~/services/visits.service';
import { DialogService } from '~/shared/components/dialog/dialog.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { AppStore } from '~/store/store';
import { AdminCompaniesService } from './admin-companies.service';
import { AdminUsersService } from './admin-users.service';
import { AdminService } from './admin.service';
import { BillingService } from './billing.service';
import { ClipboardService } from './clipboard.service';
import { DeliveryService } from './deliveries.service';
import { DomainService } from './domain.service';
import { ExtenderService } from './extender.service';
import { FastPaceService } from './fastpace.service';
import { IntercomService } from './intercom.service';
import { JWTService } from './jwt.service';
import { MapService } from './map.service';
import { OrganisationsService } from './organisations.service';
import { PongUpService } from './pongup.service';
import { ProovrService } from './proovr.service';
import { ProxyStatsService } from './proxystats/proxystats.service';
import { UserDirectoryService } from './user-directory.service';
import { VisitorsService } from './visitors.service';

const services = [
  IntegrationsService,
  CategoriesService,
  PartnersService,
  DocumentsService,
  RegionsService,
  ConfigService,
  CompaniesService,
  UserService,
  KioskService,
  UserProvisioningService,
  PxcConverter,
  CountriesService,
  GoogleMapsService,
  DownloadService,
  AppPartnersService,
  LanguageService,
  SearchService,
  StatsService,
  UtilService,
  AuthService,
  RightService,
  ApplicationsService,
  DialogService,
  StatsService,
  VisitService,
  VisitorsService,
  PubNubService,
  NotificationsService,
  ProxyStatsService,
  PongUpService,
  MapService,
  DomainService,
  AdminService,
  DeliveryService,
  AdminCompaniesService,
  FastPaceService,
  ExtenderService,
  ProovrService,
  BillingService,
  IntercomService,
  AppStore,
  ClipboardService,
  JWTService,
  AdminUsersService,
  UserDirectoryService,
  OrganisationsService,
];

@NgModule({
  providers: [services],
  imports: [],
})
export class ServicesModule {}
