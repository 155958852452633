import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICompany } from '@proxyclick/data-model';
import { CompanyActions } from '~/store/companies/companies.actions';
import { AppStore } from '~/store/store';
import { FastPaceSetupCompanyState } from './fastpace-setup-company.state';
import { allWizardPages } from './wizard-pages';

@Component({
  selector: 'app-fastpace-company-setup',
  templateUrl: './company-setup-fastpace.component.html',
})
export class SetupFastPaceCompanyComponent implements OnInit {
  public setupState: FastPaceSetupCompanyState;
  company$ = this.store.select<ICompany>('company', 'company', 'value');
  loading$ = this.store.select('company', 'company', 'loading');
  allPages = allWizardPages;
  constructor(private store: AppStore, private route: ActivatedRoute) {
    this.setupState = new FastPaceSetupCompanyState();
  }
  ngOnInit(): void {
    this.company$.subscribe(v => {
      this.setupState.company = v;
    });
    this.route.params.subscribe(params => {
      this.store.dispatch(CompanyActions.Load(params.id));
    });
  }
}
