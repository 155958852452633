import { IOption } from '~/shared/components/input/radio/radio.component';
import { Component, Input } from '@angular/core';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';
import * as _ from 'lodash';
import { ExtenderService } from '~/services/extender.service';
import { IExtenderTokenGroupResponseV1 } from '~/store/extender/extender.models';

@Component({
  selector: 'app-fastpace-company-setup-set-token-range',
  templateUrl: './set-token-range.component.html',
})
export class SetTokenRangeComponent {
  @Input() setupState: FastPaceSetupCompanyState;
  constructor(private extenderService: ExtenderService) {}

  ngOnInit(): void {
    if (this.setupState.selectedTokenGroup !== undefined) {
      this.extenderService
        .findTokenGroup(this.setupState.selectedTokenGroup.value)
        .toPromise()
        .then(r => {
          this.tokenGroup = r;
          this.setupState.tokenRangeMinimum = this.tokenGroup.rangeMinimum;
          this.setupState.tokenRangeMaximum = this.tokenGroup.rangeMaximum;
        });
    } else {
      this.setupState.tokenRangeMinimum = 10000000;
      this.setupState.tokenRangeMaximum = 99999999;
    }
  }

  tokenGroup: IExtenderTokenGroupResponseV1;

  canGoNext() {
    return (
      this.setupState.selectedTokenGroup ||
      (this.setupState.tokenRangeMinimum >= 10000000 &&
        this.setupState.tokenRangeMaximum <= 99999999 &&
        this.setupState.tokenRangeMaximum > this.setupState.tokenRangeMinimum &&
        this.setupState.tokenRangeMaximum - this.setupState.tokenRangeMinimum >= 9999998)
    );
  }
}
