import { ApiClient } from '@proxyclick/api-client';
import { ColdFusionApiClient } from '@proxyclick/api-client/dist/packages/client/src/compat/cf-api-client';
import { Observable } from 'rxjs';

type UnwrapPromise<T> = T extends Promise<infer Q> ? Q : T;
type ReTypeVerb<T> = T extends (...args: any[]) => infer Q
  ? (...args: Parameters<T>) => Observable<UnwrapPromise<Q>>
  : T;
type ReTypeVerbs<T> = {
  [index in keyof T]: ReTypeVerb<T[index]>;
};
type ReTypeEndpoint<T> = T extends (...args: any[]) => infer Q ? (...args: Parameters<T>) => ReTypeVerbs<Q> : never;

interface IApiClientObservable {
  new (): {
    [index in keyof ApiClient]: ReTypeEndpoint<ApiClient[index]>;
  };
}

const PxcApiClientCtor: IApiClientObservable = class {} as any;
export class PxcApiClient extends PxcApiClientCtor {}

//Backward compatibility to avoid upgrading all the code at once
interface IColdFusionApiClientObservable {
  new (): {
    [index in keyof ColdFusionApiClient]: ReTypeEndpoint<ColdFusionApiClient[index]>;
  };
}

const PxcColdFusionApiClientCtor: IColdFusionApiClientObservable = class {} as any;
export class PxcColdFusionApiClient extends PxcColdFusionApiClientCtor {}
