import { Component, OnInit } from '@angular/core';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';
import { FastPaceService } from '~/services/fastpace.service';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { DepartmentsDepartmentV1 } from '@proxyclick/extender-server-net2-client';
import { v4 as uuidv4 } from 'uuid';
import { ControllersCompaniesNet2CompanySettingsResponseV1 } from '@proxyclick/fastpace-client';

@Component({
  selector: 'app-fastpace-company-net2',
  templateUrl: './fastpace.company.net2.component.html',
})
export class FastPaceCompanyNet2Component extends FastPaceBaseSystemSettingsComponent implements OnInit {
  constructor(fastpaceService: FastPaceService, companyService: CompaniesService, extenderService: ExtenderService) {
    super(fastpaceService, companyService, extenderService);
  }
  async ngOnInit(): Promise<void> {
    this.net2Settings = await this.fastpaceService.getNet2Settings(this.fastpaceCompany.companyId);
    this.department = await this.extenderService.net2.departments.find1(this.fastpaceCompany.extenderId, {
      requestDepartmentId: this.net2Settings.departmentId,
      requestTracingId: uuidv4(),
    });
  }

  //current settings
  net2Settings: ControllersCompaniesNet2CompanySettingsResponseV1 | null = null;
  department: DepartmentsDepartmentV1;

  get canSubmit(): boolean {
    return this.submitting !== true;
  }

  async doUpdate(): Promise<void> {
    return this.fastpaceService.updateNet2Settings(this.fastpaceCompany.companyId, {
      doorFilterIn: [],
      doorFilterOut: [],
    });
  }
}
