import { Component } from '@angular/core';
import { _ } from '~/libs';
import { PxcConverter } from '~/services/pxc-converter.service';

const types = ['corporate', 'user', 'visitor', 'meeting', 'ticket', 'supplier'];

@Component({
  selector: 'pxc-convert',
  templateUrl: './convert.html',
})
export class ConvertPageComponent {
  idInput: string;
  refInput: string;
  pxcId: string;
  pxcRef: string;
  typeOptions = types.map(t => _.capitalize(t));
  type = this.typeOptions[0];
  idToPxcInput: string;
  idToPxcOutput: string;

  constructor(private Converter: PxcConverter) {}

  computeID() {
    this.pxcId = this.pxcRef && this.Converter.getIdFromPxc(this.pxcRef);
  }

  computeRef() {
    this.pxcRef =
      this.pxcId &&
      this.Converter.getPxcFromId({
        id: this.pxcId,
        object: this.type,
      });
  }

  convert() {
    this.refInput = this.Converter.replacePxcRefInText(this.idInput);
  }

  convertIdToPxc() {
    this.idToPxcOutput = this.Converter.replaceIdInText(this.idToPxcInput, this.type);
  }
}
