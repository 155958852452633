import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest as observableCombineLatest, Observable, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { BaseService } from '~/services/base.service';
import { CompaniesService } from '~/services/companies.service';
import { IKPI, ILiveMapStats } from '../../../../shared/models';

export interface IAdminStatsFilter {
  companyId?: string;
  date?: string;
  module?: string;
  vmStatus?: string;
  sortBy?: string;
  sort?: string;
  page?: number;
  pageSize?: number;
}

export interface IIdScanFilter {
  companyId: string;
  from: moment.Moment;
  to: moment.Moment;
}

@Injectable()
export class StatsService extends BaseService {
  constructor(injector: Injector, private http: HttpClient, private Companies: CompaniesService) {
    super(injector);
  }

  getAdminStats(adminStatsFilter: IAdminStatsFilter) {
    const idScanRange = {
      from: moment(adminStatsFilter.date).startOf('month').format('YYYY-MM-DD'),
      to: moment(adminStatsFilter.date).endOf('month').format('YYYY-MM-DD'),
    };
    return applySort(
      enrichData(
        this.apiClient.AdminStats().doGet(adminStatsFilter),
        this.apiClient.AdminIdScanStats().doGet(idScanRange)
      ),
      adminStatsFilter
    );
  }

  getIdScanStats(idScanFilter: IIdScanFilter) {
    return this.apiClient.ApplicationIdScanStats(idScanFilter.companyId).doGet({
      from: idScanFilter.from.format('YYYY-MM-DD'),
      to: idScanFilter.to.format('YYYY-MM-DD'),
    });
  }

  getLiveMapStats() {
    return this.http.get<ILiveMapStats>('/live-stats');
  }

  getLiveMapStatsObservable() {
    return timer(0, 5000)
      .pipe(switchMap(() => this.http.get<ILiveMapStats>('/live-stats')))
      .pipe(
        map(data => {
          return {
            checkinPerHour: Array.from(Array(24).keys()).map(() => Math.random() * 500),
          };
        })
      );
  }

  getKPIs() {
    return this.http.get<IKPI>('/stats/kpi');
  }

  getKPIObservable$(interval: number) {
    return timer(0, interval).pipe(switchMap(() => this.getKPIs()));
  }
}

// Inject the ID Scan data into the global stats
function enrichData(stats$: Observable<any>, idScanStats$: Observable<any>) {
  return observableCombineLatest(stats$.pipe(filter(a => !!a)), idScanStats$.pipe(filter(a => !!a))).pipe(
    map(([stats, idScanStats]) => {
      for (const d of stats.data) {
        const c = _.find(idScanStats.data, id => id.id === d.id);
        d.acuantDuplex = c ? c.acuantDuplex : 0;
      }
      return stats;
    }),
    map(stats => {
      stats.total.acuantDuplex = _.reduce(stats.data, (acc, value) => acc + value.acuantDuplex, 0);
      return stats;
    })
  );
}

// Apply necessary sort
function applySort(stats$: Observable<any>, filterStats: IAdminStatsFilter) {
  if (filterStats.sortBy === 'acuantDuplex') {
    return stats$.pipe(
      map((stats: any) => {
        stats.data = _.sortBy(stats.data, d => d.acuantDuplex);
        if (filterStats.sort === 'desc') {
          stats.data = _.reverse(stats.data);
        }
        return stats;
      })
    );
  }
  return stats$;
}
