import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ForgotPasswordPageComponent } from '~/pages/auth/forgot-password/forgot-password.page';
import { LoginPageComponent } from '~/pages/auth/login/login.page';
import { SharedModule } from '~/shared/shared.module';
import { OAuthPageComponent } from './oauth/oauth.page';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'login',
        component: LoginPageComponent,
      },
      {
        path: 'forgot-password/:code',
        component: ForgotPasswordPageComponent,
      },
      {
        path: 'oauth',
        component: OAuthPageComponent,
      },
    ]),
  ],
  declarations: [LoginPageComponent, ForgotPasswordPageComponent, OAuthPageComponent],
})
export class AuthModule {}
