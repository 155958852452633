import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CategoriesEffects } from './categories.effects';
import { categoriesReducer, categoryReducer } from './categories.reducer';

export const CATEGORIES_REDUCER_TOKEN = new InjectionToken('CATEGORIES_REDUCER_TOKEN');
export const CATEGORY_REDUCER_TOKEN = new InjectionToken('CATEGORY_REDUCER_TOKEN');

@NgModule({
  imports: [
    StoreModule.forFeature('categories', CATEGORIES_REDUCER_TOKEN),
    StoreModule.forFeature('category', CATEGORY_REDUCER_TOKEN),
    EffectsModule.forFeature([CategoriesEffects]),
  ],
  providers: [
    { provide: CATEGORIES_REDUCER_TOKEN, useValue: categoriesReducer },
    { provide: CATEGORY_REDUCER_TOKEN, useValue: categoryReducer },
  ],
})
export class CategoriesStoreModule {}
