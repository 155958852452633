import { Component } from '@angular/core';
import { PongUpService } from '~/services/pongup.service';

@Component({
  selector: 'pxc-pongup-leaderboard',
  templateUrl: './leaderboard.html',
  styleUrls: ['./leaderboard.scss'],
})
export class LeaderboardComponent {
  topPlayers$ = this.pongUp.getTopPlayers$();

  constructor(private pongUp: PongUpService) {}
}
