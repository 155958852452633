<div class="c-card__section">
  <h3>AEOS settings</h3>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Use the employee type?</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-switch [(ngModel)]="setupState.aeosUseEmployeeType" name="aeosUseEmployeeType"></pxc-switch>
      </div>
    </div>
    <div class="c-helper">
      Proxyclick can be configured to use the employee card holder type within AEOS. Usually this is disabled and the
      visitor type is used.
    </div>
  </div>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Set the host as contact via email mapping?</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-switch [(ngModel)]="setupState.aeoshostEmailAsContact" name="aeoshostEmailAsContact"></pxc-switch>
      </div>
    </div>
    <div class="c-helper">
      Use the host's email for a contact lookup in AEOS? When enabled, visitors with a host that doesn't have an
      employee or contractor profile in AEOS will not be processed.
    </div>
  </div>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Map custom fields to AEOS Free fields</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-switch
          [(ngModel)]="mapCustomFields"
          name="mapCustomFields"
          [onChange]="switchEnableCustomFieldMapping()"
        ></pxc-switch>
      </div>
    </div>
  </div>
  <div class="l-grid" *ngIf="mapCustomFields">
    <div class="l-grid__cell l-grid__cell--6">
      <pxc-obj-dropdown
        [(ngModel)]="customFieldOption"
        [options]="customFieldOptions"
        name="customFieldOption"
        key="id"
        label="name"
      ></pxc-obj-dropdown>
      <div class="c-helper">Select a Proxyclick custom field</div>
    </div>
    <div class="l-grid__cell l-grid__cell--5">
      <pxc-obj-dropdown
        [(ngModel)]="freeFieldOption"
        [options]="freeFieldOptions"
        name="freeFieldOption"
        key="id"
        label="name"
      ></pxc-obj-dropdown>
      <div class="c-helper">Select a AEOS free field.</div>
    </div>
    <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
      <hl-icon icon="add-24" (click)="addMapping()" [ngClass]="{ 'u-color-neutral-light': !canAddMapping() }"></hl-icon>
    </div>

    <div
      *ngIf="setupState.aeosCustomFieldFreeFieldMappings.length > 0"
      class="l-stack c-box u-padding-all-none u-margin-bottom-s"
    >
      <div class="l-stack__item">
        <div class="c-stacked-list c-stacked-list--s">
          <ul class="c-stacked-list__list">
            <li
              *ngFor="let customFieldMapping of setupState.aeosCustomFieldFreeFieldMappings"
              class="c-stacked-list__item"
            >
              <a
                class="c-stacked-list__inner c-stacked-list__inner--interactive"
                (click)="removeMapping(customFieldMapping.customField.id)"
              >
                <div class="c-stacked-list__inner-start">
                  {{ customFieldMapping.customField.name }} => <code>{{ customFieldMapping.freeField.name }}</code>
                </div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Card Format (Access Card Manager)</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-obj-dropdown
          [(ngModel)]="setupState.aeosCardIdentifierType"
          [options]="cardFormats"
          key="id"
          label="name"
          name="aeosCardIdentifierType"
          allowNull="true"
        ></pxc-obj-dropdown>
      </div>
    </div>
    <div class="c-helper">When using access card manager, this card format will be used</div>
  </div>

  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>
