import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUser } from '@proxyclick/data-model';
import { Subscription, Observable } from 'rxjs';
import { AppStore } from '~/store/store';
import { BatchUpdateUserEmails, IEmailChange, UpdateHomeLocation } from '~/store/users/users.actions';


@Component({
  selector: 'pxc-update-home-location-page',
  templateUrl: './update-home-location.html',
})
export class UpdateHomeLocationPageComponent implements OnInit, OnDestroy {
  users = [{ email: '' }];
  existingUsers: IUser[] = [];
  subs: Subscription = new Subscription();
  errors: {key: string, value: string}[] = [];
  isUpdateLoading$: Observable<boolean>;
  targetCompany: string = "";
  updateHomeLocationModal = {
    open: false,
  };

  constructor(private store: AppStore) {}

  ngOnInit() {
    this.subs.add(this.store.select('utilities', 'updateHomeLocation', 'errors').subscribe(results => { 
      this.targetCompany = '';
      if (!results) return;

      this.toggleUpdateHomeLocationModal();

      const remainingUsers = this.users.filter(x => !results.some(y => x.email == y.email));

      for (const user of remainingUsers) {
        this.removeRow(user.email);
      }

      for (const result of results) {
        this.errors.push({key: result.email, value: result.error})
      }
    }));

    this.isUpdateLoading$ = this.store.select('utilities', 'updateHomeLocation', 'isLoading');
  }

  addEmptyRow() {
    this.users.push({ email: '' });
  }
  
  removeRow(email: string) {
    if (!email) return;

    this.users = this.users.filter(x => x.email != email);

    if(this.users.length == 0)
      this.addEmptyRow();
  }

  onInput(user: IEmailChange, event: any) {
    if (event.inputType === 'insertFromPaste') {
      event.preventDefault();
      const content = event.target.value;
      event.target.value = '';
      this.pasteValues(content);
    }
  }
  
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  updateHomeLocations() {
    this.errors = [];
    const mappedUsers = this.users
      .filter(x => x.email)
      .map(user => { return { email: user.email }});

    if (mappedUsers.length > 0)
    {
      this.store.dispatchAsync(UpdateHomeLocation({ users: mappedUsers, newHomeLocation: this.targetCompany }));
    }
  }

  toggleUpdateHomeLocationModal() {
    this.updateHomeLocationModal = !this.updateHomeLocationModal.open
      ? {
          open: true,
        }
      : {
          open: false,
        };
  }


  private pasteValues(input: string) {
    const users = input.split(' '); // space splitting
    let i = 0;
    for (const user of users) {
      const values = user.split('	'); // tab splitting
      this.users[i] = { email: values[0] };
      i++;
    }
  }
}
