import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppPartnersActions } from '~/store/app-partners/app-partners.reducer';
import { IAppState } from '~/store/app-state';

@Component({
  selector: 'pxc-app-partners',
  templateUrl: './app-partners.html',
})
export class AppPartnersPageComponent implements OnInit {
  partners$ = this.store.select('app-partners', 'value');
  loading$ = this.store.select('app-partners', 'loading');

  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.store.dispatch(AppPartnersActions.Load());
  }
}
