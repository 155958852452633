import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { IAppState } from '~/store/app-state';
import { DomainUpdateAction } from '~/store/domains/domains.reducer';

@Component({
  selector: 'pxc-domain-info',
  templateUrl: './domain-info.html',
})
export class DomainInfoComponent {
  relationshipOptions: IOption[] = [
    { label: 'None', value: 'NONE' },
    { label: 'Established', value: 'ESTABLISHED' },
  ];

  domain$ = this.store.select('domain', 'value');
  loading$ = this.store.select('domain', 'loading');
  loadingUpdate$ = this.store.select('domain/update', 'loading');

  constructor(private store: Store<IAppState>) {}

  updateDomain(domain) {
    this.store.dispatch(DomainUpdateAction.Load(domain));
  }
}
