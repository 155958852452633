import { RouterReducerState } from '@ngrx/router-store';
import { PaginatedOrganisations } from '@proxyclick/api-client';
import {
  IApplication,
  ICompany,
  ICountry,
  IFeatureListMultiPrice,
  IIntegration,
  IJsonData,
  ILanguage,
  ILdapData,
  IMCategory,
  IPartner,
  IPartnerData,
  IRegion,
  IUser,
} from '@proxyclick/data-model';
import {
  IAsyncState,
  ICompanyState,
  ILdapDetailsState,
  IOrganisationState,
  ISearchState,
  IUserState,
} from '~/models/state';
import { IFastPaceState } from '~/store/fastpace/fastpace.state';
import { IRouterStateUrl } from '~/store/router-state-url';
import { IPaginatedCompanies, IPaginatedDomains } from '../../../../shared/filters';
import { IAdminCompany, IAdminUser, IClientConfig, IDomain, UserRight } from '../../../../shared/models';
import { IExtenderState } from './extender/extender.state';
import { IVisitorReference } from './visitors/visitors.reducer';

export const APP_INIT = 'app/app-init';
export const APP_INIT_ACTION = { type: APP_INIT };

export const CONFIG_LOADED = 'config/loaded';
export const CONFIG_LOADED_ACTION = { type: CONFIG_LOADED };

export const USER_LOGGED_IN = 'user/logged-in';
export const USER_LOGGED_IN_ACTION = { type: USER_LOGGED_IN };

export interface IAppState {
  routerReducer: RouterReducerState<IRouterStateUrl>;
  partners: IAsyncState<IPartner[]>;
  partner: IAsyncState<IPartner>;
  integrations: IAsyncState<IIntegration[]>;
  integration: IAsyncState<IIntegration>;
  'admin-users': IAsyncState<IAdminUser[]>;
  categories: IAsyncState<IMCategory[]>;
  category: IAsyncState<IMCategory>;
  regions: IAsyncState<IRegion[]>;
  countries: IAsyncState<ICountry[]>;
  config: IClientConfig;
  company: ICompanyState;
  companies: IAsyncState<IPaginatedCompanies>;
  users: IAsyncState<IUser[]>;
  user: IUserState;
  'recent-users': IAsyncState<IUser[]>;
  rights: IAsyncState<UserRight[]>;
  fastpace: IFastPaceState;
  extender: IExtenderState;
  featureLists: IAsyncState<IFeatureListMultiPrice[]>;
  ldap: IAsyncState<ILdapData[]>;
  json: IAsyncState<IJsonData[]>;
  'json-details': IAsyncState<IJsonData>;
  'ldap-details': ILdapDetailsState;
  'app-partners': IAsyncState<IPartnerData[]>;
  'app-partner': IAsyncState<IPartnerData>;
  languages: IAsyncState<ILanguage[]>;
  search: ISearchState;
  stats: IAsyncState<any>;
  applications: IAsyncState<IApplication[]>;
  application: IAsyncState<IApplication>;
  domains: IAsyncState<IPaginatedDomains>;
  domain: IAsyncState<IDomain>;
  'domain/update': IAsyncState<IDomain>;
  'domain/update-clearbit': IAsyncState<IDomain>;
  'domain-companies': IAsyncState<(ICompany & IAdminCompany)[]>;
  // TODO: NOT BEING USED - REMOVE IN SEPARATE MR
  intercom: IAsyncState<unknown>;
  visitors: IAsyncState<IVisitorReference[]>;
  organisations: IAsyncState<PaginatedOrganisations>;
  organisation: IOrganisationState;
}
