import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RightService } from '~/services/rights.service';
import { UserRight } from '../../../../../../shared/models';

@Directive({
  selector: '[pxcHasRight]',
})
export class HasRightDirective {
  @Input() set pxcHasRight(right: UserRight) {
    if (this.Rights.hasRight(right)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private Rights: RightService
  ) {}
}

@Directive({
  selector: '[pxcHasNoRight]',
})
export class HasNoRightDirective {
  @Input() set pxcHasNoRight(right: UserRight) {
    if (!this.Rights.hasRight(right)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private Rights: RightService
  ) {}
}
