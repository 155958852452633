import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfigService } from '~/services/config.service';
import { IAppState } from '~/store/app-state';
import { IVisitorReference, VisitorsActions } from '~/store/visitors/visitors.reducer';

@Component({
  selector: 'pxc-visitors',
  templateUrl: './visitors.html',
})
export class VisitorsPageComponent {
  visitors$: Observable<IVisitorReference[]> = this.store.select('visitors', 'value');
  loading$ = this.store.select('visitors', 'loading');
  email = '';
  baseUrl = '';

  constructor(private store: Store<IAppState>, private Config: ConfigService) {}

  ngOnInit() {
    this.baseUrl = this.Config.getConfig().app.baseUrl;
  }

  loadVisitors() {
    this.store.dispatch(VisitorsActions.Load({ email: this.email }));
  }
}
