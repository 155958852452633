<div *pxcHasRight='"tab_users"'>
  <div class="flex between">
    <form class="form-inline u-width-100% mb-2" (ngSubmit)="getUsers(1)">
      <input
        placeholder="Filter users..."
        class="form-control mr-2"
        type="text"
        [(ngModel)]="searchTerm"
        name="searchTerm"
      />
      <pxc-button [onClick]="searchUsers()">Filter</pxc-button>
      <div class="u-margin-left-auto"><hl-button size="s" (click)="linkUsersModalVisible = true">Add Users</hl-button></div>
    </form>
  </div>
  <table class="c-table mt-4 mb-2">
    <thead>
      <th>
        Admins
        <span *ngIf="users">({{users.length}})</span>
      </th>
      <th>Language</th>
      <th>Email</th>
      <th>Global Admin</th>
    </thead>
    <tbody *ngIf="!(loadingUsers$ | async) && (users$ | async)">
      <tr *ngFor="let user of (users$ | async).users">
        <td>
          <div class="u-flex u-flex-row">
            <div id="unlinkUser-{{user.id}}">
              <a
                *pxcHasRight="'log_as_user'"
                class="c-icon-action u-color-danger u-margin-right-s"
                (click)="toggleUnlinkModal(user.id)"
                [ngClass]="u-color-danger"
              >
                <span class="c-icon c-icon--m">
                  <hl-icon icon="remove-circle--color-24"></hl-icon>
                  <ng-template #unlinkAble>
                    <hl-icon icon="remove-circle-24"></hl-icon>
                  </ng-template>
                </span>
              </a>
            </div>
            <hl-tooltip element="#unlinkUser-{{user.id}}"> Unlink user form organisation </hl-tooltip>
            <a routerLink="/users/{{user.id}}">{{user.firstname}} {{user.lastname}}</a>
            <i class="fas fa-user ml-2"></i>
          </div>
        </td>
        <td><span class="badge badge-secondary">{{user.language.code.toUpperCase()}}</span></td>
        <td>{{user.email}}</td>
        <td>
          <pxc-switch [(ngModel)]="user.isGlobalAdmin" [onChange]="setGlobalAdmin(user)"> </pxc-switch>
        </td>
      </tr>
    </tbody>
  </table>
  <pxc-loading [loading]="loadingUsers$ | async"></pxc-loading>
  <ngb-pagination
    *ngIf="filter &&  (users$ | async) && (users$ | async).total > (users$ | async).limit"
    [collectionSize]="(users$ | async).total"
    [(page)]="filter.page"
    [pageSize]="filter.pageSize"
    [maxSize]="10"
    [rotate]="true"
    [boundaryLinks]="true"
    (pageChange)="onPageChange($event)"
  ></ngb-pagination>
</div>

<hl-modal
  *ngIf="unlinkModal.open"
  class="hl-modal"
  [isOpen]="true"
  [heading]="'Unlink User'"
  (close)="toggleUnlinkModal()"
>
  <div class="pxc-settings__subheading u-margin-bottom-gutter">
    Are you sure you want to unlink user {{unlinkModal.userId}} from this organisation?
  </div>
  <footer class="hl-modal__footer u-margin-top-s">
    <div class="c-buttons-group" style="margin-top: -10px">
      <hl-button size="small" type="primary" (click)="unlinkUserFromOrganisation(unlinkModal.userId)">Unlink</hl-button>
      <hl-button size="small" type="secondary" (click)="toggleUnlinkModal()">Close</hl-button>
    </div>
  </footer>
</hl-modal>

<hl-modal
  *ngIf="linkUsersModalVisible"
  class="hl-modal"
  [isOpen]="true"
  heading="Add company"
  (close)="linkUsersModalVisible = false"
>
  <label class="c-label c-label--s" for="new-org-companies"
    >List of user ids to be linked (e.g. US-B101, 1 per line):</label
  >
  <textarea
    class="c-textarea u-text-xs"
    id="new-org-companies"
    [(ngModel)]="userIdsToLink"
    style="height: 300px"
    [disabled]="isUsersSaving"
  ></textarea>
  <div class="u-flex u-flex-align-items-center u-margin-top-s" *ngIf="isUsersSaving">
    <hl-spinner></hl-spinner>
    <span class="u-margin-left-s">Saving ...</span>
  </div>
  <footer class="u-margin-top-s">
    <div class="c-buttons-group">
      <hl-button size="small" type="primary" (click)="linkUsers()" [disabled]="isUsersSaving">Add</hl-button>
      <hl-button size="small" type="secondary" (click)="linkUsersModalVisible = false" [disabled]="isUsersSaving"
        >Close</hl-button
      >
    </div>
  </footer>
</hl-modal>
