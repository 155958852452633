import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';
import { AuthService } from '~/services/auth.service';
import { ConfigService } from '~/services/config.service';
import { JWTService } from '~/services/jwt.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { IAppState, USER_LOGGED_IN_ACTION } from '~/store/app-state';

@Component({
  selector: 'pxc-login',
  templateUrl: './login.html',
  styleUrls: ['../auth.scss'],
})
export class LoginPageComponent implements OnInit {
  loading = false;
  email;
  password;
  forgot;

  constructor(
    private store: Store<IAppState>,
    private Auth: AuthService,
    private Notifications: NotificationsService,
    private router: Router,
    private JWT: JWTService,
    private config: ConfigService
  ) {}

  ngOnInit() {}

  onSubmit(username: string, password: string) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.Auth.auth(username, password)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        token => {
          if (!token) {
            return this.Notifications.error('Invalid credentials');
          }
          this.JWT.setJWT(token.access_token);
          this.store.dispatch(USER_LOGGED_IN_ACTION);
          this.router.navigate(['/companies']).then(v => {
            if (!v) {
              this.router.navigate(['/']);
            }
          });
        },
        err => {
          return this.Notifications.error('Invalid credentials');
        }
      );
  }

  resetPassword(email: string) {
    return this.Auth.resetPassword(email).subscribe(() => this.Notifications.success('Link sent'));
  }

  loginWithSSO() {
    window.location.href = `https://login.microsoftonline.com/e763253c-1fce-441a-9cfe-6390efc369a5/oauth2/v2.0/authorize?
                client_id=${this.config.getConfig().azureCredentials.client_id}
                &scope=https://graph.microsoft.com/User.Read
                &response_type=code
                &redirect_uri=${this.config.getConfig().azureCredentials.redirect_uri}`;
  }
}
