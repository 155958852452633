<div class="u-margin-top-s">
  <pxc-breadcrumb [breadcrumbs]="breadcrumbs$ | async"></pxc-breadcrumb>
  <h4>New Admin User</h4>
  <form #form="ngForm" (ngSubmit)="createAdminUser(form)" *ngIf="!adminUserId">
    <pxc-loading [loading]="rightsLoading"></pxc-loading>
    <div class="l-grid u-margin-bottom-m" *ngIf="!rightsLoading">
      <div class="l-grid__cell l-grid__cell--6">
        <div class="l-grid__cell l-grid__cell--10 u-margin-right-l">
          <label for="firstName" class="c-label">First Name </label>
          <input
            class="c-input c-input--s"
            type="text"
            name="firstName"
            ngModel
            id="firstName"
            placeholder="First Name"
          />
        </div>
        <div class="l-grid__cell l-grid__cell--10 u-margin-right-l">
          <label for="lastName" class="c-label">Last Name </label>
          <input class="c-input c-input--s" type="text" name="lastName" ngModel id="lastName" placeholder="Last Name" />
        </div>
        <div class="l-grid__cell l-grid__cell--10">
          <label for="email" class="c-label">Email </label>
          <input class="c-input c-input--s" type="email" name="email" ngModel id="email" placeholder="Email" />
        </div>
        <div class="l-grid__cell l-grid__cell--10">
          <label for="team" class="c-label">Team </label>
          <select class="c-select c-select--s" name="label" id="label" (change)="chooseTeam($event.target.value)">
            <option disabled="" selected="">Choose a team</option>
            <option *ngFor="let team of rightsTeams | keyvalue; index as i">{{team.key}}</option>
          </select>
        </div>
        <div class="l-grid__cell l-grid__cell--10 u-margin-top-l">
          <button class="c-button c-button--s u-margin-top-m" type="submit">Create Admin User</button>
        </div>
      </div>
      <div class="l-grid__cell l-grid__cell--3">
        <label class="c-label">
          <strong>User Rights</strong>
        </label>
        <div class="l-grid u-flex u-flex-align-items-center u-margin-left-2xs" *ngFor="let right of rights; index as i">
          <input
            id="example-checkbox-base-{{i}}"
            name="{{right.name}}"
            (change)="checkRights($event.target.checked, right.name)"
            type="checkbox"
            class="c-checkbox"
            [checked]="right.checked ? 'checked' : null"
          />
          <label for="example-checkbox-base-{{i}}" class="c-checkbox__skin"></label>
          <label for="example-checkbox-base-{{i}}" class="c-label c-label--checkbox">{{right.name}}</label>
        </div>
      </div>
    </div>
  </form>

  <form #updateForm="ngForm" (ngSubmit)="updateAdminUser(updateForm)" *ngIf="adminUser$ | async as adminUser">
    <pxc-loading [loading]="adminUserLoading && rightsLoading"></pxc-loading>
    <div class="l-grid u-margin-bottom-m" *ngIf="!adminUserLoading && !rightsLoading">
      <div class="l-grid__cell l-grid__cell--6">
        <div class="l-grid__cell l-grid__cell--10 u-margin-right-l">
          <label for="firstName" class="c-label">First Name </label>
          <input
            class="c-input c-input--s"
            type="text"
            name="firstName"
            [(ngModel)]="adminUser.firstName"
            id="firstName"
            placeholder="First Name"
          />
        </div>
        <div class="l-grid__cell l-grid__cell--10 u-margin-right-l">
          <label for="lastName" class="c-label">Last Name </label>
          <input
            class="c-input c-input--s"
            type="text"
            name="lastName"
            [(ngModel)]="adminUser.lastName"
            id="lastName"
            placeholder="Last Name"
          />
        </div>
        <div class="l-grid__cell l-grid__cell--10">
          <label for="email" class="c-label">Email </label>
          <input
            class="c-input c-input--s"
            type="email"
            name="email"
            [(ngModel)]="adminUser.email"
            id="email"
            placeholder="Email"
          />
        </div>
        <div class="l-grid__cell l-grid__cell--10">
          <label for="team" class="c-label">Team </label>
          <select class="c-select c-select--s" name="label" id="label" (change)="chooseTeam($event.target.value)">
            <option disabled="" selected="">Choose a team</option>
            <option *ngFor="let team of rightsTeams | keyvalue; index as i">{{team.key}}</option>
          </select>
        </div>
        <div class="l-grid__cell l-grid__cell--10 u-margin-top-l">
          <button class="c-button c-button--s u-margin-top-m" type="submit">Update Admin User</button>
        </div>
      </div>
      <div class="l-grid__cell l-grid__cell--3">
        <label class="c-label">
          <strong>User Rights</strong>
        </label>
        <div class="l-grid u-flex u-flex-align-items-center u-margin-left-2xs" *ngFor="let right of rights; index as i">
          <input
            id="example-checkbox-base-{{i}}"
            name="{{right.name}}"
            (change)="checkRights($event.target.checked, right.name)"
            type="checkbox"
            class="c-checkbox"
            [checked]="right.checked ? 'checked' : null"
          />
          <label for="example-checkbox-base-{{i}}" class="c-checkbox__skin"></label>
          <label for="example-checkbox-base-{{i}}" class="c-label c-label--checkbox">{{right.name}}</label>
        </div>
      </div>
    </div>
  </form>
</div>
