import { of as observableOf, Observable } from 'rxjs';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICompany } from '@proxyclick/data-model';
import * as moment from 'moment';
import { IAdminStatsFilter } from '~/services/stats.service';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { IAppState } from '~/store/app-state';
import { StatsActions } from '~/store/stats/stats.reducer';

const statuses = [
  'ALL',
  'SUGGESTED',
  'IN_TRIAL',
  'INVOICED',
  'INVOICED_OTHER_COMPANY',
  'CANCELLED',
  'HIDDEN',
  'BLOCKED',
];
const modules = ['ALL', 'VM', 'SD'];

@Component({
  selector: 'pxc-stats',
  templateUrl: './stats.html',
})
export class StatsPageComponent {
  stats$ = this.store.select('stats', 'value');
  loading$ = this.store.select('stats', 'loading');

  breadcrumbs$ = observableOf([{ label: 'Stats', url: '/stats' }]);

  columns: IOption[] = [
    { label: 'Name', value: 'name' },
    { label: 'Visits', value: 'visitor' },
    { label: 'Tickets', value: 'ticket' },
    { label: 'Transactions', value: 'total' },
    { label: 'Sms visitors', value: 'smsVisitors' },
    { label: 'Sms tickets', value: 'smsTickets' },
    { label: 'Sms total', value: 'smsTotal' },
    { label: 'Acuant calls', value: 'acuantDuplex' },
  ];

  statuses = statuses;
  modules = modules;

  status = statuses[0];
  module = modules[0];
  company: ICompany;
  month: moment.Moment = moment();

  filter: IAdminStatsFilter = {
    date: moment().format('YYYY-MM'),
    pageSize: 1000,
    sortBy: 'name',
    sort: 'asc',
  };

  constructor(private store: Store<IAppState>) {}

  getStats() {
    this.filter.date = this.month.format('YYYY-MM');
    if (this.status !== this.statuses[0]) {
      this.filter.vmStatus = this.status;
    }
    if (this.company) {
      this.filter.companyId = this.company.id;
    }
    if (this.module !== this.modules[0]) {
      this.filter.module = this.module;
    }
    this.store.dispatch(StatsActions.Load(this.filter));
  }

  onFilterChange(filter) {
    this.filter = filter;
    this.getStats();
  }
}
