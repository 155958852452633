<div *ngIf="ac2000Settings">
  <form (submit)="submit()">
    <div class="l-form__field">
      <label class="c-label">Connection ID</label>
      <input
        type="text"
        class="c-input c-input--s"
        [(ngModel)]="ac2000Settings.connectionId"
        name="connectionId"
        disabled=""
      />
    </div>
    <hr />
    <div class="l-form__field">
      <label class="c-label">Device address IN filter</label>
      <div class="l-grid">
        <div class="l-grid__cell l-grid__cell--11">
          <pxc-obj-dropdown
            [(ngModel)]="deviceOptionIn"
            [options]="deviceOptions"
            name="deviceOptionIn"
            key="deviceAddress"
            label="deviceDescription"
          ></pxc-obj-dropdown>
        </div>
        <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
          <hl-icon
            icon="add-24"
            (click)="addInMapping()"
            [ngClass]="{ 'u-color-neutral-light': !canAddInMapping() }"
          ></hl-icon>
        </div>
      </div>
      <div class="c-helper">
        To allow visitor events from AC2000 to be sent to our servers please select at least one device for IN
        movements.
      </div>
    </div>
    <div class="l-form__field">
      <label class="c-label">Device address OUT filter</label>
      <div class="l-grid">
        <div class="l-grid__cell l-grid__cell--11">
          <pxc-obj-dropdown
            [(ngModel)]="deviceOptionOut"
            [options]="deviceOptions"
            name="deviceOptionOut"
            key="deviceAddress"
            label="deviceDescription"
          ></pxc-obj-dropdown>
        </div>
        <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
          <hl-icon
            icon="add-24"
            (click)="addOutMapping()"
            [ngClass]="{ 'u-color-neutral-light': !canAddOutMapping() }"
          ></hl-icon>
        </div>
      </div>
      <div class="c-helper">
        To allow visitor events from AC2000 to be sent to our servers please select at least one device for OUT
        movements.
      </div>
    </div>

    <div
      *ngIf="deviceFilterIn.length > 0 || deviceFilterOut.length > 0"
      class="l-stack c-box u-padding-all-none u-margin-bottom-s"
    >
      <div class="l-stack__item">
        <div class="c-stacked-list c-stacked-list--s">
          <ul class="c-stacked-list__list">
            <li *ngFor="let deviceInFilter of deviceFilterIn" class="c-stacked-list__item">
              <a
                class="c-stacked-list__inner c-stacked-list__inner--interactive"
                (click)="removeInMapping(deviceInFilter.deviceAddress)"
              >
                <div class="c-stacked-list__inner-start">IN: {{ deviceInFilter.deviceDescription }}</div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
            <li *ngFor="let deviceOutFilter of deviceFilterOut" class="c-stacked-list__item">
              <a
                class="c-stacked-list__inner c-stacked-list__inner--interactive"
                (click)="removeOutMapping(deviceOutFilter.deviceAddress)"
              >
                <div class="c-stacked-list__inner-start">OUT: {{ deviceOutFilter.deviceDescription }}</div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <button type="submit" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
      Save settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
      <hl-icon
        *ngIf="submitting === false && errors === undefined"
        icon="checkmark-circle--color-24"
        width="24"
        height="24"
      ></hl-icon>
    </button>
  </form>
  <h3 *ngIf="errors">Errors during update</h3>
  <div class="u-color-danger" *ngIf="errors">
    <h4>{{ errors.title }}</h4>
    <dl>
      <ng-container *ngFor="let item of errors.errors | keyvalue">
        <dt>{{ item.key }}</dt>
        <dt>
          <ul>
            <li *ngFor="let errorItem of item.value">
              {{ errorItem }}
            </li>
          </ul>
        </dt>
      </ng-container>
    </dl>
    Trace id <code>{{ errors.traceId }}</code>
  </div>
</div>
