import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company } from '@proxyclick/api-client';
import { DialogService } from '~/shared/components/dialog/dialog.service';
import {
  OrganisationCompaniesActions,
  OrganisationCompaniesAddActions,
  OrganisationCompaniesDeleteActions,
} from '~/store/organisation/organisation.actions';
import { AppStore } from '~/store/store';

@Component({
  selector: 'pxc-organisation-companies',
  templateUrl: './organisation-companies.html',
})
export class OrganisationCompaniesComponent implements OnInit {
  companies$ = this.store.select('organisation', 'companies', 'value');
  loadingCompanies$ = this.store.select('organisation', 'companies', 'loading');

  addCompanyModalVisible = false;
  newCompanyIds: string = '';
  isCompaniesSaving = false;

  private organisationId: string;

  constructor(private store: AppStore, private route: ActivatedRoute, private dialog: DialogService) {}

  ngOnInit(): void {
    this.organisationId = this.route.parent.snapshot.params.id;
    this.store.dispatch(OrganisationCompaniesActions.Load());
  }

  deleteCompany(company: Company) {
    this.dialog.confirm('Unlink company', `Are you sure you want to unlink ${company.name}?`).then(result => {
      if (result) {
        this.store.dispatchAsync(
          OrganisationCompaniesDeleteActions.Save({
            organisationId: this.organisationId,
            companyId: company.id,
          })
        );
      }
    });
  }

  addCompany() {
    this.isCompaniesSaving = true;
    const companyIds = this.newCompanyIds
      .split('\n')
      .map(d => d.trim())
      .filter(Boolean);

    if (companyIds.length === 0) {
      return;
    }

    this.store
      .dispatchAsync(OrganisationCompaniesAddActions.Save({ organisationId: this.organisationId, companyIds }))
      .then(() => {
        this.addCompanyModalVisible = false;
        this.newCompanyIds = '';
        this.isCompaniesSaving = false;
      });
  }
}
