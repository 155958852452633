import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { OrganisationsService } from '~/services/organisations.service';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';
import { OrganisationsActions } from './organisations.actions';

@Injectable()
export class OrganisationsEffects {
  constructor(private actions$: Actions<PayloadAction>, private Organisations: OrganisationsService) {}

  loadAll$ = createEffect(() =>
    generateAsyncEffect(this.actions$, OrganisationsActions, filterData => this.Organisations.getAll(filterData))
  );
}
