import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRight } from '../../../../shared/models';
import { JWTService } from './jwt.service';

@Injectable()
export class RightService {
  private rights: string[] = [];

  constructor(private JWT: JWTService, private http: HttpClient) {}

  getRights() {
    if (!this.rights || this.rights.length === 0) {
      this.rights = [];
      const jwt = this.JWT.getParsedJWT();
      if (!jwt) {
        return [];
      }
      this.rights = jwt.rights;
    }
    return this.rights;
  }

  getAllRights(): Observable<UserRight[]> {
    return this.http.get<UserRight[]>('/admin/rights');
  }

  hasRight(right: UserRight) {
    return this.getRights() && this.getRights().includes(right);
  }

  clearCache() {
    this.rights = null;
  }
}
