<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      {{message}}
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="c-button c-button--primary c-button--s" (click)="c(true)">Yes</button>
    <button type="button" class="btn btn-outline-primary" (click)="c(false)">No</button>
  </div>
</ng-template>
