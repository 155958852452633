import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { createAction } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IQueryablePaginationFilter } from '~/models/filters';
import { IExtenderPaginatedResponse, IExtenderResponseV1 } from '~/store/extender/extender.models';
import { ExtenderActions } from '~/store/extender/extender.reducer';
import { ExtenderSelector } from '~/store/extender/extender.selectors';
import { AppStore } from '~/store/store';

@Component({
  selector: 'app-extenders',
  templateUrl: './extenders.component.html',
})
export class ExtendersComponent implements OnInit, OnDestroy {
  static Loaded = createAction('ExtendersComponent/Loaded');

  filter: IQueryablePaginationFilter;
  extenders$ = this.store.select<IExtenderPaginatedResponse<IExtenderResponseV1>>(ExtenderSelector.getExtenders);
  loading$ = this.store.select(ExtenderSelector.getExtendersLoading);
  error$ = this.store.select(ExtenderSelector.getExtendersError);

  total: number;
  limit: number;
  extenderFilterSubscription: Subscription;
  constructor(private store: AppStore, private router: Router) {}

  ngOnInit(): void {
    this.filter = {
      q: '',
      page: 1,
      pageSize: 20,
    };
    this.extenderFilterSubscription = this.extenders$.pipe(filter(a => !!a)).subscribe(extenders => {
      this.total = extenders.total;
      this.limit = this.filter.pageSize;
    });
  }

  ngOnDestroy(): void {
    this.extenderFilterSubscription.unsubscribe();
  }

  extenderStatus(extender: IExtenderResponseV1): string {
    if (!extender.hasBeenConnected && extender.offline) {
      // extender.offline is a datetime value (not boolean)
      return 'Offline (' + formatDate(extender.offline, 'medium', 'en-US', 'UTC') + ' UTC)';
    } else if (!extender.hasBeenConnected) {
      return 'Offline';
    }

    return 'Online';
  }

  loadWithFilter() {
    return () => {
      this.filter.page = 1;
      return this.loadExtenders();
    };
  }

  private loadExtenders() {
    return this.store.dispatchAsync(ExtenderActions.Extenders.Load(this.filter));
  }

  onPageChange(page) {
    this.filter.page = page;
    this.loadExtenders();
  }

  onExtenderClick(extenderId: string) {
    this.router.navigate(['fastpace', 'extenders', extenderId]);
  }
}
