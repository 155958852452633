<div class="c-card__section">
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Copy settings from another company</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-switch [(ngModel)]="copySettings" name="copySettings" *ngIf="setupState.extender"></pxc-switch>
        <div *ngIf="!setupState.extender">
          <input type="checkbox" class="c-switch c-switch--locked" disabled="" [checked]="false" />
          <label for="example-switch-locked-on" class="c-switch__skin"></label>
        </div>
      </div>
    </div>
  </div>

  <ul class="c-list c-list--bare" *ngIf="copySettings">
    <li class="u-margin-top-m">
      <div>
        <input
          [checked]="copyMode.value === 'randomextender'"
          (change)="changeCopyMode('randomextender')"
          id="randomextender"
          name="copyMode"
          type="radio"
          class="c-radio"
          [disabled]="companyOnExtenderCount < 1"
        />
        <label for="randomextender" class="c-radio__skin"></label>
        <label for="randomextender" class="c-label c-label--radio">Select a random company on the extender</label>
      </div>
      <div class="c-helper text-warning" *ngIf="companyOnExtenderCount < 1">
        There are no companies on this extender, no random company can be copied.
      </div>
    </li>
    <li class="u-margin-top-m">
      <div>
        <input
          [checked]="copyMode.value === 'search'"
          (change)="changeCopyMode('search')"
          id="search"
          name="copyMode"
          type="radio"
          class="c-radio"
        />
        <label for="search" class="c-radio__skin"></label>
        <label for="search" class="c-label c-label--radio">Search a company</label>
        <div class="c-input c-input--s u-margin-bottom-s u-margin-left-m u-margin-right-m">
          <div class="c-input__prefix c-input__prefix--shift">
            <span class="c-icon c-icon--s">
              <hl-icon icon="search-24"></hl-icon>
            </span>
          </div>
          <input
            [(ngModel)]="companySearchTerm"
            (focus)="changeCopyMode('search')"
            class="c-input c-input--s c-input--naked"
            type="text"
            placeholder="Search by name or id"
          />
          <div class="c-input__suffix">
            <button class="c-action" tabindex="" (click)="searchCompany()" type="button">
              <span class="c-action__label">Search</span>
            </button>
          </div>
        </div>
        <div class="c-helper text-warning" *ngIf="copyMode.value === 'search' && companySearchTerm && companies.length < 1">
          Did not find any companies, try adjusting the search
        </div>
        <div class="l-stack c-box u-padding-all-none u-margin-left-m" *ngIf="companies.length > 0">
          <div class="l-stack__item">
            <div class="c-stacked-list c-stacked-list--s">
              <ul class="c-stacked-list__list">
                <li *ngFor="let company of companies" class="c-stacked-list__item">
                  <a class="c-stacked-list__inner c-stacked-list__inner--interactive" (click)="selectCompany(company)">
                    <div class="c-stacked-list__inner-start">
                      {{ company.companyName }}
                    </div>
                    <div class="c-stacked-list__inner-end">
                      <span class="c-icon c-icon--m">
                        <hl-icon
                          icon="checkmark-24"
                          width="24"
                          height="24"
                          *ngIf="company.companyId === setupState.copySettingsFromCompany?.companyId"
                        ></hl-icon>
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>
