import { Pipe, PipeTransform } from '@angular/core';
import { _ } from '../../libs';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(items: any[]) {
    return _.sortBy(items, ['order', 'name']);
  }
}
