import { Component, OnInit } from '@angular/core';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';
import { FastPaceService } from '~/services/fastpace.service';
import { CompaniesService } from '~/services/companies.service';
import { ICustomField } from '@proxyclick/data-model';
import { ExtenderService } from '~/services/extender.service';
import {
  CardFormatsCardFormatEntity,
  CustomFieldsCustomFieldEntity,
  ReadersReaderEntity,
} from '@proxyclick/extender-server-openpath-client';
import { ControllersCompaniesOpenPathCompanySettingsResponseV1 } from '@proxyclick/fastpace-client';

@Component({
  selector: 'app-fastpace-company-openpath',
  templateUrl: './fastpace.company.openpath.component.html',
})
export class FastPaceCompanyOpenPathComponent extends FastPaceBaseSystemSettingsComponent implements OnInit {
  constructor(
    fastpaceService: FastPaceService,
    companyService: CompaniesService,
    protected extenderService: ExtenderService
  ) {
    super(fastpaceService, companyService, extenderService);
  }
  async ngOnInit(): Promise<void> {
    this.openPathSettings = await this.fastpaceService.getOpenPathSettings(this.fastpaceCompany.companyId);

    this.cardFormats = await this.extenderService.openpath.cardFormats.listAll(
      this.fastpaceCompany.extenderId,
      this.openPathSettings.organizationId
    );
    this.openPathCustomFields = await this.extenderService.openpath.customFields.listAll(
      this.fastpaceCompany.extenderId,
      this.openPathSettings.organizationId
    );
    this.readers = await this.extenderService.openpath.readers.listAll(
      this.fastpaceCompany.extenderId,
      this.openPathSettings.organizationId
    );

    this.customFields = await this.companyService.getCustomFields(this.fastpaceCompany.companyId).toPromise();

    this.setInitialState();
    this.loading = false;
  }

  //current settings
  openPathSettings: ControllersCompaniesOpenPathCompanySettingsResponseV1 | null = null;
  customFieldMappings: Array<{ customField: ICustomField; openPathCustomField: CustomFieldsCustomFieldEntity }> = [];

  cardFormats: CardFormatsCardFormatEntity[];
  qrCodeCardFormatOption: CardFormatsCardFormatEntity | null = null;
  cardCardFormatOption: CardFormatsCardFormatEntity | null = null;
  facilityCode: number | null = null;

  mapCustomFields: boolean;

  openPathCustomFields: CustomFieldsCustomFieldEntity[];
  openPathCustomFieldOptions: CustomFieldsCustomFieldEntity[] = [];
  openPathCustomFieldOption: CustomFieldsCustomFieldEntity | null = null;

  customFields: ICustomField[];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  readers: ReadersReaderEntity[];
  readerOptions: ReadersReaderEntity[] = [];
  readerOptionIn: ReadersReaderEntity | null = null;
  readerOptionOut: ReadersReaderEntity | null = null;

  readerFilterIns: ReadersReaderEntity[] = [];
  readerFilterOuts: ReadersReaderEntity[] = [];
  loading = true;

  setInitialState() {
    this.facilityCode = this.openPathSettings.facilityCode;
    if (
      this.openPathSettings != null &&
      this.cardFormats != null &&
      this.cardFormats.length > 0 &&
      !this.qrCodeCardFormatOption
    ) {
      this.qrCodeCardFormatOption = { id: this.openPathSettings.qrCodeCardFormatId };
      this.cardCardFormatOption = this.openPathSettings.cardFormatId
        ? { id: this.openPathSettings.cardFormatId }
        : null;
    }
    if (
      this.openPathSettings != null &&
      this.customFields != null &&
      this.customFields.length > 0 &&
      this.openPathCustomFields != null &&
      this.openPathCustomFields.length > 0
    ) {
      this.mapCustomFields = this.openPathSettings.customFieldMappings.length > 0;
      this.customFieldMappings = this.openPathSettings.customFieldMappings.map(c => {
        const customField = this.customFields.filter(cf => cf.id === c.customFieldId)[0];
        const openPathCustomField = this.openPathCustomFields.filter(cf => cf.id === c.openPathCustomFieldId)[0];
        return { customField: customField, openPathCustomField: openPathCustomField };
      });

      this.setOpenPathCustomFieldOptions();
      this.setCustomFieldOptions();
    }

    if (this.openPathSettings != null && this.readers != null && this.readers.length > 0) {
      this.readerFilterIns = this.readers.filter(
        x => this.openPathSettings.readerFilterIn.filter(y => y == x.id).length === 1
      );
      this.readerFilterOuts = this.readers.filter(
        x => this.openPathSettings.readerFilterOut.filter(y => y == x.id).length === 1
      );

      this.setReaderOptions();
    }
  }

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.customFieldMappings = [];
    }
  }

  setReaderOptions() {
    const options = this.readers.filter(c => {
      //Filter out access points assigned
      return (
        this.readerFilterIns.filter(a => a.id === c.id).length === 0 &&
        this.readerFilterOuts.filter(a => a.id == c.id).length === 0
      );
    });

    this.readerOptions = options;
    if (this.readerOptions.length > 0) {
      this.readerOptionIn = this.readerOptions[0];
      this.readerOptionOut = this.readerOptions[0];
    } else {
      this.readerOptionIn = null;
      this.readerOptionOut = null;
    }
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return this.customFieldMappings.filter(a => a.customField.id === c.id).length === 0;
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = null;
    }
  }

  setOpenPathCustomFieldOptions() {
    const options = this.openPathCustomFields.filter(c => {
      //Filter out custom fields already mapped
      return this.customFieldMappings.filter(a => a.openPathCustomField.id === c.id).length === 0;
    });

    this.openPathCustomFieldOptions = options;
    if (this.openPathCustomFieldOptions.length > 0) {
      this.openPathCustomFieldOption = this.openPathCustomFieldOptions[0];
    } else {
      this.openPathCustomFieldOption = undefined;
    }
  }

  canAddMapping() {
    return (
      this.customFieldOption &&
      this.openPathCustomFieldOption &&
      this.customFieldMappings.filter(
        c =>
          c.customField.id === this.customFieldOption.id ||
          c.openPathCustomField.id == this.openPathCustomFieldOption.id
      ).length === 0
    );
  }
  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }

    this.customFieldMappings.push({
      customField: this.customFieldOption,
      openPathCustomField: this.openPathCustomFieldOption,
    });
    this.setOpenPathCustomFieldOptions();
    this.setCustomFieldOptions();
  }

  removeMapping(id: number) {
    this.customFieldMappings = this.customFieldMappings.filter(c => c.customField.id !== id);
    this.setOpenPathCustomFieldOptions();
    this.setCustomFieldOptions();
  }

  canAddInMapping() {
    return this.readerOptionIn && this.readerFilterIns.filter(d => d.id === this.readerOptionIn.id).length < 1;
  }
  canAddOutMapping() {
    return this.readerOptionOut && this.readerFilterOuts.filter(d => d.id === this.readerOptionOut.id).length < 1;
  }
  addInMapping() {
    if (this.canAddInMapping()) {
      this.readerFilterIns.push(this.readerOptionIn);
      this.setReaderOptions();
    }
  }

  addOutMapping() {
    if (this.canAddOutMapping()) {
      this.readerFilterOuts.push(this.readerOptionOut);
      this.setReaderOptions();
    }
  }

  removeInMapping(readerId: number) {
    this.readerFilterIns = this.readerFilterIns.filter(d => d.id !== readerId);
    this.setReaderOptions();
  }
  removeOutMapping(readerId: number) {
    this.readerFilterOuts = this.readerFilterOuts.filter(d => d.id !== readerId);
    this.setReaderOptions();
  }

  get canSubmit(): boolean {
    return this.submitting !== true;
  }

  async doUpdate(): Promise<void> {
    //No update at the moment
    return this.fastpaceService.updateOpenPathSettings(this.fastpaceCompany.companyId, {
      qrCodeCardFormatId: this.qrCodeCardFormatOption.id,
      customFieldMappings: this.customFieldMappings.map(c => {
        return { customFieldId: c.customField.id, openPathCustomFieldId: c.openPathCustomField.id };
      }),
      readerFilterIn: this.readerFilterIns.map(c => c.id),
      readerFilterOut: this.readerFilterOuts.map(c => c.id),
      cardFormatId: this.cardCardFormatOption ? this.cardCardFormatOption.id : null,
      facilityCode: this.facilityCode,
    });
  }
}
