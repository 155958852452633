import { createAsyncLoadActions, createAsyncLoadReducer } from '~/utils/async-generator';

export interface IVisitorReference {
  companyId: string;
  companyName: string;
  visitorId: string;
  firstname: string;
  lastname: string;
  url: string;
}

export const VisitorsActions = createAsyncLoadActions<{ email: string }, IVisitorReference>('visitors');
export const visitorsReducer = createAsyncLoadReducer(VisitorsActions);
