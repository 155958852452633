import { Component, Input, OnInit } from '@angular/core';
import { FastPaceSetupCompanyState } from '../fastpace-setup-company.state';
import { FastPaceService } from '~/services/fastpace.service';
import { ErrorResponse } from '~/store/fastpace/fastpace.models';

@Component({
  selector: 'app-fastpace-company-setup-submit',
  templateUrl: './fastpace-setup-submit.component.html',
})
export class FastPaceSetupSubmitComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;
  loading: boolean = true;
  success: boolean = false;
  errors: ErrorResponse;
  constructor(private fastPaceService: FastPaceService) {}
  ngOnInit(): void {
    this.fastPaceService
      .create(this.setupState)
      .then(() => {
        this.loading = false;
        this.success = true;
      })
      .catch(error => {
        this.loading = false;
        if (error && error.statusCode < 500) {
          var errorResponse = error.details as ErrorResponse;
          this.errors = errorResponse;
        } else {
          this.errors = {
            title: error.message,
            errors: undefined,
            status: error.statusCode,
            traceId: error.headers?.get('traceId'),
            type: 'Failure',
          };
        }
      });
  }
}
