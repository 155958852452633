<div *pxcHasRight="'tab_fastpace'">
  <div class="flex between">
    <div class="form-inline f-m-h-1">
      <input
        class="form-control mr-2"
        type="text"
        [(ngModel)]="filter.q"
        name="search"
        placeholder="Filter extenders..."
      />
      <pxc-button [onClick]="loadWithFilter()">Filter</pxc-button>
    </div>
    <div class="flex center f-m-h-1">
      <a class="c-button c-button--primary c-button--s" [routerLink]="['new']">
        <i class="fa fa-plus"></i><span class="u-margin-left-2xs">Install</span>
      </a>
    </div>
  </div>
  <table *ngIf="!(loading$ | async) && !(error$ | async) && (extenders$ | async)" class="c-table mt-4">
    <thead>
      <tr>
        <th>Description</th>
        <th>Type</th>
        <th>Version</th>
        <th>Client version</th>
        <th>Status</th>
        <th>Extender Id</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let extender of (extenders$ | async).items"
        class="table-row pointer"
        [ngClass]="{ 'inactive': extender.offline }"
        (click)="onExtenderClick(extender.extenderId)"
      >
        <td>
          <a class="color-primary" routerLink="{{ extender.extenderId }}">{{ extender.description }}</a>
        </td>
        <td>{{ extender.type }}</td>
        <td>{{ extender.version }}</td>
        <td>
          <span *ngIf="extender.client">
            {{ extender.client.version }}
            <span *ngIf="!extender.client.isRunningLatestVersion" class="c-icon c-icon--s" title="Outdated">
              <hl-icon icon="danger-24" class="text-warning"></hl-icon>
            </span>
          </span>
        </td>
        <td>
          <span [ngClass]="{ 'text-danger': extender.offline }">{{ extenderStatus(extender) }}</span>
        </td>
        <td class="nowrap">{{ extender.extenderId }}</td>
        <td (click)="$event.stopPropagation()">
          <app-extender-seq-link
            [extenderId]="extender.extenderId"
            [clientId]="extender.client?.clientId"
          ></app-extender-seq-link>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="mt-2">
    <ngb-pagination
      *ngIf="!(loading$ | async) && filter && total > limit"
      [collectionSize]="total"
      [(page)]="filter.page"
      [pageSize]="filter.pageSize"
      [maxSize]="10"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="onPageChange($event)"
    ></ngb-pagination>
  </div>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
  <strong *ngIf="error$ | async" class="text-danger">{{ (error$ | async).message }}</strong>
</div>
