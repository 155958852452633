<div *pxcHasRight='"tab_organisations"' class="pxc-page">
  <div class="d-flex justify-content-between mb-2">
    <form (ngSubmit)="loadOrganisations()" class="form-inline">
      <input
        class="form-control mr-2"
        type="text"
        [(ngModel)]="filter.q"
        name="searchTerm"
        placeholder="Filter organisations..."
      />
      <pxc-button (click)="loadOrganisations()" [loading]="loading$">Filter</pxc-button>
    </form>
    <pxc-button data-cy="goto-create-organisation-button" routerLink="create">
      <i class="fas fa-plus"></i>
      <span class="u-margin-left-2xs">Create an organisation</span>
    </pxc-button>
  </div>
  <pxc-sortable-table [filter]="filter" (onFilterChange)="onFilterChange($event)" [columns]="columns">
    <tbody *ngIf="!(loading$ | async) && (organisations$ | async).organisations">
      <tr *ngFor="let organisation of (organisations$ | async).organisations">
        <td class="nowrap">{{organisation.id}}</td>
        <td><a class="color-primary" routerLink="{{organisation.id}}">{{organisation.name}}</a></td>
        <td>{{organisation.admins}}</td>
        <td>{{organisation.createdAt | time:'L'}}</td>
      </tr>
    </tbody>
  </pxc-sortable-table>
  <div class="mt-2">
    <ngb-pagination
      *ngIf="!(loading$ | async) && filter && (organisations$ | async).total > (organisations$ | async).limit"
      [collectionSize]="(organisations$ | async).total"
      [(page)]="filter.page"
      [pageSize]="filter.pageSize"
      [maxSize]="10"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="onPageChange($event)"
    ></ngb-pagination>
  </div>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
</div>
