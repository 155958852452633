import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import * as moment from 'moment';
import { BillingActions } from '~/store/billing/billing.actions';
import { LoadingSelectors } from '~/store/loading/loading.selector';
import { AppStore } from '~/store/store';

const dateFormat = 'YYYY-MM-DD';

@Component({
  selector: 'pxc-trial-extender',
  templateUrl: './trial-extender.html',
})
export class TrialExtenderComponent implements OnChanges {
  @Input()
  trialEndAt: string;

  @Output()
  onExtend: EventEmitter<any> = new EventEmitter();

  loading$ = this.store.select(LoadingSelectors.getByAction(BillingActions.ExtendTrial));
  expired = false;
  daysLeft: number;
  daysToAdd = 15;
  endAt: moment.Moment;
  popoverOptions = {
    placement: 'right',
    closeOnOutsideClick: true,
  };

  constructor(private store: AppStore) {}

  ngOnChanges() {
    const trialEnd = moment(this.trialEndAt);
    this.expired = trialEnd.isBefore(moment());
    if (!this.expired) {
      this.daysLeft = trialEnd.diff(moment(), 'days');
    }
    this.updateEndDate();
  }

  updateEndDate() {
    this.endAt = this.expired
      ? moment().add(this.daysToAdd, 'days')
      : moment(this.trialEndAt).add(this.daysToAdd, 'days');
  }

  onExtendInternal() {
    this.onExtend.emit(this.endAt.utc().format());
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      // Enter
      this.onExtendInternal();
    }
  }
}
