import { Component, Injector, OnInit } from '@angular/core';
import { ILdapData } from '@proxyclick/data-model';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { UserProvisioningService } from '~/services/ldap.service';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { LdapDirectoryActions } from '~/store/user-provisioning/user-provisioning.reducer';
import { BasePage } from '~/utils/base-page';

@Component({
  selector: 'pxc-ldap-details',
  templateUrl: 'ldap-details.html',
})
export class LdapDetailsPageComponent extends BasePage implements OnInit {
  attributes = {};
  ldap$ = this.store.select('ldap-details', 'ldapDetails', 'value').pipe(map(x => { return _.cloneDeep(x)}));
  loading$ = this.store.select('ldap-details', 'ldapDetails', 'loading');
  testConnection$ = this.store.select('ldap-details', 'testConnection');
  extractUsers$ = this.store.select('ldap-details', 'extractUsers');
  languages$ = this.store.select('languages', 'value');
  needAuthentication = false;
  model: any;
  authentificationOptions: IOption[] = [
    { label: 'Anonymous', value: 'ANONYMOUS' },
    { label: 'Basic', value: 'BASIC' },
  ];
  defaultLanguages = [];

  constructor(injector: Injector, private Ldap: UserProvisioningService) {
    super(injector);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.store.dispatch(LdapDirectoryActions.Load(params.id));
    });
    this.languages$.subscribe(languages => {
      this.defaultLanguages = languages && languages.map(l => l.code);
    });
  }

  refresh(companyId: string) {
    this.store.dispatch(LdapDirectoryActions.Load(companyId));
  }

  async testConnection(data: ILdapData) {
    data = this.transformData(data);
    await this.Ldap.save(data).toPromise();
    await this.Ldap.testConnection(data.companyId)
      .toPromise()
      .then(() => this.Notifications.success('Connection successful'))
      .catch(() => null);
  }

  async extractUsers(data: ILdapData) {
    data = this.transformData(data);
    await this.Ldap.save(data).toPromise();
    await this.Ldap.testConnection(data.companyId)
      .toPromise()
      .then(() => this.Ldap.extractUsers(data.companyId).toPromise())
      .catch(() => null);
  }

  async save(ldap: ILdapData) {
    await this.Ldap.save(this.transformData(ldap)).toPromise();
    this.Notifications.success('Successfully saved');
  }

  async start(ldap: ILdapData) {
    const result = await this.Ldap.sync(ldap.company.id).start().toPromise();
    this.Notifications.success('Synchronization scheduled');
    this.refresh(ldap.company.id);
  }

  async syncNow(ldap: ILdapData) {
    const result = await this.Ldap.sync(ldap.company.id).start(true).toPromise();
    this.Notifications.success('Synchronization started');
    this.refresh(ldap.company.id);
  }

  async stop(ldap: ILdapData) {
    const result = await this.Ldap.sync(ldap.company.id).stop().toPromise();
    this.Notifications.success('Synchronization stopped');
    this.refresh(ldap.company.id);
  }

  transformData(ldap: ILdapData) {
    const result = _.cloneDeep(ldap);
    result.attributes = ldap.attributes.filter(a => a.name || a.defaultValue);
    result.companyId = ldap.company.id;
    return result;
  }
}
