import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IMCategory, IPartner, IRegion } from '@proxyclick/data-model';
import * as _ from 'lodash';
import { combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { IChecked } from '~/models/utils';
import { DocumentsService } from '~/services/documents.service';
import { IAppState } from '~/store/app-state';
import { PartnerActions } from '~/store/partners/partners.reducer';

interface ICheckedRegion extends IRegion, IChecked {}
interface ICheckedCategory extends IMCategory, IChecked {}

@Component({
  selector: 'pxc-partner-details',
  templateUrl: './partner-details.html',
  styleUrls: ['./partner-details.scss'],
})
export class PartnerDetailsPageComponent implements OnInit, OnDestroy {
  partner$ = this.store.select('partner', 'value');
  loading$ = this.store.select('partner', 'loading');
  categories$: Observable<IMCategory[]>;
  regions$: Observable<IRegion[]>;

  partner: IPartner;
  categories: ICheckedCategory[];
  regions: ICheckedRegion[];

  private componentDestroyed: Subject<any> = new Subject();

  constructor(private store: Store<IAppState>, private documents: DocumentsService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.categories$ = this.store
      .select('categories', 'value')
      .pipe(map(categories => categories && categories.filter(c => c.type === 'PARTNER')));

    this.regions$ = this.store.select('regions', 'value');

    this.partner$.pipe(takeUntil(this.componentDestroyed)).subscribe(partner => (this.partner = partner));

    observableCombineLatest(this.categories$.pipe(filter(a => !!a)), this.partner$.pipe(filter(a => !!a)))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([categories, partner]) => this.applyCategories(categories, partner));

    observableCombineLatest(this.regions$.pipe(filter(a => !!a)), this.partner$.pipe(filter(a => !!a)))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([regions, partner]) => this.applyRegions(regions, partner));

    this.route.params.subscribe(params => {
      const id = params['id'];
      const action = id === 'new' ? PartnerActions.NEW() : PartnerActions.Load(id);
      this.store.dispatch(action);
    });
  }

  applyCategories(categories, partner) {
    this.categories = categories;
    this.categories.forEach(
      category => (category.checked = partner.categories && partner.categories.some(cat => cat.id === category.id))
    );
  }

  applyRegions(regions, partner) {
    this.regions = regions;
    this.regions.forEach(
      category => (category.checked = partner.regions && partner.regions.some(cat => cat.id === category.id))
    );
  }

  onCategoriesUpdate(categories) {
    this.partner.categories = categories.filter(c => c.checked).map(c => _.pick(c, 'id', 'name', 'type'));
  }

  onRegionsUpdate(region) {
    this.partner.regions = region.filter(c => c.checked).map(c => _.pick(c, 'id', 'name'));
  }

  onSave() {
    const call = _.has(this.partner, 'id')
      ? () => this.store.dispatch(PartnerActions.UPDATE(this.partner))
      : () => this.store.dispatch(PartnerActions.CREATE(this.partner));

    call();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}
