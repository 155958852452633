export function createAnimation(options: {
  duration: number;
  frequency: number;
  startOffset?: number;
  callback: (percentage: number) => any;
}) {
  setTimeout(() => {
    const startTime = Date.now();
    const interval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      options.callback(elapsed / options.duration);
    }, options.frequency);

    setTimeout(() => clearInterval(interval), options.startOffset + options.duration);
  }, options.startOffset);
}
