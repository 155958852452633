import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { flatMap } from 'rxjs/operators';
import { AuthService } from '~/services/auth.service';
import { JWTService } from '~/services/jwt.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { IAppState, USER_LOGGED_IN_ACTION } from '~/store/app-state';

@Component({
  selector: 'pxc-oauth',
  templateUrl: './oauth.html',
  styleUrls: ['../auth.scss'],
})
export class OAuthPageComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private JWT: JWTService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private Notifications: NotificationsService
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(flatMap(param => this.authService.postCode(param.code))).subscribe(
      res => {
        this.JWT.setJWT(res);
        this.store.dispatch(USER_LOGGED_IN_ACTION);
        this.router.navigate(['/companies']).then(v => {
          if (!v) {
            this.router.navigate(['/']);
          }
        });
      },
      err => {
        this.Notifications.error('There was an error while login via SSO. Contact an administrator');
        this.router.navigate(['/login']);
      }
    );
  }

  ngOnDestroy() {}
}
