import { tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IJsonData } from '@proxyclick/data-model';
import { UserProvisioningService } from '~/services/ldap.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { IAppState } from '~/store/app-state';
import { JsonDirectoriesActions } from '~/store/user-provisioning/user-provisioning.reducer';

@Component({
  selector: 'pxc-json',
  templateUrl: './json.html',
})
export class JsonPageComponent implements OnInit {
  jsonDirectories$ = this.store.select('json', 'value');
  loading$ = this.store.select('json', 'loading');

  constructor(
    private store: Store<IAppState>,
    private UserProvisioning: UserProvisioningService,
    private Notifications: NotificationsService
  ) {}

  ngOnInit() {
    this.store.dispatch(JsonDirectoriesActions.Load());
  }

  onDelete(directory: IJsonData) {
    this.UserProvisioning.json()
      .delete(directory.company.id)
      .pipe(tap(() => this.Notifications.success(`Directory [${directory.company.name}] deleted`)))
      .subscribe(() => this.store.dispatch(JsonDirectoriesActions.Load()));
  }
}
