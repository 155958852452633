import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseService } from './base.service';

export interface IPongUpPlayer {
  deletedAt: string;
  eloPoints: number;
  id: number;
  name: string;
  photoUrl: string;
  totalMatches: number;
}

@Injectable()
export class PongUpService extends BaseService {
  constructor(injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getTopPlayers$(number = 7) {
    return timer(0, 60000)
      .pipe(switchMap(() => this.getSortedPlayers()))
      .pipe(map(players => players.slice(0, number)));
  }

  getLeaderboard() {
    return this.http
      .get<{ data: IPongUpPlayer[] }>('https://getpongup.com/api/teams/proxyclick/users', {
        params: {
          withEloPoints: 'true',
          api_token: this.config.getConfig().pongUp.token,
        },
      })
      .pipe(map(r => r.data));
  }

  private getSortedPlayers() {
    return this.getLeaderboard().pipe(
      map(players => players.filter(player => player.totalMatches > 0)),
      map(players => players.sort((a, b) => b.eloPoints - a.eloPoints))
    );
  }
}
