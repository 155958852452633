import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router';
import { CompanyIdScanComponent } from 'app/shared/components/stats/company-id-scan-stats/company-id-scan-stats.component';
import { CompaniesPageComponent } from '~/pages/companies/companies.page';
import { CompanyAddressComponent } from '~/pages/companies/company-address/company-address.component';
import { CompanyDetailsPageComponent } from '~/pages/companies/company-details/company-details.page';
import { CompanySDComponent } from '~/pages/companies/company-sd/company-sd.component';
import { CompanyUsersComponent } from '~/pages/companies/company-users/company-users.component';
import { CreateCompanyPageComponent } from '~/pages/companies/create-company/create-company.page';
import { ModuleFormComponent } from '~/pages/companies/module-form/module-form.component';
import { CompanyStatsComponent } from '~/shared/components/stats/company-stats/company-stats.component';
import { FastPaceSharedModule } from '~/shared/fastpace/fastpace.shared.module';
import { RightGuard } from '~/shared/guards/right.guard';
import { SharedModule } from '~/shared/shared.module';
import { UserRights } from '../../../../../shared/models';
import { CompanyBillingComponent } from './company-billing/company-billing.component';
import { CompanyFastPaceComponent } from './company-fastpace/company-fastpace.component';
import { CompanyLegalComponent } from './company-legal/company-legal.component';
import { CompanyQrComponent } from './company-qr/company-qr.component';
import { CompanySettingsComponent } from './company-settings/company-settings.component';
import { DowngradeFeatureListComponent } from './downgrade-feature-list/downgrade-feature-list.component';
import { FeaturesSelectorComponent } from './features-selector/features-selector.component';
import { QRCodeModule } from 'angularx-qrcode';

export type CompanyId = string;

@NgModule({
  declarations: [
    CompanyDetailsPageComponent,
    CompaniesPageComponent,
    CreateCompanyPageComponent,
    CompanyUsersComponent,
    CompanyAddressComponent,
    CompanySDComponent,
    CompanySettingsComponent,
    CompanyLegalComponent,
    CompanyFastPaceComponent,
    CompanyBillingComponent,
    CompanyQrComponent,
    ModuleFormComponent,
    FeaturesSelectorComponent,
    DowngradeFeatureListComponent,
  ],
  imports: [
    SharedModule,
    FastPaceSharedModule,
    QRCodeModule,
    RouterModule.forChild([
      {
        path: 'companies',
        canActivate: [RightGuard],
        data: {
          right: UserRights.TabCompanies,
          title: 'Companies',
        },
        children: [
          {
            path: '',
            component: CompaniesPageComponent,
            data: {
              title: '',
            },
          },
          {
            path: 'create',
            component: CreateCompanyPageComponent,
            data: {
              title: 'Create',
            },
          },
          {
            path: ':id',
            component: CompanyDetailsPageComponent,
            data: {
              title: '{{company.company.value.name}}',
            },
            children: [
              {
                path: 'users',
                component: CompanyUsersComponent,
                data: {
                  title: 'Users',
                },
              },
              {
                path: 'settings',
                component: CompanySettingsComponent,
                data: {
                  title: 'Settings',
                },
              },
              {
                path: 'sd',
                component: CompanySDComponent,
                data: {
                  title: 'SD',
                },
              },
              {
                path: 'address',
                component: CompanyAddressComponent,
                data: {
                  title: 'Address',
                },
              },
              {
                path: 'stats',
                component: CompanyStatsComponent,
                data: {
                  title: 'Stats',
                },
              },
              {
                path: 'idscan',
                component: CompanyIdScanComponent,
                data: {
                  title: 'ID Scan',
                },
              },
              {
                path: 'legal',
                component: CompanyLegalComponent,
                data: {
                  title: 'Legal',
                },
              },
              {
                path: 'fastpace',
                component: CompanyFastPaceComponent,
                data: {
                  title: 'ACS',
                },
              },
              {
                path: 'billing',
                component: CompanyBillingComponent,
                data: {
                  title: 'Billing',
                },
              },
              {
                path: 'qr-code',
                component: CompanyQrComponent,
                data: {
                  title: 'QR code',
                },
              },
              {
                path: '**',
                redirectTo: 'users',
              },
            ],
          },
        ],
      },
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
    ]),
  ],
  providers: [
    {
      provide: 'companyId',
      useValue: (route: ActivatedRouteSnapshot) => 'companyId',
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CompaniesModule {}
