import { Component, Input } from '@angular/core';
import { FastPaceSetupCompanyState } from '../fastpace-setup-company.state';
import { FastPaceService } from '~/services/fastpace.service';

@Component({
  selector: 'app-fastpace-company-setup-review',
  templateUrl: './fastpace-setup-review.component.html',
})
export class FastPaceSetupReviewComponent {
  @Input() setupState: FastPaceSetupCompanyState;
}
