<div *pxcHasRight='"tab_users"'>
  <div class="flex between">
    <form class="form-inline mb-2">
      <input class="form-control mr-2" type="text" [(ngModel)]="searchTerm" name="searchTerm" />
      <pxc-button [onClick]="searchUsers()">Search</pxc-button>
    </form>
    <div class="flex">
      <div *ngIf="userProvisioning$ | async as userProvisioning" class="mr-2">
        <label><strong>User Provisioning </strong>: </label>
        <span> {{userProvisioning.provider}}</span>
      </div>
      <div *ngIf="saml$ | async as saml">
        <label><strong>SSO </strong>: </label>
        <span> {{saml.provider}}</span>
      </div>
    </div>
    <div *pxcHasRight="'log_as_user'" class="u-margin-left-s">
      <a
        class="c-button c-button--s"
        *ngIf="usersWithPxcUserDirectoryEntry.length > 0"
        (click)="togglePxcCredentialsModal()"
        >Remove all PXC credentials</a
      >
    </div>
  </div>
  <table class="c-table mt-4 mb-2">
    <thead>
      <th>
        User
        <span *ngIf="!adminsOnly && users$ | async as users">({{users.total}})</span>
        <span *ngIf="adminsOnly && admins$ | async as admins">({{admins.length}})</span>
        <label class="ml-5 pointer"
          ><input type="checkbox" [(ngModel)]="adminsOnly" />
          Admins only
        </label>
      </th>
      <th *pxcHasRight="'log_as_user'">Login</th>
      <th>Language</th>
      <th>Email</th>
      <th>Last log</th>
    </thead>
    <tbody *ngIf="!adminsOnly && (loadingUsers$ | async) === false && users$ | async as users">
      <tr *ngFor="let user of users.users">
        <td>
          <a routerLink="/users/{{user.id}}">{{user.firstname}} {{user.lastname}}</a>
          <i *ngIf="user.isAdmin" class="fas fa-user ml-2"></i>
        </td>
        <td *pxcHasRight="'log_as_user'">
          <div class="pointer"><i (click)="logAsUser(user)" class="fas fa-sign-in-alt"></i></div>
        </td>
        <td><span class="badge badge-secondary">{{user.language.code.toUpperCase()}}</span></td>
        <td>{{user.email}}</td>
        <td>{{user.lastLoggedAt | time:'fromNow'}}</td>
      </tr>
    </tbody>
    <tbody *ngIf="adminsOnly && (loadingAdmins$ | async) === false && admins$ | async as users">
      <tr *ngFor="let user of users">
        <td>
          <a routerLink="/users/{{user.id}}">{{user.firstname}} {{user.lastname}}</a>
          <i class="fas fa-user ml-2"></i>
        </td>
        <td *pxcHasRight="'log_as_user'">
          <div class="pointer"><i (click)="logAsUser(user)" class="fas fa-sign-in-alt"></i></div>
        </td>
        <td><span class="badge badge-secondary">{{user.language.code.toUpperCase()}}</span></td>
        <td>{{user.email}}</td>
        <td>{{user.lastLoggedAt | time:'fromNow'}}</td>
      </tr>
    </tbody>
  </table>
  <pxc-loading *ngIf="adminsOnly" [loading]="loadingAdmins$ | async"></pxc-loading>
  <pxc-loading *ngIf="!adminsOnly" [loading]="loadingUsers$ | async"></pxc-loading>
  <ngb-pagination
    *ngIf="filter && !adminsOnly && total > limit"
    [collectionSize]="total"
    [(page)]="filter.page"
    [pageSize]="filter.pageSize"
    [maxSize]="10"
    [rotate]="true"
    [boundaryLinks]="true"
    (pageChange)="onPageChange($event)"
  ></ngb-pagination>
</div>

<hl-modal
  *ngIf="pxcCredentialsModal"
  class="hl-modal"
  [isOpen]="true"
  [heading]="'PXC credentials'"
  (close)="togglePxcCredentialsModal()"
>
  <div>
    <div class="pxc-settings__subheading u-margin-bottom-gutter">
      {{'Are you sure you want to remove the PXC credentials for all these users?'}}
      <br /><br />
      {{'This also impacts the user their ability to login to other locations'}}
    </div>
    <div></div>
    <footer class="hl-modal__footer u-margin-top-s">
      <div class="c-buttons-group" style="margin-top: -10px">
        <hl-button size="small" type="primary" (click)="removePxcUserDirectoryForAll()">Yes</hl-button>
        <hl-button size="small" type="secondary" (click)="togglePxcCredentialsModal()">No</hl-button>
      </div>
    </footer>
  </div>
</hl-modal>
