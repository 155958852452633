<pxc-loading [loading]="loading"></pxc-loading>
<div *ngIf="!loading">
  <form (submit)="submit()">
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Card Format (QR Code)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="qrCodeCardFormatOption"
            [options]="cardFormats"
            name="qrCodeCardFormatOption"
            label="name"
            key="id"
          ></pxc-obj-dropdown>
        </div>
      </div>
      <div class="c-helper">
        Card Format used when adding cards. Format must support at least 8 digits (32 data bits)
      </div>
    </div>

    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Card Format (Access Card Manager)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="cardCardFormatOption"
            [options]="cardFormats"
            name="cardCardFormatOption"
            label="name"
            key="id"
            allowNull="true"
          ></pxc-obj-dropdown>
        </div>
      </div>
      <div class="c-helper">When using access card manager, this card format will be used</div>
    </div>

    <div class="l-form__field">
      <label class="c-label">Facility Code</label>

      <input type="number" class="c-input c-input--s" [(ngModel)]="facilityCode" name="facilityCode" />

      <div class="c-helper">
        When using access card manager, facility code, if required by the selected card format.
      </div>
    </div>

    <div class="l-form__field">
      <label class="c-label">Reader IN filter</label>
      <div class="l-grid">
        <div class="l-grid__cell l-grid__cell--11">
          <pxc-obj-dropdown
            [(ngModel)]="readerOptionIn"
            [options]="readerOptions"
            name="readerOptionIn"
            key="id"
            label="name"
          ></pxc-obj-dropdown>
        </div>
        <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
          <hl-icon
            icon="add-24"
            (click)="addInMapping()"
            [ngClass]="{ 'u-color-neutral-light': !canAddInMapping() }"
          ></hl-icon>
        </div>
      </div>
      <div class="c-helper">
        To allow visitor events from OpenPath to be sent to our servers please select at least one reader for IN
        movements.
      </div>
    </div>
    <div class="l-form__field">
      <label class="c-label">Reader OUT filter</label>
      <div class="l-grid">
        <div class="l-grid__cell l-grid__cell--11">
          <pxc-obj-dropdown
            [(ngModel)]="readerOptionOut"
            [options]="readerOptions"
            name="readerOptionOut"
            key="id"
            label="name"
          ></pxc-obj-dropdown>
        </div>
        <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
          <hl-icon
            icon="add-24"
            (click)="addOutMapping()"
            [ngClass]="{ 'u-color-neutral-light': !canAddOutMapping() }"
          ></hl-icon>
        </div>
      </div>
      <div class="c-helper">
        To allow visitor events from OpenPath to be sent to our servers please select at least one reader for OUT
        movements.
      </div>
    </div>

    <div
      *ngIf="readerFilterIns.length > 0 || readerFilterOuts.length > 0"
      class="l-stack c-box u-padding-all-none u-margin-bottom-s"
    >
      <div class="l-stack__item">
        <div class="c-stacked-list c-stacked-list--s">
          <ul class="c-stacked-list__list">
            <li *ngFor="let reader of readerFilterIns" class="c-stacked-list__item">
              <a class="c-stacked-list__inner c-stacked-list__inner--interactive" (click)="removeInMapping(reader.id)">
                <div class="c-stacked-list__inner-start">IN: {{ reader.name }}</div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
            <li *ngFor="let reader of readerFilterOuts" class="c-stacked-list__item">
              <a class="c-stacked-list__inner c-stacked-list__inner--interactive" (click)="removeOutMapping(reader.id)">
                <div class="c-stacked-list__inner-start">OUT: {{ reader.name }}</div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Map custom fields to OpenPath custom fields</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-switch
            [(ngModel)]="mapCustomFields"
            name="mapCustomFields"
            [onChange]="switchEnableCustomFieldMapping()"
          >
          </pxc-switch>
        </div>
      </div>
    </div>
    <div class="l-grid" *ngIf="mapCustomFields">
      <div class="l-grid__cell l-grid__cell--6">
        <pxc-obj-dropdown
          [(ngModel)]="customFieldOption"
          [options]="customFieldOptions"
          name="customFieldOption"
          key="id"
          label="name"
        >
        </pxc-obj-dropdown>
        <div class="c-helper">Select a Proxyclick custom field</div>
      </div>
      <div class="l-grid__cell l-grid__cell--5">
        <pxc-obj-dropdown
          [(ngModel)]="openPathCustomFieldOption"
          [options]="openPathCustomFieldOptions"
          name="openPathCustomFieldOption"
          key="id"
          label="name"
        ></pxc-obj-dropdown>
        <div class="c-helper">Select a OpenPath custom field</div>
      </div>
      <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
        <hl-icon icon="add-24" (click)="addMapping()" [ngClass]="{ 'u-color-neutral-light': !canAddMapping() }">
        </hl-icon>
      </div>

      <div *ngIf="customFieldMappings.length > 0" class="l-stack c-box u-padding-all-none u-margin-bottom-s">
        <div class="l-stack__item">
          <div class="c-stacked-list c-stacked-list--s">
            <ul class="c-stacked-list__list">
              <li *ngFor="let customFieldMapping of customFieldMappings" class="c-stacked-list__item">
                <a
                  class="c-stacked-list__inner c-stacked-list__inner--interactive"
                  (click)="removeMapping(customFieldMapping.customField.id)"
                >
                  <div class="c-stacked-list__inner-start">
                    {{ customFieldMapping.customField.name }} =>
                    <code>{{ customFieldMapping.openPathCustomField.name }}</code>
                  </div>
                  <div class="c-stacked-list__inner-end">
                    <span class="c-icon c-icon--m">
                      <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <button type="submit" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
      Save settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
      <hl-icon
        *ngIf="submitting === false && errors === undefined"
        icon="checkmark-circle--color-24"
        width="24"
        height="24"
      ></hl-icon>
    </button>
  </form>
  <h3 *ngIf="errors">Errors during update</h3>
  <div class="u-color-danger" *ngIf="errors">
    <h4>{{ errors.title }}</h4>
    <dl>
      <ng-container *ngFor="let item of errors.errors | keyvalue">
        <dt>{{ item.key }}</dt>
        <dt>
          <ul>
            <li *ngFor="let errorItem of item.value">
              {{ errorItem }}
            </li>
          </ul>
        </dt>
      </ng-container>
    </dl>
    Trace id <code>{{ errors.traceId }}</code>
  </div>
</div>
