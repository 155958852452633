<form #form="ngForm" class="u-flex u-flex-align-items-center">
  <div style="max-width: 400px;">
    <div class="u-margin-bottom-xs">
      <pxc-dropdown [(ngModel)]="featureList" name="featureList" label="reference" [options]="featureLists">
      </pxc-dropdown>
    </div>
    <span>{{featureList.description}}</span>
  </div>
  <pxc-button [onClick]="downgrade(company, 1, featureList)" [disabled]="!form.valid" class="u-margin-left-m">
    Downgrade to V1
  </pxc-button>
  <pxc-button [onClick]="downgrade(company, 2)" [disabled]="!form.valid" class="u-margin-left-m">
    Downgrade to V2
  </pxc-button>
</form>
