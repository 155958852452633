import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { RightService } from '~/services/rights.service';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';
import { RightsActions } from './rights.actions';

@Injectable()
export class RightsEffects {
  constructor(private actions$: Actions<PayloadAction>, private rightService: RightService) {}

  
  $loadAll = createEffect(() => generateAsyncEffect(this.actions$, RightsActions, () => {
    return this.rightService.getAllRights();
  }));
}
