import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppStore } from '~/store/store';
import { createAction } from '@ngrx/store';
import { ExtenderSelector } from '~/store/extender/extender.selectors';
import { ExtenderActions } from '~/store/extender/extender.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { IExtenderResponseV1, ErrorResponse } from '~/store/extender/extender.models';
import { Subscription, empty } from 'rxjs';
import { ExtenderService } from '~/services/extender.service';
import { catchError } from 'rxjs/operators';
import { FastPaceService } from '~/services/fastpace.service';
import { ExtenderDetailsState } from './extender.details.state';
import { ProovrService } from '~/services/proovr.service';
import { ProovrExtenderResponseV1 } from '~/store/proovr/proovr.models';
import {
  ControllersCompaniesFastPaceCompanyResponseV1,
  ControllersExtendersExtenderResponseV1,
} from '@proxyclick/fastpace-client';

@Component({
  selector: 'app-extender-details-page',
  templateUrl: './extender.details.page.component.html',
})
export class ExtenderDetailsPageComponent implements OnInit, OnDestroy {
  static Loaded = createAction('ExtenderDetailsPageComponent/Loaded');
  extender$ = this.store.select<IExtenderResponseV1>(ExtenderSelector.getExtender);
  loading$ = this.store.select(ExtenderSelector.getExtenderLoading);
  error$ = this.store.select(ExtenderSelector.getExtenderError);
  subscription: Subscription = new Subscription();
  companiesOnExtender: ControllersCompaniesFastPaceCompanyResponseV1[] = [];
  fastpaceExtender: ControllersExtendersExtenderResponseV1;
  proovrExtender: ProovrExtenderResponseV1 | null = null;
  state: ExtenderDetailsState = new ExtenderDetailsState();

  constructor(
    private store: AppStore,
    private route: ActivatedRoute,
    private fastPaceService: FastPaceService,
    private extenderService: ExtenderService,
    private router: Router,
    private proovrService: ProovrService
  ) {}

  ngOnInit(): void {
    const subRouteParam = this.route.params.subscribe(params => {
      this.store.dispatch(ExtenderActions.Extender.Load(params.id));
    });

    this.subscription.add(subRouteParam);

    const subExtender = this.extender$.subscribe(e => {
      if (e === undefined) {
        return;
      }
      this.state.initialize(e);
      this.fastPaceService.getCompaniesForExtender(e.extenderId).then(c => {
        this.companiesOnExtender = c;
      });
      this.fastPaceService.getExtender(e.extenderId).then(c => {
        this.fastpaceExtender = c;
      });
      this.proovrService.getExtenderSettings(e.extenderId).then(e => {
        this.proovrExtender = e;
      });
    });

    this.subscription.add(subExtender);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showGeneral: boolean = true;
  showSystem: boolean = true;
  showFastPACE: boolean = true;
  showClient: boolean = true;
  showProovr: boolean = true;

  daysInThePast: number = 7;

  onAlertSlackChange(event) {
    this.state.alertStatus = event.target.checked;
  }

  async refreshCompaniesExtenderSettings() {
    this.submitting = true;
    this.errors = undefined;
    try {
      await this.fastPaceService.refreshCompaniesExtenderSettings(this.state.extender.extenderId);
      this.submitting = false;
      this.store.dispatch(ExtenderActions.Extender.Load(this.state.extender.extenderId));
    } catch (error) {
      this.handleError(error);
    }
  }

  async refreshCompanyExtenderSettings(companyId: string) {
    this.submitting = true;
    this.errors = undefined;
    try {
      await this.fastPaceService.refreshCompanyExtenderSettings(companyId);
      this.submitting = false;
      this.store.dispatch(ExtenderActions.Extender.Load(this.state.extender.extenderId));
    } catch (error) {
      this.handleError(error);
    }
  }

  async deleteExtender() {
    var result = prompt("type 'confirm' to confirm");
    if (result == 'confirm') {
      this.submitting = true;
      this.errors = undefined;
      try {
        await this.extenderService.deleteExtender(this.state.extender.extenderId);
        this.router.navigate(['fastpace', 'extenders']);
      } catch (error) {
        this.handleError(error);
      }
    }
  }

  async retryUpdates() {
    this.submitting = true;
    this.errors = undefined;
    try {
      await this.fastPaceService.retryExtenderUpdates(this.state.extender.extenderId, this.daysInThePast);
      this.submitting = false;
      this.store.dispatch(ExtenderActions.Extender.Load(this.state.extender.extenderId));
    } catch (error) {
      this.handleError(error);
    }
  }

  async retryUpdatesForCompany(companyId: string) {
    this.submitting = true;
    this.errors = undefined;

    try {
      await this.fastPaceService.retryExtenderUpdatesForCompany(companyId, this.daysInThePast);
      this.submitting = false;
      this.store.dispatch(ExtenderActions.Extender.Load(this.state.extender.extenderId));
    } catch (error) {
      this.handleError(error);
    }
  }

  get canSubmit(): boolean {
    return this.submitting !== true && this.state.canSubmit();
  }

  submitting: boolean = false;
  errors: ErrorResponse;

  async submit() {
    this.submitting = true;
    this.errors = undefined;
    var request = this.state.updateRequest();

    try {
      await this.extenderService.updateExtender(this.state.extender.extenderId, request);
      this.submitting = false;
      this.store.dispatch(ExtenderActions.Extender.Load(this.state.extender.extenderId));
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError(error) {
    this.submitting = false;
    if (error && error.status < 500) {
      var errorResponse = error.error as ErrorResponse;
      this.errors = errorResponse;
    } else {
      this.errors = {
        title: error.message,
        errors: undefined,
        status: error.statusCode,
        traceId: error.headers?.get('traceId'),
        type: 'Failure',
      };
    }
  }
}
