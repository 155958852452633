<select
  [value]="option && option[value] ? option[value] : option"
  (change)="onOptionClick($event)"
  class="c-select c-select--s"
  [disabled]="disabled"
>
  <option *ngFor="let option of options" [value]="option && option[value] ? option[value] : option"
    >{{option && option[label] ? option[label] : option}}
  </option>
</select>
