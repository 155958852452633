import { Action } from '@ngrx/store';
import { getLoadingCommand } from './loading.reducer';

export const LoadingSelectors = {
  getByAction: (action: Action & { meta?: any }) => {
    return state => {
      const command = getLoadingCommand(action);
      return state.loading[command.loadingKey] || state.loading[action.type];
    };
  },
  getByKey: (key: string) => {
    return state => {
      return state.loading[key];
    };
  },
};
