import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PartnersService } from '~/services/partners.service';
import { PartnerActions, PartnersActions } from '~/store/partners/partners.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';

@Injectable()
export class PartnersEffects {
  constructor(private actions$: Actions<PayloadAction>, private partnersService: PartnersService) {}

  
  loadAll$ = createEffect(() => generateAsyncEffect(this.actions$, PartnersActions, () => this.partnersService.partners().get()));

  
  delete$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.DELETE),
    switchMap(action => this.partnersService.deletePartner(action.payload).pipe(map(() => PartnersActions.Load()))),
    catchError(err => observableOf(PartnersActions.Error(err)))
  ));

  
  load$ = createEffect(() => generateAsyncEffect(this.actions$, PartnerActions, id => this.partnersService.partner(id).get()));

  
  new$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.NEW),
    map(() => PartnerActions.Loaded(this.partnersService.partners().default()))
  ));

  
  update$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.UPDATE),
    switchMap(action =>
      this.partnersService.updatePartner(action.payload).pipe(
        map(partner => PartnerActions.Loaded(partner)),
        catchError(err => observableOf(PartnerActions.Error(err)))
      )
    )
  ));

  
  create$ = createEffect(() => this.actions$.pipe(
    ofType(PartnerActions.CREATE),
    switchMap(action =>
      this.partnersService.createPartner(action.payload).pipe(map(partner => PartnerActions.Loaded(partner)))
    ),
    catchError(err => observableOf(PartnerActions.Error(err)))
  ));
}
