<div class="c-card__section">
  <div class="l-form__field">
    <label class="c-label">Region</label>
    <pxc-dropdown name="selectedRegion" [(ngModel)]="state.clientSelectedRegion" [options]="regionOptions">
    </pxc-dropdown>
    <div class="c-helper">
      Choose the closest region to your customer's access control system. Let the team know if you think this will be
      too far.
    </div>
  </div>
</div>
<div class="c-card__section">
  <div class="c-banner c-banner--informative">
    <div class="c-banner__message">
      Run the installer onto the server the customer wants our client installed on. Once installed, you can bring up the
      Proxyclick client terminal by opening a command line and navigating to:
      <code>C:\Program Files (x86)\Proxyclick\{{ state.selectedExtenderTypeOption }} Access Client\</code>
      and launch
      <code>Proxyclick {{ state.selectedExtenderTypeOption }} Access Client.exe terminal</code>.
    </div>
  </div>
  <div class="l-form__field">
    <label class="c-label">Subscription ID</label>
    <input
      type="text"
      class="c-input c-input--s"
      name="subscriptionId"
      [(ngModel)]="state.clientSubscriptionId"
      minlength="32"
    />
    <div class="c-helper">
      Copy and paste the subscription ID here shown in the Terminal of the extender after installation
      (<code>poll://ID</code>)
    </div>
  </div>
  <div class="l-form__field">
    <label class="c-label">Thumbprint</label>
    <input
      type="text"
      class="c-input c-input--s"
      maxlength="4"
      minlength="4"
      name="thumbprint"
      [(ngModel)]="state.clientThumbprint"
      style="max-width: 80px;"
    />
    <div class="c-helper">
      Enter the last 4 characters of the Local Certificate as the Thumbprint, this is shown in the Terminal:<code
        >communication => show</code
      >
    </div>
  </div>
</div>
