<div>
  <div class="c-card l-stack u-margin-h-none l-form u-margin-bottom-m">
    <div class="c-card__header">
      <h2 class="c-card__title">Company {{ company.companyName }} ({{ company.companyId }})</h2>
      <div *ngIf="extender && !extender.hasBeenConnected" class="text-warning">
        <strong>The extender for this company is offline, some features may not be available</strong>
      </div>
      <a
        [href]="
          'https://seq.proxyclick.com/#/events?filter=Environment%20%3D%20\'Production\'%20and%20Application%20%3D%20\'FastPace\'%20and%20CompanyId%20%3D%20\'' +
          company.companyId +
          '\''
        "
        target="_blank"
        class="mt-3 d-block"
        >View Seq logs</a
      >
    </div>
    <div class="c-card__section">
      <h5>
        General
        <small
          ><a (click)="showGeneral = true" *ngIf="showGeneral === false"
            ><span class="c-icon c-icon--m">
              <hl-icon icon="chevron-down-24" width="24" height="24"></hl-icon> </span></a
          ><a (click)="showGeneral = false" *ngIf="showGeneral === true"
            ><span class="c-icon c-icon--m"> <hl-icon icon="chevron-up-24" width="24" height="24"></hl-icon> </span></a
        ></small>
      </h5>
      <div *ngIf="showGeneral === true">
        <div class="l-form__field">
          <pxc-field label="Enabled">
            <input type="checkbox" class="c-switch c-switch--locked" disabled="" [checked]="!company.disabled" />
            <label for="example-switch-locked-on" class="c-switch__skin"></label>
          </pxc-field>
          <small class="c-helper">
            Access control can get disabled when a the visitor management module runs out of trial or is blocked.
          </small>
        </div>
        <div class="l-form__field">
          <pxc-field label="Extender">
            <a *ngIf="company.extenderId" routerLink="/fastpace/extenders/{{ company.extenderId }}">{{
              extender.description
            }}</a>
            <span *ngIf="!company.extenderId">No extender</span>
          </pxc-field>
        </div>
        <div *ngIf="company.extenderId" class="l-form-field">
          <pxc-field label="Retry updates">
            <input
              type="number"
              class="c-input c-input--s c-input-"
              required="true"
              name="daysInThePast"
              min="1"
              [(ngModel)]="daysInThePast"
            />
            <div class="u-margin-v-s">
              <button
                type="menu"
                class="c-button c-button--primary c-button--s"
                [disabled]="!canSubmit"
                (click)="retryUpdatesForCompany(company.companyId)"
              >
                Retry failed updates (last {{ daysInThePast }} days) &nbsp;<hl-spinner
                  *ngIf="submitting"
                  size="medium"
                ></hl-spinner>
              </button>
            </div>
          </pxc-field>
        </div>
        <div *ngIf="company.extenderId" class="l-form-field">
          <pxc-field label="Refresh extender settings">
            <button
              type="menu"
              class="c-button c-button--primary c-button--s"
              [disabled]="!canSubmit"
              (click)="refreshCompanyExtenderSettings(company.companyId)"
            >
              Refresh extender settings &nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
            </button>
          </pxc-field>
        </div>
      </div>
    </div>
    <div class="c-card__section" *ngIf="extender">
      <h5>
        Access
        <small
          ><a (click)="showAccess = true" *ngIf="showAccess === false"
            ><span class="c-icon c-icon--m">
              <hl-icon icon="chevron-down-24" width="24" height="24"></hl-icon> </span></a
          ><a (click)="showAccess = false" *ngIf="showAccess === true"
            ><span class="c-icon c-icon--m"> <hl-icon icon="chevron-up-24" width="24" height="24"></hl-icon> </span></a
        ></small>
      </h5>
      <div *ngIf="extender && showAccess === true">
        <form (submit)="submit()">
          <div class="l-grid">
            <div class="l-grid__cell l-grid__cell--6">
              <div class="l-form__field">
                <div class="l-bar u-margin-bottom-xs">
                  <div class="l-bar__item-startm">
                    <div class="c-list c-list--bare u-text-m">
                      <input
                        id="access-check-in"
                        class="c-checkbox c-checkbox--m"
                        type="checkbox"
                        [(ngModel)]="company.accessCheckIn"
                        name="accessCheckIn"
                        [disabled]="!extender.hasBeenConnected"
                      />
                      <label class="c-checkbox__skin" for="access-check-in"></label>
                      &nbsp;<label class="c-label c-label--checkbox c-label--m" for="access-check-in"
                        >Access check-in</label
                      >
                    </div>
                  </div>
                </div>
                <div class="c-helper">
                  Allow access events to trigger a check-in in Proxyclick. Only works when access is allowed before
                  check-in.
                </div>
              </div>
            </div>
            <div class="l-grid__cell l-grid__cell--6">
              <div class="l-form__field">
                <div class="l-bar u-margin-bottom-xs">
                  <div class="l-bar__item-startm">
                    <div class="c-list c-list--bare u-text-m">
                      <input
                        id="access-check-out"
                        class="c-checkbox c-checkbox--m"
                        type="checkbox"
                        [(ngModel)]="company.accessCheckOut"
                        name="accessCheckOut"
                        [disabled]="!extender.hasBeenConnected"
                      />
                      <label class="c-checkbox__skin" for="access-check-out"></label>
                      &nbsp;<label class="c-label c-label--checkbox c-label--m" for="access-check-out"
                        >Access check-out</label
                      >
                    </div>
                  </div>
                </div>
                <div class="c-helper">Allow access events to trigger a check-out in Proxyclick.</div>
              </div>
            </div>
          </div>
          <div class="l-grid">
            <div class="l-grid__cell l-grid__cell--6">
              <div class="l-form__field">
                <label class="c-label">Add access trigger</label>
                <input
                  type="text"
                  class="c-input c-input--s"
                  minlength="5"
                  maxlength="50"
                  required="true"
                  name="add-access-trigger"
                  [(ngModel)]="company.expectedBeforeVisit"
                  [disabled]="!extender.hasBeenConnected"
                />
                <div class="c-helper">
                  Enter how long before the planned check-in we should switch the visitor's access permissions to
                  expected, unless they are already on-site, checked-in, or checked-out.
                </div>
              </div>
            </div>
            <div class="l-grid__cell l-grid__cell--6">
              <div class="l-form__field">
                <label class="c-label">Revoke access trigger</label>
                <input
                  type="text"
                  class="c-input c-input--s"
                  minlength="5"
                  maxlength="50"
                  required="true"
                  name="revoke-access-trigger"
                  [(ngModel)]="company.disableAfterVisit"
                  [disabled]="!extender.hasBeenConnected"
                />
                <div class="c-helper">
                  Enter how long after the planned check-out time we should disable access and remove the tokens. This
                  applies only to visits that are not in the checked-in status.
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="l-grid">
            <div class="l-grid__cell l-grid__cell--6">
              <div class="l-form__field">
                <div class="l-bar u-margin-bottom-xs">
                  <div class="l-bar__item-startm">
                    <div class="c-list c-list--bare u-text-m">
                      <input
                        id="specific-permissions"
                        class="c-checkbox c-checkbox--m"
                        type="checkbox"
                        [(ngModel)]="company.companySpecificAccess"
                        (ngModelChange)="changedCompanySpecificAccess()"
                        name="companySpecificAccess"
                        [disabled]="!extender.hasBeenConnected"
                      />
                      <label class="c-checkbox__skin" for="specific-permissions"></label>
                      &nbsp;<label class="c-label c-label--checkbox c-label--m" for="specific-permissions"
                        >Company specific permissions</label
                      >
                    </div>
                  </div>
                </div>
                <div class="c-helper">
                  Within the access control system a specific set of permissions will be enabled for this location.
                  Multiple companies can then use the same access control system with different permission rules,
                  compared to all companies using the same rules.
                </div>
              </div>
              <div class="l-form__field">
                <div class="l-bar__item-start">
                  <label class="c-label">Custom field as access level</label>
                </div>
                <pxc-dropdown
                  [(ngModel)]="customFieldOption"
                  [options]="customFieldOptions"
                  name="customFieldOptions"
                  (ngModelChange)="changedCustomFieldOption()"
                  [disabled]="!extender.hasBeenConnected"
                ></pxc-dropdown>
                <div class="c-helper">Only custom fields of the list type are suitable for access level usage</div>
              </div>
            </div>
            <div class="l-grid__cell l-grid__cell--6">
              <div class="c-box u-text-s u-color-neutral-dark">
                The following access levels would be created:
                <ul>
                  <li *ngFor="let accessLevel of accessLevels">{{ accessLevel }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div *ngIf="isOnSiteStatusEnabled$ | async">
            <hr />
            <div class="l-grid__cell l-grid__cell--6">
              <div class="l-form__field">
                <div class="l-bar u-margin-bottom-xs">
                  <div class="l-bar__item-startm">
                    <div class="c-list c-list--bare u-text-m">
                      <input
                        id="on-site-from-acs"
                        class="c-checkbox c-checkbox--m"
                        type="checkbox"
                        [(ngModel)]="company.onSiteStatusForVisitorEntered"
                        name="onSiteStatusForVisitorEntered"
                      />
                      <label class="c-checkbox__skin" for="on-site-from-acs"></label>
                      &nbsp;<label class="c-label c-label--checkbox c-label--m" for="on-site-from-acs"
                        >Use "On-site" visitor status</label
                      >
                    </div>
                  </div>
                </div>
                <div class="c-helper">
                  By default, visitors are set to "Checked-in" when they scan their QR code and enter a building.
                  Enabling this setting will set visitors to "On-site" instead.
                </div>
              </div>
            </div>
          </div>

          <button type="submit" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
            Save settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
            <hl-icon
              *ngIf="submitting === false && errors === undefined"
              icon="checkmark-circle--color-24"
              width="24"
              height="24"
            ></hl-icon>
          </button>
        </form>
        <h3 *ngIf="errors">Errors during update</h3>
        <div class="u-color-danger" *ngIf="errors">
          <h4>{{ errors.title }}</h4>
          <dl>
            <ng-container *ngFor="let item of errors.errors | keyvalue">
              <dt>{{ item.key }}</dt>
              <dt>
                <ul>
                  <li *ngFor="let errorItem of item.value">
                    {{ errorItem }}
                  </li>
                </ul>
              </dt>
            </ng-container>
          </dl>
          Trace id <code>{{ errors.traceId }}</code>
        </div>
      </div>
    </div>
    <div class="c-card__section" *ngIf="extender">
      <h5>
        {{ company.extenderType }}
        <small
          ><a (click)="showSystem = true" *ngIf="extender.hasBeenConnected && showSystem === false"
            ><span class="c-icon c-icon--m">
              <hl-icon icon="chevron-down-24" width="24" height="24"></hl-icon> </span></a
          ><a (click)="showSystem = false" *ngIf="extender.hasBeenConnected && showSystem === true"
            ><span class="c-icon c-icon--m"> <hl-icon icon="chevron-up-24" width="24" height="24"></hl-icon> </span></a
        ></small>
      </h5>
      <div *ngIf="extender.hasBeenConnected && showSystem === true">
        <app-fastpace-company-ccure *ngIf="company.extenderType === 'ccure'" [fastpaceCompany]="company">
        </app-fastpace-company-ccure>
        <app-fastpace-company-onguard *ngIf="company.extenderType === 'onguard'" [fastpaceCompany]="company">
        </app-fastpace-company-onguard>
        <app-fastpace-company-aeos *ngIf="company.extenderType === 'aeos'" [fastpaceCompany]="company">
        </app-fastpace-company-aeos>
        <app-fastpace-company-ac2000 *ngIf="company.extenderType === 'ac2000'" [fastpaceCompany]="company">
        </app-fastpace-company-ac2000>
        <app-fastpace-company-genetec *ngIf="company.extenderType === 'genetec'" [fastpaceCompany]="company">
        </app-fastpace-company-genetec>
        <app-fastpace-company-net2 *ngIf="company.extenderType === 'net2'" [fastpaceCompany]="company">
        </app-fastpace-company-net2>
        <app-fastpace-company-netbox *ngIf="company.extenderType === 'netbox'" [fastpaceCompany]="company">
        </app-fastpace-company-netbox>
        <app-fastpace-company-atium *ngIf="company.extenderType === 'atium'" [fastpaceCompany]="company">
        </app-fastpace-company-atium>
        <app-fastpace-company-brivo *ngIf="company.extenderType === 'brivo'" [fastpaceCompany]="company">
        </app-fastpace-company-brivo>
        <app-fastpace-company-openpath *ngIf="company.extenderType === 'openpath'" [fastpaceCompany]="company">
        </app-fastpace-company-openpath>
        <app-fastpace-company-genea *ngIf="company.extenderType === 'genea'" [fastpaceCompany]="company">
        </app-fastpace-company-genea>
        <app-fastpace-company-gallagher *ngIf="company.extenderType === 'gallagher'" [fastpaceCompany]="company">
        </app-fastpace-company-gallagher>
        <app-fastpace-company-amag *ngIf="company.extenderType === 'amag'" [fastpaceCompany]="company">
        </app-fastpace-company-amag>
      </div>
      <span class="text-warning" *ngIf="!extender.hasBeenConnected"
        >Settings not available until extender is online</span
      >
    </div>
    <div class="c-card__section">
      <h5>
        Tokens
        <small
          ><a (click)="showToken = true" *ngIf="showToken === false"
            ><span class="c-icon c-icon--m">
              <hl-icon icon="chevron-down-24" width="24" height="24"></hl-icon> </span></a
          ><a (click)="showToken = false" *ngIf="showToken === true"
            ><span class="c-icon c-icon--m"> <hl-icon icon="chevron-up-24" width="24" height="24"></hl-icon> </span></a
        ></small>
      </h5>
      <div *ngIf="showToken === true">
        <form (submit)="submit()">
          <div class="l-form__field">
            <pxc-field label="Generates tokens">
              <input type="checkbox" class="c-switch c-switch--locked" disabled="" [checked]="company.generateTokens" />
              <label for="example-switch-locked-on" class="c-switch__skin"></label>
            </pxc-field>
            <small class="c-helper">
              The creation of the access control tokens can be disabled. When disabled, only a visitor’s profile will be
              added to the systems without any token or credential. No extra digits will be added to our Proxyclick QRs.
              Only use this when the customer has specifically confirmed this, as almost all customers will want to use
              our Proxyclick QRs to provide their visitors access.
            </small>
          </div>

          <div class="l-form__field">
            <pxc-field label="Allow physical cards">
              <input
                id="allow-physical-cards"
                type="checkbox"
                class="c-switch"
                [(ngModel)]="company.allowPhysicalCards"
                name="allowPhysicalCards"
                [checked]="company.allowPhysicalCards"
              />
              <label for="allow-physical-cards" class="c-switch__skin"></label>
            </pxc-field>
          </div>

          <div class="l-form__field">
            <pxc-field label="Token group id">
              <input
                type="text"
                required
                class="form-control"
                [(ngModel)]="company.tokenGroupId"
                [readOnly]="true"
                name="tokenGroupId"
              />
            </pxc-field>
            <small class="form-text text-muted"> An id specifying to which tokengroup the company belongs. </small>
          </div>
          <div class="l-grid">
            <div class="l-grid__cell l-grid__cell--6">
              <div class="l-form__field">
                <pxc-field label="Token range minimum">
                  <input
                    type="text"
                    required
                    class="form-control"
                    [(ngModel)]="company.tokenRangeMinimum"
                    [readOnly]="true"
                    name="tokenRangeMinimum"
                  />
                </pxc-field>
                <small class="form-text text-muted"> The minimum range for access control tokens. </small>
              </div>
            </div>
            <div class="l-grid__cell l-grid__cell--6">
              <div class="l-form__field">
                <pxc-field label="Token range maximum">
                  <input
                    type="text"
                    required
                    class="form-control"
                    [(ngModel)]="company.tokenRangeMaximum"
                    [readOnly]="true"
                    name="tokenRangeMaximum"
                  />
                </pxc-field>
                <small class="form-text text-muted"> The maximum range for access control tokens. </small>
              </div>
            </div>
          </div>
          <button type="submit" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
            Save settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
            <hl-icon
              *ngIf="submitting === false && errors === undefined"
              icon="checkmark-circle--color-24"
              width="24"
              height="24"
            ></hl-icon>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
