import { IChecked } from '~/models/utils';
import { Input, Output, EventEmitter, Directive } from '@angular/core';

export interface IHasChecked extends IChecked {
  key: string;
  name: string;
}

@Directive()
export abstract class ListBaseComponent {
  @Input() items: IHasChecked[];

  @Output() onChange: EventEmitter<IHasChecked[]> = new EventEmitter();

  constructor() {}
}
