import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import * as moment from 'moment';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-datepicker',
  templateUrl: './datepicker.html',
  providers: controlValueAccessor(DatePickerComponent),
})
export class DatePickerComponent implements ControlValueAccessor {
  date;
  daysToAdd = 30;

  @Input() addDaysButton = false;

  private onChange: (date: moment.Moment) => void;

  writeValue(date: moment.Moment): void {
    if (typeof date === 'string') {
      date = moment(date);
    }
    this.date = momentToJson(date);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onInternalChange() {
    const newDate = jsonToMoment(this.date);
    this.onChange(newDate);
  }

  registerOnTouched(fn: any): void {
    return;
  }
  setDisabledState?(isDisabled: boolean): void {
    return;
  }

  addDays(days: number) {
    if (!days || days <= 0 || !this.date) {
      return;
    }
    const newDate = jsonToMoment(this.date).add(days, 'days');
    this.date = momentToJson(newDate);
    this.onChange(newDate);
  }
}

function jsonToMoment(date: { year: number; month: number; day: number }): moment.Moment {
  return moment()
    .year(date.year)
    .month(date.month - 1)
    .date(date.day);
}

function momentToJson(date: moment.Moment): { year: number; month: number; day: number } {
  return date
    ? {
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
      }
    : null;
}
