import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { IAppState } from '~/store/app-state';
import { DomainCompaniesSortAction } from '~/store/domains/domains.reducer';
import { ICompanyFilter } from '../../../../../../../shared/filters';

@Component({
  selector: 'pxc-domain-companies',
  templateUrl: './domain-companies.html',
})
export class DomainCompaniesComponent {
  domain$ = this.store.select('domain', 'value');
  loading$ = this.store.select('domain-companies', 'loading');
  companies$ = this.store.select('domain-companies', 'value');

  columns: IOption[] = [
    { label: 'Ref', value: 'id' },
    { label: '', value: null },
    { label: 'Name', value: 'name' },
    { label: 'VM Status', value: 'vm.status' },
    { label: 'Plan', value: 'vm.featureListId' },
    { label: 'MRR', value: 'mrr' },
    { label: 'Checkins last 30 Days', value: 'checkinsLast30Days' },
    { label: 'Users', value: 'userCount' },
    { label: 'Created', value: 'createdAt' },
  ];

  filter: ICompanyFilter = {
    page: 1,
    pageSize: 100,
    sort: 'desc',
    sortBy: 'name',
    q: '',
  };

  constructor(private store: Store<IAppState>) {}

  onFilterChange() {
    this.store.dispatch({
      type: 'test',
    });
    const action = DomainCompaniesSortAction(this.filter);
    this.store.dispatch(action);
  }
}
