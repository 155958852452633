import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-extender-seq-link',
  templateUrl: './extender.seq.link.component.html',
})
export class ExtenderSeqLinkComponent {
  @Input() extenderId: string;
  @Input() clientId: string;
}
