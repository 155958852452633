<pxc-loading *pxcHasRight='"tab_provisioning"' [loading]="loading$ | async">
  <form ngNativeValidate (ngSubmit)="save(ldap)" class="form-small" *ngIf="ldap$ | async as ldap">
    <div class="row">
      <div class="col-md-4">
        <pxc-ldap-status [ldap]="ldap"></pxc-ldap-status>
      </div>
      <div class="col-md-8">
        <button
          *ngIf="ldap.status !== 'RUNNING'"
          type="button"
          (click)="start(ldap)"
          class="c-button c-button--primary c-button--s mr-2"
        >
          <i class="fas fa-play mr-1"></i> Schedule sync
        </button>
        <button type="button" (click)="syncNow(ldap)" class="c-button c-button--primary c-button--s mr-2">
          <i class="fas fa-fast-forward mr-1"></i> Sync now
        </button>
        <button *ngIf="ldap.status !== 'STOPPED'" type="button" (click)="stop(ldap)" class="btn btn-danger">
          <i class="fas fa-stop"></i> Stop
        </button>
        <div class="mt-1 flex center f-m-h-1">
          <button type="button" class="btn btn-outline-secondary" (click)="testConnection(ldap)">
            <i class="fas fa-plug"></i> Test Connection
          </button>
          <div *ngIf="testConnection$ | async as testConnection">
            <div *ngIf="testConnection.value">Connection succesfull</div>
            <div *ngIf="testConnection.error as error">Error: {{error.error.type}}: {{error.error.message}}</div>
          </div>
        </div>
        <div *pxcHasRight="'ldap_extract'" class="mt-1 flex center f-m-h-1">
          <button type="button" class="btn btn-outline-secondary" (click)="extractUsers(ldap)">
            <i class="fas fa-users"></i> Extract Users
          </button>
          <div *ngIf="extractUsers$ | async as extractUsers">
            <div *ngIf="extractUsers.value">Got Users</div>
            <div *ngIf="extractUsers.error as error">Error: {{error.error.type}}: {{error.error.message}}</div>
          </div>
        </div>
        <button type="submit" class="btn btn-outline-primary mt-1"><i class="fas fa-save"></i> Save</button>
      </div>
    </div>
    <div class="mt-2">
      <h4 class="my-4">General</h4>
      <pxc-field label="Company">
        <pxc-companies-typeahead [(ngModel)]="ldap.company" name="company"></pxc-companies-typeahead>
      </pxc-field>
      <pxc-field label="Alias">
        <input [(ngModel)]="ldap.alias" class="form-control" name="alias" required type="text" />
      </pxc-field>
      <pxc-field label="Contact Firstname">
        <input class="form-control" [(ngModel)]="ldap.contactFirstname" name="firstname" type="text" />
      </pxc-field>
      <pxc-field label="Contact Lastname">
        <input class="form-control" [(ngModel)]="ldap.contactLastname" name="lastname" type="text" />
      </pxc-field>
      <pxc-field label="Contact Email">
        <input class="form-control" [(ngModel)]="ldap.contactEmail" name="email" type="email" />
      </pxc-field>
      <h4 class="my-4">Server</h4>
      <pxc-field label="Server IP">
        <div class="form-inline">
          <input class="form-control input-medium" [(ngModel)]="ldap.server" required name="server" type="text" />
          <span class="mx-2">:</span>
          <input class="form-control" required [(ngModel)]="ldap.port" name="port" type="number" />
          <pxc-checkbox class="ml-2" [(ngModel)]="ldap.secured" name="secured">Secured</pxc-checkbox>
        </div>
      </pxc-field>
      <pxc-field label="Authentication">
        <pxc-radio
          name="authenticationType"
          [(ngModel)]="ldap.authenticationType"
          [style]="'inline'"
          [options]="authentificationOptions"
        ></pxc-radio>
      </pxc-field>
      <div *ngIf="ldap.authenticationType === 'BASIC'">
        <pxc-field label="Username">
          <input class="form-control" required [(ngModel)]="ldap.username" name="username" type="text" />
        </pxc-field>
        <pxc-field label="Password">
          <input class="form-control" required [(ngModel)]="ldap.password" name="password" type="password" />
        </pxc-field>
      </div>
      <pxc-field label="Base DN">
        <input
          class="form-control input-fluid"
          required
          [(ngModel)]="ldap.userSearchBase"
          name="userSearchBase"
          type="text"
        />
      </pxc-field>
      <pxc-field label="Search Query">
        <input class="form-control input-fluid" required [(ngModel)]="ldap.userQuery" name="userQuery" type="text" />
      </pxc-field>
      <pxc-field label="Login Query">
        <input
          class="form-control input-fluid"
          required
          [(ngModel)]="ldap.userDnQuery"
          name="userDnQuery"
          type="text"
        />
      </pxc-field>
      <h4 class="my-4">Attributes</h4>
      <pxc-field *ngFor="let attribute of ldap.attributes" label="{{attribute.field | capitalize}}">
        <div class="flex">
          <input
            class="form-control"
            [(ngModel)]="attribute.name"
            name="{{attribute.field}}"
            type="text"
            required="{{attribute.field === 'FIRSTNAME' || attribute.field === 'LASTNAME' || attribute.field === 'EMAIL'}}"
          />
          <pxc-languages-dropdown
            *ngIf="attribute.field === 'LANGUAGE'"
            class="ml-2"
            [(ngModel)]="attribute.defaultValue"
            name="defaultLanguage"
            [valueAsCode]="true"
          ></pxc-languages-dropdown>
        </div>
      </pxc-field>
    </div>
  </form>
</pxc-loading>
