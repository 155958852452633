import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';
import { IDialog } from '~/models/notifications';

@Injectable()
export class DialogService {
  dialog$: Observable<IDialog>;

  private observer: Observer<IDialog>;

  constructor() {
    this.dialog$ = new Observable<IDialog>(observer => (this.observer = observer)).pipe(share());
  }

  info(title: string, message: string) {
    this.observer.next({
      type: 'info',
      title: title,
      message: message,
    });
  }

  confirm(title: string, message: string): Promise<boolean> {
    return new Promise(resolve => {
      this.observer.next({
        type: 'confirm',
        title: title,
        message: message,
        callback: resolve,
      });
    });
  }
}
