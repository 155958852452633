<div class="c-card__section">
  <h3>Netbox settings</h3>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Manage access levels?</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-switch
          [(ngModel)]="setupState.netboxManageAccessLevels"
          name="manageAccessLevels"
          (ngModelChange)="switchManageAccessLevels()"
        ></pxc-switch>
      </div>
    </div>
    <div class="c-helper">
      Proxyclick can be configured to skip creation of access levels for Netbox. As doing this requires full system
      privileges on the master partition, skipping can be required if our Netbox user cannot be granted sufficient
      permissions. All access levels will have to be added manually, with exact formatting.
    </div>
  </div>
  <div class="l-form__field" *ngIf="setupState.netboxManageAccessLevels">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Use Multi-Partition Access Level Groups?</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-switch
          [(ngModel)]="setupState.netboxUseMultiPartitionAccessLevelGroups"
          name="useMultiPartitionAccessLevelGroups"
        ></pxc-switch>
      </div>
    </div>
    <div class="c-helper">
      Proxyclick can be configured to use multi-partition access level groups instead of standard access levels. This is
      typically used when setting up a multi-tenant building with a lobby-setup.
    </div>
  </div>
  <div class="l-form__field">
    <label class="c-label">Visitor id field</label>

    <input
      type="number"
      class="c-input c-input--s"
      min="1"
      max="20"
      required
      [(ngModel)]="setupState.netboxVisitorIdField"
    />

    <div class="c-helper">Select the visitor id field (1-20) where we will insert the visitor id.</div>
  </div>
  <div class="l-form__field">
    <label class="c-label">Visitor status field</label>

    <input
      type="number"
      class="c-input c-input--s"
      min="1"
      max="20"
      required
      [(ngModel)]="setupState.netboxVisitorStatusField"
    />

    <div class="c-helper">Select the visitor status field (1-20) where we will insert the visitor status.</div>
  </div>

  <div class="l-form__field">
    <label class="c-label">Partition id</label>

    <input type="number" class="c-input c-input--s" min="0" [(ngModel)]="setupState.netboxPartitionKey" />

    <div class="c-helper">
      Connections to Netbox can <strong>optionally</strong> use a partition key to insert visitors only in that
      partition.
    </div>
  </div>

  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Card Format (Access Card Manager)</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-obj-dropdown
          [(ngModel)]="setupState.netboxCardFormatOption"
          [options]="cardFormatOptions"
          key="value"
          label="label"
          name="cardFormatOption"
          allowNull="true"
        ></pxc-obj-dropdown>
      </div>
    </div>
    <div class="c-helper">When using access card manager, this card format will be used</div>
  </div>

  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>
