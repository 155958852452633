import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { IQueryablePaginationFilter } from '~/models/filters';
import { ExtenderService } from '~/services/extender.service';
import { USER_LOGGED_IN } from '~/store/app-state';
import { ExtenderActions } from '~/store/extender/extender.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';

@Injectable()
export class ExtenderEffects {
  constructor(private actions$: Actions<PayloadAction>, private Extender: ExtenderService) {}

  
  initExtenders$ = createEffect(() => this.actions$.pipe(
    ofType(USER_LOGGED_IN),
    map(() =>
      ExtenderActions.Extenders.Load({
        page: 1,
        pageSize: 20,
      })
    )
  ));

  
  extenders$ = createEffect(() => generateAsyncEffect(this.actions$, ExtenderActions.Extenders, (filter: IQueryablePaginationFilter) =>
    this.Extender.listExtenders(filter)
  ));

  
  extender$ = createEffect(() => generateAsyncEffect(this.actions$, ExtenderActions.Extender, extenderId =>
    this.Extender.findExtender(extenderId)
  ));
}
