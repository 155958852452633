import { Component, Input, OnInit } from '@angular/core';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';
import { FastPaceService } from '~/services/fastpace.service';
import { BehaviorSubject } from 'rxjs';
import { ExtenderService } from '~/services/extender.service';

type TokenGroupMode = 'extender' | 'search' | 'create';

@Component({
  selector: 'app-fastpace-company-setup-select-token-group',
  templateUrl: './select-token-group.component.html',
})
export class SelectTokenGroupComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;

  loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  tokenGroupsOnExtender: IOption[] = [];
  searchedTokenGroups: IOption[] = [];

  selectedTokenGroup: string;
  tokenGroupSearchTerm: string;
  tokenGroupMode: BehaviorSubject<TokenGroupMode> = new BehaviorSubject('extender');
  createTokenGroupName: string = '';

  constructor(private extenderService: ExtenderService) {}
  ngOnInit(): void {
    if (this.setupState.extender) {
      this.extenderService
        .listTokenGroups({ page: 1, pageSize: 1000 }, this.setupState.extender.extenderId)
        .toPromise()
        .then(r => {
          this.tokenGroupsOnExtender = r.items.map(g => {
            return { label: g.description, value: g.tokenGroupId };
          });
          if (this.tokenGroupsOnExtender.length < 1) {
            this.tokenGroupMode.next('create');
          } else {
            this.tokenGroupMode.next('extender');
          }
          this.loading.next(false);
        });
    } else {
      this.setupState.tokenGroupName = this.setupState.company.name;
      this.createTokenGroupName = this.setupState.company.name;
      this.tokenGroupMode.next('create');
      this.loading.next(false);
    }
  }

  selectTokenGroup(group: string) {
    this.selectedTokenGroup = group;
    const tokenGroupOnExtender = this.tokenGroupsOnExtender.filter(tg => tg.value === group);
    if (tokenGroupOnExtender.length > 0) {
      this.tokenGroupMode.next('extender');
      this.searchedTokenGroups = [];
      this.tokenGroupSearchTerm = '';
      this.createTokenGroupName = '';
      this.setupState.selectedTokenGroup = tokenGroupOnExtender[0];
    } else {
      this.setupState.selectedTokenGroup = this.searchedTokenGroups.filter(tg => tg.value === group)[0];
    }

    this.setupState.tokenGroupName = undefined;
  }

  searchTokenGroup() {
    this.loading.next(true);
    this.extenderService
      .listTokenGroups({
        page: 1,
        pageSize: 10,
        q: this.tokenGroupSearchTerm,
      })
      .toPromise()
      .then(r => {
        this.searchedTokenGroups = r.items.map(g => {
          return { label: g.description, value: g.tokenGroupId };
        });
        this.loading.next(false);
      });
  }

  tokenGroupModeChanged(newMode: TokenGroupMode) {
    this.tokenGroupMode.next(newMode);
    this.searchedTokenGroups = [];
    this.createTokenGroupName = '';
    this.selectedTokenGroup = '';
    this.tokenGroupSearchTerm = '';
  }

  tokenGroupNameChanged($event) {
    this.setupState.selectedTokenGroup = undefined;
    this.setupState.tokenGroupName = $event.target.value;
  }

  get canGoNext() {
    if (
      this.tokenGroupMode.value === 'create' &&
      this.createTokenGroupName.length >= 10 &&
      this.createTokenGroupName.length <= 150
    ) {
      return true;
    } else if (this.selectedTokenGroup !== '' && this.selectedTokenGroup !== undefined) {
      return true;
    }
    return false;
  }
}
