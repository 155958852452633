import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { from, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-switch',
  templateUrl: './button-switch.html',
  styleUrls: ['./button-switch.scss'],
  providers: controlValueAccessor(ButtonSwitchComponent),
})
export class ButtonSwitchComponent implements ControlValueAccessor {
  @Input()
  public isDisabled: boolean;
  @Input() readOnly = false;

  @Input()
  onChange: (value: any) => Observable<any>;

  isOn: boolean;
  private onChangeInternal;
  loading = false;

  switch() {
    if (this.isDisabled || this.readOnly) {
      return;
    }
    this.isOn = !this.isOn;
    this.onChangeInternal(this.isOn);
    if (this.onChange) {
      this.loading = true;
      from(this.onChange(this.isOn))
        .pipe(finalize(() => (this.loading = false)))
        .subscribe();
    }
  }

  writeValue(obj: any): void {
    this.isOn = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeInternal = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
