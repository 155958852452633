import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pxc-button',
  templateUrl: './button.html',
})
export class ButtonComponent implements OnInit, OnDestroy {
  internalLoading = false;

  @Input()
  loading?: Observable<boolean>;

  @Input()
  onClick: Observable<any> & (() => Promise<any>);

  @Input() disabled;

  @Input()
  size = 'small';

  @Input()
  type = '';

  @Input()
  fluid = false;

  private subscription: Subscription;

  ngOnInit() {
    if (this.loading) {
      this.subscription = this.loading.subscribe(loading => (this.internalLoading = loading));
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  click() {
    if (this.disabled) {
      return;
    }
    // Pass a loading observable to override standard behavior
    if (this.loading !== undefined) {
      return;
    }
    if (this.internalLoading) {
      return;
    }
    this.internalLoading = true;

    if (this.onClick) {
      if (this.onClick.pipe) {
        this.subscription = this.onClick.pipe(finalize(() => (this.internalLoading = false))).subscribe();
      } else {
        this.onClick()
          .then(() => (this.internalLoading = false))
          .catch(() => (this.internalLoading = false));
      }
    }
  }
}
