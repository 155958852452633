<div *ngIf="termsAndConditions$ | async as termsAndConditions">
  <pxc-field label="Terms & Conditions">
    <span *ngIf="termsAndConditions.agreedAt">Agreed</span>
    <span *ngIf="!termsAndConditions.agreedAt">Not Agreed</span>
  </pxc-field>
  <div *ngIf="termsAndConditions.agreedAt">
    <pxc-field label="Agreed by">
      <a routerLink="/users/{{termsAndConditions.agreedBy.id}}"
        >{{termsAndConditions.agreedBy.firstname}} {{termsAndConditions.agreedBy.lastname}}</a
      >
    </pxc-field>
    <pxc-field label="Agreed on">
      <span>{{termsAndConditions.agreedAt | time:'LL' }}</span>
    </pxc-field>
    <pxc-field label="Version">
      <a href="{{termsAndConditions.version}}" target="_blank">{{termsAndConditions.version}}</a>
    </pxc-field>
  </div>
</div>
