import { Component, Input, OnInit } from '@angular/core';
import { ExtenderDetailsState } from '../extender.details.state';

@Component({
  selector: 'app-extender-details-genea',
  templateUrl: './extender.details.genea.component.html',
})
export class ExtenderDetailsGeneaComponent {
  @Input() state: ExtenderDetailsState;
}
