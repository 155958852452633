import { Component } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import * as moment from 'moment';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-month-picker',
  templateUrl: './monthpicker.html',
  providers: controlValueAccessor(MonthPickerComponent),
})
export class MonthPickerComponent implements ControlValueAccessor {
  month: moment.Moment = moment();
  options: IOption[];
  private onChange;

  private computeOptions() {
    const values = Array.from(Array(18).keys());
    this.options = values.map(value => ({
      value: moment(this.month).subtract(value, 'month'),
      label: moment(this.month).subtract(value, 'month').format('MMMM YYYY'),
    }));
  }

  onChangeInternal() {
    this.onChange(this.month);
  }

  writeValue(date: string): void {
    if (date) {
      this.month = moment(date, 'YYYY-MM');
    }
    this.computeOptions();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    return;
  }
  setDisabledState?(isDisabled: boolean): void {
    return;
  }
}
