<div *ngIf="kpis" class="kpi-table">
  <h2 class="mb-4">Proxyclick Key Metrics</h2>
  <table>
    <thead>
      <th></th>
      <th width="200">{{kpis.headers.prev}}</th>
      <th>{{kpis.headers.last}}</th>
      <th>{{kpis.headers.target}}</th>
    </thead>
    <tbody>
      <tr class="mb-1" *ngFor="let kpi of kpis.data">
        <td><strong>{{kpi.field}}</strong></td>
        <td>{{kpi.prev}}</td>
        <td [ngClass]="kpi.lastClass">{{kpi.last}}</td>
        <td class="target">{{kpi.target}}</td>
      </tr>
    </tbody>
  </table>
</div>
