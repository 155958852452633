import { createAction } from '@ngrx/store';
import { IFeatureListBase } from '@proxyclick/data-model';
import { IBillingStatus, IFeatureList } from '@proxyclick/data-model';
import { createAsyncSaveActions } from '~/utils/async-generator';

export const BillingActions = {
  BillingLoad: createAction('billing/load', (payload: { companyId: string }) => ({
    payload,
    meta: { loadingKey: 'billing/load', loadingAction: 'start' },
  })),
  FeatureListsLoad: createAction('billing/feature-lists/load', (payload: { companyId: string }) => ({ payload })),
  BillingLoaded: createAction('billing/loaded', (payload: { billing: IBillingStatus }) => ({
    ...payload,
    meta: { loadingKey: 'billing/load', loadingAction: 'stop' },
  })),
  FeatureListsLoaded: createAction('billing/feature-lists', (payload: { featureLists: IFeatureListBase[] }) => ({
    ...payload,
  })),

  ExtendTrial: createAction('subscription/extend-trial/start', (payload: { companyId: string; endAt: string }) => ({
    payload,
  })),
  ExtendTrialDone: createAction('subscription/extend-trial/done', (payload: { companyId: string }) => ({ payload })),
  ExtendTrialError: createAction('subscription/extend-trial/error', (payload: { error: Error }) => ({ payload })),

  BlockSubscription: createAction('subscription/block/load', (payload: { companyId: string }) => ({ payload })),
  BlockSubscriptionDone: createAction('subscription/block/done', (payload: { companyId: string }) => ({ payload })),
  BlockSubscriptionError: createAction('subscription/block/error', (payload: { error: Error }) => ({ payload })),

  UnblockSubscription: createAction('subscription/unblock/load', (payload: { companyId: string }) => ({ payload })),
  UnblockSubscriptionDone: createAction('subscription/unblock/done', (payload: { companyId: string }) => ({ payload })),
  UnblockSubscriptionError: createAction('subscription/unblock/error', (payload: { error: Error }) => ({ payload })),

  CancelSubscription: createAction('subscription/cancel', (payload: { companyId: string; options: any }) => ({
    payload,
  })),
  CancelSubscriptionDone: createAction('subscription/cancel/done', (payload: { companyId: string }) => ({ payload })),

  UpdateBilling: createAsyncSaveActions<{ companyId: string; billing: IBillingStatus }, { companyId: string }>(
    'billing/update'
  ),

  ActivateLocation: createAsyncSaveActions<{ companyId: string }, { companyId: string }>('billing/activate-location'),

  MigrateBilling: createAction('billing/migrate', (payload: { companyId: string; featureLists: IFeatureList[] }) => ({
    payload,
  })),
  DowngradeBilling: createAsyncSaveActions<{ companyId: string; featureListId: number }, { companyId: string }>(
    'billing/downgrade'
  ),

  ActivateFeatureList: createAction(
    'feature-lists/activate/start',
    (payload: { companyId: string; featureListId: number }) => ({ payload })
  ),
  ActivateFeatureListDone: createAction(
    'feature-lists/activate/done',
    (payload: { companyId: string; featureListId: number }) => ({ payload })
  ),
  ActivateFeatureListError: createAction('feature-lists/activate/error', (payload: { error: Error }) => ({ payload })),

  CancelFeatureList: createAction(
    'feature-lists/cancel/start',
    (payload: { companyId: string; featureListId: number }) => ({ payload })
  ),
  CancelFeatureListDone: createAction(
    'feature-lists/cancel/done',
    (payload: { companyId: string; featureListId: number }) => ({ payload })
  ),
  CancelFeatureListError: createAction('feature-lists/cancel/error', (payload: { error: Error }) => ({ payload })),

  ResetFeatureList: createAction(
    'feature-lists/reset/start',
    (payload: { companyId: string; featureListId: number }) => ({ payload })
  ),
  ResetFeatureListDone: createAction(
    'feature-lists/reset/done',
    (payload: { companyId: string; featureListId: number }) => ({ payload })
  ),
  ResetFeatureListError: createAction('feature-lists/reset/error', (payload: { error: Error }) => ({ payload })),

  SubscriptionFormInit: createAction('subscription-form/init'),
};
