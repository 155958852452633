import {
  Company,
  Organisation,
  OrganisationDomain,
  PlatformLocationCompany,
  TenantOrganisation,
} from '@proxyclick/api-client';
import { IOrganisationUsersFilter, IPaginatedUsers } from '~/models/filters';
import { IUnlinkUserFromOrganisation } from '~/services/users.service';
import { createAsyncLoadActions, createAsyncSaveActions } from '~/utils/async-generator';

// Organisation actions
export const OrganisationActions = createAsyncLoadActions<string, Organisation>('organisation');
export const OrganisationUsersActions = createAsyncLoadActions<IOrganisationUsersFilter, IPaginatedUsers>(
  'organisation/users'
);
export const OrganisationLinkUsersAction = createAsyncSaveActions<
  { organisationId: string; userIds: string[] },
  { organisationId: string; userIds: string[] }
>('organisation/link-user');

export const OrganisationUnlinkUserAction = createAsyncLoadActions<
  IUnlinkUserFromOrganisation,
  IOrganisationUsersFilter
>('organisation/unlink-user');

export const OrganisationDomainsActions = createAsyncLoadActions<void, OrganisationDomain[]>('organisation/domains');

export const OrganisationDomainsUpdateActions = createAsyncSaveActions<
  OrganisationDomain & { organisationId: string },
  OrganisationDomain
>('organisation/domain-update');

export const OrganisationDomainsDeleteActions = createAsyncSaveActions<
  { organisationId: string; domain: OrganisationDomain['domain'] },
  { organisationId: string; domain: OrganisationDomain['domain'] }
>('organisation/domain-delete');

export const OrganisationDomainsAddActions = createAsyncSaveActions<
  { organisationId: string; domains: string[] },
  OrganisationDomain[]
>('organisation/domain-add');

export const OrganisationCompaniesActions = createAsyncLoadActions<void, Company[]>('organisation/companies');

export const OrganisationCompaniesDeleteActions = createAsyncSaveActions<
  { organisationId: string; companyId: string },
  void
>('organisation/companies-delete');

export const OrganisationCompaniesAddActions = createAsyncSaveActions<
  { organisationId: string; companyIds: string[] },
  void
>('organisation/companies-add');

export const OrganisationPlatformTenantActions = createAsyncLoadActions<void, TenantOrganisation | null>(
  'organisation/platformTenantOrganisation'
);

export const OrganisationPlatformTenantAddActions = createAsyncSaveActions<
  { organisationId: string; tenantId: string; locationMappings: PlatformLocationCompany[] },
  TenantOrganisation
>('organisation/platformTenantOrganisation-add');

export const OrganisationPlatformTenantUserSyncActions = createAsyncSaveActions<{ organisationId: string }, void>(
  'organisation/platformTenantOrganisation-user-sync'
);
