import { Component, Input, OnChanges } from '@angular/core';
import * as showdown from 'showdown';

@Component({
  selector: 'pxc-markdown-preview',
  templateUrl: './markdown-preview.html',
  styleUrls: ['./markdown-preview.scss'],
})
export class MarkdownPreviewComponent implements OnChanges {
  @Input()
  public content;

  public transformedContent;

  ngOnChanges() {
    this.transformedContent = this.transform(this.content);
  }

  transform(input: string): string {
    const converter = new showdown.Converter();
    return converter.makeHtml(input);
  }
}
