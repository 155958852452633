import { Actions, ofType } from '@ngrx/effects';
import { ActionCreator, createAction, Creator } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

export class PayloadAction<T = any> {
  type: string;
  payload: T;

  constructor(payload: T, type?: string) {
    this.payload = payload;
    if (type) {
      this.type = type;
    }
  }
}

export interface IAsyncState<T> {
  value: T;
  loading: boolean;
  error: Error;
}

type IAsyncReducer<T> = (state: IAsyncState<T>, action: PayloadAction) => IAsyncState<T>;

// Generates a new default value (to avoid returnign the same instance to every reducer)
const getDefaultValue = () => ({
  value: null,
  loading: false,
  error: null,
});

export function createAsyncReducer<T>(
  startWorkAction: string,
  finishWorkAction: string,
  errorAction: string
): IAsyncReducer<T> {
  return function reducer(state: IAsyncState<T> = getDefaultValue(), action: PayloadAction<T | Error>): IAsyncState<T> {
    switch (action.type) {
      case startWorkAction:
        return {
          ...state,
          value: null,
          loading: true,
          error: null,
        };
      case finishWorkAction:
        return {
          ...state,
          value: action.payload as T,
          loading: false,
          error: null,
        };
      case errorAction:
        return {
          ...state,
          loading: false,
          value: null,
          error: action.payload as Error,
        };
      default:
        return state;
    }
  };
}

// Async load helper functions

export interface IAsyncLoadActions<LoadType, LoadedType> {
  Load:  ActionCreator<string, Creator<[LoadType], PayloadAction<LoadType>>>;
  Loaded: ActionCreator<string, Creator<[LoadedType], PayloadAction<LoadedType>>>;
  Error: ActionCreator<string, Creator<[Error], PayloadAction<Error>>>;
}

export function createAsyncLoadReducer<LoadType, LoadedType, State>(
  actions: IAsyncLoadActions<LoadType, LoadedType>
): IAsyncReducer<State> {
  return createAsyncReducer<State>(actions.Load.type, actions.Loaded.type, actions.Error.type);
}

export function createAsyncLoadActions<LoadType, LoadedType>(name: string): IAsyncLoadActions<LoadType, LoadedType> {
  const loadingKey = (Math.random() + '').substring(2);

  return {
    Load: createAction(`${name}/load`, (payload: LoadType) => ({ payload, meta: { loadingKey, loadingAction: 'start', async: true }})),
    Loaded: createAction(`${name}/loaded`, (payload: LoadedType) => ({ payload, meta: { loadingKey, loadingAction: 'stop', async: true }})),
    Error: createAction(`${name}/error`, (payload: Error) => ({ payload })),
  };
}

// Async save helper functions

interface IAsyncSaveActions<SaveType, SavedType> {
  Save: ActionCreator<string, Creator<[SaveType], PayloadAction<SaveType>>>;
  Saved: ActionCreator<string, Creator<[SavedType], PayloadAction<SavedType>>>;
  Error: ActionCreator<string, Creator<[Error], PayloadAction<Error>>>;
}

export function createAsyncSaveReducer<SaveType, SavedType, State>(
  actions: IAsyncSaveActions<SaveType, SavedType>
): IAsyncReducer<State> {
  return createAsyncReducer<State>(actions.Save.type, actions.Saved.type, actions.Error.type);
}

export function createAsyncSaveActions<SaveType, SavedType>(name: string): IAsyncSaveActions<SaveType, SavedType> {
  const loadingKey = (Math.random() + '').substring(2);
  return {
    Save: createAction(`${name}/save`, (payload: SaveType) => ({ payload, meta: { loadingKey, loadingAction: 'start', async: true }})),
    Saved: createAction(`${name}/saved`, (payload: SavedType) => ({ payload, meta: { loadingKey, loadingAction: 'start', async: true }})),
    Error: createAction(`${name}/error`, (payload: Error) => ({ payload })),
  };
}

// Effects generators

export function generateAsyncEffect(
  actions$: Actions<PayloadAction>,
  actions: IAsyncLoadActions<any, any>,
  effect: (action?) => Observable<any>,
  overrideValue: boolean = false
): Observable<any> {
  return actions$.pipe(
    ofType(actions.Load.type),
    switchMap(action =>
      effect(action.payload).pipe(
        map(value => ({
          ...actions.Loaded(overrideValue ? overrideValue : value),
          meta: { ...((action as any).meta || {}), loadingAction: 'stop' },
        })),
        catchError(err => of(actions.Error(err)))
      )
    )
  );
}

export function generateAsyncSaveEffect<TSave, TSaved>(
  actions$: Actions<PayloadAction<TSave>>,
  actions: IAsyncSaveActions<TSave, TSaved>,
  effect: (action: TSave) => Observable<TSaved>
): Observable<any> {
  return actions$.pipe(
    ofType(actions.Save.type),
    concatMap(action =>
      effect(action.payload).pipe(
        map(value => ({
          ...actions.Saved(value),
          meta: { ...((action as any).meta || {}), loadingAction: 'stop' },
        })),
        catchError(err => of(actions.Error(err)))
      )
    )
  );
}
