import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';
import { VisitorsService } from '../../services/visitors.service';
import { VisitorsActions } from './visitors.reducer';

@Injectable()
export class VisitorsEffects {
  constructor(private actions$: Actions<PayloadAction>, private visitorsService: VisitorsService) {}

  
  loadAll$ = createEffect(() => generateAsyncEffect(this.actions$, VisitorsActions, (data: { email: string }) =>
    this.visitorsService.getVisitorsByEmail(data.email)
  ));
}
