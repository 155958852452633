import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { createAction } from '@ngrx/store';
import { ControllersCompaniesFastPaceCompanyResponseV1 } from '@proxyclick/fastpace-client';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IQueryablePaginationFilter } from '~/models/filters';
import { IPaginatedResponse } from '~/store/fastpace/fastpace.models';
import { FastPaceCompanyActions } from '~/store/fastpace/fastpace.reducer';
import { FastPaceSelector } from '~/store/fastpace/fastpace.selectors';
import { AppStore } from '~/store/store';

@Component({
  selector: 'app-fastpace-companies',
  templateUrl: './fastpace.companies.component.html',
})
export class FastPaceCompaniesComponent implements OnInit, OnDestroy {
  public static Loaded = createAction('FastPaceCompaniesComponent/Loaded');

  public filter: IQueryablePaginationFilter;
  public companies$ = this.store.select<IPaginatedResponse<ControllersCompaniesFastPaceCompanyResponseV1>>(
    FastPaceSelector.getCompanies
  );
  public loading$ = this.store.select(FastPaceSelector.getCompaniesLoading);
  public error$ = this.store.select(FastPaceSelector.getCompaniesError);

  public total: number;
  public limit: number;
  public companyFilterSubscription: Subscription;
  constructor(private store: AppStore, private router: Router) {}

  public ngOnInit(): void {
    this.filter = {
      q: '',
      page: 1,
      pageSize: 20,
    };
    this.companyFilterSubscription = this.companies$.pipe(filter(a => !!a)).subscribe(companies => {
      this.total = companies.totalAmount;
      this.limit = this.filter.pageSize;
    });
  }

  public ngOnDestroy(): void {
    this.companyFilterSubscription.unsubscribe();
  }

  public loadWithFilter() {
    return () => {
      this.filter.page = 1;
      return this.loadCompanies();
    };
  }

  private loadCompanies() {
    return this.store.dispatchAsync(FastPaceCompanyActions.Companies.Load(this.filter));
  }

  public onPageChange(page) {
    this.filter.page = page;
    this.loadCompanies();
  }

  public onCompanyClick(companyId: string) {
    this.router.navigate(['fastpace', 'companies', companyId]);
  }
}
