import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of as observableOf, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { AdminUserActions, CreateAdminUser, UpdateAdminUser } from '~/store/admin-users/admin-users.actions';
import { RightsActions } from '~/store/rights/rights.actions';
import { AppStore } from '~/store/store';
import { IAdminUserDB, UserRight } from '../../../../../../shared/models';
import { teams } from '../../../utils/teams';

@Component({
  selector: 'pxc-admin-users-create',
  templateUrl: './admin-users-create.html',
  styleUrls: ['./admin-users-create.scss'],
})
export class CreateAdminUserComponent implements OnInit, OnDestroy {
  constructor(private store: AppStore, private route: ActivatedRoute, private Notifications: NotificationsService) {}

  rights$ = this.store.select('rights', 'value');
  rightsTeams = teams;
  rights = [];
  adminUserRights = [];
  adminUserId;
  adminUser$ = this.store.select('admin-user', 'value');
  breadcrumbs$;
  adminUserLoading = true;
  rightsLoading = true;
  private componentDestroyed: Subject<any> = new Subject();

  ngOnInit() {
    this.store.dispatchAsync(RightsActions.Load()).then(() => (this.rightsLoading = false));
    this.rights$.pipe(takeUntil(this.componentDestroyed)).subscribe(rights => {
      if (rights) {
        this.rights = [];
        rights.map(r => this.rights.push({ name: r.name, checked: false }));
      }
    });
    this.route.queryParams.pipe(takeUntil(this.componentDestroyed)).subscribe(param => {
      if (param.id) {
        this.breadcrumbs$ = this.adminUser$.pipe(
          filter(a => !!a),
          map((adminUser: IAdminUserDB) => [
            { label: 'Admin users', url: '/admin-users' },
            {
              label: `${adminUser.firstName} ${adminUser.lastName}`,
              url: `/users-admin/create`,
              queryParam: adminUser.id,
            },
          ])
        );
        this.adminUserId = param.id;
        this.store
          .dispatchAsync(AdminUserActions.Load({ adminUserId: param.id }))
          .then(() => (this.adminUserLoading = false));
      } else {
        this.breadcrumbs$ = observableOf([
          { label: 'Admin users', url: '/admin-users' },
          { label: 'New admin user', url: `/users-admin/create` },
        ]);
      }
    });
    this.adminUser$.pipe(takeUntil(this.componentDestroyed)).subscribe(adminUser => {
      if (adminUser) {
        adminUser.rights.map(right => this.checkRights(true, right));
      }
    });
  }

  ngOnDestroy() {
    this.clearRights();
    this.componentDestroyed.next();
  }

  createAdminUser(form: NgForm) {
    const checkedRights = [];
    this.rights.filter(r => {
      if (r.checked) {
        checkedRights.push(r.name);
      }
    });
    form.value.rights = checkedRights;
    this.store
      .dispatchAsync(CreateAdminUser.Save({ adminUser: form.value }))
      .then(() => this.Notifications.success('Admin user succesfully updated'));
  }

  updateAdminUser(updateForm: NgForm) {
    const checkedRights = [];
    this.rights.filter(r => {
      if (r.checked) {
        checkedRights.push(r.name);
      }
    });
    updateForm.value.rights = checkedRights;
    updateForm.value.id = this.adminUserId;
    this.store
      .dispatchAsync(UpdateAdminUser.Save({ adminUser: updateForm.value }))
      .then(() => this.Notifications.success('Admin user succesfully updated'));
  }

  checkRights(checked: any, right: UserRight) {
    this.rights.map(r => {
      if (right === r.name) {
        r.checked = checked;
      }
    });
  }

  clearRights() {
    this.rights.map(r => (r.checked = false));
  }

  chooseTeam(team) {
    this.clearRights();

    if (team === 'Admin') {
      this.rights.map(r => (r.checked = true));
      return;
    }
    this.clearRights();
    this.rights.map(r => {
      if (teams[team].some(teamRight => teamRight === r.name)) {
        r.checked = true;
      }
    });
  }
}
