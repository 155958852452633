import { Injectable, Injector } from '@angular/core';
import { IVisitor } from '@proxyclick/data-model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { BaseService } from './base.service';

@Injectable()
export class VisitorsService extends BaseService {
  constructor(injector: Injector, private Notifications: NotificationsService) {
    super(injector);
  }

  getVisitorsByEmail(email: string): Observable<IVisitor[]> {
    return this.apiClient
      .AdminVisitorRecords(email)
      .doGet()
      .pipe(
        tap(() => this.Notifications.success(`Your search for ${email} is done`)),
        map(visitors => visitors)
      );
  }
}
