<div class="c-card__section">
  <h3>OnGuard settings</h3>
  <pxc-loading [loading]="loading"></pxc-loading>
  <small *ngIf="segmentationInfoMessage" class="c-helper text-warning">{{ segmentationInfoMessage }}</small>
  
  <div *ngIf="!loading">
    <div *ngIf="segmentationSettings?.segmentationEnabled">
      <div class="l-form__field">
        <div class="l-bar u-margin-bottom-xs">
          <div class="l-bar__item-start">
            <label class="c-label">Visitor Segment</label>
          </div>
          <div class="l-bar__item-end">
            <pxc-obj-dropdown [(ngModel)]="segmentOption" [options]="segmentOptions"
              (ngModelChange)="segmentOptionChanged()" key="id" label="name" name="segmentOption" allowNull="true"
              [disabled]="!segmentationSettings.segmentVisitors">
            </pxc-obj-dropdown>
          </div>
        </div>

        <div class="c-helper">Visitors can <strong>optionally</strong> be inserted into a specific segment</div>
      </div>
      <div class="l-form__field">
        <div class="l-bar u-margin-bottom-xs">
          <div class="l-bar__item-start">
            <label class="c-label">Access Level Segment</label>
          </div>
          <div class="l-bar__item-end">
            <pxc-obj-dropdown [(ngModel)]="segmentAccessLevelOption" [options]="segmentOptions"
              (ngModelChange)="segmentAccessLevelOptionChanged()" name="segmentAccessLevelOption" key="id" label="name"
              allowNull="true"
              [disabled]="segmentationSettings.segmentVisitors && !segmentationSettings.allowAccessLevelsToBeConfiguredAsAssignableByOtherSegments">
            </pxc-obj-dropdown>
          </div>
        </div>

        <div class="c-helper">Access levels can <strong>optionally</strong> be managed in a specific segment</div>
        <small class="text-warning" *ngIf="!segmentationSettings.allowAccessLevelsToBeConfiguredAsAssignableByOtherSegments">This segment is not configured to be modified by other segments</small>
      </div>
    </div>

    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Badge Type (Access Card Manager)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="setupState.onGuardCardBadgeType"
            [options]="badgeTypes"
            key="id"
            label="name"
            allowNull="true"
          ></pxc-obj-dropdown>
        </div>
        
      </div>
      <div class="c-helper">When using access card manager, this badge type will be used</div>
    </div>

    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Map custom fields to OnGuard fields</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-switch [(ngModel)]="mapCustomFields" name="mapCustomFields"
            (ngModelChange)="switchEnableCustomFieldMapping()"></pxc-switch>
        </div>
      </div>
    </div>
    <div class="l-grid" *ngIf="mapCustomFields">
      <div class="l-grid__cell l-grid__cell--4">
        <pxc-obj-dropdown [(ngModel)]="customFieldOption" [options]="customFieldOptions" name="customFieldOption"
          key="id" label="name"></pxc-obj-dropdown>
        <div class="c-helper">
          Select a Proxyclick custom field
        </div>
      </div>
      <div class="l-grid__cell l-grid__cell--3">
        <pxc-obj-dropdown [(ngModel)]="typeOption" [options]="typeOptions" name="fieldTypeOption" key="typeName"
          label="typeName" (ngModelChange)="fieldTypeOptionChanged()"></pxc-obj-dropdown>
        <div class="c-helper">
          Select the OnGuard field type you want to map it to.
        </div>
      </div>
      <div class="l-grid__cell l-grid__cell--4">
        <pxc-obj-dropdown [(ngModel)]="propertyOption" [options]="typePropertyOptions[typeOption.typeName] || []"
          name="propertyOption" key="name" label="name"></pxc-obj-dropdown>
        <div class="c-helper">
          Select the OnGuard property name you want to map it to.
        </div>
      </div>
      <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
        <hl-icon icon="add-24" (click)="addMapping()" [ngClass]="{ 'u-color-neutral-light': !canAddMapping() }">
        </hl-icon>
      </div>

      <div *ngIf="setupState.onGuardCustomFieldMappings.length > 0"
        class="l-stack c-box u-padding-all-none u-margin-bottom-s">
        <div class="l-stack__item">
          <div class="c-stacked-list c-stacked-list--s">
            <ul class="c-stacked-list__list">
              <li *ngFor="let customFieldMapping of setupState.onGuardCustomFieldMappings" class="c-stacked-list__item">
                <a class="c-stacked-list__inner c-stacked-list__inner--interactive"
                  (click)="removeMapping(customFieldMapping.customField.id)">
                  <div class="c-stacked-list__inner-start">
                    {{ customFieldMapping.customField.name }} =>
                    <code>{{ customFieldMapping.fieldType.typeName }}.{{ customFieldMapping.fieldProperty.name }}</code>
                  </div>
                  <div class="c-stacked-list__inner-end">
                    <span class="c-icon c-icon--m">
                      <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()">
  </app-fastpace-setup-footer>
</div>
