<div *pxcHasRight='"tab_provisioning"'>
  <div class="flex between">
    <div class="form-inline f-m-h-1">
      <input class="form-control" [(ngModel)]="name" type="text" placeholder="Filter..." />
      <div class="ml-4">Status:</div>
      <pxc-dropdown [(ngModel)]="filter" [options]="filterOptions"></pxc-dropdown>
    </div>
    <div class="flex center f-m-h-1">
      <button class="c-button c-button--primary c-button--s" routerLink="new">
        <i class="fa fa-plus"></i><span class="u-margin-left-2xs">Create</span>
      </button>
    </div>
  </div>
  <table *ngIf="ldapDirectories$ | async as ldapDirectories" class="c-table mt-4">
    <thead>
      <th>Company</th>
      <th>Alias</th>
      <th>Status</th>
      <th>Last synchronization</th>
      <th>Next synchronization</th>
      <th></th>
      <th></th>
    </thead>
    <tbody>
      <tr
        *ngFor="let directory of ldapDirectories | filter:statusFilter(filter) | filter:nameFilter(name)"
        class="table-row"
      >
        <td>
          <a routerLink="{{directory.company.id}}">{{directory.company.name}}</a>
        </td>
        <td>{{directory.alias}}</td>
        <td [ngClass]="getClass(directory)">{{directory.status}}</td>
        <td>{{directory.lastSynchronizedAt | time:"L LT"}}</td>
        <td>{{directory.nextSynchronizeAt | time:'L LT'}}</td>
        <td>
          <button class="btn btn-outline-success visible-on-hover" routerLink="{{directory.company.id}}">
            <i class="fas fa-pencil-alt"></i>
          </button>
        </td>
        <td>
          <button
            class="btn btn-outline-danger visible-on-hover"
            (click)="onDelete(directory)"
            [ngClass]="{'is-loading': directory.isDeleting}"
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
</div>
