import { Component } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ICompany } from '@proxyclick/data-model';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CompaniesService } from '~/services/companies.service';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-companies-typeahead',
  templateUrl: './companies-typeahead.html',
  providers: controlValueAccessor(CompaniesTypeAheadComponent),
})
export class CompaniesTypeAheadComponent implements ControlValueAccessor {
  company: ICompany;

  private onChange: (company: ICompany) => void;

  constructor(private readonly Companies: CompaniesService) {}

  onInternalChange() {
    if (this.company && this.company.id) {
      this.onChange(this.company);
    }
  }

  writeValue(company): void {
    this.company = company;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    return;
  }
  setDisabledState?(isDisabled: boolean): void {
    return;
  }

  // .bind(this) is not working on ng-bootstrap > 9. This is a workaournd
  // https://github.com/ng-bootstrap/ng-bootstrap/issues/4055
  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => (term.length <= 2 ? [] : this.Companies.searchCompanies(term)))
    );
  }

  formatCompanyInput(company: ICompany) {
    return company.name;
  }
}
