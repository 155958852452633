import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-dropdown',
  templateUrl: './dropdown.html',
  styleUrls: ['./dropdown.scss'],
  providers: controlValueAccessor(DropdownComponent),
})
export class DropdownComponent implements ControlValueAccessor, OnInit {
  @Input() options: string[] | IOption[];
  @Input() label = 'label';
  @Input() value = 'value';
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() onChange: (value: any) => Observable<any>;
  @Input() loading: Observable<boolean>;

  option;

  private onChangeInternal: (string) => void;
  internalLoading = false;

  constructor(private elem: ElementRef, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.loading) {
      this.loading.subscribe(loading => (this.internalLoading = loading));
    }
  }

  onOptionClick(event) {
    const index = event.target.selectedIndex;
    let value = this.options[index];
    if (value[this.value] !== undefined) {
      value = value[this.value];
    }
    this.onChangeInternal(value);

    if (this.onChange) {
      this.internalLoading = true;
      this.onChange(value)
        .pipe(finalize(() => (this.internalLoading = false)))
        .subscribe();
    }
  }

  writeValue(option): void {
    if (typeof this.options[0] === 'string') {
      this.option = _.find(this.options, o => o === option);
    } else {
      this.option = _.find(this.options, o => o && o[this.value] === option);
    }
    // this.option = _.find(this.options, o => (o[this.value] !== undefined && option ?
    //     _.isEqual(o[this.value], option[this.value]) :
    //     _.isEqual(o, option)));
  }

  registerOnChange(fn: any): void {
    this.onChangeInternal = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}
}
