import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { ApplicationsService } from '~/services/applications.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { IAppState } from '~/store/app-state';
import { ApplicationsActions } from '~/store/applications/applications.reducer';

@Component({
  templateUrl: './applications.html',
})
export class ApplicationsPageComponent implements OnInit {
  applications$ = this.store.select('applications', 'value');
  loading$ = this.store.select('applications', 'loading');

  filter = '';
  applicationName = '';
  accessTypes = ['USER', 'COMPANY', 'PROXYCLICK'];
  rights = {
    user: true,
    company: false,
    proxyclick: false,
  };

  constructor(
    private store: Store<IAppState>,
    private Applications: ApplicationsService,
    private Notifications: NotificationsService
  ) {}

  ngOnInit() {
    this.store.dispatch(ApplicationsActions.Load());
  }

  createApplication(name: string) {
    const accessTypes = _.chain(this.rights)
      .pickBy(r => r)
      .keys()
      .map(s => s.toUpperCase())
      .join(',')
      .value();

    this.Applications.createApplication({
      name: name,
      accessTypes: accessTypes,
    }).subscribe(result => {
      this.Notifications.success(`Application [${result.name}] succesfully created`);
      this.store.dispatch(ApplicationsActions.Load());
    });
  }
}
