<div *pxcHasRight='"tab_partners"' class="pxc-page">
  <div class="d-flex justify-content-between mb-2">
    <button class="c-button c-button--s" routerLink="create">
      <i class="fas fa-plus"></i><span class="u-margin-left-2xs">Create new partner</span>
    </button>
  </div>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
  <table *ngIf="!(loading$ | async) && (partners$ | async)" class="c-table">
    <thead>
      <th>Logo</th>
      <th>Name</th>
      <th>Displayed name</th>
      <th># Companies linked</th>
      <th># Kiosk linked</th>
      <th>Language</th>
    </thead>
    <tbody>
      <tr *ngFor="let partner of partners$ | async">
        <td><img src="{{partner.logoUrl}}" /></td>
        <td><a routerLink="{{partner.id}}">{{partner.name}}</a></td>
        <td>{{partner.displayName}}</td>
        <td>{{partner.companyCount}}</td>
        <td>{{partner.kioskCount}}</td>
        <td>{{partner.language.displayName}}</td>
      </tr>
    </tbody>
  </table>
</div>
