<div *pxcHasRight='"tab_provisioning"'>
  <div class="u-margin-right-2xl u-margin-top-l">
    <div class="u-margin-bottom-m">
      <span class="u-text-l u-text-medium">Clone users from the the original company to the target company</span>
    </div>
    <div class="u-margin-right-2xl">
      <div class="c-box u-max-width-900">
        <div class="u-flex u-flex-justify-between u-flex-align-items-center">
          <div>
            <input
              [(ngModel)]="originalCompanyId"
              class="form-control c-input c-input--s"
              type="text"
              placeholder="Original CO"
            />
          </div>
          <span class="c-icon c-icon--l">
            <hl-icon icon="arrow-right-24"></hl-icon>
          </span>
          <div class="flex center f-m-h-1 form-inline">
            <input
              [(ngModel)]="targetCompanyId"
              class="form-control c-input c-input--s"
              type="text"
              placeholder="Target CO"
            />
          </div>
          <div>
            <pxc-button
              [disabled]="!(originalCompanyId && targetCompanyId)"
              (click)="(originalCompanyId && targetCompanyId) && toggleUserCloneModal()"
              [loading]="loading$"
              >Clone Users</pxc-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<hl-modal
  *ngIf="userCloneModal && !(userDifferenceLoading$ | async) && (userDifference$ | async) as userDifference"
  class="hl-modal"
  [isOpen]="true"
  [heading]="'Clone users'"
  (close)="toggleUserCloneModal()"
>
  <div>
    <div class="pxc-settings__subheading u-margin-bottom-gutter">
      <span>
        Are you sure you want to clone the users from {{originalCompanyId}} to {{targetCompanyId}}
        <br /><br />
        <li>Total amount of users who will be cloned: {{userDifference.total}}</li>
        <li>Total amount of users in {{originalCompanyId}}: {{userDifference.originalCount}}</li>
        <li>Total amount of users in {{targetCompanyId}}: {{userDifference.targetCount}}</li>
        <br /><br />
        Disclaimer: this process will take some time, around 2 minutes for 2.5k users. Expect some longer waiting times
        for a bigger amount of users to be cloned. If the process times out, wait around 15 minutes before trying again,
        the users will still be cloned.
      </span>
    </div>
    <footer class="hl-modal__footer u-margin-top-s">
      <div class="c-buttons-group" style="margin-top: -10px;">
        <hl-button size="small" type="primary" (click)="cloneUsers()" [loading]="(loading$ | async)">Yes</hl-button>
        <hl-button size="small" type="secondary" (click)="toggleUserCloneModal()">No</hl-button>
      </div>
    </footer>
  </div>
</hl-modal>
