<div class="c-card__section">
  <h3>Review setup</h3>

  <div class="l-stack c-box u-padding-all-none u-margin-v-s">
    <div class="l-stack__item">
      <div class="c-stacked-list c-stacked-list--s">
        <ul class="c-stacked-list__list">
          <li class="c-stacked-list__item">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Company</div>
              <div class="c-stacked-list__inner-end">{{ setupState.company.name }} ({{ setupState.company.id }})</div>
            </div>
          </li>
          <li class="c-stacked-list__item">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Extender</div>
              <div class="c-stacked-list__inner-end" *ngIf="setupState.extender">
                {{ setupState.extender.description }} of type {{ setupState.extender.type }}
              </div>
              <div class="c-stacked-list__inner-end" *ngIf="!setupState.extender">No extender</div>
            </div>
          </li>
          <li class="c-stacked-list__item">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Generate tokens</div>
              <div class="c-stacked-list__inner-end">
                <input
                  type="checkbox"
                  class="c-switch c-switch--locked"
                  disabled=""
                  [checked]="setupState.generateTokens"
                />
                <label for="example-switch-locked-on" class="c-switch__skin"></label>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.generateTokens">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Token group</div>
              <div class="c-stacked-list__inner-end">
                <span *ngIf="setupState.tokenGroupName">{{ setupState.tokenGroupName }}</span>
                <span *ngIf="!setupState.tokenGroupName && setupState.selectedTokenGroup">{{
                  setupState.selectedTokenGroup.label
                }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Token range</div>
              <div class="c-stacked-list__inner-end">
                {{ setupState.tokenRangeMinimum }} - {{ setupState.tokenRangeMaximum }}
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Access level custom field</div>
              <div class="c-stacked-list__inner-end">
                <span *ngIf="setupState.accessLevelsCustomField">{{ setupState.accessLevelsCustomField.name }}</span>
                <span *ngIf="!setupState.accessLevelsCustomField"
                  ><input type="checkbox" class="c-switch c-switch--locked" disabled="" />
                  <label for="example-switch-locked-on" class="c-switch__skin"></label
                ></span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Copy settings</div>
              <div class="c-stacked-list__inner-end">
                <span *ngIf="setupState.copySettingsFromRandomExtenderCompany"
                  >From a random company on the extender</span
                >
                <span *ngIf="setupState.copySettingsFromCompany"
                  >From {{ setupState.copySettingsFromCompany.companyName }}</span
                >
                <span *ngIf="!setupState.copySettingsFromCompany && !setupState.copySettingsFromRandomExtenderCompany"
                  >Use default settings</span
                >
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'ac2000'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AC2000 connection id</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.ac2000Connection.connectionId }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'ac2000'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AC2000 Device IN filter</div>
              <div class="c-stacked-list__inner-end">
                <ul>
                  <li *ngFor="let inFilter of setupState.ac2000DeviceFilterIn">
                    {{ inFilter.deviceDescription }}
                  </li>
                </ul>
                >
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'ac2000'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AC2000 Device OUT filter</div>
              <div class="c-stacked-list__inner-end">
                <ul>
                  <li *ngFor="let outFilter of setupState.ac2000DeviceFilterOut">
                    {{ outFilter.deviceDescription }}
                  </li>
                </ul>
                >
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'aeos'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AEOS Use employee type</div>
              <div class="c-stacked-list__inner-end">
                <input
                  type="checkbox"
                  class="c-switch c-switch--locked"
                  disabled=""
                  [checked]="setupState.aeosUseEmployeeType"
                />
                <label for="example-switch-locked-on" class="c-switch__skin"></label>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'aeos'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AEOS Host as contact</div>
              <div class="c-stacked-list__inner-end">
                <input
                  type="checkbox"
                  class="c-switch c-switch--locked"
                  disabled=""
                  [checked]="setupState.aeoshostEmailAsContact"
                />
                <label for="example-switch-locked-on" class="c-switch__skin"></label>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'aeos'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AEOS Custom field to free field mapping</div>
              <div class="c-stacked-list__inner-end">
                <span *ngIf="setupState.aeosCustomFieldFreeFieldMappings.length === 0">No mappings</span>
                <div *ngIf="setupState.aeosCustomFieldFreeFieldMappings.length > 0">
                  <ul>
                    <li *ngFor="let mapping of setupState.aeosCustomFieldFreeFieldMappings">
                      <span>{{ mapping.customField.name }} => {{ mapping.freeField.name }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li
            class="c-stacked-list__item"
            *ngIf="setupState.extender?.type === 'aeos' && setupState.aeosCardIdentifierType"
          >
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AEOS card format (Access Card Manager)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.aeosCardIdentifierType.name }}</span>
              </div>
            </div>
          </li>

          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'ccure'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">CCure partition</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.ccurePartition?.name || 'No partition' }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'ccure'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">CCure Use Personnel Group</div>
              <div class="c-stacked-list__inner-end">
                <input
                  type="checkbox"
                  class="c-switch c-switch--locked"
                  disabled=""
                  [checked]="setupState.ccureUsePersonnelGroup"
                />
                <label for="example-switch-locked-on" class="c-switch__skin"></label>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'ccure'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">CCure personnel group</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.ccurePersonnelGroup?.name || 'Auto-created (Proxyclick visitors)' }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'ccure'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">CCure Custom field to field mapping</div>
              <div class="c-stacked-list__inner-end">
                <span *ngIf="setupState.ccureCustomFieldMappings.length === 0">No mappings</span>
                <div *ngIf="setupState.ccureCustomFieldMappings.length > 0">
                  <ul>
                    <li *ngFor="let mapping of setupState.ccureCustomFieldMappings">
                      <span>{{ mapping.customField.name }} => {{ mapping.fieldName }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li
            class="c-stacked-list__item"
            *ngIf="setupState.extender?.type === 'ccure' && setupState.ccureCardCHUIDFormat"
          >
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">CCure card format (Access Card Manager)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.ccureCardCHUIDFormat.name }}</span>
              </div>
            </div>
          </li>
          <li
            class="c-stacked-list__item"
            *ngIf="setupState.extender?.type === 'ccure' && setupState.ccureFacilityCode"
          >
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">CCure facility code (Access Card Manager)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.ccureFacilityCode }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'gallagher'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Gallagher division</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.gallagherDivision?.name || 'No division' }}</span>
              </div>
            </div>
          </li>
          <li
            class="c-stacked-list__item"
            *ngIf="setupState.extender?.type === 'gallagher' && setupState.gallagherCardCardType"
          >
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Gallagher card type (Access Card Manager)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.gallagherCardCardType.name }}</span>
              </div>
            </div>
          </li>

          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'gallagher'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Gallagher Custom field to field mapping</div>
              <div class="c-stacked-list__inner-end">
                <span *ngIf="setupState.gallagherCustomFieldMappings.length === 0">No mappings</span>
                <div *ngIf="setupState.gallagherCustomFieldMappings.length > 0">
                  <ul>
                    <li *ngFor="let mapping of setupState.gallagherCustomFieldMappings">
                      <span>{{ mapping.customField.name }} => {{ mapping.fieldName }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'amag'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AMAG Company</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.amagCompany.description }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'amag'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AMAG Customer Code</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.amagCustomerCode }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'amag'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AMAG Card Format (qr code)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.amagQrCodeCardFormatName }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'amag'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AMAG Card Format (physical card)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.amagPhysicalCardFormatName }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'amag'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">AMAG Custom field to field mapping</div>
              <div class="c-stacked-list__inner-end">
                <span *ngIf="setupState.amagCustomFieldMappings.length === 0">No mappings</span>
                <div *ngIf="setupState.amagCustomFieldMappings.length > 0">
                  <ul>
                    <li *ngFor="let mapping of setupState.amagCustomFieldMappings">
                      <span>{{ mapping.customField.name }} => {{ mapping.amagPersonalDataTitle.title }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'genetec'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Genetec partition</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.genetecPartition?.name || 'No partition' }}</span>
              </div>
            </div>
          </li>
          <li
            class="c-stacked-list__item"
            *ngIf="setupState.extender?.type === 'genetec' && setupState.genetecCardCredentialFormat"
          >
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Genetec credential format (Access Card Manager)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.genetecCardCredentialFormat.name }}</span>
              </div>
            </div>
          </li>
          <li
            class="c-stacked-list__item"
            *ngIf="setupState.extender?.type === 'genetec' && setupState.genetecFacilityCode"
          >
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Genetec facility code (Access Card Manager)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.genetecFacilityCode }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'netbox'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Netbox manage access levels</div>
              <div class="c-stacked-list__inner-end">
                <input
                  type="checkbox"
                  class="c-switch c-switch--locked"
                  disabled=""
                  [checked]="setupState.netboxManageAccessLevels"
                />
                <label for="example-switch-locked-on" class="c-switch__skin"></label>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'netbox'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Netbox use multi-partition access level groups</div>
              <div class="c-stacked-list__inner-end">
                <input
                  type="checkbox"
                  class="c-switch c-switch--locked"
                  disabled=""
                  [checked]="setupState.netboxUseMultiPartitionAccessLevelGroups"
                />
                <label for="example-switch-locked-on" class="c-switch__skin"></label>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'netbox'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Netbox visitor id field</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.netboxVisitorIdField }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'netbox'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Netbox visitor status field</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.netboxVisitorStatusField }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'netbox'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Netbox partition key</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.netboxPartitionKey }}</span>
              </div>
            </div>
          </li>
          <li
            class="c-stacked-list__item"
            *ngIf="setupState.extender?.type === 'netbox' && setupState.netboxCardFormatOption"
          >
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Netbox card format (Access Card Manager)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.netboxCardFormatOption.value }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'onguard'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">OnGuard visitor segment</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.onGuardCardHolderSegment?.name || 'No segment' }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'onguard'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">OnGuard access level segment</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.onGuardAccessLevelSegement?.name || 'No segment' }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'onguard'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">OnGuard Custom field to field mapping</div>
              <div class="c-stacked-list__inner-end">
                <span *ngIf="setupState.onGuardCustomFieldMappings.length === 0">No mappings</span>
                <div *ngIf="setupState.onGuardCustomFieldMappings.length > 0">
                  <ul>
                    <li *ngFor="let mapping of setupState.onGuardCustomFieldMappings">
                      <span
                        >{{ mapping.customField.name }} => {{ mapping.fieldType.typeName }}.{{
                          mapping.fieldProperty.name
                        }}</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'onguard'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">OnGuard Badge Type (Access Card Manager)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.onGuardCardBadgeType?.name || 'No Badge Type' }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'brivo'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Brivo Card Format (QR Code)</div>
              <div class="c-stacked-list__inner-end">
                <span
                  >{{ setupState.brivoQrCodeCredentialFormat.name }} ({{
                    setupState.brivoQrCodeCredentialFormat.id
                  }}
                  )</span
                >
              </div>
            </div>
          </li>
          <li
            class="c-stacked-list__item"
            *ngIf="setupState.extender?.type === 'brivo' && setupState.brivoCardCredentialFormat !== null"
          >
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Brivo Card Format (Access Card Manager)</div>
              <div class="c-stacked-list__inner-end">
                <span
                  >{{ setupState.brivoCardCredentialFormat.name }} ({{
                    setupState.brivoCardCredentialFormat.id
                  }}
                  )</span
                >
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'brivo'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Brivo Facility Code</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.brivoFacilityCode }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'brivo'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Brivo Custom field mapping</div>
              <div class="c-stacked-list__inner-end">
                <span *ngIf="setupState.brivoCustomFieldMappings.length === 0">No mappings</span>
                <div *ngIf="setupState.brivoCustomFieldMappings.length > 0">
                  <ul>
                    <li *ngFor="let mapping of setupState.brivoCustomFieldMappings">
                      <span>{{ mapping.customField.name }} => {{ mapping.brivoCustomField.fieldName }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'openpath'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">OpenPath Organization</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.openPathOrganization.name }} ({{ setupState.openPathOrganization.id }} )</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'openpath'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">OpenPath Card Format (QR Code)</div>
              <div class="c-stacked-list__inner-end">
                <span
                  >{{ setupState.openPathQrCodeCardFormat.name }} ({{ setupState.openPathQrCodeCardFormat.id }} )</span
                >
              </div>
            </div>
          </li>
          <li
            class="c-stacked-list__item"
            *ngIf="setupState.extender?.type === 'openpath' && setupState.openPathCardCardFormat"
          >
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">OpenPath Card Format (Access Card Manager)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.openPathCardCardFormat.name }} ({{ setupState.openPathCardCardFormat.id }} )</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'openpath'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">OpenPath Facility Code (Access Card Manager)</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.openPathFacilityCode || 'No facility code' }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'genea'">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Genea Location</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.geneaLocation.name }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'genea' && setupState.geneaTenant">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Genea Tenant</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.geneaTenant.name }}</span>
              </div>
            </div>
          </li>
          <li class="c-stacked-list__item" *ngIf="setupState.extender?.type === 'genea' && setupState.geneaTenant">
            <div class="c-stacked-list__inner">
              <div class="c-stacked-list__inner-start">Genea Tenant</div>
              <div class="c-stacked-list__inner-end">
                <span>{{ setupState.geneaTenant.name }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="true"></app-fastpace-setup-footer>
</div>
