import { Component, Input } from '@angular/core';
import { ICompany, IModuleInfo } from '@proxyclick/data-model';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { BillingActions } from '~/store/billing/billing.actions';
import { AppStore } from '~/store/store';

@Component({
  selector: 'pxc-module-form',
  templateUrl: './module-form.html',
  styleUrls: ['./module-form.scss'],
})
export class ModuleFormComponent {
  @Input() company: ICompany;
  @Input() module: IModuleInfo;

  constructor(private Notifications: NotificationsService, private store: AppStore) {}

  extendTrial(company: ICompany, companyModule: IModuleInfo) {
    return () =>
      this.store
        .dispatchAsync(BillingActions.ExtendTrial({ companyId: company.id, endAt: companyModule.trialEndAt }))
        .then(() => this.Notifications.success('Succesfully extended trial'));
  }
}
