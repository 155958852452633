<div class="flex f-m-h-1 center ml-3">
  <span>From</span>
  <pxc-datepicker [(ngModel)]="from" name="from"> </pxc-datepicker>
  <span>To</span>
  <pxc-datepicker [(ngModel)]="to" name="vmTrialEndAt"> </pxc-datepicker>
  <pxc-button [loading]="loading$" [disabled]="!from || !to" (click)="getStats()">Get Stats</pxc-button>
</div>

<table class="c-table mt-2">
  <tbody *ngIf="!(loading$ | async) && stats$ | async as stats">
    <tr>
      <td>Scans started</td>
      <td>{{stats.scanStarted}}</td>
    </tr>
    <tr>
      <td>Scans succesfully finished</td>
      <td>{{stats.scanFinished}}</td>
    </tr>
    <tr>
      <td>Calls to Acuant single</td>
      <td>{{stats.acuantSingle}}</td>
    </tr>
    <tr>
      <td>Calls to Acuant duplex</td>
      <td>{{stats.acuantDuplex}}</td>
    </tr>
    <tr>
      <td>Calls to Microsoft Detect</td>
      <td>{{stats.microsoftDetect}}</td>
    </tr>
    <tr>
      <td>Calls to Microsoft Verify</td>
      <td>{{stats.microsoftVerify}}</td>
    </tr>
  </tbody>
</table>
<pxc-loading [loading]="loading$ | async"></pxc-loading>
