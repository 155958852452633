import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable()
export class DeliveryService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  getDelivery(companyId: string, deliveryId: string) {
    return this.apiClient.ApplicationDeliveryManagementDelivery(companyId, deliveryId).doGet();
  }
}
