/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IBillingStatus, ICompany, IFeatureList, IFeatureListMultiPrice } from '@proxyclick/data-model';
import * as _ from 'lodash';
import { from, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BillingMigrationFormComponent } from '~/shared/components/billing/migration-form/migration-form.component';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { BillingActions } from '~/store/billing/billing.actions';
import { BillingSelectors } from '~/store/billing/billing.selector';
import { FeatureListsSelectors } from '~/store/feature-lists/feature-lists.selector';
import { LoadingSelectors } from '~/store/loading/loading.selector';
import { AppStore } from '~/store/store';

const currencyOptions = ['EUR', 'USD', 'GBP'];

@Component({
  selector: 'pxc-company-billing',
  templateUrl: './company-billing.html',
  styleUrls: ['./company-billing.scss'],
})
export class CompanyBillingComponent implements OnInit, OnDestroy {
  public loading = {
    billing$: this.store.select(LoadingSelectors.getByAction(BillingActions.BillingLoad)),
    activableCompanyFeatureLists$: this.store.select(LoadingSelectors.getByAction(BillingActions.FeatureListsLoad)),
  };
  public billing$ = this.store.select(BillingSelectors.getBilling);
  public activableCompanyFeatureLists$ = this.store.select(BillingSelectors.getActivableFeatureLists);
  public company$ = this.store.select('company', 'company', 'value');
  public company: ICompany;
  public subscriptions: Subscription[] = [];
  public frequency = 'monthly';
  public modules$ = this.store.select('company', 'modules', 'value');
  public featureLists$ = this.store.select(FeatureListsSelectors.getAll);
  public adminCompany$ = this.store.select('company', 'adminCompany', 'value');
  public vm$ = this.modules$.pipe(
    filter(a => !!a),
    map(modules => _.find(modules, m => m.reference === 'VM'))
  );
  public currencyOptions = currencyOptions;
  public popoverOptions = {
    placement: 'right',
    closeOnOutsideClick: true,
  };

  public packFilter = (fl: IFeatureList) => fl.type === 'PACK' || fl.type === 'BASE';
  public integrationFilter = (fl: IFeatureList) => fl.type === 'INTEGRATION';
  public oldPricingFeatures = (fl: IFeatureListMultiPrice) => fl.version < 3;
  public downgradeFeatures = (fl: IFeatureListMultiPrice) =>
    fl.reference.includes('vm_features_enterprise') || fl.reference.includes('tenant');
  public billing: IBillingStatus;

  constructor(
    private store: AppStore,
    private modalService: NgbModal,
    private notificationsService: NotificationsService
  ) {}

  public ngOnInit() {
    this.subscriptions.push(
      this.company$.subscribe(company => {
        if (company) {
          this.company = company;
          this.store.dispatch(
            BillingActions.BillingLoad({
              companyId: this.company.id,
            })
          );
        }
      })
    );
    this.subscriptions.push(this.billing$.subscribe(billing => (this.billing = billing)));
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public startTrial(featureList: IFeatureList) {
    return () =>
      this.store.dispatchAsync(
        BillingActions.ActivateFeatureList({
          companyId: this.company.id,
          featureListId: featureList.id,
        })
      );
  }

  public cancelSubscription() {
    return () =>
      this.store.dispatchAsync(
        BillingActions.CancelSubscription({
          companyId: this.company.id,
          options: {
            end_of_term: true,
            credit_option_for_current_term_charges: 'none',
            account_receivables_handling: 'no_action',
          },
        })
      );
  }

  public blockSubscription() {
    return () => this.store.dispatchAsync(BillingActions.BlockSubscription({ companyId: this.company.id }));
  }

  public unblockSubscription() {
    return () => this.store.dispatchAsync(BillingActions.UnblockSubscription({ companyId: this.company.id }));
  }

  public cancelFeatureList(featureList: IFeatureList) {
    return () =>
      this.store.dispatchAsync(
        BillingActions.CancelFeatureList({ companyId: this.company.id, featureListId: featureList.id })
      );
  }

  public resetFeatureList(featureList: IFeatureList) {
    return () =>
      this.store.dispatchAsync(
        BillingActions.ResetFeatureList({ companyId: this.company.id, featureListId: featureList.id })
      );
  }

  public extendTrial(trialEndAt: string) {
    return this.store
      .dispatchAsync(BillingActions.ExtendTrial({ companyId: this.company.id, endAt: trialEndAt }))
      .then(() => this.notificationsService.success('Succesfully extended trial'));
  }

  public openMigrationModal() {
    return () =>
      this.modalService.open(BillingMigrationFormComponent, {
        centered: true,
        size: 'lg',
      }).result;
  }

  public updateBilling(company: ICompany, billing: IBillingStatus) {
    return () =>
      from(
        this.store.dispatchAsync(
          BillingActions.UpdateBilling.Save({
            companyId: company.id,
            billing,
          })
        )
      );
  }

  public hasIntegrations(featureLists: IFeatureList[]) {
    return featureLists.filter(fl => fl.type === 'INTEGRATION').length > 0;
  }

  public activateLocation() {
    return () =>
      this.store.dispatchAsync(
        BillingActions.ActivateLocation.Save({
          companyId: this.company.id,
        })
      );
  }

  public checkIfSubscriptionNotBasAndNotLinked() {
    return this.billing.subscriptionType !== 'BASE' && this.billing.subscriptionType !== 'SUBSCRIPTION-LINKED';
  }
}
