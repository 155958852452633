import { createSelector } from '@ngrx/store';
import { IBillingStatus } from '@proxyclick/data-model';
import { FeatureListStatus, IFeature, IFeatureList, IFeatureListMultiPrice } from '@proxyclick/data-model/index';

import {
  FeatureListsSelectors,
  IDiffFeatureList,
  TARGET_OPTIONAL_FEATURE_LIST_REF,
  TARGET_VERSION
} from '~/store/feature-lists/feature-lists.selector';
import { IAppState } from '../app-state';

export type AdminFeatureListStatus =  FeatureListStatus
| 'CHARGED_BUT_NOT_ACTIVATED'
| 'ACTIVATED_BUT_NOT_CHARGED';

export interface IAdminFeatureList extends IFeatureList{
  adminStatus: AdminFeatureListStatus
}

const get = (state: IAppState) => state.company.billing;
const getBilling = createSelector(get, state => state.billing);
const getFeatureLists = createSelector(get, state => state.featureLists);

const getCurrentBillingVersion = createSelector(getBilling, billing => billing.version);
const getActivatedFeatureLists = createSelector(getFeatureLists, featureLists =>
  featureLists ? featureLists.filter(fl => fl.status === 'IN_TRIAL' || fl.status === 'INVOICED') : featureLists
);

const getActivatedBaseFeatureLists = createSelector(getActivatedFeatureLists, featureLists =>
  featureLists ? featureLists.filter(fl => fl.type === 'BASE') : featureLists
);

const getActivatedPackFeatureLists = createSelector(getActivatedFeatureLists, featureLists =>
  featureLists ? featureLists.filter(fl => fl.type === 'PACK') : featureLists
);
const getActivatedIntegrationFeatureLists = createSelector(getActivatedFeatureLists, featureLists =>
  featureLists ? featureLists.filter(fl => fl.type === 'INTEGRATION') : featureLists
);

const getFeatureListsWithAdminStatus = createSelector(
    [getFeatureLists],
    (featureLists: IFeatureList[]): IAdminFeatureList[] => {
      if (!featureLists) {
        return null;
      }

      return featureLists.map(featureList =>({
            ...featureList,
            adminStatus: computeAdminStatus(featureList)
          })
      );
    }
);

const computeAdminStatus = (featureList: IFeatureList): AdminFeatureListStatus => {
  if (featureList.isCharged && !featureList.activatedAt) {
    return 'CHARGED_BUT_NOT_ACTIVATED';
  }
  if (!featureList.isCharged && featureList.activatedAt) {
    if (featureList.status === 'IN_TRIAL') {
      return 'IN_TRIAL';
    }
    return 'ACTIVATED_BUT_NOT_CHARGED';
  }
  return featureList.status;
}

const getActivableFeatureLists = createSelector(
    [getBilling, getActivatedBaseFeatureLists, getFeatureListsWithAdminStatus],
    (billing: IBillingStatus, basePlans: IFeatureList[], featureLists:IAdminFeatureList[]): IAdminFeatureList[] => {
      if (!billing || !basePlans || !featureLists) {
        return featureLists;
      }
      if (billing.version === TARGET_VERSION && basePlans.length === 1) {
        return featureLists.filter(featureList =>
            featureList.type === 'BASE' || (TARGET_OPTIONAL_FEATURE_LIST_REF[basePlans[0].reference] && TARGET_OPTIONAL_FEATURE_LIST_REF[basePlans[0].reference].includes(featureList.reference))
        );
      }
      return featureLists;
    }
);

const getFeaturesFromFeatureLists = (featureLists: IFeatureListMultiPrice[] | IFeatureList[]): IFeature[] => {
  const setFeatures: Record<number, IFeature> = {};
  for (const featureList of featureLists) {
    for (const feature of featureList.features) {
      setFeatures[feature.id] = feature;
    }
  }
  return Object.values(setFeatures);
};

const computeDiffTargetAndCurrentFeatureLists = (
    targetFeatureLists: IFeatureListMultiPrice[],
    currentFeatureLists: IFeatureList[]
): IDiffFeatureList[] => {
  const currentFeatures = getFeaturesFromFeatureLists(currentFeatureLists);
  return targetFeatureLists.map(targetFeatureList => {
    const targetFeatures = getFeaturesFromFeatureLists([targetFeatureList]);
    const notExistingInTargetFeatures = currentFeatures
        .filter(currentFeature => !targetFeatures.find(targetFeature => currentFeature.id === targetFeature.id))
        .map(notExistingInTargetFeature => ({
          ...notExistingInTargetFeature,
          includedInCurrent: false,
          includedInTarget: false,
        }));
    const existingFeatures = targetFeatures
        .filter(targetFeature => currentFeatures.find(currentFeature => currentFeature.id === targetFeature.id))
        .map(existingFeature => ({ ...existingFeature, includedInCurrent: true, includedInTarget: true }));
    const newFeatures = targetFeatures
        .filter(targetFeature => !existingFeatures.find(existingFeature => existingFeature.id === targetFeature.id))
        .map(newFeature => ({ ...newFeature, includedInCurrent: false, includedInTarget: true }));
    return {
      ...targetFeatureList,
      features: [...notExistingInTargetFeatures, ...existingFeatures, ...newFeatures],
    };
  });
};

const getDiffTargetAndCurrentFeatureLists = createSelector(
    [FeatureListsSelectors.getTargetFeatureLists, getActivatedFeatureLists],
    (targetFeatureLists:IFeatureListMultiPrice[], currentFeatureLists: IFeatureList[]): IDiffFeatureList[] =>
        computeDiffTargetAndCurrentFeatureLists(targetFeatureLists, currentFeatureLists)
);

const getDiffTargetAndCurrentMandatoryFeatureLists = createSelector(
    [FeatureListsSelectors.getTargetMandatoryFeatureLists, getActivatedFeatureLists],
    (mandatoryTargetFeatureLists: IFeatureListMultiPrice[], currentFeatureLists: IFeatureList[]): IDiffFeatureList[] =>
        computeDiffTargetAndCurrentFeatureLists(mandatoryTargetFeatureLists, currentFeatureLists)
);

export const BillingSelectors = {
  getBilling,
  getCurrentBillingVersion,
  getFeatureLists,
  getActivatedFeatureLists,
  getActivableFeatureLists,
  getActivatedPackFeatureLists,
  getActivatedIntegrationFeatureLists,
  getDiffTargetAndCurrentFeatureLists,
  getDiffTargetAndCurrentMandatoryFeatureLists,
};

export const selectBilling = (state: IAppState) => state.company.billing.billing;
