import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { IAppState } from '~/store/app-state';
import { CompanyIdScanStats } from '~/store/companies/companies.actions';

@Component({
  selector: 'pxc-company-idscan-stats',
  templateUrl: './company-id-scan-stats.html',
})
export class CompanyIdScanComponent implements OnInit {
  loading$ = this.store.select('company', 'idScanStats', 'loading');
  stats$ = this.store.select('company', 'idScanStats', 'value');

  companyId: string;

  from: moment.Moment = moment().subtract(1, 'month');
  to: moment.Moment = moment();

  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.companyId = null;
    this.store.select('company', 'company', 'value', 'id').subscribe(id => {
      this.companyId = id;
    });
  }

  getStats() {
    this.store.dispatch(
      CompanyIdScanStats.Load({
        companyId: this.companyId,
        from: this.from,
        to: this.to,
      })
    );
  }
}
