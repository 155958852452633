import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { filter, map } from 'rxjs/operators';
import { IAppState } from '~/store/app-state';
import { companyFeatureList } from '~/store/companies/companies.selector';
import { FeatureListsSelectors } from '~/store/feature-lists/feature-lists.selector';

@Component({
  selector: 'pxc-company-sd',
  templateUrl: './company-sd.html',
})
export class CompanySDComponent {
  modules$ = this.store.select('company', 'modules', 'value');
  company$ = this.store.select('company', 'company', 'value');
  featuresList$ = this.store.select(FeatureListsSelectors.getAll);
  features$ = companyFeatureList(this.modules$, this.featuresList$);

  sd$ = this.modules$.pipe(
    filter(a => !!a),
    map(modules => _.find(modules, m => m.reference === 'SD'))
  );

  constructor(private store: Store<IAppState>) {}
}
