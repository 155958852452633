<div class="pxc-navbar">
  <div class="pxc-container">
    <div class="pxc-navbar__home">
      <a routerLink="/" class="u-margin-right-s u-color-neutral">Black Gate</a>
      <pxc-search-bar *ngIf="showSearch"></pxc-search-bar>
    </div>
    <div class="pxc-navbar__links ml-4">
      <a routerLink="domains" *pxcHasRight="'tab_domains'" routerLinkActive="active">Domains</a>
      <a routerLink="organisations" *pxcHasRight="'tab_organisations'" routerLinkActive="active">Organisations</a>
      <a routerLink="companies" *pxcHasRight="'tab_companies'" routerLinkActive="active">Companies</a>
      <a routerLink="users" *pxcHasRight="'tab_users'" routerLinkActive="active">Users</a>
      <a routerLink="admin-users" *pxcHasRight="'super_admin'" routerLinkActive="active">Admin Users</a>
      <a routerLink="visitors" *pxcHasRight="'tab_visitors'" routerLinkActive="active">Visitors</a>
      <a routerLink="partners" *pxcHasRight="'tab_partners'" routerLinkActive="active">Partners</a>
      <a routerLink="stats" *pxcHasRight="'tab_stats'" routerLinkActive="active">Stats</a>
      <a routerLink="provisioning" *pxcHasRight="'tab_provisioning'" routerLinkActive="active">Provisioning</a>
      <a routerLink="fastpace" *pxcHasRight="'tab_fastpace'" routerLinkActive="active">Access Control</a>
      <a routerLink="marketplace" *pxcHasRight="'tab_marketplace'" routerLinkActive="active">Marketplace</a>
      <a routerLink="utilities" *pxcHasRight="'tab_utilities'" routerLinkActive="active">Utilities</a>
      <a routerLink="map" routerLinkActive="active">Map</a>
      <a (click)="logout()"><i class="fas fa-sign-out-alt"></i></a>
    </div>
  </div>
</div>
