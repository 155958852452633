import { createAction, props } from '@ngrx/store';
import { IUser } from '@proxyclick/data-model';
import { createAsyncLoadActions, createAsyncLoadReducer } from '~/utils/async-generator';
import { IAdminUser } from '../../../../../shared/models';

export interface IEmailChange {
  email: string;
  newEmail: string;
}

export interface IErrorWithEmail {
  email: string;
  error?: any;
}

export const UsersActions = createAsyncLoadActions('users');
export const usersReducer = createAsyncLoadReducer(UsersActions);

export const RecentUsersActions = createAsyncLoadActions('recent-users');
export const recentUsersReducer = createAsyncLoadReducer(RecentUsersActions);

export const UserLoad = createAction('user/load', props<{ payload: string }>());
export const UserActions = createAsyncLoadActions<string, IUser>('user');

export const UserPasswordActions = createAsyncLoadActions('user/password');
export const UserEmailActions = createAsyncLoadActions('user/email');
export const UserLinkCompanyActions = createAsyncLoadActions('user/link-company');
export const UserLinkMultipleCompaniesActions = createAsyncLoadActions('user/link-companies');
export const UserUnlinkCompanyActions = createAsyncLoadActions('user/unlink-company');
export const UserUnlinkFromAllCompaniesActions = createAsyncLoadActions('user/unlink-all-companies');
export const UserCompanyCloneActions = createAsyncLoadActions('users/clone-company');
export const UserDifferenceBetweenCompaniesActions = createAsyncLoadActions('users/user-difference');

export const UpdateHomeLocation = createAction(
  'users/update-home-location',
  props<{ users: { email: string }[]; newHomeLocation: string }>()
);
export const UpdateHomeLocationErrors = createAction(
  'users/update-home-location-errors',
  props<{ errors: IErrorWithEmail[] }>()
);

export const BatchUpdateUserEmails = createAction('users/batch-email-update', props<{ data: IEmailChange[] }>());
export const BatchUpdateUserEmailsErrors = createAction(
  'user/email-update-errors',
  props<{ errors: IErrorWithEmail[] }>()
);
export const UserCreate = createAction('user/create', props<{ user: IAdminUser }>());

export const PxcUserDirectoryActions = {
  remove: createAction('user/remove-pxc-user-directory', props<{ userId: string }>()),
  removeDone: createAction('user/remove-pxc-user-directory/done', props<{ payload: { userId: string } }>()),
  removeMultiple: createAction('user/remove-pxc-users-directory', props<{ userIds: string }>()),
  removeMultipleDone: createAction('user/remove-pxc-users-directory/done', props<{ payload: { userIds: string } }>()),

  create: createAction('user/create-pxc-user-directory', props<{ userId: string; email: string }>()),
  createDone: createAction(
    'user/create-pxc-user-directory/done',
    props<{ payload: { userId: string; email: string } }>()
  ),
};

export const UserGroupActions = {
  saveUserGroupForCompany: createAction(
    'users/user-group-saved',
    props<{ userId: string; companyId: string; userGroupId: number }>()
  ),
  saveUserGroupForCompanyDone: createAction('users/user-group-saved-done'),
  removeUserGroupForCompany: createAction(
    'users/user-group-removed',
    props<{ userId: string; companyId: string; userGroupId: number }>()
  ),
  removeUserGroupForCompanyDone: createAction('users/user-group-removed-done'),
};
