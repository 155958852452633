import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPartner } from '@proxyclick/data-model';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { ObservableCache } from '~/utils/observable-cache';

const defaultPartner: IPartner = {
  name: '',
  tagline: '',
  description: '',
  email: '',
  categories: [],
  regions: [],
  pictures: [],
  active: true,
};

@Injectable()
export class PartnersService {
  cache: ObservableCache = new ObservableCache();

  constructor(private http: HttpClient, private notifications: NotificationsService) {}

  createPartner(partner) {
    return this.http.post<IPartner>(`/marketplace/api/partners`, partner).pipe(
      tap(() => this.partners().invalidate()),
      tap(() => this.notifications.success('Partner has been created'))
    );
  }

  updatePartner(partner) {
    return this.http.patch<IPartner>(`/marketplace/api/partners/${partner.id}`, partner).pipe(
      tap(() => this.partner(partner.id).invalidate()),
      tap(() => this.notifications.success('Partner has been updated'))
    );
  }

  deletePartner(partner) {
    return this.http.delete(`/marketplace/api/partners/${partner.id}`).pipe(
      tap(() => this.partner(partner.id).invalidate()),
      tap(() => this.notifications.success('Partner has been deleted'))
    );
  }

  partners() {
    return {
      get: () => this.cache.getObservable(`partners`, this.http.get<IPartner[]>('/marketplace/api/partners')),
      invalidate: () => this.cache.invalidate(`partners`),
      default: () => defaultPartner,
    };
  }

  partner(partnerId) {
    return {
      get: () =>
        this.cache.getObservable(
          `partner-${partnerId}`,
          this.http.get<IPartner>(`/marketplace/api/partners/${partnerId}`)
        ),
      invalidate: () => {
        this.cache.invalidate(`partner-${partnerId}`);
        this.cache.invalidate(`partners`);
      },
    };
  }
}
