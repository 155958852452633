import { Injectable, Injector } from '@angular/core';
import { BaseService } from '~/services/base.service';

@Injectable()
export class UtilService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  encrypt(data) {
    return this.apiClient.SystemEncrypt().doPost({ data: data });
  }
}
