import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IVisit } from '@proxyclick/data-model';
import { Chart, ChartConfiguration } from 'chart.js';
import * as moment from 'moment';
import { randomColor } from 'randomcolor';
import { Observable, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CompaniesService } from '~/services/companies.service';
import { ProxyStatsService } from '~/services/proxystats/proxystats.service';
import './rounded-bar.js';

const reloadFrequency = 60 * 60 * 1000;

Chart.defaults.global.defaultFontFamily = 'Circular';
Chart.defaults.global.defaultFontColor = '#748AA1';

const checkinLabels = new Array(24);
for (let i = 0; i < 24; i++) {
  checkinLabels[i] = i;
}

@Component({
  selector: 'pxc-map',
  templateUrl: './map.html',
})
export class MapPageComponent implements OnInit, OnDestroy {
  @ViewChild('map')
  mapElement: ElementRef;
  @ViewChild('countryChart', { static: true })
  countryChartElement: ElementRef;
  @ViewChild('checkinChart', { static: true })
  checkinChartElement: ElementRef;

  checkinObservable: Observable<IVisit>;
  checkinChart;
  countryChart;

  companies;

  private subscriptions: Subscription[] = [];

  constructor(injector: Injector, private proxyStats: ProxyStatsService) {
    this.companies = injector.get(CompaniesService);
  }

  ngOnInit() {
    this.subscriptions.push(this.proxyStats.checkinPerHour$().subscribe(stats => this.initializeCheckinChart(stats)));
    this.subscriptions.push(
      this.proxyStats.checkinPerCountries$().subscribe(stats => this.initializeCountryChart(stats))
    );
    this.subscriptions.push(
      timer(5000, 5000)
        .pipe(switchMap(() => this.proxyStats.checkinPerHour$()))
        .subscribe(stats => this.updateCheckinChart(stats))
    );
    this.subscriptions.push(
      timer(5000, 5000)
        .pipe(switchMap(() => this.proxyStats.checkinPerCountries$()))
        .subscribe(stats => this.updateCountryChart(stats))
    );
    this.subscriptions.push(timer(reloadFrequency).subscribe(() => location.reload())); // Reload the page every hour
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private updateCheckinChart(
    stats: {
      hour: string;
      value: number;
    }[]
  ) {
    this.checkinChart.data.datasets[0].data = stats.map(s => s.value);
    this.checkinChart.data.datasets[0].backgroundColor = getCheckinBackgroundColor();
    this.checkinChart.update();
    this.checkinChart.resize();
  }

  private updateCountryChart(stats) {
    this.countryChart.data.datasets[0].data = stats.map(s => s.value);
    this.countryChart.data.datasets[0].backgroundColor = stats.map(s => getCountryColor(s.country));
    this.countryChart.labels = stats.map(s => s.country);
    this.countryChart.update();
    this.countryChart.resize();
  }

  private initializeCheckinChart(
    stats: {
      hour: string;
      value: number;
    }[]
  ) {
    const ctx = this.checkinChartElement.nativeElement.getContext('2d');
    const options: ChartConfiguration = {
      type: 'bar',
      data: {
        labels: checkinLabels,
        datasets: [
          {
            label: 'Checkins',
            backgroundColor: getCheckinBackgroundColor(),
            borderWidth: 3,
            borderColor: '#292E33',
            data: stats.map(s => s.value),
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      },
    };
    options.options['cornerRadius'] = 10;
    this.checkinChart = new Chart(ctx, options);
    this.checkinChart.resize();
  }

  private initializeCountryChart(stats) {
    const ctx = this.countryChartElement.nativeElement.getContext('2d');
    const options: ChartConfiguration = {
      type: 'horizontalBar',
      data: computeCountryGraphData(stats),
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      },
    };
    options.options['cornerRadius'] = 10;
    this.countryChart = new Chart(ctx, options);
    this.countryChart.resize();
  }
}

const computeCountryGraphData = stats => {
  return {
    datasets: [
      {
        data: stats.map(s => s.value),
        backgroundColor: stats.map(s => getCountryColor(s.country)),
        // borderColor: '#2c2c4c',
        borderWidth: 3,
        borderColor: '#292E33',
      },
    ],
    labels: stats.map(s => s.country),
  };
};

const getCountryColor = country => {
  if (!countryColors[country]) {
    countryColors[country] = randomColor();
  }
  return countryColors[country];
};

const countryColors = {
  BE: '#ff1a1a',
  NL: '#ff751a',
  GB: '#a64dff',
  US: '#0099ff',
  CA: '#00b3b3',
  FR: '#ff1a75',
  NO: '#ffcc00',
  REST: '#ffffff7d',
};

const getCheckinBackgroundColor = () => {
  const backgroundColors = new Array(24);
  for (let i = 0; i < 24; i++) {
    if (i === moment().hour()) {
      backgroundColors[i] = '#26DB8E';
    } else if (i >= moment().hour()) {
      backgroundColors[i] = '#ffffff7d';
    } else {
      backgroundColors[i] = '#FFFFFF';
    }
  }
  return backgroundColors;
};
