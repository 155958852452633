import { Component } from '@angular/core';
import { AppStore } from '~/store/store';
import { UserCompanyCloneActions, UserDifferenceBetweenCompaniesActions } from '~/store/users/users.actions';

@Component({
  templateUrl: './clone-users.html',
})
export class CloneUsersPageComponent {
  userDifference$ = this.store.select('user-difference', 'value');
  userDifferenceLoading$ = this.store.select('user-difference', 'loading');
  loading$ = this.store.select('clone-users', 'loading');

  originalCompanyId: string;
  targetCompanyId: string;

  userCloneModal = false;

  constructor(private store: AppStore) {}

  cloneUsers() {
    this.store
      .dispatchAsync(
        UserCompanyCloneActions.Load({
          originalCompanyId: this.originalCompanyId,
          targetCompanyId: this.targetCompanyId,
        })
      )
      .then(() => this.toggleUserCloneModal());
  }

  toggleUserCloneModal() {
    if (!this.userCloneModal) {
      this.store.dispatch(
        UserDifferenceBetweenCompaniesActions.Load({
          originalCompanyId: this.originalCompanyId,
          targetCompanyId: this.targetCompanyId,
        })
      );
    }
    this.userCloneModal = !this.userCloneModal;
  }
}
