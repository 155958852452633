import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { StatsService } from '~/services/stats.service';
import { IKPI } from '../../../../../../../shared/models';

const KPI_INTERVAL = 1000 * 60;

@Component({
  selector: 'pxc-kpi-table',
  templateUrl: './kpi-table.html',
})
export class KpiTableComponent implements OnInit {
  timers = [];
  kpis;

  constructor(private stats: StatsService) {}

  ngOnInit() {
    this.stats
      .getKPIObservable$(KPI_INTERVAL)
      .pipe(map(kpis => this.enrichKPI(kpis)))
      .subscribe(kpis => (this.kpis = kpis));
  }

  private getKPIClass(field: string, value: string, target: string) {
    if (field === 'Response Time') {
      const valueMin = parseInt(value.split('m')[0]);
      const valueSeconds = parseInt(value.split('m')[1]) || 0;
      const valueDuration = moment.duration({ m: valueMin, s: valueSeconds });
      const targetMin = parseInt(target.split('m')[0]);
      const targetSeconds = parseInt(target.split('m')[1]) || 0;
      const targetDuration = moment.duration({ m: targetMin, s: targetSeconds });
      return targetDuration.subtract(valueDuration).seconds() < 0 ? 'text-danger' : 'text-success';
    } else if (field === 'Churn') {
      return parseFloat(value) > parseFloat(target) ? 'text-danger' : 'text-success';
    } else {
      return parseFloat(value) < parseFloat(target) ? 'text-danger' : 'text-success';
    }
  }

  private enrichKPI(kpi: IKPI) {
    kpi.data.forEach(data => {
      data['lastClass'] = this.getKPIClass(data.field, data.last, data.target);
      data['prevClass'] = this.getKPIClass(data.field, data.prev, data.target);
    });
    return kpi;
  }
}
