import { createAction } from '@ngrx/store';
import { IPartner } from '@proxyclick/data-model';
import { createAsyncLoadActions, createAsyncLoadReducer, PayloadAction } from '~/utils/async-generator';

export const PartnersActions = createAsyncLoadActions<void, IPartner[]>('partners');
export const partnersReducer = createAsyncLoadReducer(PartnersActions);

export const PartnerActions = {
  NEW: createAction('partner/new'),
  UPDATE: createAction('partner/update', (payload: IPartner) => ({ payload })),
  CREATE: createAction('partner/create', (payload: IPartner) => ({ payload })),
  DELETE: createAction('partner/delete', (payload: IPartner) => ({ payload })),
  ...createAsyncLoadActions<string, IPartner>('partner'),
};

const partnerBaseReducer = createAsyncLoadReducer(PartnerActions);

function partnerExtraReducer(state = null, action: PayloadAction<IPartner>) {
  switch (action.type) {
    case PartnerActions.CREATE.type:
    case PartnerActions.UPDATE.type:
    case PartnerActions.NEW.type:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export const partnerReducer = (state, action) => {
  return partnerExtraReducer(partnerBaseReducer(state, action), action);
};
