import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '~/shared/shared.module';
import { IntegrationDetailsPageComponent } from './integrations/integration-details/integration-details.page';
import { IntegrationsPageComponent } from './integrations/integrations.page';
import { MarketplacePageComponent } from './marketplace.page';
import { PartnerDetailsPageComponent } from './partners/partner-details/partner-details.page';
import { PartnersPageComponent } from './partners/partners.page';
import { RightGuard } from '~/shared/guards/right.guard';
import { UserRights } from '../../../../../shared/models';
import { CategoriesPageComponent } from './categories/categories.page';

@NgModule({
  declarations: [
    IntegrationsPageComponent,
    IntegrationDetailsPageComponent,
    PartnersPageComponent,
    PartnerDetailsPageComponent,
    CategoriesPageComponent,
    MarketplacePageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    TranslateModule.forRoot(),
    RouterModule.forChild([
      {
        path: 'marketplace',
        component: MarketplacePageComponent,
        canActivate: [RightGuard],
        data: {
          right: UserRights.TabMarketplace,
          title: 'Marketplace',
        },
        children: [
          {
            path: 'integrations',
            component: IntegrationsPageComponent,
          },
          {
            path: 'integrations/:id',
            component: IntegrationDetailsPageComponent,
          },
          {
            path: 'partners',
            component: PartnersPageComponent,
          },
          {
            path: 'partners/:id',
            component: PartnerDetailsPageComponent,
          },
          {
            path: 'categories',
            component: CategoriesPageComponent,
          },
          {
            path: '**',
            redirectTo: 'integrations',
          },
        ],
      },
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MarketplaceModule {}
