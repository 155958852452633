<div class="full-height">
  <form ngNativeValidate #form="ngForm" (ngSubmit)="onSubmit(email, password)" *ngIf="!forgot" class="pxc-login">
    <img class="logo" src="assets/img/logo-square.png" />
    <label class="mb-2">
      <input
        class="form-control large grey no-borders round"
        type="text"
        [(ngModel)]="email"
        name="email"
        required
        placeholder="Email"
        autocomplete="off"
      />
    </label>
    <label class="mb-4">
      <input
        class="form-control large grey no-borders round"
        type="password"
        [(ngModel)]="password"
        required
        name="password"
        placeholder="Password"
      />
    </label>
    <button *ngIf="!loading" class="btn btn-primary btn-round btn-xl">Login</button>
    <button *ngIf="loading" class="btn btn-primary btn-round btn-xl">
      <i class="fas fa-sync-alt fa-spin fa-lg"></i>
    </button>
    <a class="mt-2 ml-4" (click)="forgot = true">Forgot password?</a>
  </form>

  <form ngNativeValidate (ngSubmit)="resetPassword(forgotEmail)" class="pxc-login" *ngIf="forgot">
    <img class="logo" src="assets/img/logo-square.png" />
    <label class="mt-4 full-width">
      <span class="ml-4">We'll send you a link to reset your password</span>
      <input
        class="form-control large grey no-borders round mt-4"
        type="text"
        [(ngModel)]="forgotEmail"
        name="forgotEmail"
        required
        placeholder="Email"
      />
    </label>
    <button class="btn btn-primary btn-round btn-xl full-width mt-2">Reset password</button>
    <a class="mt-2 ml-4" (click)="forgot = false">Back</a>
  </form>
  <div class="pxc-login">
    <button class="btn btn-primary btn-round btn-xl u-margin-top-l" (click)="loginWithSSO()">Login via SSO</button>
  </div>
</div>
