<div *pxcHasRight='"tab_companies"'>
  <button class="c-button c-button--s" (click)="addCompanyModalVisible = true">Add company</button>
  <table class="c-table c-table--compact mt-4 mb-2" *ngIf="(loadingCompanies$ | async) === false; else spinner">
    <thead>
      <th style="width: 100px">Id</th>
      <th>Name</th>
      <th></th>
    </thead>
    <tbody *ngIf="companies$ | async as companies">
      <tr *ngFor="let company of companies">
        <td>{{company.id}}</td>
        <td><a [routerLink]="['/companies', company.id]">{{company.name}}</a></td>
        <td>
          <button class="c-button c-button--s c-button--link" (click)="deleteCompany(company)">
            <hl-icon class="c-button__icon" icon="close-24"></hl-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <ng-template #spinner>
    <pxc-loading [loading]="true"></pxc-loading>
  </ng-template>
</div>

<hl-modal
  *ngIf="addCompanyModalVisible"
  class="hl-modal"
  [isOpen]="true"
  heading="Add company"
  (close)="addCompanyModalVisible = false"
>
  <label class="c-label c-label--s" for="new-org-companies"
    >List of new company ids (e.g. CO-B101, 1 per line):</label
  >
  <textarea
    class="c-textarea u-text-xs"
    id="new-org-companies"
    [(ngModel)]="newCompanyIds"
    style="height: 300px"
    [disabled]="isCompaniesSaving"
  ></textarea>
  <div class="u-flex u-flex-align-items-center u-margin-top-s" *ngIf="isCompaniesSaving">
    <hl-spinner></hl-spinner>
    <span class="u-margin-left-s">Saving ...</span>
  </div>
  <footer class="u-margin-top-s">
    <div class="c-buttons-group">
      <hl-button size="small" type="primary" (click)="addCompany()" [disabled]="isCompaniesSaving">Add</hl-button>
      <hl-button size="small" type="secondary" (click)="addCompanyModalVisible = false" [disabled]="isCompaniesSaving"
        >Close</hl-button
      >
    </div>
  </footer>
</hl-modal>
