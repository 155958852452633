export class WizardPage {
  constructor(public key: string, public label: string, public extenderPage: boolean) {}
}

export const extenderPage: WizardPage = new WizardPage('select-extender', 'Select extender', false);
export const tokenSetupPage: WizardPage = new WizardPage('token-setup', 'Token setup', false);
export const tokenGroupPage: WizardPage = new WizardPage('token-group', 'Token group', false);
export const tokenRangePage: WizardPage = new WizardPage('token-range', 'Token range', false);
export const customFieldAccessLevelPage: WizardPage = new WizardPage(
  'custom-field-access-level',
  'Custom field to access level',
  false
);
export const copySettingsPage: WizardPage = new WizardPage('copy-settings', 'Copy settings', false);
export const reviewPage: WizardPage = new WizardPage('review', 'Review', false);
export const submitPage: WizardPage = new WizardPage('submit', 'Submit', false);

export const ac2000SettingsPage: WizardPage = new WizardPage('ac2000', 'AC2000 settings', true);
export const aeosSettingsPage: WizardPage = new WizardPage('aeos', 'AEOS settings', true);
export const ccureSettingsPage: WizardPage = new WizardPage('ccure', 'CCure settings', true);
export const genetecSettingsPage: WizardPage = new WizardPage('genetec', 'Genetec settings', true);
export const netboxSettingsPage: WizardPage = new WizardPage('netbox', 'Netbox settings', true);
export const onguardSettingsPage: WizardPage = new WizardPage('onguard', 'OnGuard settings', true);
export const brivoSettingsPage: WizardPage = new WizardPage('brivo', 'Brivo settings', true);
export const openPathSettingsPage: WizardPage = new WizardPage('openpath', 'OpenPath settings', true);
export const geneaSettingsPage: WizardPage = new WizardPage('genea', 'Genea settings', true);
export const gallagherSettingsPage: WizardPage = new WizardPage('gallagher', 'Gallagher settings', true);
export const amagSettingsPage: WizardPage = new WizardPage('amag', 'AMAG settings', true);

export const extenderPages: WizardPage[] = [
  ac2000SettingsPage,
  aeosSettingsPage,
  ccureSettingsPage,
  genetecSettingsPage,
  netboxSettingsPage,
  onguardSettingsPage,
  brivoSettingsPage,
  openPathSettingsPage,
  geneaSettingsPage,
  gallagherSettingsPage,
  amagSettingsPage
];

export const allWizardPages = {
  extenderPage: extenderPage,
  tokenSetupPage: tokenSetupPage,
  tokenGroupPage: tokenGroupPage,
  tokenRangePage: tokenRangePage,
  customFieldAccessLevelPage: customFieldAccessLevelPage,
  copySettingsPage: copySettingsPage,
  reviewPage: reviewPage,
  submitPage: submitPage,
  ac2000SettingsPage: ac2000SettingsPage,
  aeosSettingsPage: aeosSettingsPage,
  ccureSettingsPage: ccureSettingsPage,
  genetecSettingsPage: genetecSettingsPage,
  netboxSettingsPage: netboxSettingsPage,
  onguardSettingsPage: onguardSettingsPage,
  brivoSettingsPage: brivoSettingsPage,
  openPathSettingsPage: openPathSettingsPage,
  geneaSettingsPage: geneaSettingsPage,
  gallagherSettingsPage: gallagherSettingsPage,
  amagSettingsPage: amagSettingsPage
};
