import { createSelector } from '@ngrx/store';
import { IBillingDetails, ICountry, IFeatureListMultiPrice, IModuleInfo, IUser } from '@proxyclick/data-model';
import * as _ from 'lodash';
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IPaginatedUsers } from '~/models/filters';
import { IAppState } from '../app-state';

const get = (state: IAppState) => state.companies;

export const CompaniesSelector = {
  get: createSelector(get, state => state.value),
  isLoading: createSelector(get, state => state.loading),
  error: createSelector(get, state => state.error),
};

export function usersWithAdmin(users$: Observable<IPaginatedUsers>, admins$: Observable<IUser[]>) {
  return observableCombineLatest([users$.pipe(filter(u => !!u)), admins$.pipe(filter(a => !!a))]).pipe(
    map(([users, admins]) => ({
      limit: users.limit,
      total: users.total,
      users: users.users.map(user =>
        {
          return { ...user, isAdmin: admins.some(a => a.id === user.id) }
        })
    }),
   )
  );
}

export function companyFeatureList(
  modules$: Observable<IModuleInfo[]>,
  features$: Observable<IFeatureListMultiPrice[]>
) {
  return observableCombineLatest(modules$.pipe(filter(m => !!m)), features$.pipe(filter(f => !!f))).pipe(
    map(([modules, features]) => ({
      VM: _.find(features, f => f.id === modules[1].featureListId),
      SD: _.find(features, f => f.id === modules[0].featureListId),
    }))
  );
}

export function defaultCurrency(details$: Observable<IBillingDetails>, countries$: Observable<ICountry[]>) {
  // return observableCombineLatest(
  //     details$.pipe(filter(a => !!a)), countries$.pipe(filter(a => !!a))
  // ).pipe(map(([details, countries]) => {
  //     if (!details.currency) {
  //         const country = _.find(countries, c => c.code === details.address.countryCode);
  //         details.currency = country.currency;
  //     }
  //     return details;
  // }));
}
