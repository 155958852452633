import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IFeatureList } from '@proxyclick/data-model';
import { combineLatest, of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BillingService } from '~/services/billing.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { BillingActions } from '~/store/billing/billing.actions';
import { BillingSelectors } from '~/store/billing/billing.selector';
import { CompanySelector } from '~/store/company/selectors';
import {
  FeatureListsSelectors,
  TARGET_OPTIONAL_FEATURE_LIST_REF
} from '~/store/feature-lists/feature-lists.selector';
import { AppStore } from '~/store/store';

@Component({
  selector: 'pxc-billing-migration-form',
  templateUrl: './migration-form.html',
  styleUrls: ['./migration-form.scss'],
})
export class BillingMigrationFormComponent implements OnInit, OnDestroy {
  modules$ = this.store.select('company', 'modules', 'value');
  migrateForm = new FormGroup({
    selectedMandatoryFeatureList: new FormControl(null, Validators.required),
    selectedOptionalFeatureLists: new FormControl([]),
  });

  get selectedMandatoryFeatureList(): IFeatureList {
    return this.migrateForm.get('selectedMandatoryFeatureList').value;
  }

  get selectedOptionalFeatureLists(): IFeatureList[] {
    return this.migrateForm.get('selectedOptionalFeatureLists').value;
  }

  get selectedFeatureLists(): IFeatureList[] {
    return this.selectedOptionalFeatureLists.concat(this.selectedMandatoryFeatureList);
  }

  targetFeatureLists$ = this.store.select(FeatureListsSelectors.getTargetFeatureLists);
  targetMandatoryFeatureLists$ = this.store.select(FeatureListsSelectors.getTargetMandatoryFeatureLists);
  targetOptionalFeatureLists$ = combineLatest([
    this.store.select(FeatureListsSelectors.getTargetOptionalFeatureLists),
    this.migrateForm.controls.selectedMandatoryFeatureList.valueChanges,
  ]).pipe(
    map(([featureLists]) =>
      featureLists.filter(
        featureList =>
          this.selectedMandatoryFeatureList &&
          TARGET_OPTIONAL_FEATURE_LIST_REF[this.selectedMandatoryFeatureList.reference].includes(featureList.reference)
      )
    )
  );
  currentFeaturesLists$ = this.store.select(BillingSelectors.getActivatedFeatureLists);
  currentPackFeatureLists$ = this.store.select(BillingSelectors.getActivatedPackFeatureLists);
  currentIntegrationFeatureLists$ = this.store.select(BillingSelectors.getActivatedIntegrationFeatureLists);

  diffCurrentAndTargetMandatoryFeatureLists$ = this.store.select(
    BillingSelectors.getDiffTargetAndCurrentMandatoryFeatureLists
  );

  billing$ = this.Billing.getBilling$();
  currentBillingVersion$ = this.store.select(BillingSelectors.getCurrentBillingVersion);
  company$ = this.store.select(CompanySelector.Company.Value);

  featureLists$ = combineLatest([
    this.targetFeatureLists$,
    this.targetMandatoryFeatureLists$,
    this.currentFeaturesLists$,
    this.currentPackFeatureLists$,
    this.currentIntegrationFeatureLists$,
    this.diffCurrentAndTargetMandatoryFeatureLists$,
  ]).pipe(
    map(
      ([
        targetFeatureLists,
        targetMandatoryFeatureLists,
        currentFeaturesLists,
        currentPackFeatureLists,
        currentIntegrationFeatureLists,
        diffCurrentAndTargetMandatoryFeatureLists,
      ]) => ({
        targetFeatureLists,
        targetMandatoryFeatureLists,
        currentFeaturesLists,
        currentPackFeatureLists,
        currentIntegrationFeatureLists,
        diffCurrentAndTargetMandatoryFeatureLists,
      })
    )
  );

  companyId: string;
  errMsg: string;

  private subscriptions: Subscription[] = [];

  constructor(
    public modal: NgbActiveModal,
    private store: AppStore,
    private Billing: BillingService,
    private Notifications: NotificationsService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.company$.subscribe(company => {
        this.companyId = company.id;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  migrate() {
    return this.Billing.migrateBilling(
      this.companyId,
      this.selectedFeatureLists.map(featureList => ({ ...featureList, price: 0 }))
    ).pipe(
      tap(() => this.store.dispatch(BillingActions.BillingLoad({ companyId: this.companyId }))),
      tap(() => this.Notifications.success('Company successfully migrated to new Pricing')),
      tap(() => this.modal.close()),
      catchError(err => {
        this.errMsg = err && err.error && err.error.message;
        return of(null);
      })
    );
  }
}
