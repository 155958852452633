<div class="row justify-content-center">
  <div *ngIf="dashboardBaseUrl && companyId$ | async as companyId">
    <div class="u-flex-column justify-content-center">
      <qrcode
        class="u-align-center"
        [elementType]="'svg'"
        [qrdata]="dashboardBaseUrl + '?companyId=' + companyId"
        [width]="512"
        [errorCorrectionLevel]="'M'"
        (qrCodeURL)="onChangeURL($event)"
      ></qrcode>
    </div>
    <div class="d-flex justify-content-center u-padding-all-m">
      <a class="c-button c-button--s c-button--secondary u-margin-right-m" [href]="qrCodeSrc" download>
        Download SVG
      </a>
    </div>
  </div>
</div>
