import { Component, OnInit } from '@angular/core';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';
import { FastPaceService } from '~/services/fastpace.service';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { LocationsLocationData, TenantsTenantEntity } from '@proxyclick/extender-server-genea-client';
import { ControllersCompaniesGeneaCompanySettingsResponseV1 } from '@proxyclick/fastpace-client';

export interface GeneaDoor {
  uuid: string;
  name: string;
}

@Component({
  selector: 'app-fastpace-company-genea',
  templateUrl: './fastpace.company.genea.component.html',
})
export class FastPaceCompanyGeneaComponent extends FastPaceBaseSystemSettingsComponent implements OnInit {
  constructor(fastpaceService: FastPaceService, companyService: CompaniesService, extenderService: ExtenderService) {
    super(fastpaceService, companyService, extenderService);
  }
  async ngOnInit(): Promise<void> {
    this.geneaSettings = await this.fastpaceService.getGeneaSettings(this.fastpaceCompany.companyId);
    this.location = (
      await this.extenderService.genea.locations.find(this.fastpaceCompany.extenderId, this.geneaSettings.locationUuid)
    ).data;

    if (this.geneaSettings.tenantUuid) {
      this.tenant = (
        await this.extenderService.genea.tenants.listAll(
          this.fastpaceCompany.extenderId,
          this.geneaSettings.locationUuid
        )
      ).filter(x => x.uuid == this.geneaSettings.tenantUuid)[0];
    }

    const mercuryDoors = (
      await this.extenderService.genea.mercuryDoors.listAll(
        this.fastpaceCompany.extenderId,
        this.geneaSettings.customerUuid
      )
    ).data;
    this.doors = mercuryDoors.map(x => {
      return { uuid: x.uuid, name: x.name };
    });
    this.setInitialState();
  }

  //current settings
  geneaSettings: ControllersCompaniesGeneaCompanySettingsResponseV1 | null = null;
  location: LocationsLocationData | null = null;
  tenant: TenantsTenantEntity | null = null;

  doors: GeneaDoor[] = [];
  doorOptions: GeneaDoor[] = [];
  doorOptionIn: GeneaDoor | null = null;
  doorOptionOut: GeneaDoor | null = null;

  doorFilterIns: GeneaDoor[] = [];
  doorFilterOuts: GeneaDoor[] = [];

  setInitialState() {
    if (this.geneaSettings != null && this.doors != null && this.doors.length > 0) {
      this.doorFilterIns = this.doors.filter(
        x => this.geneaSettings.doorFilterIn.filter(y => y == x.uuid).length === 1
      );
      this.doorFilterOuts = this.doors.filter(
        x => this.geneaSettings.doorFilterOut.filter(y => y == x.uuid).length === 1
      );

      this.setDoorOptions();
    }
  }

  setDoorOptions() {
    const options = this.doors.filter(c => {
      //Filter out access points assigned
      return (
        this.doorFilterIns.filter(a => a.uuid === c.uuid).length === 0 &&
        this.doorFilterOuts.filter(a => a.uuid == c.uuid).length === 0
      );
    });

    this.doorOptions = options;
    if (this.doorOptions.length > 0) {
      this.doorOptionIn = this.doorOptions[0];
      this.doorOptionOut = this.doorOptions[0];
    } else {
      this.doorOptionIn = null;
      this.doorOptionOut = null;
    }
  }

  canAddInMapping() {
    return this.doorOptionIn && this.doorFilterIns.filter(d => d.uuid === this.doorOptionIn.uuid).length < 1;
  }
  canAddOutMapping() {
    return this.doorOptionOut && this.doorFilterOuts.filter(d => d.uuid === this.doorOptionOut.uuid).length < 1;
  }
  addInMapping() {
    this.doorFilterIns.push(this.doorOptionIn);
    this.setDoorOptions();
  }

  addOutMapping() {
    this.doorFilterOuts.push(this.doorOptionOut);
    this.setDoorOptions();
  }

  removeInMapping(doorId: string) {
    this.doorFilterIns = this.doorFilterIns.filter(d => d.uuid !== doorId);
    this.setDoorOptions();
  }
  removeOutMapping(doorId: string) {
    this.doorFilterOuts = this.doorFilterOuts.filter(d => d.uuid !== doorId);
    this.setDoorOptions();
  }

  get canSubmit(): boolean {
    return this.submitting !== true;
  }

  async doUpdate(): Promise<void> {
    //No update at the moment
    return this.fastpaceService.updateGeneaSettings(this.fastpaceCompany.companyId, {
      doorFilterIn: this.doorFilterIns.map(c => c.uuid),
      doorFilterOut: this.doorFilterOuts.map(c => c.uuid),
    });
  }
}
