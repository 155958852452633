<div class="c-card__section">
  <h3>Genea settings</h3>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Location</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-obj-dropdown
          [(ngModel)]="locationOption"
          [options]="locationOptions"
          (ngModelChange)="locationOptionChanged()"
          key="uuid"
          label="name"
        ></pxc-obj-dropdown>
      </div>
    </div>
  </div>
  <div *ngIf="locationOption && locationOption.type == 'ENTERPRISE_BUILDING'">
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Tenant</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="tenantOption"
            [options]="tenantOptions"
            (ngModelChange)="tenantOptionChanged()"
            key="uuid"
            label="name"
          ></pxc-obj-dropdown>
        </div>
      </div>
      <div class="c-helper">
        The tenant visitors will be added to
      </div>
    </div>
  </div>
  <div *ngIf="locationOption && locationOptionSettings && locationOptionSettings.hardwareProvider == 'HID'">
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Card Set</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="cardSetOption"
            [options]="cardSetOptions"
            (ngModelChange)="cardSetOptionChanged()"
            key="id"
            label="name"
          ></pxc-obj-dropdown>
        </div>
      </div>
      <div class="c-helper">
        Card Set used when adding cards. Format must support at least 8 digits (32 data bits)
      </div>
    </div>
  </div>
  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>
