<form class="inner-addon" style="min-width: 250px;" (ngSubmit)="submitSearch()">
  <input
    [(ngModel)]="term"
    name="term"
    placeholder="Search companies, users..."
    (keyup)="onKeyup($event, term)"
    type="text"
    class="form-control"
    autocomplete="off"
  />
  <i *ngIf="loading$ | async" class="fas fa-sync-alt fa-spin addon-right"></i>
  <pxc-search-results
    [search]="values$ | async"
    [loadingUsers]="loadingUsers$ | async"
    [loadingCompanies]="loadingCompanies$ | async"
  >
  </pxc-search-results>
</form>
