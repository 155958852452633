<!-- Step 3 -->
<div class="c-card__section">
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Custom field values as access levels</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-switch
          [(ngModel)]="customFieldsAsAccessLevels"
          name="customFieldsAsAccessLevels"
          [onChange]="changeCustomFieldsAsAccessLevels()"
        ></pxc-switch>
      </div>
    </div>
  </div>
  <div class="l-form__field" *ngIf="customFieldsAsAccessLevels">
    <h5>Select field</h5>
    <div class="text-warning" *ngIf="customFields.length === 0">
      No custom fields of the list type are available
    </div>

    <div *ngIf="customFields.length > 0" class="l-stack c-box u-padding-all-none">
      <div class="l-stack__item">
        <div class="c-stacked-list c-stacked-list--s">
          <ul class="c-stacked-list__list">
            <li *ngFor="let customField of customFields" class="c-stacked-list__item">
              <a
                class="c-stacked-list__inner c-stacked-list__inner--interactive"
                (click)="selectCustomField(customField.id)"
              >
                <div class="c-stacked-list__inner-start">
                  {{ customField.name }}
                </div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon
                      icon="checkmark-24"
                      width="24"
                      height="24"
                      *ngIf="
                        setupState.accessLevelsCustomField && setupState.accessLevelsCustomField.id === customField.id
                      "
                    ></hl-icon>
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="c-helper">Only custom fields of the list type are suitable for access level usage</div>
  </div>
  <div class="c-helper">
    Proxyclick normally creates 3 access levels:
    <ul>
      <li>Proxyclick expected</li>
      <li>Proxyclick checked-in</li>
      <li>Proxyclick checked-out</li>
    </ul>
    We can add the values of a custom field to these. For example, a field with three options (Contractor, Visitor,
    Employee) would result in 9 access levels.
    <br />
    <ul>
      <li>Proxyclick expected - Contractor</li>
      <li>Proxyclick expected - Visitor</li>
      <li>Proxyclick expected - Employee</li>
      <li>Proxyclick checked-in - Contractor</li>
      <li>Proxyclick checked-in - Visitor</li>
      <li>Proxyclick checked-in - Employee</li>
      <li>Proxyclick checked-out - Contractor</li>
      <li>Proxyclick checked-out - Visitor</li>
      <li>Proxyclick checked-out - Employee</li>
    </ul>

    Please not that per company there is also the option to add the company name to the access level name for per-tenant
    access.
  </div>
  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>
