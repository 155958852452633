import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { AdminCompaniesService } from '~/services/admin-companies.service';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';
import { AdminCompaniesActions } from './admin-companies.reducer';

@Injectable()
export class AdminCompaniesEffects {
  constructor(private actions$: Actions<PayloadAction>, private Companies: AdminCompaniesService) {}

  
  loadAll$ = createEffect(() => generateAsyncEffect(this.actions$, AdminCompaniesActions, domain =>
    this.Companies.getCompanies({
      page: 1,
      pageSize: 200,
      domainId: domain,
    })
  ));
}
