import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';
import { IQueryablePaginationFilter } from '~/models/filters';
import { FastPaceService } from '~/services/fastpace.service';
import { USER_LOGGED_IN } from '~/store/app-state';
import { FastPaceCompanyActions } from '~/store/fastpace/fastpace.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';

@Injectable()
export class FastPaceEffects {
  constructor(private actions$: Actions<PayloadAction>, private FastPace: FastPaceService) {}

  initCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_LOGGED_IN),
      map(() =>
        FastPaceCompanyActions.Companies.Load({
          page: 1,
          pageSize: 20,
        })
      )
    )
  );

  company$ = createEffect(() =>
    generateAsyncEffect(this.actions$, FastPaceCompanyActions.Company, companyId =>
      from(this.FastPace.getCompany(companyId))
    )
  );

  companies$ = createEffect(() =>
    generateAsyncEffect(this.actions$, FastPaceCompanyActions.Companies, (filter: IQueryablePaginationFilter) =>
      from(this.FastPace.getCompanies(filter))
    )
  );
}
