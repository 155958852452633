import { Component } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ICompany } from '@proxyclick/data-model';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-companies-selector',
  templateUrl: './companies-selector.html',
  providers: controlValueAccessor(CompaniesSelectorComponent),
})
export class CompaniesSelectorComponent implements ControlValueAccessor {
  company;
  companies: ICompany[] = [];

  private onChange;

  onChangeInternal(company: ICompany) {
    this.companies.push(company);
    this.onChange(this.companies);
    this.company = '';
  }

  remove(company: ICompany) {
    this.companies.splice(this.companies.indexOf(company), 1);
    this.onChange(this.companies);
  }

  writeValue(obj: any): void {
    this.companies = obj || [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    return;
  }
  setDisabledState?(isDisabled: boolean): void {
    return;
  }
}
