import { Component, Input } from '@angular/core';
import { IOption } from '~/shared/components/input/radio/radio.component';

@Component({
  selector: 'pxc-tabs',
  templateUrl: 'tabs.html',
  styleUrls: ['./tabs.scss'],
})
export class TabsComponent {
  @Input() tabs: IOption[];
}
