import { Component, Input } from '@angular/core';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';

@Component({
  selector: 'app-fastpace-company-setup-generate-tokens',
  templateUrl: './generate-tokens.component.html',
})
export class GenerateTokensComponent {
  @Input() setupState: FastPaceSetupCompanyState;
}
