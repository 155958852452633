export const localTranslations = {
  category_checkin_notifications: 'Check-in notifications',
  category_sales_marketing: 'Sales & marketing',
  category_storage: 'Storage',
  category_calendar: 'Calendar',
  category_access_control: 'Access control',
  category_wifi: 'Wi-Fi',
  category_external_watch_list: 'External watch list',
  category_parking_management: 'Parking management',
  category_meeting_room: 'Workspace & asset booking',
  category_security_integrators: 'Security integrators',
  category_systems_integrator: 'Systems integrators',
  category_security_services: 'Security services',
  category_hospitality_services: 'Hospitality services',
  category_software_vendors: 'Software vendors',
  category_sso: 'Single Sign On (SSO)',
  category_user_provisioning: 'User Provisioning',
  category_wayfinding: 'Wayfinding',
  category_compliance: 'Compliance',
  category_most_popular: 'Most Popular',
  category_build: 'Build',
  category_desk_booking: 'Desk booking',
  category_temperature_scanning: 'Temperature scanning',
  category_custom: 'Custom',
  category_employee_apps: 'Virtual reception',
  category_smart_parking: 'Smart parking',
};
