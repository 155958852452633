import { Injectable, Injector } from '@angular/core';
import { BaseService } from '~/services/base.service';

@Injectable()
export class KioskService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  getKioskParams(companyId: string) {
    return this.apiClient.ApplicationVisitorManagementKioskParams(companyId).doGet();
  }

  setKioskParams(companyId: string, data: any) {
    return this.apiClient.ApplicationVisitorManagementKioskParams(companyId).doPatch(data);
  }

  setKioskVersion(companyId: string, ios: boolean) {
    const method = ios
      ? this.apiClient.ApplicationVisitorManagementKioskUpgrade(companyId).doPost()
      : this.apiClient.ApplicationVisitorManagementKioskDowngrade(companyId).doPost();
    return method;
  }
}
