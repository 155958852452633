import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { IUser } from '@proxyclick/data-model';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UserService } from '~/services/users.service';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-users-typeahead',
  templateUrl: './users-typeahead.html',
  providers: controlValueAccessor(UsersTypeAheadComponent),
})
export class UsersTypeAheadComponent implements ControlValueAccessor {
  user: IUser;

  @Input()
  domain?: string;

  @Input()
  companyId?: string;

  @Input()
  placeholder?: string = 'Key Contact...';

  private onChange: (user: IUser) => void;

  constructor(private Users: UserService) {}

  onInternalChange() {
    if (this.user && this.user.id) {
      this.onChange(this.user);
    } else if (this.user === '') {
      this.user = null;
      this.onChange(null);
    }
  }

  writeValue(admin): void {
    this.user = admin;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    return;
  }

  setDisabledState?(isDisabled: boolean): void {
    return;
  }

  // .bind(this) is not working on ng-bootstrap > 9. This is a workaournd
  // https://github.com/ng-bootstrap/ng-bootstrap/issues/4055
  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => (term.length <= 2 ? [] : this.Users.searchUsers(term, this.domain, this.companyId)))
    );
  }

  formatInput(admin: IUser) {
    return `${admin.firstname} ${admin.lastname}`;
  }
}
