import { refCount, publishReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

export class ObservableCache {
  private cache = {};

  getObservable<T>(key: string, observable: Observable<T>): Observable<T> {
    if (!this.cache[key]) {
      this.cache[key] = observable.pipe(publishReplay(1), refCount());
    }
    return this.cache[key];
  }

  invalidate(key?: string) {
    if (!key) {
      this.cache = {};
    } else {
      this.cache[key] = null;
    }
  }
}
