<!-- TODO: Add right -->
<pxc-loading [loading]="loading$ | async">
  <pxc-breadcrumb [breadcrumbs]="breadcrumbs$ | async"></pxc-breadcrumb>

  <div class="domain-details" *ngIf="domain$ | async as domain">
    <h3 class="mb-4">{{domain.domain}}</h3>
    <img
      *ngIf="domain.clearbitData && domain.clearbitData.logo"
      class="domain-logo logo-medium"
      src="{{domain.clearbitData.logo}}"
    />
    <pxc-tabs [tabs]="tabs" class="mb-2"></pxc-tabs>
    <router-outlet></router-outlet>
  </div>
</pxc-loading>
