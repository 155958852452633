import { Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { IAppState } from '~/store/app-state';

export abstract class BasePage {
  store: Store<IAppState>;
  route: ActivatedRoute;
  Notifications: NotificationsService;

  constructor(injector: Injector) {
    this.store = injector.get(Store) as Store<IAppState>;
    this.route = injector.get(ActivatedRoute);
    this.Notifications = injector.get(NotificationsService);
  }
}
