import { Component, OnInit } from '@angular/core';
import {
  CredentialFormatsCredentialFormatResponseV1,
  PartitionsPartitionResponseV1,
} from '@proxyclick/extender-server-genetec-client';
import { ControllersCompaniesGenetecCompanySettingsResponseV1 } from '@proxyclick/fastpace-client';
import { v4 as uuidv4 } from 'uuid';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceService } from '~/services/fastpace.service';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';

@Component({
  selector: 'app-fastpace-company-genetec',
  templateUrl: './fastpace.company.genetec.component.html',
})
export class FastPaceCompanyGenetecComponent extends FastPaceBaseSystemSettingsComponent implements OnInit {
  constructor(fastpaceService: FastPaceService, companyService: CompaniesService, extenderService: ExtenderService) {
    super(fastpaceService, companyService, extenderService);
  }
  async ngOnInit(): Promise<void> {
    this.genetecSettings = await this.fastpaceService.getGenetecSettings(this.fastpaceCompany.companyId);
    if (this.genetecSettings.partitionId != null) {
      this.partition = await this.extenderService.genetec.partitions.find(this.fastpaceCompany.extenderId, {
        requestId: this.genetecSettings.partitionId,
        requestTracingId: uuidv4(),
      });
    }

    this.credentialFormats = (
      await this.extenderService.genetec.credentialFormats.list(this.fastpaceCompany.extenderId, {
        requestTracingId: uuidv4(),
      })
    ).filter(x => x.isCustomFormat && x.unfixedFields.length > 0);

    this.qrCodeCredentialFormat =
      this.credentialFormats.find(x => x.formatId === this.genetecSettings.qrCodeCredentialFormatId) ?? null;

    this.cardCredentialFormat =
      this.credentialFormats.find(x => x.formatId === this.genetecSettings.cardCredentialFormatId) ?? null;
  }

  //current settings
  credentialFormats: CredentialFormatsCredentialFormatResponseV1[] = [];
  genetecSettings: ControllersCompaniesGenetecCompanySettingsResponseV1 | null = null;
  partition: PartitionsPartitionResponseV1 | null = null;
  qrCodeCredentialFormat: CredentialFormatsCredentialFormatResponseV1 | null = null;
  cardCredentialFormat: CredentialFormatsCredentialFormatResponseV1 | null = null;
  facilityCode: number | null;

  get canSubmit(): boolean {
    return this.submitting !== true;
  }

  async doUpdate(): Promise<void> {
    return this.fastpaceService.updateGenetecSettings(this.fastpaceCompany.companyId, {
      accessPointFilterIn: [],
      accessPointFilterOut: [],
      cardCredentialFormatId: this.cardCredentialFormat?.formatId,
      facilityCode: this.facilityCode,
    });
  }
}
