<div class="c-card__section">
  <h3>Token setup</h3>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Generate access control tokens</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-switch [(ngModel)]="setupState.generateTokens" name="generateTokens" *ngIf="setupState.useExtender">
        </pxc-switch>
        <div *ngIf="!setupState.useExtender">
          <input type="checkbox" class="c-switch c-switch--locked" disabled="" [checked]="true" />
          <label for="example-switch-locked-on" class="c-switch__skin"></label>
        </div>
      </div>
    </div>
    <div class="c-helper">
      Do you want to generate tokens for this company? Most companies need this setting, only the ones that want to use
      manual tokens only and skip us putting in a Proxyclick QR token into the access control profile for that visitor
      would want to skip this.
    </div>
  </div>
  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="true"></app-fastpace-setup-footer>
</div>
