import { Component, Input } from '@angular/core';
import { ILdapData } from '@proxyclick/data-model';

@Component({
  selector: 'pxc-ldap-status',
  templateUrl: './ldap-status.html',
  styleUrls: ['./ldap-status.scss'],
})
export class LdapStatusComponent {
  @Input()
  ldap: ILdapData;

  getClass(ldap: ILdapData) {
    switch (ldap.status) {
      case 'RUNNING':
        return 'running';
      case 'STOPPED':
        return 'stopped';
      default:
        return 'failed';
    }
  }
}
