import { combineReducers, createReducer, on } from '@ngrx/store';
import { ControllersCompaniesFastPaceCompanyResponseV1 } from '@proxyclick/fastpace-client';
import { IQueryablePaginationFilter } from '~/models/filters';
import { IPaginatedResponse } from '~/store/fastpace/fastpace.models';
import { createAsyncLoadActions } from '~/utils/async-generator';

const fastpaceCompaniesBase = 'fastpace/companies';
const fastpaceCompanyBase = 'fastpace/company';

export const FastPaceCompanyActions = {
  Companies: createAsyncLoadActions<
    IQueryablePaginationFilter,
    IPaginatedResponse<ControllersCompaniesFastPaceCompanyResponseV1>
  >(fastpaceCompaniesBase),
  Company: createAsyncLoadActions<string, ControllersCompaniesFastPaceCompanyResponseV1>(fastpaceCompanyBase),
};

export const FastPaceCompaniesReducer = createReducer(
  {},
  on(FastPaceCompanyActions.Companies.Load, (state, action) => ({
    ...state,
    data: { loading: true, error: null },
  })),
  on(FastPaceCompanyActions.Companies.Loaded, (state, action) => ({
    ...state,
    data: { loading: false, error: null, value: action.payload },
  })),
  on(FastPaceCompanyActions.Companies.Error, (state, action) => ({
    ...state,
    data: { loading: false, error: action.payload as Error },
  })),
  on(FastPaceCompanyActions.Company.Load, (state, action) => ({
    ...state,
    current: { loading: true, error: null },
  })),
  on(FastPaceCompanyActions.Company.Loaded, (state, action) => ({
    ...state,
    current: { loading: false, error: null, value: action.payload },
  })),
  on(FastPaceCompanyActions.Company.Error, (state, action) => ({
    ...state,
    current: { loading: false, error: action.payload as Error },
  }))
);

export const fastPaceReducer = combineReducers({
  companies: FastPaceCompaniesReducer,
});
