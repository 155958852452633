<div class="pxc-page">
  <div class="d-sm-flex align-items-center">
    <div class="form-group">
      <label for="pxcRef"><strong>Pxc Reference</strong></label>
      <div class="form-inline">
        <input id="pxcRef" [(ngModel)]="pxcRef" (ngModelChange)="computeID()" type="text" class="form-control" />
        <pxc-dropdown
          style="min-width: 150px;"
          [(ngModel)]="type"
          (ngModelChange)="computeRef()"
          [options]="typeOptions"
          class="ml-2"
        ></pxc-dropdown>
        <i class="fas fa-arrows-alt-h fa-2x ml-4"></i>
      </div>
    </div>
    <div class="form-group ml-4">
      <label for="pxcId"><strong>ID</strong></label>
      <input id="pxcId" [(ngModel)]="pxcId" (ngModelChange)="computeRef()" type="text" class="form-control" />
    </div>
  </div>
  <div class="d-flex mt-5">
    <div class="form-group flex-fill">
      <label for="idInput"><strong>Pxc Reference</strong></label>
      <textarea [(ngModel)]="idInput" (ngModelChange)="convert()" class="form-control" id="idInput" rows="5"></textarea>
    </div>

    <div class="ml-4 form-group flex-fill">
      <label for="refInput"><strong>ID</strong></label>
      <textarea [(ngModel)]="refInput" class="form-control" id="refInput" rows="5"></textarea>
    </div>
  </div>

  <div class="d-flex mt-5">
    <div class="form-group flex-fill">
      <label for="idInput"><strong>ID</strong></label>
      <textarea
        [(ngModel)]="idToPxcInput"
        (ngModelChange)="convertIdToPxc()"
        class="form-control"
        id="idToPxcInput"
        rows="5"
      ></textarea>
    </div>
    <div class="ml-4 form-group flex-fill">
      <label for="refInput"><strong>Pxc Reference</strong></label>
      <textarea [(ngModel)]="idToPxcOutput" class="form-control" id="idToPxcOutput" rows="5"></textarea>
    </div>
  </div>
</div>
