import { Component, OnInit } from '@angular/core';
import { ICustomField } from '@proxyclick/data-model';
import {
  ChuidFormatsChuidFormatV1,
  PartitionsPartitionV1,
  PersonnelGroupsGroupResponseV1,
} from '@proxyclick/extender-server-ccure-client';
import { ControllersCompaniesCCureCompanySettingsResponseV1 } from '@proxyclick/fastpace-client';
import { v4 as uuidv4 } from 'uuid';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceService } from '~/services/fastpace.service';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { fetchAll } from '~/utils/utils';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';

@Component({
  selector: 'app-fastpace-company-ccure',
  templateUrl: './fastpace.company.ccure.component.html',
})
export class FastPaceCompanyCCureComponent extends FastPaceBaseSystemSettingsComponent implements OnInit {
  constructor(fastpaceService: FastPaceService, companyService: CompaniesService, extenderService: ExtenderService) {
    super(fastpaceService, companyService, extenderService);
  }
  async ngOnInit(): Promise<void> {
    this.ccureSettings = await this.fastpaceService.getCCureSettings(this.fastpaceCompany.companyId);

    this.customFields = await this.companyService.getCustomFields(this.fastpaceCompany.companyId).toPromise();

    this.ccureCustomFields = (
      await this.extenderService.getCCureExtenderConfig(this.fastpaceCompany.extenderId).toPromise()
    ).customFieldOptions;

    this.ccurePartitions = (
      await this.extenderService.ccure.partitions.list(this.fastpaceCompany.extenderId, { requestTracingId: uuidv4() })
    ).partitions;

    this.ccurePersonnelGroups = await fetchAll<PersonnelGroupsGroupResponseV1>(
      async page =>
        (
          await this.extenderService.ccure.personnelGroups.list(this.fastpaceCompany.extenderId, {
            requestPageSize: 100,
            requestPageNumber: page,
            requestTracingId: uuidv4(),
          })
        ).groups
    );

    this.ccureChuidFormats = (
      await this.extenderService.ccure.chuidFormats.list(this.fastpaceCompany.extenderId, {
        requestTracingId: uuidv4(),
      })
    ).chuidFormats;

    this.setInitialState();
  }

  //current settings
  ccureSettings: ControllersCompaniesCCureCompanySettingsResponseV1 | null = null;
  //new settings
  partition: PartitionsPartitionV1 | null;
  usePersonnelGroup: boolean;
  personnelGroup: PersonnelGroupsGroupResponseV1 | null;
  customFieldMappings: Array<{ customField: ICustomField; fieldName: string }> = [];

  ccurePartitions: PartitionsPartitionV1[];

  ccurePersonnelGroups: PersonnelGroupsGroupResponseV1[];
  ccurePersonnelGroupOptions: PersonnelGroupsGroupResponseV1[] = [];
  ccurePersonnelGroupOption: PersonnelGroupsGroupResponseV1 | null = null;

  //custom field mapping
  mapCustomFields: boolean = false;

  customFields: ICustomField[] = [];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  ccureCustomFields: Array<string> = [];
  ccureCustomFieldOptions: IOption[] = [];
  ccureCustomFieldOption: string | undefined = '';

  ccureChuidFormats: ChuidFormatsChuidFormatV1[] = [];
  ccurePhysicalChuidFormat: ChuidFormatsChuidFormatV1 | null;
  ccureQrCodeChuidFormat: ChuidFormatsChuidFormatV1 | null;
  ccureFacilityCode: number | null = null;

  switchUsePersonnelGroup() {
    if (this.usePersonnelGroup === false) {
      this.personnelGroup = null;
    }
  }

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.customFieldMappings = [];
    }
  }

  setInitialState() {
    if (this.ccureSettings !== null) {
      this.usePersonnelGroup = this.ccureSettings.usePersonnelGroup;
    }
    if (this.ccureSettings !== null && this.customFields.length > 0 && this.ccureCustomFields.length > 0) {
      this.mapCustomFields = this.ccureSettings.customFieldMappings.length > 0 ? true : false;
      this.customFieldMappings = this.ccureSettings.customFieldMappings.map(c => {
        var customField = this.customFields.find(cf => cf.id == c.customFieldId);
        return { customField: customField, fieldName: c.fieldName };
      });
      this.setCCureCustomFieldOptions();
      this.setCustomFieldOptions();
    }

    if (this.ccureSettings != null && this.ccureSettings.partitionId != null && this.ccurePartitions.length > 0) {
      this.partition = this.ccurePartitions.filter(x => x.objectId == this.ccureSettings.partitionId)[0];
    }

    if (this.ccurePersonnelGroups.length > 0) {
      this.setCCurePersonnelGroupOptions();
    }

    if (this.ccureSettings != null && this.ccurePersonnelGroups.length > 0) {
      if (this.usePersonnelGroup) {
        this.ccurePersonnelGroupOption = { objectId: this.ccureSettings.personnelGroupId };
        this.personnelGroup = this.ccurePersonnelGroupOption;
      }
    }
    if (this.ccureSettings?.qrCodeChuidFormatId != null) {
      this.ccureQrCodeChuidFormat = this.ccureChuidFormats.find(
        x => x.objectId == this.ccureSettings.qrCodeChuidFormatId
      );
    }

    if (this.ccureSettings?.cardChuidFormatId != null) {
      this.ccurePhysicalChuidFormat = this.ccureChuidFormats.find(
        x => x.objectId == this.ccureSettings.cardChuidFormatId
      );
    }

    this.ccureFacilityCode = this.ccureSettings.facilityCode;
  }

  setCCurePersonnelGroupOptions() {
    this.ccurePersonnelGroupOptions = this.ccurePersonnelGroups;
  }

  setCCureCustomFieldOptions() {
    const options = this.ccureCustomFields
      .filter(c => {
        //Filter out custom fields already mapped
        return (
          this.customFieldMappings !== undefined && this.customFieldMappings.filter(a => a.fieldName === c).length === 0
        );
      })
      .map(c => {
        return { label: c, value: c };
      });

    this.ccureCustomFieldOptions = options;
    if (this.ccureCustomFieldOptions.length > 0) {
      this.ccureCustomFieldOption = this.ccureCustomFieldOptions[0].value;
    } else {
      this.ccureCustomFieldOption = undefined;
    }
  }

  ccurePersonnelGroupOptionChanged() {
    this.personnelGroup = this.ccurePersonnelGroupOption;
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return (
        this.customFieldMappings !== undefined &&
        this.customFieldMappings.filter(a => a.customField.id === c.id).length === 0
      );
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = null;
    }
  }

  canAddMapping() {
    return (
      this.customFieldOption &&
      this.ccureCustomFieldOption &&
      this.customFieldMappings.filter(
        c => c.fieldName === this.ccureCustomFieldOption || c.customField.id === this.customFieldOption.id
      ).length === 0
    );
  }

  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }
    this.customFieldMappings.push({
      customField: this.customFieldOption,
      fieldName: this.ccureCustomFieldOption,
    });
    this.setCustomFieldOptions();
    this.setCCureCustomFieldOptions();
  }

  removeMapping(id: number) {
    this.customFieldMappings = this.customFieldMappings.filter(c => c.customField.id !== id);
    this.setCustomFieldOptions();
    this.setCCureCustomFieldOptions();
  }

  async doUpdate(): Promise<void> {
    return this.fastpaceService.updateCCureSettings(this.fastpaceCompany.companyId, {
      customFieldMappings: this.customFieldMappings.map(x => {
        return { customFieldId: x.customField.id, fieldName: x.fieldName };
      }),
      usePersonnelGroup: this.usePersonnelGroup,
      personnelGroupId: this.personnelGroup != null ? this.personnelGroup.objectId : null,
      doorFilterIn: [],
      doorFilterOut: [],
      cardChuidFormatId: this.ccurePhysicalChuidFormat?.objectId ?? null,
      facilityCode: this.ccureFacilityCode,
    });
  }

  get canSubmit(): boolean {
    return this.submitting !== true;
  }
}
