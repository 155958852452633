import { Injectable } from '@angular/core';
import { IJWTPayload } from '../../../../shared/models';

@Injectable()
export class JWTService {
  constructor() {}

  setJWT(jwt) {
    return localStorage.setItem('pxc-jwt', jwt);
  }

  removeJWT() {
    return localStorage.removeItem('pxc-jwt');
  }

  getJWT() {
    return localStorage.getItem('pxc-jwt');
  }

  getParsedJWT(): IJWTPayload {
    const jwt = this.getJWT();
    if (!jwt) {
      return null;
    }
    const base64Url = this.getJWT().split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}
