<ng-template #rt let-r="result">
  <span class="flex center">
    {{r.firstName}} {{r.lastName}}
  </span>
</ng-template>
<input
  type="text"
  class="form-control input-small position-relative"
  name="admin"
  [(ngModel)]="admin"
  (ngModelChange)="onInternalChange()"
  [ngbTypeahead]="search"
  [inputFormatter]="formatInput"
  [resultTemplate]="rt"
  placeholder="Relationship Manager..."
/>
