import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMCategory } from '@proxyclick/data-model';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CategoriesService {
  constructor(private httpClient: HttpClient) {}

  getCategories(): Observable<IMCategory[]> {
    return this.httpClient.get<IMCategory[]>('/marketplace/api/categories').pipe(catchError(err => of([])));
  }

  updateCategories(categories: IMCategory[]) {
    return this.httpClient
      .patch<IMCategory[]>(`/marketplace/api/categories`, categories)
      .pipe(catchError(err => of([])));
  }
}
