import { tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ILdapData } from '@proxyclick/data-model';
import { UserProvisioningService } from '~/services/ldap.service';
import { IBreadcrumb } from '~/shared/components/breadcrumb/breadcrumb.component';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { IAppState } from '~/store/app-state';
import { LdapDirectoriesActions } from '~/store/user-provisioning/user-provisioning.reducer';

@Component({
  templateUrl: './ldap.html',
  styleUrls: ['./ldap-status/ldap-status.scss'],
})
export class LdapPageComponent implements OnInit {
  ldapDirectories$ = this.store.select('ldap', 'value');
  loading$ = this.store.select('ldap', 'loading');
  breadcrumbs: IBreadcrumb[] = [{ label: 'LDAPs', url: '/ldap' }];

  filterOptions = ['ALL', 'RUNNING', 'STOPPED', 'FAILED'];
  filter = this.filterOptions[0];
  name = '';

  constructor(
    private store: Store<IAppState>,
    private UserProvisioning: UserProvisioningService,
    private Notifications: NotificationsService
  ) {}

  ngOnInit() {
    this.store.dispatch(LdapDirectoriesActions.Load());
  }

  onDelete(directory: ILdapData) {
    this.UserProvisioning.ldap()
      .delete(directory.company.id)
      .pipe(tap(() => this.Notifications.success(`Directory [${directory.company.name}] deleted`)))
      .subscribe(() => this.store.dispatch(LdapDirectoriesActions.Load()));
  }

  getClass(ldap: ILdapData) {
    switch (ldap.status) {
      case 'RUNNING':
        return 'running';
      case 'STOPPED':
        return 'stopped';
      default:
        return 'failed';
    }
  }

  statusFilter(status: string) {
    return (ldap: ILdapData) => {
      if (status === 'ALL') {
        return true;
      } else {
        return ldap.status === status;
      }
    };
  }

  nameFilter(filter = '') {
    return (ldap: ILdapData) =>
      ldap.company.name.toLowerCase().includes(filter.toLowerCase()) ||
      ldap.alias.toLowerCase().includes(filter.toLowerCase());
  }
}
