import { combineReducers, createAction, createReducer, on, props } from '@ngrx/store';
import { ICCompany, ICUser } from '~/models/intercom';
import { CompanyLoad } from '../companies/companies.actions';
import { UserLoad } from '../users/users.actions';

export const IntercomLoadedCompany = createAction('intercom/loaded', props<{ company: ICCompany }>());
export const IntercomLoadedUser = createAction('intercom/user/loaded', props<{ user: ICUser }>());

export const intercomReducer = combineReducers({
  company: createReducer(
    {},
    on(CompanyLoad, state => ({ ...state, loading: true })),
    on(IntercomLoadedCompany, (state, { company }) => ({ ...state, loading: false, value: company }))
  ),
  user: createReducer(
    {},
    on(UserLoad, state => ({ ...state, loading: true })),
    on(IntercomLoadedUser, (state, { user }) => ({ ...state, loading: false, value: user }))
  ),
});
