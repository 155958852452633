import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { LanguageService } from '~/services/languages.service';
import { USER_LOGGED_IN } from '~/store/app-state';
import { LanguagesActions } from '~/store/languages/languages.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';

@Injectable()
export class LanguagesEffects {
  constructor(private actions$: Actions<PayloadAction>, private Languages: LanguageService) {}

  
  loadAll$ = createEffect(() => generateAsyncEffect(this.actions$, LanguagesActions, () => this.Languages.getLanguages()));

  
  init$ = createEffect(() => this.actions$.pipe(
    ofType(USER_LOGGED_IN),
    map(() => LanguagesActions.Load())
  ));
}
