import { combineReducers, createReducer, on } from "@ngrx/store";
import { BatchUpdateUserEmails, IEmailChange, UpdateHomeLocation, BatchUpdateUserEmailsErrors, UpdateHomeLocationErrors } from "../users/users.actions";

export interface IBatchUserEmailChangeState {
  userEmailUpdatedErrors: IBatchUserEmailChangeError[] 
  isLoading: boolean;
};

export interface IBatchUserEmailChangeError {
  email: string;
  error?: any;
}

export interface IUpdateHomeLocation {
  done: {
    userId: string,
    newHomeLocation: string;
  };
  error: {
    data: {
      userId: string,
      newHomeLocation: string;
    },
    error: any
  };
  isLoading: boolean;
}

export const utilitiesReducer = combineReducers({
  batchUserEmailChange: createReducer<IBatchUserEmailChangeState>(
    {
      userEmailUpdatedErrors: null,
      isLoading: false,
    },
    on(BatchUpdateUserEmails, (state, payload) => {
      return {...state, isLoading: true }
    }),
    on(BatchUpdateUserEmailsErrors, (state, payload) => {
        return {...state, userEmailUpdatedErrors: payload.errors, isLoading: false }
    }),
  ),
  updateHomeLocation: createReducer<IUpdateHomeLocation>(
    {
      done:  null,
      error: null,
      isLoading: false,
    },
    on(UpdateHomeLocation, (state, payload) => {
      return {...state, isLoading: true }
    }),
    on(UpdateHomeLocationErrors, (state, payload) => {
        return {...state, errors: payload.errors, isLoading: false}
    }),
  ),
});

  
