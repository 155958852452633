<pxc-loading *pxcHasRight='"tab_partners"' [loading]="loading$ | async">
  <pxc-breadcrumb [breadcrumbs]="breadcrumbs$ | async"></pxc-breadcrumb>
  <div *ngIf="partner$ | async as partner">
    <form #form="ngForm">
      <div class="row">
        <div class="col-8">
          <pxc-field label="Name">
            <input class="form-control input-medium" [(ngModel)]="partner.name" name="name" />
          </pxc-field>
          <pxc-field label="Displayed name">
            <input class="form-control input-medium" [(ngModel)]="partner.displayName" name="displayName" />
          </pxc-field>
          <pxc-field label="Partner signup page">
            <input class="form-control input-medium" [(ngModel)]="partner.alias" name="alias" />
          </pxc-field>
          <pxc-field label="Signup link">
            <pre>{{baseUrl}}/partner-signup/{{partner.alias}}</pre>
          </pxc-field>
          <pxc-field label="Language">
            <pxc-languages-dropdown [(ngModel)]="partner.language" name="language"></pxc-languages-dropdown>
          </pxc-field>
        </div>
        <div class="col-2 offset-2">
          <div class="flex column between">
            <pxc-image-upload
              [(ngModel)]="partner.logoUrl"
              (ngModelChange)="updatePicture(partner, $event)"
              name="picture"
              target="api"
            ></pxc-image-upload>
            <pxc-button *ngIf="partner.logoUrl" class="u-margin-top-xs" type="danger" [onClick]="deleteLogo(partner)">
              Remove logo
            </pxc-button>
            <pxc-button *ngIf="form.dirty" class="u-margin-top-xs" type="primary" [onClick]="update(partner)"
              >Update
            </pxc-button>
          </div>
        </div>
      </div>
    </form>

    <h4 class="mt-2">Linked companies</h4>
    <table class="c-table">
      <thead>
        <th>Ref</th>
        <th>Company</th>
      </thead>
      <tbody>
        <tr *ngFor="let company of partner.linkedCompanies">
          <td>{{company.id}}</td>
          <td>
            <a routerLink="/companies/{{company.id}}">{{company.name}}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</pxc-loading>
