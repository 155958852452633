import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { AppPartnersService } from '~/services/app-partners.service';
import { AppPartnerDetailsActions, AppPartnersActions } from '~/store/app-partners/app-partners.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';

@Injectable()
export class AppPartnersEffects {
  constructor(private actions$: Actions<PayloadAction>, private PartnersService: AppPartnersService) {}

  
  loadAll$ = createEffect(() => generateAsyncEffect(this.actions$, AppPartnersActions, () => this.PartnersService.getPartners()));

  
  load$ = createEffect(() => generateAsyncEffect(this.actions$, AppPartnerDetailsActions, id => this.PartnersService.getPartner(id)));
}
