import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { IAsyncState } from '~/models/state';

export function searchValueSelector(...observables: Observable<IAsyncState<any>>[]) {
  return observableCombineLatest(observables).pipe(map(os => os.map(o => o.value)));
}

export function searchLoadingSelector(...observables: Observable<IAsyncState<any>>[]) {
  return observableCombineLatest(observables).pipe(map(os => !_.every(os, o => o.loading === false)));
}
