import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { UserProvisioningService } from '~/services/ldap.service';
import {
  JsonDirectoriesActions,
  JsonDirectoryActions,
  LdapDirectoriesActions,
  LdapDirectoryActions,
} from '~/store/user-provisioning/user-provisioning.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';

@Injectable()
export class LdapEffects {
  constructor(private actions$: Actions<PayloadAction<any>>, private userProvisioning: UserProvisioningService) {}

  
  getAll$ = createEffect(() => generateAsyncEffect(this.actions$, LdapDirectoriesActions, () => this.userProvisioning.ldap().getAll()));

  
  get$ = createEffect(() => generateAsyncEffect(this.actions$, LdapDirectoryActions, id =>
    this.userProvisioning.ldap().getByCompanyId(id)
  ));

  
  getAllJson$ = createEffect(() => generateAsyncEffect(this.actions$, JsonDirectoriesActions, () => this.userProvisioning.json().getAll()));

  
  getJson$ = createEffect(() => generateAsyncEffect(this.actions$, JsonDirectoryActions, id =>
    this.userProvisioning.json().getByCompanyId(id)
  ));
}
