<pxc-loading [loading]="loading$ | async"></pxc-loading>
<div *ngIf="!(loading$ | async) && categories$ | async as categories" class="p-2">
  <form>
    <table class="c-table mt-2">
      <thead>
        <tr>
          <th>Order</th>
          <th>Type</th>
          <th>Name</th>
          <th>
            <pxc-button (click)="saveCategories(categories)" loading>
              <i class="fas fa-save"></i>
              <span class="u-margin-left-2xs">Save</span>
            </pxc-button>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody class="form-group">
        <ng-container *ngFor="let category of categories; index as i">
          <tr *ngIf="category.type === 'INTEGRATION'" class="pxc-category-row table-row">
            <td>
              <input class="form-control input-xs" type="number" name="order-{{i}}" [(ngModel)]="categories[i].order" />
            </td>
            <td>{{category.type}}</td>
            <td>{{category.name | translate}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </form>
</div>
