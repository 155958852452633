<pxc-loading *pxcHasRight='"tab_users"' [loading]="loading$ | async">
  <pxc-breadcrumb [breadcrumbs]="breadcrumbs$ | async"></pxc-breadcrumb>
  <div class="pxc-user-details row" *ngIf="user$ | async as user">
    <div class="pxc-user-details__sidebar col-md-5">
      <div class="pxc-user-details__header">
        <img src="{{user.picture192}}" />
        <h3 class="mt-3">{{user.firstname}} {{user.lastname}}</h3>
      </div>
      <div class="pxc-user-details__summary f-m-v-1">
        <div>
          <strong>Company</strong>
          <span>
            <a class="color-primary" routerLink="/companies/{{user.originalCompany.id}}"
              >{{user.originalCompany.name}}</a
            >
          </span>
        </div>
        <div>
          <strong>Created</strong>
          <span>{{user.createdAt | time}}</span>
        </div>
        <div>
          <strong>Last logged</strong>
          <span>{{user.lastLoggedAt | time:'fromNow'}}</span>
        </div>
        <div>
          <strong>Email</strong>
          <span>{{user.email}}</span>
        </div>
        <div>
          <strong>Language</strong>
          <span>{{user.language.name}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <button *pxcHasRight="'log_as_user'" class="btn btn-success mb-2" (click)="logAsUser(user)">Log as user</button>
      <div class="form-inline">
        <div *ngIf="user.hasPxcDirectoryEntry; else noPxcDirectoryEntry">
          <input [(ngModel)]="password" class="form-control" minlength="8" type="password" />
          <button
            (click)="updatePassword(user.id, password)"
            [disabled]="!password || password.length < 8"
            class="btn btn-outline-primary ml-2"
          >
            Update password
          </button>
          <span *pxcHasRight="'log_as_user'">
            <button
              *ngIf="!pxcCredentialsModal"
              (click)="togglePxcCredentialsModal()"
              class="btn btn-outline-primary ml-2"
            >
              Remove PXC credentials
            </button>
          </span>
        </div>
        <ng-template #noPxcDirectoryEntry>
          <span *pxcHasRight="'log_as_user'">
            <a *ngIf="!pxcCredentialsModal" class="c-button c-button--s" (click)="togglePxcCredentialsModal()">
              Create PXC credentials
            </a>
          </span>
        </ng-template>
      </div>
      <div class="form-inline mt-2">
        <input [(ngModel)]="email" class="form-control" type="email" />
        <button (click)="updateEmail(user.id, email)" [disabled]="!email" class="btn btn-outline-primary ml-2">
          Update email
        </button>
      </div>
      <div class="mt-4">
        <pxc-field label="Global Admin">
          <pxc-switch data-cy="user-details-global-admin-label" [(ngModel)]="user.isGlobalAdmin" [onChange]="setGlobalAdmin(user)"> </pxc-switch>
        </pxc-field>
      </div>
      <div class="mt-4">
        <pxc-field label="Block User">
          <pxc-switch [(ngModel)]="user.isBlocked" [onChange]="setBlockedUser(user)"> </pxc-switch>
        </pxc-field>
      </div>
    </div>
  </div>

  <hr class="c-divider" />

  <div class="pxc-user-details row u-margin-top-l" *ngIf="user$ | async as user">
    <div class="col-md-7 pl-0">
      <form ngNativeValidate (ngSubmit)="save(user.id, companies)" class="form-small">
        <h3 class="mt-4">Link user to companies</h3>
        <pxc-field label="Select companies">
          <pxc-companies-selector [(ngModel)]="companies" name="companies"></pxc-companies-selector>
        </pxc-field>
        <button data-cy="user-details-save-button" type="submit" class="btn btn-outline-primary mt-1">
          <i class="fas fa-save"></i> Save
        </button>
      </form>
    </div>
  </div>

  <hr class="c-divider" />

  <div class="pxc-user-details row u-margin-top-l" *ngIf="user$ | async as user">
    <div
      class="u-flex u-flex-row u-flex-justify-between u-flex-align-items-baseline pxc-user-details__linked-companies"
    >
      <h3 class="mt-4">Linked companies</h3>
      <span *pxcHasRight="'log_as_user'">
        <button
          *ngIf="linkedCompanies.length > 0 && getNumberOfUnlinkableCompanies(user.unlinkableCompanies) !== linkedCompanies.length"
          class="c-button c-button--s c-button--danger"
          (click)="toggleUnlinkModal(user.originalCompany.id)"
        >
          Unlink all
        </button>
      </span>
    </div>
    <table class="c-table">
      <thead>
        <th>Ref</th>
        <th>Name</th>
        <th>Country</th>
        <th>Since</th>
        <th>
          <span *pxcHasRight="'log_as_user'">
            <div *ngIf="linkedCompanies.length > 0" class="u-flex u-flex-row u-flex-align-items-baseline">
              <span class="u-margin-right-m">
                User Group
              </span>
              <div class="u-margin-right-m" *ngFor="let userGroup of allUserGroups">
                <input
                  id="{{userGroup.name}}"
                  class="c-checkbox c-checkbox--s"
                  type="checkbox"
                  [checked]="checkMainUserGroups(userGroup)"
                  (change)="onMainUserGroupChecked($event, userGroup)"
                />
                <label class="c-checkbox__skin checkbox-align-margins" for="{{userGroup.name}}"></label>
                <label class="c-label c-label--checkbox c-label--s" for="{{userGroup.name}}">{{userGroup.name}}</label>
              </div>
              <a *ngIf="showApplyToAllButton()" class="c-button c-button--s" (click)="saveAllUserGroups()">
                Apply to all
              </a>
            </div>
          </span>
        </th>
      </thead>
      <tbody>
        <tr *ngFor="let company of linkedCompanies">
          <td>
            <div class="u-flex u-flex-row">
              <div id="unlinkCompany-{{company.id}}">
                <a
                  *pxcHasRight="'log_as_user'"
                  class="c-icon-action u-color-danger u-margin-right-s"
                  (click)="toggleUnlinkModal(null, company.id)"
                  [ngClass]="{
                    'pxc-user-details__unlink-disabled': isCompanyInUnlinkableCompanies(company.id, user.unlinkableCompanies),
                    'u-color-danger': !isCompanyInUnlinkableCompanies(company.id, user.unlinkableCompanies)
                  }"
                >
                  <span class="c-icon c-icon--m">
                    <hl-icon
                      *ngIf="!isCompanyInUnlinkableCompanies(company.id, user.unlinkableCompanies); else unlinkAble"
                      icon="remove-circle--color-24"
                    ></hl-icon>
                    <ng-template #unlinkAble>
                      <hl-icon icon="remove-circle-24"></hl-icon>
                    </ng-template>
                  </span>
                </a>
              </div>
              <hl-tooltip element="#unlinkCompany-{{company.id}}">
                {{getUnlinkText(company.id, user.unlinkableCompanies)}}
              </hl-tooltip>
              <span>{{company.id}}</span>
            </div>
          </td>
          <td>
            <a class="color-primary" routerLink="/companies/{{company.id}}">{{company.name}}</a>
            <i *ngIf="company.isAdmin" class="fas fa-user ml-2"></i>
          </td>
          <td>
            <span class="u-margin-right-2xs">
              <img
                class="mr-1"
                *ngIf="company.countryCode"
                src="assets/img/flag/{{company.countryCode.toLowerCase()}}.png"
              />
            </span>
            {{company.countryCode}}
          </td>
          <td>{{company.createdAt | time:'L'}}</td>
          <td>
            <div *pxcHasRight="'log_as_user'" class="u-flex u-flex-row u-flex-align-items-baseline">
              <div class="u-margin-right-m" *ngFor="let userGroup of allUserGroups">
                <input
                  id="{{userGroup.name}}-{{company.id}}"
                  class="c-checkbox c-checkbox--s"
                  type="checkbox"
                  [checked]="hasUserGroup(userGroup, company.userGroups)"
                  (change)="onUserGroupChecked($event, company.id, userGroup)"
                />
                <label class="c-checkbox__skin checkbox-align-margins" for="{{userGroup.name}}-{{company.id}}"></label>
                <label class="c-label c-label--checkbox c-label--s" for="{{userGroup.name}}-{{company.id}}"
                  >{{userGroup.name}}</label
                >
              </div>
              <a
                *ngIf="company.addedUserGroups.length !== 0 || company.removedUserGroups.length !== 0"
                class="c-button c-button--s"
                (click)="saveUserGroupsForCompany(company.id)"
              >
                Save
              </a>
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</pxc-loading>

<hl-modal
  *ngIf="pxcCredentialsModal"
  class="hl-modal"
  [isOpen]="true"
  [heading]="'PXC credentials'"
  (close)="togglePxcCredentialsModal()"
>
  <div *ngIf="user$ | async as user">
    <div class="pxc-settings__subheading u-margin-bottom-gutter">
      <span *ngIf="user.hasPxcDirectoryEntry; else noPxcDirectoryEntryText">
        {{'Are you sure you want to remove the PXC credentials for this user?'}}
        <br /><br />
        {{'This also impacts the user their ability to login to other locations'}}
      </span>
      <ng-template #noPxcDirectoryEntryText>
        <span>
          {{'Are you sure you want to add PXC credentials for this user and send them a forget password email?'}}
        </span>
      </ng-template>
    </div>
    <footer class="hl-modal__footer u-margin-top-s">
      <div class="c-buttons-group" style="margin-top: -10px;">
        <hl-button
          size="small"
          type="primary"
          (click)="user.hasPxcDirectoryEntry ? removePxcDirectoryEntry() : createPxcDirectoryEntry(user.email)"
          >Yes</hl-button
        >
        <hl-button size="small" type="secondary" (click)="togglePxcCredentialsModal()">No</hl-button>
      </div>
    </footer>
  </div>
</hl-modal>

<hl-modal
  *ngIf="unlinkModal.open"
  class="hl-modal"
  [isOpen]="true"
  [heading]="'Unlink companies'"
  (close)="toggleUnlinkModal()"
>
  <div *ngIf="user$ | async as user">
    <div class="pxc-settings__subheading u-margin-bottom-gutter">
      {{unlinkModal.companyId ? 'Are you sure you want to unlink this user from this location?' : 'Are you sure you want
      to unlink this user from all locations?'}}
    </div>
    <div
      *ngIf="unlinkModal.originalCompanyId && user.unlinkableCompanies.defaultHost.length > 0"
      class="u-margin-bottom-m"
    >
      These locations cannot be unlinked because the user is the default host for them:
      <li *ngFor="let company of user.unlinkableCompanies.defaultHost">
        ({{company}}) {{getCompanyNameById(company)}}
      </li>
    </div>
    <div
      *ngIf="unlinkModal.originalCompanyId && user.unlinkableCompanies.watchListAlert.length > 0"
      class="u-margin-bottom-m"
    >
      These locations cannot be unlinked because the user is still in the watchlist alerts:
      <li *ngFor="let company of user.unlinkableCompanies.watchListAlert">
        ({{company}}) {{getCompanyNameById(company)}}
      </li>
    </div>
    <footer class="hl-modal__footer u-margin-top-s">
      <div class="c-buttons-group" style="margin-top: -10px;">
        <hl-button size="small" type="primary" (click)="unlinkUserFromCompanies(user)">Unlink</hl-button>
        <hl-button size="small" type="secondary" (click)="toggleUnlinkModal()">Close</hl-button>
      </div>
    </footer>
  </div>
</hl-modal>
