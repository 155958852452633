<pxc-loading *pxcHasRight='"tab_provisioning"' [loading]="loading$ | async">
  <pxc-breadcrumb [breadcrumbs]="breadcrumbs$ | async"></pxc-breadcrumb>
  <div *ngIf="application$ | async as application">
    <pxc-field label="Name">
      <code>{{application.name}}</code>
    </pxc-field>
    <pxc-field label="Client ID">
      <code>{{application.clientId}}</code>
    </pxc-field>
    <pxc-field label="Client Secret">
      <code>{{application.clientSecret}}</code>
    </pxc-field>
    <!-- toggleTokenExpiration only available for customer applications -->
    <pxc-field *ngIf="application.id > 100" label="Token Expiration">
      <button class="c-button c-button--primary c-button--s" (click)="toggleTokenExpiration(application.id,!application.tokenValidity)">
        {{ application.tokenValidity ? 'Disable' : 'Enable' }}
      </button>
    </pxc-field>
  </div>
</pxc-loading>
