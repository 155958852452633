<div class="c-card__section">
  <h3>Gallagher settings</h3>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Division</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-obj-dropdown
          [(ngModel)]="selectedDivisionOption"
          [options]="divisionsOptions"
          (ngModelChange)="gallagherDivisionOptionChanged()"
          key="id"
          label="name"
        ></pxc-obj-dropdown>
      </div>
    </div>
  </div>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Card Type (Access Card Manager)</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-obj-dropdown
          [(ngModel)]="setupState.gallagherCardCardType"
          [options]="cardTypes"
          key="id"
          label="name"
          allowNull="true"
        ></pxc-obj-dropdown>
      </div>
    </div>
    <div class="c-helper">When using access card manager, this card type will be used.</div>
  </div>

  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Map custom fields to Gallagher fields</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-switch
          [(ngModel)]="mapCustomFields"
          name="mapCustomFields"
          (ngModelChange)="switchEnableCustomFieldMapping()"
        ></pxc-switch>
      </div>
    </div>
  </div>
  <div class="l-grid" *ngIf="mapCustomFields">
    <div class="l-grid__cell l-grid__cell--6">
      <pxc-obj-dropdown
        [(ngModel)]="customFieldOption"
        [options]="customFieldOptions"
        key="id"
        label="name"
      ></pxc-obj-dropdown>
      <div class="c-helper">Select a Proxyclick custom field</div>
    </div>
    <div class="l-grid__cell l-grid__cell--5">
      <pxc-dropdown
        [(ngModel)]="selectedGallagherCustomFieldOption"
        [options]="gallagherCustomFieldOptions"
      ></pxc-dropdown>
      <div class="c-helper">Select the Gallagher field name you want to map it to.</div>
    </div>
    <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
      <hl-icon icon="add-24" (click)="addMapping()" [ngClass]="{ 'u-color-neutral-light': !canAddMapping() }"></hl-icon>
    </div>

    <div
      *ngIf="setupState.gallagherCustomFieldMappings.length > 0"
      class="l-stack c-box u-padding-all-none u-margin-bottom-s"
    >
      <div class="l-stack__item">
        <div class="c-stacked-list c-stacked-list--s">
          <ul class="c-stacked-list__list">
            <li *ngFor="let customFieldMapping of setupState.gallagherCustomFieldMappings" class="c-stacked-list__item">
              <a
                class="c-stacked-list__inner c-stacked-list__inner--interactive"
                (click)="removeMapping(customFieldMapping.customField.id)"
              >
                <div class="c-stacked-list__inner-start">
                  {{ customFieldMapping.customField.name }} => <code>{{ customFieldMapping.fieldName }}</code>
                </div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>
