import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { StatsService } from '~/services/stats.service';
import { StatsActions } from '~/store/stats/stats.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';

@Injectable()
export class StatsEffects {
  constructor(private actions: Actions<PayloadAction>, private Stats: StatsService) {}

  
  getStats$ = createEffect(() => generateAsyncEffect(this.actions, StatsActions, filter => this.Stats.getAdminStats(filter)));
}
