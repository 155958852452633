<div class="c-card__section">
  <div class="l-form__field">
    <label class="c-label">API Key</label>
    <input
      type="password"
      class="c-input c-input--s"
      required="true"
      name="brivoApiKey"
      [(ngModel)]="state.brivoApiKey"
    />
    <div class="c-helper">
      Retrieve an API Key for the customer in the brivo developer portal
      <a href="https://developer.brivo.com/apps/mykeys">developer portal</a>
    </div>
  </div>
</div>
