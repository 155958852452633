import { Injectable, Injector } from '@angular/core';
import { ILanguage } from '@proxyclick/data-model';
import { Observable } from 'rxjs';
import { BaseService } from '~/services/base.service';

@Injectable()
export class LanguageService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  getLanguages(): Observable<ILanguage[]> {
    return this.apiClient.ApplicationLanguages().doGet();
  }
}
