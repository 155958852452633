import { createSelector } from '@ngrx/store';
import { IAppState } from '~/store/app-state';
import { IExtenderState } from '~/store/extender/extender.state';

export class ExtenderSelector {
  public static get = (state: IAppState) => state.extender;

  public static getExtenderLoading = createSelector(
    ExtenderSelector.get,
    (extenderState: IExtenderState) => extenderState.extenders.current.loading
  );
  public static getExtenderError = createSelector(
    ExtenderSelector.get,
    (extenderState: IExtenderState) => extenderState.extenders.current.error
  );
  public static getExtender = createSelector(
    ExtenderSelector.get,
    (extenderState: IExtenderState) => extenderState.extenders.current.value
  );

  public static getExtendersLoading = createSelector(
    ExtenderSelector.get,
    (extenderState: IExtenderState) => extenderState.extenders.data.loading
  );

  public static getExtendersError = createSelector(
    ExtenderSelector.get,
    (extenderState: IExtenderState) => extenderState.extenders.data.error
  );

  public static getExtenders = createSelector(
    ExtenderSelector.get,
    (extenderState: IExtenderState) => extenderState.extenders.data.value
  );

  public static getExtendersFilter = createSelector(
    ExtenderSelector.get,
    (extenderState: IExtenderState) => extenderState.extenders.filter
  );
}
