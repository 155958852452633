import { tap, map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '~/services/base.service';
import { ObservableCache } from '~/utils/observable-cache';
import { IApplication } from '../../../node_modules/@proxyclick/data-model';

@Injectable()
export class ApplicationsService extends BaseService {
  private cache: ObservableCache = new ObservableCache();

  constructor(injector: Injector) {
    super(injector);
  }

  getApplications() {
    return this.cache.getObservable(
      'applications',
      this.apiClient
        .AdminApplications()
        .doGet()
        .pipe(map(apps => apps.map(app => convert(app))))
    );
  }

  getApplication(applicationId: number) {
    return this.cache.getObservable(
      `app-${applicationId}`,
      this.apiClient.AdminApplication(applicationId).doGet().pipe(map(convert))
    );
  }

  createApplication(data) {
    return this.apiClient
      .AdminApplications()
      .doPost(data)
      .pipe(tap(() => this.cache.invalidate('applications')));
  }

  updateApplication(applicationId: number,enableTokenValidation: boolean) {
    return this.newApiClient
      .AdminApplication(applicationId)
      .doPatch({ enableTokenValidation })
      .pipe(tap(() => this.cache.invalidate(`app-${applicationId}`)));
  }

}

function convert(app): IApplication {
  app.accessTypes = app.accessTypes.split(',');
  return app;
}
