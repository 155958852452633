<div *ngIf="amagSettings">
  <form (submit)="submit()">
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Company</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="selectedCompanyOption"
            [options]="companiesOptions"
            (ngModelChange)="amagCompanyOptionChanged()"
            key="id"
            label="description"
            name="companyOption"
            disabled=""
          ></pxc-obj-dropdown>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Card format (qr code)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="qrCodeCardFormatOption"
            [options]="cardFormatOptions"
            key="id"
            label="name"
            name="qrCodeCardFormatOption"
            disabled=""
          ></pxc-obj-dropdown>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Card format (physical card)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="physicalCardFormatOption"
            [options]="cardFormatOptions"
            key="id"
            label="name"
            name="physicalCardFormatOption"
          ></pxc-obj-dropdown>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Customer code</label>
        </div>
        <div class="l-bar__item-end">
          <input
            type="number"
            class="c-input c-input--s"
            min="1"
            max="20"
            required
            [(ngModel)]="customerCode"
            name="customerCodeOption"
          />
        </div>
      </div>
      <div class="c-helper">Select the customer code set as default inside the ACS</div>
    </div>
    <hr />
    <div class="l-form__field">
      <label class="c-label">Reader IN filter</label>
      <div class="l-grid">
        <div class="l-grid__cell l-grid__cell--11">
          <pxc-obj-dropdown
            [(ngModel)]="selectedReaderOptionIn"
            [options]="readerOptions"
            name="readerOptionIn"
            key="deviceId"
            label="deviceDescription"
          ></pxc-obj-dropdown>
        </div>
        <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
          <hl-icon
            icon="add-24"
            (click)="addInMapping()"
            [ngClass]="{ 'u-color-neutral-light': !canAddInMapping() }"
          ></hl-icon>
        </div>
      </div>
      <div class="c-helper">
        To allow visitor events from AMAG to be sent to our servers please select at least one Reader for IN movements.
      </div>
    </div>
    <div class="l-form__field">
      <label class="c-label">Reader OUT filter</label>
      <div class="l-grid">
        <div class="l-grid__cell l-grid__cell--11">
          <pxc-obj-dropdown
            [(ngModel)]="selectedReaderOptionOut"
            [options]="readerOptions"
            name="readerOptionOut"
            key="deviceId"
            label="deviceDescription"
          ></pxc-obj-dropdown>
        </div>
        <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
          <hl-icon
            icon="add-24"
            (click)="addOutMapping()"
            [ngClass]="{ 'u-color-neutral-light': !canAddOutMapping() }"
          ></hl-icon>
        </div>
      </div>
      <div class="c-helper">
        To allow visitor events from AMAG to be sent to our servers please select at least one reader for OUT movements.
      </div>
    </div>

    <div
      *ngIf="readerFilterIns.length > 0 || readerFilterOuts.length > 0"
      class="l-stack c-box u-padding-all-none u-margin-bottom-s"
    >
      <div class="l-stack__item">
        <div class="c-stacked-list c-stacked-list--s">
          <ul class="c-stacked-list__list">
            <li *ngFor="let reader of readerFilterIns" class="c-stacked-list__item">
              <a
                class="c-stacked-list__inner c-stacked-list__inner--interactive"
                (click)="removeInMapping(reader.deviceId)"
              >
                <div class="c-stacked-list__inner-start">IN: {{ reader.deviceDescription }}</div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
            <li *ngFor="let reader of readerFilterOuts" class="c-stacked-list__item">
              <a
                class="c-stacked-list__inner c-stacked-list__inner--interactive"
                (click)="removeOutMapping(reader.deviceId)"
              >
                <div class="c-stacked-list__inner-start">OUT: {{ reader.deviceDescription }}</div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Map custom fields to AMAG fields</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-switch
            [(ngModel)]="mapCustomFields"
            name="mapCustomFields"
            (ngModelChange)="switchEnableCustomFieldMapping()"
          ></pxc-switch>
        </div>
      </div>
    </div>
    <div class="l-grid" *ngIf="mapCustomFields">
      <div class="l-grid__cell l-grid__cell--6">
        <pxc-obj-dropdown
          [(ngModel)]="customFieldOption"
          [options]="customFieldOptions"
          name="customFieldOptions"
          key="id"
          label="name"
        ></pxc-obj-dropdown>
        <div class="c-helper">Select a Proxyclick custom field</div>
      </div>
      <div class="l-grid__cell l-grid__cell--5">
        <pxc-dropdown
          [(ngModel)]="selectedAMAGCustomFieldOption"
          [options]="amagCustomFieldOptions"
          name="amagCustomFieldOptions"
          key="id"
          label="title"
        ></pxc-dropdown>
        <div class="c-helper">Select the AMAG field name you want to map it to.</div>
      </div>
      <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
        <hl-icon icon="add-24" (click)="addMapping()" [ngClass]="{ 'u-color-neutral-light': !canAddMapping() }">
        </hl-icon>
      </div>

      <div *ngIf="customFieldMappings.length > 0" class="l-stack c-box u-padding-all-none u-margin-bottom-s">
        <div class="l-stack__item">
          <div class="c-stacked-list c-stacked-list--s">
            <ul class="c-stacked-list__list">
              <li *ngFor="let customFieldMapping of customFieldMappings" class="c-stacked-list__item">
                <a
                  class="c-stacked-list__inner c-stacked-list__inner--interactive"
                  (click)="removeMapping(customFieldMapping.customField.id)"
                >
                  <div class="c-stacked-list__inner-start">
                    {{ customFieldMapping.customField.name }} =>
                    <code>{{ customFieldMapping.amagPersonalDataTitle.title }}</code>
                  </div>
                  <div class="c-stacked-list__inner-end">
                    <span class="c-icon c-icon--m">
                      <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
      Save settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner
      ><hl-icon
        *ngIf="submitting === false && errors === undefined"
        icon="checkmark-circle--color-24"
        width="24"
        height="24"
      ></hl-icon>
    </button>
  </form>
  <h3 *ngIf="errors">Errors during update</h3>
  <div class="u-color-danger" *ngIf="errors">
    <h4>{{ errors.title }}</h4>
    <dl>
      <ng-container *ngFor="let item of errors.errors | keyvalue">
        <dt>{{ item.key }}</dt>
        <dt>
          <ul>
            <li *ngFor="let errorItem of item.value">
              {{ errorItem }}
            </li>
          </ul>
        </dt>
      </ng-container>
    </dl>
    Trace id <code>{{ errors.traceId }}</code>
  </div>
</div>
