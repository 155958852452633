import { Component, OnInit, Input } from '@angular/core';
import { ICustomField } from '@proxyclick/data-model';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';
import { FastPaceService } from '~/services/fastpace.service';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import {
  BadgesBadgeTypeResponseV1,
  PanelsPanelResponseV1,
  ReadersReaderResponseV1,
  SegmentsSegmentationSettingsResponseV1,
  SegmentsSegmentResponseV1,
  TypesTypeResponseV1,
  TypesTypeResponseV1TypePropertyEntity,
} from '@proxyclick/extender-server-onguard-client';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { ControllersCompaniesOnGuardCompanySettingsResponseV1 } from '@proxyclick/fastpace-client';

export interface OnGuardReaderOption extends ReadersReaderResponseV1 {
  uniqueId: string;
  displayName: string;
}

@Component({
  selector: 'app-fastpace-company-onguard',
  templateUrl: './fastpace.company.onguard.component.html',
})
export class FastPaceCompanyOnGuardComponent extends FastPaceBaseSystemSettingsComponent implements OnInit {
  constructor(fastpaceService: FastPaceService, companyService: CompaniesService, extenderService: ExtenderService) {
    super(fastpaceService, companyService, extenderService);
  }
  async ngOnInit(): Promise<void> {
    this.onguardSettings = await this.fastpaceService.getOnGuardSettings(this.fastpaceCompany.companyId);

    this.customFields = await this.companyService.getCustomFields(this.fastpaceCompany.companyId).toPromise();

    this.availableTypes = ['Lnl_Visitor', 'Lnl_CardHolder', 'Lnl_Badge'];

    for await (const availableType of this.availableTypes) {
      var type = await this.extenderService.onguard.types.find(this.fastpaceCompany.extenderId, {
        requestTypeName: availableType,
        requestTracingId: uuidv4(),
      });
      this.types.push(type);
    }

    this.mapCustomFields = this.onguardSettings.customFieldMappings.length > 0;
    this.customFieldMappings = this.onguardSettings.customFieldMappings.map(x => {
      const field = this.customFields.filter(cf => cf.id === x.customFieldId)[0];
      const type = this.types.filter(t => t.typeName === x.fieldType)[0];
      const property = type.properties.filter(p => p.name === x.fieldName)[0];

      return { customField: field, fieldType: type, fieldProperty: property };
    });

    this.setCustomFieldOptions();
    this.setTypeOptions();
    this.setPropertyTypeOptions();

    this.panels = await this.extenderService.onguard.panels.list(this.fastpaceCompany.extenderId, {
      requestTracingId: uuidv4(),
    });
    this.readers = await this.extenderService.onguard.readers.list(this.fastpaceCompany.extenderId, {
      requestTracingId: uuidv4(),
    });

    for (let key in this.onguardSettings.panelDeviceFilterIn) {
      var panelId = parseInt(key);
      this.readerFilterIn = this.readerFilterIn.concat(
        this.onguardSettings.panelDeviceFilterIn[key].map(x =>
          _.find(this.readers, r => r.readerId == x && r.panelId == panelId)
        )
      );
    }

    for (let key in this.onguardSettings.panelDeviceFilterOut) {
      var panelId = parseInt(key);
      this.readerFilterOut = this.readerFilterOut.concat(
        this.onguardSettings.panelDeviceFilterOut[key].map(x =>
          _.find(this.readers, r => r.readerId == x && r.panelId == panelId)
        )
      );
    }

    this.setReaderOptions();

    this.segmentationSettings = await this.extenderService.onguard.segments.getSettings(
      this.fastpaceCompany.extenderId,
      { requestTracingId: uuidv4() }
    );
    if (this.segmentationSettings.segmentationEnabled) {
      var segments = await this.extenderService.onguard.segments.listSegments(this.fastpaceCompany.extenderId, {
        requestTracingId: uuidv4(),
      });

      if (this.onguardSettings.cardholderSegmentId != null) {
        this.cardholderSegment = segments.filter(x => x.id === this.onguardSettings.cardholderSegmentId)[0];
      }

      if (this.onguardSettings.accessLevelSegmentId != null) {
        this.accessLevelSegment = segments.filter(x => x.id === this.onguardSettings.accessLevelSegmentId)[0];
      }
    } else {
      //segments are disable for this onguard instance
    }

    this.badgeTypes = await this.extenderService.onguard.badges.listTypes(this.fastpaceCompany.extenderId, {
      requestTracingId: uuidv4(),
    });

    this.cardBadgeTypeOption = this.onguardSettings.cardBadgeTypeId
      ? this.badgeTypes.find(b => b.id === this.onguardSettings.cardBadgeTypeId)
      : null;

    this.qrCodeBagdeTypeOption = this.onguardSettings.qrCodeBadgeTypeId
      ? this.badgeTypes.find(b => b.id === this.onguardSettings.qrCodeBadgeTypeId)
      : null;

    this.loading = false;
  }

  loading: boolean = true;

  segmentationSettings: SegmentsSegmentationSettingsResponseV1 | null = null;
  //current settings
  onguardSettings: ControllersCompaniesOnGuardCompanySettingsResponseV1 | null = null;
  //new settings
  customFieldMappings: Array<{
    customField: ICustomField;
    fieldType: TypesTypeResponseV1;
    fieldProperty: TypesTypeResponseV1TypePropertyEntity;
  }> = [];
  readerFilterIn: ReadersReaderResponseV1[] = [];
  readerFilterOut: ReadersReaderResponseV1[] = [];
  cardholderSegment: SegmentsSegmentResponseV1 | null = null;
  accessLevelSegment: SegmentsSegmentResponseV1 | null = null;

  panels: PanelsPanelResponseV1[] = [];
  readers: ReadersReaderResponseV1[] = [];
  readerOptions: OnGuardReaderOption[] = [];
  readerOptionIn: OnGuardReaderOption | null = null;
  readerOptionOut: OnGuardReaderOption | null = null;

  badgeTypes: BadgesBadgeTypeResponseV1[] = [];
  qrCodeBagdeTypeOption: BadgesBadgeTypeResponseV1 | null = null;
  cardBadgeTypeOption: BadgesBadgeTypeResponseV1 | null = null;

  //custom field mappings
  mapCustomFields: boolean = false;

  availableTypes: string[];
  types: TypesTypeResponseV1[] = [];
  typeOptions: TypesTypeResponseV1[] = [];
  typeOption: TypesTypeResponseV1 | null = null;

  typePropertyOptions: { [type: string]: TypesTypeResponseV1TypePropertyEntity[] } = {};
  propertyOption: TypesTypeResponseV1TypePropertyEntity | null = null;

  customFields: ICustomField[];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.customFieldMappings = [];
    }
  }

  setReaderOptions() {
    this.readerOptions = this.readers
      .filter(
        r =>
          this.readerFilterIn.filter(f => f.readerId === r.readerId && f.panelId == r.panelId).length === 0 &&
          this.readerFilterOut.filter(f => f.readerId === r.readerId && f.panelId == r.panelId).length === 0
      )
      .map(r => {
        var panel = this.panels.filter(p => p.id === r.panelId)[0];
        var onGuardReaderOption: OnGuardReaderOption = {
          uniqueId: `${panel.id}_${r.readerId}`,
          displayName: `${panel.name} - ${r.name}`,
          ...r,
        };
        return onGuardReaderOption;
      });

    if (this.readerOptions.length > 0) {
      this.readerOptionIn = this.readerOptions[0];
      this.readerOptionOut = this.readerOptions[0];
    } else {
      this.readerOptionIn = null;
      this.readerOptionOut = null;
    }
  }

  setTypeOptions() {
    this.typeOptions = this.types;
    this.typeOption = this.typeOptions[0];
  }

  setPropertyTypeOptions() {
    this.typePropertyOptions = {};
    this.types.forEach(type => {
      const options = type.properties.filter(
        p =>
          this.customFieldMappings.filter(
            m => m.fieldType.typeName === type.typeName && m.fieldProperty.name === p.name
          ).length === 0
      );
      this.typePropertyOptions[type.typeName] = options;
    });
    this.fieldTypeOptionChanged();
  }

  fieldTypeOptionChanged() {
    if (this.typeOption) {
      var typePropertyOption = this.typePropertyOptions[this.typeOption.typeName];
      if (typePropertyOption.length > 0) {
        this.propertyOption = typePropertyOption[0];
      } else {
        this.propertyOption = null;
      }
    }
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return this.customFieldMappings.filter(a => a.customField.id === c.id).length === 0;
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = null;
    }
  }

  canAddMapping() {
    return (
      this.customFieldOption &&
      this.typeOption &&
      this.propertyOption &&
      this.customFieldMappings.filter(
        c =>
          (c.fieldProperty.name === this.propertyOption && c.fieldType.typeName === this.typeOption.typeName) ||
          c.customField.id === this.customFieldOption.id
      ).length === 0
    );
  }

  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }
    this.customFieldMappings.push({
      customField: this.customFieldOption,
      fieldType: this.typeOption,
      fieldProperty: this.propertyOption,
    });
    this.setCustomFieldOptions();
    this.setPropertyTypeOptions();
  }

  removeMapping(id: number) {
    this.customFieldMappings = this.customFieldMappings.filter(c => c.customField.id !== id);
    this.setCustomFieldOptions();
    this.setPropertyTypeOptions();
  }

  canAddPanelDeviceIn(): boolean {
    return (
      this.readerOptionIn &&
      this.readerFilterIn.filter(
        f => f.readerId === this.readerOptionIn.readerId && f.panelId == this.readerOptionIn.panelId
      ).length === 0 &&
      this.readerFilterOut.filter(
        f => f.readerId === this.readerOptionIn.readerId && f.panelId == this.readerOptionIn.panelId
      ).length === 0
    );
  }

  addPanelDeviceIn() {
    if (this.canAddPanelDeviceIn()) {
      this.readerFilterIn.push(this.readerOptionIn);
      this.setReaderOptions();
    }
  }

  removePanelDeviceIn(reader: ReadersReaderResponseV1) {
    this.readerFilterIn = this.readerFilterIn.filter(
      x => x.readerId !== reader.readerId || x.panelId !== reader.panelId
    );
    this.setReaderOptions();
  }

  canAddPanelDeviceOut(): boolean {
    return (
      this.readerOptionOut &&
      this.readerFilterIn.filter(
        f => f.readerId === this.readerOptionOut.readerId && f.panelId == this.readerOptionOut.panelId
      ).length === 0 &&
      this.readerFilterOut.filter(
        f => f.readerId === this.readerOptionOut.readerId && f.panelId == this.readerOptionOut.panelId
      ).length === 0
    );
  }

  addPanelDeviceOut() {
    if (this.canAddPanelDeviceOut()) {
      this.readerFilterOut.push(this.readerOptionOut);
      this.setReaderOptions();
    }
  }

  removePanelDeviceOut(reader: ReadersReaderResponseV1) {
    this.readerFilterOut = this.readerFilterOut.filter(
      x => x.readerId !== reader.readerId || x.panelId !== reader.panelId
    );
    this.setReaderOptions();
  }

  get canSubmit(): boolean {
    return this.submitting !== true;
  }

  async doUpdate(): Promise<void> {
    const panelDeviceFilterIn: { [key: number]: number[] } = {};
    var readerFilterInByPanelId = _.groupBy(this.readerFilterIn, x => x.panelId);
    for (let key in readerFilterInByPanelId) {
      panelDeviceFilterIn[key] = readerFilterInByPanelId[key].map(x => x.readerId);
    }

    const panelDeviceFilterOut: { [key: number]: number[] } = {};
    var readerFilterOutByPanelId = _.groupBy(this.readerFilterOut, x => x.panelId);
    for (let key in readerFilterOutByPanelId) {
      panelDeviceFilterOut[key] = readerFilterOutByPanelId[key].map(x => x.readerId);
    }

    return this.fastpaceService.updateOnGuardSettings(this.fastpaceCompany.companyId, {
      customFieldMappings: this.customFieldMappings.map(x => {
        return { customFieldId: x.customField.id, fieldType: x.fieldType.typeName, fieldName: x.fieldProperty.name };
      }),
      panelDeviceFilterIn: panelDeviceFilterIn,
      panelDeviceFilterOut: panelDeviceFilterOut,
      cardBadgeTypeId: this.cardBadgeTypeOption ? this.cardBadgeTypeOption.id : null,
    });
  }
}
