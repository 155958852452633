import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  constructor() {}

  transform(time, format = 'LL') {
    if (!time) {
      return 'Never';
    }
    if (format === 'fromNow') {
      return moment(time).fromNow();
    }
    return moment(time).format(format);
  }
}
