import { Component, Input } from '@angular/core';

@Component({
  selector: 'pxc-caret',
  templateUrl: './caret.html',
})
export class CaretComponent {
  @Input() sortBy: string;
  @Input() sort: 'asc' | 'desc';
  @Input() value: string;
}
