import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RightService } from '~/services/rights.service';

@Injectable()
export class RightGuard implements CanActivate {
  constructor(private router: Router, private rights: RightService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!next.data || !next.data.right) {
      console.warn('RightGuard used without right');
      return false;
    }

    return this.rights.hasRight(next.data.right);
  }
}
