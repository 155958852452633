import { ICompany, ICustomField } from '@proxyclick/data-model';
import { ConnectionsConnectionSettings, DevicesDeviceResponseV1 } from '@proxyclick/extender-server-ac2000-client';
import {
  FreeFieldsFreeFieldConfigurationResultV1,
  IdentifierTypesIdentifierTypeResultV1,
} from '@proxyclick/extender-server-aeos-client';
import { CompaniesCompanyV1, PersonalDataTitlesPersonalDataTitleV1 } from '@proxyclick/extender-server-amag-client';
import { CredentialFormatEntity, CustomFieldEntity } from '@proxyclick/extender-server-brivo-client';
import {
  ChuidFormatsChuidFormatV1,
  PartitionsPartitionV1,
  PersonnelGroupsGroupResponseV1,
} from '@proxyclick/extender-server-ccure-client';
import { CardTypesCardTypeV1, DivisionsDivisionV1 } from '@proxyclick/extender-server-gallagher-client';
import {
  LocationsLocationData,
  LocationsLocationSettingsResponseData,
  TenantsTenantEntity,
} from '@proxyclick/extender-server-genea-client';
import {
  CredentialFormatsCredentialFormatResponseV1,
  PartitionsPartitionResponseV1,
} from '@proxyclick/extender-server-genetec-client';
import {
  BadgesBadgeTypeResponseV1,
  SegmentsSegmentResponseV1,
  TypesTypeResponseV1,
  TypesTypeResponseV1TypePropertyEntity,
} from '@proxyclick/extender-server-onguard-client';
import {
  CardFormatsCardFormatEntity,
  ClientLoginTokenScopeOrganization,
} from '@proxyclick/extender-server-openpath-client';
import { ControllersCompaniesFastPaceCompanyResponseV1 } from '@proxyclick/fastpace-client';
import { BehaviorSubject } from 'rxjs';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { IExtenderResponseV1 } from '~/store/extender/extender.models';
import {
  copySettingsPage,
  customFieldAccessLevelPage,
  extenderPage,
  extenderPages,
  reviewPage,
  submitPage,
  tokenGroupPage,
  tokenRangePage,
  tokenSetupPage,
  WizardPage,
} from './wizard-pages';

export class FastPaceSetupCompanyState {
  company: ICompany;
  extender: IExtenderResponseV1 | null;

  useExtender: boolean = true;
  generateTokens: boolean = true;

  selectedTokenGroup: IOption | undefined = undefined;
  tokenGroupName: string | undefined = undefined;

  tokenRangeMinimum: number = 10000000;
  tokenRangeMaximum: number = 99999999;

  accessLevelsCustomField: ICustomField | undefined = undefined;

  copySettingsFromRandomExtenderCompany: boolean = false;
  copySettingsFromCompany: ControllersCompaniesFastPaceCompanyResponseV1 | undefined = undefined;

  aeosUseEmployeeType: boolean = false;
  aeoshostEmailAsContact: boolean = false;
  aeosCustomFieldFreeFieldMappings: Array<{
    customField: ICustomField;
    freeField: FreeFieldsFreeFieldConfigurationResultV1;
  }> = [];
  aeosCardIdentifierType: IdentifierTypesIdentifierTypeResultV1 | null = null;

  ac2000Connection: ConnectionsConnectionSettings | undefined = undefined;
  ac2000DeviceFilterIn: DevicesDeviceResponseV1[] = [];
  ac2000DeviceFilterOut: DevicesDeviceResponseV1[] = [];

  ccurePartition: PartitionsPartitionV1 | undefined = undefined;
  ccureCustomFieldMappings: Array<{ customField: ICustomField; fieldName: string }> = [];
  ccureUsePersonnelGroup: boolean = false;
  ccurePersonnelGroup: PersonnelGroupsGroupResponseV1 | undefined = undefined;
  ccureCardCHUIDFormat: ChuidFormatsChuidFormatV1 | null = null;
  ccureFacilityCode: number | undefined = undefined;

  genetecPartition: PartitionsPartitionResponseV1 | undefined = undefined;

  netboxPartitionKey: number | null | undefined = undefined;
  netboxVisitorIdField: number | undefined = undefined;
  netboxVisitorStatusField: number | undefined = undefined;
  netboxManageAccessLevels: boolean = true;
  netboxUseMultiPartitionAccessLevelGroups: boolean = false;
  netboxCardFormatOption: IOption | null = null;

  onGuardCardHolderSegment: SegmentsSegmentResponseV1 | null | undefined = undefined;
  onGuardAccessLevelSegement: SegmentsSegmentResponseV1 | null | undefined = undefined;
  onGuardCustomFieldMappings: Array<{
    customField: ICustomField;
    fieldType: TypesTypeResponseV1;
    fieldProperty: TypesTypeResponseV1TypePropertyEntity;
  }> = [];
  onGuardCardBadgeType: BadgesBadgeTypeResponseV1 | null = null;

  brivoQrCodeCredentialFormat: CredentialFormatEntity | null = null;
  brivoCardCredentialFormat: CredentialFormatEntity | null = null;
  brivoFacilityCode: number | null = null;
  brivoCustomFieldMappings: Array<{ customField: ICustomField; brivoCustomField: CustomFieldEntity }> = [];

  openPathOrganization: ClientLoginTokenScopeOrganization;
  openPathQrCodeCardFormat: CardFormatsCardFormatEntity | null = null;
  openPathCardCardFormat: CardFormatsCardFormatEntity | null = null;
  openPathFacilityCode: number | null = null;

  geneaLocation: LocationsLocationData;
  geneaLocationSettings: LocationsLocationSettingsResponseData;
  geneaTenant: TenantsTenantEntity;

  genetecCardCredentialFormat: CredentialFormatsCredentialFormatResponseV1 | null = null;
  genetecFacilityCode: number | null = null;

  gallagherDivision: DivisionsDivisionV1 | undefined = undefined;
  gallagherCustomFieldMappings: Array<{ customField: ICustomField; fieldName: string }> = [];
  gallagherCardCardType: CardTypesCardTypeV1 | null = null;

  amagCompany: CompaniesCompanyV1 | undefined = undefined;
  amagCustomFieldMappings: Array<{
    customField: ICustomField;
    amagPersonalDataTitle: PersonalDataTitlesPersonalDataTitleV1;
  }> = [];
  amagQrCodeCardFormatId: number | undefined = undefined;
  amagQrCodeCardFormatName: string | undefined = undefined;
  amagPhysicalCardFormatId: number | undefined = undefined;
  amagPhysicalCardFormatName: string | undefined = undefined;
  amagCustomerCode: number | undefined = undefined;

  page$: BehaviorSubject<string> = new BehaviorSubject<string>(extenderPage.key);

  get currentPage(): number {
    return this.wizardPages.findIndex(v => v.key === this.page$.value) + 1;
  }

  get wizardPages(): WizardPage[] {
    const pages = [extenderPage, tokenSetupPage];
    if (this.generateTokens) {
      pages.push(tokenGroupPage);
      pages.push(tokenRangePage);
    }
    pages.push(customFieldAccessLevelPage);
    pages.push(copySettingsPage);
    if (this.extender) {
      const page = extenderPages.filter(ep => ep.key === this.extender.type);
      if (page.length > 0) {
        pages.push(page[0]);
      }
    }
    pages.push(reviewPage);
    pages.push(submitPage);
    return pages;
  }

  get totalPages() {
    return this.wizardPages.length;
  }
  get backPage(): WizardPage | null {
    const pages = this.wizardPages;
    const currentIndex = pages.findIndex(v => v.key === this.page$.value);
    if (currentIndex === 0) {
      return null;
    } else {
      return pages[currentIndex - 1];
    }
  }

  get nextPage(): WizardPage | null {
    const pages = this.wizardPages;
    const currentIndex = pages.findIndex(v => v.key === this.page$.value);
    if (currentIndex === pages.length) {
      return null;
    } else {
      return pages[currentIndex + 1];
    }
  }
  get nextTitle(): string {
    const value = this.nextPage;
    if (value === null) {
      return 'Unknown page, error occured';
    }
    return value.label;
  }

  get backTitle(): string {
    const value = this.backPage;
    if (value === null) {
      return 'Unknown page, error occured';
    }
    return value.label;
  }

  setPage(page: string) {
    this.page$.next(page);
  }
}
