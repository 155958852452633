import { combineReducers } from '@ngrx/store';
import { createAsyncLoadReducer } from '~/utils/async-generator';
import {
  UserActions,
  UserCompanyCloneActions,
  UserDifferenceBetweenCompaniesActions,
  UserEmailActions,
  UserLinkCompanyActions,
  UserLinkMultipleCompaniesActions,
  UserPasswordActions,
  UsersActions,
} from './users.actions';

export const usersReducer = createAsyncLoadReducer(UsersActions);

export const userReducer = combineReducers({
  user: createAsyncLoadReducer(UserActions),
  password: createAsyncLoadReducer(UserPasswordActions),
  email: createAsyncLoadReducer(UserEmailActions),
  linkCompany: createAsyncLoadReducer(UserLinkCompanyActions),
  linkCompanies: createAsyncLoadReducer(UserLinkMultipleCompaniesActions),
});

export const userDifferenceReducer = createAsyncLoadReducer(UserDifferenceBetweenCompaniesActions);
export const userCompanyCloneReducer = createAsyncLoadReducer(UserCompanyCloneActions);
