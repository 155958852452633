<div *pxcHasRight='"tab_domains"'>
  <button class="c-button c-button--s" (click)="addDomainModalVisible = true">Add domain</button>
  <table class="c-table mt-4 mb-2" *ngIf="(loadingDomains$ | async) === false; else spinner">
    <thead>
      <th>Domain</th>
      <th>SCIM</th>
      <th>SAML</th>
      <th>Verified</th>
      <th>Created On</th>
      <th></th>
    </thead>
    <tbody *ngIf="domains$ | async as domains">
      <tr *ngFor="let domain of domains">
        <td>{{domain.domain}}</td>
        <td>
          <pxc-switch [(ngModel)]="domain.scimEnabled" [onChange]="update(domain)"></pxc-switch>
        </td>
        <td>
          <pxc-switch [(ngModel)]="domain.samlEnabled" [onChange]="update(domain)"></pxc-switch>
        </td>
        <td>
          <pxc-switch [(ngModel)]="domain.verified" [onChange]="update(domain)"></pxc-switch>
        </td>
        <td>{{domain.createdAt | date: 'medium':'UTC'}}</td>
        <td>
          <button class="c-button c-button--s c-button--link" (click)="deleteDomain(domain)">
            <hl-icon class="c-button__icon" icon="close-24"></hl-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <ng-template #spinner>
    <pxc-loading [loading]="true"></pxc-loading>
  </ng-template>
</div>

<hl-modal
  *ngIf="addDomainModalVisible"
  class="hl-modal"
  [isOpen]="true"
  heading="Add domain"
  (close)="addDomainModalVisible = false"
>
  <label class="c-label c-label--s" for="new-org-domains"
    >List of new domains to add for this organisation (1 per line):</label
  >
  <textarea
    class="c-textarea u-text-xs"
    id="new-org-domains"
    [(ngModel)]="newDomains"
    style="height: 300px"
    [disabled]="isDomainsSaving"
  ></textarea>
  <div class="u-flex u-flex-align-items-center u-margin-top-s" *ngIf="isDomainsSaving">
    <hl-spinner></hl-spinner>
    <span class="u-margin-left-s">Saving ...</span>
  </div>
  <footer class="u-margin-top-s">
    <div class="c-buttons-group">
      <hl-button size="small" type="primary" (click)="addDomain()" [disabled]="isDomainsSaving">Add</hl-button>
      <hl-button size="small" type="secondary" (click)="addDomainModalVisible = false" [disabled]="isDomainsSaving"
        >Close</hl-button
      >
    </div>
  </footer>
</hl-modal>
