import { Component } from '@angular/core';
import { IOption } from '~/shared/components/input/radio/radio.component';

@Component({
  templateUrl: './user-provisioning.html',
})
export class UserProvisioningPageComponent {
  tabs: IOption[] = [
    { label: 'LDAPs', value: 'ldap' },
    { label: 'CSV Upload', value: 'json' },
    { label: 'Applications', value: 'applications' },
    { label: 'Clone users', value: 'clone-users' },
  ];
}
