import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';
import { FastPaceService } from '~/services/fastpace.service';
import { CompaniesService } from '~/services/companies.service';
import { DevicesDeviceResponseV1 } from '@proxyclick/extender-server-ac2000-client';
import { ExtenderService } from '~/services/extender.service';
import { v4 as uuidv4 } from 'uuid';
import { fetchAll } from '~/utils/utils';
import { ControllersCompaniesAC2000CompanySettingsResponseV1 } from '@proxyclick/fastpace-client';

@Component({
  selector: 'app-fastpace-company-ac2000',
  templateUrl: './fastpace.company.ac2000.component.html',
})
export class FastPaceCompanyAC2000Component extends FastPaceBaseSystemSettingsComponent implements OnInit {
  constructor(fastpaceService: FastPaceService, companyService: CompaniesService, extenderService: ExtenderService) {
    super(fastpaceService, companyService, extenderService);
  }
  async ngOnInit(): Promise<void> {
    this.ac2000Settings = await this.fastpaceService.getAC2000Settings(this.fastpaceCompany.companyId);
    this.devices = await fetchAll<DevicesDeviceResponseV1>(
      async page =>
        (
          await this.extenderService.ac2000.devices.list(this.fastpaceCompany.extenderId, {
            requestTracingId: uuidv4(),
            requestConnectionId: this.ac2000Settings.connectionId,
            requestPage: page,
            requestPageSize: 100,
          })
        ).items
    );
    this.deviceFilterIn = this.ac2000Settings.deviceAddressFilterIn.map(
      x => this.devices.filter(d => d.deviceAddress === x)[0]
    );
    this.deviceFilterOut = this.ac2000Settings.deviceAddressFilterOut.map(
      x => this.devices.filter(d => d.deviceAddress === x)[0]
    );
    this.setDeviceOptions();
  }

  //current settings
  ac2000Settings: ControllersCompaniesAC2000CompanySettingsResponseV1 | null = null;
  //new settings
  deviceFilterIn: DevicesDeviceResponseV1[] = [];
  deviceFilterOut: DevicesDeviceResponseV1[] = [];

  devices: DevicesDeviceResponseV1[];
  deviceOptions: DevicesDeviceResponseV1[] = [];
  deviceOptionIn: DevicesDeviceResponseV1 | null = null;
  deviceOptionOut: DevicesDeviceResponseV1 | null = null;

  setDeviceOptions() {
    const options = this.devices.filter(c => {
      //Filter out custom fields already mapped
      return (
        this.deviceFilterIn.filter(a => a.deviceAddress === c.deviceAddress).length === 0 &&
        this.deviceFilterOut.filter(a => a.deviceAddress === c.deviceAddress).length === 0
      );
    });

    this.deviceOptions = options;
    if (this.deviceOptions.length > 0) {
      this.deviceOptionIn = this.deviceOptions[0];
      this.deviceOptionOut = this.deviceOptions[0];
    } else {
      this.deviceOptionIn = null;
      this.deviceOptionOut = null;
    }
  }

  canAddInMapping() {
    return (
      this.deviceOptionIn &&
      this.deviceFilterIn.filter(d => d.deviceAddress === this.deviceOptionIn.deviceAddress).length < 1
    );
  }
  canAddOutMapping() {
    return (
      this.deviceOptionOut &&
      this.deviceFilterOut.filter(d => d.deviceAddress === this.deviceOptionOut.deviceAddress).length < 1
    );
  }
  addInMapping() {
    if (this.canAddInMapping()) {
      this.deviceFilterIn.push(this.deviceOptionIn);
      this.setDeviceOptions();
    }
  }

  addOutMapping() {
    if (this.canAddOutMapping()) {
      this.deviceFilterOut.push(this.deviceOptionOut);
      this.setDeviceOptions();
    }
  }

  removeInMapping(deviceAddress: string) {
    this.deviceFilterIn = this.deviceFilterIn.filter(d => d.deviceAddress !== deviceAddress);
    this.setDeviceOptions();
  }
  removeOutMapping(deviceAddress: string) {
    this.deviceFilterOut = this.deviceFilterOut.filter(d => d.deviceAddress !== deviceAddress);
    this.setDeviceOptions();
  }

  get canSubmit(): boolean {
    return this.submitting !== true;
  }

  async doUpdate(): Promise<void> {
    await this.fastpaceService.updateAC2000Settings(this.fastpaceCompany.companyId, {
      deviceAddressFilterIn: this.deviceFilterIn.map(x => x.deviceAddress),
      deviceAddressFilterOut: this.deviceFilterOut.map(x => x.deviceAddress),
    });
  }
}
