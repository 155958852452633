import { combineReducers, createReducer, on } from '@ngrx/store';
import { IQueryablePaginationFilter } from '~/models/filters';
import { IExtenderResponseV1, IExtenderPaginatedResponse } from '~/store/extender/extender.models';
import { createAsyncLoadActions } from '~/utils/async-generator';

const extendersBase = 'extenderServer/extenders';
const extenderBase = 'extenderServer/extender';

export const ExtenderActions = {
  Extenders: createAsyncLoadActions<IQueryablePaginationFilter, IExtenderPaginatedResponse<IExtenderResponseV1>>(
    extendersBase
  ),
  Extender: createAsyncLoadActions<string, IExtenderResponseV1>(extenderBase),
};

export const ExtenderReducer = createReducer(
  {},
  on(ExtenderActions.Extenders.Load, (state, action) => ({
    ...state,
    data: { loading: true, error: null },
  })),
  on(ExtenderActions.Extenders.Loaded, (state, action) => ({
    ...state,
    data: { loading: false, error: null, value: action.payload },
  })),
  on(ExtenderActions.Extenders.Error, (state, action) => ({
    ...state,
    data: { loading: false, error: action.payload as Error },
  })),
  on(ExtenderActions.Extender.Load, (state, action) => ({
    ...state,
    current: { loading: true, error: null },
  })),
  on(ExtenderActions.Extender.Loaded, (state, action) => ({
    ...state,
    current: { loading: false, error: null, value: action.payload },
  })),
  on(ExtenderActions.Extender.Error, (state, action) => ({
    ...state,
    current: { loading: false, error: action.payload as Error },
  }))
);

export const extenderReducer = combineReducers({
  extenders: ExtenderReducer,
});
