import { Component, OnInit } from '@angular/core';
import { AuthService } from '~/services/auth.service';
import { RightService } from '~/services/rights.service';

@Component({
  selector: 'pxc-navbar',
  templateUrl: './navbar.html',
  styleUrls: ['./navbar.scss'],
})
export class NavbarComponent implements OnInit {
  showSearch = false;

  constructor(private Auth: AuthService, private Right: RightService) {}

  ngOnInit() {
    this.showSearch = this.Right.hasRight('tab_users') || this.Right.hasRight('tab_companies');
  }

  logout() {
    this.Auth.logout();
  }
}
