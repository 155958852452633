import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAdminStatsFilter } from '~/services/stats.service';
import { IAppState } from '~/store/app-state';
import { CompanyStats } from '~/store/companies/companies.actions';

@Component({
  selector: 'pxc-company-stats',
  templateUrl: './company-stats.html',
})
export class CompanyStatsComponent implements OnInit {
  stats$ = this.store.select('company', 'stats', 'value');
  loading$ = this.store.select('company', 'stats', 'loading');

  filter: IAdminStatsFilter;

  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.store.select('company', 'company', 'value').subscribe(company => {
      this.filter = {
        companyId: company.id,
        sortBy: 'period',
        sort: 'desc',
      };
    });
  }

  applySort(sort: string) {
    if (this.filter.sortBy === sort) {
      this.filter.sort = this.filter.sort === 'asc' ? 'desc' : 'asc';
    } else {
      this.filter.sortBy = sort;
      this.filter.sort = 'desc';
    }
    this.getStats();
  }

  getStats() {
    this.store.dispatch(CompanyStats.Load(this.filter));
  }
}
