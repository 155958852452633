import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface IOption {
  label: string;
  value: any;
}

@Component({
  selector: 'pxc-radio',
  templateUrl: './radio.html',
  styleUrls: ['./radio.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent implements ControlValueAccessor {
  @Input()
  options: IOption[];

  @Input()
  style = 'block';

  value: string;

  @Input()
  name: string;

  private onChange: (string) => void;

  setValue(option: IOption) {
    this.value = option.value;
    this.onChange(this.value);
  }

  isChecked(option: IOption) {
    return this.value === option.value;
  }

  writeValue(obj: string): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
}
