import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IUser } from '@proxyclick/data-model';
import { RightService } from 'app/services/rights.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { IUsersFilter } from '~/models/filters';
import { UserService } from '~/services/users.service';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { IAppState } from '~/store/app-state';
import { UsersActions } from '~/store/users/users.actions';

@Component({
  selector: 'pxc-users',
  templateUrl: './users.html',
})
export class UsersPageComponent implements OnInit {
  users$: Observable<IUser[]>;
  loading$: Observable<boolean>;
  recentUsers$: Observable<IUser[]>;

  searchTerm = '';
  emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  columns: IOption[] = [
    { label: 'Ref', value: null },
    { label: '', value: null },
    { label: 'User', value: 'name' },
    { label: 'Login', value: null },
    { label: 'Company', value: null },
    { label: 'Created', value: 'new' },
    { label: 'Last log', value: 'lastlog' },
  ];
  filter: IUsersFilter & { email: string } = {
    q: null,
    pageSize: 200,
    sort: 'desc',
    sortBy: 'lastlog',
    email: null,
  };

  constructor(private store: Store<IAppState>, private Users: UserService, private Rights: RightService) {}

  ngOnInit() {
    this.users$ = this.store.select('users', 'value');
    this.loading$ = this.store.select('users', 'loading');

    if (!this.Rights.hasRight('log_as_user')) {
      _.remove(this.columns, c => c.label === 'Login');
    }
  }

  loadUsers() {
    this.emailRegex.test(this.searchTerm) ? (this.filter.email = this.searchTerm) : (this.filter.q = this.searchTerm);
    this.store.dispatch(UsersActions.Load(this.filter));
  }

  loadRecentUsers() {
    this.filter.sortBy = 'lastlog';
    this.filter.sort = 'desc';
    this.loadUsers();
  }

  async logAsUser(user: IUser) {
    this.Users.logAsUser(user);
  }

  onFilterChange(filter) {
    this.filter = filter;
    this.loadUsers();
  }
}
