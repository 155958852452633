<form #form="ngForm" class="form-inline">
  <div class="form-group">
    <div class="input-group">
      <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        [(ngModel)]="date"
        (ngModelChange)="onInternalChange()"
        [ngModelOptions]="{standalone: true}"
        (click)="d.toggle()"
        ngbDatepicker
        #d="ngbDatepicker"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
          <i class="far fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="addDaysButton">
    <input class="form-control input-xs ml-2" [(ngModel)]="daysToAdd" name="daysToAdd" type="number" />
    <hl-button class="u-margin-left-xs" [size]="'small'" (click)="addDays(daysToAdd)">Add days </hl-button>
  </div>
</form>
