<div *ngIf="netboxSettings">
  <form (submit)="submit()">
    <div class="l-form__field">
      <label class="c-label">Partition Key</label>
      <input
        type="text"
        class="c-input c-input--s"
        [(ngModel)]="netboxSettings.partitionKey"
        name="partitionKey"
        disabled=""
      />
    </div>
    <hr />
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Card Format (Access Card Manager)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="cardFormatOption"
            [options]="cardFormatOptions"
            key="value"
            label="label"
            name="cardFormatOption"
            allowNull="true"
          ></pxc-obj-dropdown>
        </div>
      </div>
      <div class="c-helper">When using access card manager, this card format will be used</div>
    </div>
    <hr />
    <div class="l-grid">
      <div class="l-grid__cell l-grid__cell--6">
        <div class="l-form__field">
          <label class="c-label">Visitor ID Field</label>
          <input type="text" class="c-input c-input--s" [(ngModel)]="visitorIdField" name="visitorIdField" />
        </div>
      </div>
      <div class="l-grid__cell l-grid__cell--6">
        <div class="l-form__field">
          <label class="c-label">Visitor Status Field</label>
          <input type="text" class="c-input c-input--s" [(ngModel)]="visitorStatusField" name="visitorStatusField" />
        </div>
      </div>
    </div>
    <div class="l-grid">
      <div class="l-grid__cell l-grid__cell--6">
        <div class="l-form__field">
          <div class="l-bar u-margin-bottom-xs">
            <div class="l-bar__item-startm">
              <div class="c-list c-list--bare u-text-m">
                <input
                  id="manageAccessLevels"
                  class="c-checkbox c-checkbox--m"
                  type="checkbox"
                  [(ngModel)]="manageAccessLevels"
                  name="manageAccessLevels"
                  (ngModelChange)="switchManageAccessLevels()"
                />
                <label class="c-checkbox__skin" for="manageAccessLevels"></label>
                &nbsp;<label class="c-label c-label--checkbox c-label--m" for="manageAccessLevels"
                  >Manage Access Levels</label
                >
              </div>
            </div>
          </div>
          <div class="c-helper">
            Proxyclick can be configured to skip creation of access levels for Netbox. As doing this requires full
            system privileges on the master partition, skipping can be required if our Netbox user cannot be granted
            sufficient permissions. All access levels will have to be added manually, with exact formatting.
          </div>
        </div>
      </div>
      <div class="l-grid__cell l-grid__cell--6">
        <div class="l-form__field">
          <div class="l-bar u-margin-bottom-xs">
            <div class="l-bar__item-startm">
              <div class="c-list c-list--bare u-text-m">
                <input
                  id="useMultiPartitionAccessLevelGroups"
                  class="c-checkbox c-checkbox--m"
                  type="checkbox"
                  [(ngModel)]="useMultiPartitionAccessLevelGroups"
                  name="useMultiPartitionAccessLevelGroups"
                  (ngModelChange)="switchUseMultiPartitionAccessLevelGroups()"
                />
                <label class="c-checkbox__skin" for="useMultiPartitionAccessLevelGroups"></label>
                &nbsp;<label class="c-label c-label--checkbox c-label--m" for="useMultiPartitionAccessLevelGroups"
                  >Use Multi Partition Access Level Groups</label
                >
              </div>
            </div>
          </div>
          <div class="c-helper">
            Proxyclick can be configured to use multi-partition access level groups instead of standard access levels.
            This is typically used when setting up a multi-tenant building with a lobby-setup.
          </div>
        </div>
      </div>
    </div>
    <button type="submit" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
      Save settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
      <hl-icon
        *ngIf="submitting === false && errors === undefined"
        icon="checkmark-circle--color-24"
        width="24"
        height="24"
      ></hl-icon>
    </button>
  </form>
  <h3 *ngIf="errors">Errors during update</h3>
  <div class="u-color-danger" *ngIf="errors">
    <h4>{{ errors.title }}</h4>
    <dl>
      <ng-container *ngFor="let item of errors.errors | keyvalue">
        <dt>{{ item.key }}</dt>
        <dt>
          <ul>
            <li *ngFor="let errorItem of item.value">
              {{ errorItem }}
            </li>
          </ul>
        </dt>
      </ng-container>
    </dl>
    Trace id <code>{{ errors.traceId }}</code>
  </div>
</div>
