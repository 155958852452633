import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ISubscription } from 'rxjs-compat/Subscription';
import { RightService } from '~/services/rights.service';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { IAppState } from '~/store/app-state';
import { OrganisationActions } from '~/store/organisation/organisation.actions';
import { UserRights } from '../../../../../../shared/models';

@Component({
  selector: 'pxc-organisation-details',
  templateUrl: './organisation-details.html',
})
export class OrganisationDetailsPageComponent implements OnInit, OnDestroy {
  organisation$ = this.store.select('organisation', 'organisation', 'value');
  loading$ = this.store.select('organisation', 'organisation', 'loading');
  routeParamsSub: ISubscription;

  tabs: IOption[] = [
    { label: 'Companies', value: 'companies' },
    { label: 'Domains', value: 'domains' },
    { label: 'Platform', value: 'platform' },
  ];

  constructor(private store: Store<IAppState>, private route: ActivatedRoute, private Rights: RightService) {}

  ngOnInit() {
    if (this.Rights.hasRight(UserRights.TabUsers)) {
      this.tabs.unshift({ label: 'Admins', value: 'users' });
    }
    this.routeParamsSub = this.route.params.subscribe(params => {
      this.store.dispatch(OrganisationActions.Load(params.id));
    });
  }

  ngOnDestroy() {
    this.routeParamsSub.unsubscribe();
  }
}
