import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TaffyHttpClientProvider } from '@proxyclick/api-client';

@Injectable()
export class PxcApiClientAdapterService implements TaffyHttpClientProvider {
  /* @ngInject */
  constructor(private http: HttpClient) {}

  get(url: string, options: any = {}) {
    return this.http.get('/api' + url, options) as any;
  }

  delete(url: string, options: any = {}) {
    return this.http.delete('/api' + url, options) as any;
  }

  put(url: string, data: any, options: any = {}) {
    return this.http.put('/api' + url, data, options) as any;
  }

  patch(url: string, data: any, options: any = {}) {
    return this.http.patch('/api' + url, data, options) as any;
  }

  post(url: string, data: any, options: any = {}) {
    return this.http.post('/api' + url, data, options) as any;
  }
}
