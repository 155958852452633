<div *pxcHasRight='"tab_provisioning"'>
  <div class="flex end">
    <button class="c-button c-button--primary c-button--s" routerLink="new"><i class="fa fa-plus"></i> Create</button>
  </div>
  <table *ngIf="jsonDirectories$ | async as ldapDirectories" class="c-table mt-4">
    <thead>
      <th>Company</th>
      <th>Alias</th>
      <th>Last synchronization</th>
      <th></th>
      <th></th>
    </thead>
    <tbody>
      <tr *ngFor="let directory of ldapDirectories" class="table-row">
        <td>
          <a routerLink="{{directory.company.id}}">{{directory.company.name}}</a>
        </td>
        <td>{{directory.alias}}</td>
        <td>{{directory.lastLog && directory.lastLog.synchronizedAt | time:'L LT'}}</td>
        <td>
          <button class="btn btn-outline-success visible-on-hover" routerLink="{{directory.company.id}}">
            <i class="fas fa-pencil-alt"></i>
          </button>
        </td>
        <td>
          <button
            class="btn btn-outline-danger visible-on-hover"
            (click)="onDelete(directory)"
            [ngClass]="{'is-loading': directory.isDeleting}"
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
</div>
