import { Component, Input, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-pagination-dots',
  templateUrl: './pagination-dots.component.html',
})
export class PaginationDotsComponent {
  @Input() page: number;
  @Input() totalPages: number;

  get pageCountArray() {
    const array = [];
    for (let n = 0; n < this.totalPages; ++n) {
      array.push(n);
    }
    return array;
  }
}
