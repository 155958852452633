import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CONFIG_LOADED_ACTION, IAppState } from '~/store/app-state';
import { ObservableCache } from '~/utils/observable-cache';
import { IClientConfig } from '../../../../shared/models';

@Injectable()
export class ConfigService {
  private cache: ObservableCache = new ObservableCache();

  private config: IClientConfig;

  constructor(private http: HttpClient, private store: Store<IAppState>) {}

  initConfig() {
    return this.cache
      .getObservable('config', this.http.get<IClientConfig>('/config'))
      .toPromise()
      .then(config => {
        this.config = config;
        this.store.dispatch(CONFIG_LOADED_ACTION);
      });
  }

  getConfig() {
    return this.config;
  }
}

export function configFactory(config: ConfigService) {
  return () => config.initConfig();
}
