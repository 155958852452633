import { combineReducers } from '@ngrx/store';
import { createAsyncLoadReducer } from '~/utils/async-generator';
import { billingReducer } from '../billing/billing.reducer';
import {
  AdminCompanyActions,
  CompaniesActions,
  CompanyActions,
  CompanyAddress,
  CompanyAdminActions,
  CompanyIdScanStats,
  CompanyKioskPartners,
  CompanyModules,
  CompanyParams,
  CompanySAML,
  CompanyStats,
  CompanyTermsAndConditions,
  CompanyUserProvisioning,
  CompanyUsersActions,
  CompanyVmInvitationEmailParams,
  KioskParamsActions,
  VmParamsActions,
} from './companies.actions';

export interface ICompanySetFeaturePayload {
  companyId: string;
  featureListId: number;
}

export const companiesReducer = createAsyncLoadReducer(CompaniesActions);

export const companyReducer = combineReducers({
  company: createAsyncLoadReducer(CompanyActions),
  users: createAsyncLoadReducer(CompanyUsersActions),
  admins: createAsyncLoadReducer(CompanyAdminActions),
  modules: createAsyncLoadReducer(CompanyModules),
  billing: billingReducer,
  kioskParams: createAsyncLoadReducer(KioskParamsActions),
  kioskPartner: createAsyncLoadReducer(CompanyKioskPartners),
  vmParams: createAsyncLoadReducer(VmParamsActions),
  stats: createAsyncLoadReducer(CompanyStats),
  address: createAsyncLoadReducer(CompanyAddress),
  idScanStats: createAsyncLoadReducer(CompanyIdScanStats),
  userProvisioning: createAsyncLoadReducer(CompanyUserProvisioning),
  saml: createAsyncLoadReducer(CompanySAML),
  termsAndConditions: createAsyncLoadReducer(CompanyTermsAndConditions),
  params: createAsyncLoadReducer(CompanyParams),
  adminCompany: createAsyncLoadReducer(AdminCompanyActions),
  vmInvitationalEmailParams: createAsyncLoadReducer(CompanyVmInvitationEmailParams),
});
