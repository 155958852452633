<div [ngClass]="getClassMap()" class="flex wrap">
  <div
    class="u-margin-bottom-s"
    style="width: 33%;"
    *ngFor="let featureList of diffCurrentAndTargetMandatoryFeatureLists"
  >
    <div class="u-margin-right-xs">
      <div>
        <div class="flex center l-grid">
          <label class="flex center l-grid__cell l-grid__cell--9@large">
            <hl-radio
              (change)="onInternalChange(featureList.id)"
            ></hl-radio>
            <label class="c-checkbox__skin"></label>
            <span class="u-text-m text-bold u-margin-left-xs u-color-neutral-darker wrap"
              >{{featureList.description}}</span
            >
          </label>
        </div>
        <div>
          <div class="flex center" *ngFor="let feature of featureList.features">
            <div style="width: 20px; height: 25px;">
              <div *ngIf="!feature.includedInCurrent && feature.includedInTarget" class="u-color-accent-green">
                <hl-icon style="width: 24; height: 24; margin-top: 3px;" icon="add-circle-24"></hl-icon>
              </div>
              <div class="color-danger-light" *ngIf="!feature.includedInCurrent && !feature.includedInTarget">
                <hl-icon style="margin-top: 5px;" icon="close-16"></hl-icon>
              </div>
            </div>
            <span class="u-text-s u-color-neutral u-margin-left-xs">{{feature.description}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
