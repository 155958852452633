export const teams: Record<string, string[]> = {
  Admin: [],
  'Customer Success': [
    'log_as_user',
    'ldap_extract',
    'tab_companies',
    'tab_users',
    'tab_partners',
    'tab_provisioning',
    'tab_marketplace',
    'tab_stats',
    'tab_domains',
    'tab_organisations',
    'tab_visitors',
    'tab_fastpace',
    'company_vm_billing',
    'company_vm_options',
    'company_extend_trial',
    'company_sd_billing',
    'billing_start_pack_trial',
    'billing_cancel_pack',
    'billing_create_subscription',
    'billing_link_to_company',
    'billing_link_to_subscription',
    'billing_block_subscription',
    'billing_unblock_subscription',
    'billing_cancel_subscription',
    'billing_migrate',
    'billing_downgrade',
  ],
  'External support': [
    'tab_companies',
    'tab_users',
    'tab_domains',
    'tab_organisations',
    'company_extend_trial',
    'tab_visitors',
  ],
  SDR: [
    'tab_organisations',
    'tab_domains',
    'tab_companies',
    'tab_visitors',
    'billing_start_pack_trial',
    'billing_create_subscription',
  ],
  'Account Executive': [
    'tab_organisations',
    'tab_domains',
    'tab_companies',
    'tab_visitors',
    'billing_start_pack_trial',
    'billing_create_subscription',
    'billing_block_subscription',
    'billing_unblock_subscription',
  ],
  Marketing: [
    'tab_organisations',
    'tab_domains',
    'tab_marketplace',
    'marketplace_edit_integrations',
    'marketplace_edit_partners',
  ],
  Finance: [
    'tab_organisations',
    'tab_companies',
    'tab_visitors',
    'company_vm_billing',
    'company_vm_options',
    'company_extend_trial',
    'company_sd_billing',
  ],
};
