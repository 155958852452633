import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRegion } from '@proxyclick/data-model';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ObservableCache } from '~/utils/observable-cache';

@Injectable()
export class RegionsService {
  private cache: ObservableCache = new ObservableCache();

  constructor(private http: HttpClient) {}

  getRegions() {
    return this.cache.getObservable(
      'regions',
      this.http.get<IRegion[]>('/marketplace/api/regions').pipe(catchError(err => of([])))
    );
  }
}
