import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IMDocument } from '@proxyclick/data-model';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { BaseService } from '~/services/base.service';

const mimeTypeExtensionMap = {
  'application/pdf': '.pdf',
  'image/jpg': '.jpg',
  'image/jpeg': '.jpg',
  'image/gif': '.gif',
  'image/png': '.png',
};

@Injectable()
export class DocumentsService extends BaseService {
  constructor(injector: Injector, private httpClient: HttpClient) {
    super(injector);
  }

  uploadToMarketplace(file: File): Observable<IMDocument> {
    const formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);

    return this.httpClient.post<IMDocument>('/marketplace/api/documents', formData);
  }

  uploadFileFromUrl(url: string) {
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .subscribe((fileBlob: File) => this.uploadFileFromFileObj(fileBlob));
  }

  uploadFileFromFileObj(file: File) {
    const formData = new FormData();
    formData.append('document', file, file.name || this.generateFilename(file));
    return this.apiClient.Documents().doPost(formData as any);
  }

  private generateFilename(file: Blob): string {
    const uuid = Math.random().toString().substr(2);
    const extension = _.get(mimeTypeExtensionMap, file.type, '');

    return 'temp-file-' + uuid + extension;
  }
}
