import { Component, Input, OnInit } from '@angular/core';
import { ExtenderDetailsState } from '../extender.details.state';

@Component({
  selector: 'app-extender-details-openpath',
  templateUrl: './extender.details.openpath.component.html',
})
export class ExtenderDetailsOpenPathComponent {
  @Input() state: ExtenderDetailsState;
}
