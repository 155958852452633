
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RightGuard } from '~/shared/guards/right.guard';
import { SharedModule } from '~/shared/shared.module';
import { UserRights } from '../../../../../shared/models';
import { VisitorsPageComponent } from './visitors.page';

@NgModule({
  declarations: [
    VisitorsPageComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'visitors',
        canActivate: [RightGuard],
        data: {
          right: UserRights.TabVisitors,
          title: 'Visitors',
        },
        children: [
          {
            path: '',
            component: VisitorsPageComponent,
            data: {
              title: '',
            },
          },
        ],
      },
    ]),
  ],
})

export class VisitorsModule {}
