import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { JWTService } from '~/services/jwt.service';

@Injectable()
export class BatchService {
  constructor(private http: HttpClient, private JWT: JWTService) {}

  batch(
    input: {
      url: string;
      method: string;
    }[]
  ) {
    //Max 20 per batch
    const observables = _.chunk(input, 20).map(x =>
      this.http.post('/api/batch', x, {
        headers: {
          Authorization: `Bearer ${this.JWT.getJWT()}`,
        },
      })
    );

    return forkJoin(observables).pipe(
      map((response: any[]) =>
        response.map(r => ({
          body: r.body,
          code: r.code,
          status: r.status,
        }))
      )
    );
  }
}
