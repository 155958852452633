import { Component, Input, OnInit } from '@angular/core';
import { NewExtenderState } from '../new-extender.state';

@Component({
  selector: 'app-new-extender-brivo',
  templateUrl: './new-extender.brivo.component.html',
})
export class NewExtenderBrivoComponent {
  @Input() state: NewExtenderState;
}
