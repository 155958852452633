<div *pxcHasRight='"super_admin"' class="pxc-page">
  <a class="c-button c-button--s" routerLink="create">Create Admin User</a>
  <!-- <pxc-loading [loading]="loading$ | async"></pxc-loading> -->
  <table *ngIf="!(loading$ | async) && (adminUsers$ | async)" class="c-table">
    <thead>
      <th>Ref</th>
      <th>User</th>
      <th>Email</th>
    </thead>
    <tbody>
      <tr *ngFor="let adminUser of adminUsers$ | async">
        <td>{{adminUser.id}}</td>
        <td>
          <a [routerLink]="['/admin-users/create']" [queryParams]="{id: adminUser.id}"
            >{{adminUser.firstName}} {{adminUser.lastName}}</a
          >
        </td>
        <td>{{adminUser.email}}</td>
      </tr>
    </tbody>
  </table>
</div>
