import { Injector } from '@angular/core';
import { ConfigService } from '~/services/config.service';
import { PxcApiClient, PxcColdFusionApiClient } from '~/shared/api-client/api-client';

export class BaseService {
  //Backward compatibility to avoid upgrading all the code at once
  protected apiClient: PxcColdFusionApiClient;
  protected newApiClient: PxcApiClient;
  protected config: ConfigService;

  constructor(injector: Injector) {
    this.apiClient = injector.get(PxcColdFusionApiClient);
    this.newApiClient = injector.get(PxcApiClient);
    this.config = injector.get(ConfigService);
  }
}
