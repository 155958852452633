import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IntegrationsService } from '~/services/integrations.service';
import { IntegrationActions, IntegrationsActions } from '~/store/integrations/integrations.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';

@Injectable()
export class IntegrationsEffects {
  constructor(private actions$: Actions<PayloadAction>, private integrationsService: IntegrationsService) {}

  // Integration list effects
  
  loadAll$ = createEffect(() => generateAsyncEffect(this.actions$, IntegrationsActions, () =>
    this.integrationsService.integrations().get()
  ));

  // Integration details effects
  
  load$ = createEffect(() => generateAsyncEffect(this.actions$, IntegrationActions, id => this.integrationsService.integration(id).get()));

  
  new$ = createEffect(() => this.actions$.pipe(
    ofType(IntegrationActions.NEW),
    map(() => IntegrationActions.Loaded(this.integrationsService.integrations().default()))
  ));

  
  delete$ = createEffect(() => this.actions$.pipe(
    ofType(IntegrationActions.DELETE),
    switchMap(action =>
      this.integrationsService.deleteIntegration(action.payload).pipe(
        map(() => IntegrationsActions.Load()),
        catchError(err => observableOf(IntegrationsActions.Error(err)))
      )
    )
  ));

  
  update$ = createEffect(() => this.actions$.pipe(
    ofType(IntegrationActions.UPDATE),
    switchMap(action =>
      this.integrationsService.updateIntegration(action.payload).pipe(
        map(integration => IntegrationActions.Loaded(integration)),
        catchError(err => observableOf(IntegrationActions.Error(err)))
      )
    )
  ));

  
  create$ = createEffect(() => this.actions$.pipe(
    ofType(IntegrationActions.CREATE),
    switchMap(action =>
      this.integrationsService.createIntegration(action.payload).pipe(
        map(integration => IntegrationActions.Loaded(integration)),
        catchError(err => observableOf(IntegrationActions.Error(err)))
      )
    )
  ));
}
