<pxc-loading [loading]="loading$ | async"></pxc-loading>
<strong *ngIf="error$ | async" class="text-danger">{{ (error$ | async).message }}</strong>

<div *ngIf="extender$ | async as extender">
  <form class="c-card l-stack u-margin-h-none l-form u-margin-bottom-m">
    <div class="c-card__header">
      <h2 class="c-card__title">{{ extender.description }}</h2>
      <app-extender-seq-link
        [extenderId]="extender.extenderId"
        [clientId]="extender.client?.clientId"
      ></app-extender-seq-link>
    </div>

    <div class="c-card__section">
      <h5>
        General
        <small
          ><a (click)="showGeneral = true" *ngIf="showGeneral === false"
            ><span class="c-icon c-icon--m">
              <hl-icon icon="chevron-down-24" width="24" height="24"></hl-icon> </span></a
          ><a (click)="showGeneral = false" *ngIf="showGeneral === true"
            ><span class="c-icon c-icon--m"> <hl-icon icon="chevron-up-24" width="24" height="24"></hl-icon> </span></a
        ></small>
      </h5>

      <div *ngIf="showGeneral === true">
        <div class="alert alert-danger" *ngIf="state.extender.offline">
          The extender is offline since
          {{ state.extender.offline | date: 'mediumDate':'UTC' }}
          {{ state.extender.offline | date: 'mediumTime':'UTC' }}
          UTC .
        </div>
        <pxc-field label="ID">
          <code>{{ state.extender.extenderId }}</code>
        </pxc-field>
        <pxc-field label="Type"> {{ state.extender.type }} {{ state.extender.version }} </pxc-field>
        <pxc-field label="Last Connection">
          {{ state.extender.lastIsConnected | date: 'mediumDate':'UTC' }}
          {{ state.extender.lastIsConnected | date: 'mediumTime':'UTC' }}
          {{ state.extender.lastIsConnected ? 'UTC' : '' }}
        </pxc-field>
        <pxc-field label="Subscription ID">
          <code *ngIf="state.extender.subscriptionId">{{ state.extender.subscriptionId }}</code>
          <code *ngIf="!state.extender.subscriptionId">Extender does not subscribe to any events</code>
        </pxc-field>
        <div class="l-form__field">
          <label class="c-label">Description</label>
          <input
            type="text"
            class="c-input c-input--s"
            minlength="5"
            maxlength="50"
            required="true"
            name="description"
            [(ngModel)]="state.description"
          />
        </div>
        <div class="l-form__field">
          <label class="c-label">Technical contacts</label>
          <input
            type="text"
            class="c-input c-input--s"
            required="true"
            name="technicalContacts"
            maxlength=""
            [(ngModel)]="state.technicalContacts"
          />
          <div class="c-helper">
            Please provide a list of email addresses (<code>;</code> separated) on the customer side that will be
            emailed when errors occur during the lifetime of our application.
          </div>
        </div>
        <div class="l-form__field">
          <div class="l-bar u-margin-bottom-xs">
            <div class="l-bar__item-startm">
              <div class="c-list c-list--bare u-text-m">
                <input
                  id="alert-slack"
                  class="c-checkbox c-checkbox--m"
                  type="checkbox"
                  [checked]="state.alertStatus"
                  (change)="onAlertSlackChange($event)"
                />
                <label class="c-checkbox__skin" for="alert-slack"></label>
                &nbsp;<label class="c-label c-label--checkbox c-label--m" for="alert-slack"
                  >Alert Slack on status changes</label
                >
              </div>
            </div>
          </div>
          <div class="c-helper">
            Alert the slack #accesscontrol-events channel when this client goes offline or online. Highly recommended
            for all customer installations, only disable when the installation is a demo machine that is expected to go
            offline often.
          </div>
        </div>
      </div>
    </div>

    <div class="c-card__section" *ngIf="state.extender.client">
      <h5>
        Client
        <small
          ><a (click)="showClient = true" *ngIf="showClient === false"
            ><span class="c-icon c-icon--m">
              <hl-icon icon="chevron-down-24" width="24" height="24"></hl-icon> </span></a
          ><a (click)="showClient = false" *ngIf="showClient === true"
            ><span class="c-icon c-icon--m"> <hl-icon icon="chevron-up-24" width="24" height="24"></hl-icon> </span></a
        ></small>
      </h5>

      <div *ngIf="showClient === true">
        <app-extender-details-client [state]="state"></app-extender-details-client>
      </div>
    </div>

    <div class="c-card__section" *ngIf="!extender.client">
      <h5>
        {{ extender.type }}
        <small
          ><a (click)="showSystem = true" *ngIf="showSystem === false"
            ><span class="c-icon c-icon--m">
              <hl-icon icon="chevron-down-24" width="24" height="24"></hl-icon> </span></a
          ><a (click)="showSystem = false" *ngIf="showSystem === true"
            ><span class="c-icon c-icon--m"> <hl-icon icon="chevron-up-24" width="24" height="24"></hl-icon> </span></a
        ></small>
      </h5>
      <div *ngIf="showSystem === true">
        <app-extender-details-brivo *ngIf="state.extender.type == 'brivo'" [state]="state">
        </app-extender-details-brivo>
        <app-extender-details-openpath *ngIf="state.extender.type == 'openpath'" [state]="state">
        </app-extender-details-openpath>
        <app-extender-details-genea *ngIf="state.extender.type == 'genea'" [state]="state">
        </app-extender-details-genea>
      </div>
    </div>

    <div class="c-card__section">
      <div class="c-card__section text-danger" *ngIf="errors">
        <h4>{{ errors.title }}</h4>
        <dl>
          <ng-container *ngFor="let item of errors.errors | keyvalue">
            <dt>{{ item.key }}</dt>
            <dt>{{ item.value }}</dt>
          </ng-container>
        </dl>
        Trace id <code>{{ errors.traceId }}</code>
      </div>
      <button (click)="submit()" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
        Save settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
      </button>
      &nbsp;
      <button
        type="menu"
        class="c-button c-button--danger c-button--s"
        [disabled]="!canSubmit"
        (click)="deleteExtender()"
      >
        Remove extender&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
      </button>
    </div>

    <div class="c-card__section" *ngIf="companiesOnExtender.length > 0">
      <h5>
        FastPACE
        <small
          ><a (click)="showFastPACE = true" *ngIf="showFastPACE === false"
            ><span class="c-icon c-icon--m">
              <hl-icon icon="chevron-down-24" width="24" height="24"></hl-icon> </span></a
          ><a (click)="showFastPACE = false" *ngIf="showFastPACE === true"
            ><span class="c-icon c-icon--m"> <hl-icon icon="chevron-up-24" width="24" height="24"></hl-icon> </span></a
        ></small>
      </h5>

      <div *ngIf="showFastPACE === true">
        <pxc-field label="Failed actions in past 7 days">
          <div class="l-grid">
            <div class="l-grid__cell l-grid__cell--2">
              {{ fastpaceExtender.failedRetriesLast7Days }}
            </div>
            <div class="l-grid__cell l-grid__cell--2" *ngIf="fastpaceExtender.failedRetriesLast7Days > 0">
              <input
                type="number"
                class="c-input c-input--s"
                required="true"
                name="daysInThePast"
                min="1"
                [(ngModel)]="daysInThePast"
              />
            </div>
            <div class="l-grid__cell l-grid__cell--8" *ngIf="fastpaceExtender.failedRetriesLast7Days > 0">
              <button
                type="menu"
                class="c-button c-button--primary c-button--s"
                [disabled]="!canSubmit"
                (click)="retryUpdates()"
              >
                Retry failed updates for all companies (last {{ daysInThePast }} days)&nbsp;<hl-spinner
                  *ngIf="submitting"
                  size="medium"
                >
                </hl-spinner>
              </button>
            </div>
          </div>
        </pxc-field>

        <h4>{{ companiesOnExtender.length }} companies on Extender</h4>

        <ul>
          <li *ngFor="let company of companiesOnExtender">
            <div class="u-margin-v-s">
              <div class="l-grid">
                <div class="l-grid__cell l-grid__cell--4">
                  <a routerLink="/fastpace/companies/{{ company.companyId }}"
                    >{{ company.companyName }} ({{ company.companyId }})</a
                  >
                </div>
                <div class="l-grid__cell l-grid__cell--3">
                  <button
                    type="menu"
                    class="c-button c-button--primary c-button--s"
                    [disabled]="!canSubmit"
                    (click)="refreshCompanyExtenderSettings(company.companyId)"
                  >
                    Refresh settings ({{ company.companyId }}) &nbsp;<hl-spinner
                      *ngIf="submitting"
                      size="medium"
                    ></hl-spinner>
                  </button>
                </div>
                <div class="l-grid__cell l-grid__cell--3">
                  <button
                    type="menu"
                    class="c-button c-button--primary c-button--s"
                    [disabled]="!canSubmit"
                    (click)="retryUpdatesForCompany(company.companyId)"
                  >
                    Retry failed updates ({{ company.companyId }} last {{ daysInThePast }} days) &nbsp;<hl-spinner
                      *ngIf="submitting"
                      size="medium"
                    ></hl-spinner>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <button
          type="menu"
          class="c-button c-button--primary c-button--s"
          [disabled]="!canSubmit"
          (click)="refreshCompaniesExtenderSettings()"
        >
          Refresh settings for all companies&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
        </button>
        <div class="c-helper">
          Refreshes the settings for all companies, ensures access levels and groups are setup, sets up an event
          subscription and ensures the copmany is able to use the system. Will be automatically triggered for any
          setting change that requires this, but a manual override might be needed if something went wrong.
        </div>
      </div>
    </div>

    <div class="c-card__section">
      <h5>
        Proovr
        <small
          ><a (click)="showProovr = true" *ngIf="showProovr === false"
            ><span class="c-icon c-icon--m">
              <hl-icon icon="chevron-down-24" width="24" height="24"></hl-icon> </span></a
          ><a (click)="showProovr = false" *ngIf="showProovr === true"
            ><span class="c-icon c-icon--m"> <hl-icon icon="chevron-up-24" width="24" height="24"></hl-icon> </span></a
        ></small>
      </h5>

      <div *ngIf="showProovr === true && proovrExtender">
        <proovr-extender-details-page [extenderId]="extender.extenderId"></proovr-extender-details-page>
      </div>
      <div *ngIf="showProovr === true && !proovrExtender" class="c-banner c-banner--informative">
        <div class="c-banner__message">
          Enabling Proovr for an Extender currently requires a manual action, please reach out for help in the
          #accesscontrol channel
        </div>
      </div>
    </div>
  </form>
</div>
