import { createReducer, on } from '@ngrx/store';
import { BillingActions } from './billing.actions';

export const billingReducer = createReducer(
  {},
  on(BillingActions.BillingLoaded, (state, { billing }) => ({
    ...state,
    billing,
  })),
  on(BillingActions.FeatureListsLoaded, (state, { featureLists }) => ({
    ...state,
    featureLists,
  })),
);
