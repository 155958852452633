<pxc-loading [loading]="loading$ | async"></pxc-loading>
<div *ngIf="!(loading$ | async) && (partners$ | async)" class="p-2">
  <button class="c-button c-button--primary c-button--s" *pxcHasRight="'marketplace_edit_partners'" routerLink="new">
    <i class="fas fa-plus"></i> Create
  </button>
  <table class="c-table mt-2">
    <thead>
      <tr>
        <th></th>
        <th>Order</th>
        <th>Name</th>
        <th>Tagline</th>
        <th>Active</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let partner of partners$ | async | sortBy" class="pxc-partner-row table-row">
        <td><img *ngIf="partner.logo" src="{{partner.logo.url}}" /></td>

        <td>{{partner.order}}</td>
        <td><a routerLink="{{partner.id}}">{{partner.name}}</a></td>
        <td>{{partner.tagline}}</td>
        <td [ngClass]="getActiveClass(partner)">{{isActive(partner)}}</td>
        <td>
          <button
            class="btn btn-outline-success visible-on-hover"
            *pxcHasRight="'marketplace_edit_partners'"
            routerLink="{{partner.id}}"
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
        </td>
        <td>
          <button
            class="btn btn-outline-danger visible-on-hover"
            (click)="onDelete(partner)"
            *pxcHasRight="'marketplace_edit_partners'"
            [ngClass]="{'is-loading': partner.isDeleting}"
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
