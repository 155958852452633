import { Component, Input, OnInit } from '@angular/core';
import { ExtenderService } from '~/services/extender.service';
import { ErrorResponse } from '~/store/extender/extender.models';
import { ExtenderDetailsState } from '../extender.details.state';
import { catchError, map } from 'rxjs/operators';
import { AppStore } from '~/store/store';
import { ExtenderActions } from '~/store/extender/extender.reducer';
import { empty } from 'rxjs';

@Component({
  selector: 'app-extender-details-client',
  templateUrl: './extender.details.client.component.html',
})
export class ExtenderDetailsClientComponent {
  @Input() state: ExtenderDetailsState;

  constructor(private extenderService: ExtenderService, private store: AppStore) {}

  submitting: boolean = false;
  errors: ErrorResponse;

  async refreshClientSettings() {
    this.submitting = true;
    this.errors = undefined;
    try {
      await this.extenderService.refreshClientSettings(this.state.extender.client.clientId);
      this.submitting = false;
      this.store.dispatch(ExtenderActions.Extender.Load(this.state.extender.extenderId));
    } catch (error) {
      this.handleError(error);
    }
  }

  get canSubmit(): boolean {
    return this.submitting !== true;
  }

  handleError(error) {
    this.submitting = false;
    if (error && error.status < 500) {
      var errorResponse = error.error as ErrorResponse;
      this.errors = errorResponse;
    } else {
      this.errors = {
        title: error.message,
        errors: undefined,
        status: error.status,
        traceId: error.headers.get('traceId'),
        type: 'Failure',
      };
    }
  }
}
