import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { IFeatureListBase } from '@proxyclick/data-model';
import {
  BillingFrequency,
  IBillingStatus,
  ICompany,
  IFeatureList,
  IShippingAddress,
  ISubscription,
} from '@proxyclick/data-model';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, map, share, tap } from 'rxjs/operators';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { IAppState } from '~/store/app-state';
import { CompanySelector } from '~/store/company/selectors';
import { BaseService } from './base.service';

export interface ICreateSubscriptionDetails extends IShippingAddress {
  frequency: BillingFrequency;
  noChargebee?: boolean;
}

export const frequencyNames = {
  monthly: 'Monthly',
  yearly: 'Yearly',
  '2years': '2-Years',
  '3years': '3-Years',
  '4years': '4-Years',
  '5years': '5-Years',
};

export const frequencyOptions: IOption[] = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
  { label: '2-Years', value: '2years' },
  { label: '3-Years', value: '3years' },
  { label: '4-Years', value: '4years' },
  { label: '5-Years', value: '5years' },
];

@Injectable()
export class BillingService extends BaseService {
  private billing$: BehaviorSubject<IBillingStatus> = new BehaviorSubject(null);
  private featureLists$: BehaviorSubject<IFeatureListBase[]> = new BehaviorSubject(null);
  private loading = {
    billing$: new BehaviorSubject(true),
    featureLists$: new BehaviorSubject(true),
  };
  private company$ = this.store.select(CompanySelector.Company.Value);
  private company: ICompany;

  constructor(injector: Injector, private store: Store<IAppState>) {
    super(injector);

    this.company$
      .pipe(
        filter(c => !!c),
        tap(company => {
          this.company = company;
          return this.refresh(company.id);
        })
      )
      .subscribe();
  }

  getBilling$() {
    return this.billing$.pipe(share());
  }

  getFeatureLists$() {
    return this.featureLists$.pipe(share());
  }

  getLoading() {
    return {
      billing$: this.loading.billing$.pipe(share()),
      featureLists$: this.loading.featureLists$.pipe(share()),
    };
  }

  refresh(companyId: string = this.company.id) {
    this.refreshBilling(companyId).subscribe();
    this.refreshFeatureLists(companyId).subscribe();
  }

  refreshBilling(companyId: string = this.company.id) {
    this.loading.billing$.next(true);
    return this.getBilling(companyId).pipe(
      map(billing => {
        this.billing$.next(billing);
        this.loading.billing$.next(false);
      })
    );
  }

  refreshFeatureLists(companyId: string = this.company.id) {
    this.loading.featureLists$.next(true);
    return this.getCompanyFeatureLists(companyId).pipe(
      map(featureLists => {
        this.featureLists$.next(featureLists);
        this.loading.featureLists$.next(false);
      })
    );
  }

  activateFeatureList(companyId: string, featureListId: number) {
    return this.apiClient.ApplicationCompanyFeatureList(companyId, featureListId).doPut({});
  }

  cancelFeatureList(companyId: string, featureListId: number) {
    return this.apiClient.ApplicationCompanyFeatureListCancel(companyId, featureListId).doPost();
    // .pipe(switchMap(() => this.refreshFeatureLists()));
  }

  resetFeatureList(companyId: string, featureListId: number) {
    return this.apiClient.ApplicationCompanyFeatureListReset(companyId, featureListId).doPost();
    // .pipe(switchMap(() => this.refreshFeatureLists()));
  }

  getSubscription(companyId: string) {
    return this.apiClient
      .ApplicationBillingSubscription(companyId)
      .doGet()
      .pipe(catchError(_ => of(null)));
  }

  activateLocation(companyId: string) {
    return this.apiClient
      .ApplicationBillingSubscription(companyId)
      .doPut({ noChargebee: true } as ICreateSubscriptionDetails);
  }

  cancelSubscription(companyId: string, options: any) {
    return this.apiClient.ApplicationBillingSubscriptionCancel(companyId).doPost({
      options,
    });
    // .pipe(tap(() => this.refresh()));
  }

  blockSubscription(companyId: string) {
    return this.apiClient.ApplicationBillingSubscriptionBlock(companyId).doPost();
    // .pipe(tap(() => this.refresh()));
  }

  unblockSubscription(companyId: string) {
    return this.apiClient.ApplicationBillingSubscriptionUnblock(companyId).doPost();
    // .pipe(tap(() => this.refresh()));
  }

  extendTrial(companyId: string, endAt: string) {
    return this.apiClient
      .ApplicationBillingExtendTrial(companyId)
      .doPost({
        endAt,
      })
      .pipe(map(() => companyId));
    // .pipe(tap(() => this.refresh()));
  }

  checkFeatures(companyId: string, featureListId: number) {
    return this.apiClient.ApplicationCompanyFeatureListCheckFeatures(companyId, featureListId).doPost();
  }

  updateBilling(companyId: string, billing: IBillingStatus) {
    return this.apiClient.ApplicationBilling(companyId).doPatch(billing);
  }

  migrateBilling(companyId: string, featureLists: IFeatureList[]) {
    return this.apiClient.ApplicationBillingMigrate(companyId).doPost({
      featureLists,
    });
  }

  downgradeBilling(companyId: string, featureListId: number) {
    return this.apiClient.ApplicationBillingDowngrade(companyId).doPost({
      featureListId,
    });
  }

  getBilling(companyId: string) {
    return this.apiClient.ApplicationBilling(companyId).doGet();
  }

  getCompanyFeatureLists(companyId: string) {
    return this.apiClient.ApplicationCompanyFeatureLists(companyId).doGet();
  }
}

export const getTotal = (
  featureLists: IFeatureList[],
  frequency: BillingFrequency,
  subscription: ISubscription | null = null
) => {
  if (subscription) {
    return getPrice(subscription.mrr, frequency);
  }
  return featureLists.reduce((prev, curr) => {
    if (curr.activatedAt && ['INVOICED', 'IN_TRIAL'].includes(curr.status)) {
      return prev + getPrice(curr.price || curr.discountedPrices[frequency] || 0, frequency);
    } else {
      return prev + 0;
    }
  }, 0);
};

export const getPrice = (featureListPrice: number, frequency: BillingFrequency) => {
  switch (frequency) {
    case 'monthly':
      return featureListPrice;
    case 'yearly':
      return featureListPrice * 12;
    case '2years':
      return featureListPrice * 24;
    case '3years':
      return featureListPrice * 36;
    case '4years':
      return featureListPrice * 48;
    case '5years':
      return featureListPrice * 60;
  }
};
