import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { IMDocument } from '@proxyclick/data-model';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-images-manager',
  templateUrl: './images-manager.html',
  providers: controlValueAccessor(ImagesManagerComponent),
})
export class ImagesManagerComponent implements ControlValueAccessor {
  @Input()
  public limit = 5;

  public pictures: IMDocument[] = [];

  public onChange: Function;

  constructor() {}

  deletePicture(picture) {
    this.pictures.splice(this.pictures.indexOf(picture), 1);
    this.update();
  }

  addPicture() {
    this.pictures.push(null);
  }

  update() {
    this.onChange(this.pictures);
  }

  private onTouched(value) {}

  writeValue(pictures): void {
    this.pictures = pictures ? pictures : [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}
}
