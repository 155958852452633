import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  DefaultRouterStateSerializer,
  routerReducer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from '~/shared/shared.module';
import { AppPartnersEffects } from '~/store/app-partners/app-partners.effects';
import { appPartnerDetailsReducer, appPartnersReducer } from '~/store/app-partners/app-partners.reducer';
import { ApplicationsEffects } from '~/store/applications/applications.effects';
import { applicationReducer, applicationsReducer } from '~/store/applications/applications.reducer';
import { CompaniesEffects } from '~/store/companies/companies.effects';
import { companiesReducer, companyReducer } from '~/store/companies/companies.reducer';
import { CustomRouteStateSerializer } from '~/store/custom-route-state-serializer';
import { FastPaceEffects } from '~/store/fastpace/fastpace.effects';
import { fastPaceReducer } from '~/store/fastpace/fastpace.reducer';
import { IntegrationsStoreModule } from '~/store/integrations/integrations.store.module';
import { LanguagesEffects } from '~/store/languages/languages.effect';
import { languagesReducer } from '~/store/languages/languages.reducer';
import { OrganisationEffects } from '~/store/organisation/organisation.effects';
import { organisationReducer } from '~/store/organisation/organisation.reducer';
import { OrganisationsEffects } from '~/store/organisations/organisations.effects';
import { organisationsReducer } from '~/store/organisations/organisations.reducer';
import { PartnersStoreModule } from '~/store/partners/partners.store.module';
import { RegionsEffects } from '~/store/regions/regions.effects';
import { countriesReducer, regionsReducer } from '~/store/regions/regions.reducer';
import { SearchEffects } from '~/store/search/search.effects';
import { searchReducer } from '~/store/search/search.reducer';
import { StatsEffects } from '~/store/stats/stats.effects';
import { statsReducer } from '~/store/stats/stats.reducer';
import { LdapStoreModule } from '~/store/user-provisioning/user-provisioning.store.module';
import { UsersEffects } from '~/store/users/users.effects';
import { userCompanyCloneReducer, userDifferenceReducer, userReducer, usersReducer } from '~/store/users/users.reducer';
import { AdminCompaniesEffects } from './admin-companies/admin-companies.effects';
import { adminCompaniesReducer } from './admin-companies/admin-companies.reducer';
import { AdminUsersEffects } from './admin-users/admin-users.effects';
import { adminUserReducer, adminUsersReducer } from './admin-users/admin-users.reducer';
import { BillingEffects } from './billing/billing.effects';
import { CategoriesStoreModule } from './categories/categories.store.module';
import { DomainsEffects } from './domains/domains.effects';
import {
  domainCompaniesReducer,
  domainDetailsReducer,
  domainsReducer,
  domainUpdateClearbitReducer,
  domainUpdateReducer,
} from './domains/domains.reducer';
import { errorReducer } from './error/error.reducer';
import { ExtenderEffects } from './extender/extender.effects';
import { extenderReducer } from './extender/extender.reducer';
import { featureListsReducer } from './feature-lists/feature-lists.reducer';
import { IntercomEffects } from './intercom/intercom.effects';
import { intercomReducer } from './intercom/intercom.reducer';
import { loadingReducer } from './loading/loading.reducer';
import { RightsEffects } from './rights/rights.effects';
import { rightsReducer } from './rights/rights.reducer';
import { recentUsersReducer } from './users/users.actions';
import { utilitiesReducer } from './utilities/utilities.reducer';
import { VisitorsEffects } from './visitors/visitors.effects';
import { visitorsReducer } from './visitors/visitors.reducer';

export const BASE_REDUCER_TOKEN = new InjectionToken('BASE_REDUCER_TOKEN');

const baseReducer = {
  companies: companiesReducer,
  organisations: organisationsReducer,
  organisation: organisationReducer,
  company: companyReducer,
  users: usersReducer,
  user: userReducer,
  'admin-users': adminUsersReducer,
  'admin-user': adminUserReducer,
  rights: rightsReducer,
  'recent-users': recentUsersReducer,
  regions: regionsReducer,
  featureLists: featureListsReducer,
  fastpace: fastPaceReducer,
  extender: extenderReducer,
  'app-partners': appPartnersReducer,
  'app-partner': appPartnerDetailsReducer,
  languages: languagesReducer,
  search: searchReducer,
  stats: statsReducer,
  countries: countriesReducer,
  applications: applicationsReducer,
  application: applicationReducer,
  domains: domainsReducer,
  domain: domainDetailsReducer,
  'domain-companies': domainCompaniesReducer,
  'admin-companies': adminCompaniesReducer,
  'domain/update': domainUpdateReducer,
  'domain/update-clearbit': domainUpdateClearbitReducer,
  intercom: intercomReducer,
  utilities: utilitiesReducer,
  visitors: visitorsReducer,
  loading: loadingReducer,
  error: errorReducer,
  'user-difference': userDifferenceReducer,
  'clone-users': userCompanyCloneReducer,
};

const reducerProvider = {
  provide: BASE_REDUCER_TOKEN,
  useValue: baseReducer,
};

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forRoot({
      routerReducer,
      // debug: debugReducer
    }),
    StoreModule.forRoot(BASE_REDUCER_TOKEN, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 100, //  Retains least 25 states
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),

    IntegrationsStoreModule,
    CategoriesStoreModule,
    PartnersStoreModule,
    LdapStoreModule,

    EffectsModule.forFeature([
      CompaniesEffects,
      OrganisationsEffects,
      OrganisationEffects,
      UsersEffects,
      RegionsEffects,
      AppPartnersEffects,
      LanguagesEffects,
      SearchEffects,
      StatsEffects,
      ApplicationsEffects,
      DomainsEffects,
      AdminCompaniesEffects,
      FastPaceEffects,
      ExtenderEffects,
      BillingEffects,
      IntercomEffects,
      AdminUsersEffects,
      RightsEffects,
      VisitorsEffects,
    ]),
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouteStateSerializer,
    },
    reducerProvider,
  ],
})
export class AppStoreModule {}
