export interface ProovrExtenderUserImportMappingRequestV1 {
  companyId: string;
  fieldId: string;
  fieldValue: string;
  fieldValueComparison: string;
}

export enum ProovrFieldValueComparison {
  equals,
  notEquals,
  contains,
}

export interface ProovrExtenderResponseV1 {
  extenderId: string;
  description: string;
  tokenGroupId: string;
  mapExistingUsers: string;
  userSyncIsEnabled: boolean;
  userSyncBaseCompanyId: string;
  userSyncIsDryRun: boolean;
  userSyncReportEmailAddresses: string;
}

export interface ProovrExtenderUserImportJobResponseV1 {
  jobKey: string;
  extenderId: string;
  status: string;
  baseCompanyId: string;
  startDate: Date;
  completionDateTime: Date;
  isDryRun: boolean;
  isReportAvailable: boolean;
  exceptionMessage: string;
  exceptionStackTrace: string;
}

export interface IPaginatedResponse<T> {
  items: T[];
  totalAmount: number;
}

export interface ProovrUpdateExtenderClientRequestV1 {
  userSyncIsEnabled: boolean;
  userSyncBaseCompanyId: string;
  userSyncIsDryRun: boolean;
  userSyncReportEmailAddresses: string;
}

export interface ProovrExtenderUserImportMappingResponseV1 {
  extenderUserSyncFieldMapId: string;
  companyId: string;
  extenderId: string;
  fieldId: string;
  fieldValue: string;
  fieldValueComparison: string;
}

export enum ProovrUserImportJobDataFileExtension {
  csv = 'csv',
  json = 'json',
}

export enum ProovrUserImportJobDataType {
  userReport = 'user-report',
  userImports = 'user-imports',
  userErrors = 'user-errors',
}

export interface ProovrExtenderUserFieldResponseV1 {
  id: string;
  name: string;
  description: string;
  type: string;
  values: ProovrExtenderUserFieldListItemResponseV1[];
}

export interface ProovrExtenderUserFieldListItemResponseV1 {
  id: string;
  name: string;
}
