import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { BaseService } from './base.service';

@Injectable()
export class UserDirectoryService extends BaseService {
  constructor(injector: Injector, private Notifications: NotificationsService) {
    super(injector);
  }

  removePxcUserDirectory(userId: string): Observable<string> {
    return this.apiClient
      .AdminUserLogin(userId)
      .doDelete()
      .pipe(
        tap(() => this.Notifications.success(`User ${userId} has been removed from the PXC User Directory`)),
        map(() => userId)
      );
  }

  removeMultiplePxcUserDirectory(userIds: string): Observable<string> {
    return this.apiClient
      .AdminUsersLogin(userIds)
      .doDelete()
      .pipe(
        tap(() => this.Notifications.success(`All users their PXC User Directories have been removed`)),
        map(() => userIds)
      );
  }

  createPxcUserDirectory(userId: string, email: string): Observable<{ userId: string; email: string }> {
    return this.apiClient
      .AdminUserLogin(userId)
      .doPost()
      .pipe(
        switchMap(() => this.apiClient.AdminUserPasswordReminder().doPost({ email })),
        tap(() => this.Notifications.success(`User ${userId} has been added to the PXC User Directory`)),
        map(() => ({ userId, email }))
      );
  }
}
