<div>
  <div *ngIf="plan">
    {{plan}}
  </div>
  <div *ngIf="!plan" #wrapper class="pxc-icon-list__wrapper" id="wrapper-{{uid}}">
    <div
      class="pxc-icon-list__icon"
      *ngFor="let featureList of filteredList; let i = index"
      [style.z-index]="zIndexBase - i"
    >
      <pxc-pack-icon *ngIf="featureList" [pack]="featureList.reference"></pxc-pack-icon>
    </div>
  </div>
  <hl-popover element="#wrapper-{{uid}}" trigger="mouseenter" [options]="popoverOptions">
    <div class="u-color-neutral-darker u-text-m text-bold">Packs</div>
    <div class="pxc-icon-list__popup">
      <div *ngFor="let featureList of filteredList" class="pxc-icon-list__element">
        <pxc-pack-icon [pack]="featureList.reference"></pxc-pack-icon>
        <div class="u-margin-left-xs u-color-neutral-darker u-text-s">{{featureList.name}}</div>
      </div>
    </div>
  </hl-popover>
</div>
