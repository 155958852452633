<div
  class="u-color-bg-neutral-off pxc-full-height"
  *pxcHasRight='"tab_organisations"'
>
  <pxc-loading [loading]="loading$ | async">
    <div
      class="organisation-details__wrapper"
      *ngIf="organisation$ | async as organisation"
    >
      <div class="organisation-details__header u-color-bg-white u-padding-h-l u-padding-top-m">
        <div class="u-flex u-flex-justify-between">
          <span class="u-text-xl u-color-brand text-bold u-margin-bottom-gutter">{{organisation.name}}</span>
        </div>
        <pxc-tabs
          [tabs]="tabs"
          class="mb-2"
        ></pxc-tabs>
      </div>
      <div class="organisation-details__content u-padding-h-l u-padding-v-m">
        <router-outlet></router-outlet>
      </div>
    </div>
  </pxc-loading>
</div>
