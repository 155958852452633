import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AuthService } from '~/services/auth.service';

@Component({
  selector: 'pxc-password-strength',
  templateUrl: './password-strength.html',
})
export class PasswordStrengthComponent implements OnChanges {
  @Input()
  password: string;

  @Output()
  onStrengthChange: EventEmitter<number> = new EventEmitter();

  strength = 0;
  width = 0;
  color = 'danger';

  constructor(private Auth: AuthService) {}

  ngOnChanges() {
    this.computePasswordStrength();
    this.computeColor();
    this.computeWidth();
  }

  computePasswordStrength() {
    this.strength = this.Auth.measurePasswordStrength(this.password);
    this.onStrengthChange.emit(this.strength);
  }

  computeColor() {
    if (this.strength >= 40) {
      this.color = 'success';
    } else if (this.strength >= 20) {
      this.color = 'warning';
    } else {
      this.color = 'danger';
    }
  }

  computeWidth() {
    this.width = Math.min(100, Math.max(0, this.strength * 2));
  }

  getClass() {
    return 'text-' + this.color;
  }
}
