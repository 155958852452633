import { Injectable } from '@angular/core';
import { ICompany, ICompanyParams, ICustomField, IFeatureList, IInvitationEmailSettings } from '@proxyclick/data-model';
import { Observable, of as observableOf, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { _ } from '~/libs';
import { ICompanyFilter } from '~/models/filters';
import { GoogleMapsService } from '~/services/googlemaps.service';
import { PxcColdFusionApiClient } from '~/shared/api-client/api-client';
import { ICompanySetFeaturePayload } from '~/store/companies/companies.reducer';
import { IPaginatedCompanies } from '../../../../shared/filters';
import { PxcConverter } from './pxc-converter.service';

export interface ICreateCompanyForm {
  name: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  address: string;
  city: string;
  zip: string;
  countryCode: string;
  phone?: string;
  modules: string[];
}

export const newCompany: ICreateCompanyForm = {
  name: '',
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  address: '',
  city: '',
  zip: '',
  countryCode: 'US',
  phone: '',
  modules: ['VM'],
};

interface IUpdateCompanyStatusData {
  billingCompanyId?: string;
  endDate?: string;
}

const enum CompanyBillingStatus {
  INVOICED = 'INVOICED',
  INVOICED_OTHER_COMPANY = 'INVOICED_OTHER_COMPANY',
  IN_TRIAL = 'IN_TRIAL',
  HIDDEN = 'HIDDEN',
  CANCELLED = 'CANCELLED',
  BLOCKED = 'BLOCKED',
  SUGGESTED = 'SUGGESTED',
}

@Injectable()
export class CompaniesService {
  constructor(
    private apiClient: PxcColdFusionApiClient,
    private GoogleMaps: GoogleMapsService,
    private pxcConverter: PxcConverter
  ) {}

  getCompanies(filter: ICompanyFilter): Observable<IPaginatedCompanies> {
    return this.apiClient.AdminCompanies().doGet(filter);
  }

  getCompany(companyId: string): Observable<ICompany> {
    return this.apiClient.ApplicationCompanyProfile(companyId).doGet();
  }

  getAllFeatures(): Observable<IFeatureList[]> {
    return this.apiClient.AdminFeatureLists().doGet();
  }

  getCustomFields(companyId: string): Observable<ICustomField[]> {
    return this.apiClient.ApplicationVisitorManagementCustomFields(companyId).doGet();
  }

  getCompanyModules(companyId: string) {
    return this.apiClient.ApplicationCompanyModules(companyId).doGet();
  }

  getVmParams(companyId: string) {
    return this.apiClient.ApplicationVisitorManagementParams(companyId).doGet();
  }

  setVmParams(companyId: string, data) {
    return this.apiClient.ApplicationVisitorManagementParams(companyId).doPatch(data);
  }

  setMaxCustomFields(companyId: string, maxCustomFields: number) {
    return this.apiClient.AdminCompaniesVisitorManagementParamsCustomFieldsLimit(companyId).doPut({ maxCustomFields });
  }

  getCompanyParams(companyId: string) {
    return this.apiClient.ApplicationCompanyParams(companyId).doGet();
  }

  updateCompanyParams(companyId: string, params: ICompanyParams) {
    return this.apiClient.ApplicationCompanyParams(companyId).doPatch(params);
  }

  updateVmInvitationalEmailParams(companyId: string, params: IInvitationEmailSettings) {
    return this.apiClient.ApplicationVisitorManagementParamsInvitationEmail(companyId).doPatch(params);
  }

  getAddress(companyId: string) {
    return this.apiClient.ApplicationCompanyAddress(companyId).doGet();
  }

  updateAddress(companyId: string, data) {
    return this.apiClient.ApplicationCompanyAddress(companyId).doPut(data);
  }

  setFeatures(data: ICompanySetFeaturePayload) {
    return {
      vm: () =>
        this.apiClient
          .ApplicationCompanyModuleSetFeatureList(data.companyId, 'VM')
          .doPut({ featureListId: data.featureListId }),
      sd: () =>
        this.apiClient
          .ApplicationCompanyModuleSetFeatureList(data.companyId, 'SD')
          .doPut({ featureListId: data.featureListId }),
    };
  }

  getKioskPartner(companyId: string) {
    return this.apiClient
      .ApplicationVisitorManagementKioskPartner(companyId)
      .doGet()
      .pipe(catchError(() => of(null)));
  }

  setKioskPartner(companyId: string, partnerId: number) {
    return this.apiClient.ApplicationVisitorManagementKioskPartner(companyId).doPut({
      partnerId: partnerId,
    });
  }

  removeKioskPartner(companyId) {
    return this.apiClient.ApplicationVisitorManagementKioskPartner(companyId).doDelete();
  }

  searchCompanies(term: string) {
    return term.startsWith('CO-') && this.pxcConverter.isValidReference(term)
      ? this.getCompany(term).pipe(
          map(c => (c ? [c] : [])),
          catchError(err => of([]))
        )
      : this.getCompanies({ q: term }).pipe(
          map(d => d.companies),
          catchError(err => of([]))
        );
  }

  getUserProvisioning(companyId: string) {
    return this.apiClient
      .ApplicationIntegrationsUsersProvisioning(companyId)
      .doGet()
      .pipe(catchError(err => observableOf(null)));
  }

  getSAML(companyId: string) {
    return this.apiClient
      .ApplicationCompanyUsersSaml(companyId)
      .doGet()
      .pipe(catchError(err => observableOf(null)));
  }

  getTermsAndConditions(companyId: string) {
    return this.apiClient.ApplicationCompanyTermsAndConditions(companyId).doGet();
  }

  getVmInvitationEmailParams(companyId: string) {
    return this.apiClient.ApplicationVisitorManagementParamsInvitationEmail(companyId).doGet();
  }

  deleteCompany(companyId: string) {
    return this.apiClient.ApplicationCompany(companyId).doDelete();
  }

  async createAdminCompany(company: ICreateCompanyForm) {
    const geoloc = await this.GoogleMaps.getGeoCode(
      company.address,
      company.zip,
      company.city,
      company.countryCode
    ).toPromise();
    if (geoloc.status !== 'OK' || !_.pick(geoloc, 'results[0].geometry.location')) {
      throw Error('Invalid address: ' + geoloc.status);
    }
    const location = geoloc.results[0].geometry.location;
    const timezone = await this.GoogleMaps.getTimezone(location.lat, location.lng).toPromise();
    if (timezone.status !== 'OK') {
      throw Error('Error while getting timezone: ' + timezone.status);
    }
    return this.createAdminTrial(company, location.lat, location.lng, timezone.timeZoneId);
  }

  async createAdminTrial(company: ICreateCompanyForm, latitude: number, longitude: number, timezone: string) {
    const createdCompany = await this.apiClient
      .SignupCreateAdminTrial()
      .doPost({
        firstname: company.firstname,
        lastname: company.lastname,
        email: company.email,
        password: company.password,
        address: company.address,
        city: company.city,
        timezone: timezone,
        postalCode: company.zip,
        companyName: company.name,
        modules: company.modules,
        countryCode: company.countryCode,
        latitude: latitude,
        longitude: longitude,
        phone: company.phone,
      })
      .toPromise();
  }
}
