import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BillingService } from '~/services/billing.service';
import { BillingActions } from './billing.actions';

@Injectable()
export class BillingEffects {
  constructor(private actions$: Actions<TypedAction<string>>, private billing: BillingService) {}

  billing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BillingActions.BillingLoad,
        BillingActions.BlockSubscriptionDone,
        BillingActions.UnblockSubscriptionDone,
        BillingActions.MigrateBilling,
        BillingActions.ExtendTrialDone,
        BillingActions.DowngradeBilling.Saved,
        BillingActions.UpdateBilling.Saved,
        BillingActions.ActivateLocation.Saved
      ),
      switchMap(action => this.billing.getBilling(action.payload.companyId)),
      map(result => BillingActions.BillingLoaded({ billing: result }))
    )
  );

  featureLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BillingActions.BillingLoad,
        BillingActions.ActivateFeatureListDone,
        BillingActions.CancelFeatureListDone,
        BillingActions.ResetFeatureListDone,
        BillingActions.ExtendTrialDone,
        BillingActions.BlockSubscriptionDone,
        BillingActions.UnblockSubscriptionDone,
        BillingActions.DowngradeBilling.Saved,
        BillingActions.UpdateBilling.Saved
      ),
      switchMap(action => this.billing.getCompanyFeatureLists(action.payload.companyId)),
      map(result => BillingActions.FeatureListsLoaded({ featureLists: result }))
    )
  );

  extendTrial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.ExtendTrial),
      switchMap(action =>
        this.billing.extendTrial(action.payload.companyId, action.payload.endAt).pipe(
          map(companyId => BillingActions.ExtendTrialDone({ companyId })),
          catchError(error => of(BillingActions.ExtendTrialError({ error })))
        )
      )
    )
  );

  blockSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.BlockSubscription),
      switchMap(action =>
        this.billing.blockSubscription(action.payload.companyId).pipe(
          map(() => BillingActions.BlockSubscriptionDone({ companyId: action.payload.companyId })),
          catchError(error => of(BillingActions.BlockSubscriptionError({ error })))
        )
      )
    )
  );

  unblockSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.UnblockSubscription),
      switchMap(action =>
        this.billing.unblockSubscription(action.payload.companyId).pipe(
          map(() => BillingActions.UnblockSubscriptionDone({ companyId: action.payload.companyId })),
          catchError(error => of(BillingActions.UnblockSubscriptionError({ error })))
        )
      )
    )
  );

  cancelSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.CancelSubscription),
      switchMap(action =>
        this.billing.cancelSubscription(action.payload.companyId, action.payload.options).pipe(
          map(() => BillingActions.BillingLoad({ companyId: action.payload.companyId })),
          catchError(error => of(BillingActions.UnblockSubscriptionError({ error })))
        )
      )
    )
  );

  activateFeatureList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.ActivateFeatureList),
      switchMap(action =>
        this.billing.activateFeatureList(action.payload.companyId, action.payload.featureListId).pipe(
          map(() =>
            BillingActions.ActivateFeatureListDone({
              companyId: action.payload.companyId,
              featureListId: action.payload.featureListId,
            })
          ),
          catchError(error => of(BillingActions.ActivateFeatureListError({ error })))
        )
      )
    )
  );

  cancelFeatureList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.CancelFeatureList),
      switchMap(action =>
        this.billing.cancelFeatureList(action.payload.companyId, action.payload.featureListId).pipe(
          map(() =>
            BillingActions.CancelFeatureListDone({
              companyId: action.payload.companyId,
              featureListId: action.payload.featureListId,
            })
          ),
          catchError(error => of(BillingActions.CancelFeatureListError({ error })))
        )
      )
    )
  );

  resetFeatureList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.ResetFeatureList),
      switchMap(action =>
        this.billing.resetFeatureList(action.payload.companyId, action.payload.featureListId).pipe(
          map(() =>
            BillingActions.ResetFeatureListDone({
              companyId: action.payload.companyId,
              featureListId: action.payload.featureListId,
            })
          ),
          catchError(error => of(BillingActions.ResetFeatureListError({ error })))
        )
      )
    )
  );

  activateLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.ActivateLocation.Save),
      switchMap(({ payload: action }) =>
        this.billing.activateLocation(action.companyId).pipe(
          map(() => BillingActions.ActivateLocation.Saved({ companyId: action.companyId })),
          catchError(error => of(BillingActions.ActivateLocation.Error(error)))
        )
      ),
      catchError(error => of(BillingActions.ActivateLocation.Error(error)))
    )
  );

  updateBilling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.UpdateBilling.Save),
      switchMap(({ payload: action }) =>
        this.billing.updateBilling(action.companyId, action.billing).pipe(
          map(() => BillingActions.UpdateBilling.Saved({ companyId: action.companyId })),
          catchError(error => of(BillingActions.UpdateBilling.Error(error)))
        )
      )
    )
  );

  downgradeBilling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActions.DowngradeBilling.Save),
      switchMap(({ payload: action }) =>
        this.billing.downgradeBilling(action.companyId, action.featureListId).pipe(
          map(() => BillingActions.DowngradeBilling.Saved({ companyId: action.companyId })),
          catchError(error => of(BillingActions.DowngradeBilling.Error(error)))
        )
      )
    )
  );
}
