<pxc-companies-typeahead
  required
  [(ngModel)]="company"
  (ngModelChange)="onChangeInternal($event)"
></pxc-companies-typeahead>

<div class="pxc-selector mt-2">
  <div class="pxc-selector-item" *ngFor="let c of companies">
    <img *ngIf="c && c.countryCode" class="mr-2" src="assets/img/flag/{{c.countryCode.toLowerCase()}}.png" /> {{c.name}}
    <i class="fas fa-times pointer" (click)="remove(c)"></i>
  </div>
</div>
