import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import '~/../../node_modules/@proxyclick/hello-icons/dist/dashboard-sprite.css';
import '~/../../node_modules/@proxyclick/hello-icons/dist/legacy-sprite.css';
import { AppModule } from '~/app.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
