<div class="l-form__field">
  <label class="c-label">Email</label>
  <input type="text" class="c-input c-input--s" required="true" name="email" [(ngModel)]="state.openpathEmail" />
</div>
<div class="l-form__field">
  <label class="c-label">Password</label>
  <input
    type="password"
    class="c-input c-input--s"
    required="true"
    name="password"
    [(ngModel)]="state.openpathPassword"
  />
</div>
