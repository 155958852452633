import { createSelector } from '@ngrx/store';
import { IAppState } from '../app-state';

const get = (state: IAppState) => state.organisations;

export const OrganisationsSelector = {
  get: createSelector(get, state => state.value),
  isLoading: createSelector(get, state => state.loading),
  error: createSelector(get, state => state.error),
};
