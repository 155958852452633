import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IIntegration } from '@proxyclick/data-model';
import { tap } from 'rxjs/operators';
import { BaseService } from '~/services/base.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { ObservableCache } from '~/utils/observable-cache';

const defaultIntegration: IIntegration = {
  name: '',
  tagline: '',
  description: '',
  editor: '',
  plan: 'FREE',
  categories: [],
  pictures: [],
  prices: { EUR: 20, USD: 20, GBP: 20 },
  callToAction: 'INSTALL',
  active: true,
};

@Injectable()
export class IntegrationsService extends BaseService {
  private cache: ObservableCache = new ObservableCache();

  constructor(private http: HttpClient, injector: Injector, private Notifications: NotificationsService) {
    super(injector);
  }

  createIntegration(integration: IIntegration) {
    return this.http.post<IIntegration>(`/marketplace/api/integrations`, integration).pipe(
      tap(() => this.integrations().invalidate()),
      tap(() => this.Notifications.success('Integration has been created'))
    );
  }

  updateIntegration(integration: IIntegration) {
    return this.http.patch<IIntegration>(`/marketplace/api/integrations/${integration.id}`, integration).pipe(
      tap(() => this.integration(integration.id).invalidate()),
      tap(() => this.Notifications.success('Integration has been updated'))
    );
  }

  deleteIntegration(integration: IIntegration) {
    return this.http.delete<void>(`/marketplace/api/integrations/${integration.id}`).pipe(
      tap(() => this.integration(integration.id).invalidate()),
      tap(() => this.Notifications.success('Integration has been deleted'))
    );
  }

  integrations() {
    return {
      get: () =>
        this.cache.getObservable(`integrations`, this.http.get<IIntegration[]>('/marketplace/api/integrations')),
      invalidate: () => this.cache.invalidate(`integrations`),
      default: () => defaultIntegration,
    };
  }

  integration(integrationId) {
    return {
      get: () =>
        this.cache.getObservable(
          `integration-${integrationId}`,
          this.http.get<IIntegration>(`/marketplace/api/integrations/${integrationId}`)
        ),
      invalidate: () => {
        this.cache.invalidate(`integration-${integrationId}`);
        this.cache.invalidate(`integrations`);
      },
    };
  }
}
