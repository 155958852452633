import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { map, tap } from 'rxjs/operators';
import { queries } from './queries';

const BASE_URL = 'proxystats/';

export interface IProxyStatsQuery {
  aggregations: {
    [key: string]: {
      doc_count_error_upper_bound: number;
      sum_other_doc_count: number;
      buckets: {
        key: any;
        key_as_string?: any;
        doc_count: number;
      }[];
    };
  };
  hits: any;
}

@Injectable()
export class ProxyStatsService {
  constructor(private http: HttpClient) {}

  private query(query) {
    return this.http.post<IProxyStatsQuery>(BASE_URL + query.url, query.query);
  }

  freeTrialsPerMonth$() {
    return this.query(queries.freeTrials).pipe(
      map(r => r.aggregations.freeTrialByMonth.buckets),
      map(r =>
        r.map(month => ({
          month: moment(month.key).format('YYYY-MM'),
          value: month.doc_count,
        }))
      )
    );
  }

  NPSPerMonth$() {
    return this.query(queries.NPS).pipe(
      map(r => r.aggregations.NPSByMonth.buckets),
      map(r =>
        r.map(month => ({
          month: moment(month.key).format('YYYY-MM'),
          nps: computeNPS(month['score'].buckets),
        }))
      )
    );
  }

  checkinPerHour$() {
    return this.query(queries.checkinPerHour).pipe(
      map(r => r.aggregations.checkinPerHour.buckets),
      map(r =>
        r.map(hour => ({
          hour: moment(hour.key).format('HH'),
          value: hour.doc_count,
        }))
      ),
      map(r => r.sort((a, b) => parseInt(a.hour) - parseInt(b.hour)))
    );
  }

  checkinPerCountries$() {
    let rest;
    return this.query(queries.checkinPerCountry).pipe(
      map(r => r.aggregations.checkinPerCountry),
      tap(r => (rest = r.sum_other_doc_count)),
      map(r =>
        r.buckets.map(country => ({
          country: country.key,
          value: country.doc_count,
        }))
      ),
      map(r => [
        ...r,
        {
          country: 'REST',
          value: rest,
        },
      ])
    );
  }
}

function computeNPS(values: { key: number; doc_count: number }[]) {
  const detractors = values.filter(v => v.key <= 6).reduce((prev, curr) => prev + curr.doc_count, 0);
  const promotors = values.filter(v => v.key >= 9).reduce((prev, curr) => prev + curr.doc_count, 0);
  const neutrals = values.filter(v => v.key >= 7 && v.key <= 8).reduce((prev, curr) => prev + curr.doc_count, 0);
  const promotorsRatio = promotors / (promotors + detractors + neutrals);
  const detractorsRatio = detractors / (promotors + detractors + neutrals);
  const nps = ((promotorsRatio - detractorsRatio) * 100).toFixed(1);
  // console.log('nps', { detractors, promotors, nps, neutrals, promotorsRatio, detractorsRatio });
  return nps;
}
