import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConvertPageComponent } from '~/pages/utilities/convert/convert.page';
import { RightGuard } from '~/shared/guards/right.guard';
import { SharedModule } from '~/shared/shared.module';
import { UserRights } from '../../../../../shared/models';
import { BatchEmailUpdatePageComponent } from './batch-email-update/batch-email-update.page';
import { UpdateHomeLocationPageComponent } from './update-home-location/update-home-location.page';
import { UtilitiesPageComponent } from './utilities.page';

@NgModule({
  declarations: [
    UtilitiesPageComponent,
    ConvertPageComponent,
    BatchEmailUpdatePageComponent,
    UpdateHomeLocationPageComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'utilities',
        component: UtilitiesPageComponent,
        canActivate: [RightGuard],
        data: {
          right: UserRights.TabUtilities,
          title: 'Utilities',
        },
        children: [
          {
            path: 'convert',
            component: ConvertPageComponent,
          },
          {
            path: 'batch-email-update',
            component: BatchEmailUpdatePageComponent,
          },
          {
            path: 'update-home-location',
            component: UpdateHomeLocationPageComponent,
          },
          {
            path: '**',
            redirectTo: 'convert',
          },
        ],
      },
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UtilitiesModule {}
