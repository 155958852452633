import { Observable } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd, Params, PRIMARY_OUTLET } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

export interface IBreadcrumb {
  label: string;
  url: string;
}

export interface IHasBreadcrumbs {
  breadcrumbs$: Observable<IBreadcrumb[]>;
}

@Component({
  selector: 'pxc-breadcrumb',
  templateUrl: './breadcrumb.html',
})
export class BreadcrumbComponent {
  @Input()
  breadcrumbs: IBreadcrumb[];
}
