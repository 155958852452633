import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { JWTService } from '~/services/jwt.service';

const exceptions = [
  'https://maps.googleapis.com/maps/api/geocode/json',
  'https://maps.googleapis.com/maps/api/timezone/json',
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private JWT: JWTService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    if (_.some(exceptions, e => e === req.url)) {
      return next.handle(req);
    }
    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + this.JWT.getJWT()),
    });
    return next.handle(authReq);
  }
}
