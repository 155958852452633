<div *pxcHasRight='"tab_stats"' class="pxc-page">
  <div class="flex my-2 f-m-h-1">
    <pxc-month-picker [(ngModel)]="month"> </pxc-month-picker>
    <pxc-dropdown [(ngModel)]="status" [options]="statuses"> </pxc-dropdown>
    <pxc-dropdown [(ngModel)]="module" [options]="modules"> </pxc-dropdown>
    <pxc-companies-typeahead [(ngModel)]="company"></pxc-companies-typeahead>
    <pxc-button [loading]="loading$" (click)="getStats()">Get Stats</pxc-button>
  </div>
  <pxc-sortable-table [filter]="filter" (onFilterChange)="onFilterChange($event)" [columns]="columns">
    <tbody *ngIf="!(loading$ | async) && stats$ | async as stats">
      <tr *ngIf="stats.total as total">
        <td><strong>Total</strong></td>
        <td><strong>{{total.visitCount}}</strong></td>
        <td><strong>{{total.ticketCount}}</strong></td>
        <td><strong>{{total.total}}</strong></td>
        <td><strong>{{total.smsVisitCount}}</strong></td>
        <td><strong>{{total.smsTicketCount}}</strong></td>
        <td><strong>{{total.smsCount}}</strong></td>
        <td><strong>{{total.acuantDuplex}}</strong></td>
      </tr>
      <tr *ngFor="let company of stats.data">
        <td><a routerLink="/companies/{{company.id}}">{{company.name}}</a></td>
        <td>{{company.visitCount}}</td>
        <td>{{company.ticketCount}}</td>
        <td>{{company.total}}</td>
        <td>{{company.smsVisitCount}}</td>
        <td>{{company.smsTicketCount}}</td>
        <td>{{company.smsCount}}</td>
        <td>{{company.acuantDuplex}}</td>
      </tr>
    </tbody>
  </pxc-sortable-table>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
</div>
