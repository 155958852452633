import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

const status = {
  IN_TRIAL: 'In Trial',
  SUGGESTED: 'Suggested',
  INVOICED: 'Invoiced',
  INVOICED_OTHER_COMPANY: 'Invoiced to another company',
  BLOCKED: 'Blocked',
  CANCELLED: 'Cancelled',
  EXPIRED: 'Expired',
  CHARGED_BUT_NOT_ACTIVATED: 'Charged but not activated',
  ACTIVATED_BUT_NOT_CHARGED: 'Activated but not charged'
};

@Pipe({
  name: 'status',
})
export class StatusPipe implements PipeTransform {
  transform(input: string, trialEndAt: string) {
    if (!input) {
      return '';
    }
    if (input === 'IN_TRIAL' && trialEndAt) {
      if (moment(trialEndAt).isBefore(moment())) {
        return status['EXPIRED'];
      }
    }
    return `${status[input]}`;
  }
}
