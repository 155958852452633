<div class="u-flex align-items-center">
  <div class="c-heading c-heading--m u-color-neutral-darker" *ngIf="!expired">
    {{daysLeft}} days left
  </div>

  <div class="c-heading c-heading--m u-color-warning" *ngIf="expired">
    Expired
  </div>

  <button id="extend-trial-button" class="c-button c-button--s u-margin-left-s">Extend Trial</button>
  <hl-popover
    #popoverElement
    element="#extend-trial-button"
    [options]="popoverOptions"
    trigger="click"
    closeTrigger="mouseleave"
  >
    <div class="u-flex u-flex-align-items-center">
      <input
        class="c-input c-input--s"
        type="number"
        style="max-width: 150px;"
        (keydown)="onKeyDown($event)"
        [(ngModel)]="daysToAdd"
        (ngModelChange)="updateEndDate()"
      />
      <div class="u-margin-left-s">
        <pxc-button type="primary" [loading]="loading$" (click)="onExtendInternal()">Extend</pxc-button>
      </div>
    </div>
    <div class="u-text-xs u-color-neutral u-margin-top-xs">Until {{endAt | time:'LL'}}</div>
  </hl-popover>
</div>
