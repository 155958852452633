<div *pxcHasRight='"tab_companies"' class="pxc-page">
  <div class="d-flex justify-content-between mb-2">
    <form (ngSubmit)="loadCompanies()" class="form-inline">
      <input
        class="form-control mr-2"
        type="text"
        [(ngModel)]="filter.q"
        name="searchTerm"
        placeholder="Filter companies..."
      />
      <pxc-button (click)="loadCompanies()" [loading]="loading$">Filter</pxc-button>
    </form>
    <pxc-button routerLink="create"
      ><i class="fas fa-plus"></i> <span class="u-margin-left-2xs">Create company</span></pxc-button
    >
  </div>
  <pxc-sortable-table [filter]="filter" (onFilterChange)="onFilterChange($event)" [columns]="columns">
    <tbody *ngIf="!(loading$ | async) && (companies$ | async).companies">
      <tr *ngFor="let company of (companies$ | async).companies">
        <td class="nowrap">{{company.id}}</td>
        <td><img *ngIf="company.countryCode" src="assets/img/flag/{{company.countryCode.toLowerCase()}}.png" /></td>

        <td><a class="color-primary" routerLink="{{company.id}}">{{company.name}}</a></td>
        <td>{{company.domain}}</td>
        <td>{{company.billing.status}}</td>
        <td>
          <pxc-pack-icon-list [featureLists]="company.billing.featureLists"></pxc-pack-icon-list>
        </td>
        <td>{{company.checkinsLast30Days}}</td>
        <td>{{company.userCount}}</td>
        <td>{{company.countryCode}}</td>
        <td>{{company.createdAt | time:'L'}}</td>
      </tr>
    </tbody>
  </pxc-sortable-table>
  <div class="mt-2">
    <ngb-pagination
      *ngIf="!(loading$ | async) && filter && total > limit"
      [collectionSize]="total"
      [(page)]="filter.page"
      [pageSize]="filter.pageSize"
      [maxSize]="10"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="onPageChange($event)"
    ></ngb-pagination>
  </div>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
</div>
