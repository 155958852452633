<div class="alert alert-warning" *ngIf="!state.extender.client.isRunningLatestVersion">
  The client is running version {{ state.extender.client.version }}, the latest version is
  {{ state.extender.client.latestInstallerVersion }}.
  <a href="https://api.fastpace.io/downloads/clients/{{ state.extender.client.type }}"
    >Download the latest {{ state.extender.client.type }} client.</a
  >
</div>
<ng-template #online>
  <div class="text-success mb-2">
    The extender is online. Last heartbeat received
    {{ state.extender.client.lastHeartbeat | date: 'mediumDate':'UTC' }}
    {{ state.extender.client.lastHeartbeat | date: 'mediumTime':'UTC' }} UTC.
  </div>
</ng-template>

<pxc-field label="Last Heartbeat">
  {{ state.extender.client.lastHeartbeat }}
</pxc-field>

<pxc-field label="Last Halibut Connection">
  {{ state.extender.client.lastUp }}
</pxc-field>

<pxc-field label="Version">
  {{ state.extender.client.version }}
</pxc-field>
<pxc-field label="Last used IP address">
  {{ state.extender.client.lastUsedIPAddress }}
</pxc-field>

<pxc-field label="Thumbprint">
  <code>{{ state.extender.client.thumbprint }}</code>
</pxc-field>
<pxc-field label="Endpoint">
  <code>{{ state.extender.client.endpoint }}</code>
</pxc-field>

<button
  type="menu"
  class="c-button c-button--primary c-button--s"
  [disabled]="!canSubmit"
  (click)="refreshClientSettings()"
>
  Refresh client connection settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
</button>
<div class="c-helper">
  Refreshes connetion details, sets up the heartbeats, corrects the base url if wrong. Usually only used when something
  went wrong during setup and the client isn't connecting fully yet.
</div>
