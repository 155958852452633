<pxc-loading [loading]="loading"></pxc-loading>
<div *ngIf="!loading">
  <form (submit)="submit()">
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Badge Type (QR Code)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="qrCodeBagdeTypeOption"
            [options]="badgeTypes"
            key="id"
            label="name"
            name="qrCodeBagdeTypeOption"
            allowNull="true"
            disabled=""
          ></pxc-obj-dropdown>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Badge Type (Access Card Manager)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="cardBadgeTypeOption"
            [options]="badgeTypes"
            key="id"
            label="name"
            name="cardBadgeTypeOption"
            allowNull="true"
          ></pxc-obj-dropdown>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-startm">
          <div class="c-list c-list--bare u-text-m">
            <input
              id="usesVisitorType"
              class="c-checkbox c-checkbox--m"
              type="checkbox"
              [checked]="onguardSettings.usesVisitorType"
              name="usesVisitorType"
              disabled=""
            />
            <label class="c-checkbox__skin" for="usesVisitorType"></label>
            &nbsp;<label class="c-label c-label--checkbox c-label--m" for="usesVisitorType">Uses Visitor Type</label>
          </div>
        </div>
      </div>
    </div>
    <div class="l-grid" *ngIf="segmentationSettings?.segmentationEnabled">
      <div class="l-grid__cell l-grid__cell--6">
        <div class="l-form__field">
          <label class="c-label">Access Level Segment</label>
          <input
            type="text"
            class="c-input c-input--s"
            [(ngModel)]="accessLevelSegment?.name || onguardSettings.accessLevelSegmentId"
            name="accessLevelSegment"
            disabled=""
          />
        </div>
      </div>
      <div class="l-grid__cell l-grid__cell--6">
        <div class="l-form__field">
          <label class="c-label">Cardholder Segment</label>
          <input
            type="text"
            class="c-input c-input--s"
            [(ngModel)]="cardholderSegment?.name || onguardSettings.cardholderSegmentId"
            name="cardholderSegment"
            disabled=""
          />
        </div>
      </div>
    </div>
    <hr />
    <div class="l-form__field">
      <label class="c-label">Reader IN filter</label>
      <div class="l-grid">
        <div class="l-grid__cell l-grid__cell--11">
          <pxc-obj-dropdown
            [(ngModel)]="readerOptionIn"
            [options]="readerOptions"
            name="readerOptionIn"
            key="uniqueId"
            label="displayName"
          ></pxc-obj-dropdown>
        </div>
        <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
          <hl-icon
            icon="add-24"
            (click)="addPanelDeviceIn()"
            [ngClass]="{ 'u-color-neutral-light': !canAddPanelDeviceIn() }"
          ></hl-icon>
        </div>
      </div>
      <div class="c-helper">
        To allow visitor events from OnGuard to be sent to our servers please select at least one reader for IN
        movements.
      </div>
    </div>
    <div class="l-form__field">
      <label class="c-label">Reader OUT filter</label>
      <div class="l-grid">
        <div class="l-grid__cell l-grid__cell--11">
          <pxc-obj-dropdown
            [(ngModel)]="readerOptionOut"
            [options]="readerOptions"
            name="readerOptionOut"
            key="uniqueId"
            label="displayName"
          ></pxc-obj-dropdown>
        </div>
        <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
          <hl-icon
            icon="add-24"
            (click)="addPanelDeviceOut()"
            [ngClass]="{ 'u-color-neutral-light': !canAddPanelDeviceOut() }"
          ></hl-icon>
        </div>
      </div>
      <div class="c-helper">
        To allow visitor events from OnGuard to be sent to our servers please select at least one reader for OUT
        movements.
      </div>
    </div>

    <div
      *ngIf="readerFilterIn.length > 0 || readerFilterOut.length > 0"
      class="l-stack c-box u-padding-all-none u-margin-bottom-s"
    >
      <div class="l-stack__item">
        <div class="c-stacked-list c-stacked-list--s">
          <ul class="c-stacked-list__list">
            <li *ngFor="let reader of readerFilterIn" class="c-stacked-list__item">
              <a class="c-stacked-list__inner c-stacked-list__inner--interactive" (click)="removePanelDeviceIn(reader)">
                <div class="c-stacked-list__inner-start">IN: {{ reader.name }}</div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
            <li *ngFor="let reader of readerFilterOut" class="c-stacked-list__item">
              <a
                class="c-stacked-list__inner c-stacked-list__inner--interactive"
                (click)="removePanelDeviceOut(reader)"
              >
                <div class="c-stacked-list__inner-start">OUT: {{ reader.name }}</div>
                <div class="c-stacked-list__inner-end">
                  <span class="c-icon c-icon--m">
                    <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Map custom fields to OnGuard fields</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-switch
            [(ngModel)]="mapCustomFields"
            name="mapCustomFields"
            (ngModelChange)="switchEnableCustomFieldMapping()"
          ></pxc-switch>
        </div>
      </div>
    </div>
    <div class="l-grid" *ngIf="mapCustomFields">
      <div class="l-grid__cell l-grid__cell--4">
        <pxc-obj-dropdown
          [(ngModel)]="customFieldOption"
          [options]="customFieldOptions"
          name="customFieldOptions"
          key="id"
          label="name"
        >
        </pxc-obj-dropdown>
        <div class="c-helper">Select a Proxyclick custom field</div>
      </div>
      <div class="l-grid__cell l-grid__cell--3">
        <pxc-obj-dropdown
          [(ngModel)]="typeOption"
          [options]="typeOptions"
          name="typeOption"
          key="typeName"
          label="typeName"
          (ngModelChange)="fieldTypeOptionChanged()"
        ></pxc-obj-dropdown>
        <div class="c-helper">Select the OnGuard field type you want to map it to.</div>
      </div>
      <div class="l-grid__cell l-grid__cell--4">
        <pxc-obj-dropdown
          [(ngModel)]="propertyOption"
          [options]="typePropertyOptions[typeOption.typeName] || []"
          name="propertyOption"
          key="name"
          label="name"
        ></pxc-obj-dropdown>
        <div class="c-helper">Select the OnGuard property name you want to map it to.</div>
      </div>
      <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
        <hl-icon icon="add-24" (click)="addMapping()" [ngClass]="{ 'u-color-neutral-light': !canAddMapping() }">
        </hl-icon>
      </div>

      <div *ngIf="customFieldMappings.length > 0" class="l-stack c-box u-padding-all-none u-margin-bottom-s">
        <div class="l-stack__item">
          <div class="c-stacked-list c-stacked-list--s">
            <ul class="c-stacked-list__list">
              <li *ngFor="let customFieldMapping of customFieldMappings" class="c-stacked-list__item">
                <a
                  class="c-stacked-list__inner c-stacked-list__inner--interactive"
                  (click)="removeMapping(customFieldMapping.customField.id)"
                >
                  <div class="c-stacked-list__inner-start">
                    {{ customFieldMapping.customField.name }} =>
                    <code>{{ customFieldMapping.fieldType.typeName }}.{{ customFieldMapping.fieldProperty.name }}</code>
                  </div>
                  <div class="c-stacked-list__inner-end">
                    <span class="c-icon c-icon--m">
                      <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
      Save settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
      <hl-icon
        *ngIf="submitting === false && errors === undefined"
        icon="checkmark-circle--color-24"
        width="24"
        height="24"
      ></hl-icon>
    </button>
  </form>
  <h3 *ngIf="errors">Errors during update</h3>
  <div class="u-color-danger" *ngIf="errors">
    <h4>{{ errors.title }}</h4>
    <dl>
      <ng-container *ngFor="let item of errors.errors | keyvalue">
        <dt>{{ item.key }}</dt>
        <dt>
          <ul>
            <li *ngFor="let errorItem of item.value">
              {{ errorItem }}
            </li>
          </ul>
        </dt>
      </ng-container>
    </dl>
    Trace id <code>{{ errors.traceId }}</code>
  </div>
</div>
