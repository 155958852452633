import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdminUsersActions } from '~/store/admin-users/admin-users.actions';
import { IAppState } from '~/store/app-state';
import { ObserverController } from '~/utils/observer-controller';

@Component({
  selector: 'pxc-admin-users',
  templateUrl: './admin-users.html',
})
export class AdminUsersComponent extends ObserverController implements OnInit {
  constructor(private store: Store<IAppState>, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  adminUsers$ = this.store.select('admin-users', 'value');
  loading$ = this.store.select('admin-users', 'loading');

  ngOnInit() {
    super.ngOnInit();
    this.store.dispatch(AdminUsersActions.Load());
  }
}
