import { Component, Input, OnInit } from '@angular/core';
import { ICompany, IFeature } from '@proxyclick/data-model';
import { tap } from 'rxjs/operators';
import { CompaniesService } from '~/services/companies.service';
import { RightService } from '~/services/rights.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { BillingActions } from '~/store/billing/billing.actions';
import { AppStore } from '~/store/store';

@Component({
  selector: 'pxc-features-selector',
  templateUrl: './features-selector.html',
})
export class FeaturesSelectorComponent implements OnInit {
  @Input() company: ICompany;
  @Input() module: 'VM' | 'SD';
  @Input() featuresList;
  @Input() features;

  readOnly = true;

  constructor(
    private Companies: CompaniesService,
    private Notifications: NotificationsService,
    private Right: RightService,
    private store: AppStore
  ) {}

  ngOnInit() {
    this.readOnly =
      this.module === 'VM' ? !this.Right.hasRight('company_vm_billing') : !this.Right.hasRight('company_sd_billing');
  }

  updateFeatures(company: ICompany, module: 'VM' | 'SD', featuresList: IFeature) {
    return this.Companies.setFeatures({
      companyId: company.id,
      featureListId: featuresList.id,
    })
      [module.toLowerCase()]()
      .pipe(
        tap(() => this.Notifications.success('Succesfully set features')),
        tap(() =>
          this.store.dispatch(
            BillingActions.ActivateFeatureListDone({ companyId: company.id, featureListId: featuresList.id })
          )
        )
      );
  }
}
