import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '~/store/app-state';
import { DomainUpdateClearbit } from '~/store/domains/domains.reducer';
import { IDomain } from '../../../../../../../shared/models';

@Component({
  selector: 'pxc-domain-clearbit',
  templateUrl: './domain-clearbit.html',
})
export class DomainClearbitComponent {
  domain$ = this.store.select('domain', 'value');
  loading$ = this.store.select('domain', 'loading');
  loadingUpdate$ = this.store.select('domain/update-clearbit', 'loading');

  constructor(private store: Store<IAppState>) {}

  updateClearbit(domain: IDomain) {
    this.store.dispatch(DomainUpdateClearbit.Load(domain));
  }
}
