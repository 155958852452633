<div *ngIf="company">
  <pxc-field label="Kiosk Partner">
    <form class="form-inline" #partnerForm="ngForm">
      <pxc-partners-dropdown [(ngModel)]="kioskPartner" [readOnly]="readOnly.options" name="kioskPartner">
      </pxc-partners-dropdown>
      <pxc-button
        *ngIf="partnerForm.controls.kioskPartner && partnerForm.controls.kioskPartner.dirty"
        class="u-margin-left-xs"
        [onClick]="updateKioskPartner(company, kioskPartner)"
      >
        Update
      </pxc-button>
    </form>
  </pxc-field>
  <div *ngIf="kioskParams$ | async as kioskParams">
    <pxc-field label="Print type">
      <div style="max-width: 300px">
        <pxc-dropdown
          [(ngModel)]="kioskParams.printType"
          [readOnly]="readOnly.options"
          [options]="printTypeOptions"
          [onChange]="updatePrintType(company)"
        ></pxc-dropdown>
      </div>
    </pxc-field>
    <pxc-field label="Kiosk Version">
      <div style="max-width: 300px">
        <pxc-dropdown
          [(ngModel)]="kioskParams.showAll"
          [readOnly]="readOnly.options"
          [options]="kioskVersionOptions"
          [onChange]="updateKioskType(company)"
        ></pxc-dropdown>
      </div>
    </pxc-field>
    <pxc-field label="Minimal name suggestion length for kiosk lookup">
      <div style="max-width: 300px">
        <pxc-dropdown
          [(ngModel)]="kioskParams.nameSuggestionLength"
          [readOnly]="readOnly.options"
          [options]="nameSuggestionLengthOptions"
          [onChange]="updateNameSuggestionLength(company)"
        ></pxc-dropdown>
      </div>
    </pxc-field>
    <pxc-field *ngIf="kioskParams.showAll" label="Infopack enabled">
      <pxc-switch
        [(ngModel)]="kioskParams.infopackEnabled"
        [readOnly]="readOnly.options"
        [onChange]="updateInfopack(company)"
      ></pxc-switch>
    </pxc-field>
  </div>
  <div *ngIf="vmParams$ | async as vmParams">
    <pxc-field label="ID Match enabled">
      <div class="form-inline">
        <pxc-switch
          [(ngModel)]="vmParams.idScanEnabled"
          [readOnly]="readOnly.options"
          [onChange]="updateIdScan(company)"
        ></pxc-switch>
        <label *ngIf="vmParams.idScanEnabled" class="ml-4"
          >Threshold
          <input
            class="ml-2 form-control input-xs"
            type="number"
            min="0"
            max="100"
            [disabled]="readOnly.options"
            [(ngModel)]="vmParams.idScanThreshold"
            (ngModelChange)="updateThreshold($event)"
          />
        </label>
      </div>
    </pxc-field>
    <pxc-field label="Custom Field Limit">
      <div style="max-width: 300px">
        <pxc-dropdown
          [(ngModel)]="vmParams.maxCustomFields"
          [readOnly]="readOnly.options"
          [options]="maxCustomFieldsOptions"
          [onChange]="updateMaxCustomFields(company)"
        ></pxc-dropdown>
      </div>
    </pxc-field>
    <pxc-field
      *ngIf="!(loadingUsers$ | async) && !(loadingKioskParams$ | async) && (kioskParams$ | async) as kioskParams"
      label="Default Host"
    >
      <div style="max-width: 300px">
        <pxc-users-typeahead
          [(ngModel)]="kioskParams && kioskParams.defaultHost"
          (ngModelChange)="updateDefaultHost(kioskParams.defaultHost)"
          placeholder="Search for a user"
          [companyId]="company.id"
        ></pxc-users-typeahead>
      </div>
    </pxc-field>
  </div>
  <div *ngIf="companyParams$ | async as companyParams">
    <pxc-field label="Show Intercom Messenger">
      <div class="form-inline">
        <pxc-switch [(ngModel)]="companyParams.showMessenger" [onChange]="updateCompanyParams(company, companyParams)">
        </pxc-switch>
      </div>
    </pxc-field>
  </div>
  <div *ngIf="companyParams$ | async as companyParams">
    <pxc-field label="Enable emergency for users">
      <div class="form-inline">
        <pxc-switch [(ngModel)]="companyParams.emergencyUsers" [onChange]="updateCompanyParams(company, companyParams)">
        </pxc-switch>
      </div>
    </pxc-field>
  </div>
  <div *ngIf="vmInvitationalEmailParams$ | async as vmInvitationalEmailParams">
    <pxc-field label="Show map for invitational email">
      <div class="form-inline">
        <pxc-switch
          [(ngModel)]="vmInvitationalEmailParams.showMap"
          [onChange]="updateVmInvitationalEmailParams(company.id, vmInvitationalEmailParams)"
        >
        </pxc-switch>
      </div>
    </pxc-field>
  </div>
  <pxc-field label="Link to invite new users">
    <button id="inviteLinkBtn" (click)="copyInviteLinkToClipboard()" class="c-button c-button-primary c-button--s">
      Get link
    </button>
  </pxc-field>
</div>
