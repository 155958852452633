import { createAction } from '@ngrx/store';
import { IIntegration } from '@proxyclick/data-model';
import { createAsyncLoadActions, createAsyncLoadReducer, PayloadAction } from '~/utils/async-generator';

export const IntegrationsActions = createAsyncLoadActions<void, IIntegration[]>('integrations');
export const integrationsReducer = createAsyncLoadReducer(IntegrationsActions);

export const IntegrationActions = {
  NEW: createAction('integration/new'),
  UPDATE: createAction('integration/update', (payload: IIntegration) => ({ payload })),
  CREATE: createAction('integration/create', (payload: IIntegration) => ({ payload })),
  DELETE: createAction('integration/delete', (payload: IIntegration) => ({ payload })),

  ...createAsyncLoadActions<string, IIntegration>('integration'),
};

const integrationBaseReducer = createAsyncLoadReducer(IntegrationActions);

function integrationExtraReducer(state = null, action: PayloadAction<IIntegration>) {
  switch (action.type) {
    case IntegrationActions.CREATE.type:
    case IntegrationActions.UPDATE.type:
    case IntegrationActions.NEW.type:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export const integrationReducer = (state, action) => {
  return integrationExtraReducer(integrationBaseReducer(state, action), action);
};
