import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '~/store/app-state';
import { SearchAction } from '~/store/search/search.reducer';

@Component({
  selector: 'pxc-search-results',
  templateUrl: './search-results.html',
  styleUrls: ['./search-results.scss'],
})
export class SearchResultsComponent {
  constructor(private elem: ElementRef, private store: Store<IAppState>) {}

  @Input()
  search;

  @Input()
  loadingCompanies;

  @Input()
  loadingUsers;

  onClick() {
    this.search = null;
    this.store.dispatch(SearchAction.Load(null));
  }

  @HostListener('document:click')
  onExternalClick() {
    if (!this.elem.nativeElement.contains(event.target)) {
      this.search = null;
    }
    event.stopPropagation();
  }
}
