import { Injectable, Injector } from '@angular/core';
import { ILdapAttribute, ILdapData } from '@proxyclick/data-model';
import * as _ from 'lodash';
import { of as observableOf } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseService } from '~/services/base.service';
import { DownloadService } from '~/services/download.service';

const ldapFields = ['FIRSTNAME', 'LASTNAME', 'EMAIL', 'PHONE', 'MOBILE', 'LANGUAGE'];
const ldapOrder = {
  FIRSTNAME: 0,
  LASTNAME: 1,
  EMAIL: 2,
  PHONE: 3,
  MOBILE: 4,
  LANGUAGE: 5,
};

@Injectable()
export class UserProvisioningService extends BaseService {
  constructor(injector: Injector, private download: DownloadService) {
    super(injector);
  }

  ldap() {
    return {
      getAll: () => this.apiClient.AdminUsersLdap().doGet(),
      getByCompanyId: id =>
        id === 'new'
          ? observableOf(this.getDefaultLdap())
          : this.apiClient.ApplicationCompanyUsersLdap(id).doGet().pipe(map(transformLdap)),
      save: data => this.apiClient.ApplicationCompanyUsersLdap(data.company.id).doPut(data),
      delete: companyId => this.apiClient.ApplicationCompanyUsersLdap(companyId).doDelete(),
    };
  }

  json() {
    return {
      getAll: () => this.apiClient.AdminUsersJson().doGet(),
      getByCompanyId: id =>
        id === 'new' ? observableOf(this.getDefaultJson()) : this.apiClient.ApplicationCompanyUsersJson(id).doGet(),
      save: (companyId, data) => this.apiClient.ApplicationCompanyUsersJson(companyId).doPut(data),
      delete: companyId => this.apiClient.ApplicationCompanyUsersJson(companyId).doDelete(),
    };
  }

  testConnection(companyId: string) {
    return this.apiClient.ApplicationCompanyUsersLdapTestConnection(companyId).doPost();
  }

  extractUsers(companyId: string) {
    return this.apiClient
      .ApplicationCompanyUsersLdapExtract(companyId)
      .doGet(
        {},
        {
          responseType: 'blob',
        }
      )
      .pipe(
        tap(data => {
          this.download.downloadFile(data, 'extract.xls', 'application/vnd.ms-excel');
        })
      );
  }

  save(data: ILdapData) {
    return this.apiClient.ApplicationCompanyUsersLdap(data.company.id).doPut(data);
  }

  sync(companyId: string) {
    return {
      start: (syncNow: boolean = false) =>
        this.apiClient.ApplicationCompanyUsersLdapStartSynchronization(companyId).doPost({
          syncNow,
        }),
      stop: () => this.apiClient.ApplicationCompanyUsersLdapStopSynchronization(companyId).doPost(),
    };
  }

  getDefaultLdap() {
    return {
      secured: false,
      authenticationType: 'ANONYMOUS',
      attributes: ldapFields.map(getDefaultAttribute),
    };
  }

  getDefaultJson() {
    return {
      company: null,
      companies: null,
      fields: ['FIRSTNAME', 'LASTNAME', 'EMAIL'],
    };
  }
}

function getDefaultAttribute(name: string): ILdapAttribute {
  return {
    defaultValue: null,
    field: name,
    name: '',
  };
}

function transformLdap(ldap: ILdapData) {
  ldap.attributes = transformAttributes(ldap.attributes);
  return ldap;
}

function transformAttributes(attributes: ILdapAttribute[]): ILdapAttribute[] {
  for (const field of ldapFields) {
    if (!_.find(attributes, a => a.field === field)) {
      attributes.push(getDefaultAttribute(field));
    }
  }
  return attributes.sort((a, b) => ldapOrder[a.field] - ldapOrder[b.field]);
}
