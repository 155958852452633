import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { IFeatureList } from '@proxyclick/data-model';
import { IDiffFeatureList } from '~/store/feature-lists/feature-lists.selector';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-select-one-feature-list-form',
  templateUrl: './select-one-feature-list-form.html',
  providers: controlValueAccessor(SelectOneFeatureListFormComponent),
})
export class SelectOneFeatureListFormComponent implements ControlValueAccessor {
  selectedFeatureList: IFeatureList;

  @Input()
  diffCurrentAndTargetMandatoryFeatureLists: IDiffFeatureList[];

  @Input()
  targetFeatureLists: IFeatureList[];

  onChange: (selectedFeatureList: IFeatureList) => void;

  onInternalChange(selectedFeatureListId: number) {
    const selectedFeatureList = this.targetFeatureLists.find(
      targetFeatureList => targetFeatureList.id === selectedFeatureListId
    );
    this.onChange(selectedFeatureList);
  }

  writeValue(selectedFeatureList: IFeatureList): void {
    this.selectedFeatureList = selectedFeatureList;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    return;
  }

  setDisabledState?(isDisabled: boolean): void {
    return;
  }

  getClassMap() {
    return {};
  }
}
