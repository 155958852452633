import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AdminCompaniesService } from '~/services/admin-companies.service';
import { AuthService } from '~/services/auth.service';
import { CompaniesService } from '~/services/companies.service';
import { FastPaceService } from '~/services/fastpace.service';
import { KioskService } from '~/services/kiosk.service';
import { StatsService } from '~/services/stats.service';
import { UserService } from '~/services/users.service';
import { CONFIG_LOADED, USER_LOGGED_IN, USER_LOGGED_IN_ACTION } from '~/store/app-state';
import { CountriesActions } from '~/store/regions/regions.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';
import { FeatureActions as FeatureListsActions } from '../feature-lists/feature-lists.actions';
import {
  AdminCompanyActions,
  CompaniesActions,
  CompanyActions,
  CompanyAddress,
  CompanyAdminActions,
  CompanyDelete,
  CompanyIdScanStats,
  CompanyKioskPartners,
  CompanyModules,
  CompanyParams,
  CompanySAML,
  CompanyStats,
  CompanyTermsAndConditions,
  CompanyUserProvisioning,
  CompanyUsersActions,
  CompanyVmInvitationEmailParams,
  KioskParamsActions,
  VmParamsActions,
} from './companies.actions';

@Injectable()
export class CompaniesEffects {
  constructor(
    private actions$: Actions<PayloadAction>,
    private Companies: CompaniesService,
    private Users: UserService,
    private Kiosk: KioskService,
    private Stats: StatsService,
    private Auth: AuthService,
    private AdminCompanies: AdminCompaniesService,
    private fastPace: FastPaceService
  ) {}

  loadAll$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompaniesActions, filterData => this.Companies.getCompanies(filterData))
  );

  load$ = createEffect(() => generateAsyncEffect(this.actions$, CompanyActions, id => this.Companies.getCompany(id)));

  loadUsers$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyUsersActions, id => this.Users.getUsersForCompany(id))
  );

  loadAdmins$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyAdminActions, id => this.Users.getUserGroupMembers(id, 2))
  );

  modules$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyModules, id => this.Companies.getCompanyModules(id))
  );

  features$ = createEffect(() =>
    generateAsyncEffect(this.actions$, FeatureListsActions, () => this.Companies.getAllFeatures())
  );

  kioskParams$ = createEffect(() =>
    generateAsyncEffect(this.actions$, KioskParamsActions, companyId => this.Kiosk.getKioskParams(companyId))
  );

  vmParams$ = createEffect(() =>
    generateAsyncEffect(this.actions$, VmParamsActions, companyId => this.Companies.getVmParams(companyId))
  );

  stats$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyStats, filterData => this.Stats.getAdminStats(filterData))
  );

  idScanStats$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyIdScanStats, filterData => this.Stats.getIdScanStats(filterData))
  );

  address$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyAddress, companyId => this.Companies.getAddress(companyId))
  );

  kioskPartner$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyKioskPartners, companyId => this.Companies.getKioskPartner(companyId))
  );

  userProvisioning$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyUserProvisioning, companyId =>
      this.Companies.getUserProvisioning(companyId)
    )
  );

  saml$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanySAML, companyId => this.Companies.getSAML(companyId))
  );

  termsAndConditions$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyTermsAndConditions, companyId =>
      this.Companies.getTermsAndConditions(companyId)
    )
  );

  params$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyParams, companyId => this.Companies.getCompanyParams(companyId))
  );

  vmInvitationalEmail$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyVmInvitationEmailParams, companyId =>
      this.Companies.getVmInvitationEmailParams(companyId)
    )
  );

  adminCompany$ = createEffect(() =>
    generateAsyncEffect(this.actions$, AdminCompanyActions, companyId => this.AdminCompanies.getCompanyById(companyId))
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.Load.type),
      mergeMap(action => [
        CompanyAdminActions.Load(action.payload),
        CompanyModules.Load(action.payload),
        KioskParamsActions.Load(action.payload),
        VmParamsActions.Load(action.payload),
        CompanyKioskPartners.Load(action.payload),
        CompanyUserProvisioning.Load(action.payload),
        CompanySAML.Load(action.payload),
        CompanyTermsAndConditions.Load(action.payload),
        CompanyParams.Load(action.payload),
        AdminCompanyActions.Load(action.payload),
        CompanyIdScanStats.Load({
          companyId: action.payload,
          from: moment().subtract(1, 'month'),
          to: moment(),
        }),
        CountriesActions.Load(),
        CompanyStats.Load({
          companyId: action.payload,
          sortBy: 'period',
          sort: 'desc',
        }),
        CompanyUsersActions.Load({
          companyId: action.payload,
          withMetaData: true,
          page: 1,
          pageSize: 100,
        }),
        CompanyVmInvitationEmailParams.Load(action.payload),
      ])
    )
  );

  allFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_LOGGED_IN),
      map(() => FeatureListsActions.Load())
    )
  );

  initApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CONFIG_LOADED),
      filter(() => this.Auth.isUserLoggedIn()),
      map(() => USER_LOGGED_IN_ACTION)
    )
  );

  delete$ = createEffect(() =>
    generateAsyncEffect(this.actions$, CompanyDelete, companyId => this.Companies.deleteCompany(companyId))
  );
}
