import { Injectable, Injector } from '@angular/core';
import { IVisit } from '@proxyclick/data-model';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable()
export class VisitService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  getVisit(companyId: string, date: string, meetingId: string, visitorId: string): Observable<IVisit> {
    return this.apiClient.ApplicationVisitorManagementVisit(companyId, date, meetingId, visitorId).doGet();
  }
}
