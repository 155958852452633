import { Component, OnInit, OnDestroy } from '@angular/core';
import { empty, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { ExtenderService } from '~/services/extender.service';
import { ErrorResponse, IExtenderTypeResponseV1 } from '~/store/extender/extender.models';
import { NewExtenderState } from './new-extender.state';

@Component({
  selector: 'app-new-extender',
  templateUrl: './new.extender.component.html',
})
export class NewExtenderComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private extenderService: ExtenderService) {}

  ngOnInit(): void {
    this.extenderTypeSubscription = this.extenderService.listExtenderTypes().subscribe(o => {
      this.extenderTypes = o;
      this.extenderTypeOptions = o.map(o => {
        return { value: o.id, label: o.name };
      }).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.extenderTypeSubscription.unsubscribe();
  }

  state: NewExtenderState = new NewExtenderState();
  loading: boolean = true;

  extenderTypes: IExtenderTypeResponseV1[] = [];
  extenderTypeOptions: IOption[] = [];
  private extenderTypeSubscription: Subscription;

  get selectedSystem() {
    if (this.state.selectedExtenderTypeOption === undefined) {
      return undefined;
    }
    const system = this.extenderTypes.filter(es => es.id === this.state.selectedExtenderTypeOption)[0];
    return system;
  }

  errors: ErrorResponse;

  get canSubmit(): boolean {
    return this.loading !== true && this.submitting !== true && this.state.canSubmit();
  }
  submitting: boolean = false;

  async submit() {
    this.submitting = true;
    this.errors = undefined;

    var request = this.state.createRequest();

    this.extenderService
      .createExtender(request)
      .pipe(this.catchSubmitError())
      .subscribe((observer: { extenderId: string }) => {
        this.submitting = false;
        this.router.navigate(['fastpace', 'extenders', observer.extenderId]);
      });
  }
  catchSubmitError = () => {
    return catchError(error => {
      this.submitting = false;
      if (error && error.status < 500) {
        var errorResponse = error.error as ErrorResponse;
        this.errors = errorResponse;
      } else {
        this.errors = {
          title: error.message,
          errors: undefined,
          status: error.status,
          traceId: error.headers.get('traceId'),
          type: 'Failure',
        };
      }
      return empty();
    });
  };
}
