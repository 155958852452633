import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IUser } from '@proxyclick/data-model';
import { combineLatest as observableCombineLatest, from, of, combineLatest, forkJoin } from 'rxjs';
import { catchError, concatMap, endWith, map, mergeMap, switchMap } from 'rxjs/operators';
import { UserDirectoryService } from '~/services/user-directory.service';
import { UserService } from '~/services/users.service';
import { USER_LOGGED_IN } from '~/store/app-state';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';
import {
  BatchUpdateUserEmails,
  BatchUpdateUserEmailsErrors,
  PxcUserDirectoryActions,
  UpdateHomeLocation,
  UpdateHomeLocationErrors,
  UserActions,
  UserCompanyCloneActions,
  UserDifferenceBetweenCompaniesActions,
  UserEmailActions,
  UserGroupActions,
  UserLinkCompanyActions,
  UserLinkMultipleCompaniesActions,
  UserPasswordActions,
  UsersActions,
  UserUnlinkCompanyActions,
  UserUnlinkFromAllCompaniesActions,
} from './users.actions';

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions<PayloadAction>,
    private usersService: UserService,
    private userDirectoryService: UserDirectoryService
  ) {}

  
  $loadAll = createEffect(() => generateAsyncEffect(this.actions$, UsersActions, filter => this.usersService.getUsers(filter)));

  
  $load = createEffect(() => generateAsyncEffect(this.actions$, UserActions, userId =>
    combineLatest(this.usersService.getUser(userId), this.usersService.getUserCompanies(userId)).pipe(
      mergeMap(([user, companies]) => {
        const observables = companies.map(company =>
          this.usersService.getWatchListAlertUsers(company.id).pipe(
            map(users => {
              return {
                company,
                watchListAlertUsers: users,
              };
            })
          )
        );
        return combineLatest(of(user), forkJoin(observables));
      }),
      map(([user, watchListAlertUsersResults]: [IUser, { company: any; watchListAlertUsers: IUser[] }[]]) => {
        const companiesWithAddedAndRemoved = [];
        const unlinkableCompanies = {
          watchListAlert: [],
          defaultHost: [],
        };

        for (const { company, watchListAlertUsers } of watchListAlertUsersResults) {
          if (company.defaultHostId === user.id) {
            unlinkableCompanies.defaultHost.push(company.id);
          }

          if (watchListAlertUsers.some(watchListAlertUser => watchListAlertUser.id === user.id)) {
            unlinkableCompanies.watchListAlert.push(company.id);
          }

          companiesWithAddedAndRemoved.push({
            addedUserGroups: [],
            removedUserGroups: [],
            ...company,
          });
        }

        return {
          linkedCompanies: companiesWithAddedAndRemoved,
          unlinkableCompanies,
          ...user,
        };
      })
    )
  ));

  
  $updateEmail = createEffect(() => generateAsyncEffect(this.actions$, UserEmailActions, data => this.usersService.updateEmail(data)));

  
  $updatePassword = createEffect(() => generateAsyncEffect(this.actions$, UserPasswordActions, data =>
    this.usersService.updatePassword(data)
  ));

  
  $updatedUser = createEffect(() => this.actions$.pipe(
    ofType(
      UserPasswordActions.Loaded.type,
      UserLinkMultipleCompaniesActions.Loaded.type,
      UserUnlinkFromAllCompaniesActions.Loaded.type,
      PxcUserDirectoryActions.createDone,
      PxcUserDirectoryActions.removeDone
    ),
    map(action => UserActions.Load(action.payload.userId))
  ));

  
  $updatedUserContinued = createEffect(() => this.actions$.pipe(
    ofType(UserEmailActions.Loaded.type),
    map(action => UserActions.Load(action.payload.userId))
  ));

  
  $addUserGroups = createEffect(() => this.actions$.pipe(
    ofType(UserGroupActions.saveUserGroupForCompany),
    mergeMap(action =>
      this.usersService.addUserGroupsForUserAndCompany(action.companyId, action.userGroupId, action.userId)
    ),
    map(() => UserGroupActions.saveUserGroupForCompanyDone())
  ));

  
  $removeUserGroups = createEffect(() => this.actions$.pipe(
    ofType(UserGroupActions.removeUserGroupForCompany),
    mergeMap(action =>
      this.usersService.removeUserGroupsForUserAndCompany(action.companyId, action.userGroupId, action.userId)
    ),
    map(() => UserGroupActions.removeUserGroupForCompanyDone())
  ));

  
  $linkToCompanies = createEffect(() => generateAsyncEffect(this.actions$, UserLinkMultipleCompaniesActions, data =>
    this.usersService.linkToMultipleCompanies(data)
  ));

  
  $linkToCompany = createEffect(() => generateAsyncEffect(this.actions$, UserLinkCompanyActions, data =>
    this.usersService.linkToCompany(data)
  ));

  
  $unlinkFromCompany = createEffect(() => generateAsyncEffect(this.actions$, UserUnlinkCompanyActions, data =>
    this.usersService.unlinkFromCompany(data)
  ));

  
  $unlinkFromAllCompanies = createEffect(() => generateAsyncEffect(this.actions$, UserUnlinkFromAllCompaniesActions, data =>
    this.usersService.unlinkFromAllCompanies(data)
  ));

  
  $init = createEffect(() => this.actions$.pipe(
    ofType(USER_LOGGED_IN),
    map(() =>
      UsersActions.Load({
        q: '',
        pageSize: 200,
        sort: 'desc',
        sortBy: 'lastlog',
      })
    )
  ));

  
  $batchUpdateEmails = createEffect(() => this.actions$.pipe(
    ofType(BatchUpdateUserEmails),
    switchMap(event =>
      this.usersService.updateEmailBatch(event.data) 
         .pipe(map(result => BatchUpdateUserEmailsErrors({ errors: result })),
        )
      )
    ));

  
  $updateHomeLocation = createEffect(() => this.actions$.pipe(
    ofType(UpdateHomeLocation),
    switchMap(event =>
      this.usersService.updateHomeLocation(event).pipe(map(result => UpdateHomeLocationErrors({ errors: result })))
    )
  ));

  
  $removePxcUserDirectory = createEffect(() => this.actions$.pipe(
    ofType(PxcUserDirectoryActions.remove),
    switchMap(action => this.userDirectoryService.removePxcUserDirectory(action.userId)),
    map(data => PxcUserDirectoryActions.removeDone({ payload: { userId: data } }))
  ));

  
  $removeMultiplePxcUserDirectory = createEffect(() => this.actions$.pipe(
    ofType(PxcUserDirectoryActions.removeMultiple),
    switchMap(action => this.userDirectoryService.removeMultiplePxcUserDirectory(action.userIds)),
    map(data => PxcUserDirectoryActions.removeMultipleDone({ payload: { userIds: data } }))
  ));

  
  $createPxcUserDirectory = createEffect(() => this.actions$.pipe(
    ofType(PxcUserDirectoryActions.create),
    switchMap(action => this.userDirectoryService.createPxcUserDirectory(action.userId, action.email)),
    map(data => PxcUserDirectoryActions.createDone({ payload: { userId: data.userId, email: data.email } }))
  ));

  $cloneUsers = createEffect(() => generateAsyncEffect(
    this.actions$,
    UserCompanyCloneActions,
    (data: { originalCompanyId: string; targetCompanyId: string }) =>
      this.usersService.cloneUsersForCompany(data.originalCompanyId, data.targetCompanyId)
  ));

  $userDifference = createEffect(() => generateAsyncEffect(
    this.actions$,
    UserDifferenceBetweenCompaniesActions,
    (data: { originalCompanyId: string; targetCompanyId: string }) =>
      this.usersService.getUserDifferenceBetweenCompanies(data.originalCompanyId, data.targetCompanyId)
  ));
}
