import { Component } from '@angular/core';
import { ICountry } from '@proxyclick/data-model';
import { of as observableOf } from 'rxjs';
import { CompaniesService, ICreateCompanyForm, newCompany } from '~/services/companies.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';

@Component({
  selector: 'pxc-create-company',
  templateUrl: './create-company.html',
})
export class CreateCompanyPageComponent {
  company: ICreateCompanyForm = newCompany;
  countries: ICountry[];
  form;
  breadcrumbs$ = observableOf([
    { label: 'Companies', url: '/companies' },
    { label: 'New company', url: `/create-company` },
  ]);
  vm = true;
  sd = false;

  constructor(private Companies: CompaniesService, private Notifications: NotificationsService) {}

  onSubmit() {
    this.company.modules = this.vm ? ['VM'] : [];
    if (this.sd) {
      this.company.modules.push('SD');
    }
    this.Companies.createAdminCompany(this.company)
      .then(company => {
        this.Notifications.success('The company has been succesfully created');
      })
      .catch(err => {
        this.Notifications.error(`Error while creating company: ${err.error.message}`);
      });
  }
}
