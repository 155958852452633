import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { IAppState } from '~/store/app-state';
import { DomainActions } from '~/store/domains/domains.reducer';
import { IDomainFilter } from '../../../../../shared/filters';
import { IAdminUser } from '../../../../../shared/models';

@Component({
  selector: 'pxc-domains',
  templateUrl: './domains.html',
})
export class DomainsPageComponent implements OnInit {
  domains$ = this.store.select('domains', 'value');
  loading$ = this.store.select('domains', 'loading');
  accountManager: IAdminUser;
  filter: IDomainFilter = {
    page: 1,
    pageSize: 100,
    sort: 'desc',
    sortBy: 'companyCount',
    relationship: null,
  };
  searchTerm = '';
  columns: IOption[] = [
    { label: 'Logo', value: null },
    { label: 'Name', value: 'domain' },
    { label: 'RM', value: 'accountManagerId' },
    { label: 'Checkins last 30 days', value: 'checkinCount' },
    { label: 'MRR', value: 'mrr' },
    { label: 'Locations', value: 'companyCount' },
    { label: 'Employees', value: 'employeeCount' },
    { label: 'Users', value: 'userCount' },
    { label: 'Industry', value: 'industry' },
  ];
  total: number;
  limit: number;
  relationshipOptions: IOption[] = [
    { label: 'Any', value: null },
    { label: 'None', value: 'NONE' },
    { label: 'Established', value: 'ESTABLISHED' },
  ];

  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.loadDomains();

    this.domains$.pipe(filter(a => !!a)).subscribe(domains => {
      this.total = domains.total;
      this.limit = domains.limit;
    });
  }

  loadDomains() {
    if (this.accountManager) {
      this.filter.accountManagerId = this.accountManager.id;
    } else {
      delete this.filter.accountManagerId;
    }
    this.store.dispatch(DomainActions.Load(this.filter));
  }

  onFilterChange(f) {
    this.filter = f;
    this.loadDomains();
  }

  onPageChange(page) {
    this.filter.page = page;
    this.loadDomains();
  }
}
