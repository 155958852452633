import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppPartnerDetailsPageComponent } from '~/pages/app-partners/app-partner-details/app-partner-details.page';
import { AppPartnersPageComponent } from '~/pages/app-partners/app-partners.page';
import { SharedModule } from '~/shared/shared.module';
import { CreateAppPartnerPageComponent } from '~/pages/app-partners/create-app-partner/create-app-partner.page';
import { UserRights } from '../../../../../shared/models';
import { RightGuard } from '~/shared/guards/right.guard';

@NgModule({
  declarations: [AppPartnersPageComponent, AppPartnerDetailsPageComponent, CreateAppPartnerPageComponent],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'partners',
        canActivate: [RightGuard],
        data: {
          right: UserRights.TabPartners,
          title: 'Partners',
        },
        children: [
          {
            path: '',
            component: AppPartnersPageComponent,
            data: {
              title: '',
            },
          },
          {
            path: 'create',
            component: CreateAppPartnerPageComponent,
            data: {
              title: 'Create',
            },
          },
          {
            path: ':partnerId',
            component: AppPartnerDetailsPageComponent,
            data: {
              title: '{{app-partner.value.name}}',
            },
          },
        ],
      },
    ]),
  ],
})
export class AppPartnersModule {}
