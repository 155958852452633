import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { IAppState } from '~/store/app-state';
import { DomainDetailsAction } from '~/store/domains/domains.reducer';

const tabs: IOption[] = [
  { label: 'Companies', value: 'companies' },
  { label: 'Info', value: 'info' },
  { label: 'Clearbit', value: 'clearbit' },
];

@Component({
  selector: 'pxc-domain-details',
  templateUrl: './domain-details.html',
  styleUrls: ['./domain-details.scss'],
})
export class DomainDetailsPageComponent implements OnInit, OnDestroy {
  domain$ = this.store.select('domain', 'value');
  loading$ = this.store.select('domain', 'loading');
  companies$ = this.store.select('domain-companies', 'value');

  breadcrumbs$ = this.domain$.pipe(
    filter(a => !!a),
    map(domain => [
      { label: 'Domains', url: '/domains' },
      { label: domain.domain, url: '.' },
    ])
  );
  tabs = tabs;

  private subs: Subscription[] = [];

  constructor(private store: Store<IAppState>, private route: ActivatedRoute) {}

  ngOnInit() {
    this.subs.push(
      this.route.params.subscribe(params => {
        this.store.dispatch(DomainDetailsAction.Load(params.id));
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
