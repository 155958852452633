<ng-template #rt let-r="result">
  <span class="flex center">
    <img *ngIf="r && r.countryCode" class="mr-2" src="assets/img/flag/{{r.countryCode.toLowerCase()}}.png" />
    {{r.name}} ({{r.id}})
  </span>
</ng-template>
<input
  type="text"
  class="c-input c-input--s"
  name="company"
  [(ngModel)]="company"
  (ngModelChange)="onInternalChange()"
  [ngbTypeahead]="search"
  [inputFormatter]="formatCompanyInput"
  [resultTemplate]="rt"
  placeholder="Company..."
/>
