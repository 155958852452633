import { Component, Input, OnInit } from '@angular/core';
import { ICustomField } from '@proxyclick/data-model';
import { CredentialFormatEntity, CustomFieldEntity } from '@proxyclick/extender-server-brivo-client';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';

@Component({
  selector: 'app-fastpace-company-setup-extenders-brivo',
  templateUrl: './brivo-setup.component.html',
})
export class BrivoSetupComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;
  constructor(private companyService: CompaniesService, private extenderService: ExtenderService) {}
  async ngOnInit(): Promise<void> {
    this.customFields = await this.companyService.getCustomFields(this.setupState.company.id).toPromise();
    this.setCustomFieldOptions();

    this.credentialFormats = (
      await this.extenderService.brivo.credentials.listAllFormats(this.setupState.extender.extenderId)
    ).filter(c => {
      return c.credentialFields.filter(cf => cf.name === 'card_number').length > 0;
    });

    this.brivoCustomFields = await this.extenderService.brivo.customFields.listAll(this.setupState.extender.extenderId);
    this.setBrivoCustomFieldOptions();
  }

  credentialFormats: CredentialFormatEntity[] = [];

  mapCustomFields: boolean;

  brivoCustomFields: CustomFieldEntity[];
  brivoCustomFieldOptions: CustomFieldEntity[] = [];
  brivoCustomFieldOption: CustomFieldEntity | null = null;

  customFields: ICustomField[];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.setupState.brivoCustomFieldMappings = [];
    }
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return this.setupState.brivoCustomFieldMappings.filter(a => a.customField.id === c.id).length === 0;
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = null;
    }
  }

  setBrivoCustomFieldOptions() {
    const options = this.brivoCustomFields.filter(c => {
      //Filter out custom fields already mapped
      return this.setupState.brivoCustomFieldMappings.filter(a => a.brivoCustomField.id === c.id).length === 0;
    });

    this.brivoCustomFieldOptions = options;
    if (this.brivoCustomFieldOptions.length > 0) {
      this.brivoCustomFieldOption = this.brivoCustomFieldOptions[0];
    } else {
      this.brivoCustomFieldOption = null;
    }
  }

  canAddMapping() {
    return (
      this.customFieldOption &&
      this.brivoCustomFieldOption &&
      this.setupState.brivoCustomFieldMappings.filter(
        c => c.customField.id === this.customFieldOption.id || c.brivoCustomField.id == this.brivoCustomFieldOption.id
      ).length === 0
    );
  }
  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }

    this.setupState.brivoCustomFieldMappings.push({
      customField: this.customFieldOption,
      brivoCustomField: this.brivoCustomFieldOption,
    });
    this.setBrivoCustomFieldOptions();
    this.setCustomFieldOptions();
  }

  removeMapping(id: number) {
    this.setupState.brivoCustomFieldMappings = this.setupState.brivoCustomFieldMappings.filter(
      c => c.customField.id !== id
    );
    this.setBrivoCustomFieldOptions();
    this.setCustomFieldOptions();
  }

  canGoNext() {
    if (this.mapCustomFields && this.setupState.brivoCustomFieldMappings.length < 1) {
      return false;
    }
    return true;
  }
}
