import { Component, Input } from '@angular/core';

@Component({
  selector: 'hl-spinner',
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() textOverride: string;

  get iconSizeClass(): string {
    switch (this.size) {
      case 'small':
        return 'c-icon--s';
      case 'medium':
        return 'c-icon--m';
      case 'large':
        'c-icon--36';
      default:
        throw new Error('Unknown size received');
    }
  }

  get iconSize(): string {
    switch (this.size) {
      case 'small':
        return '16';
      case 'medium':
        return '24';
      case 'large':
        '36';
      default:
        throw new Error('Unknown size received');
    }
  }

  get iconWrapperSize(): string {
    switch (this.size) {
      case 'small':
        return 's';
      case 'medium':
        return 'm';
      case 'large':
        'l';
      default:
        throw new Error('Unknown size received');
    }
  }
}
