import { Component, Input, OnInit } from '@angular/core';
import { ICompany, IFeatureList } from '@proxyclick/data-model';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { BillingActions } from '~/store/billing/billing.actions';
import { AppStore } from '~/store/store';

const PRIME_FEATURE_LIST_ID = 16;
@Component({
  selector: 'pxc-downgrade-feature-list',
  templateUrl: './downgrade-feature-list.html',
})
export class DowngradeFeatureListComponent implements OnInit {
  @Input() company: ICompany;
  @Input() featureLists;

  featureList;
  readOnly = true;

  constructor(private store: AppStore, private notifications: NotificationsService) {}

  ngOnInit() {
    this.featureList = this.featureLists[0];
  }

  downgrade(company: ICompany, billingVersion: number, featureList?: IFeatureList) {
    return () =>
      this.store
        .dispatchAsync(
          BillingActions.DowngradeBilling.Save({
            companyId: company.id,
            featureListId: billingVersion === 2 ? PRIME_FEATURE_LIST_ID : featureList.id
          })
        )
        .then(() => this.notifications.success('Succesfully downgraded'));
  }
}
