<!-- TODO: <div *pxcHasRight='"tab_billing"'> -->
<pxc-loading [loading]="loading.billing$ | async">
  <div *ngIf="billing$ | async as billing">
    <div *ngIf="featureLists$ | async as featureLists">
      <div>
        <div *ngIf="company && vm$ | async as vm">
          <!--- Billing version 1 -> 3 -->
          <ng-container *ngIf="billing.version < 4">
            <pxc-field label="Migrate" *pxcHasRight="'billing_migrate'">
              <pxc-button type="primary" [onClick]="openMigrationModal()">
                <i class="fas fa-angle-double-up"></i><span class="u-margin-left-2xs">Migrate to new pricing</span>
              </pxc-button>
            </pxc-field>
          </ng-container>
          <pxc-module-form
            *ngIf="billing.version === 1 && billing.status === 'IN_TRIAL'"
            [company]="company"
            [module]="vm"
          >
          </pxc-module-form>
        </div>
      </div>
      <div>
        <!-- IN TRIAL --->
        <div *ngIf="billing.status === 'IN_TRIAL'">
          <form #form="ngForm">
            <pxc-field label="Trial Until" *ngIf="billing.version >= 2">
              <div *pxcHasRight="'company_extend_trial'" class="form-inline">
                <pxc-trial-extender
                  [trialEndAt]="billing.trialEndAt"
                  (onExtend)="extendTrial($event)"
                ></pxc-trial-extender>
              </div>
              <div *pxcHasNoRight="'company_extend_trial'">{{billing.trialEndAt | time:'LL'}}</div>
            </pxc-field>
            <ng-container
              *ngIf="billing && (billing.status !== 'INVOICED' && !(billing.subscriptionId && billing.customerId))"
            >
              <pxc-field label="Activate" *pxcHasRight="'billing_create_subscription'">
                <pxc-button id="activate-location" type="primary" [onClick]="activateLocation()">
                  <span>Activate Location</span>
                </pxc-button>
              </pxc-field>
            </ng-container>
          </form>
          <!-- Only allow downgrades for billing version 2 or 3 -->
          <ng-container *ngIf="billing.version == 2 || billing.version == 3">
            <pxc-field label="Downgrade" *pxcHasRight="'billing_downgrade'">
              <button class="c-button c-button--s" id="downgrade">Downgrade</button>
              <hl-popover
                #popoverElement
                element="#downgrade"
                [options]="popoverOptions"
                trigger="click"
                closeTrigger="mouseleave"
              >
                <pxc-downgrade-feature-list
                  [company]="company"
                  [featureLists]="featureLists | filter:downgradeFeatures"
                >
                </pxc-downgrade-feature-list>
              </hl-popover>
            </pxc-field>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Subscription -->
    <div *ngIf="billing.status !== 'IN_TRIAL'">
      <!-- Late payment banner -->
      <pxc-field label="Late Payment">
        <div class="form-inline">
          <pxc-switch [(ngModel)]="billing.latePayment" [onChange]="updateBilling(company, billing)"> </pxc-switch>
        </div>
      </pxc-field>
      <pxc-field label="Status">
        <div class="flex center">
          <span>{{billing.status | status:billing.trialEndAt}}</span>
          <ng-container *ngIf="billing.subscriptionType !== 'SUBSCRIPTION-LINKED'">
            <div *pxcHasRight="'billing_block_subscription'">
              <pxc-button class="u-margin-left-xs" *ngIf="billing.status === 'INVOICED'" [onClick]="blockSubscription()"
                >Block</pxc-button
              >
            </div>
            <div *pxcHasRight="'billing_unblock_subscription'">
              <pxc-button
                class="u-margin-left-xs"
                *ngIf="billing.status === 'BLOCKED'"
                [onClick]="unblockSubscription()"
                >Unblock</pxc-button
              >
            </div>
            <ng-container>
              <div *pxcHasRight="'billing_cancel_subscription'">
                <pxc-button
                  class="u-margin-left-xs"
                  *ngIf="billing.status === 'INVOICED' || billing.status === 'BLOCKED'"
                  [onClick]="cancelSubscription()"
                  >Cancel</pxc-button
                >
              </div>
            </ng-container>
          </ng-container>
        </div>
      </pxc-field>
    </div>

    <div *ngIf="activableCompanyFeatureLists$ | async as featureLists">
      <div class="u-margin-top-l">
        <h3 class="mt-3">Feature activation for the specific location</h3>
        <div class="c-helper text-warning u-margin-top-xs">
          <span class="c-icon c-icon--m">
            <hl-icon icon="danger-24"></hl-icon>
          </span>
          Changes must be communicated to billing
        </div>
      </div>

      <!--- Packs table -->
      <table class="c-table u-margin-top-s">
        <thead>
          <th class="pxc-company-billing__table--col-name">{{billing.version === 2 ? 'Pack' : 'Plan'}}</th>
          <ng-container *ngIf="checkIfSubscriptionNotBasAndNotLinked()">
            <th class="pxc-company-billing__table--col-status">Status</th>
          </ng-container>
          <th class="pxc-company-billing__table--col-trialUntil">Trial Until</th>
          <th class="pxc-company-billing__table--col-trialUntil">Activated on</th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngFor="let featureList of featureLists | filter:packFilter">
            <td>
              <div class="u-flex u-flex-align-items-center">
                <span>{{featureList.description}}</span>
                <div *pxcHasRight="'company_vm_billing'">
                  <span
                    *ngIf="billing.version === 1"
                    class="c-action u-margin-left-xs"
                    id="setFeatures-{{featureList.id}}"
                  >
                    <span class="c-icon c-icon--m c-action__icon">
                      <hl-icon icon="signature-24"></hl-icon>
                    </span>
                  </span>
                </div>
              </div>
              <hl-popover
                *ngIf="billing.version < 3"
                #popoverElement
                element="#setFeatures-{{featureList.id}}"
                [options]="popoverOptions"
                trigger="click"
                closeTrigger="mouseleave"
              >
                <pxc-features-selector
                  [company]="company"
                  [module]="'VM'"
                  [featuresList]="featureLists$ | async | filter:oldPricingFeatures"
                  [features]="featureList"
                >
                </pxc-features-selector>
              </hl-popover>
            </td>
            <ng-container *ngIf="checkIfSubscriptionNotBasAndNotLinked()">
              <td>{{featureList.adminStatus | status}}</td>
            </ng-container>
            <td>{{featureList.trialEndAt ? (featureList.trialEndAt | time) : 'Not done'}}</td>
            <td>{{featureList.activatedAt ? (featureList.activatedAt | time) : 'Not done'}}</td>
            <td>
              <div *ngIf="billing.version > 1">
                <div *pxcHasRight="'billing_start_pack_trial'">
                  <div *ngIf="featureList.status === 'SUGGESTED'">
                    <pxc-button
                      type="primary"
                      *ngIf="(featureList.type !== 'BASE' || billing.version >= 3)"
                      [onClick]="startTrial(featureList)"
                    >
                      {{billing.version >= 3 ? 'Activate' :'Start trial'}}</pxc-button
                    >
                  </div>
                </div>
                <ng-container *ngIf="billing.version < 3">
                  <div *pxcHasRight="'billing_cancel_pack'">
                    <div *ngIf="featureList.status === 'IN_TRIAL' || featureList.status === 'INVOICED'">
                      <pxc-button
                        type="danger"
                        *ngIf="featureList.type !== 'BASE'"
                        [onClick]="cancelFeatureList(featureList)"
                        >Cancel</pxc-button
                      >
                    </div>
                  </div>
                  <div *pxcHasRight="'billing_start_pack_trial'">
                    <div *ngIf="featureList.status === 'CANCELLED'">
                      <pxc-button *ngIf="featureList.type !== 'BASE'" [onClick]="resetFeatureList(featureList)"
                        >Reset</pxc-button
                      >
                    </div>
                  </div>
                </ng-container>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!--- Integations table -->
      <table *ngIf="billing.version < 3  && hasIntegrations(featureLists)" class="c-table u-margin-top-m">
        <thead>
          <th class="pxc-company-billing__table--col-name">Integration</th>
          <ng-container *ngIf="checkIfSubscriptionNotBasAndNotLinked()">
            <th class="pxc-company-billing__table--col-status">Status</th>
          </ng-container>
          <th class="pxc-company-billing__table--col-trialUntil">Trial Until</th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngFor="let featureList of featureLists | filter:integrationFilter">
            <td>{{featureList.description}}</td>
            <ng-container *ngIf="checkIfSubscriptionNotBasAndNotLinked()">
              <td>{{featureList.adminStatus | status}}</td>
            </ng-container>
            <td>{{featureList.trialEndAt ? (featureList.trialEndAt | time) : 'Not done'}}</td>
            <td>
              <div *pxcHasRight="'billing_start_pack_trial'">
                <div *ngIf="featureList.status === 'SUGGESTED'">
                  <pxc-button type="primary" *ngIf="featureList.type !== 'BASE'" [onClick]="startTrial(featureList)"
                    >Start trial</pxc-button
                  >
                </div>
              </div>
              <div *pxcHasRight="'billing_cancel_pack'">
                <div *ngIf="featureList.status === 'IN_TRIAL' || featureList.status === 'INVOICED'">
                  <pxc-button
                    type="danger"
                    *ngIf="featureList.type !== 'BASE'"
                    [onClick]="cancelFeatureList(featureList)"
                    >Cancel</pxc-button
                  >
                </div>
              </div>
              <div *pxcHasRight="'billing_start_pack_trial'">
                <div *ngIf="featureList.status === 'CANCELLED'">
                  <pxc-button *ngIf="featureList.type !== 'BASE'" [onClick]="resetFeatureList(featureList)"
                    >Reset</pxc-button
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="billing.version >= 3 && hasIntegrations(featureLists)" class="c-table u-margin-top-m">
        <thead>
          <th class="pxc-company-billing__table--col-name">Extra</th>
          <ng-container *ngIf="checkIfSubscriptionNotBasAndNotLinked()">
            <th class="pxc-company-billing__table--col-status">Status</th>
          </ng-container>
          <th class="pxc-company-billing__table--col-trialUntil">Activated on</th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngFor="let featureList of featureLists | filter:integrationFilter">
            <td>{{featureList.description}}</td>
            <ng-container *ngIf="checkIfSubscriptionNotBasAndNotLinked()">
              <td>{{featureList.adminStatus | status}}</td>
            </ng-container>
            <td>{{featureList.activatedAt ? (featureList.activatedAt | time) : 'Not done'}}</td>
            <td>
              <div *pxcHasRight="'billing_start_pack_trial'">
                <div *ngIf="featureList.status === 'SUGGESTED'">
                  <pxc-button type="primary" *ngIf="featureList.type !== 'BASE'" [onClick]="startTrial(featureList)"
                    >Activate</pxc-button
                  >
                </div>
              </div>
              <div *pxcHasRight="'billing_cancel_pack'">
                <div *ngIf="featureList.status === 'IN_TRIAL' || featureList.status === 'INVOICED'">
                  <pxc-button
                    type="danger"
                    *ngIf="featureList.type !== 'BASE'"
                    [onClick]="cancelFeatureList(featureList)"
                    >Deactivate</pxc-button
                  >
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</pxc-loading>
