<div
  [ngClass]="getClassMap()"
  [formGroup]="selectedMultipleFeatureListsForm"
  *ngIf="targetFeatureLists.length"
  class="flex wrap"
>
  <div class="u-margin-bottom-s" style="width: 50%;" *ngFor="let featureList of targetFeatureLists;">
    <div class="c-box u-margin-right-xs">
      <div class="flex center l-grid">
        <label formArrayName="selectedFeatureLists" class="flex center l-grid__cell l-grid__cell--8">
          <hl-checkbox (change)="onInternalChange($event.target.value, featureList)"> </hl-checkbox>
          <label class="c-checkbox__skin"></label>
          <span class="u-text-m text-bold u-margin-left-xs u-color-neutral-darker wrap"
            >{{featureList.description}}</span
          >
        </label>
      </div>
    </div>
  </div>
</div>
