import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OrganisationDetailsPageComponent } from '~/pages/organisations/organisation-details/organisation-details.page';
import { FastPaceSharedModule } from '~/shared/fastpace/fastpace.shared.module';
import { RightGuard } from '~/shared/guards/right.guard';
import { SharedModule } from '~/shared/shared.module';
import { UserRights } from '../../../../../shared/models';
import { CreateOrganisationPageComponent } from './create-organisation/create-organisation.page';
import { OrganisationCompaniesComponent } from './organisation-companies/organisation-companies.component';
import { OrganisationDomainsComponent } from './organisation-domains/organisation-domains.component';
import { OrganisationPlatformPageComponent as OrganisationPlatformPageComponent } from './organisation-platform/organisation-platform.page';
import { OrganisationUsersComponent } from './organisation-users/organisation-users.component';
import { OrganisationsPageComponent } from './organisations.page';
import { ReactiveComponentModule } from '@ngrx/component';

@NgModule({
  declarations: [
    OrganisationsPageComponent,
    CreateOrganisationPageComponent,
    OrganisationDetailsPageComponent,
    OrganisationUsersComponent,
    OrganisationCompaniesComponent,
    OrganisationDomainsComponent,
    OrganisationPlatformPageComponent,
  ],
  imports: [
    ReactiveComponentModule,
    CommonModule,
    FormsModule,
    SharedModule,
    FastPaceSharedModule,
    RouterModule.forChild([
      {
        path: 'organisations',
        canActivate: [RightGuard],
        data: {
          right: UserRights.TabOrganisations,
          title: 'Organisations',
        },
        children: [
          {
            path: '',
            component: OrganisationsPageComponent,
            data: {
              title: '',
            },
          },
          {
            path: 'create',
            component: CreateOrganisationPageComponent,
            data: {
              title: 'Create',
            },
          },
          {
            path: ':id',
            component: OrganisationDetailsPageComponent,
            data: {
              title: '{{organisation.organisation.value.name}}',
            },
            children: [
              {
                path: 'users',
                component: OrganisationUsersComponent,
                data: {
                  title: 'Users',
                },
              },
              {
                path: 'companies',
                component: OrganisationCompaniesComponent,
                data: {
                  title: 'Companies',
                },
              },
              {
                path: 'domains',
                component: OrganisationDomainsComponent,
                data: {
                  title: 'Domains',
                },
              },
              {
                path: 'platform',
                component: OrganisationPlatformPageComponent,
                data: {
                  title: 'Platform',
                },
              },
              {
                path: '**',
                redirectTo: 'users',
              },
            ],
          },
        ],
      },
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrganisationsModule {}
