import { Component } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AdminService } from '~/services/admin.service';
import { controlValueAccessor } from '~/utils/utils';
import { IAdminUser } from '../../../../../../../shared/models';

@Component({
  selector: 'pxc-admins-typeahead',
  templateUrl: './admins-typeahead.html',
  providers: controlValueAccessor(AdminsTypeAheadComponent),
})
export class AdminsTypeAheadComponent implements ControlValueAccessor {
  admin: IAdminUser & string;

  private onChange: (company: IAdminUser) => void;

  constructor(private Admins: AdminService) {}

  onInternalChange() {
    if (this.admin && this.admin.id) {
      this.onChange(this.admin);
    } else if (this.admin === '') {
      this.admin = null;
      this.onChange(null);
    }
  }

  writeValue(admin): void {
    this.admin = admin;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  delete() {
    this.admin = null;
    this.onChange(null);
  }

  registerOnTouched(fn: any): void {
    return;
  }
  setDisabledState?(isDisabled: boolean): void {
    return;
  }

  // .bind(this) is not working on ng-bootstrap > 9. This is a workaournd
  // https://github.com/ng-bootstrap/ng-bootstrap/issues/4055
  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => (term.length <= 2 ? [] : this.Admins.searchAdmins(term)))
    );
  }

  formatInput(admin: IAdminUser) {
    return `${admin.firstName} ${admin.lastName}`;
  }
}
