import { IPxcConfig, IUser } from "@proxyclick/data-model";

export type DomainRelationship = "NONE" | "ESTABLISHED";

export type UserRight =
  | "company_extend_trial"
  | "company_sd_billing"
  | "company_vm_options"
  | "company_vm_billing"
  | "create_application"
  | "ldap_extract"
  | "log_as_user"
  | "marketplace_edit_integrations"
  | "marketplace_edit_partners"
  | "tab_domains"
  | "tab_companies"
  | "tab_organisations"
  | "tab_marketplace"
  | "tab_partners"
  | "tab_provisioning"
  | "tab_stats"
  | "tab_users"
  | "tab_utilities"
  | "tab_fastpace"
  | "tab_visitors"
  | "billing_start_pack_trial"
  | "billing_cancel_pack"
  | "billing_create_subscription"
  | "billing_block_subscription"
  | "billing_unblock_subscription"
  | "billing_cancel_subscription"
  | "billing_migrate"
  | "billing_downgrade"
  | "super_admin"
  | "tab_visitors"
  | "delete_company";

export const UserRights: { [name: string]: UserRight } = {
  ExtendTrial: "company_extend_trial",
  SetSDFeatureList: "company_sd_billing",
  SetVMFeatureList: "company_vm_billing",
  UpdateVMSettings: "company_vm_options",
  CreateApplication: "create_application",
  ExtractLDAPUsers: "ldap_extract",
  LogAsUser: "log_as_user",
  EditMarketplaceIntegrations: "marketplace_edit_integrations",
  EditMarketplacePartners: "marketplace_edit_partners",
  TabDomains: "tab_domains",
  TabCompanies: "tab_companies",
  TabOrganisations: "tab_organisations",
  TabMarketplace: "tab_marketplace",
  TabPartners: "tab_partners",
  TabProvisioning: "tab_provisioning",
  TabStats: "tab_stats",
  TabUsers: "tab_users",
  TabUtilities: "tab_utilities",
  TabFastPace: "tab_fastpace",
  StartPackTrial: "billing_start_pack_trial",
  CancelPack: "billing_cancel_pack",
  CreateSubscription: "billing_create_subscription",
  BlockSubscription: "billing_block_subscription",
  UnblockSubscription: "billing_unblock_subscription",
  CancelSubscription: "billing_cancel_subscription",
  MigrateSubscription: "billing_migrate",
  DowngradeSubscription: "billing_downgrade",
  SuperAdmin: "super_admin",
  TabVisitors: "tab_visitors",
  DeleteCompany: "delete_company",
};

export interface IAdminUserBase {
  id?: number;
  firstName?: string;
  lastName?: string;
  email: string;
  rights?: UserRight[];
}

export interface IAdminUser extends IAdminUserBase {}

export interface IAdminUserDB extends IAdminUserBase {
  password?: string;
}

export interface IAdminCompany {
  id: string;
  mrr: number;
}

export interface IAdminCompanyDB extends IAdminCompany {
  domainId: number;
}

export interface IJWTPayload {
  user: Partial<IAdminUser>;
  accessToken: string;
  rights: UserRight[] | undefined;
  createdAt: string;
  infinite?: boolean;
}

export interface ILiveMapStats {
  checkinPerHour: number[];
  countries: { code: string; count: number }[];
}

export interface IKPIField {
  field: string;
  prev: string;
  last: string;
  target: string;
}

export interface IKPI {
  headers: {
    prev: string;
    last: string;
    target: string;
  };
  data: IKPIField[];
}

export interface IUserRight {
  id: number;
  name: UserRight;
}

export interface IDomainBase {
  id?: number;
  domain: string;
  name?: string;
  companyCount: number;
  employeeCount?: number;
  industry?: string;
  mrr?: number;
  checkinCount?: number;
  logo?: string;
  userCount: number;
  note: string;
  relationship: DomainRelationship;
  contactId: string;
}

export interface IDomain extends IDomainBase {
  accountManager?: IAdminUser;
  clearbitData?: IClearbitCompany;
  keyContact?: IUser;
}

export interface IDomainDB extends IDomainBase {
  accountManagerId?: number;
  accountManagerEmail?: string;
  clearbitData?: string;
}

export interface IClearbitCompany {
  category: {
    industry: string;
  };
  metrics: {
    alexaUsRank: number;
    alexaGlobalRank: number;
    googleRank: number;
    employees: number;
    employeesRange: string;
    marketCap: number;
    raised: number;
    annualRevenue: number;
  };
}

export interface IConfig extends IClientConfig {
  server: {
    auth: {
      tokenLifetime: number;
    };
    port: number;
    api: IPxcConfig.Api;
    logging: IPxcConfig.Logging;
    marketplace: {
      baseUrl: string;
      token: string;
    };
    admin: {
      baseUrl: string;
    };
    db: {
      host: string;
      user: string;
      password: string;
      database: string;
    };
    cryptoKey: string;
    bugsnag: {
      apiKey: string;
    };
    sendgrid: {
      apiKey: string;
    };
    proxystats: {
      baseUrl: string;
      accessKey: string;
      secretKey: string;
    };
    googlesheets: {
      email: string;
      privateKeyFilePath: string;
    };
    pulsar: IPxcConfig.Pulsar;
    health: IPxcConfig.Health;
    clearbit: {
      apiKey: string;
    };
    domain: {
      sync: boolean;
      frequency: number;
    };
    identityServer: {
      baseUrl: string;
      clientId: string;
      clientSecret: string;
    };
    fastpace: {
      url: string;
    };
    proovr: {
      url: string;
    };
    extender: {
      url: string;
    };
    intercom: {
      baseUrl: string;
      app_id: string;
      token: string;
    };
    azureCredentials: {
      client_secret: string;
    };
  };
}

export interface IClientConfig {
  app: {
    baseUrl: string;
  };
  googleMaps: {
    apiKey: string;
  };
  pubnub: {
    subscribeKey: string;
    channel: string;
  };
  pongUp: {
    token: string;
  };
  azureCredentials: {
    client_id: string;
    redirect_uri: string;
  };
}

