import { Action } from '@ngrx/store';
import { errorRegex } from './error.reducer';

export const ErrorSelectors = {
  getByAction: (action: Action) => {
    return state => {
      const reg = errorRegex.exec(action.type);
      if (reg) {
        return state.error[reg[1]];
      }
      return state.error[action.type];
    };
  },
};
