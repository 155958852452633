<div class="l-bar">
  <div class="l-bar__item-start">
    <app-pagination-dots [page]="setupState.currentPage" [totalPages]="setupState.totalPages"></app-pagination-dots>
  </div>
  <div class="l-bar__item-end">
    <button class="c-button c-button--primary c-button--s" type="button" [disabled]="!canGoNext" (click)="next()">
      {{ setupState.nextTitle }}
    </button>
  </div>
</div>
