<form class="c-card c-card--w700 l-stack u-margin-h-none l-form u-margin-bottom-m" *ngIf="!(loading$ | async)">
  <div class="c-card__header">
    <h2 class="c-card__title">Setup access control for {{ setupState.company.name }} ({{ setupState.company.id }})</h2>
  </div>

  <app-fastpace-company-setup-select-extender
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.extenderPage.key"
  ></app-fastpace-company-setup-select-extender>

  <app-fastpace-company-setup-generate-tokens
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.tokenSetupPage.key"
  ></app-fastpace-company-setup-generate-tokens>
  <app-fastpace-company-setup-select-token-group
    *ngIf="setupState.page$.value === allPages.tokenGroupPage.key"
    [setupState]="setupState"
  ></app-fastpace-company-setup-select-token-group>
  <app-fastpace-company-setup-set-token-range
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.tokenRangePage.key"
  ></app-fastpace-company-setup-set-token-range>
  <app-fastpace-company-setup-customfields-accesslevels
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.customFieldAccessLevelPage.key"
  >
  </app-fastpace-company-setup-customfields-accesslevels>
  <app-fastpace-company-setup-copy-settings
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.copySettingsPage.key"
  ></app-fastpace-company-setup-copy-settings>

  <app-fastpace-company-setup-extenders-ac2000
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.ac2000SettingsPage.key"
  ></app-fastpace-company-setup-extenders-ac2000>

  <app-fastpace-company-setup-extenders-aeos
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.aeosSettingsPage.key"
  ></app-fastpace-company-setup-extenders-aeos>

  <app-fastpace-company-setup-extenders-ccure
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.ccureSettingsPage.key"
  ></app-fastpace-company-setup-extenders-ccure>

  <app-fastpace-company-setup-extenders-genetec
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.genetecSettingsPage.key"
  ></app-fastpace-company-setup-extenders-genetec>

  <app-fastpace-company-setup-extenders-netbox
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.netboxSettingsPage.key"
  ></app-fastpace-company-setup-extenders-netbox>

  <app-fastpace-company-setup-extenders-onguard
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.onguardSettingsPage.key"
  ></app-fastpace-company-setup-extenders-onguard>

  <app-fastpace-company-setup-extenders-brivo
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.brivoSettingsPage.key"
  ></app-fastpace-company-setup-extenders-brivo>

  <app-fastpace-company-setup-extenders-openpath
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.openPathSettingsPage.key"
  ></app-fastpace-company-setup-extenders-openpath>

  <app-fastpace-company-setup-extenders-genea
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.geneaSettingsPage.key"
  ></app-fastpace-company-setup-extenders-genea>

  <app-fastpace-company-setup-extenders-gallagher
  [setupState]="setupState"
  *ngIf="setupState.page$.value === allPages.gallagherSettingsPage.key"
  ></app-fastpace-company-setup-extenders-gallagher>

  <app-fastpace-company-setup-extenders-amag
  [setupState]="setupState"
  *ngIf="setupState.page$.value === allPages.amagSettingsPage.key"
  ></app-fastpace-company-setup-extenders-amag>

  <app-fastpace-company-setup-review
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.reviewPage.key"
  ></app-fastpace-company-setup-review>

  <app-fastpace-company-setup-submit
    [setupState]="setupState"
    *ngIf="setupState.page$.value === allPages.submitPage.key"
  ></app-fastpace-company-setup-submit>
</form>
