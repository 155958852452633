import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProovrService } from '~/services/proovr.service';
import {
  ProovrExtenderUserFieldListItemResponseV1,
  ProovrExtenderUserFieldResponseV1,
  ProovrExtenderUserImportJobResponseV1,
  ProovrExtenderUserImportMappingResponseV1,
  ProovrUserImportJobDataFileExtension,
  ProovrUserImportJobDataType,
} from '~/store/proovr/proovr.models';
import { saveAs } from 'file-saver';
import { Observable, Subscription, timer } from 'rxjs';

@Component({
  selector: 'proovr-extender-details-page',
  templateUrl: './proovr.details.page.component.html',
})
export class ProovrDetailsPageComponent implements OnInit, OnDestroy {
  @Input() extenderId: string;
  constructor(private proovrService: ProovrService) {}

  loading: boolean = true;
  userImportEnabled: boolean = false;
  userImportIsDryRun: boolean = true;
  userImportEmail: string | undefined = undefined;
  userImportBaseCompanyId: string | undefined = undefined;

  mappingCompanyId: string | undefined = undefined;
  mappingFields: ProovrExtenderUserFieldResponseV1[] = [];
  mappingField: ProovrExtenderUserFieldResponseV1 | undefined = undefined;

  mappingFieldValue: string | undefined = undefined;
  mappingFieldValueListItem: ProovrExtenderUserFieldListItemResponseV1 | undefined = undefined;

  mappingComparisons: string[] = ['equals', 'notEquals', 'contains'];
  mappingComparison: string | undefined = undefined;

  mappings: ProovrExtenderUserImportMappingResponseV1[] = [];
  latestImportJobs: ProovrExtenderUserImportJobResponseV1[] = [];

  submittingImport: boolean = false;
  submittingSettings: boolean = false;

  everyXSeconds: Observable<number> = timer(0, 15000);
  subscription: Subscription;

  async ngOnInit(): Promise<void> {
    try {
      const extenderSettings = await this.proovrService.getExtenderSettings(this.extenderId);
      this.userImportEnabled = extenderSettings.userSyncIsEnabled;
      this.userImportBaseCompanyId = extenderSettings.userSyncBaseCompanyId;
      this.userImportEmail = extenderSettings.userSyncReportEmailAddresses;
      this.userImportIsDryRun = extenderSettings.userSyncIsDryRun;

      this.mappingFields = await (
        await this.proovrService.getUserImportMappingFields(this.extenderId, {
          page: 1,
          pageSize: 100,
        })
      ).items;

      this.mappings = await (
        await this.proovrService.getUserImportMappings(this.extenderId, { page: 1, pageSize: 100 })
      ).items;

      await this.refreshLatestImportJobs();

      this.everyXSeconds.subscribe(() => {
        this.refreshLatestImportJobs();
      });
    } finally {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async saveSettings(): Promise<void> {
    this.submittingSettings = true;
    try {
      await this.proovrService.updateExtenderSettings(this.extenderId, {
        userSyncBaseCompanyId: this.userImportBaseCompanyId,
        userSyncIsDryRun: this.userImportIsDryRun,
        userSyncIsEnabled: this.userImportEnabled,
        userSyncReportEmailAddresses: this.userImportEmail,
      });
    } finally {
      this.submittingSettings = false;
    }
  }

  async refreshLatestImportJobs() {
    this.latestImportJobs = await (
      await this.proovrService.getUserImportJobs(this.extenderId, { page: 1, pageSize: 15 })
    ).items;
  }

  async removeMapping(mappingId: string): Promise<void> {
    await this.proovrService.deleteUserImportMapping(this.extenderId, mappingId);
    this.mappings = this.mappings.filter(x => x.extenderUserSyncFieldMapId !== mappingId);
  }
  async addMapping(): Promise<void> {
    const response = await this.proovrService.createUserImportMapping(this.extenderId, {
      companyId: this.mappingCompanyId,
      fieldId: this.mappingField.id,
      fieldValue: this.mappingFieldValue,
      fieldValueComparison: this.mappingComparison,
    });
    this.mappings.push(response);
  }

  async triggerImport(): Promise<void> {
    this.submittingImport = true;
    try {
      await this.proovrService.triggerUserImport(this.extenderId);
      this.latestImportJobs = await (
        await this.proovrService.getUserImportJobs(this.extenderId, { page: 1, pageSize: 15 })
      ).items;
    } finally {
      this.submittingImport = false;
    }
  }

  async downloadReport(jobKey: string): Promise<void> {
    const data = await this.proovrService.downloadUserImportJobData(
      this.extenderId,
      jobKey,
      ProovrUserImportJobDataType.userReport,
      ProovrUserImportJobDataFileExtension.csv
    );
    const blob = new Blob([data], { type: 'application/csv' });
    saveAs(blob, 'report.csv');
  }

  get canAddMapping(): boolean {
    return (
      this.mappingCompanyId !== undefined &&
      this.mappingField !== undefined &&
      this.mappingComparison !== undefined &&
      this.mappingFieldValue !== undefined &&
      this.mappingFieldValue !== ''
    );
  }

  get canSubmit(): boolean {
    return (
      this.userImportEmail !== undefined &&
      this.userImportBaseCompanyId !== undefined &&
      this.userImportEmail !== '' &&
      this.userImportBaseCompanyId !== ''
    );
  }

  getFieldName(fieldId: string) {
    return this.mappingFields.filter(x => x.id === fieldId)[0].name;
  }

  getValueName(fieldId: string, fieldValue: string): string {
    const field = this.mappingFields.filter(x => x.id === fieldId)[0];
    if (field.type === 'List') {
      const fieldListItem = field.values.filter(x => x.id === fieldValue)[0];
      return `${fieldListItem.name} (${fieldListItem.id})`;
    }

    return fieldValue;
  }
}
