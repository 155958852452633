import { ChangeDetectorRef, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class ObserverController implements OnInit, OnDestroy {
  public subscription: Subscription = new Subscription();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    Object.keys(this)
      .filter(key => /\$$/.test(key))
      .forEach(key => this.addSubscriber(key));
  }

  protected addSubscriber(key: string) {
    this.subscription.add(
      this[key].subscribe(value => {
        this[key.substr(0, key.length - 1)] = value;
        this.cdr.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
