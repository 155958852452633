<form #form="ngForm" *ngIf="module">
  <!-- In trial -->
  <div *ngIf="module.status === 'IN_TRIAL'">
    <pxc-field label="{{module.reference}} Trial Until">
      <div *pxcHasRight="'company_extend_trial'" class="form-inline">
        <pxc-datepicker [(ngModel)]="module.trialEndAt" name="vmTrialEndAt" addDaysButton="true"></pxc-datepicker>
        <pxc-button
          *ngIf="form.controls.vmTrialEndAt && form.controls.vmTrialEndAt.dirty"
          [onClick]="extendTrial(company, module)"
          class="u-margin-left-m"
        >
          Extend
        </pxc-button>
      </div>
      <div *pxcHasNoRight="'company_extend_trial'">{{module.trialEndAt | time:'LL'}}</div>
    </pxc-field>
  </div>
</form>
