import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { IntercomService } from '~/services/intercom.service';
import { PayloadAction } from '~/utils/async-generator';

@Injectable()
export class IntercomEffects {
  constructor(private actions$: Actions<PayloadAction>, private Intercom: IntercomService) {}

  // @Effect()
  // loadCompany = this.actions$.pipe(
  //     ofType(CompanyActions.Load.type),
  //     switchMap(action => this.Intercom.getCompany(action.payload)),
  //     map(company => IntercomLoadedCompany({ company: company }))
  // );

  // @Effect()
  // loadUser = this.actions$.pipe(
  //     ofType(UserLoad),
  //     switchMap(action => this.Intercom.getUser(action.payload)),
  //     map(user => IntercomLoadedUser({ user: user }))
  // );
}
