import { createAction, props } from '@ngrx/store';
import { ICompany, ICompanyParams, IIdScanStats, IKioskParams, IUser, IVmParams } from '@proxyclick/data-model';
import { IAdminStatsFilter, IIdScanFilter } from '~/services/stats.service';
import { createAsyncLoadActions } from '~/utils/async-generator';
import {
  IAdminCompanyFilter,
  ICompanyFilter,
  ICompanyUsersFilter,
  IPaginatedCompanies,
  IPaginatedUsers,
} from '../../../../../shared/filters';
import { IAdminCompany } from '../../../../../shared/models';

// Companies actions
export const CompaniesActions = createAsyncLoadActions<ICompanyFilter, IPaginatedCompanies>('companies');

// Company actions
export const CompanyActions = createAsyncLoadActions<string, ICompany>('company');
export const CompanyUsersActions = createAsyncLoadActions<ICompanyUsersFilter, IPaginatedUsers>('company' + '/users');
export const CompanyAdminActions = createAsyncLoadActions<string, IUser[]>('company' + '/admins');
export const CompanyModules = createAsyncLoadActions<string, any>('company' + '/modules');
export const KioskParamsActions = createAsyncLoadActions<string, IKioskParams>('company' + '/kiosk-params');
export const VmParamsActions = createAsyncLoadActions<string, IVmParams>('company' + '/vm-params');
export const CompanyStats = createAsyncLoadActions<IAdminStatsFilter, any>('company' + '/stats');
export const CompanyIdScanStats = createAsyncLoadActions<IIdScanFilter, IIdScanStats>('company' + '/idscan-stats');
export const CompanyAddress = createAsyncLoadActions<string, any>('company' + '/address');
export const CompanyKioskPartners = createAsyncLoadActions<string, any>('company' + '/kiosk-partners');
export const CompanyVmInvitationEmailParams = createAsyncLoadActions<string, ICompanyParams>(
  'company' + '/vm-invitation-email-params'
);

export const CompanyUserProvisioning = createAsyncLoadActions<string, any>('company' + '/user-provisioning');
export const CompanySAML = createAsyncLoadActions<string, any>('company' + '/saml');
export const CompanyTermsAndConditions = createAsyncLoadActions<string, any>('company' + '/terms-and-conditions');
export const CompanyParams = createAsyncLoadActions<string, ICompanyParams>('company' + '/params');
export const AdminCompanyActions = createAsyncLoadActions<IAdminCompanyFilter, IAdminCompany>(
  'company' + '/admin-company'
);
export const CompanyLoad = createAction('company/load', props<{ payload: string }>());

export const CompanyDelete = createAsyncLoadActions<string, void>('company/delete');
