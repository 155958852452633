<div class="ldap-status" *ngIf="ldap" [ngClass]="getClass(ldap)">
  <div>
    <strong>Status</strong>: {{ldap.status}}
    <i *ngIf="ldap.status === 'RUNNING'" class="far fa-check-circle"></i>
  </div>
  <div><strong>Last sync</strong>: {{ldap.lastSynchronizedAt | time:'L LT'}}</div>
  <div><strong>Next sync</strong>: {{ldap.nextSynchronizeAt | time:'L LT'}}</div>
  <div *ngIf="ldap.lastSynchronizedAt && ldap.lastLog as lastLog">
    <div><strong>Created:</strong> {{lastLog.createdCount}}</div>
    <div><strong>Linked:</strong> {{lastLog.linkedCount}}</div>
    <div><strong>Updated:</strong> {{lastLog.updatedCount}}</div>
    <div><strong>Unlinked:</strong> {{lastLog.unlinkedCount}}</div>
    <div><strong>Deleted:</strong> {{lastLog.deletedCount}}</div>
    <div *ngIf="lastLog.error"><strong>Error:</strong> {{lastLog.error}}</div>
  </div>
</div>
