import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IIntegration } from '@proxyclick/data-model';
import { Observable } from 'rxjs';
import { ConfigService } from '~/services/config.service';
import { DialogService } from '~/shared/components/dialog/dialog.service';
import { IntegrationActions, IntegrationsActions } from '~/store/integrations/integrations.reducer';

@Component({
  selector: 'pxc-integrations',
  templateUrl: './integrations.html',
  styleUrls: ['./integrations.scss'],
})
export class IntegrationsPageComponent implements OnInit {
  public integrations$: Observable<IIntegration[]> = this.store.select('integrations', 'value');
  public loading$: Observable<boolean> = this.store.select('integrations', 'loading');

  constructor(
    private store: Store<any>,
    private config: ConfigService,
    private router: ActivatedRoute,
    private dialog: DialogService
  ) {}

  ngOnInit() {
    this.store.dispatch(IntegrationsActions.Load());
  }

  onDelete(integration: IIntegration) {
    this.dialog.confirm('Delete integration', `Are you sure you want to delete ${integration.name}?`).then(result => {
      if (result) {
        integration['isDeleting'] = true;
        this.store.dispatch(IntegrationActions.DELETE(integration));
      }
    });
  }

  isActive(integration: IIntegration) {
    return integration.active ? 'ON' : 'OFF';
  }

  getActiveClass(integration: IIntegration) {
    return integration.active ? 'active' : 'inactive';
  }
}
