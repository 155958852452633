<div
  *ngIf="search && ((search.companies && search.companies.length) || (search.users && search.users.length))"
  class="pxc-search-results"
>
  <div *ngIf="!loadingCompanies && search.companies && search.companies.length">
    <div>Companies</div>
    <div class="pxc-search-results__item" *ngFor="let company of search.companies">
      <img *ngIf="company.countryCode" src="assets/img/flag/{{company.countryCode.toLowerCase()}}.png" />
      <a (click)="onClick()" routerLink="/companies/{{company.id}}"> {{company.name}} </a>
      <span>{{company.userCount}} <i class="far fa-user"></i></span>
    </div>
  </div>
  <div *ngIf="!loadingUsers && search.users && search.users.length">
    <div>Users</div>
    <div class="pxc-search-results__item" *ngFor="let user of search.users">
      <img
        *ngIf="user.originalCompany.countryCode"
        class="u-margin-right-2xs"
        src="assets/img/flag/{{user.originalCompany.countryCode.toLowerCase()}}.png"
      />
      <a (click)="onClick()" routerLink="/users/{{user.id}}">{{user.firstname}} {{user.lastname}}</a>
    </div>
  </div>
  <div *ngIf="search && search.users && search.companies && search.users.length === 0 && search.companies.length === 0">
    No result found
  </div>
</div>
