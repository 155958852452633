<div class="l-form__field">
  <label class="c-label">API Key</label>
  <input
    type="password"
    class="c-input c-input--s"
    required="true"
    name="brivoApiKey"
    [(ngModel)]="state.brivoApiKey"
  />
  <div class="c-helper">
    Retrieve an API Key for the customer in the brivo developer portal
    <a href="https://developer.brivo.com/apps/mykeys">developer portal</a>
  </div>
</div>
<pxc-field label="Login URI">
  <a href="https://api.proxyclick-extender.com/v1/extenders/{{ state.extender.extenderId }}/brivo/login"
    >https://api.proxyclick-extender.com/v1/extenders/{{ state.extender.extenderId }}/brivo/login</a
  >
</pxc-field>
<div class="c-helper">
  This URL allows customers to link their Brivo account with that extender using the 3-Legged OAuth flow.
</div>
