<div *pxcHasRight='"tab_marketplace"'>
  <ul class="nav nav-tabs my-4">
    <li class="nav-item">
      <a routerLink="/marketplace/integrations" class="nav-link" routerLinkActive="active">Integrations</a>
    </li>
    <li class="nav-item">
      <a routerLink="/marketplace/categories" class="nav-link" routerLinkActive="active">Categories</a>
    </li>
    <li *ngIf="false" class="nav-item">
      <a routerLink="/marketplace/partners" class="nav-link" routerLinkActive="active">Partners</a>
    </li>
  </ul>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
  <form
    class="pxc-integration-details p-2"
    *ngIf="!(loading$ | async) && integration"
    (ngSubmit)="onSave(form.value)"
    #form="ngForm"
  >
    <div class="pxc-integration-details__form">
      <div class="pxc-integration-details__info">
        <div class="form-group">
          <label for="name" class="label">Name</label>
          <input
            id="name"
            class="form-control"
            type="text"
            [(ngModel)]="integration && integration.name"
            required
            name="name"
          />
        </div>
        <div class="form-group">
          <label for="alias" class="label">Alias</label>
          <input
            id="alias"
            class="form-control"
            type="text"
            [(ngModel)]="integration && integration.alias"
            required
            name="alias"
          />
        </div>
        <div class="form-group">
          <label for="tagline" class="label">Tagline</label>
          <div class="control">
            <input
              id="tagline"
              class="form-control"
              type="text"
              [(ngModel)]="integration && integration.tagline"
              name="tagline"
            />
          </div>
        </div>
        <div class="form-group">
          <label for="editor" class="label">Editor</label>
          <input
            id="editor"
            class="form-control"
            type="text"
            [(ngModel)]="integration && integration.editor"
            name="editor"
          />
        </div>
        <div class="form-group">
          <label for="installLink" class="label">Installation Link</label>
          <input
            id="installLink"
            class="form-control"
            type="text"
            [(ngModel)]="integration && integration.installLink"
            name="installLink"
          />
        </div>
        <div class="form-group">
          <label for="video" class="label">Youtube video ID</label>
          <input
            id="video"
            class="form-control"
            type="text"
            placeholder="tgbNymZ7vqY"
            [(ngModel)]="integration && integration.video"
            name="video"
          />
        </div>
        <div class="form-group">
          <label for="helpUrl" class="label">Installation guide</label>
          <input
            id="helpUrl"
            class="form-control"
            type="text"
            [(ngModel)]="integration && integration.helpUrl"
            name="helpUrl"
          />
        </div>
        <div class="form-group" *ngIf="integration && integration.callToAction">
          <label for="calllToAction" class="label">Call to action</label>
          <pxc-dropdown
            id="callToAction"
            [(ngModel)]="integration && integration.callToAction"
            label="description"
            [options]="callToActionOptions"
            name="callToAction"
          ></pxc-dropdown>
        </div>
        <div class="form-group">
          <label class="label"
            >Order
            <input class="form-control input-xs" type="number" name="order" />
          </label>
        </div>
      </div>
      <div class="flex column">
        <div *ngIf="integration">
          <div class="form-group" *ngFor="let code of currencyCodes; index as i">
            <label for="price-{{i}}">{{code}}</label>
            <input
              type="number"
              class="form-control"
              id="price-{{i}}"
              name="price-{{i}}"
              [(ngModel)]="integration.prices[code]"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="label">
            <strong>Categories</strong>
          </label>
          <pxc-checkbox-list [items]="categories" (onChange)="onCategoriesUpdate($event)"></pxc-checkbox-list>
        </div>
        <div class="form-group">
          <label class="label">
            <strong>Required plans</strong>
          </label>
          <pxc-checkbox-list [items]="integrationPlans" (onChange)="onRequiredUpdate($event)"></pxc-checkbox-list>
        </div>
        <div class="form-group">
          <label class="label">
            <strong>Available as add-on in plans</strong>
          </label>
          <pxc-checkbox-list [items]="addOnPlans" (onChange)="onAddOnUpdate($event)"></pxc-checkbox-list>
        </div>
        <div class="form-group">
          <label class="label">
            <strong>Active</strong>
          </label>
          <pxc-switch [(ngModel)]="integration && integration.active" name="active"></pxc-switch>
        </div>
      </div>
      <div class="flex column between">
        <div class="pxc-integration-details__logo">
          <pxc-image-upload
            class="field"
            [(ngModel)]="integration && integration.logo"
            required
            name="logo"
          ></pxc-image-upload>
        </div>
        <div class="form-group">
          <div class="control form-buttons">
            <button
              type="submit"
              *pxcHasRight="'marketplace_edit_partners'"
              [disabled]="!form.valid || (loading$ | async) || (integration && integration.logo && integration && integration.logo.loading)"
              class="c-button c-button--primary c-button--s"
              [ngClass]="{'is-loading': (loading$ | async)}"
            >
              <i class="fas fa-save"></i
              ><span class="u-margin-left-2xs">{{ integration && integration.id ? 'Save' : 'Create'}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <simplemde
      class="pxc-markdown-editor"
      [(ngModel)]="integration && integration.description"
      name="description"
    ></simplemde>
    <pxc-markdown-preview [content]="integration && integration.description"></pxc-markdown-preview>

    <div class="mt-1">
      <pxc-images-manager [(ngModel)]="integration && integration.pictures" name="pictures"></pxc-images-manager>
    </div>
  </form>
</div>
