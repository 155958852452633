import { take } from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { IMDocument } from '@proxyclick/data-model';
import * as _ from 'lodash';
import { DocumentsService } from '~/services/documents.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { controlValueAccessor } from '~/utils/utils';

interface IPicture extends IMDocument {
  loading?: boolean;
}

@Component({
  selector: 'pxc-image-upload',
  templateUrl: './image-upload.html',
  styleUrls: ['./image-upload.scss'],
  providers: controlValueAccessor(ImageUploadComponent),
})
export class ImageUploadComponent implements ControlValueAccessor {
  picture: IPicture = {};

  uuid = Math.random() * 100000;

  @Input()
  target = 'marketplace';

  private onChange: (p) => void;

  constructor(private documentsService: DocumentsService, private notifications: NotificationsService) {}

  writeValue(pic: IPicture | string): void {
    if (typeof pic === 'string') {
      this.picture = {
        loading: false,
        url: pic,
      };
    } else {
      this.picture = pic;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  onFileUpload(event) {
    const file = event.target.files[0];
    this.picture = {
      loading: true,
    };

    if (this.target === 'marketplace') {
      this.uploadToMarketplace(file);
    } else {
      this.uploadToAPI(file);
    }
  }

  private uploadToMarketplace(file: File) {
    this.documentsService
      .uploadToMarketplace(file)
      .pipe(take(1))
      .subscribe(
        result => {
          this.picture.url = URL.createObjectURL(file);
          _.assignIn(this.picture, result);
          this.picture.loading = false;
          this.onChange(this.picture);
        },
        error => {
          this.picture.loading = false;
          this.notifications.error('Error while uploading the file');
          delete this.picture;
        }
      );
  }

  private uploadToAPI(file: File) {
    this.documentsService.uploadFileFromFileObj(file).subscribe(result => {
      const url = URL.createObjectURL(file);
      this.picture.url = url;
      this.picture.loading = false;
      this.onChange(result);
    });
  }
}
