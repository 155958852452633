<pxc-breadcrumb [breadcrumbs]="breadcrumbs$ | async"></pxc-breadcrumb>
<form ngNativeValidate (ngSubmit)="onSubmit()" class="form-small mt-4">
  <pxc-field label="Company">
    <input type="text" class="form-control" [(ngModel)]="company.name" name="companyName" />
  </pxc-field>
  <pxc-field label="Admin Name">
    <div class="form-inline">
      <input
        type="text"
        placeholder="First name"
        class="form-control col-sm-6"
        [(ngModel)]="company.firstname"
        name="firstname"
      />
      <input
        type="text"
        placeholder="Last name"
        class="form-control col-sm-6 ml-2"
        [(ngModel)]="company.lastname"
        name="lastname"
      />
    </div>
  </pxc-field>
  <pxc-field label="Email">
    <input type="email" class="form-control" [(ngModel)]="company.email" name="email" />
  </pxc-field>
  <pxc-field label="Password">
    <input type="password" class="form-control" [(ngModel)]="company.password" name="password" />
  </pxc-field>
  <pxc-field label="Address">
    <input type="text" required class="form-control" [(ngModel)]="company.address" name="address" />
  </pxc-field>
  <pxc-field label="City">
    <input type="text" class="form-control" [(ngModel)]="company.city" name="city" />
  </pxc-field>
  <pxc-field label="ZIP">
    <input type="text" class="form-control" [(ngModel)]="company.zip" name="zip" />
  </pxc-field>
  <pxc-field label="Country">
    <pxc-country-picker [(ngModel)]="company.countryCode" name="country"></pxc-country-picker>
    <!-- <pxc-dropdown [(ngModel)]="company.country" label="name" name="country" [options]="countries"></pxc-dropdown> -->
  </pxc-field>
  <pxc-field label="Phone">
    <input type="text" class="form-control" [(ngModel)]="company.phone" name="phone" />
  </pxc-field>
  <button class="c-button c-button--primary c-button--s">Create</button>
</form>
