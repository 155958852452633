import { Component, OnInit } from '@angular/core';
import { ICustomField } from '@proxyclick/data-model';
import {
  EntrancesAccessPointResultV1,
  FreeFieldsFreeFieldConfigurationResultV1,
  IdentifierTypesIdentifierTypeResultV1,
  UnitsUnitResultV1,
} from '@proxyclick/extender-server-aeos-client';
import { ControllersCompaniesAeosCompanySettingsResponseV1 } from '@proxyclick/fastpace-client';
import { v4 as uuidv4 } from 'uuid';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceService } from '~/services/fastpace.service';
import { fetchAll } from '~/utils/utils';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';

@Component({
  selector: 'app-fastpace-company-aeos',
  templateUrl: './fastpace.company.aeos.component.html',
})
export class FastPaceCompanyAEOSComponent extends FastPaceBaseSystemSettingsComponent implements OnInit {
  constructor(fastpaceService: FastPaceService, companyService: CompaniesService, extenderService: ExtenderService) {
    super(fastpaceService, companyService, extenderService);
  }
  async ngOnInit(): Promise<void> {
    this.aeosSettings = await this.fastpaceService.getAEOSSettings(this.fastpaceCompany.companyId);

    this.customFields = await this.companyService.getCustomFields(this.fastpaceCompany.companyId).toPromise();

    this.freeFields = await this.extenderService.aeos.freeFields.list(this.fastpaceCompany.extenderId, {
      requestTracingId: uuidv4(),
    });

    this.accessPoints = await fetchAll<EntrancesAccessPointResultV1>(
      async page =>
        (
          await this.extenderService.aeos.entrances.listAccessPoints(this.fastpaceCompany.extenderId, {
            requestPage: page,
            requestPageSize: 100,
            requestTracingId: uuidv4(),
          })
        ).accessPoints
    );

    try {
      this.units = (
        await this.extenderService.aeos.units.list(this.fastpaceCompany.extenderId, { requestTracingId: uuidv4() })
      ).units;
      this.unitsEnabled = true;
    } catch {
      //Units not enabled for this AEOS system
      this.unitsEnabled = false;
      this.unit = null;
    }

    this.cardFormats = (
      await this.extenderService.aeos.identifierTypes.list(this.fastpaceCompany.extenderId, {
        requestTracingId: uuidv4(),
      })
    ).identifierTypes;

    this.setInitialState();
  }

  //current settings
  aeosSettings: ControllersCompaniesAeosCompanySettingsResponseV1 | null = null;
  //new settings
  customFieldMappings: Array<{ customField: ICustomField; freeField: FreeFieldsFreeFieldConfigurationResultV1 }> = [];
  accessPointFilterIn: EntrancesAccessPointResultV1[] = [];
  accessPointFilterOut: EntrancesAccessPointResultV1[] = [];
  usesEmployeeType: boolean;
  hostEmailAsContact: boolean;
  unit: UnitsUnitResultV1 | null;

  //custom field mappings
  mapCustomFields: boolean = false;

  freeFields: FreeFieldsFreeFieldConfigurationResultV1[] = [];
  freeFieldOptions: FreeFieldsFreeFieldConfigurationResultV1[] = [];
  freeFieldOption: FreeFieldsFreeFieldConfigurationResultV1 | null = null;

  customFields: ICustomField[] = [];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  accessPoints: EntrancesAccessPointResultV1[] = [];
  accessPointOptions: EntrancesAccessPointResultV1[] = [];
  accessPointOptionIn: EntrancesAccessPointResultV1 | null = null;
  accessPointOptionOut: EntrancesAccessPointResultV1 | null = null;

  unitsEnabled: boolean = false;
  units: UnitsUnitResultV1[] = [];
  unitOptions: UnitsUnitResultV1[] = [];
  unitOption: UnitsUnitResultV1 | null = null;

  cardFormats: IdentifierTypesIdentifierTypeResultV1[] = [];
  cardFormatOption: IdentifierTypesIdentifierTypeResultV1 | null = null;
  qrCodeCardFormatOption: IdentifierTypesIdentifierTypeResultV1 | null = null;
  async setInitialState() {
    this.usesEmployeeType = this.aeosSettings.usesEmployeeType;
    this.hostEmailAsContact = this.aeosSettings.hostEmailAsContact;

    if (this.customFields.length > 0 && this.freeFields.length > 0) {
      this.mapCustomFields = this.aeosSettings.customFieldMappings.length > 0;
      this.customFieldMappings = this.aeosSettings.customFieldMappings.map(x => {
        var customField = this.customFields.filter(c => c.id === x.customFieldId)[0];
        var freeField = this.freeFields.filter(f => f.id === x.freeFieldId)[0];
        return { customField: customField, freeField: freeField };
      });
      this.setCustomFieldOptions();
      this.setFreeFieldOptions();
    }

    if (this.accessPoints.length > 0) {
      this.accessPointFilterIn = this.aeosSettings.accessPointFilterIn.map(
        a => this.accessPoints.filter(ap => ap.id == a)[0]
      );
      this.accessPointFilterOut = this.aeosSettings.accessPointFilterOut.map(
        a => this.accessPoints.filter(ap => ap.id == a)[0]
      );
      this.setAccessPointOptions();
    }

    if (this.units.length > 0) {
      this.setUnitOptions();
      if (this.aeosSettings.unitId != null) {
        this.unit = this.units.filter(u => u.id == this.aeosSettings.unitId)[0];
        this.unitOption = { id: this.aeosSettings.unitId };
      } else {
        this.unit = null;
        this.unitOption = null;
      }
    }

    this.cardFormatOption = this.cardFormats.find(x => x.id == this.aeosSettings.cardIdentifierTypeId);
    this.qrCodeCardFormatOption = this.cardFormats.find(x => x.id == this.aeosSettings.qrCodeIdentifierTypeId);
  }

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.customFieldMappings = [];
    }
  }

  unitOptionChanged() {
    this.unit = this.unitOption;
  }

  setUnitOptions() {
    this.unitOptions = this.units;
  }

  setAccessPointOptions() {
    const options = this.accessPoints.filter(c => {
      //Filter out custom fields already mapped
      return (
        this.accessPointFilterIn.filter(a => a.id === c.id).length === 0 &&
        this.accessPointFilterOut.filter(a => a.id === c.id).length === 0
      );
    });

    this.accessPointOptions = options;
    if (this.accessPointOptions.length > 0) {
      this.accessPointOptionIn = this.accessPointOptions[0];
      this.accessPointOptionOut = this.accessPointOptions[0];
    } else {
      this.accessPointOptionIn = null;
      this.accessPointOptionOut = null;
    }
  }

  setFreeFieldOptions() {
    const options = this.freeFields.filter(c => {
      //Filter out custom fields already mapped
      return this.customFieldMappings.filter(a => a.freeField.id === c.id).length === 0;
    });

    this.freeFieldOptions = options;
    if (this.freeFieldOptions.length > 0) {
      this.freeFieldOption = this.freeFieldOptions[0];
    } else {
      this.freeFieldOption = null;
    }
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return (
        this.customFieldMappings !== undefined &&
        this.customFieldMappings.filter(a => a.customField.id === c.id).length === 0
      );
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = null;
    }
  }

  canAddMapping() {
    return (
      this.customFieldOption !== undefined &&
      this.customFieldOption !== undefined &&
      this.customFieldMappings.filter(
        c => c.freeField.id === this.freeFieldOption.id || c.customField.id === this.customFieldOption.id
      ).length === 0
    );
  }

  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }
    this.customFieldMappings.push({
      customField: this.customFieldOption,
      freeField: this.freeFieldOption,
    });
    this.setCustomFieldOptions();
    this.setFreeFieldOptions();
  }

  removeMapping(id: number) {
    this.customFieldMappings = this.customFieldMappings.filter(c => c.customField.id !== id);
    this.setCustomFieldOptions();
    this.setFreeFieldOptions();
  }

  canAddInMapping() {
    return (
      this.accessPointOptionIn && this.accessPointFilterIn.filter(d => d.id === this.accessPointOptionIn.id).length < 1
    );
  }
  canAddOutMapping() {
    return (
      this.accessPointOptionOut &&
      this.accessPointFilterOut.filter(d => d.id === this.accessPointOptionOut.id).length < 1
    );
  }
  addInMapping() {
    this.accessPointFilterIn.push(this.accessPointOptionIn);
    this.setAccessPointOptions();
  }

  addOutMapping() {
    this.accessPointFilterOut.push(this.accessPointOptionOut);
    this.setAccessPointOptions();
  }

  removeInMapping(accessPointId: number) {
    this.accessPointFilterIn = this.accessPointFilterIn.filter(d => d.id !== accessPointId);
    this.setAccessPointOptions();
  }
  removeOutMapping(accessPointId: number) {
    this.accessPointFilterOut = this.accessPointFilterOut.filter(d => d.id !== accessPointId);
    this.setAccessPointOptions();
  }

  get canSubmit(): boolean {
    return this.submitting !== true;
  }

  async doUpdate(): Promise<void> {
    return this.fastpaceService.updateAEOSSettings(this.fastpaceCompany.companyId, {
      usesEmployeeType: this.usesEmployeeType,
      hostEmailAsContact: this.hostEmailAsContact,
      accessPointFilterIn: this.accessPointFilterIn.map(x => x.id),
      accessPointFilterOut: this.accessPointFilterOut.map(x => x.id),
      customFieldMappings: this.customFieldMappings.map(x => {
        return { customFieldId: x.customField.id, freeFieldId: x.freeField.id };
      }),
      unitId: this.unit != null ? this.unit.id : null,
      cardIdentifierTypeId: this.cardFormatOption.id,
    });
  }
}
