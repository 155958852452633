import { Component, Input } from '@angular/core';

@Component({
  selector: 'pxc-loading',
  templateUrl: './loading.html',
})
export class LoadingComponent {
  @Input() loading = true;

  constructor() {}
}
