import { IAdminUser } from '../../../../../shared/models';
import { createAsyncLoadActions, createAsyncSaveActions } from '~/utils/async-generator';

export const CreateAdminUser = createAsyncSaveActions<{ adminUser: IAdminUser }, { adminUser: IAdminUser }>(
  'admin-user/create'
);
export const UpdateAdminUser = createAsyncSaveActions<{ adminUser: IAdminUser }, { adminUser: IAdminUser }>(
  'admin-user/update'
);

export const AdminUsersActions = createAsyncLoadActions<void, IAdminUser[]>('admin-users');
export const AdminUserActions = createAsyncLoadActions<{ adminUserId: string }, IAdminUser>('admin-user');
