<div class="u-color-bg-neutral-off pxc-full-height" *pxcHasRight='"tab_companies"'>
  <pxc-loading [loading]="loading$ | async">
    <div class="company-details__wrapper" *ngIf="company$ | async as company">
      <div class="company-details__header">
        <div class="u-flex u-flex-justify-between">
          <span class="u-text-xl u-color-brand text-bold u-margin-bottom-gutter">
            {{company.name}}
            <span *ngIf="company.organisationId">
              <br>
              <a [routerLink]="['/organisations', company.organisationId]" class="u-text-s">Organisation</a>
            </span>
          </span>
          <button
            *pxcHasRight="'delete_company'"
            (click)="deleteLocation(company.id)"
            class="c-button c-button--primary-danger c-button--s"
          >
            Delete Company
          </button>
        </div>
        <pxc-tabs [tabs]="tabs" class="mb-2"></pxc-tabs>
      </div>

      <div class="company-details__content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </pxc-loading>
</div>
