import { combineReducers } from '@ngrx/store';
import { createAsyncLoadActions, createAsyncLoadReducer } from '~/utils/async-generator';

const searchBase = 'search';

export const SearchAction = createAsyncLoadActions(searchBase);
export const SearchCompaniesActions = createAsyncLoadActions(searchBase + '/companies');
export const SearchUsersActions = createAsyncLoadActions(searchBase + '/users');

export const searchReducer = combineReducers({
  companies: createAsyncLoadReducer(SearchCompaniesActions),
  users: createAsyncLoadReducer(SearchUsersActions),
});
