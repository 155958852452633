import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICompany, ICompanyAddress } from '@proxyclick/data-model';
import { filter, switchMap, tap } from 'rxjs/operators';
import { CompaniesService } from '~/services/companies.service';
import { GoogleMapsService } from '~/services/googlemaps.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { IAppState } from '~/store/app-state';
import { CompanyAddress } from '~/store/companies/companies.actions';

@Component({
  selector: 'pxc-company-address',
  templateUrl: './company-address.html',
})
export class CompanyAddressComponent implements OnInit {
  address$ = this.store.select('company', 'address', 'value');
  loading$ = this.store.select('company', 'address', 'loading');

  company: ICompany;

  constructor(
    private store: Store<IAppState>,
    private Companies: CompaniesService,
    private GoogleMaps: GoogleMapsService,
    private Notifications: NotificationsService
  ) {}

  ngOnInit() {
    this.store.select('company', 'company', 'value').subscribe(company => {
      this.company = company;
      this.store.dispatch(CompanyAddress.Load(this.company.id));
    });
  }

  updateAddress(address: ICompanyAddress) {
    const data = {
      address: address.address.addressLine,
      city: address.address.city,
      postalCode: address.address.postalCode,
      countryCode: address.address.countryCode,
      longitude: address.longitude,
      latitude: address.latitude,
    };
    return this.Companies.updateAddress(this.company.id, data).pipe(
      tap(() => this.Notifications.success('Address succesfully updated'))
    );
  }

  refreshGoogleMaps(address) {
    return this.GoogleMaps.getGeoCode(
      address.address.addressLine,
      address.address.postalCode,
      address.address.city,
      address.address.countryCode
    ).pipe(
      filter(geocode => {
        if (!geocode || !geocode.results || geocode.results.length === 0) {
          this.Notifications.error('No geolocation found');
          return false;
        }
        return true;
      }),
      switchMap(geocode => {
        address.latitude = geocode.results[0].geometry.location.lat;
        address.longitude = geocode.results[0].geometry.location.lng;
        return this.updateAddress(address);
      }),
      tap(() => this.Notifications.success('Geolocation succesfully updated'))
    );
  }
}
