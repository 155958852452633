import { Component, Input } from '@angular/core';
import { FastPaceSetupCompanyState } from '../fastpace-setup-company.state';
import { IExtenderResponseV1 } from '~/store/extender/extender.models';
import { ExtenderService } from '~/services/extender.service';

@Component({
  selector: 'app-fastpace-company-setup-select-extender',
  templateUrl: './select-extender.component.html',
})
export class SelectExtenderComponent {
  @Input() setupState: FastPaceSetupCompanyState;
  extenders: IExtenderResponseV1[];
  selectedExtender: string = '';
  searchTerm: string = '';
  searching: boolean = false;

  constructor(private extenderService: ExtenderService) {}

  get canSelect() {
    return this.extenders.length > 1 && !this.searching;
  }

  get selectedExtenderItem() {
    return this.extenders.filter(e => e.extenderId === this.selectedExtender)[0];
  }

  search() {
    this.searching = true;
    this.selectedExtender = '';
    this.extenderService
      .listExtenders({ page: 1, pageSize: 10, q: this.searchTerm })
      .toPromise()
      .then(r => {
        this.extenders = r.items;
        this.searching = false;
      });
  }

  selectExtender(value: string) {
    this.selectedExtender = value;
    const extender = this.extenders.filter(e => e.extenderId === this.selectedExtender)[0];
    this.setupState.extender = extender;
  }
  canGoNext() {
    return !this.setupState.useExtender || this.selectedExtender !== '';
  }
}
