<div class="c-card__section">
  <h3>AC2000 settings</h3>
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Connection</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-obj-dropdown
          [(ngModel)]="connectionOption"
          [options]="connections"
          key="connectionId"
          label="connectionId"
          (ngModelChange)="connectionOptionChanged()"
          name="connectionOption"
        ></pxc-obj-dropdown>
      </div>
    </div>
    <div class="c-helper">
      Connections to AC2000 have to go through connections configured through the Manager. Please select a connection
      here to ensure the company is connected to the correct partition.
    </div>
  </div>
  <div class="l-form__field">
    <label class="c-label">Device address IN filter</label>
    <div class="l-grid">
      <div class="l-grid__cell l-grid__cell--11">
        <pxc-obj-dropdown
          [(ngModel)]="deviceOptionIn"
          key="deviceAddress"
          label="deviceDescription"
          [options]="deviceOptions"
          name="deviceOptionIn"
        ></pxc-obj-dropdown>
      </div>
      <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
        <hl-icon
          icon="add-24"
          (click)="addInMapping()"
          [ngClass]="{ 'u-color-neutral-light': !canAddInMapping() }"
        ></hl-icon>
      </div>
    </div>
    <div class="c-helper">
      To allow visitor events from AC2000 to be sent to our servers please select at least one device for IN movements.
    </div>
  </div>
  <div class="l-form__field">
    <label class="c-label">Device address OUT filter</label>
    <div class="l-grid">
      <div class="l-grid__cell l-grid__cell--11">
        <pxc-obj-dropdown
          [(ngModel)]="deviceOptionOut"
          key="deviceAddress"
          label="deviceDescription"
          [options]="deviceOptions"
          name="deviceOptionOut"
        ></pxc-obj-dropdown>
      </div>
      <div class="l-grid__cell l-grid__cell--1 u-padding-top-xs">
        <hl-icon
          icon="add-24"
          (click)="addOutMapping()"
          [ngClass]="{ 'u-color-neutral-light': !canAddOutMapping() }"
        ></hl-icon>
      </div>
    </div>
    <div class="c-helper">
      To allow visitor events from AC2000 to be sent to our servers please select at least one device for OUT movements.
    </div>
  </div>

  <div
    *ngIf="setupState.ac2000DeviceFilterIn.length > 0 || setupState.ac2000DeviceFilterOut.length > 0"
    class="l-stack c-box u-padding-all-none u-margin-bottom-s"
  >
    <div class="l-stack__item">
      <div class="c-stacked-list c-stacked-list--s">
        <ul class="c-stacked-list__list">
          <li *ngFor="let deviceInFilter of setupState.ac2000DeviceFilterIn" class="c-stacked-list__item">
            <a
              class="c-stacked-list__inner c-stacked-list__inner--interactive"
              (click)="removeInMapping(deviceInFilter.deviceAddress)"
            >
              <div class="c-stacked-list__inner-start">IN: {{ deviceInFilter.deviceDescription }}</div>
              <div class="c-stacked-list__inner-end">
                <span class="c-icon c-icon--m">
                  <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                </span>
              </div>
            </a>
          </li>
          <li *ngFor="let deviceOutFilter of setupState.ac2000DeviceFilterOut" class="c-stacked-list__item">
            <a
              class="c-stacked-list__inner c-stacked-list__inner--interactive"
              (click)="removeOutMapping(deviceOutFilter.deviceAddress)"
            >
              <div class="c-stacked-list__inner-start">OUT: {{ deviceOutFilter.deviceDescription }}</div>
              <div class="c-stacked-list__inner-end">
                <span class="c-icon c-icon--m">
                  <hl-icon icon="trash-24" width="24" height="24"></hl-icon>
                </span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>
