import { createSelector } from '@ngrx/store';

interface IAsyncState {
  value: any;
  loading: boolean;
}

export function createLoadingSelector(get) {
  return {
    Value: createSelector(get, (state: IAsyncState) => state.value),
    Loading: createSelector(get, (state: IAsyncState) => state.loading),
  };
}
