import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { AppPartnersActions } from '~/store/app-partners/app-partners.reducer';
import { IAppState } from '~/store/app-state';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-partners-dropdown',
  templateUrl: './partners.dropdown.html',
  providers: controlValueAccessor(AppPartnerDropdownComponent),
})
export class AppPartnerDropdownComponent implements ControlValueAccessor, OnInit {
  partners$ = this.store.select('app-partners', 'value').pipe(
    filter(a => !!a),
    map(partners => [...partners, { id: null, name: 'None' }])
  );

  @Input() readOnly = false;

  partner;
  private onChange;

  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.store.dispatch(AppPartnersActions.Load());
  }

  writeValue(partner: any): void {
    this.partner = partner;
    if (partner === null) {
      this.partner = { id: null, name: 'None' };
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onChangeInternal() {
    this.onChange(this.partner);
  }

  registerOnTouched(fn: any): void {
    return;
  }

  setDisabledState?(isDisabled: boolean): void {
    return;
  }
}
