<form *ngIf="featureLists$ | async as featureLists" [formGroup]="migrateForm">
  <div *ngIf="billing$ | async as billing">
    <div *ngIf="currentBillingVersion$ | async as currentBillingVersion">
      <div class="modal-header u-color-brand">
        <h3>Migrate subscription</h3>
        <div class="pointer" (click)="modal.dismiss()">
          <hl-icon icon="close-circle-24" style="width: 24px; height: 24px;"></hl-icon>
        </div>
      </div>
      <div class="modal-body">
        <!--- Current status -->
        <div class="c-box" *ngIf="currentBillingVersion === 1">
          <h2 class="u-color-neutral-darker">Features: {{ featureLists.currentFeaturesLists | featureListV1 }}</h2>
          <div class="u-color-neutral u-margin-top-xs">Status: {{billing.status}}</div>
        </div>
        <div class="c-box" *ngIf="currentBillingVersion === 2">
          <h2 class="u-color-neutral-darker">Plan: Prime</h2>
          <h2
            class="u-color-neutral-darker"
            style="display: inline;"
            *ngIf="featureLists.currentPackFeatureLists.length"
          >
            Packs:
          </h2>
          <span
            class="u-text-m u-margin-left-xs u-color-neutral-darker wrap"
            *ngFor="let pack of featureLists.currentPackFeatureLists; last as isLast"
          >
            {{ pack.description }}
            <span *ngIf="!isLast">,</span>
          </span>
          <br />
          <h2
            class="u-color-neutral-darker"
            style="display: inline;"
            *ngIf="featureLists.currentIntegrationFeatureLists.length"
          >
            Integrations:
          </h2>
          <span
            class="u-text-m u-margin-left-xs u-color-neutral-darker wrap"
            *ngFor="let integration of featureLists.currentIntegrationFeatureLists; last as isLast"
          >
            {{ integration.description }}
            <span *ngIf="!isLast">,</span>
          </span>
          <div class="u-color-neutral u-margin-top-xs">Status: {{billing.status}}</div>
        </div>
        <!--- Mandatory feature list -->
        <div class="c-box u-margin-top-s">
          <pxc-select-one-feature-list-form
            formControlName="selectedMandatoryFeatureList"
            [diffCurrentAndTargetMandatoryFeatureLists]="featureLists.diffCurrentAndTargetMandatoryFeatureLists"
            [targetFeatureLists]="featureLists.targetMandatoryFeatureLists"
          ></pxc-select-one-feature-list-form>
        </div>
        <!-- Optional feature lists -->
        <div class="c-box u-margin-top-s" *ngIf="targetOptionalFeatureLists$ | async as targetOptionalFeatureLists">
          <pxc-select-multiple-feature-list-form
            formControlName="selectedOptionalFeatureLists"
            [targetFeatureLists]="targetOptionalFeatureLists"
          ></pxc-select-multiple-feature-list-form>
        </div>
      </div>
      <div class="modal-footer flex between">
        <div>
          <div *ngIf="migrateForm.get('selectedMandatoryFeatureList').value" class="u-text-m u-color-neutral">
            You upgrade from {{ currentBillingVersion === 2 ? 'Prime' : (featureLists.currentFeaturesLists |
            featureListV1) }} to {{migrateForm.get('selectedMandatoryFeatureList').value.description}}
          </div>
        </div>
        <div>
          <span class="color-danger text-bold u-margin-right-s" *ngIf="errMsg">
            {{errMsg}}
          </span>
          <pxc-button type="submit" [onClick]="migrate()" [disabled]="migrateForm.invalid">
            Migrate
          </pxc-button>
        </div>
      </div>
    </div>
  </div>
</form>
