import { Injectable, Injector } from '@angular/core';
import { ICompany, IGroup, IUser } from '@proxyclick/data-model';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, filter, last, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ICompanyUsersFilter, IHasCompanyAndUserId, IHasOrganisationAndUserId, IOrganisationUsersFilter, IUsersFilter } from '~/models/filters';
import { BaseService } from '~/services/base.service';
import { BatchService } from '~/shared/api-client/batch.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { filterNull } from '~/utils/utils';
import { PxcConverter } from './pxc-converter.service';

export interface IUpdatePassword extends IHasCompanyAndUserId {
  password: string;
}

export interface IUpdateEmail {
  email: string;
  newEmail: string;
}

export interface IUpdateEmailWithUserId {
  email: string;
  userId: string;
}

export interface IUpdateHomeLocation {
  users: { email: string }[];
  newHomeLocation: string;
}

export interface ILinkUserToCompany extends IHasCompanyAndUserId {}

export interface IUnlinkUserFromOrganisation extends IHasOrganisationAndUserId {}

export interface ILinkUserToCompanies {
  userId: string;
  companies: ICompany[];
}

export interface IUnlinkableCompanies {
  defaultHost: string[];
  watchListAlert: string[];
}

@Injectable()
export class UserService extends BaseService {
  constructor(
    injector: Injector,
    private Notifications: NotificationsService,
    private batch: BatchService,
    private pxcConverter: PxcConverter
  ) {
    super(injector);
  }

  getUsers(filter: IUsersFilter): Observable<IUser[]> {
    filter = filterNull(filter);
    return this.apiClient
      .AdminUsers()
      .doGet(filter)
      .pipe(map(users => users.users));
  }

  getUsersForCompany(filter: ICompanyUsersFilter) {
    return this.apiClient.ApplicationUsers(filter.companyId).doGet(filter);
  }

  getUsersForOrganisation(filter: IOrganisationUsersFilter) {
    return this.apiClient.OrganisationUsers(filter.organisationId).doGet(filter);
  }

  getUser(userId: string): Observable<IUser> {
    return this.apiClient.AdminUser(userId).doGet();
  }

  getUserCompanies(userId: string) {
    return this.apiClient
      .AdminUserCompanies(userId)
      .doGet()
      .pipe(switchMap((companies: ICompany[]) => this.isAdminBatch(userId, companies)));
  }

  getUserGroupMembers(companyId: string, groupId: number): Observable<IUser[]> {
    return this.apiClient
      .ApplicationUserGroupMembers(companyId, groupId)
      .doGet({
        pageSize: 1000,
      })
      .pipe(map(admins => admins.members));
  }

  addUserGroupsForUserAndCompany(companyId: string, groupId: number, userId: string) {
    return this.apiClient
      .ApplicationUserGroupMember(companyId, groupId, userId)
      .doPut()
      .pipe(
        tap(() =>
          this.Notifications.success(`User Group ${groupId} has been added for user ${userId} for ${companyId}`)
        )
      );
  }

  removeUserGroupsForUserAndCompany(companyId: string, groupId: number, userId: string) {
    return this.apiClient
      .ApplicationUserGroupMember(companyId, groupId, userId)
      .doDelete()
      .pipe(
        tap(() =>
          this.Notifications.success(`User Group ${groupId} has been removed for user ${userId} for ${companyId}`)
        )
      );
  }

  getGroupsForUser(companyId: string, userId: string): Observable<IGroup[]> {
    return this.apiClient.ApplicationUserUserGroups(companyId, userId).doGet();
  }

  isAdminBatch(userId: string, companies: ICompany[]) {
    const urls = companies.map(company => {
      return {
        url: this.isUserGroupMember(company.id, 2, userId).url,
        method: 'GET',
      };
    });
    return this.batch.batch(urls).pipe(
      map(responses => responses.map(response => (response.code === 204 ? true : false))),
      map(responses => {
        let i = 0;
        const result = [];
        for (const company of companies) {
          result.push({
            ...company,
            isAdmin: responses[i++],
          });
        }
        return result;
      })
    );
  }

  isUserGroupMember(companyId: string, groupId: number, userId: string) {
    return this.apiClient.ApplicationUserGroupMember(companyId, groupId, userId);
  }

  getAdmin(companyId: string) {
    return this.getUserGroupMembers(companyId, 2);
  }

  getRecentUsers() {
    return this.getUsers({
      sort: 'desc',
      sortBy: 'lastlog',
      pageSize: 100,
    });
  }

  searchUsers(term: string, domain?: string, companyId?: string) {
    return term.startsWith('US-') && this.pxcConverter.isValidReference(term)
      ? this.getUser(term).pipe(
          map(c => (c ? [c] : [])),
          catchError(err => of([]))
        )
      : this.getUsers({ q: term, domain, companyId }).pipe(catchError(err => of([])));
  }

  async logAsUser(user: IUser, companyId?: string) {
    const data = await this.generateUserApplicationToken(user.id).toPromise();
    let url = `${this.config.getConfig().app.baseUrl}/authenticate?accessToken=${data.token}&hiddenMode=1`;
    if (companyId) {
      url += `&companyId=${companyId}`;
    }
    window.open(url, '_blank');
  }

  generateUserApplicationToken(userId: string) {
    return this.apiClient.AdminUserGenerateApplicationToken(userId).doPost();
  }

  updatePassword(data: IUpdatePassword) {
    return this.apiClient
      .AdminUserPassword(data.userId)
      .doPut({ password: data.password })
      .pipe(
        tap(() => this.Notifications.success('Password has been updated')),
        map(() => data)
      );
  }

  updateEmail(data: IUpdateEmailWithUserId) {
    return this.apiClient
      .AdminUserEmail(data.userId)
      .doPut({ email: data.email })
      .pipe(
        tap(() => this.Notifications.success('Email has been updated')),
        map(() => data)
      );
  }

  updateEmailBatch(data: IUpdateEmail[]) {
    return this.apiClient
      .AdminUserEmailBatchUpdate()
      .doPut({ data: data })
      .pipe(
        tap(() => this.Notifications.success('Multiple emails have been updated')),
        map(result => result)
      );
  }

  updateHomeLocation(data: IUpdateHomeLocation) {
    return this.apiClient
      .AdminUserHomeLocation()
      .doPut(data)
      .pipe(
        tap(() => this.Notifications.success('Home location has been updated for multiple emails')),
        map(result => result)
      );
  }

  linkToCompany(data: ILinkUserToCompany) {
    return this.apiClient
      .AdminUserCompanyAdd(data.userId)
      .doPost({ companyId: data.companyId })
      .pipe(
        tap(() => this.Notifications.success('User linked to company')),
        map(() => data)
      );
  }

  linkToMultipleCompanies(data: ILinkUserToCompanies) {
    const observables = data.companies.map(company =>
      this.apiClient.AdminUserCompanyAdd(data.userId).doPost({ companyId: company.id })
    );

    return forkJoin(observables).pipe(
      tap(() => this.Notifications.success('User linked to multiple companies')),
      map(() => data)
    );
  }

  unlinkFromCompany(data: ILinkUserToCompany) {
    return this.apiClient
      .ApplicationUser(data.companyId, data.userId)
      .doDelete({})
      .pipe(
        tap(() => this.Notifications.success(`User unlinked from company ${data.companyId}`)),
        map(() => data)
      );
  }

  unlinkFromOrganisation(data: IUnlinkUserFromOrganisation) {
    return this.apiClient
      .OrganisationUser(data.organisationId, data.userId)
      .doDelete({})
      .pipe(
        tap(() => this.Notifications.success(`User unlinked from organisation ${data.organisationId}`)),
        map(() => data)
      );
  }

  unlinkFromAllCompanies(data: ILinkUserToCompanies & { originalCompanyId: string }) {
    const originalCompanyInLinkedCompanies = data.companies.some(company => company.id === data.originalCompanyId);
    const filterCompanies = data.companies.filter(company => company.id !== data.originalCompanyId);

    if (filterCompanies.length === 0) {
      return this.unlinkFromCompany({ companyId: data.originalCompanyId, userId: data.userId });
    }

    return from(filterCompanies).pipe(
      mergeMap(company => this.apiClient.ApplicationUser(company.id, data.userId).doDelete()),
      last(),
      tap(() => this.Notifications.success('User unlinked from all companies')),
      filter(() => originalCompanyInLinkedCompanies),
      switchMap(() => this.apiClient.ApplicationUser(data.originalCompanyId, data.userId).doDelete()),
      map(() => this.Notifications.success('User unlinked from original company'))
    );
  }

  setGlobalAdmin(userId: string, isGlobalAdmin: boolean) {
    return this.apiClient.AdminUserGlobalAdmin(userId).doPut({
      isGlobalAdmin: isGlobalAdmin,
    });
  }

  setBlocked(userId: string, isBlocked: boolean) {
    return this.apiClient.AdminUserBlock(userId).doPut({
      isBlocked: isBlocked,
    });
  }

  getWatchListAlertUsers(companyId: string): Observable<IUser[]> {
    return this.apiClient
      .ApplicationVisitorManagementWatchListsAlertsUsers(companyId)
      .doGet()
      .pipe(map(users => users));
  }

  cloneUsersForCompany(originalCompanyId: string, targetCompanyId: string) {
    return this.apiClient
      .AdminCloneUsersForCompany(originalCompanyId, targetCompanyId)
      .doPut()
      .pipe(tap(data => this.Notifications.success(`${data.total} users have been cloned to ${targetCompanyId}`)));
  }

  getUserDifferenceBetweenCompanies(originalCompanyId: string, targetCompanyId: string) {
    return this.apiClient
      .AdminUserDifferenceBetweenCompanies(originalCompanyId, targetCompanyId)
      .doGet()
      .pipe(map(data => data));
  }
}
