import { Component } from '@angular/core';
import { AppStore } from '~/store/store';
import { SafeUrl } from '@angular/platform-browser';
import { ConfigService } from '~/services/config.service';

@Component({
  selector: 'pxc-company-qr',
  templateUrl: './company-qr.html',
})
export class CompanyQrComponent {
  companyId$ = this.store.select('company', 'company', 'value', 'id');
  qrCodeSrc: SafeUrl;
  dashboardBaseUrl: string;

  constructor(private store: AppStore, private Config: ConfigService) {}

  ngOnInit() {
    this.dashboardBaseUrl = this.Config.getConfig().app.baseUrl.replace('admin', 'app') + '/backend/employee/presence';
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeSrc = url;
  }
}
