<div *pxcHasRight='"tab_marketplace"'>
  <ul class="nav nav-tabs my-4">
    <li class="nav-item">
      <a routerLink="/marketplace/integrations" class="nav-link" routerLinkActive="active">Integrations</a>
    </li>
    <li class="nav-item">
      <a routerLink="/marketplace/partners" class="nav-link" routerLinkActive="active">Partners</a>
    </li>
  </ul>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
  <form *ngIf="!(loading$ | async) && partner" class="p-2" (ngSubmit)="onSave(form.value)" #form="ngForm">
    <div class="pxc-partner-details__form">
      <div class="pxc-partner-details__info">
        <div class="form-group">
          <label class="label">Name</label>
          <input class="form-control" type="text" [(ngModel)]="partner.name" required name="name" />
        </div>
        <div class="form-group">
          <label class="label">Alias</label>
          <input class="form-control" type="text" [(ngModel)]="partner.alias" required name="alias" />
        </div>
        <div class="form-group">
          <label class="label">Title</label>
          <input class="form-control" type="text" [(ngModel)]="partner.tagline" name="tagline" />
        </div>
        <div class="form-group">
          <label class="label">Website</label>
          <input class="form-control" type="text" [(ngModel)]="partner.website" name="website" />
        </div>
        <div class="form-group">
          <label class="label">Email</label>
          <input class="form-control" type="text" [(ngModel)]="partner.email" name="email" />
        </div>
        <div class="form-group">
          <label class="label">Country</label>
          <input class="form-control" type="text" [(ngModel)]="partner.country" name="country" />
        </div>
        <div class="form-group">
          <label class="label">Youtube video ID</label>
          <input class="form-control" type="text" placeholder="tgbNymZ7vqY" [(ngModel)]="partner.video" name="video" />
        </div>
        <div class="form-group">
          <label class="label"
            >Order
            <input class="form-control input-xs" type="number" [(ngModel)]="partner.order" name="order" />
          </label>
        </div>
      </div>
      <div class="flex column">
        <div class="form-group">
          <label class="label">Categories</label>
          <pxc-checkbox-list [items]="categories" (onChange)="onCategoriesUpdate($event)"></pxc-checkbox-list>
        </div>
        <div class="form-group">
          <label class="label">Regions</label>
          <pxc-checkbox-list [items]="regions" (onChange)="onRegionsUpdate($event)"></pxc-checkbox-list>
        </div>
        <div class="form-group">
          <label class="label">
            <strong>Active</strong>
          </label>
          <pxc-switch [(ngModel)]="partner.active" name="active"></pxc-switch>
        </div>
      </div>
      <div class="flex column between">
        <div class="pxc-partner-details__logo">
          <pxc-image-upload [(ngModel)]="partner.logo" required name="logo"></pxc-image-upload>
        </div>
        <div class="form-group">
          <div class="control form-buttons">
            <button
              type="submit"
              *pxcHasRight="'marketplace_edit_partners'"
              [disabled]="!form.valid || (loading$ | async) || (partner.logo && partner.logo.loading)"
              class="c-button c-button--primary c-button--s"
              [ngClass]="{'is-loading': (loading$ | async)}"
            >
              <i class="fas fa-save"></i> {{ partner.id ? 'Save' : 'Create'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <simplemde class="pxc-markdown-editor" [(ngModel)]="partner.description" name="description"></simplemde>
    <pxc-markdown-preview [content]="partner.description"></pxc-markdown-preview>

    <div class="mt-1">
      <pxc-images-manager [(ngModel)]="partner.pictures" name="pictures"></pxc-images-manager>
    </div>
  </form>
</div>
