<pxc-loading [loading]="loading$ | async"></pxc-loading>
<div *ngIf="!(loading$ | async) && integrations$ | async" class="p-2">
  <button
    class="c-button c-button--primary c-button--s"
    *pxcHasRight="'marketplace_edit_integrations'"
    routerLink="./new"
  >
    <i class="fas fa-plus"></i> Create
  </button>
  <table class="c-table mt-2">
    <thead>
      <tr>
        <th></th>
        <th>Order</th>
        <th>Name</th>
        <th>Title</th>
        <th>Active</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let integration of integrations$ | async | sortBy" class="pxc-integration-row table-row">
        <td><img *ngIf="integration.logo" src="{{integration.logo.url}}" /></td>

        <td>{{integration.order}}</td>
        <td><a routerLink="{{integration.id}}">{{integration.name}}</a></td>
        <td>{{integration.tagline}}</td>
        <td [ngClass]="getActiveClass(integration)">{{isActive(integration)}}</td>
        <td>
          <button
            class="btn btn-outline-success visible-on-hover"
            *pxcHasRight="'marketplace_edit_integrations'"
            routerLink="{{integration.id}}"
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
        </td>
        <td>
          <button
            class="btn btn-outline-danger visible-on-hover"
            *pxcHasRight="'marketplace_edit_integrations'"
            (click)="onDelete(integration)"
            [ngClass]="{'is-loading': integration.isDeleting}"
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
