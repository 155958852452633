import { NgModule } from '@angular/core';
import { ApiClient } from '@proxyclick/api-client';
import { ColdFusionApiClient } from '@proxyclick/api-client';
import { PxcApiClient, PxcColdFusionApiClient } from './api-client';
import { BatchService } from './batch.service';
import { PxcApiClientAdapterService } from './pxc-api-client-adapter.service';

@NgModule({
  providers: [
    BatchService,
    PxcApiClientAdapterService,
    {
      provide: PxcApiClient,
      deps: [PxcApiClientAdapterService],
      useFactory: (pxcApiClientAdapter: PxcApiClientAdapterService) => {
        return new ApiClient(pxcApiClientAdapter);
      },
    },
    //Backward compatibility to avoid upgrading all the code at once
    {
      provide: PxcColdFusionApiClient,
      deps: [PxcApiClientAdapterService],
      useFactory: (pxcApiClientAdapter: PxcApiClientAdapterService) => {
        return new ColdFusionApiClient(pxcApiClientAdapter);
      },
    },
  ],
})
export class ApiClientModule {}
