import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IMCategory } from '@proxyclick/data-model';
import { Observable } from 'rxjs';
import { CategoriesActions, CategoryActions } from '~/store/categories/categories.reducer';

@Component({
  selector: 'pxc-categories',
  templateUrl: './categories.html',
  styleUrls: ['./categories.scss'],
})
export class CategoriesPageComponent implements OnInit {
  public categories$: Observable<IMCategory[]> = this.store.select('categories', 'value');
  public loading$: Observable<boolean> = this.store.select('categories', 'loading');

  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.store.dispatch(CategoriesActions.Load());
  }

  saveCategories(categories: IMCategory[]) {
    const integrationCategories = categories.filter(cat => cat.type === 'INTEGRATION');
    this.store.dispatch(CategoryActions.UPDATE(integrationCategories));
  }
}
