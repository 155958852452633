import { Component, Input, OnInit } from '@angular/core';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import {
  CardFormatsCardFormatEntity,
  ClientLoginTokenScopeOrganization,
} from '@proxyclick/extender-server-openpath-client';

@Component({
  selector: 'app-fastpace-company-setup-extenders-openpath',
  templateUrl: './openpath-setup.component.html',
})
export class OpenPathSetupComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;
  constructor(private companyService: CompaniesService, private extenderService: ExtenderService) {}
  async ngOnInit(): Promise<void> {
    this.organizations = (
      await this.extenderService.openpath.organizations.listAll(this.setupState.extender.extenderId)
    ).filter(x => x.id !== null);
    this.setOrganizationOptions();
  }

  organizations: ClientLoginTokenScopeOrganization[] = [];
  organizationOptions: ClientLoginTokenScopeOrganization[] = [];
  organizationOption: ClientLoginTokenScopeOrganization | null = null;

  cardFormats: CardFormatsCardFormatEntity[] = [];

  async organizationOptionChanged() {
    if (this.organizationOption && this.setupState.openPathOrganization !== this.organizationOption) {
      this.setupState.openPathOrganization = this.organizationOption;
      this.cardFormats = await this.extenderService.openpath.cardFormats.listAll(
        this.setupState.extender.extenderId,
        this.setupState.openPathOrganization.id
      );
      if (this.cardFormats.length > 0) {
        this.setupState.openPathQrCodeCardFormat = this.cardFormats[0];
      } else {
        this.setupState.openPathQrCodeCardFormat = null;
      }
    }
  }

  setOrganizationOptions() {
    this.organizationOptions = this.organizations;
    if (this.organizationOptions.length > 0) {
      this.organizationOption = this.organizationOptions[0];
    } else {
      this.organizationOption = null;
    }
  }

  canGoNext() {
    return this.setupState.openPathOrganization && this.setupState.openPathQrCodeCardFormat;
  }
}
