import { Component, Input, OnInit } from '@angular/core';
import { FastPaceSetupCompanyState } from '../fastpace-setup-company.state';
import { CustomFieldType, ICustomFieldType, ICustomField } from '@proxyclick/data-model';
import { CompaniesService } from '~/services/companies.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-fastpace-company-setup-customfields-accesslevels',
  templateUrl: './customfields-accesslevels.component.html',
})
export class CustomFieldAccessLevelsComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;
  customFieldsAsAccessLevels: boolean = false;
  customFields: ICustomField[] = [];

  constructor(private companyService: CompaniesService) {}

  ngOnInit(): void {
    this.companyService
      .getCustomFields(this.setupState.company.id)
      .toPromise()
      .then(c => {
        this.customFields = c.filter(c => c.type.label === 'LIST');
      });
  }
  changeCustomFieldsAsAccessLevels() {
    if (this.customFieldsAsAccessLevels === false) {
      this.setupState.accessLevelsCustomField = undefined;
    }
  }

  selectCustomField(id: number) {
    this.setupState.accessLevelsCustomField = this.customFields.filter(c => c.id === id)[0];
  }

  canGoNext() {
    return !this.customFieldsAsAccessLevels || this.setupState.accessLevelsCustomField !== undefined;
  }
}
