import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '~/store/app-state';
import { CountriesActions } from '~/store/regions/regions.reducer';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-country-picker',
  templateUrl: './country-picker.html',
  providers: controlValueAccessor(CountryPickerComponent),
})
export class CountryPickerComponent implements OnInit, ControlValueAccessor {
  countries$ = this.store.select('countries', 'value');
  countryCode: string;

  private onChange;

  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.store.dispatch(CountriesActions.Load());
  }

  onOptionClick(event) {
    this.onChange(event.target.value);
  }

  writeValue(countryCode: string): void {
    this.countryCode = countryCode;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    return;
  }
  setDisabledState?(isDisabled: boolean): void {
    return;
  }
}
