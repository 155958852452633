import { Component, Input } from '@angular/core';
import { FeatureListRef } from '@proxyclick/data-model';

@Component({
  selector: 'pxc-pack-icon',
  template: '<hl-icon icon="{{icon}}"></hl-icon>',
})
export class PackIconComponent {
  @Input() pack: FeatureListRef;
  @Input() size: string;

  get icon() {
    return this.icons[this.size || '40'][this.pack] || null;
  }

  private icons = {
    '40': {
      prime: 'pack-prime-40',
      efficiency: 'pack-efficiency-40',
      security: 'pack-security-40',
      privacy: 'pack-privacy-40',
      delivery: 'pack-delivery-40',
    },
    '75': {
      prime: 'pack-prime-75',
      efficiency: 'pack-efficiency-75',
      security: 'pack-security-75',
      privacy: 'pack-privacy-75',
      delivery: 'pack-delivery-75',
    },
  };
}
