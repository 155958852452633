<pxc-loading [loading]="loading$ | async">
  <form *ngIf="address$ | async as address" ngNativeValidate class="form-small">
    <pxc-field label="Address">
      <input type="text" required class="form-control" [(ngModel)]="address.address.addressLine" name="address" />
    </pxc-field>
    <pxc-field label="City">
      <input type="text" class="form-control" [(ngModel)]="address.address.city" name="city" />
    </pxc-field>
    <pxc-field label="ZIP">
      <input type="text" class="form-control" [(ngModel)]="address.address.postalCode" name="zip" />
    </pxc-field>
    <pxc-field label="Country">
      <pxc-country-picker [(ngModel)]="address.address.countryCode" name="country"></pxc-country-picker>
    </pxc-field>
    <pxc-field label="Region">
      <input type="text" class="form-control" [(ngModel)]="address.address.stateOfRegion" name="state" />
    </pxc-field>
    <pxc-button *pxcHasRight="'company_vm_options'" [onClick]="updateAddress(address)">Update Address</pxc-button>
    <pxc-button class="u-margin-left-s" *pxcHasRight="'company_vm_options'" [onClick]="refreshGoogleMaps(address)">
      <i class="fas fa-map-marker-alt"></i>
      <span class="u-margin-left-2xs">Refresh Google Maps</span>
    </pxc-button>
  </form>
</pxc-loading>
