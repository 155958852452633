<div class="full-height">
  <form #form="ngForm" (ngSubmit)="onSubmit(password, passwordCheck)" class="pxc-login">
    <img class="logo" src="assets/img/logo-square.png" />
    <div class="forgot-password__form">
      <label class="mb-2">
        <input
          class="form-control large grey no-borders round"
          type="password"
          [(ngModel)]="password"
          name="password"
          required
          placeholder="New password"
        />
      </label>
      <label class="mb-4">
        <input
          class="form-control large grey no-borders round"
          type="password"
          [(ngModel)]="passwordCheck"
          name="passwordCheck"
          required
          placeholder="Retype your password"
        />
      </label>
      <strong *ngIf="error" class="text-danger forgot-password__error">{{error}}</strong>
    </div>
    <label class="mb-4 font-grey">
      <pxc-password-strength
        [password]="password"
        (onStrengthChange)="onStrengthChange($event)"
      ></pxc-password-strength>
    </label>
    <button *ngIf="!loading" class="btn btn-primary btn-round btn-xl">Reset password</button>
    <button *ngIf="loading" class="btn btn-primary btn-round btn-xl">
      <i class="fas fa-sync-alt fa-spin fa-lg"></i>
    </button>
  </form>
</div>
