import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IFeatureListMultiPrice, IIntegration, IMCategory, IPlan } from '@proxyclick/data-model';
import * as _ from 'lodash';
import { combineLatest as observableCombineLatest, Observable, of as observableOf, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { IChecked } from '~/models/utils';
import { IAppState } from '~/store/app-state';
import { IntegrationActions } from '~/store/integrations/integrations.reducer';

interface ICheckedCategory extends IMCategory, IChecked {}

const callToActionOptions = ['INSTALL', 'EMAIL', 'VOTE'];

@Component({
  selector: 'pxc-integration-details',
  templateUrl: './integration-details.html',
  styleUrls: ['./integration-details.scss'],
})
export class IntegrationDetailsPageComponent implements OnInit, OnDestroy {
  public integration$ = this.store.select('integration', 'value');
  public loading$ = this.store.select('integration', 'loading');
  public categories$: Observable<IMCategory[]>;
  public plans$: Observable<IFeatureListMultiPrice[]>;

  public integration: IIntegration;
  public categories: ICheckedCategory[] = [];

  public callToActionOptions = callToActionOptions;

  private componentDestroyed: Subject<any> = new Subject();
  private currencyCodes = ['EUR', 'GBP', 'USD'];
  private allPlans = ['Essential', 'Premium', 'Enterprise'];
  private requiredPlans;
  public selectedAddOnPlans;
  private plans;
  public integrationPlans = [];
  public addOnPlans = [];

  constructor(private store: Store<IAppState>, private route: ActivatedRoute) {}

  ngOnInit() {
    this.categories$ = this.store
      .select('categories', 'value')
      .pipe(map(categories => categories && categories.filter(c => c.type === 'INTEGRATION')));

    this.plans$ = this.store
      .select('featureLists', 'value')
      .pipe(map(feature => feature && feature.filter(f => this.allPlans.find(p => p === f.description))));

    this.integration$.pipe(takeUntil(this.componentDestroyed)).subscribe(integration => {
      this.integration = integration;
      for (const currencyCode of this.currencyCodes) {
        if (this.integration && !this.integration.prices[currencyCode]) {
          this.integration.prices[currencyCode] = null;
        }
      }
      this.calculateIntegrationPlans();
      this.calculateAddOnPlans();
    });

    this.plans$.pipe(takeUntil(this.componentDestroyed)).subscribe(plans => (this.plans = plans));

    observableCombineLatest(this.categories$.pipe(filter(a => !!a)), this.integration$.pipe(filter(a => !!a)))
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([categories, integration]) => this.applyCategories(categories, integration));

    this.route.params.subscribe(params => {
      const id = params['id'];
      const action = id === 'new' ? IntegrationActions.NEW() : IntegrationActions.Load(id);
      this.store.dispatch(action);
    });
  }

  applyCategories(categories, integration) {
    this.categories = categories;
    this.categories.forEach(
      category => (category.checked = integration.categories.some(cat => cat.id === category.id))
    );
  }

  onSave() {
    this.integration.categories = <IMCategory[]>(
      this.categories.filter(c => c.checked).map(c => _.pick(c, 'id', 'name', 'type'))
    );

    const call = _.has(this.integration, 'id')
      ? () => this.store.dispatch(IntegrationActions.UPDATE(this.integration))
      : () => this.store.dispatch(IntegrationActions.CREATE(this.integration));

    call();
  }

  onPlanUpdate(plan: IPlan) {
    this.integration.plan = plan;
  }

  onCategoriesUpdate(categories) {
    this.integration.categories = categories.filter(c => c.checked).map(c => _.pick(c, 'id', 'name', 'type'));
  }

  onRequiredUpdate(required: { checked: boolean; name: string }[] | null) {
    this.integration.required = '';
    if (required !== null && required.length > 0) {
      this.integration.required = required.map(r => r.name).join(',');
    }
  }

  onAddOnUpdate(addOn: { checked: boolean; name: string }[] | null) {
    this.integration.addOn = '';
    if (addOn !== null && addOn.length > 0) {
      this.integration.addOn = addOn.map(r => r.name).join(',');
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  calculateIntegrationPlans() {
    this.integrationPlans = [];
    if (this.plans && this.integration) {
      this.plans.forEach(p => {
        this.requiredPlans = this.integration && this.integration.required && this.integration.required.split(',');
        const checked = this.requiredPlans && this.requiredPlans.find(req => req.trim() === p.description);
        this.integrationPlans.push({ name: p.description, checked: checked ? true : false });
      });
    }
  }

  calculateAddOnPlans() {
    this.addOnPlans = [];
    if (this.plans && this.integration) {
      this.plans.forEach(p => {
        this.selectedAddOnPlans = this.integration && this.integration.addOn && this.integration.addOn.split(',');
        const checked = this.selectedAddOnPlans && this.selectedAddOnPlans.find(req => req.trim() === p.description);
        this.addOnPlans.push({ name: p.description, checked: checked ? true : false });
      });
    }
  }
}
