import { Component, Input, OnInit } from '@angular/core';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import {
  LocationsLocationSettingsResponseData,
  LocationsLocationData,
  TenantsTenantEntity,
} from '@proxyclick/extender-server-genea-client';

@Component({
  selector: 'app-fastpace-company-setup-extenders-genea',
  templateUrl: './genea-setup.component.html',
})
export class GeneaSetupComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;
  constructor(private companyService: CompaniesService, private extenderService: ExtenderService) {}
  async ngOnInit(): Promise<void> {
    this.locations = (await this.extenderService.genea.locations.listAll(this.setupState.extender.extenderId)).data;
    this.setLocationOptions();
  }

  locations: LocationsLocationData[];
  locationOptions: LocationsLocationData[] = [];
  locationOption: LocationsLocationData | null = null;
  locationOptionSettings: LocationsLocationSettingsResponseData | null = null;

  tenants: TenantsTenantEntity[] = [];
  tenantOptions: TenantsTenantEntity[] = [];
  tenantOption: TenantsTenantEntity | null = null;

  tenantOptionChanged() {
    if (this.tenantOption) {
      this.setupState.geneaTenant = this.tenantOption;
    }
  }

  async locationOptionChanged() {
    if (this.locationOption && this.setupState.geneaLocation !== this.locationOption) {
      this.setupState.geneaLocation = this.locationOption;
      this.setupState.geneaLocationSettings = await this.extenderService.genea.locations
        .getSettings(this.setupState.extender.extenderId, this.setupState.geneaLocation.uuid)
        .then(r => r.data);

      if (this.setupState.geneaLocation.type == 'ENTERPRISE_BUILDING') {
        this.tenants = await this.extenderService.genea.tenants.listAll(
          this.setupState.extender.extenderId,
          this.setupState.geneaLocation.uuid
        );
        this.setTenantOptions();
      } else {
        this.setupState.geneaTenant = null;
        this.tenants = [];
        this.tenantOptions = [];
        this.tenantOption = null;
      }
    }
  }

  setTenantOptions() {
    this.tenantOptions = this.tenants;
    if (this.tenantOptions.length > 0) {
      this.tenantOption = this.tenantOptions[0];
    } else {
      this.tenantOption = null;
    }
  }

  setLocationOptions() {
    this.locationOptions = this.locations;
    if (this.locationOptions.length > 0) {
      this.locationOption = this.locationOptions[0];
    } else {
      this.locationOption = null;
    }
  }

  canGoNext() {
    return (
      this.setupState.geneaLocation &&
      this.setupState.geneaLocationSettings &&
      this.setupState.geneaLocationSettings.hardwareProvider == 'Mercury'
    );
  }
}
