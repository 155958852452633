import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { INotification } from '~/models/notifications';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';

@Component({
  selector: 'pxc-notifications',
  templateUrl: './notifications.html',
  styleUrls: ['./notifications.scss'],
})
export class NotificationComponent implements OnInit {
  notifications$: Observable<INotification[]>;

  notifications: INotification[] = [];

  constructor(private notificationsService: NotificationsService) {}

  ngOnInit() {
    this.notificationsService.notifications$.subscribe(notif => {
      notif.class = notif.type;
      this.notifications.push(notif);
      setTimeout(() => this.dismiss(notif), 5000);
    });
  }

  dismiss(notif) {
    _.remove(this.notifications, n => n === notif);
  }
}
