import { createSelector } from '@ngrx/store';
import { IAppState } from '~/store/app-state';
import { IFastPaceState } from '~/store/fastpace/fastpace.state';

export class FastPaceSelector {
  public static get = (state: IAppState) => state.fastpace;

  public static getCompaniesLoading = createSelector(
    FastPaceSelector.get,
    (fastPaceState: IFastPaceState) => fastPaceState.companies.data.loading
  );

  public static getCompaniesError = createSelector(
    FastPaceSelector.get,
    (fastPaceState: IFastPaceState) => fastPaceState.companies.data.error
  );

  public static getCompanies = createSelector(
    FastPaceSelector.get,
    (fastPaceState: IFastPaceState) => fastPaceState.companies.data.value
  );

  public static getCompaniesFilter = createSelector(
    FastPaceSelector.get,
    (fastPaceState: IFastPaceState) => fastPaceState.companies.filter
  );

  public static getCompanyLoading = createSelector(
    FastPaceSelector.get,
    (fastPaceState: IFastPaceState) => fastPaceState.companies.current.loading
  );

  public static getCompanyError = createSelector(
    FastPaceSelector.get,
    (fastPaceState: IFastPaceState) => fastPaceState.companies.current.error
  );

  public static getCompany = createSelector(
    FastPaceSelector.get,
    (fastPaceState: IFastPaceState) => fastPaceState.companies.current.value
  );
}
