import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ICompanyFilter } from '~/models/filters';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { CompaniesActions } from '~/store/companies/companies.actions';
import { CompaniesSelector } from '~/store/companies/companies.selector';
import { AppStore } from '~/store/store';

@Component({
  selector: 'pxc-companies',
  templateUrl: './companies.html',
})
export class CompaniesPageComponent implements OnInit, OnDestroy {
  companies$ = this.store.select(CompaniesSelector.get);
  loading$ = this.store.select(CompaniesSelector.isLoading);

  filter: ICompanyFilter;
  columns: IOption[] = [
    { label: 'Ref', value: 'ref' },
    { label: '', value: null },
    { label: 'Name', value: 'company' },
    { label: 'Domain', value: 'domain' },
    { label: 'Status', value: 'status' },
    { label: 'Plans', value: 'featureListId' },
    { label: 'Checkins last 30 days', value: 'checkins' },
    { label: 'Users', value: 'userCount' },
    { label: 'Country', value: null },
    { label: 'Created', value: 'since' },
  ];
  total: number;
  limit: number;
  companiesFilterSubscription: Subscription;
  constructor(private store: AppStore) {}

  ngOnInit() {
    this.filter = {
      q: '',
      page: 1,
      pageSize: 100,
      sortBy: 'since',
      sort: 'desc',
    };
    this.loadCompanies();

    this.companiesFilterSubscription = this.companies$.pipe(filter(a => !!a)).subscribe(companies => {
      this.total = companies.total;
      this.limit = companies.limit;
    });
  }

  ngOnDestroy(): void {
    this.companiesFilterSubscription.unsubscribe();
  }

  loadCompanies() {
    this.store.dispatchAsync(CompaniesActions.Load(this.filter));
  }

  onFilterChange(f) {
    this.filter = f;
    this.loadCompanies();
  }

  onPageChange(page) {
    this.filter.page = page;
    this.loadCompanies();
  }
}
