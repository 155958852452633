import { Component, OnInit } from '@angular/core';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';
import { FastPaceService } from '~/services/fastpace.service';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { ControllersCompaniesNetboxCompanySettingsResponseV1 } from '@proxyclick/fastpace-client';
import { v4 as uuidv4 } from 'uuid';
import { IOption } from '~/shared/components/input/radio/radio.component';

@Component({
  selector: 'app-fastpace-company-netbox',
  templateUrl: './fastpace.company.netbox.component.html',
})
export class FastPaceCompanyNetboxComponent extends FastPaceBaseSystemSettingsComponent implements OnInit {
  constructor(fastpaceService: FastPaceService, companyService: CompaniesService, extenderService: ExtenderService) {
    super(fastpaceService, companyService, extenderService);
  }
  async ngOnInit(): Promise<void> {
    this.netboxSettings = await this.fastpaceService.getNetboxSettings(this.fastpaceCompany.companyId);

    this.manageAccessLevels = this.netboxSettings.manageAccessLevels;
    this.useMultiPartitionAccessLevelGroups = this.netboxSettings.useMultiPartitionAccessLevelGroups;
    this.visitorIdField = this.netboxSettings.visitorIdField;
    this.visitorStatusField = this.netboxSettings.visitorStatusField;
    const response = await this.extenderService.netbox.cardFormats.listAll(this.fastpaceCompany.extenderId, {
      requestTracingId: uuidv4(),
    });
    this.cardFormats = response as unknown as string[];

    this.cardFormatOptions = this.cardFormats.map(x => {
      return { value: x, label: x };
    });

    this.cardFormatOption = this.netboxSettings.cardFormat
      ? { value: this.netboxSettings.cardFormat, label: this.netboxSettings.cardFormat }
      : null;
  }

  //current settings
  netboxSettings: ControllersCompaniesNetboxCompanySettingsResponseV1 | null = null;
  //new settings
  manageAccessLevels: boolean;
  useMultiPartitionAccessLevelGroups: boolean;
  visitorIdField: number;
  visitorStatusField: number;

  cardFormats: string[] = [];
  cardFormatOptions: IOption[] = [];
  cardFormatOption: IOption | null = null;

  switchManageAccessLevels() {
    if (!this.manageAccessLevels) {
      this.useMultiPartitionAccessLevelGroups = false;
    }
  }

  switchUseMultiPartitionAccessLevelGroups() {
    if (this.useMultiPartitionAccessLevelGroups) {
      this.manageAccessLevels = true;
    }
  }

  get canSubmit(): boolean {
    return this.submitting !== true;
  }

  async doUpdate(): Promise<void> {
    return this.fastpaceService.updateNetboxSettings(this.fastpaceCompany.companyId, {
      manageAccessLevels: this.manageAccessLevels,
      useMultiPartitionAccessLevelGroups: this.useMultiPartitionAccessLevelGroups,
      readerFilterIn: [],
      readerFilterOut: [],
      visitorIdField: this.visitorIdField,
      visitorStatusField: this.visitorStatusField,
      cardFormat: this.cardFormatOption ? this.cardFormatOption.value : null,
    });
  }
}
