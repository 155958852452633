import { Injectable } from '@angular/core';
import { _ } from '~/libs';

interface IdObject {
  id: number | string;
  object: string;
}

const typePrefixes = {
  user: 'US',
  corporate: 'CO',
  department: 'DE',
  building: 'BU',
  location: 'LO',
  caterer: 'CA',
  supplier: 'SU',
  lunch: 'L',
  catering: 'C',
  parking: 'P',
  visitor: 'V',
  meeting: 'M',
  ticket: 'T',
  document: 'DOC',
  item: 'I',
};

const format = /^([A-Z]{1,3})-([A-Z]+)([0-9]{3})$/;
const globalSearch = /[A-Z]{1,3}-[A-Z]+[0-9]{3}/g;
const idGlobalSearch = /[0-9]+/g;

const customBase = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'J',
  'K',
  'L',
  'M',
  'N',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

@Injectable()
export class PxcConverter {
  /**
   * Computes the pxc ref for an object
   * @param the input pxc object
   */
  // eslint-disable-next-line complexity
  getPxcFromId(object: IdObject): string {
    let result: string;

    if (object.id === undefined || object.object === undefined) {
      throw new Error('Invalid pxc object');
    }

    // Get the prefix
    const prefix = typePrefixes[object.object.toLowerCase()];

    if (!prefix) {
      throw new Error('Unknown pxc object: ' + object.object);
    }

    // Return object id if it's already in the correct format
    if (format.exec('' + object.id)) {
      return '' + object.id;
    }

    if (typeof object.id === 'string') {
      object.id = parseInt(object.id);
    }

    // Handle user special cases
    if (object.object === 'user' && object.id === -1) {
      return 'SYSTEM';
    }
    if (object.object === 'user' && object.id === -2) {
      return 'SUPPORT';
    }

    if (object.id < 0) {
      throw new Error('Negative id: ' + object.id);
    }

    result = prefix + '-';

    // Add the id encoded in special base
    result += this.encodeBaseN(object.id, customBase);

    // Add a checksum
    result += this.computeChecksum(object.id);

    return result;
  }

  /**
   * Computes the pxc ref for an object
   * @param the input pxc object
   */
  getIdFromPxc(ref: string): string {
    const parts = format.exec(ref);
    if (!parts) {
      return;
    }

    const [full, type, cleanRef, checksum] = parts;
    if (!!typePrefixes[type]) {
      return;
    }

    const id = this.decodeBaseN(cleanRef, customBase);

    if (this.computeChecksum(id) !== checksum) {
      throw Error(`Invalid checksum [${ref}]`);
    }

    return id + '';
  }

  replacePxcRefInText(input: string) {
    return _.replace(input, globalSearch, val => this.getIdFromPxc(val));
  }

  replaceIdInText(input: string, type: string) {
    return _.replace(input, idGlobalSearch, val => this.getPxcFromId({ id: val, object: type }));
  }

  isValidReference(input: string) {
    try {
      this.getIdFromPxc(input);
      return true;
    } catch (err) {
      return false;
    }
  }

  /**
   * Encodes the id in a custom base
   * @param id
   * @param base: custom base
   */
  private encodeBaseN(id: number, base: string[]) {
    let workValue = id;
    let encodedValue = '';
    const radix = base.length;

    do {
      const result = Math.floor(workValue / radix);
      const remainder = workValue % radix;
      encodedValue = base[remainder] + encodedValue;
      workValue = result;
    } while (workValue > 0);

    return encodedValue;
  }

  /**
   * Decodes the id from a custom base
   * @param input
   * @param base: custom base
   */
  private decodeBaseN(input: string, base: string[]) {
    let result = 0;
    const radix = base.length;
    // Reverse the number
    const workValue = input.split('').reverse();
    const basePairs = _.toPairs(base);
    let index = 0;

    for (const value of workValue) {
      const found = _.find(basePairs, p => p[1] === value);
      const power = Math.pow(radix, index);
      const converted = parseInt(found[0]);
      result += converted * power;
      index++;
    }

    return result;
  }

  private computeChecksum(input: number) {
    const cksum = input.toString().slice(-1);
    const mod = ('0' + (input % 97).toString()).slice(-2);
    return cksum + mod;
  }
}
