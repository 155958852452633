import { createAsyncLoadReducer, createAsyncSaveReducer, PayloadAction } from '~/utils/async-generator';
import {
  OrganisationActions,
  OrganisationCompaniesActions,
  OrganisationDomainsActions,
  OrganisationDomainsAddActions,
  OrganisationDomainsDeleteActions,
  OrganisationPlatformTenantActions,
  OrganisationPlatformTenantAddActions,
  OrganisationUsersActions,
} from './organisation.actions';
import { combineReducers } from '@ngrx/store';
import { IAppState } from '../app-state';

export const organisationReducer = combineReducers({
  organisation: createAsyncLoadReducer(OrganisationActions),
  users: createAsyncLoadReducer(OrganisationUsersActions),
  companies: createAsyncLoadReducer(OrganisationCompaniesActions),
  tenantOrganisation: createAsyncLoadReducer(OrganisationPlatformTenantActions),
  'tenantOrganisation-add': createAsyncSaveReducer(OrganisationPlatformTenantAddActions),
  domains: domainReducer,
});

function domainReducer(
  state: IAppState['organisation']['domains'],
  action: PayloadAction
): IAppState['organisation']['domains'] {
  switch (action.type) {
    case OrganisationDomainsDeleteActions.Saved.type:
      return {
        ...state,
        value: state.value.filter(d => d.domain !== action.payload.domain),
      };
    case OrganisationDomainsAddActions.Saved.type:
      return {
        ...state,
        value: [...state.value, ...action.payload],
      };
    default:
      return createAsyncLoadReducer(OrganisationDomainsActions)(state as any, action) as any;
  }
}
