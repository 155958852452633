<div *pxcHasRight='"tab_stats"'>
  <table class="c-table mt-2">
    <thead>
      <th class="pointer" (click)="applySort('period')">
        Period <pxc-caret value="period" [sort]="filter.sort" [sortBy]="filter.sortBy"></pxc-caret>
      </th>
      <th class="pointer" (click)="applySort('visitor')">
        Visitors <pxc-caret value="visitor" [sort]="filter.sort" [sortBy]="filter.sortBy"></pxc-caret>
      </th>
      <th class="pointer" (click)="applySort('ticket')">
        Tickets <pxc-caret value="ticket" [sort]="filter.sort" [sortBy]="filter.sortBy"></pxc-caret>
      </th>
      <th class="pointer" (click)="applySort('total')">
        Transactions <pxc-caret value="total" [sort]="filter.sort" [sortBy]="filter.sortBy"></pxc-caret>
      </th>
      <th class="pointer" (click)="applySort('smsVisitor')">
        Sms Visitors <pxc-caret value="smsVisitor" [sort]="filter.sort" [sortBy]="filter.sortBy"></pxc-caret>
      </th>
      <th class="pointer" (click)="applySort('smsTicket')">
        Sms Tickets <pxc-caret value="smsTicket" [sort]="filter.sort" [sortBy]="filter.sortBy"></pxc-caret>
      </th>
      <th class="pointer" (click)="applySort('smsTotal')">
        Sms Total <pxc-caret value="smsTotal" [sort]="filter.sort" [sortBy]="filter.sortBy"></pxc-caret>
      </th>
    </thead>
    <tbody *ngIf="!(loading$ | async) && stats$ | async as stats">
      <tr *ngIf="stats.total as total">
        <td><strong>Total</strong></td>
        <td><strong>{{total.visitCount}}</strong></td>
        <td><strong>{{total.ticketCount}}</strong></td>
        <td><strong>{{total.total}}</strong></td>
        <td><strong>{{total.smsVisitCount}}</strong></td>
        <td><strong>{{total.smsTicketCount}}</strong></td>
        <td><strong>{{total.smsCount}}</strong></td>
      </tr>
      <tr *ngFor="let company of stats.data">
        <td>{{company.period}}</td>
        <td>{{company.visitCount}}</td>
        <td>{{company.ticketCount}}</td>
        <td>{{company.total}}</td>
        <td>{{company.smsVisitCount}}</td>
        <td>{{company.smsTicketCount}}</td>
        <td>{{company.smsCount}}</td>
      </tr>
    </tbody>
  </table>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
</div>
