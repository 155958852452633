import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IFeatureList } from '@proxyclick/data-model';
import * as _ from 'lodash';

// The order in which the icons are shown
const packsOrder = ['prime', 'efficiency', 'security', 'privacy', 'delivery'];

@Component({
  selector: 'pxc-pack-icon-list',
  templateUrl: './pack-icon-list.html',
  styleUrls: ['./pack-icon-list.scss'],
})
export class PackIconListComponent implements OnChanges, OnInit {
  @Input()
  featureLists: IFeatureList[] = [];

  filteredList: IFeatureList[];
  uid: string;
  popoverOptions = {
    placement: 'right',
    closeOnOutsideClick: true,
  };
  plan: string;
  zIndexBase = 1005;

  ngOnInit() {
    this.filteredList = this.filterFeatures(this.featureLists);
    this.uid = (Math.random() + '').substr(2);
    this.formatFeatureListRefForV1AndV3();
  }

  ngOnChanges() {
    this.filteredList = this.filterFeatures(this.featureLists);
  }

  formatFeatureListRefForV1AndV3() {
    this.featureLists.forEach(featureList => {
      const v1Regex = /vm_features_.*(free|small|medium|large|enterprise|custom|tenant).*(20[0-9][0-9])/;
      const v3Regex = /(essential|enterprise|premium).*(20[0-9][0-9])/;

      const v1Matches = v1Regex.exec(featureList.reference);
      const v3Matches = v3Regex.exec(featureList.reference);

      if (v1Matches) {
        this.plan = `${_.capitalize(v1Matches[1])} ${v1Matches[2]}`;
      } else if (featureList.reference && featureList.reference.startsWith('sd_')) {
        this.plan = 'Service Desk';
      } else if (v3Matches) {
        this.plan = `${_.capitalize(v3Matches[1])} ${v3Matches[2]}`;
      }
    });
  }

  private filterFeatures(featureLists: IFeatureList[]) {
    return _.chain(featureLists)
      .filter(fl => packsOrder.includes(fl.reference))
      .map(fl => ({
        ...fl,
        name: _.startCase(fl.reference),
      }))
      .orderBy(packsOrder)
      .value();
  }
}
