<form class="form-inline mb-2">
  <input
    placeholder="Filter companies..."
    class="form-control mr-2"
    type="text"
    [(ngModel)]="filter.q"
    name="searchTerm"
  />
</form>

<pxc-loading [loading]="loading$ | async">
  <pxc-sortable-table [filter]="filter" (onFilterChange)="onFilterChange()" [columns]="columns">
    <tbody *ngIf="companies$ | async as companies">
      <tr *ngFor="let company of companies | contains:filter.q:'name'">
        <td>{{company.id}}</td>
        <td>
          <img
            class="mr-1"
            *ngIf="company.countryCode"
            src="assets/img/flag/{{company.countryCode.toLowerCase()}}.png"
          />
        </td>
        <td>
          <a class="color-primary" routerLink="/companies/{{company.id}}">{{company.name}}</a>
          <i *ngIf="company.isAdmin" class="fas fa-user ml-2"></i>
        </td>
        <td>{{company.billing.status}}</td>
        <td>
          <pxc-pack-icon-list [featureLists]="company.billing.featureLists"></pxc-pack-icon-list>
        </td>
        <td>{{company.mrr}}</td>
        <td>{{company.checkinsLast30Days}}</td>
        <td>{{company.userCount}}</td>
        <td>{{company.createdAt | time:'L'}}</td>
      </tr>
    </tbody>
  </pxc-sortable-table>
</pxc-loading>
