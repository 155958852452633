<div *pxcHasRight='"tab_provisioning"'>
  <div class="flex between wrap">
    <div>
      <input [(ngModel)]="filter" class="form-control input-small" type="text" placeholder="Filter..." />
    </div>
    <div *pxcHasRight="'create_application'" class="flex center f-m-h-1 form-inline">
      <input [(ngModel)]="applicationName" class="form-control input-medium" type="text" placeholder="Name" />
      <pxc-checkbox [(ngModel)]="rights.user">
        User
      </pxc-checkbox>
      <pxc-checkbox [(ngModel)]="rights.company">
        Company
      </pxc-checkbox>
      <pxc-checkbox [(ngModel)]="rights.proxyclick">
        Proxyclick
      </pxc-checkbox>
      <button class="c-button c-button--primary c-button--s" (click)="createApplication(applicationName)">
        <i class="fa fa-plus"></i> Create
      </button>
    </div>
  </div>
  <pxc-loading [loading]="loading$ | async">
    <table *ngIf="applications$ | async as applications" class="c-table mt-4">
      <thead>
        <th>Name</th>
        <th>Access Types</th>
      </thead>
      <tbody>
        <tr *ngFor="let application of applications | contains:filter:'name'" class="table-row">
          <td><a routerLink="{{application.id}}">{{application.name}}</a></td>
          <td class="f-m-h-1">
            <span *ngFor="let access of application.accessTypes" class="badge badge-secondary">{{access}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </pxc-loading>
</div>
