import { Component } from '@angular/core';
import { Organisation } from '@proxyclick/api-client';
import { of as observableOf } from 'rxjs';
import { ICreateOrganisationForm, newOrganisation, OrganisationsService } from '~/services/organisations.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';

@Component({
  selector: 'pxc-create-organisation',
  templateUrl: './create-organisation.html',
})
export class CreateOrganisationPageComponent {
  organisation: ICreateOrganisationForm = newOrganisation;
  breadcrumbs$ = observableOf([
    { label: 'Organisations', url: '/organisations' },
    { label: 'New organisation', url: `/create` },
  ]);

  constructor(private Organisations: OrganisationsService, private Notifications: NotificationsService) {}

  onSubmit() {
    const users = this.organisation.users
      .replace(/([^\S\r\n])/g, '')
      .replace(/(\,|\;)/g, '\n')
      .split('\n')
      .filter(Boolean);

    this.Organisations.create({ name: this.organisation.name })
      .then(async (organisation: Organisation) => {
        const linkedUsersPromises = users.map((userId: string) =>
          this.Organisations.linkUserToOrganisation(organisation.id, userId)
        );

        Promise.all(linkedUsersPromises)
          .then(() => {
            this.Notifications.success('The organisation has been successfully created');
            this.organisation.users = '';
            this.organisation.name = '';
          })
          .catch(() => this.Notifications.error(`Error while linking users to organisation`));
      })
      .catch(() => this.Notifications.error(`Error while creating organisation`));
  }
}
