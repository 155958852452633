import { Component, Input, OnInit } from '@angular/core';
import {
  CardFormatsCardFormatResponseV1,
  CompaniesCompanyV1,
  PersonalDataTitlesPersonalDataTitleV1,
} from '@proxyclick/extender-server-amag-client';
import { ICustomField } from '@proxyclick/data-model';
import { v4 as uuidv4 } from 'uuid';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';

@Component({
  selector: 'app-fastpace-company-setup-extenders-amag',
  templateUrl: './amag-setup.component.html',
})
export class AMAGSetupComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;
  constructor(private companyService: CompaniesService, private extenderService: ExtenderService) {}
  async ngOnInit(): Promise<void> {
    this.customFields = await this.companyService.getCustomFields(this.setupState.company.id).toPromise();
    this.setCustomFieldOptions();

    this.companies = await this.extenderService.amag.companies.list(this.setupState.extender.extenderId, {
      requestTracingId: uuidv4(),
    });
    this.cardFormats = await this.extenderService.amag.cardFormats.listCardFormats(
      this.setupState.extender.extenderId,
      {
        requestTracingId: uuidv4(),
      }
    );

    this.setCompaniesOptions();
    this.setCardFormatOptions();
  }

  companies: CompaniesCompanyV1[] = [];
  companiesOptions: CompaniesCompanyV1[] = [];
  companyOption: CompaniesCompanyV1 | null = null;

  cardFormats: CardFormatsCardFormatResponseV1[] = [];
  cardFormatOptions: CardFormatsCardFormatResponseV1[] = [];
  qrCodeCardFormatOption: CardFormatsCardFormatResponseV1 | null = null;
  physicalCardFormatOption: CardFormatsCardFormatResponseV1 | null = null;

  mapCustomFields: boolean = false;

  customFields: ICustomField[] = [];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  amagCustomFields: PersonalDataTitlesPersonalDataTitleV1[] = [];
  amagCustomFieldOptions: PersonalDataTitlesPersonalDataTitleV1[] = [];
  amagCustomFieldOption: PersonalDataTitlesPersonalDataTitleV1 | null = null;

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.setupState.amagCustomFieldMappings = [];
      this.setCustomFieldOptions();
      this.setamagCustomFieldOptions();
    }
  }

  async amagCompanyOptionChanged() {
    this.setupState.amagCompany = this.companyOption;

    const personalDataTitles = await this.extenderService.amag.personalDataTitles.list(
      this.setupState.extender.extenderId,
      {
        requestCompanyId: this.companyOption.id,
        requestIncludeVisitors: false,
      }
    );

    this.amagCustomFields = personalDataTitles;
    this.setamagCustomFieldOptions();
    this.amagCustomFieldOption = this.amagCustomFieldOptions[0];
  }

  setCompaniesOptions() {
    this.companiesOptions = this.companies;
    if (this.companiesOptions.length > 0) {
      this.companyOption = this.companiesOptions[0];
    } else {
      this.companyOption = null;
    }
  }

  async amagCardFormatOptionChanged() {
    this.setupState.amagQrCodeCardFormatId = this.qrCodeCardFormatOption.id;
    this.setupState.amagQrCodeCardFormatName = this.qrCodeCardFormatOption.name;
    this.setupState.amagPhysicalCardFormatId = this.physicalCardFormatOption.id;
    this.setupState.amagPhysicalCardFormatName = this.physicalCardFormatOption.name;
  }

  setCardFormatOptions() {
    this.cardFormatOptions = this.cardFormats;
    if (this.cardFormatOptions.length > 0) {
      this.qrCodeCardFormatOption = this.cardFormatOptions[0];
      this.physicalCardFormatOption = this.cardFormatOptions[0];
    } else {
      this.qrCodeCardFormatOption = null;
      this.physicalCardFormatOption = null;
    }
  }

  setamagCustomFieldOptions() {
    const options = this.amagCustomFields.filter(c => {
      //Filter out custom fields already mapped
      return this.setupState.amagCustomFieldMappings.filter(a => a.amagPersonalDataTitle.id === c.id).length === 0;
    });

    this.amagCustomFieldOptions = options;
    if (this.amagCustomFieldOptions.length > 0) {
      this.amagCustomFieldOption = this.amagCustomFieldOptions[0];
    } else {
      this.amagCustomFieldOption = undefined;
    }
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return this.setupState.amagCustomFieldMappings.filter(a => a.customField.id === c.id).length === 0;
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = null;
    }
  }

  canAddMapping() {
    return (
      this.customFieldOption &&
      this.amagCustomFieldOption &&
      this.setupState.amagCustomFieldMappings.filter(
        c =>
          c.amagPersonalDataTitle.id === this.amagCustomFieldOption.id || c.customField.id === this.customFieldOption.id
      ).length === 0
    );
  }

  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }
    this.setupState.amagCustomFieldMappings.push({
      customField: this.customFieldOption,
      amagPersonalDataTitle: this.amagCustomFieldOption,
    });
    this.setCustomFieldOptions();
    this.setamagCustomFieldOptions();
  }

  removeMapping(id: number) {
    this.setupState.amagCustomFieldMappings = this.setupState.amagCustomFieldMappings.filter(
      c => c.customField.id !== id
    );
    this.setCustomFieldOptions();
    this.setamagCustomFieldOptions();
  }

  canGoNext() {
    if (
      (this.mapCustomFields && this.setupState.amagCustomFieldMappings.length < 1) ||
      (this.companies.length > 1 && this.setupState.amagCompany == null) ||
      (this.cardFormats.length > 1 && this.setupState.amagQrCodeCardFormatId == null) ||
      (this.cardFormats.length > 1 && this.setupState.amagPhysicalCardFormatId == null) ||
      this.setupState.amagCustomerCode == null
    ) {
      return false;
    }
    return true;
  }
}
