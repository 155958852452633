<div *pxcHasRight='"tab_partners"'>
  <pxc-breadcrumb [breadcrumbs]="breadcrumbs$ | async"></pxc-breadcrumb>
  <form #form="ngForm">
    <pxc-field label="Name">
      <input class="form-control input-medium" [(ngModel)]="partner.name" name="name" />
    </pxc-field>
    <pxc-field label="Displayed name">
      <input class="form-control input-medium" [(ngModel)]="partner.displayName" name="displayName" />
    </pxc-field>
    <pxc-field label="Partner signup page">
      <input class="form-control input-medium" [(ngModel)]="partner.alias" name="alias" />
    </pxc-field>
    <pxc-field label="Signup link">
      <pre>{{baseUrl}}/partner-signup/{{partner.alias}}</pre>
    </pxc-field>
    <pxc-field label="Language">
      <pxc-languages-dropdown [(ngModel)]="partner.language" name="language"></pxc-languages-dropdown>
    </pxc-field>

    <pxc-button *ngIf="form.dirty" class="u-margin-top-s" type="primary" [onClick]="create(partner)"
      >Create
    </pxc-button>
  </form>
</div>
