import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICompany, IGroup, IUser } from '@proxyclick/data-model';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IUnlinkableCompanies, UserService } from '~/services/users.service';
import { IHasBreadcrumbs } from '~/shared/components/breadcrumb/breadcrumb.component';
import { AppStore } from '~/store/store';
import {
  PxcUserDirectoryActions,
  UserActions,
  UserEmailActions,
  UserGroupActions,
  UserLinkMultipleCompaniesActions,
  UserPasswordActions,
  UserUnlinkCompanyActions,
  UserUnlinkFromAllCompaniesActions,
} from '~/store/users/users.actions';
import { userGroups } from '~/utils/user-groups';

@Component({
  selector: 'pxc-user-details',
  templateUrl: './user-details.html',
  styleUrls: ['./user-details.scss'],
})
export class UserDetailsComponent implements OnInit, IHasBreadcrumbs, OnDestroy {
  user$ = this.store.select('user', 'user', 'value');
  loading$ = this.store.select('user', 'user', 'loading');
  companies$ = this.user$.pipe(map(x => (!x ? [] : x.linkedCompanies)));
  linkedCompanies = [];
  companies = [];
  breadcrumbs$ = this.user$.pipe(
    filter(a => !!a),
    map((user: IUser) => [
      { label: 'Users', url: '/users' },
      { label: `${user.firstname} ${user.lastname}`, url: `/users/${user.id}` },
    ])
  );

  allUserGroups = userGroups;
  companyId: string;
  userId: string;
  subscription: Subscription;
  unlinkModal = {
    open: false,
    companyId: null,
    originalCompanyId: null,
  };

  pxcCredentialsModal = false;

  mainUserGroups = [];

  constructor(private store: AppStore, private route: ActivatedRoute, private Users: UserService) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.store.dispatch(UserActions.Load(params.userId));
      this.userId = params.userId;
      this.companyId = params.companyId;
    });

    this.subscription = this.companies$.subscribe(companies => {
      this.linkedCompanies = companies;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updatePassword(userId: string, password: string) {
    this.store.dispatch(UserPasswordActions.Load({ companyId: this.companyId, userId: userId, password: password }));
  }

  updateEmail(userId: string, email: string) {
    this.store.dispatch(UserEmailActions.Load({ companyId: this.companyId, userId: userId, email: email }));
  }

  save(userId: string, selectedCompanies: ICompany[]) {
    const filteredCompanies = selectedCompanies.filter(x => !this.linkedCompanies.find(y => y.id == x.id));
    this.store.dispatch(UserLinkMultipleCompaniesActions.Load({ userId: userId, companies: filteredCompanies }));
    this.companies = [];
  }

  async logAsUser(user: IUser) {
    this.Users.logAsUser(user);
  }

  setGlobalAdmin(user: IUser) {
    return isGlobalAdmin => this.Users.setGlobalAdmin(user.id, isGlobalAdmin);
  }

  setBlockedUser(user: IUser) {
    return isBlocked => this.Users.setBlocked(user.id, isBlocked);
  }

  onUserGroupChecked(event, companyId: string, userGroup: IGroup): void {
    const linkedCompany = this.linkedCompanies.filter(company => company.id === companyId).shift();

    if (event.target.checked) {
      if (!linkedCompany.removedUserGroups.includes(userGroup)) {
        linkedCompany.addedUserGroups.push(userGroup);
      }
      linkedCompany.userGroups.push(userGroup);
      linkedCompany.removedUserGroups = this.filterUsergroup(userGroup.id, linkedCompany.removedUserGroups);
    } else {
      if (!linkedCompany.addedUserGroups.includes(userGroup)) {
        linkedCompany.removedUserGroups.push(userGroup);
      }
      linkedCompany.userGroups = this.filterUsergroup(userGroup.id, linkedCompany.userGroups);
      linkedCompany.addedUserGroups = this.filterUsergroup(userGroup.id, linkedCompany.addedUserGroups);
    }
  }

  async saveUserGroupsForCompany(companyId: string): Promise<void> {
    const linkedCompany = this.linkedCompanies.filter(company => company.id === companyId).shift();

    await linkedCompany.addedUserGroups.map(userGroup =>
      this.store.dispatch(
        UserGroupActions.saveUserGroupForCompany({
          companyId,
          userId: this.userId,
          userGroupId: userGroup.id,
        })
      )
    );

    await linkedCompany.removedUserGroups.map(userGroup =>
      this.store.dispatch(
        UserGroupActions.removeUserGroupForCompany({
          companyId,
          userId: this.userId,
          userGroupId: userGroup.id,
        })
      )
    );

    linkedCompany.addedUserGroups = [];
    linkedCompany.removedUserGroups = [];
  }

  filterUsergroup(userGroupId: number, userGroups: IGroup[]): IGroup[] {
    return userGroups.filter(userGroup => userGroup.id !== userGroupId);
  }

  hasUserGroup(userGroup: IGroup, companyUserGroups: IGroup[]): IGroup {
    return companyUserGroups && companyUserGroups.find(checkedUserGroup => checkedUserGroup.id === userGroup.id);
  }

  onMainUserGroupChecked(event, userGroup: IGroup): void {
    if (event.target.checked) {
      this.mainUserGroups.push(userGroup);
    } else {
      this.mainUserGroups = this.filterUsergroup(userGroup.id, this.mainUserGroups);
    }
    this.linkedCompanies.map(linkedCompany => this.onUserGroupChecked(event, linkedCompany.id, userGroup));
  }

  async saveAllUserGroups(): Promise<void> {
    this.linkedCompanies.map(async linkedCompany => await this.saveUserGroupsForCompany(linkedCompany.id));
    this.mainUserGroups = [];
  }

  checkMainUserGroups(userGroup: IGroup): boolean {
    return this.mainUserGroups.some(mainUserGroup => mainUserGroup.id === userGroup.id);
  }

  showApplyToAllButton(): boolean {
    let show = false;
    this.linkedCompanies.map(linkedCompany => {
      if (linkedCompany.addedUserGroups.length !== 0 || linkedCompany.removedUserGroups.length !== 0) {
        show = true;
      }
    });
    return show;
  }

  unlinkUserFromCompany(companyId: string): void {
    this.store.dispatch(UserUnlinkCompanyActions.Load({ companyId, userId: this.userId }));
    this.linkedCompanies = this.linkedCompanies.filter(linkedCompany => linkedCompany.id !== companyId);
  }

  async unlinkAllCompanies(
    originalCompanyId: string,
    user: IUser & { unlinkableCompanies: IUnlinkableCompanies }
  ): Promise<void> {
    const filteredCompanies = this.linkedCompanies
      .filter(val => !user.unlinkableCompanies.defaultHost.includes(val.id))
      .filter(val => !user.unlinkableCompanies.watchListAlert.includes(val.id));
    await this.store.dispatch(
      UserUnlinkFromAllCompaniesActions.Load({
        companies: filteredCompanies,
        userId: this.userId,
        originalCompanyId,
      })
    );
  }

  async unlinkUserFromCompanies(user: IUser & { unlinkableCompanies: IUnlinkableCompanies }) {
    if (this.unlinkModal.open && this.unlinkModal.companyId) {
      this.unlinkUserFromCompany(this.unlinkModal.companyId);
    } else {
      this.linkedCompanies.length === 1 &&
      this.linkedCompanies.some(company => company.id === this.unlinkModal.originalCompanyId)
        ? this.unlinkUserFromCompany(this.unlinkModal.originalCompanyId)
        : await this.unlinkAllCompanies(this.unlinkModal.originalCompanyId, user).then(() => window.location.reload());
    }
    this.toggleUnlinkModal();
  }

  toggleUnlinkModal(originalCompanyId?: string, companyId?: string) {
    this.unlinkModal = !this.unlinkModal.open
      ? {
          open: true,
          companyId,
          originalCompanyId,
        }
      : {
          open: false,
          companyId: null,
          originalCompanyId: null,
        };
  }

  getNumberOfUnlinkableCompanies(unlinkableCompanies: IUnlinkableCompanies): number {
    return [...new Set([...unlinkableCompanies.defaultHost, ...unlinkableCompanies.watchListAlert])].length;
  }

  isCompanyInUnlinkableCompanies(companyId: string, unlinkableCompanies: IUnlinkableCompanies): boolean {
    return (
      unlinkableCompanies.watchListAlert.includes(companyId) || unlinkableCompanies.defaultHost.includes(companyId)
    );
  }

  getUnlinkText(companyId: string, unlinkableCompanies: IUnlinkableCompanies): string {
    if (unlinkableCompanies.watchListAlert.includes(companyId) && unlinkableCompanies.defaultHost.includes(companyId)) {
      return 'This company cannot be unlinked because the user is in the watchlist alerts and also a default host';
    } else if (unlinkableCompanies.watchListAlert.includes(companyId)) {
      return 'This company cannot be unlinked because the user is in the watchlist alerts';
    } else if (unlinkableCompanies.defaultHost.includes(companyId)) {
      return 'This company cannot be unlinked because the user is a default host';
    } else {
      return 'You can unlink this company for this user by clicking this icon';
    }
  }

  getCompanyNameById(companyId: string): string {
    const company = this.linkedCompanies.filter(company => company.id === companyId);
    return company[0].name;
  }

  removePxcDirectoryEntry() {
    this.store
      .dispatchAsync(PxcUserDirectoryActions.remove({ userId: this.userId }))
      .then(() => this.togglePxcCredentialsModal());
  }

  createPxcDirectoryEntry(email: string) {
    this.store
      .dispatchAsync(PxcUserDirectoryActions.create({ userId: this.userId, email }))
      .then(() => this.togglePxcCredentialsModal());
  }

  togglePxcCredentialsModal() {
    this.pxcCredentialsModal = !this.pxcCredentialsModal;
  }
}
