import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICompany, ICompanyParams, IInvitationEmailSettings, IPartner, IUser } from '@proxyclick/data-model';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ClipboardService } from '~/services/clipboard.service';
import { CompaniesService } from '~/services/companies.service';
import { ConfigService } from '~/services/config.service';
import { KioskService } from '~/services/kiosk.service';
import { RightService } from '~/services/rights.service';
import { UtilService } from '~/services/util.service';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { IAppState } from '~/store/app-state';
import { usersWithAdmin } from '~/store/companies/companies.selector';

const printTypeOptions: IOption[] = [
  { label: 'Airprint', value: 'AIRPRINT' },
  { label: 'Not airprint', value: 'OTHER' },
  { label: 'None', value: 'NONE' },
];
const infopackOptions: IOption[] = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];

const kioskVersionOptions: IOption[] = [
  { label: 'iOS', value: true },
  { label: 'Browser-Based', value: false },
];

const nameSuggestionLengthOptions: IOption[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
];

@Component({
  selector: 'pxc-company-settings',
  templateUrl: './company-settings.html',
})
export class CompanySettingsComponent implements OnInit, OnDestroy {
  company: ICompany;

  companyParams$ = this.store.select('company', 'params', 'value');

  vmParams$ = this.store.select('company', 'vmParams', 'value');
  vmInvitationalEmailParams$ = this.store.select('company', 'vmInvitationalEmailParams', 'value');
  kioskParams$ = this.store.select('company', 'kioskParams', 'value');
  loadingKioskParams$ = this.store.select('company', 'kioskParams', 'loading');
  kioskPartner$ = this.store.select('company', 'kioskPartner', 'value').subscribe(partner => {
    this.kioskPartner = partner;
  });

  admins$ = this.store.select('company', 'admins', 'value');
  users$ = usersWithAdmin(this.store.select('company', 'users', 'value'), this.admins$);
  loadingUsers$ = this.store.select('company', 'users', 'loading');

  kioskPartner: IPartner;
  printTypeOptions = printTypeOptions;
  infopackOptions = infopackOptions;
  kioskVersionOptions = kioskVersionOptions;
  nameSuggestionLengthOptions = nameSuggestionLengthOptions;
  maxCustomFieldsOptions = _.range(10, 30).map(i => ({
    label: i,
    value: i,
  }));
  thresholdSubject: Subject<number> = new Subject<number>();
  readOnly = {};
  users = [];

  subscription: Subscription;

  constructor(
    private store: Store<IAppState>,
    private Util: UtilService,
    private Config: ConfigService,
    private Kiosk: KioskService,
    private Notifications: NotificationsService,
    private Companies: CompaniesService,
    private Rights: RightService,
    private Clipboard: ClipboardService
  ) {}

  ngOnInit() {
    this.store.select('company', 'company', 'value').subscribe(company => {
      this.company = company;
      this.init();
    });

    this.subscription = this.users$.subscribe(users => {
      users.users.map(user => this.users.push({ label: `${user.firstname} ${user.lastname}`, value: user.id }));
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init() {
    this.readOnly = {
      options: !this.Rights.hasRight('company_vm_options'),
    };
    this.thresholdSubject
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        switchMap(value =>
          this.Companies.setVmParams(this.company.id, {
            idScanThreshold: value,
          })
        ),
        tap(() => this.Notifications.success('ID Match Threshold updated'))
      )
      .subscribe();
  }

  computeInviteLink(companyId: string) {
    return this.Util.encrypt(`new|${companyId}`).pipe(
      map(encrypted => {
        return this.Config.getConfig().app.baseUrl.replace('app', 'join') + '/en/' + encrypted.code;
      })
    );
  }

  updatePrintType(company: ICompany) {
    return printType =>
      this.Kiosk.setKioskParams(company.id, {
        printType: printType,
      }).pipe(tap(() => this.Notifications.success('Print type updated')));
  }

  updateKioskType(company: ICompany) {
    return value =>
      this.Kiosk.setKioskVersion(company.id, value).pipe(
        tap(() => this.Notifications.success('Kiosk version updated'))
      );
  }

  updateNameSuggestionLength(company: ICompany) {
    return nameSuggestionLength =>
      this.Kiosk.setKioskParams(company.id, {
        nameSuggestionLength: nameSuggestionLength,
      }).pipe(tap(() => this.Notifications.success('Kiosk name suggestion length updated')));
  }

  updateKioskPartner(company: ICompany, partner) {
    return partner && partner.id !== null
      ? this.Companies.setKioskPartner(company.id, partner.id).pipe(
          tap(() => (this.kioskPartner = partner)),
          tap(() => this.Notifications.success('Partner updated'))
        )
      : this.removeKioskPartner(company);
  }

  updateInfopack(company: ICompany) {
    return infopackEnabled =>
      this.Kiosk.setKioskParams(company.id, {
        infopackEnabled: infopackEnabled,
      }).pipe(tap(() => this.Notifications.success('Infopack updated')));
  }

  updateIdScan(company: ICompany) {
    return idScanEnabled =>
      this.Companies.setVmParams(company.id, {
        idScanEnabled: idScanEnabled,
      }).pipe(tap(() => this.Notifications.success('ID Match updated')));
  }

  updateCompanyParams(company: ICompany, params: ICompanyParams) {
    return () => this.Companies.updateCompanyParams(company.id, params);
  }

  updateVmInvitationalEmailParams(companyId: string, params: IInvitationEmailSettings) {
    return () => this.Companies.updateVmInvitationalEmailParams(companyId, params);
  }

  updateMaxCustomFields(company: ICompany) {
    return value =>
      this.Companies.setMaxCustomFields(company.id, value).pipe(
        tap(() => this.Notifications.success('Custom Fields Limit successfully changed'))
      );
  }

  updateDefaultHost(defaultHost: IUser) {
    return this.Kiosk.setKioskParams(this.company.id, { defaultHostId: defaultHost && defaultHost.id })
      .pipe(tap(() => this.Notifications.success('Default host has been updated')))
      .subscribe();
  }

  private removeKioskPartner(company) {
    return this.Companies.removeKioskPartner(company.id).pipe(
      tap(() => (this.kioskPartner = null)),
      tap(() => this.Notifications.success('Partner removed'))
    );
  }

  updateThreshold(value) {
    value = Math.min(100, Math.max(0, value));
    this.thresholdSubject.next(value);
  }

  copyInviteLinkToClipboard() {
    this.computeInviteLink(this.company.id).subscribe(joinLink => {
      this.Clipboard.copyToClipboard(joinLink);
      this.Notifications.success('Copied to clipboard');
    });
  }
}
