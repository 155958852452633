<div class="pxc-page">
  <div class="flex between mb-2">
    <form class="form-inline">
      <input
        placeholder="visitor@domain.com"
        class="form-control mr-2"
        type="text"
        [(ngModel)]="email"
        name="searchTerm"
      />
      <pxc-button (click)="loadVisitors()" [loading]="loading$">Find</pxc-button>
    </form>
  </div>

  <table class="c-table">
    <thead>
      <th>Visitor ID</th>
      <th>Company ID</th>
      <th>Company Name</th>
      <th>First Name</th>
      <th>Last Name</th>
    </thead>
    <ng-container *ngIf="(visitors$ | async)?.length > 0 || (visitors$ | async) !== null">
      <tbody *ngIf="!(loading$ | async) && (visitors$ | async)">
        <tr *ngFor="let visitor of (visitors$ | async)">
          <td>
            <a href="{{baseUrl + '/' + visitor.companyId + '/visitor-profile/' + visitor.id}}" target="_blank">
              {{visitor.id}}
            </a>
          </td>
          <td>{{visitor.companyId}}</td>
          <td>{{visitor.companyName}}</td>
          <td>{{visitor.firstname}}</td>
          <td>{{visitor.lastname}}</td>
        </tr>
      </tbody>
    </ng-container>
  </table>
  <ng-container *ngIf="((visitors$ | async)?.length === 0 || (visitors$ | async) === null) && !(loading$ | async)">
    <div class="u-flex u-flex-justify-center u-margin-top-l u-text-l mw-100">There are no results</div>
  </ng-container>
</div>
<pxc-loading [loading]="loading$ | async"> </pxc-loading>
