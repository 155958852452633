import { Component, OnInit } from '@angular/core';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { OrganisationsSelector } from '~/store/organisations/organisations.selector';
import { AppStore } from '~/store/store';
import { IOrganisationFilter } from '../../../../../shared/filters';
import { OrganisationsActions } from '~/store/organisations/organisations.actions';

@Component({
  selector: 'pxc-organisations',
  templateUrl: './organisations.html',
})
export class OrganisationsPageComponent implements OnInit {
  organisations$ = this.store.select(OrganisationsSelector.get);
  loading$ = this.store.select(OrganisationsSelector.isLoading);

  filter: IOrganisationFilter;
  columns: IOption[] = [
    { label: 'Ref', value: null },
    { label: 'Name', value: 'name' },
    { label: 'Admins', value: 'admins' },
    { label: 'Created', value: 'createdAt' },
  ];
  constructor(private store: AppStore) {}

  ngOnInit() {
    this.filter = {
      page: 1,
      pageSize: 100,
      sortBy: 'createdAt',
      sort: 'desc',
    };
    this.loadOrganisations();
  }

  loadOrganisations(page?: number) {
    if (page) {
      this.filter.page = page;
    } else {
      this.filter.page = 1;
    }
    this.store.dispatchAsync(OrganisationsActions.Load(this.filter));
  }

  onFilterChange(f) {
    this.filter = f;
    this.loadOrganisations();
  }

  onPageChange(page) {
    this.loadOrganisations(page);
  }
}
