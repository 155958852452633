import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IPartnerData } from '@proxyclick/data-model';
import { filter, map, tap } from 'rxjs/operators';
import { AppPartnersService } from '~/services/app-partners.service';
import { ConfigService } from '~/services/config.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { AppPartnerDetailsActions } from '~/store/app-partners/app-partners.reducer';
import { IAppState } from '~/store/app-state';

@Component({
  selector: 'pxc-app-partner-details',
  templateUrl: './app-partner-details.html',
})
export class AppPartnerDetailsPageComponent implements OnInit {
  partner$ = this.store.select('app-partner', 'value');
  loading$ = this.store.select('app-partner', 'loading');
  baseUrl;

  breadcrumbs$ = this.partner$.pipe(
    filter(a => !!a),
    map((partner: IPartnerData) => [
      { label: 'Partners', url: '/partners' },
      { label: `${partner.name}`, url: `/partners/${partner.id}` },
    ])
  );

  constructor(
    private store: Store<IAppState>,
    private route: ActivatedRoute,
    private Partners: AppPartnersService,
    private Notifications: NotificationsService,
    private Config: ConfigService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.store.dispatch(AppPartnerDetailsActions.Load(params.partnerId));
    });
    this.baseUrl = this.Config.getConfig().app.baseUrl;
  }

  update(partner: IPartnerData) {
    return this.Partners.updatePartner(partner).pipe(tap(() => this.Notifications.success('Succesfully updated')));
  }

  updatePicture(partner: IPartnerData, event) {
    this.Partners.setPicture(partner.id, event.token).subscribe(result =>
      this.Notifications.success('Updated picture')
    );
  }

  deleteLogo(partner: IPartnerData) {
    return this.Partners.deletePicture(partner.id).pipe(
      tap(result => this.Notifications.success('Logo removed')),
      tap(() => this.store.dispatch(AppPartnerDetailsActions.Load(partner.id)))
    );
  }
}
