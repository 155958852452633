import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { RightService } from '~/services/rights.service';
import { DialogService } from '~/shared/components/dialog/dialog.service';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { IAppState } from '~/store/app-state';
import { CompanyActions, CompanyDelete } from '~/store/companies/companies.actions';
import { AppStore } from '~/store/store';
import { UserRights } from '../../../../../../shared/models';

const tabs: IOption[] = [
  { label: 'Users', value: 'users' },
  { label: 'Settings', value: 'settings' },
  { label: 'Billing', value: 'billing' },
  { label: 'Address', value: 'address' },
  { label: 'Stats', value: 'stats' },
  { label: 'ID Match', value: 'idscan' },
  { label: 'Legal', value: 'legal' },
  { label: 'Access control', value: 'fastpace' },
  { label: 'QR code check-in', value: 'qr-code' },
];

@Component({
  selector: 'pxc-company-details',
  templateUrl: './company-details.html',
  styleUrls: ['./company-details.scss'],
})
export class CompanyDetailsPageComponent implements OnInit {
  company$ = this.store.select('company', 'company', 'value');
  loading$ = this.store.select('company', 'company', 'loading');

  tabs = tabs;

  constructor(
    private store: Store<IAppState>,
    private route: ActivatedRoute,
    private Rights: RightService,
    private dialog: DialogService,
    private appStore: AppStore,
    private router: Router
  ) {}

  ngOnInit() {
    if (!this.Rights.hasRight(UserRights.TabUsers)) {
      _.remove(tabs, t => t.value === 'users');
    }
    if (!this.Rights.hasRight(UserRights.TabStats)) {
      _.remove(tabs, t => t.value === 'stats');
    }
    this.route.params.subscribe(params => {
      this.store.dispatch(CompanyActions.Load(params.id));
    });
  }

  async deleteLocation(companyId: string) {
    const confirmation = await this.dialog.confirm(
      'Delete Location',
      'Are you sure you want to delete this location? This action is irreversible'
    );
    if (confirmation) {
      await this.appStore.dispatchAsync(CompanyDelete.Load(companyId));
      this.router.navigate(['/companies']);
    }
  }
}
