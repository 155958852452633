import { Component, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'pxc-json-beautifier',
  templateUrl: './json-beautifier.html',
  styleUrls: ['./json-beautifier.scss'],
})
export class JsonBeautifierComponent implements OnChanges {
  @Input() json;
  html;

  ngOnChanges() {
    this.computeHtml();
  }

  computeHtml() {
    this.html = '';
    _.forIn(this.json, (value, key) => {
      this.html += getHtml(value, key);
    });
  }
}

function getHtml(value: any, key: string) {
  if (!value) {
    return '';
  } else if (typeof value === 'object') {
    // Object
    let result = `<div class="json-object"><span class="json-key">${key}</span><br>`;
    _.forIn(value, (v, k) => {
      result += getHtml(v, k);
    });
    result += '</div>';
    return result;
  } else if (!isNaN(parseInt(key))) {
    // Array element
    return `<div>${value}</div>`;
  } else {
    // Simple value
    return `<div><span class="json-key">${key}</span> <span>${value}</span></div>`;
  }
}
