import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { CategoriesService } from '~/services/categories.service';
import { CategoriesActions, CategoryActions } from '~/store/categories/categories.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';
import { USER_LOGGED_IN } from '../app-state';

@Injectable()
export class CategoriesEffects {
  constructor(private actions$: Actions<PayloadAction>, private categories: CategoriesService) {}

  
  init$ = createEffect(() => this.actions$.pipe(
    ofType(USER_LOGGED_IN),
    map(() => CategoriesActions.Load())
  ));

  
  load$ = createEffect(() => generateAsyncEffect(this.actions$, CategoriesActions, () => this.categories.getCategories()));

  
  update$ = createEffect(() => this.actions$.pipe(
    ofType(CategoryActions.UPDATE),
    switchMap(action => this.categories.updateCategories(action.payload).pipe(map(() => CategoriesActions.Load())))
  ));
}
