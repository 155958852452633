import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { BaseService } from '~/services/base.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { IJWTPayload } from '../../../../shared/models';
import { JWTService } from './jwt.service';
import { RightService } from './rights.service';

const JWT_VALIDITY_HOURS = 8;

@Injectable()
export class AuthService extends BaseService {
  constructor(
    injector: Injector,
    private http: HttpClient,
    private router: Router,
    private JWT: JWTService,
    private Right: RightService,
    private notifications: NotificationsService
  ) {
    super(injector);
  }

  auth(username: string, password: string) {
    return this.http.post<{ access_token: string }>('/authenticate', {
      username,
      password,
    });
  }

  postCode(code: string) {
    return this.http.post('/sso', { code });
  }

  resetPassword(mail: string) {
    return this.http.post('/reset-password', {
      username: mail,
    });
  }

  isUserLoggedIn() {
    const payload = this.JWT.getParsedJWT();
    if (!payload) {
      return false;
    }
    if (this.isJWTExpired(payload)) {
      this.notifications.error('Your access has expired. Please login again');
      this.JWT.removeJWT();
      this.Right.clearCache();
      return false;
    }
    return true;
  }

  isJWTExpired(payload: IJWTPayload) {
    if (payload.infinite === true) {
      return false;
    }
    const diff = payload.createdAt && moment().diff(moment(payload.createdAt), 'hour');
    return diff >= JWT_VALIDITY_HOURS;
  }

  logout() {
    return this.http
      .post('/logout', {})
      .pipe(
        finalize(() => {
          this.JWT.removeJWT();
          this.Right.clearCache();
          this.router.navigate(['/login']);
        })
      )
      .subscribe();
  }

  setPassword(password: string, code: string) {
    return this.http.post('/set-password', {
      password,
      code,
    });
  }

  // https://github.com/rnadler/ng2-password-strength-bar/blob/master/src/passwordStrengthBar.component.ts
  // TODO: improve algorithm
  measurePasswordStrength(p: string) {
    if (!p) {
      return 0;
    }
    let _force = 0;
    const _regex = /[$-/:-?{-~!"^_`\[\]]/g; // "
    const _lowerLetters = /[a-z]+/.test(p);
    const _upperLetters = /[A-Z]+/.test(p);
    const _numbers = /[0-9]+/.test(p);
    const _symbols = _regex.test(p);

    const _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];

    let _passedMatches = 0;
    for (const _flag of _flags) {
      _passedMatches += _flag === true ? 1 : 0;
    }

    _force += p.length * 2 + (p.length >= 10 ? 1 : 0);
    _force += _passedMatches * 10;

    // penality (short password)
    _force = p.length <= 6 ? Math.min(_force, 10) : _force;

    // penality (poor variety of characters)
    _force = _passedMatches === 1 ? Math.min(_force, 10) : _force;
    _force = _passedMatches === 2 ? Math.min(_force, 20) : _force;
    _force = _passedMatches === 3 ? Math.min(_force, 40) : _force;

    return _force;
  }
}
