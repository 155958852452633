<!-- TODO: add Rights -->
<div class="pxc-page">
  <div class="flex between mb-2">
    <form class="form-inline">
      <input
        placeholder="Filter domains..."
        class="form-control mr-2"
        type="text"
        [(ngModel)]="filter.q"
        name="searchTerm"
      />
      <span class="mr-2">RM</span>
      <pxc-admins-typeahead
        class="mr-4"
        name="accountManager"
        [(ngModel)]="accountManager"
        (ngModelChange)="loadDomains()"
      ></pxc-admins-typeahead>
      <span class="mr-2">Relationship</span>
      <pxc-dropdown
        class="mr-2"
        [(ngModel)]="filter.relationship"
        [options]="relationshipOptions"
        name="relationship"
        (ngModelChange)="loadDomains()"
      >
      </pxc-dropdown>
      <input
        placeholder="Filter industry..."
        class="form-control mr-2"
        type="text"
        [(ngModel)]="filter.industry"
        name="industry"
      />
      <pxc-button (click)="loadDomains()" [loading]="loading$">Filter</pxc-button>
    </form>
  </div>

  <pxc-sortable-table [filter]="filter" (onFilterChange)="onFilterChange($event)" [columns]="columns">
    <tbody *ngIf="!(loading$ | async) && (domains$ | async)">
      <tr *ngFor="let domain of (domains$ | async).domains">
        <td><img class="logo-small" src="{{domain.logo}}" /></td>

        <td><a routerLink="{{domain.id}}">{{domain.name}}</a></td>
        <td>{{domain.accountManager && (domain.accountManager.firstName + ' ' + domain.accountManager.lastName)}}</td>
        <td>{{domain.checkinCount}}</td>
        <td>{{domain.mrr}}</td>
        <td>{{domain.companyCount}}</td>
        <td>{{domain.employeeCount}}</td>
        <td>{{domain.userCount}}</td>
        <td>{{domain.industry}}</td>
      </tr>
    </tbody>
  </pxc-sortable-table>
  <div class="mt-2">
    <ngb-pagination
      *ngIf="filter && total > limit"
      [collectionSize]="total"
      [(page)]="filter.page"
      [pageSize]="filter.pageSize"
      [maxSize]="10"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="onPageChange($event)"
    ></ngb-pagination>
  </div>
</div>
<pxc-loading [loading]="loading$ | async"> </pxc-loading>
