import { Component, Injector, OnInit } from '@angular/core';
import { IJsonData } from '@proxyclick/data-model';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
import { UserProvisioningService } from '~/services/ldap.service';
import { JsonDirectoryActions } from '~/store/user-provisioning/user-provisioning.reducer';
import { BasePage } from '~/utils/base-page';

const allFields = [
  'FIRSTNAME',
  'LASTNAME',
  'EMAIL',
  'PHONE',
  'MOBILE',
  'LANGUAGE',
  'FUNCTION',
  'ABOUT',
  'LICENSEPLATE',
];

@Component({
  selector: 'pxc-json-details',
  templateUrl: './json-details.html',
})
export class JsonDetailsPageComponent extends BasePage implements OnInit {
  loading$ = this.store.select('json-details', 'loading');
  json$ = this.store.select('json-details', 'value');

  fields = {};
  allFields = allFields;

  constructor(injector: Injector, private UserProvisioning: UserProvisioningService) {
    super(injector);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.store.dispatch(JsonDirectoryActions.Load(params.id));
    });

    this.json$.pipe(filter(a => !!a)).subscribe(json => {
      this.fields = {};
      json.fields.forEach(field => {
        this.fields[field] = field;
      });
    });
  }

  save(json: IJsonData) {
    const jsonFields = [];
    _.forIn(this.fields, value => {
      if (value) {
        jsonFields.push(value);
      }
    });
    const data = {
      directoryId: json.id,
      companyId: json.company.id,
      alias: json.alias,
      linkedCompanies: json.companies.map(c => c.id),
      fields: jsonFields,
    };

    return this.UserProvisioning.json()
      .save(json.company.id, data)
      .subscribe(
        r => {
          this.Notifications.success('Successfully updated');
          if (!json.id) {
            // Just created a new one
            this.store.dispatch(JsonDirectoryActions.Load(json.company.id));
          }
        },
        err => this.store.dispatch(JsonDirectoryActions.Load(json.company.id))
      );
  }
}
