<pxc-loading [loading]="loading$ | async">
  <p *ngIf="!(fastpaceSettings$ | async) && company$ | async as company">
    This location does not have access control enabled. <br />

    <button
      type="submit"
      routerLink="/fastpace/companies/{{company.id}}/setup"
      class="c-button c-button--primary c-button--s u-margin-top-s"
    >
      Setup company with access controls&nbsp;
    </button>
  </p>
  <div *ngIf="fastpaceSettings$ | async as fastpaceSettings">
    <app-fastpace-company-details [company]="fastpaceSettings"></app-fastpace-company-details>
  </div>
</pxc-loading>
