import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ErrorPageComponent } from '~/pages/error/error-page.component';

@NgModule({
  declarations: [ErrorPageComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,

    RouterModule.forChild([
      {
        path: 'error',
        component: ErrorPageComponent,
        data: {
          breadcrumb: 'Error',
        },
      },
    ]),
  ],
  exports: [RouterModule],
})
export class ErrorPageModule {}
