import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { CountriesService } from '~/services/countries.service';
import { RegionsService } from '~/services/regions.services';
import { CountriesActions, RegionsActions } from '~/store/regions/regions.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';
import { USER_LOGGED_IN } from '../app-state';

@Injectable()
export class RegionsEffects {
  constructor(
    private actions$: Actions<PayloadAction>,
    private regions: RegionsService,
    private country: CountriesService
  ) {}

  
  init$ = createEffect(() => this.actions$.pipe(
    ofType(USER_LOGGED_IN),
    map(() => RegionsActions.Load())
  ));

  
  loads$ = createEffect(() => generateAsyncEffect(this.actions$, RegionsActions, () => this.regions.getRegions()));

  
  countries$ = createEffect(() => generateAsyncEffect(this.actions$, CountriesActions, () => this.country.getCountries()));
}
