export const queries = {
  // Free trial last 6 Months
  freeTrials: {
    url: '/events-*/_search',
    query: {
      size: 0,
      query: {
        bool: {
          must: [
            {
              range: {
                timestamp: {
                  gte: 'now-6M/M',
                },
              },
            },
            {
              term: {
                type: 'signUp',
              },
            },
          ],
        },
      },
      aggs: {
        freeTrialByMonth: {
          date_histogram: {
            field: 'timestamp',
            interval: 'month',
          },
        },
      },
    },
  },
  // NPS last 6 months
  NPS: {
    url: '/events-*/_search',
    query: {
      size: 0,
      query: {
        bool: {
          must: [
            {
              range: {
                timestamp: {
                  gte: 'now-6M/M',
                },
              },
            },
            {
              term: {
                type: 'NPS_Score_updated',
              },
            },
          ],
        },
      },
      aggs: {
        NPSByMonth: {
          date_histogram: {
            field: 'timestamp',
            interval: 'month',
          },
          aggs: {
            score: {
              histogram: {
                field: 'score',
                interval: 1,
              },
            },
          },
        },
      },
    },
  },
  // Checkin per hour, last 24 hours
  checkinPerHour: {
    url: 'events-*/_search',
    query: {
      size: 0,
      query: {
        bool: {
          must: [
            {
              term: {
                type: {
                  value: 'visit-checked-in-event',
                },
              },
            },
            {
              range: {
                timestamp: {
                  gte: 'now-23H/H',
                },
              },
            },
          ],
        },
      },
      aggs: {
        checkinPerHour: {
          date_histogram: {
            field: 'timestamp',
            interval: 'hour',
          },
        },
      },
    },
  },
  checkinPerCountry: {
    // Checkin per country, last 24 hours
    url: 'events-*/_search',
    query: {
      size: 0,
      query: {
        bool: {
          must: [
            {
              term: {
                type: {
                  value: 'visit-checked-in-event',
                },
              },
            },
            {
              range: {
                timestamp: {
                  gte: 'now-24H/H',
                },
              },
            },
          ],
        },
      },
      aggs: {
        checkinPerCountry: {
          terms: {
            field: 'company.country',
          },
        },
      },
    },
  },
};
