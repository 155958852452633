<div class="c-card__section">
  <div class="l-form__field">
    <label class="c-label">Token group</label>
    <div class="c-helper">
      Tokens for a visitor need to be unique within the access control system. As multiple tenants within a building as
      well as multiple physical buildings can share the same access control system, a token group defines for which
      companies the token needs to be unique. Most often this is a token group per installed extender (as it is
      connected to a single system) but certain setups, such as distributed access control systems or custom
      configuration could require using multiple groups per extender.
    </div>
    <ul class="c-list c-list--bare">
      <li class="u-margin-top-m">
        <div>
          <input
            id="extender"
            name="tokenGroupMode"
            [checked]="tokenGroupMode.value === 'extender'"
            (change)="tokenGroupModeChanged('extender')"
            type="radio"
            class="c-radio"
            value="extender"
            [disabled]="tokenGroupsOnExtender.length < 1"
          />
          <label for="extender" class="c-radio__skin"></label>
          <label for="extender" class="c-label c-label--radio">Select existing group on extender</label>
          <div class="u-color-accent-orange" *ngIf="tokenGroupsOnExtender.length < 1">
            There are no token groups on this extender
          </div>
          <div
            *ngIf="tokenGroupsOnExtender.length > 0"
            class="l-stack c-box u-padding-all-none u-margin-left-m"
            style="width: 95%;"
          >
            <div class="l-stack__item">
              <div class="c-stacked-list c-stacked-list--s">
                <ul class="c-stacked-list__list">
                  <li *ngFor="let tokenGroup of tokenGroupsOnExtender" class="c-stacked-list__item">
                    <a
                      class="c-stacked-list__inner c-stacked-list__inner--interactive"
                      (click)="selectTokenGroup(tokenGroup.value)"
                    >
                      <div class="c-stacked-list__inner-start">
                        {{ tokenGroup.label }}
                      </div>
                      <div class="c-stacked-list__inner-end">
                        <span class="c-icon c-icon--m">
                          <hl-icon
                            icon="checkmark-24"
                            width="24"
                            height="24"
                            *ngIf="tokenGroup.value === selectedTokenGroup"
                          ></hl-icon>
                        </span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="u-margin-top-m">
        <div>
          <input
            id="search"
            name="tokenGroupMode"
            [checked]="tokenGroupMode.value === 'search'"
            (change)="tokenGroupModeChanged('search')"
            type="radio"
            class="c-radio"
            value="search"
          />
          <label for="search" class="c-radio__skin"></label>
          <label for="search" class="c-label c-label--radio">Search an existing group</label>
          <div class="c-input c-input--s u-margin-bottom-s u-margin-left-m" style="width: 95%;">
            <div class="c-input__prefix c-input__prefix--shift">
              <span class="c-icon c-icon--s">
                <hl-icon icon="search-24"></hl-icon>
              </span>
            </div>
            <input
              [(ngModel)]="tokenGroupSearchTerm"
              class="c-input c-input--s c-input--naked"
              type="text"
              placeholder="Search by name or id"
              [disabled]="loading.value || tokenGroupMode.value !== 'search'"
            />
            <div class="c-input__suffix">
              <button
                class="c-action"
                tabindex=""
                (click)="searchTokenGroup()"
                type="button"
                [disabled]="loading.value"
              >
                <span class="c-action__label">Search</span>
              </button>
            </div>
          </div>
          <div
            class="l-stack c-box u-padding-all-none u-margin-left-m"
            *ngIf="searchedTokenGroups.length > 0"
            style="width: 95%;"
          >
            <div class="l-stack__item">
              <div class="c-stacked-list c-stacked-list--s">
                <ul class="c-stacked-list__list">
                  <li *ngFor="let tokenGroup of searchedTokenGroups" class="c-stacked-list__item">
                    <a
                      class="c-stacked-list__inner c-stacked-list__inner--interactive"
                      (click)="selectTokenGroup(tokenGroup.value)"
                    >
                      <div class="c-stacked-list__inner-start">
                        {{ tokenGroup.label }}
                      </div>
                      <div class="c-stacked-list__inner-end">
                        <span class="c-icon c-icon--m">
                          <hl-icon
                            icon="checkmark-24"
                            width="24"
                            height="24"
                            *ngIf="tokenGroup.value === selectedTokenGroup"
                          ></hl-icon>
                        </span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="u-margin-top-m">
        <div>
          <input
            id="create"
            name="tokenGroupMode"
            [checked]="tokenGroupMode.value === 'create'"
            (change)="tokenGroupModeChanged('create')"
            type="radio"
            class="c-radio"
            value="create"
          />
          <label for="create" class="c-radio__skin"></label>
          <label for="create" class="c-label c-label--radio">Create a new group</label>
          <input
            type="text"
            class="c-input c-input--s u-margin-left-m"
            [disabled]="loading.value || tokenGroupMode.value !== 'create'"
            minlength="10"
            style="width: 95%;"
            maxlength="150"
            [(ngModel)]="createTokenGroupName"
            (change)="tokenGroupNameChanged($event)"
          />
          <div class="c-helper">
            Provide a name that indicates the scope for this group. It needs to be unique and within 10-150 characters.
          </div>
        </div>
      </li>
    </ul>
    <hl-spinner *ngIf="loading.value"></hl-spinner>
  </div>
  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext"></app-fastpace-setup-footer>
</div>
