<form #form="ngForm" class="u-flex u-flex-align-items-center">
  <div style="max-width: 400px;">
    <div class="u-margin-bottom-xs">
      <pxc-dropdown
        [(ngModel)]="features"
        [readOnly]="readOnly"
        name="features"
        label="reference"
        [options]="featuresList"
      >
      </pxc-dropdown>
    </div>
    <span>{{features.description}}</span>
  </div>
  <pxc-button
    *ngIf="form.controls.features && form.controls.features.dirty"
    [onClick]="updateFeatures(company, module, features)"
    [disabled]="!form.valid"
    class="u-margin-left-m"
  >
    Update features
  </pxc-button>
</form>
