<div class="c-card__section">
  <div class="c-banner c-banner--informative" *ngIf="setupState.selectedTokenGroup">
    <div class="c-banner__message">
      Token range from selected token group will be used.
    </div>
  </div>
  <div
    class="c-banner c-banner--informative"
    *ngIf="
      !setupState.selectedTokenGroup &&
      setupState.useExtender &&
      setupState.extender.extenderType.tokenRangeType == 'Proxyclick'
    "
  >
    <div class="c-banner__message">
      For this extender type you probably won't need to adjust the default token range as it should have a range unique
      for the Proxyclick card format.
    </div>
  </div>

  <div
    class="c-banner c-banner--informative"
    *ngIf="
      !setupState.selectedTokenGroup &&
      setupState.useExtender &&
      setupState.extender.extenderType.tokenRangeType == 'CardFormat'
    "
  >
    <div class="c-banner__message">
      For this extender type you may need to adjust the default token range as it should have a range unique for the
      selected card format.
    </div>
  </div>

  <div
    class="c-banner c-banner--danger"
    *ngIf="
      !setupState.selectedTokenGroup &&
      setupState.useExtender &&
      setupState.extender.extenderType.tokenRangeType == 'Global'
    "
  >
    <div class="c-banner__message">
      For this extender type you quite likely will need to adjust the token range as it uses a global token range.<br />
      <span class="text-uppercase"
        >Please make sure this is aligned before continuing, this part of the setup is not reversible.</span
      >
    </div>
  </div>

  <div class="c-banner c-banner--danger" *ngIf="!setupState.selectedTokenGroup && !setupState.useExtender">
    <div class="c-banner__message">
      No extender selected, you quite likely will need to adjust the token range similarly to using a global token
      range.<br />
      <span class="text-uppercase"
        >Please make sure this is aligned before continuing, this part of the setup is not reversible.</span
      >
    </div>
  </div>

  <div>
    <div class="l-form__field">
      <label class="c-label">Minimum range</label>
      <input
        class="c-input"
        type="number"
        [(ngModel)]="setupState.tokenRangeMinimum"
        min="10000000"
        max="90000001"
        [disabled]="setupState.selectedTokenGroup"
      />
    </div>
    <div class="l-form__field">
      <label class="c-label">Maximum range</label>
      <input
        class="c-input"
        type="number"
        [(ngModel)]="setupState.tokenRangeMaximum"
        min="19999999"
        max="99999999"
        [disabled]="setupState.selectedTokenGroup"
      />
    </div>
    <div class="c-helper">
      The range should be at least 10 million in size (10000000).
    </div>
  </div>
  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>
