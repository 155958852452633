import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '~/store/app-state';
import { FastPaceCompanyActions } from '~/store/fastpace/fastpace.reducer';
import { FastPaceSelector } from '~/store/fastpace/fastpace.selectors';
import { ControllersCompaniesFastPaceCompanyResponseV1 } from '@proxyclick/fastpace-client';

@Component({
  selector: 'pxc-company-fastpace',
  templateUrl: './company-fastpace.html',
})
export class CompanyFastPaceComponent implements OnInit {
  public company$ = this.store.select('company', 'company', 'value');
  public fastpaceSettings$ = this.store.select<ControllersCompaniesFastPaceCompanyResponseV1>(
    FastPaceSelector.getCompany
  );
  public loading$ = this.store.select(FastPaceSelector.getCompanyLoading);
  public error$ = this.store.select(FastPaceSelector.getCompanyError);

  constructor(private store: Store<IAppState>) {}

  public ngOnInit() {
    this.company$.subscribe(company => {
      this.store.dispatch(FastPaceCompanyActions.Company.Load(company.id));
    });
  }
}
