import * as _ from 'lodash';

export const errorRegex = /(.*)\/(start|load|save|loaded|done|error)$/;

export const errorReducer = (state = {}, action) => {
  const newState = _.cloneDeep(state);

  if (action && action.type) {
    const reg = errorRegex.exec(action.type);
    if (reg) {
      switch (reg[2]) {
        case 'loaded':
        case 'done':
        case 'load':
        case 'save':
        case 'start':
          delete newState[reg[1]];
          break;
        case 'error':
          newState[reg[1]] = action.error ? action.error : action;
      }
    }

  }
  return newState;
};
