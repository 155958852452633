import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { ServicesModule } from '~/services/services.module';
import { ApiClientModule } from '~/shared/api-client/api-client.module';
import { BreadcrumbComponent } from '~/shared/components/breadcrumb/breadcrumb.component';
import { ButtonSwitchComponent } from '~/shared/components/button/button-switch/button-switch.component';
import { ButtonComponent } from '~/shared/components/button/button.component';
import { CountryPickerComponent } from '~/shared/components/countries/country-picker.component';
import { DatePickerComponent } from '~/shared/components/datepicker/datepicker.component';
import { MonthPickerComponent } from '~/shared/components/datepicker/monthpicker.component';
import { DialogComponent } from '~/shared/components/dialog/dialog.component';
import { DropdownComponent } from '~/shared/components/dropdown/dropdown.component';
import { FieldComponent } from '~/shared/components/forms/field.component';
import { ImageUploadComponent } from '~/shared/components/image-upload/image-upload.component';
import { ImagesManagerComponent } from '~/shared/components/images-manager/images-manager.component';
import { CheckboxComponent } from '~/shared/components/input/checkbox/checkbox.component';
import { CheckboxListComponent } from '~/shared/components/input/list/checkbox-list/checkbox-list.component';
import { RadioComponent } from '~/shared/components/input/radio/radio.component';
import { LanguagesDropDownComponent } from '~/shared/components/languages/languages-dropdown.component';
import { LoadingComponent } from '~/shared/components/loading/loading.component';
import { MarkdownPreviewComponent } from '~/shared/components/markdown/markdown-preview/markdown-preview.component';
import { NavbarComponent } from '~/shared/components/navbar/navbar.component';
import { AppPartnerDropdownComponent } from '~/shared/components/partners/partners.dropdown.component';
import { PasswordStrengthComponent } from '~/shared/components/password-strength/password-strength.component';
import { HasNoRightDirective, HasRightDirective } from '~/shared/components/rights/has-right.directive';
import { CompanyIdScanComponent } from '~/shared/components/stats/company-id-scan-stats/company-id-scan-stats.component';
import { CompanyStatsComponent } from '~/shared/components/stats/company-stats/company-stats.component';
import { CaretComponent } from '~/shared/components/tables/caret.component';
import { SortableTableComponent } from '~/shared/components/tables/sortable-table.component';
import { TabsComponent } from '~/shared/components/tabs/tabs.component';
import { CompaniesSelectorComponent } from '~/shared/components/typeahead/companies-selector/companies-selector.component';
import { CompaniesTypeAheadComponent } from '~/shared/components/typeahead/companies/companies-typeahead.component';
import { FilterPipe } from '~/shared/pipes/filter.pipe';
import { SafeResourcePipe } from '~/shared/pipes/safe-resource.pipe';
import { SortByPipe } from '~/shared/pipes/sort-by.pipe';
import { TimePipe } from '~/shared/pipes/time.pipe';
import { NotificationComponent } from '~/shared/services/notifications/notifications.component';
import { AddressFormComponent } from './components/address/address-form.component';
import { AddressComponent } from './components/address/address.component';
import { BillingDetailsFormComponent } from './components/billing/details-form/billing-details.modal.component';
import { BillingMigrationFormComponent } from './components/billing/migration-form/migration-form.component';
import { SelectMultipleFeatureListFormComponent } from './components/billing/select-multiple-feature-list-form/select-multiple-feature-list-form.component';
import { SelectOneFeatureListFormComponent } from './components/billing/select-one-feature-list-form/select-one-feature-list-form.component';
import { TrialExtenderComponent } from './components/billing/trial-extender/trial-extender.component';
import { ObjectDropdownComponent } from './components/object-dropdown/dropdown.component';
import { IntercomPanelComponent } from './components/intercom/intercom-panel';
import { JsonBeautifierComponent } from './components/json-beautifier/json-beautifier.component';
import { LiveMapComponent } from './components/live-map/live-map.component';
import { PackIconListComponent } from './components/packs/pack-icon-list.component';
import { PackIconComponent } from './components/packs/pack-icon.component';
import { LeaderboardComponent } from './components/pongup/leaderboard/leaderboard.component';
import { SearchBarComponent } from './components/search/search-bar/search-bar.component';
import { SearchResultsComponent } from './components/search/search-results/search-results.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { KpiTableComponent } from './components/stats/kpi-table/kpi-table.component';
import { RowComponent } from './components/tables/row.component';
import { AdminsTypeAheadComponent } from './components/typeahead/admins/admins-typeahead.component';
import { UsersTypeAheadComponent } from './components/typeahead/users/users-typeahead.component';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';
import { RightGuard } from './guards/right.guard';
import { CurrencyPipe } from './pipes/currency.pipe';
import { FeatureListV1 as FeatureListV1Pipe } from './pipes/feature-list-v1.pipe';
import { PricePipe } from './pipes/price.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { CamelCasePipe, CapitalizePipe, ContainsPipe } from './pipes/string.pipe';

const components = [
  MarkdownPreviewComponent,
  ButtonComponent,
  NotificationComponent,
  ImagesManagerComponent,
  ImageUploadComponent,
  CheckboxComponent,
  CheckboxListComponent,
  RadioComponent,
  NavbarComponent,
  DropdownComponent,
  BreadcrumbComponent,
  FieldComponent,
  LoadingComponent,
  DatePickerComponent,
  CompaniesTypeAheadComponent,
  AdminsTypeAheadComponent,
  LanguagesDropDownComponent,
  ButtonSwitchComponent,
  MonthPickerComponent,
  CaretComponent,
  CompanyStatsComponent,
  CountryPickerComponent,
  AppPartnerDropdownComponent,
  SortableTableComponent,
  TabsComponent,
  PasswordStrengthComponent,
  DialogComponent,
  CompaniesSelectorComponent,
  CompanyIdScanComponent,
  SearchResultsComponent,
  SearchBarComponent,
  LiveMapComponent,
  LeaderboardComponent,
  KpiTableComponent,
  JsonBeautifierComponent,
  UsersTypeAheadComponent,
  IntercomPanelComponent,
  RowComponent,
  BillingDetailsFormComponent,
  BillingMigrationFormComponent,
  SelectOneFeatureListFormComponent,
  SelectMultipleFeatureListFormComponent,
  PackIconComponent,
  PackIconListComponent,
  AddressComponent,
  AddressFormComponent,
  TrialExtenderComponent,
  SpinnerComponent,
  ObjectDropdownComponent,
];

const pipes = [
  SafeResourcePipe,
  TimePipe,
  FilterPipe,
  SortByPipe,
  CamelCasePipe,
  CapitalizePipe,
  ContainsPipe,
  FeatureListV1Pipe,
  PricePipe,
  CurrencyPipe,
  StatusPipe,
];

const directives = [HasRightDirective, HasNoRightDirective, CopyToClipboardDirective];

const guards = [RightGuard];

@NgModule({
  imports: [
    ServicesModule,
    CommonModule,
    FormsModule,
    TranslateModule.forRoot(),
    ApiClientModule,
    RouterModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],
  declarations: [components, pipes, directives],
  exports: [components, pipes, directives, CalendarModule, NgbModule, FormsModule, CommonModule],
  providers: [ApiClientModule, guards],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
