import { Component, Input, OnInit } from '@angular/core';
import { FastPaceSetupCompanyState } from '../fastpace-setup-company.state';
import { FastPaceService } from '~/services/fastpace.service';
import { BehaviorSubject } from 'rxjs';
import { ControllersCompaniesFastPaceCompanyResponseV1 } from '@proxyclick/fastpace-client';

type CopyMode = 'search' | 'randomextender';
@Component({
  selector: 'app-fastpace-company-setup-copy-settings',
  templateUrl: './fastpace-copy-settings.component.html',
})
export class CopySettingsComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;
  constructor(private fastPaceService: FastPaceService) {}
  ngOnInit(): void {
    if (this.setupState.extender) {
      this.fastPaceService.getCompaniesForExtender(this.setupState.extender.extenderId).then(r => {
        this.companyOnExtenderCount = r.length;
        if (this.companyOnExtenderCount > 0) {
          this.copySettings = true;
          this.copyMode.next('randomextender');
          this.setupState.copySettingsFromRandomExtenderCompany = true;
        }
      });
    } else {
      this.copySettings = false;
    }
  }
  copySettings: boolean = false;
  copyMode: BehaviorSubject<CopyMode> = new BehaviorSubject('search');
  companyOnExtenderCount: number = 0;
  companySearchTerm: string = '';
  companies: ControllersCompaniesFastPaceCompanyResponseV1[] = [];

  searchCompany() {
    this.fastPaceService
      .getCompanies({
        page: 1,
        pageSize: 10,
        q: this.companySearchTerm,
      })
      .then(r => {
        this.companies = r.items;
      });
  }

  selectCompany(company: ControllersCompaniesFastPaceCompanyResponseV1) {
    this.setupState.copySettingsFromCompany = company;
  }

  changeCopyMode(mode: CopyMode) {
    this.copyMode.next(mode);
    this.setupState.copySettingsFromCompany = undefined;
    this.companies = [];
    if (mode === 'randomextender') {
      this.setupState.copySettingsFromRandomExtenderCompany = true;
    } else {
      this.setupState.copySettingsFromRandomExtenderCompany = false;
    }
  }

  canGoNext() {
    return (
      !this.copySettings ||
      (this.copySettings &&
        (this.setupState.copySettingsFromRandomExtenderCompany ||
          this.setupState.copySettingsFromCompany !== undefined))
    );
  }
}
