<div *ngIf="genetecSettings">
  <form (submit)="submit()">
    <div class="l-form__field">
      <label class="c-label">Partition</label>
      <input
        type="text"
        class="c-input c-input--s"
        [(ngModel)]="partition?.name || genetecSettings.partitionId"
        name="partition"
        disabled=""
      />
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Credential Format (QR Code)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="qrCodeCredentialFormat"
            [options]="credentialFormats"
            key="formatId"
            label="name"
            name="qrCodeCredentialFormat"
            disabled=""
          ></pxc-obj-dropdown>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Credential Format (Access Card Manager)</label>
        </div>
        <div class="l-bar__item-end">
          <pxc-obj-dropdown
            [(ngModel)]="cardCredentialFormat"
            [options]="credentialFormats"
            key="formatId"
            label="name"
            name="cardCredentialFormat"
            allowNull="true"
          ></pxc-obj-dropdown>
        </div>
      </div>
    </div>
    <div class="l-form__field">
      <div class="l-bar u-margin-bottom-xs">
        <div class="l-bar__item-start">
          <label class="c-label">Facility code</label>
        </div>
        <div class="l-bar__item-end">
          <input type="number" class="c-input c-input--s" required [(ngModel)]="facilityCode" name="facilityCode" />
        </div>
      </div>
      <div class="c-helper">Select the facility code set as default inside the ACS</div>
    </div>
    <button type="submit" class="c-button c-button--primary c-button--s" [disabled]="!canSubmit">
      Save settings&nbsp;<hl-spinner *ngIf="submitting" size="medium"></hl-spinner>
      <hl-icon
        *ngIf="submitting === false && errors === undefined"
        icon="checkmark-circle--color-24"
        width="24"
        height="24"
      ></hl-icon>
    </button>
  </form>
  <h3 *ngIf="errors">Errors during update</h3>
  <div class="u-color-danger" *ngIf="errors">
    <h4>{{ errors.title }}</h4>
    <dl>
      <ng-container *ngFor="let item of errors.errors | keyvalue">
        <dt>{{ item.key }}</dt>
        <dt>
          <ul>
            <li *ngFor="let errorItem of item.value">
              {{ errorItem }}
            </li>
          </ul>
        </dt>
      </ng-container>
    </dl>
    Trace id <code>{{ errors.traceId }}</code>
  </div>
</div>
