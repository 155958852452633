import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ICCompany, ICUser } from '~/models/intercom';
import { BaseService } from './base.service';

@Injectable()
export class IntercomService extends BaseService {
  constructor(injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getCompany(companyId: string) {
    return this.http.get<ICCompany>(`/intercom/companies?company_id=${companyId}`);
  }

  getUser(userId: string) {
    return this.http.get<ICUser>(`/intercom/users?user_id=${userId}`);
  }

  getFieldName(field: string) {}
}
