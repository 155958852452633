import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '~/services/auth.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';

@Component({
  selector: 'pxc-forgot-password',
  templateUrl: './forgot-password.html',
  styleUrls: ['../auth.scss', './forgot-password.scss'],
})
export class ForgotPasswordPageComponent implements OnInit {
  code: string;
  loading = false;
  password;
  passwordCheck;
  error: string;

  private strength = 0;

  constructor(
    private route: ActivatedRoute,
    private Auth: AuthService,
    private router: Router,
    private Notifications: NotificationsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(result => {
      this.code = result['code'];
    });
  }

  onSubmit(password: string, passwordCheck: string) {
    this.error = '';
    if (!password || !passwordCheck) {
      this.error = 'Please input your password';
      return;
    }
    if (password !== passwordCheck) {
      this.error = 'Passwords are not identical';
      return;
    }
    if (password.length < 8) {
      this.error = 'Password should contains at least 8 characters';
      return;
    }
    this.Auth.setPassword(password, this.code).subscribe(
      result => {
        this.loading = false;
        this.Notifications.success('Your password has been succesfully set');
        this.router.navigate(['/login']);
      },
      error => {
        this.Notifications.error('This link is expired or invalid');
      }
    );
  }

  onStrengthChange(event) {
    this.error = '';
    this.strength = event;
  }
}
