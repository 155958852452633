import {
  ProovrExtenderUserImportMappingRequestV1,
  ProovrExtenderUserImportJobResponseV1,
  ProovrUpdateExtenderClientRequestV1,
  ProovrExtenderUserImportMappingResponseV1,
  ProovrUserImportJobDataFileExtension,
  ProovrUserImportJobDataType,
  ProovrExtenderUserFieldResponseV1,
  ProovrExtenderResponseV1,
} from './../store/proovr/proovr.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IQueryablePaginationFilter, IPaginationFilter } from '~/models/filters';
import { IPaginatedResponse } from '~/store/proovr/proovr.models';

@Injectable()
export class ProovrService {
  constructor(private http: HttpClient) {}

  public getExtenderSettings(extenderId: string): Promise<ProovrExtenderResponseV1> {
    return this.http
      .get<ProovrExtenderResponseV1>(`/proovr-api/v1/extenders/${extenderId}`)
      .pipe(this.catch404s())
      .toPromise();
  }

  public updateExtenderSettings(extenderId: string, request: ProovrUpdateExtenderClientRequestV1) {
    return this.http.put(`/proovr-api/v1/extenders/${extenderId}`, request).toPromise();
  }

  public triggerUserImport(extenderId: string) {
    return this.http.post(`/proovr-api/v1/extenders/${extenderId}/user-imports`, {}).toPromise();
  }

  public getUserImportJobs(
    extenderId: string,
    filter: IPaginationFilter
  ): Promise<IPaginatedResponse<ProovrExtenderUserImportJobResponseV1>> {
    return this.http
      .get<IPaginatedResponse<ProovrExtenderUserImportJobResponseV1>>(
        `/proovr-api/v1/extenders/${extenderId}/user-imports?page=${filter.page}&pageSize=${filter.pageSize}`
      )
      .toPromise();
  }

  public getUserImportMappingFields(
    extenderId: string,
    filter: IPaginationFilter
  ): Promise<IPaginatedResponse<ProovrExtenderUserFieldResponseV1>> {
    return this.http
      .get<IPaginatedResponse<ProovrExtenderUserFieldResponseV1>>(
        `/proovr-api/v1/extenders/${extenderId}/import-mappings/fields`
      )
      .toPromise();
  }

  public getUserImportMappings(
    extenderId: string,
    filter: IPaginationFilter
  ): Promise<IPaginatedResponse<ProovrExtenderUserImportMappingResponseV1>> {
    return this.http
      .get<IPaginatedResponse<ProovrExtenderUserImportMappingResponseV1>>(
        `/proovr-api/v1/extenders/${extenderId}/import-mappings`
      )
      .toPromise();
  }

  public createUserImportMapping(
    extenderId: string,
    request: ProovrExtenderUserImportMappingRequestV1
  ): Promise<ProovrExtenderUserImportMappingResponseV1> {
    return this.http
      .post<ProovrExtenderUserImportMappingResponseV1>(
        `/proovr-api/v1/extenders/${extenderId}/import-mappings`,
        request
      )
      .toPromise();
  }

  public updateUserImportMapping(
    extenderId: string,
    mappingId: string,
    request: ProovrExtenderUserImportMappingRequestV1
  ) {
    return this.http
      .put<ProovrExtenderUserImportMappingResponseV1>(
        `/proovr-api/v1/extenders/${extenderId}/import-mappings/${mappingId}`,
        request
      )
      .toPromise();
  }

  public deleteUserImportMapping(extenderId: string, mappingId: string) {
    return this.http.delete(`/proovr-api/v1/extenders/${extenderId}/import-mappings/${mappingId}`).toPromise();
  }

  public downloadUserImportJobData(
    extenderId: string,
    userImportJobKey: string,
    jobDataType: ProovrUserImportJobDataType,
    fileExtension: ProovrUserImportJobDataFileExtension
  ): Promise<ArrayBuffer> {
    return this.http
      .get(`/proovr-api/v1/extenders/${extenderId}/user-imports/${userImportJobKey}/${jobDataType}.${fileExtension}`, {
        responseType: 'arraybuffer',
      })
      .toPromise();
  }

  private catch404s = () => {
    return catchError(error => {
      if (error && error.status === 404) {
        return of(null);
      }
      throw error;
    });
  };
}
