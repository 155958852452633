<div class="c-card__section">
  <div class="l-form__field">
    <div class="l-bar u-margin-bottom-xs">
      <div class="l-bar__item-start">
        <label class="c-label">Use extender</label>
      </div>
      <div class="l-bar__item-end">
        <pxc-switch [(ngModel)]="setupState.useExtender" name="useExtender"></pxc-switch>
      </div>
    </div>
    <div class="c-helper">
      Do you want to link an extender to this company? Most companies need this setting, only the ones that want to
      generate tokens without being linked to an ACS would disable this.
    </div>
  </div>
  <div *ngIf="setupState.useExtender">
    <h3 class="u-margin-bottom-m">Select extender</h3>
    <div class="l-form__field">
      <div class="c-input c-input--s u-margin-bottom-s">
        <div class="c-input__prefix c-input__prefix--shift">
          <span class="c-icon c-icon--s">
            <hl-icon icon="search-24"></hl-icon>
          </span>
        </div>
        <input
          [(ngModel)]="searchTerm"
          class="c-input c-input--s c-input--naked"
          type="text"
          placeholder="Search by name or id"
          [disabled]="searching"
          (keydown.enter)="search()"
        />
        <div class="c-input__suffix">
          <button class="c-action" tabindex="" (click)="search()" type="button" [disabled]="searching">
            <span class="c-action__label">Search</span>
          </button>
        </div>
      </div>
      <hl-spinner *ngIf="searching"></hl-spinner>
      <div *ngIf="extenders && extenders.length > 0" class="l-stack c-box u-padding-all-none">
        <div class="l-stack__item">
          <div class="c-stacked-list c-stacked-list--s">
            <ul class="c-stacked-list__list">
              <li *ngFor="let extender of extenders" [ngClass]="{ 'disabled': !extender.hasBeenConnected }" class="c-stacked-list__item">
                <a
                  class="c-stacked-list__inner c-stacked-list__inner--interactive"
                  (click)="extender.hasBeenConnected && selectExtender(extender.extenderId)"
                >
                  <div class="c-stacked-list__inner-start">
                    {{ extender.description }}
                    &nbsp;<span class="c-badge" [ngClass]="extender.hasBeenConnected ? 'c-badge--primary' : 'c-badge--neutral-light'"> {{ extender.type }}</span>
                    &nbsp;<span *ngIf="!extender.hasBeenConnected" class="c-badge c-badge--warning">offline</span>
                  </div>
                  <div class="c-stacked-list__inner-end">
                    <span class="c-icon c-icon--m">
                      <hl-icon
                        icon="checkmark-24"
                        width="24"
                        height="24"
                        *ngIf="extender.extenderId === selectedExtender"
                      ></hl-icon>
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-fastpace-setup-footer [setupState]="setupState" [canGoNext]="canGoNext()"></app-fastpace-setup-footer>
</div>
