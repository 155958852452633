import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUser } from '@proxyclick/data-model';
import { ISubscription } from 'rxjs-compat/Subscription';
import { IOrganisationUsersFilter } from '~/models/filters';
import { UserService } from '~/services/users.service';
import { OrganisationLinkUsersAction, OrganisationUnlinkUserAction, OrganisationUsersActions } from '~/store/organisation/organisation.actions';
import { AppStore } from '~/store/store';

@Component({
  selector: 'pxc-organisation-users',
  templateUrl: './organisation-users.html',
})
export class OrganisationUsersComponent implements OnInit, OnDestroy {
  users$ = this.store.select('organisation', 'users', 'value');
  loadingUsers$ = this.store.select('organisation', 'users', 'loading');
  organisationSub: ISubscription;
  filter: IOrganisationUsersFilter;
  searchTerm: string = '';
  organisationId: string;
  unlinkModal = {
    open: false,
    userId: null,
  };
  userIdsToLink = '';
  isUsersSaving = false;
  linkUsersModalVisible = false;


  constructor(private store: AppStore, private Users: UserService) {}

  ngOnInit(): void {
    this.organisationId = null;
    this.organisationSub = this.store.select('organisation', 'organisation', 'value', 'id').subscribe(id => {
      this.organisationId = id;
      this.initFilter();
    });
  }

  initFilter() {
    this.filter = {
      organisationId: this.organisationId,
      withMetaData: true,
      page: 1,
      pageSize: 100,
    };
  }

  getUsers(page: number) {
    this.filter.q = this.searchTerm;
    this.filter.page = page;
    return this.store.dispatchAsync(OrganisationUsersActions.Load(this.filter));
  }

  searchUsers() {
    return () => {
      return this.getUsers(1);
    };
  }

  onPageChange(page) {
    this.getUsers(page);
  }

  setGlobalAdmin(user: IUser) {
    return (isGlobalAdmin: boolean) => this.Users.setGlobalAdmin(user.id, isGlobalAdmin);
  }

  toggleUnlinkModal(userId?: string) {
    this.unlinkModal = !this.unlinkModal.open
      ? {
          open: true,
          userId,
        }
      : {
          open: false,
          userId: null,
        };
  }

  async unlinkUserFromOrganisation(userId: string) {
    await this.store.dispatchAsync(OrganisationUnlinkUserAction.Load({ userId, organisationId: this.organisationId }));
    this.toggleUnlinkModal();
  }

  ngOnDestroy() {
    this.organisationSub.unsubscribe();
  }

  linkUsers() {
    this.isUsersSaving = true;
    const userIds = this.userIdsToLink
      .split('\n')
      .map(d => d.trim())
      .filter(Boolean);

    if (userIds.length === 0) {
      return;
    }

    this.store
      .dispatchAsync(OrganisationLinkUsersAction.Save({ organisationId: this.organisationId, userIds }))
      .then(() => {
        this.linkUsersModalVisible = false;
        this.userIdsToLink = '';
        this.isUsersSaving = false;
      });
  }
}
