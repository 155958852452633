<div>
  <div *ngIf="errors.length > 0">
    <div class="u-color-danger">
      <h3>We encountered some errors while trying to update the following home locations</h3>
      <table class="c-table mt-4">
        <thead>
          <tr>
            <th>Email</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of errors">
            <td>{{ item.key }}</td>
            <td>{{ item.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="flex justify-content-center align-items-center w-50 u-margin-bottom-l">
    <label class="c-label u-margin-right-xs">Target Company Id:</label>
    <input class="c-input c-input--s u-margin-left-s u-margin-right-s" [(ngModel)]="targetCompany" type="text" />
    <pxc-button (click)="toggleUpdateHomeLocationModal()" [loading]="false">Update Home Locations</pxc-button>
  </div>
  <div class="u-margin-bottom-xs w-50">
    <div class="flex center u-margin-top-xs" *ngFor="let user of users">
      <input
        class="c-input c-input--s"
        [(ngModel)]="user.email"
        (input)="onInput(user, $event)"
        type="email"
        placeholder="email@domain.com"
      />
      <hl-icon class="u-margin-left-s" icon="add-circle-24" (click)="addEmptyRow()"></hl-icon>
    </div>
  </div>

  <hl-modal
    *ngIf="updateHomeLocationModal.open"
    class="hl-modal"
    [isOpen]="true"
    [heading]="'Update Home Locations'"
    (close)="toggleUpdateHomeLocationModal()"
  >
    <div>
      <div class="pxc-settings__subheading u-margin-bottom-gutter">
        <span>
          Are you sure you want to update the home location for {{users.length}} users?
          <br /><br />
          Disclaimer: this process will take some time, around 2 minutes for 2.5k users. Expect some longer waiting
          times for a bigger amount of users to be updated. If the process timeout, wait around 15 minutes before trying
          again, the users will still be updated.
        </span>
      </div>
      <footer class="hl-modal__footer u-margin-top-s">
        <div class="c-buttons-group" style="margin-top: -10px;">
          <hl-button size="small" type="primary" (click)="updateHomeLocations()" [loading]="isUpdateLoading$ | async"
            >Update</hl-button
          >
          <hl-button size="small" type="secondary" (click)="toggleUpdateHomeLocationModal()">Close</hl-button>
        </div>
      </footer>
    </div>
  </hl-modal>
</div>
