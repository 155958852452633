import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { share } from 'rxjs/operators';
import { INotification } from '~/models/notifications';

@Injectable()
export class NotificationsService {
  notifications$: Observable<INotification>;

  private observer: Observer<INotification>;
  private initialQueue: INotification[] = [];

  constructor() {
    this.notifications$ = new Observable<INotification>(observer => {
      this.observer = observer;

      this.initialQueue.forEach(notification => this.observer.next(notification));
    }).pipe(share());
  }

  success(message: string) {
    this.addNotification({
      type: 'success',
      message: message,
    });
  }

  error(message: string) {
    if (!message) {
      return;
    }
    this.addNotification({
      type: 'danger',
      message: message,
    });
  }

  private addNotification(notification: INotification) {
    if (!this.observer) {
      this.initialQueue.push(notification);
    } else {
      this.observer.next(notification);
    }
  }
}
