import { Component, Input, OnInit } from '@angular/core';
import { ICustomField } from '@proxyclick/data-model';
import {
  FreeFieldsFreeFieldConfigurationResultV1,
  IdentifierTypesIdentifierTypeResultV1,
} from '@proxyclick/extender-server-aeos-client';
import { v4 as uuidv4 } from 'uuid';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';

@Component({
  selector: 'app-fastpace-company-setup-extenders-aeos',
  templateUrl: './aeos-setup.component.html',
})
export class AeosSetupComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;
  constructor(private companyService: CompaniesService, private extenderService: ExtenderService) {}
  async ngOnInit(): Promise<void> {
    this.customFields = await this.companyService.getCustomFields(this.setupState.company.id).toPromise();
    this.setCustomFieldOptions();
    this.freeFields = await this.extenderService.aeos.freeFields.list(this.setupState.extender.extenderId, {
      requestTracingId: uuidv4(),
    });
    this.setFreeFieldOptions();

    this.cardFormats = (
      await this.extenderService.aeos.identifierTypes.list(this.setupState.extender.extenderId, {
        requestTracingId: uuidv4(),
      })
    ).identifierTypes;
  }

  mapCustomFields: boolean;

  freeFields: FreeFieldsFreeFieldConfigurationResultV1[];
  freeFieldOptions: FreeFieldsFreeFieldConfigurationResultV1[] = [];
  freeFieldOption: FreeFieldsFreeFieldConfigurationResultV1 | null = null;

  customFields: ICustomField[];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  cardFormats: IdentifierTypesIdentifierTypeResultV1[] = [];

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.setupState.aeosCustomFieldFreeFieldMappings = [];
    }
  }

  setFreeFieldOptions() {
    const options = this.freeFields.filter(c => {
      //Filter out custom fields already mapped
      return this.setupState.aeosCustomFieldFreeFieldMappings.filter(a => a.freeField.id === c.id).length === 0;
    });

    this.freeFieldOptions = options;
    if (this.freeFieldOptions.length > 0) {
      this.freeFieldOption = this.freeFieldOptions[0];
    } else {
      this.freeFieldOption = undefined;
    }
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return this.setupState.aeosCustomFieldFreeFieldMappings.filter(a => a.customField.id === c.id).length === 0;
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = undefined;
    }
  }

  canAddMapping() {
    return (
      this.customFieldOption !== undefined &&
      this.freeFieldOption !== undefined &&
      this.setupState.aeosCustomFieldFreeFieldMappings.filter(
        c => c.freeField.id === this.freeFieldOption.id || c.customField.id === this.customFieldOption.id
      ).length === 0
    );
  }
  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }
    this.setupState.aeosCustomFieldFreeFieldMappings.push({
      customField: this.customFieldOption,
      freeField: this.freeFieldOption,
    });
    this.setCustomFieldOptions();
    this.setFreeFieldOptions();
  }

  removeMapping(id: number) {
    this.setupState.aeosCustomFieldFreeFieldMappings = this.setupState.aeosCustomFieldFreeFieldMappings.filter(
      c => c.customField.id !== id
    );
    this.setCustomFieldOptions();
    this.setFreeFieldOptions();
  }

  canGoNext() {
    if (this.mapCustomFields && this.setupState.aeosCustomFieldFreeFieldMappings.length < 1) {
      return false;
    }
    return true;
  }
}
