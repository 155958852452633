import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TenantOrganisationPost } from '@proxyclick/api-client';
import {
  OrganisationPlatformTenantActions,
  OrganisationPlatformTenantAddActions,
  OrganisationPlatformTenantUserSyncActions,
} from '~/store/organisation/organisation.actions';
import { AppStore } from '~/store/store';

@Component({
  selector: 'pxc-organisation-platform-page',
  templateUrl: './organisation-platform.html',
})
export class OrganisationPlatformPageComponent implements OnInit {
  platformTenantAdd: TenantOrganisationPost = {
    tenantId: '',
    locationMappings: [{ locationId: '', companyId: '' }],
  };

  loading = {
    platformTenant$: this.store.select('organisation', 'tenantOrganisation', 'loading'),
    platformTenantAdd$: this.store.select('organisation', 'tenantOrganisation-add', 'loading'),
  };
  error = {
    platformTenantAdd$: this.store.select('organisation', 'tenantOrganisation-add', 'error'),
  };
  addPlatformTenantModal = {
    open: false,
  };
  platformTenant$ = this.store.select('organisation', 'tenantOrganisation', 'value');

  statusMapping = {
    NOT_STARTED: {
      color: 'white',
      info: 'The user synchronisation is not started yet, you can start it by click the "Start" button',
    },
    WAITING: { color: 'neutral-light', info: 'The user synchronisation will start soon..' },
    RUNNING: { color: 'default', info: 'The user synchronisation is ongoing..' },
    LIVE: {
      color: 'primary',
      info: 'The user synchronisation is live, changes made in Platform are directly reflected into Proxyclick',
    },
    ERROR: {
      color: 'danger',
      info: 'An error has occured during the user synchronisation, you can start it again by clicking the "Start" button',
    },
  };

  configure: boolean = false;
  private organisationId: string;

  constructor(private store: AppStore, private route: ActivatedRoute) {}

  ngOnInit() {
    this.organisationId = this.route.parent.snapshot.params.id;
    this.store.dispatch(OrganisationPlatformTenantActions.Load());
  }

  addEmptyRow() {
    this.platformTenantAdd.locationMappings.push({ locationId: '', companyId: '' });
  }

  removeRow(index: number) {
    this.platformTenantAdd.locationMappings.splice(index, 1);
  }

  addPlatformTenant() {
    if (this.platformTenantAdd.tenantId && this.platformTenantAdd.locationMappings.length > 0) {
      this.store.dispatch(
        OrganisationPlatformTenantAddActions.Save({
          organisationId: this.organisationId,
          tenantId: this.platformTenantAdd.tenantId,
          locationMappings: this.platformTenantAdd.locationMappings,
        })
      );
    }
  }

  startSync() {
    this.store.dispatch(OrganisationPlatformTenantUserSyncActions.Save({ organisationId: this.organisationId }));
  }

  refresh() {
    this.store.dispatch(OrganisationPlatformTenantActions.Load());
  }

  get canAddPlatformTenant(): boolean {
    return (
      this.platformTenantAdd.tenantId &&
      this.platformTenantAdd.tenantId !== '' &&
      this.platformTenantAdd.locationMappings.filter(lm => lm.locationId !== '' && lm.companyId !== '').length > 0
    );
  }

  toggleAddPlatformTenantModal() {
    this.addPlatformTenantModal = !this.addPlatformTenantModal.open
      ? {
          open: true,
        }
      : {
          open: false,
        };
  }
}
