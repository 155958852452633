import { Component, OnInit } from '@angular/core';
import { AppStore } from '~/store/store';
import { createAction } from '@ngrx/store';
import { FastPaceSelector } from '~/store/fastpace/fastpace.selectors';
import { ActivatedRoute } from '@angular/router';
import { FastPaceCompanyActions } from '~/store/fastpace/fastpace.reducer';
import { ControllersCompaniesFastPaceCompanyResponseV1 } from '@proxyclick/fastpace-client';
@Component({
  selector: 'app-fastpace-company-details-page',
  templateUrl: './fastpace.company.details.page.component.html',
})
export class FastPaceCompanyDetailsPageComponent implements OnInit {
  static Loaded = createAction('FastPaceCompanyDetailsPageComponent/Loaded');
  company$ = this.store.select<ControllersCompaniesFastPaceCompanyResponseV1>(FastPaceSelector.getCompany);
  loading$ = this.store.select(FastPaceSelector.getCompanyLoading);
  error$ = this.store.select(FastPaceSelector.getCompanyError);
  constructor(private store: AppStore, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.store.dispatch(FastPaceCompanyActions.Company.Load(params.id));
    });
  }
}
