import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ApplicationsService } from '~/services/applications.service';
import { ApplicationActions, ApplicationsActions } from '~/store/applications/applications.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';

@Injectable()
export class ApplicationsEffects {
  constructor(private actions$: Actions<PayloadAction>, private Applications: ApplicationsService) {}

  
  getAll$ = createEffect(() => generateAsyncEffect(this.actions$, ApplicationsActions, () => this.Applications.getApplications()));

  
  get$ = createEffect(() => generateAsyncEffect(this.actions$, ApplicationActions, id => this.Applications.getApplication(id)));
}
