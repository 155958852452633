import { combineReducers } from '@ngrx/store';
import { ILdapData } from '@proxyclick/data-model';
import { createAsyncLoadActions, createAsyncLoadReducer } from '~/utils/async-generator';

export const LdapDirectoriesActions = createAsyncLoadActions<void, ILdapData>('ldap');
export const LdapDirectoriesReducer = createAsyncLoadReducer(LdapDirectoriesActions);

export const LdapDirectoryActions = createAsyncLoadActions<string, ILdapData>('ldap-details');

export const JsonDirectoriesActions = createAsyncLoadActions<void, ILdapData>('json');
export const jsonDirectoriesReducer = createAsyncLoadReducer(JsonDirectoriesActions);

export const JsonDirectoryActions = createAsyncLoadActions<string, any>('json-details');
export const jsonDirectoryReducer = createAsyncLoadReducer(JsonDirectoryActions);

export const LdapDetailsReducer = combineReducers({
  ldapDetails: createAsyncLoadReducer(LdapDirectoryActions),
});
