import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from '~/services/base.service';

interface IGeoLocation {
  results: {
    address_components: any[];
    formatted_addres: string;
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
  }[];
  status: string;
}

interface ITimezone {
  status: string;
  timeZoneId: string;
  timeZoneName: string;
}

@Injectable()
export class GoogleMapsService extends BaseService {
  constructor(injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getGeoCode(address: string, zip: string, city: string, country: string) {
    const config = this.config.getConfig();
    const formattedAddress = `+${address},+${zip},+${city},+${country}+`;

    return this.http.get<IGeoLocation>('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        key: config.googleMaps.apiKey,
        address: formattedAddress,
      },
    });
  }

  getTimezone(latitude: number, longitude: number) {
    const config = this.config.getConfig();
    return this.http.get<ITimezone>('https://maps.googleapis.com/maps/api/timezone/json', {
      params: {
        location: `${latitude},${longitude}`,
        key: config.googleMaps.apiKey,
        timestamp: '1',
      },
    });
  }
}
