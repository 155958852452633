<div *pxcHasRight="'tab_fastpace'">
  <div class="flex between">
    <div class="form-inline f-m-h-1">
      <input
        class="form-control mr-2"
        type="text"
        [(ngModel)]="filter.q"
        name="search"
        placeholder="Filter companies..."
      />
      <pxc-button [onClick]="loadWithFilter()">Filter</pxc-button>
    </div>
    <div class="flex center f-m-h-1">
      <!-- <button class="c-button c-button--primary c-button--s" routerLink="new">
          <i class="fa fa-plus"></i><span class="u-margin-left-2xs">Create</span>
        </button> -->
    </div>
  </div>
  <table *ngIf="!(loading$ | async) && !(error$ | async) && (companies$ | async)" class="c-table mt-4">
    <thead>
      <tr>
        <th>Name</th>
        <th>ID</th>
        <th>Failed # past 7 days</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let company of (companies$ | async).items"
        class="table-row pointer"
        (click)="onCompanyClick(company.companyId)"
      >
        <td>{{ company.companyName }}</td>
        <td>{{ company.companyId }}</td>
        <td>{{ company.failedRetriesLast7Days }}</td>
        <td (click)="$event.stopPropagation()">
          <a href="https://seq.proxyclick.com/#/events?filter=CompanyId%20%3D%20'{{ company.companyId }}'"
            >View in Seq</a
          >
        </td>
      </tr>
    </tbody>
  </table>

  <div class="mt-2">
    <ngb-pagination
      *ngIf="!(loading$ | async) && filter && total > limit"
      [collectionSize]="total"
      [(page)]="filter.page"
      [pageSize]="filter.pageSize"
      [maxSize]="10"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="onPageChange($event)"
    ></ngb-pagination>
  </div>
  <pxc-loading [loading]="loading$ | async"></pxc-loading>
  <strong *ngIf="error$ | async" class="text-danger">{{ (error$ | async).message }}</strong>
</div>
