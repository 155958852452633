<pxc-loading *pxcHasRight='"tab_provisioning"' [loading]="loading$ | async">
  <form
    ngNativeValidate
    (ngSubmit)="save(json)"
    class="form-small"
    *ngIf="!(loading$ | async) && json$ | async as json"
  >
    <pxc-field *ngIf="!json.id" label="Company">
      <pxc-companies-typeahead [(ngModel)]="json.company" name="company"></pxc-companies-typeahead>
    </pxc-field>
    <pxc-field *ngIf="!json.id" label="Alias">
      <input [(ngModel)]="json.alias" name="alias" type="text" class="form-control" />
    </pxc-field>
    <pxc-field label="Linked companies">
      <pxc-companies-selector [(ngModel)]="json.companies" name="companies"></pxc-companies-selector>
    </pxc-field>
    <h4 class="my-4">Attributes</h4>
    <div *ngFor="let field of allFields">
      <pxc-field label="{{field}}">
        <input class="form-control" [(ngModel)]="fields[field]" name="{{field}}" type="text" />
      </pxc-field>
    </div>

    <button type="submit" class="btn btn-outline-primary mt-1"><i class="fas fa-save"></i> Save</button>
  </form>
</pxc-loading>
