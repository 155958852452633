import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ICompany } from '@proxyclick/data-model';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';
import { filterNull } from '~/utils/utils';
import { IDomainFilter, IPaginatedDomains } from '../../../../shared/filters';
import { IAdminCompany, IDomain } from '../../../../shared/models';
import { AdminCompaniesService } from './admin-companies.service';
import { BaseService } from './base.service';
import { CompaniesService } from './companies.service';

@Injectable()
export class DomainService extends BaseService {
  constructor(
    injector: Injector,
    private httpClient: HttpClient,
    private Notifications: NotificationsService,
    private Companies: CompaniesService,
    private AdminCompanies: AdminCompaniesService
  ) {
    super(injector);
  }

  getDomains(filter: IDomainFilter) {
    return this.httpClient.get<IPaginatedDomains>('/admin/domains', {
      params: filterNull(filter),
    });
  }

  getDomainById(id: number) {
    return this.httpClient.get<IDomain>(`/admin/domains/${id}`).pipe(
      switchMap(domain =>
        domain && domain.contactId // Enrich the keyContact value by getting AdminUsers
          ? this.apiClient
              .AdminUser(domain.contactId)
              .doGet()
              .pipe(
                map(u => ({
                  ...domain,
                  keyContact: u,
                }))
              )
          : of(domain)
      )
    );
  }

  getCompaniesForDomain(domain: IDomain) {
    return combineLatest(
      this.Companies.getCompanies({
        page: 1,
        pageSize: 999,
        domain: domain.domain,
      }).pipe(map(r => r.companies)),
      this.AdminCompanies.getCompanies({
        page: 1,
        pageSize: 999,
        domainId: domain.id,
      })
    ).pipe(map(([companies, adminCompanies]) => mergeCompanies(companies, adminCompanies)));
  }

  updateDomain(domain: IDomain) {
    const url = `/admin/domains/${domain.id}`;
    domain.contactId = domain.keyContact && domain.keyContact.id;
    return this.httpClient.patch(url, domain).pipe(tap(() => this.Notifications.success('Succesfully updated')));
  }

  updateClearbit(domain: IDomain) {
    return this.httpClient.post(`/admin/domains/updateClearbit/${domain.id}`, {});
  }
}

function mergeCompanies(companies: ICompany[], adminCompanies: IAdminCompany[]) {
  const r = companies.map<ICompany & IAdminCompany>((c: ICompany & IAdminCompany) => {
    const company = adminCompanies.find(ac => ac.id === c.id);
    c.mrr = (company && company.mrr) || 0;
    return c;
  });
  return r;
}
