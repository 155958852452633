import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '~/shared/components/dialog/dialog.service';

@Component({
  selector: 'pxc-dialog',
  templateUrl: 'dialog.html',
})
export class DialogComponent implements OnInit {
  closeResult: string;
  @ViewChild('content', { static: true }) content;
  dialog$ = this.dialog.dialog$;
  message = '';
  title = '';

  constructor(private modalService: NgbModal, private dialog: DialogService) {}

  ngOnInit() {
    this.dialog$.subscribe(dialog => {
      this.message = dialog.message;
      this.title = dialog.title;
      this.modalService
        .open(this.content, {
          centered: true,
        })
        .result.then(
          result => {
            dialog.callback(result);
          },
          reason => {
            dialog.callback(false);
          }
        );
    });
  }
}
