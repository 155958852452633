import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StatsService } from '~/services/stats.service';
import { SharedModule } from '~/shared/shared.module';
import { MapPageComponent } from './map.page';

@NgModule({
  declarations: [MapPageComponent],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'map',
        component: MapPageComponent,
        data: {
          title: 'Map',
        },
      },
    ]),
  ],
  providers: [StatsService],
})
export class MapModule {}
