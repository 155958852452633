import { createAction } from '@ngrx/store';
import { IMCategory, ICategory } from '@proxyclick/data-model';
import { createAsyncLoadActions, createAsyncLoadReducer, PayloadAction } from '~/utils/async-generator';

export const CategoriesActions = createAsyncLoadActions<void, IMCategory[]>('categories');
export const categoriesReducer = createAsyncLoadReducer(CategoriesActions);

export const CategoryActions = {
  UPDATE: createAction('category/update', (payload: IMCategory[]) => ({ payload })),
  ...createAsyncLoadActions<string, ICategory>('category'),
};

const categoryBaseReducer = createAsyncLoadReducer(CategoryActions);

function categoryExtraReducer(state = null, action: PayloadAction<ICategory>) {
  switch (action.type) {
    case CategoryActions.UPDATE.type:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export const categoryReducer = (state, action) => {
  return categoryExtraReducer(categoryBaseReducer(state, action), action);
};
