import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FastPaceSetupCompanyState } from './fastpace-setup-company.state';

@Component({
  selector: 'app-fastpace-setup-footer',
  templateUrl: './setup-footer.component.html',
})
export class SetupFooterComponent {
  @Input() setupState: FastPaceSetupCompanyState;
  @Input() canGoNext: boolean = false;

  next() {
    this.setupState.setPage(this.setupState.nextPage.key);
  }
}
