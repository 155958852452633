import { Component, Input, OnInit } from '@angular/core';
import { ICustomField } from '@proxyclick/data-model';
import { ControllersCompaniesFastPaceCompanyResponseV1 } from '@proxyclick/fastpace-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceService } from '~/services/fastpace.service';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { IExtenderResponseV1 } from '~/store/extender/extender.models';
import { ExtenderActions } from '~/store/extender/extender.reducer';
import { ErrorResponse } from '~/store/fastpace/fastpace.models';
import { AppStore } from '~/store/store';

@Component({
  selector: 'app-fastpace-company-details',
  templateUrl: './fastpace.company.details.component.html',
})
export class FastPaceCompanyDetailsComponent implements OnInit {
  @Input() company: ControllersCompaniesFastPaceCompanyResponseV1;
  constructor(
    private readonly store: AppStore,
    private readonly fastpaceService: FastPaceService,
    private readonly companyService: CompaniesService,
    private readonly extenderService: ExtenderService
  ) {}
  ngOnInit(): void {
    this.companyService
      .getCustomFields(this.company.companyId)
      .toPromise()
      .then(r => {
        this.customFields = r;
        this.setCustomFieldOptions();
        this.calculateAccessLevels();
      });

    this.isOnSiteStatusEnabled$ = this.companyService
      .getVmParams(this.company.companyId)
      .pipe(map(x => x.onSiteStatus));

    if (this.company.extenderId) {
      this.extenderService
        .findExtender(this.company.extenderId)
        .toPromise()
        .then(r => (this.extender = r));
    }
  }

  isOnSiteStatusEnabled$: Observable<boolean>;

  extender: IExtenderResponseV1 | null;
  showGeneral: boolean = false;
  showToken: boolean = false;
  showAccess: boolean = true;
  showSystem: boolean = true;
  daysInThePast: number = 7;

  customFields: ICustomField[] = [];

  //custom field as access levels
  customFieldOptions: IOption[] = [];
  customFieldOption: string | undefined;

  //show access levels
  baseAccessLevels: string[] = ['Proxyclick expected', 'Proxyclick checked-in', 'Proxyclick checked-out'];
  accessLevels: string[];

  setCustomFieldOptions() {
    var options = this.customFields
      .filter(c => c.type.label === 'LIST')
      .map(c => {
        return { label: c.name, value: c.id.toString(10) };
      });
    this.customFieldOptions = [];
    this.customFieldOptions.push({ label: '(Empty)', value: '' });
    this.customFieldOptions = this.customFieldOptions.concat(options);
    this.customFieldOption =
      this.company.accessLevelCustomFieldId !== null ? this.company.accessLevelCustomFieldId.toString(10) : '';
  }

  changedCustomFieldOption() {
    this.company.accessLevelCustomFieldId =
      this.customFieldOption !== '' ? Number.parseInt(this.customFieldOption) : null;
    this.calculateAccessLevels();
  }

  changedCompanySpecificAccess() {
    this.calculateAccessLevels();
  }

  calculateAccessLevels() {
    this.accessLevels = [];
    this.baseAccessLevels.forEach(element => {
      if (this.company.accessLevelCustomFieldId !== null) {
        var customFieldId = this.company.accessLevelCustomFieldId;
        var customField = this.customFields.filter(c => c.id === customFieldId)[0];
        customField.values.forEach(customFieldValue => {
          this.accessLevels.push(this.formatAccessLevel(element, this.company.companySpecificAccess, customFieldValue));
        });
      } else {
        this.accessLevels.push(this.formatAccessLevel(element, this.company.companySpecificAccess, undefined));
      }
    });
  }

  formatAccessLevel(baseName: string, companySpecificAccess: boolean, customFieldValue: string): string {
    var name = baseName;
    if (companySpecificAccess) {
      name += ' - ' + this.company.companyName;
    }
    if (customFieldValue !== undefined) {
      name += ' - ' + customFieldValue;
    }
    return name;
  }

  get canSubmit(): boolean {
    return this.submitting !== true && this.extender.hasBeenConnected;
  }

  submitting: boolean | null = null;
  errors: ErrorResponse;

  async submit() {
    this.submitting = true;
    this.errors = undefined;
    try {
      await this.fastpaceService.updateCompanySettings(this.company.companyId, {
        accessCheckIn: this.company.accessCheckIn,
        accessCheckOut: this.company.accessCheckOut,
        expectedBeforeVisit: this.company.expectedBeforeVisit,
        disableAfterVisit: this.company.disableAfterVisit,
        companySpecificAccess: this.company.companySpecificAccess,
        accessLevelCustomFieldId: this.company.accessLevelCustomFieldId,
        onSiteStatusForVisitorEntered: this.company.onSiteStatusForVisitorEntered,
        allowPhysicalCards: this.company.allowPhysicalCards,
      });
      this.submitting = false;
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError(error) {
    this.submitting = false;
    if (error && error.statusCode < 500) {
      var errorResponse = error.details as ErrorResponse;
      this.errors = errorResponse;
    } else {
      this.errors = {
        title: error.message,
        errors: undefined,
        status: error.statusCode,
        traceId: error.headers?.get('traceId'),
        type: 'Failure',
      };
    }
  }

  async retryUpdatesForCompany(companyId: string) {
    this.submitting = true;
    this.errors = undefined;
    try {
      await this.fastpaceService.retryExtenderUpdatesForCompany(companyId, this.daysInThePast);
      this.submitting = false;
      this.submitting = false;
      this.store.dispatch(ExtenderActions.Extender.Load(this.company.extenderId));
    } catch (error) {
      this.handleError(error);
    }
  }

  async refreshCompanyExtenderSettings(companyId: string) {
    this.submitting = true;
    this.errors = undefined;
    try {
      await this.fastpaceService.refreshCompanyExtenderSettings(companyId);
      this.submitting = false;
      this.store.dispatch(ExtenderActions.Extender.Load(this.company.extenderId));
    } catch (error) {
      this.handleError(error);
    }
  }
}
