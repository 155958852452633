import { Component, OnInit } from '@angular/core';
import { ICustomField } from '@proxyclick/data-model';
import {
  CardTypesCardTypeV1,
  DivisionsDivisionV1,
  PersonalDataDefinitionsPersonalDataDefinitionV1,
} from '@proxyclick/extender-server-gallagher-client';
import { ControllersCompaniesGallagherCompanySettingsResponseV1 } from '@proxyclick/fastpace-client';
import { v4 as uuidv4 } from 'uuid';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceService } from '~/services/fastpace.service';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { fetchAll } from '~/utils/utils';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';

@Component({
  selector: 'app-fastpace-company-gallagher',
  templateUrl: './fastpace.company.gallagher.component.html',
})
export class FastPaceCompanyGallagherComponent extends FastPaceBaseSystemSettingsComponent implements OnInit {
  //current settings
  gallagherSettings: ControllersCompaniesGallagherCompanySettingsResponseV1 | null = null;
  //new settings
  selectedDivision: DivisionsDivisionV1 | null;
  customFieldMappings: Array<{ customField: ICustomField; fieldName: string }> = [];
  divisions: DivisionsDivisionV1[];

  mapCustomFields: boolean = false;

  customFields: ICustomField[] = [];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  gallagherCustomFields: Array<string> = [];
  gallagherCustomFieldOptions: IOption[] = [];
  selectedGallagherCustomFieldOption: string | undefined = '';
  cardTypes: CardTypesCardTypeV1[] = [];
  cardCardType: CardTypesCardTypeV1 | null = null;
  qrCodeCardType: CardTypesCardTypeV1 | null = null;

  constructor(fastpaceService: FastPaceService, companyService: CompaniesService, extenderService: ExtenderService) {
    super(fastpaceService, companyService, extenderService);
  }

  async ngOnInit(): Promise<void> {
    this.gallagherSettings = await this.fastpaceService.getGallagherSettings(this.fastpaceCompany.companyId);
    this.customFields = await this.companyService.getCustomFields(this.fastpaceCompany.companyId).toPromise();

    this.gallagherCustomFields = (
      await fetchAll<PersonalDataDefinitionsPersonalDataDefinitionV1>(
        async page =>
          (
            await this.extenderService.gallagher.personalDataDefinitions.list(this.fastpaceCompany.extenderId, {
              requestTop: 100,
              requestSkip: (page - 1) * 100,
              requestTracingId: uuidv4(),
              requestFields: [],
              requestName: '',
            })
          ).personalDataDefinitions
      )
    ).map(x => x.name);

    this.divisions = await fetchAll<DivisionsDivisionV1>(
      async page =>
        (
          await this.extenderService.gallagher.divisions.list(this.fastpaceCompany.extenderId, {
            requestTop: 100,
            requestSkip: (page - 1) * 100,
            requestTracingId: uuidv4(),
            requestFields: [],
            requestName: '',
          })
        ).divisions
    );

    this.cardTypes = (
      await this.extenderService.gallagher.cardTypes.list(this.fastpaceCompany.extenderId, {
        requestTracingId: uuidv4(),
      })
    ).cardTypes;

    this.setInitialState();
  }

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.customFieldMappings = [];
    }
  }

  setInitialState() {
    if (this.gallagherSettings !== null && this.customFields.length > 0 && this.gallagherCustomFields.length > 0) {
      this.mapCustomFields = this.gallagherSettings.customFieldMappings.length > 0 ? true : false;
      this.customFieldMappings = this.gallagherSettings.customFieldMappings.map(c => {
        var customField = this.customFields.find(cf => cf.id == c.customFieldId);
        return { customField: customField, fieldName: c.fieldName };
      });
      this.setGallagherCustomFieldOptions();
      this.setCustomFieldOptions();
    }

    if (this.gallagherSettings != null && this.gallagherSettings.divisionId != null && this.divisions.length > 0) {
      this.selectedDivision = this.divisions.filter(x => x.id == this.gallagherSettings.divisionId)[0];
    }

    if (this.gallagherSettings != null && this.gallagherSettings.cCardTypeId != null) {
      this.cardCardType = this.cardTypes.find(x => x.id == this.gallagherSettings.cCardTypeId);
    }

    if (this.gallagherSettings != null && this.gallagherSettings.qrCodeCardTypeId != null) {
      this.qrCodeCardType = this.cardTypes.find(x => x.id == this.gallagherSettings.qrCodeCardTypeId);
    }
  }

  setGallagherCustomFieldOptions() {
    const options = this.gallagherCustomFields
      .filter(c => {
        //Filter out custom fields already mapped
        return (
          this.customFieldMappings !== undefined && this.customFieldMappings.filter(a => a.fieldName === c).length === 0
        );
      })
      .map(c => {
        return { label: c, value: c };
      });

    this.gallagherCustomFieldOptions = options;
    if (this.gallagherCustomFieldOptions.length > 0) {
      this.selectedGallagherCustomFieldOption = this.gallagherCustomFieldOptions[0].value;
    } else {
      this.selectedGallagherCustomFieldOption = undefined;
    }
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return (
        this.customFieldMappings !== undefined &&
        this.customFieldMappings.filter(a => a.customField.id === c.id).length === 0
      );
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = null;
    }
  }

  canAddMapping() {
    return (
      this.customFieldOption &&
      this.selectedGallagherCustomFieldOption &&
      this.customFieldMappings.filter(
        c => c.fieldName === this.selectedGallagherCustomFieldOption || c.customField.id === this.customFieldOption.id
      ).length === 0
    );
  }

  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }
    this.customFieldMappings.push({
      customField: this.customFieldOption,
      fieldName: this.selectedGallagherCustomFieldOption,
    });
    this.setCustomFieldOptions();
    this.setGallagherCustomFieldOptions();
  }

  removeMapping(id: number) {
    this.customFieldMappings = this.customFieldMappings.filter(c => c.customField.id !== id);
    this.setCustomFieldOptions();
    this.setGallagherCustomFieldOptions();
  }

  async doUpdate(): Promise<void> {
    return this.fastpaceService.updateGallagherSettings(this.fastpaceCompany.companyId, {
      cCardTypeId: this.cardCardType?.id ?? null,
      customFieldMappings: this.customFieldMappings.map(x => {
        return { customFieldId: x.customField.id, fieldName: x.fieldName };
      }),
      doorFilterIn: [],
      doorFilterOut: [],
    });
  }

  get canSubmit(): boolean {
    return this.submitting !== true;
  }
}
