import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CompaniesService } from '~/services/companies.service';
import { RightService } from '~/services/rights.service';
import { UserService } from '~/services/users.service';
import { SearchAction, SearchCompaniesActions, SearchUsersActions } from '~/store/search/search.reducer';
import { generateAsyncEffect, PayloadAction } from '~/utils/async-generator';

@Injectable()
export class SearchEffects {
  constructor(
    private actions$: Actions<PayloadAction>,
    private Companies: CompaniesService,
    private Users: UserService,
    private Right: RightService
  ) {}

  
  search$ = createEffect(() => this.actions$.pipe(
    ofType(SearchAction.Load.type),
    map(action => action.payload),
    switchMap(term => [SearchUsersActions.Load(term), SearchCompaniesActions.Load(term)])
  ));

  
  searchUsers$ = createEffect(() => generateAsyncEffect(
    this.actions$, SearchUsersActions, term => term && this.Right.hasRight('tab_users') 
      ? this.Users.searchUsers(term)
      : observableOf([])
  ).pipe(catchError(_ => of([]))));

  
  searchCompanies$ = createEffect(() => generateAsyncEffect(this.actions$, SearchCompaniesActions, term =>
    term && this.Right.hasRight('tab_companies') ? this.Companies.searchCompanies(term) : observableOf([])
  ).pipe(catchError(_ => of([]))));
}
