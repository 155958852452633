import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '~/store/app-state';

@Component({
  selector: 'pxc-company-legal',
  templateUrl: './company-legal.html',
})
export class CompanyLegalComponent {
  company$ = this.store.select('company', 'company', 'value');
  termsAndConditions$ = this.store.select('company', 'termsAndConditions', 'value');

  constructor(private store: Store<IAppState>) {}
}
