import { Component } from '@angular/core';
import { IOption } from '~/shared/components/input/radio/radio.component';

@Component({
  selector: 'pxc-marketplace',
  templateUrl: './marketplace.html',
})
export class MarketplacePageComponent {
  tabs: IOption[] = [
    { label: 'Integrations', value: 'integrations' },
    { label: 'Categories', value: 'categories' },
    // { label: 'Partners', value: 'partners' },
  ];
}
