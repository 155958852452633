import { Injectable } from '@angular/core';

@Injectable()
export class DownloadService {
  constructor() {}

  downloadFile(data: any, filename: string, type) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    anchor.click();
  }
}
