import { Component, Input, OnInit } from '@angular/core';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { NewExtenderState } from '../new-extender.state';

@Component({
  selector: 'app-new-extender-client',
  templateUrl: './new-extender.client.component.html',
})
export class NewExtenderClientComponent {
  @Input() state: NewExtenderState;

  regionOptions: IOption[] = [
    { label: 'US East Coast', value: 'us-east' },
    { label: 'West Europe', value: 'europe-west' },
  ];
}
