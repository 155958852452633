import {
  IBaseCreateOrUpdateExtenderRequestV1,
  ICreateBrivoExtenderRequestV1,
  ICreateClientExtenderRequestV1,
  ICreateGeneaExtenderRequestV1,
  ICreateOpenPathExtenderRequestV1,
} from '~/store/extender/extender.models';

export class NewExtenderState {
  selectedExtenderTypeOption: string | undefined;
  description: string = '';
  alertStatus: boolean = true;
  technicalContacts: string = '';

  clientSubscriptionId: string = '';
  clientThumbprint: string = '';
  clientSelectedRegion: string | undefined;

  openpathEmail: string = '';
  openpathPassword: string = '';

  brivoApiKey: string = '';

  geneaApiKey: string = '';

  createRequest(): IBaseCreateOrUpdateExtenderRequestV1 {
    const request: IBaseCreateOrUpdateExtenderRequestV1 = {
      description: this.description,
      type: this.selectedExtenderTypeOption,
      alertStatus: this.alertStatus,
      technicalContacts: this.technicalContacts,
    };

    switch (request.type) {
      case 'brivo':
        const brivoRequest: ICreateBrivoExtenderRequestV1 = {
          ...request,
          apiKey: this.brivoApiKey,
        };
        return brivoRequest;
      case 'openpath':
        const openpathRequest: ICreateOpenPathExtenderRequestV1 = {
          ...request,
          email: this.openpathEmail,
          password: this.openpathPassword,
        };
        return openpathRequest;
      case 'genea':
        const geneaRequest: ICreateGeneaExtenderRequestV1 = {
          ...request,
          apiKey: this.geneaApiKey,
        };
        return geneaRequest;
      default:
        const clientRequest: ICreateClientExtenderRequestV1 = {
          ...request,
          region: this.clientSelectedRegion,
          subscriptionId: this.clientSubscriptionId,
          thumbprintCharacters: this.clientThumbprint,
        };
        return clientRequest;
    }
  }

  canSubmit(): boolean {
    return (
      this.description !== '' &&
      this.description.length >= 5 &&
      this.selectedExtenderTypeOption !== undefined &&
      this.technicalContacts !== '' &&
      this.technicalContacts.length <= 300 &&
      ((this.selectedExtenderTypeOption == 'brivo' && this.brivoApiKey !== '') ||
        (this.selectedExtenderTypeOption == 'openpath' && this.openpathEmail !== '' && this.openpathPassword !== '') ||
        (this.selectedExtenderTypeOption == 'genea' && this.geneaApiKey !== '') ||
        (this.clientSubscriptionId !== '' &&
          this.clientThumbprint !== '' &&
          this.clientThumbprint.length === 4 &&
          this.clientSubscriptionId.length >= 32 &&
          this.clientSelectedRegion !== undefined))
    );
  }
}
