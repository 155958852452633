import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationError, NavigationStart, Router, ActivationStart } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, withLatestFrom, switchMap } from 'rxjs/operators';
import { AuthService } from '~/services/auth.service';
import { localTranslations } from '~/shared/translate/local-translations';
import { APP_INIT_ACTION, IAppState } from '~/store/app-state';
import { RightService } from './services/rights.service';
import * as _ from 'lodash';
import { combineLatest, of } from 'rxjs';

const pages = {
  tab_organisations: '/organisations',
  tab_companies: '/companies',
  tab_users: '/users',
  tab_partners: '/partners',
  tab_stats: '/stats',
  tab_provisioning: '/provisioning',
  tab_utilities: '/convert',
  tab_marketplace: '/marketplace',
  tab_map: '/map',
  tab_fastpace: '/fastpace',
};
const defaultLanguage = 'en';
const notAuthentifiedPages = ['/login', '/forgot-password', '/oauth'];
const fullWidthPages = ['/map', '/companies/CO-', '/organisations/O-'];

@Component({
  selector: 'pxc-root',
  templateUrl: './app.component.html',
  providers: [Title],
})
export class AppComponent implements OnInit {
  svgSheet: string;

  constructor(
    private translate: TranslateService,
    private store: Store<IAppState>,
    private Route: Router,
    private Auth: AuthService,
    private Rights: RightService,
    private http: HttpClient,
    private title: Title
  ) {
    this.initTranslations();
  }

  ngOnInit() {
    this.store.dispatch(APP_INIT_ACTION);
    this.redirectToLoginIfNotAuth();
    this.redirectToHome();
    this.initSvg();
    this.initTitleChanger();
  }

  redirectToLoginIfNotAuth() {
    this.Route.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        filter(event => !notAuthentifiedPages.some(p => event['url'].startsWith(p))),
        filter(event => !this.Auth.isUserLoggedIn())
      )
      .subscribe(() => this.Route.navigate(['/login']));
  }

  redirectToHome() {
    this.Route.events
      .pipe(
        filter(event => event instanceof NavigationError),
        filter(event => this.Auth.isUserLoggedIn())
      )
      .subscribe(() => this.Route.navigate([this.getHomePage()]));
  }

  getHomePage() {
    const homePage = this.Rights.getRights().filter(r => r.startsWith('tab_'))[0];
    const home = pages[homePage];
    return home;
  }

  showNav() {
    return !notAuthentifiedPages.some(p => this.Route.url.startsWith(p));
  }

  showContainer() {
    return !fullWidthPages.some(p => this.Route.url.startsWith(p));
  }

  initTranslations() {
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
    this.translate.setTranslation(defaultLanguage, localTranslations);
  }

  initSvg() {
    this.http
      .get('assets/all-sprite.svg', {
        responseType: 'text',
      })
      .subscribe(svgContent => {
        this.svgSheet = svgContent;
        const elem = document.createElement('div');
        elem.setAttribute('style', 'height: 0; overflow: hidden');
        elem.innerHTML = svgContent;
        document.body.append(elem);
      });
  }

  initTitleChanger() {
    this.Route.events
      .pipe(
        filter(event => event instanceof ActivationStart),
        switchMap((event: ActivationStart) => {
          const pageTitleParts: string[] = [];
          let route = event.snapshot;

          while (route) {
            if (route.data.title) {
              pageTitleParts.push(route.data.title);
            }

            route = route.parent;
          }

          const pageTitle = [...pageTitleParts, 'Black Gate'].join(' | ');

          const matches = pageTitle.match(/\{\{([a-zA-Z0-9-\.]+)\}\}/g);

          if (!matches) {
            return of(pageTitle);
          }

          const valueSelectors = matches
            .map(match => match.substring(2, match.length - 2)) // remove {{ }}
            .map(match => match.split('.'))
            .map(selectorParts => this.store.select(state => _.get(state, selectorParts, '')));

          return combineLatest(...valueSelectors).pipe(
            map(values => {
              return values.reduce<string>(
                (title: string, value: string, i) => title.replace(matches[i], value),
                pageTitle
              );
            })
          );
        })
      )
      .subscribe(pageTitle => {
        this.title.setTitle(pageTitle);
      });
  }
}
