import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RecentUsersComponent } from '~/pages/users/recent-users/recent-users.component';
import { UsersPageComponent } from '~/pages/users/users.page';
import { RightGuard } from '~/shared/guards/right.guard';
import { SharedModule } from '~/shared/shared.module';
import { UserRights } from '../../../../../shared/models';
import { UserDetailsComponent } from './user-details/user-details.page';

@NgModule({
  declarations: [UsersPageComponent, RecentUsersComponent, UserDetailsComponent],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'users',
        canActivate: [RightGuard],
        data: {
          right: UserRights.TabUsers,
          title: 'Users',
        },
        children: [
          {
            path: '',
            component: UsersPageComponent,
          },
          {
            path: ':userId',
            component: UserDetailsComponent,
            data: {
              title: '{{user.user.value.firstname}} {{user.user.value.lastname}}',
            },
          },
        ],
      },
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UsersModule {}
