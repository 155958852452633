import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup } from '@angular/forms';
import { IFeatureList } from '@proxyclick/data-model';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-select-multiple-feature-list-form',
  templateUrl: './select-multiple-feature-list-form.html',
  providers: controlValueAccessor(SelectMultipleFeatureListFormComponent),
})
export class SelectMultipleFeatureListFormComponent implements ControlValueAccessor {
  @Input()
  targetFeatureLists: IFeatureList[];

  selectedMultipleFeatureListsForm = new FormGroup({
    selectedFeatureLists: new FormArray([]),
  });

  onChange: (selectedFeatureLists: IFeatureList[]) => void;

  get featureListsFormArray() {
    return this.selectedMultipleFeatureListsForm.get('selectedFeatureLists') as FormArray;
  }

  get selectedFeatureLists(): IFeatureList[] {
    return this.selectedMultipleFeatureListsForm.get('selectedFeatureLists').value;
  }

  onInternalChange(checked, featureList) {
    if (checked) {
      this.featureListsFormArray.push(new FormControl(featureList));
    } else {
      this.featureListsFormArray.controls.forEach((item: FormControl, index) => {
        if (item.value.id === featureList.id) {
          this.featureListsFormArray.removeAt(index);
        }
      });
    }
    this.onChange(this.selectedFeatureLists);
  }

  writeValue(selectedFeatureLists: IFeatureList[]): void {
    this.selectedMultipleFeatureListsForm.patchValue({ selectedFeatureLists });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    return;
  }

  setDisabledState?(isDisabled: boolean): void {
    return;
  }
  getClassMap() {
    return {};
  }
}
