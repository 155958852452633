<ng-template #rt let-r="result">
  <span class="flex center">
    <img class="mr-1" width="24" src="{{r.picture24}}" />
    {{r.firstname}} {{r.lastname}}
  </span>
</ng-template>
<input
  type="text"
  class="form-control input-small"
  name="user"
  [(ngModel)]="user"
  (ngModelChange)="onInternalChange()"
  [ngbTypeahead]="search"
  [inputFormatter]="formatInput"
  [resultTemplate]="rt"
  [placeholder]="placeholder"
/>
