import { Component, OnInit } from '@angular/core';
import {
  CardFormatsCardFormatResponseV1,
  CompaniesCompanyV1,
  DevicesDeviceSummaryV1,
  KnownDevicesDeviceTypeV1,
  PersonalDataTitlesPersonalDataTitleV1,
} from '@proxyclick/extender-server-amag-client';
import { ICustomField } from '@proxyclick/data-model';
import { v4 as uuidv4 } from 'uuid';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { FastPaceService } from '~/services/fastpace.service';
import { FastPaceBaseSystemSettingsComponent } from '../fastpace.company.base';
import { ControllersCompaniesAmagCompanySettingsResponseV1 } from '@proxyclick/fastpace-client';

@Component({
  selector: 'app-fastpace-company-amag',
  templateUrl: './fastpace.company.amag.component.html',
})
export class FastPaceCompanyAMAGComponent extends FastPaceBaseSystemSettingsComponent implements OnInit {
  //current settings
  amagSettings: ControllersCompaniesAmagCompanySettingsResponseV1 | null = null;

  //new settings
  companies: CompaniesCompanyV1[] = [];
  companiesOptions: CompaniesCompanyV1[] = [];
  selectedCompanyOption: CompaniesCompanyV1 | null = null;

  customFieldMappings: Array<{
    customField: ICustomField;
    amagPersonalDataTitle: PersonalDataTitlesPersonalDataTitleV1;
  }> = [];
  mapCustomFields: boolean = false;

  customFields: ICustomField[] = [];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  amagCustomFields: PersonalDataTitlesPersonalDataTitleV1[] = [];
  amagCustomFieldOptions: PersonalDataTitlesPersonalDataTitleV1[] = [];
  selectedAMAGCustomFieldOption: PersonalDataTitlesPersonalDataTitleV1 | null = null;

  cardFormats: CardFormatsCardFormatResponseV1[] = [];
  cardFormatOptions: CardFormatsCardFormatResponseV1[] = [];
  qrCodeCardFormatOption: CardFormatsCardFormatResponseV1 | null = null;
  physicalCardFormatOption: CardFormatsCardFormatResponseV1 | null = null;

  readers: DevicesDeviceSummaryV1[] = [];
  readerOptions: DevicesDeviceSummaryV1[] = [];
  selectedReaderOptionIn: DevicesDeviceSummaryV1 | null = null;
  selectedReaderOptionOut: DevicesDeviceSummaryV1 | null = null;

  readerFilterIns: DevicesDeviceSummaryV1[] = [];
  readerFilterOuts: DevicesDeviceSummaryV1[] = [];

  customerCode: number | undefined = undefined;

  constructor(fastpaceService: FastPaceService, companyService: CompaniesService, extenderService: ExtenderService) {
    super(fastpaceService, companyService, extenderService);
  }

  async ngOnInit(): Promise<void> {
    this.amagSettings = await this.fastpaceService.getAMAGSettings(this.fastpaceCompany.companyId);
    this.customFields = await this.companyService.getCustomFields(this.fastpaceCompany.companyId).toPromise();
    this.companies = await this.extenderService.amag.companies.list(this.fastpaceCompany.extenderId, {
      requestTracingId: uuidv4(),
    });
    this.readers = await this.extenderService.amag.devices.list(this.fastpaceCompany.extenderId, {
      requestDeviceType: KnownDevicesDeviceTypeV1.ReaderDevice,
    });
    this.cardFormats = await this.extenderService.amag.cardFormats.listCardFormats(this.fastpaceCompany.extenderId, {
      requestTracingId: uuidv4(),
    });
    this.amagCustomFields = await this.extenderService.amag.personalDataTitles.list(this.fastpaceCompany.extenderId, {
      requestCompanyId: this.amagSettings.amagCompanyId,
      requestIncludeVisitors: false,
    });
    this.setCompaniesOptions();
    this.setCardFormatOptions();
    this.setAMAGCustomFieldOptions();
    this.setCustomFieldOptions();
    this.setReadersOptions();

    this.setInitialState();
  }

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.customFieldMappings = [];
    }
  }

  setInitialState() {
    if (this.amagSettings !== null && this.customFields.length > 0 && this.amagCustomFields.length > 0) {
      this.mapCustomFields = this.amagSettings.customFieldMappings.length > 0 ? true : false;
      this.customFieldMappings = this.amagSettings.customFieldMappings.map(c => {
        const customField = this.customFields.find(cf => cf.id == c.customFieldId);
        const personalDataTitle = this.amagCustomFields.find(x => x.id == c.personalDataTitleId);
        return { customField: customField, amagPersonalDataTitle: personalDataTitle };
      });
      this.setCustomFieldOptions();
    }

    if (this.amagSettings != null && this.amagSettings.amagCompanyId != null && this.companies.length > 0) {
      this.selectedCompanyOption = this.companies.find(x => x.id == this.amagSettings.amagCompanyId);
    }

    if (this.amagSettings?.qrCodeCardFormatId != null) {
      this.qrCodeCardFormatOption = this.cardFormatOptions.find(x => x.id == this.amagSettings.qrCodeCardFormatId);
    }

    if (this.amagSettings?.cardFormatId != null) {
      this.physicalCardFormatOption = this.cardFormatOptions.find(x => x.id == this.amagSettings.cardFormatId);
    }

    if (this.amagSettings != null && this.amagSettings.customerCode != null) {
      this.customerCode = this.amagSettings.customerCode;
    }

    if (this.amagSettings != null && this.readers != null && this.readers.length > 0) {
      this.readerFilterIns = this.readers.filter(x => this.amagSettings.inReadersIds.some(y => y == x.deviceId));
      this.readerFilterOuts = this.readers.filter(x => this.amagSettings.outReadersIds.some(y => y == x.deviceId));

      this.setReadersOptions();
    }
  }

  setAMAGCustomFieldOptions() {
    const options = this.amagCustomFields.filter(c => {
      //Filter out custom fields already mapped
      return (
        this.customFieldMappings !== undefined &&
        this.customFieldMappings.filter(a => a.amagPersonalDataTitle.id === c.id).length === 0
      );
    });

    this.amagCustomFieldOptions = options;
    if (this.amagCustomFieldOptions.length > 0) {
      this.selectedAMAGCustomFieldOption = this.amagCustomFieldOptions[0];
    } else {
      this.selectedAMAGCustomFieldOption = undefined;
    }
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return (
        this.customFieldMappings !== undefined &&
        this.customFieldMappings.filter(a => a.customField.id === c.id).length === 0
      );
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = null;
    }
  }

  async amagCompanyOptionChanged() {
    this.setAMAGCustomFieldOptions();
    this.selectedAMAGCustomFieldOption = this.amagCustomFieldOptions[0];
  }

  setCompaniesOptions() {
    this.companiesOptions = this.companies;
    if (this.companiesOptions.length > 0) {
      this.selectedCompanyOption = this.companiesOptions[0];
    } else {
      this.selectedCompanyOption = null;
    }
  }

  setCardFormatOptions() {
    this.cardFormatOptions = this.cardFormats;
    if (this.cardFormatOptions.length > 0) {
      this.qrCodeCardFormatOption = this.cardFormatOptions[0];
    } else {
      this.qrCodeCardFormatOption = null;
    }
  }

  setReadersOptions() {
    this.readerOptions = this.readers
      .filter(x => !this.readerFilterIns.some(y => y.deviceId == x.deviceId))
      .filter(x => !this.readerFilterOuts.some(y => y.deviceId == x.deviceId));
    if (this.readerOptions.length > 0) {
      this.selectedReaderOptionIn = this.readerOptions[0];
    } else {
      this.selectedReaderOptionIn = null;
    }
  }

  canAddInMapping() {
    return (
      this.selectedReaderOptionIn &&
      this.readerFilterIns.filter(d => d.deviceId === this.selectedReaderOptionIn.deviceId).length < 1
    );
  }

  canAddOutMapping() {
    return (
      this.selectedReaderOptionOut &&
      this.readerFilterOuts.filter(d => d.deviceId === this.selectedReaderOptionOut.deviceId).length < 1
    );
  }

  addInMapping() {
    this.readerFilterIns.push(this.selectedReaderOptionIn);
    this.setReadersOptions();
  }

  addOutMapping() {
    this.readerFilterOuts.push(this.selectedReaderOptionOut);
    this.setReadersOptions();
  }

  canAddMapping() {
    return (
      this.customFieldOption &&
      this.selectedAMAGCustomFieldOption &&
      this.customFieldMappings.filter(
        c =>
          c.amagPersonalDataTitle.id === this.selectedAMAGCustomFieldOption.id ||
          c.customField.id === this.customFieldOption.id
      ).length === 0
    );
  }

  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }
    this.customFieldMappings.push({
      customField: this.customFieldOption,
      amagPersonalDataTitle: this.selectedAMAGCustomFieldOption,
    });
    this.setCustomFieldOptions();
    this.setAMAGCustomFieldOptions();
  }

  removeMapping(id: number) {
    this.customFieldMappings = this.customFieldMappings.filter(c => c.customField.id !== id);
    this.setCustomFieldOptions();
    this.setAMAGCustomFieldOptions();
  }

  removeInMapping(id: number) {
    this.readerFilterIns = this.readerFilterIns.filter(d => d.deviceId !== id);
    this.setReadersOptions();
  }
  removeOutMapping(id: number) {
    this.readerFilterOuts = this.readerFilterOuts.filter(d => d.deviceId !== id);
    this.setReadersOptions();
  }

  async doUpdate(): Promise<void> {
    return this.fastpaceService.updateAMAgSettings(this.fastpaceCompany.companyId, {
      amagCompanyId: this.selectedCompanyOption.id,
      qrCodeCardFormatId: this.qrCodeCardFormatOption.id,
      cardFormatId: this.physicalCardFormatOption.id,
      customerCode: this.customerCode,
      inReadersIds: this.readerFilterIns.map(x => x.deviceId),
      outReadersIds: this.readerFilterOuts.map(x => x.deviceId),
      customFieldMappings: this.customFieldMappings.map(x => {
        return { customFieldId: x.customField.id, personalDataTitleId: x.amagPersonalDataTitle.id };
      }),
    });
  }

  get canSubmit(): boolean {
    return this.submitting !== true;
  }
}
