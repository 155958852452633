import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RightGuard } from '~/shared/guards/right.guard';
import { SharedModule } from '~/shared/shared.module';
import { UserRights } from '../../../../../shared/models';
import { CreateAdminUserComponent } from './admin-users-create/admin-users-create.component';
import { AdminUsersComponent } from './admin-users.component';

@NgModule({
  declarations: [AdminUsersComponent, CreateAdminUserComponent],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'admin-users',
        canActivate: [RightGuard],
        children: [
          {
            path: '',
            component: AdminUsersComponent,
          },
          {
            path: 'create',
            component: CreateAdminUserComponent,
            canActivate: [RightGuard],
            data: {
              title: 'New',
              right: UserRights.SuperAdmin,
            },
          },
        ],
        data: {
          right: UserRights.SuperAdmin,
          title: 'Admin Users',
        },
      },
    ]),
  ],
})
export class AdminUsersModule {}
