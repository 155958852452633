import { Injectable, Injector } from '@angular/core';
import { ICountry } from '@proxyclick/data-model';
import { Observable } from 'rxjs';
import { BaseService } from '~/services/base.service';
import { ObservableCache } from '~/utils/observable-cache';

@Injectable()
export class CountriesService extends BaseService {
  cache: ObservableCache = new ObservableCache();

  constructor(injector: Injector) {
    super(injector);
  }

  getCountries(): Observable<ICountry[]> {
    return this.cache.getObservable('countries', this.apiClient.SystemCountries().doGet());
  }
}
