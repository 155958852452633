import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { map } from 'rxjs/operators';
import { filterNull } from '~/utils/utils';
import { IAdminCompanyFilter } from '../../../../shared/filters';
import { IAdminCompany } from '../../../../shared/models';
import { BaseService } from './base.service';

@Injectable()
export class AdminCompaniesService extends BaseService {
  constructor(injector: Injector, private httpClient: HttpClient) {
    super(injector);
  }

  getCompanies(filter: IAdminCompanyFilter) {
    return this.httpClient.get<IAdminCompany[]>('/admin/companies', {
      params: filterNull(filter),
    });
  }

  getCompanyById(companyId: string) {
    return this.getCompanies({
      id: companyId,
    }).pipe(map(companies => companies[0]));
  }
}
