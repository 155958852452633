import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApplicationDetailsPageComponent } from '~/pages/user-provisioning/applications/application-details/applications-details.page';
import { ApplicationsPageComponent } from '~/pages/user-provisioning/applications/applications.page';
import { JsonDetailsPageComponent } from '~/pages/user-provisioning/json/json-details/json-details.page';
import { JsonPageComponent } from '~/pages/user-provisioning/json/json.page';
import { LdapDetailsPageComponent } from '~/pages/user-provisioning/ldap/ldap-details/ldap-details.page';
import { LdapStatusComponent } from '~/pages/user-provisioning/ldap/ldap-status/ldap-status.page';
import { LdapPageComponent } from '~/pages/user-provisioning/ldap/ldap.page';
import { UserProvisioningPageComponent } from '~/pages/user-provisioning/user-provisioning.page';
import { RightGuard } from '~/shared/guards/right.guard';
import { SharedModule } from '~/shared/shared.module';
import { UserRights } from '../../../../../shared/models';
import { CloneUsersPageComponent } from './clone-users/clone-users.page';

@NgModule({
  declarations: [
    UserProvisioningPageComponent,
    LdapPageComponent,
    LdapDetailsPageComponent,
    LdapStatusComponent,
    JsonPageComponent,
    JsonDetailsPageComponent,
    ApplicationsPageComponent,
    ApplicationDetailsPageComponent,
    CloneUsersPageComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'provisioning',
        component: UserProvisioningPageComponent,
        canActivate: [RightGuard],
        data: {
          right: UserRights.TabProvisioning,
          title: 'Provisioning',
        },
        children: [
          {
            path: 'ldap',
            component: LdapPageComponent,
            data: {
              title: 'LDAP',
            },
          },
          {
            path: 'ldap/:id',
            component: LdapDetailsPageComponent,
            data: {
              title: 'LDAP',
            },
          },
          {
            path: 'json',
            component: JsonPageComponent,
            data: {
              title: 'CSV Upload',
            },
          },
          {
            path: 'json/:id',
            component: JsonDetailsPageComponent,
            data: {
              title: 'CSV Upload',
            },
          },
          {
            path: 'clone-users',
            component: CloneUsersPageComponent,
            data: {
              title: 'Clone users',
            },
          },
          {
            path: 'applications',
            data: {
              title: 'Applications',
            },
            children: [
              {
                path: '',
                component: ApplicationsPageComponent,
                data: {
                  title: '',
                },
              },
              {
                path: ':id',
                component: ApplicationDetailsPageComponent,
                data: {
                  title: '{{application.value.name}}',
                },
              },
            ],
          },
          {
            path: '',
            redirectTo: 'ldap',
            pathMatch: 'full',
          },
          {
            path: '**',
            redirectTo: 'ldap',
          },
        ],
      },
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserProvisioningModule {}
