import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ObservableCache } from '~/utils/observable-cache';
import { IAdminUser } from '../../../../shared/models';

@Injectable()
export class AdminService {
  private cache: ObservableCache = new ObservableCache();

  constructor(private httpClient: HttpClient) {}

  getAdmins() {
    return this.cache.getObservable('admins', this.httpClient.get<IAdminUser[]>('/admin/users'));
  }

  searchAdmins(term: string) {
    return this.getAdmins().pipe(
      map(admins =>
        admins.filter(
          a =>
            (a.firstName && a.firstName.toLowerCase().includes(term.toLowerCase())) ||
            (a.lastName && a.lastName.toLowerCase().includes(term.toLowerCase()))
        )
      )
    );
  }
}
