import {
  IBaseCreateOrUpdateExtenderRequestV1,
  IExtenderResponseV1,
  IUpdateBrivoExtenderRequestV1,
  IUpdateClientExtenderRequestV1,
  IUpdateGeneaExtenderRequestV1,
  IUpdateOpenPathExtenderRequestV1,
} from '~/store/extender/extender.models';
import { extenderReducer } from '~/store/extender/extender.reducer';

export class ExtenderDetailsState {
  extender: IExtenderResponseV1;

  description: string = '';
  type: string;

  alertStatus: boolean;
  technicalContacts: string;

  brivoApiKey: string | null;

  openpathEmail: string | null;
  openpathPassword: string | null;

  geneaApiKey: string | null;

  initialize(extender: IExtenderResponseV1) {
    this.extender = extender;
    this.description = extender.description;
    this.type = extender.type;
    this.alertStatus = extender.alertStatus;
    this.technicalContacts = extender.technicalContacts;

    if (extender.type == 'brivo') {
      //brivo specific assignments
      this.brivoApiKey = null;
    }

    if (extender.type == 'openpath') {
      //openpath specific assignments
      this.openpathEmail = null;
      this.openpathPassword = null;
    }

    if (extender.type == 'genea') {
      this.geneaApiKey = null;
    }
  }

  updateRequest(): IBaseCreateOrUpdateExtenderRequestV1 {
    const request: IBaseCreateOrUpdateExtenderRequestV1 = {
      description: this.description,
      type: this.type,
      alertStatus: this.alertStatus,
      technicalContacts: this.technicalContacts,
    };

    switch (request.type) {
      case 'brivo':
        const brivoRequest: IUpdateBrivoExtenderRequestV1 = {
          ...request,
          apiKey: this.brivoApiKey,
        };
        return brivoRequest;
      case 'openpath':
        const openpathRequest: IUpdateOpenPathExtenderRequestV1 = {
          ...request,
          email: this.openpathEmail,
          password: this.openpathPassword,
        };
        return openpathRequest;
      case 'genea':
        const geneaRequest: IUpdateGeneaExtenderRequestV1 = {
          ...request,
          apiKey: this.geneaApiKey,
        };
        return geneaRequest;
      default:
        const clientRequest: IUpdateClientExtenderRequestV1 = {
          ...request,
        };
        return clientRequest;
    }
  }

  canSubmit(): boolean {
    return (
      this.description !== '' &&
      this.description.length >= 5 &&
      this.technicalContacts !== '' &&
      this.technicalContacts.length <= 300
    );
  }
}
