import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISortableFilter } from '~/models/filters';
import { IOption } from '~/shared/components/input/radio/radio.component';

@Component({
  selector: 'pxc-sortable-table',
  templateUrl: './sortable-table.html',
})
export class SortableTableComponent {
  @Input() filter: ISortableFilter;
  @Input() columns: IOption[];
  @Output() onFilterChange: EventEmitter<ISortableFilter> = new EventEmitter();

  applySort(column: IOption) {
    if (!column.value) {
      return;
    }

    if (this.filter.sortBy === column.value) {
      this.filter.sort = this.filter.sort === 'asc' ? 'desc' : 'asc';
    } else {
      this.filter.sortBy = column.value;
      this.filter.sort = 'desc';
    }
    this.onFilterChange.emit(this.filter);
  }
}
