import { Injectable } from '@angular/core';
import { combineLatest as observableCombineLatest, of as observableOf } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CompaniesService } from '~/services/companies.service';
import { UserService } from '~/services/users.service';

@Injectable()
export class SearchService {
  constructor(private Companies: CompaniesService, private Users: UserService) {}

  search(term: string) {
    return term
      ? observableCombineLatest(
          this.Companies.getCompanies({
            q: term,
          }).pipe(
            startWith({
              companies: [],
              limit: 0,
              total: 0,
            })
          ),
          this.Users.getUsers({
            q: term,
          }).pipe(startWith([]))
        ).pipe(
          map(([companies, users]) => ({
            companies: companies.companies,
            users: users,
          }))
        )
      : observableOf(null);
  }
}
