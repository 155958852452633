<div>
  <div *ngIf="errors.length > 0">
    <div class="u-color-danger">
      <h3>We encountered the following errors while trying to update the emails</h3>
      <table class="c-table mt-4">
        <thead>
          <tr>
            <th>Email</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of errors">
            <td>{{ item.key }}</td>
            <td>{{ item.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="flex justify-content-end align-items-center">
    <label class="c-label u-margin-right-xs">{{usersToBeUpdated}} emails to be updated</label>
    <pxc-button (click)="toggleUpdateEmailsModal()" [loading]="false">Update emails</pxc-button>
  </div>
  <div class="u-margin-bottom-xs">
    <div class="flex center u-margin-top-xs" *ngFor="let user of users">
      <input
        class="c-input c-input--s"
        [(ngModel)]="user.originalEmail"
        (input)="onInput(user, $event)"
        type="text"
        placeholder="originalEmail@originalDomain.com"
      />
      <input
        class="c-input c-input--s u-margin-left-s"
        [(ngModel)]="user.newEmail"
        type="text"
        placeholder="newEmail@newDomain.com"
      />
      <hl-icon class="u-margin-left-s" icon="add-circle-24" (click)="addEmptyRow()"></hl-icon>
    </div>
  </div>

  <hl-modal
    *ngIf="updateEmailsModal.open"
    class="hl-modal"
    [isOpen]="true"
    [heading]="'Update Emails'"
    (close)="toggleUpdateEmailsModal()"
  >
    <div>
      <div class="pxc-settings__subheading u-margin-bottom-gutter">
        Are you sure you want to update the emails of {{usersToBeUpdated}} users?
      </div>
      <footer class="hl-modal__footer u-margin-top-s">
        <div class="c-buttons-group" style="margin-top: -10px;">
          <hl-button size="small" type="primary" (click)="updateEmails()" [loading]="isUpdateLoading$ | async"
            >Update</hl-button
          >
          <hl-button size="small" type="secondary" (click)="toggleUpdateEmailsModal()">Close</hl-button>
        </div>
      </footer>
    </div>
  </hl-modal>
</div>
