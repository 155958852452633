import { Component } from '@angular/core';
import { IOption } from '~/shared/components/input/radio/radio.component';

@Component({
  templateUrl: './fastpace.component.html',
})
export class FastpaceComponent {
  tabs: IOption[] = [
    { label: 'Extenders', value: 'extenders' },
    { label: 'Companies', value: 'companies' },
  ];
}
