import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-obj-dropdown',
  templateUrl: './dropdown.html',
  styleUrls: ['./dropdown.scss'],
  providers: controlValueAccessor(ObjectDropdownComponent),
})
export class ObjectDropdownComponent implements ControlValueAccessor, OnInit {
  @Input() options: any[];
  @Input() label: string = 'name';
  @Input() key: string = 'id';
  @Input() value: string | null = null;
  @Input() allowNull: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() loading: Observable<boolean>;

  option;

  private onChangeInternal: (any) => void;
  internalLoading = false;

  constructor(private elem: ElementRef, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.loading) {
      this.loading.subscribe(loading => (this.internalLoading = loading));
    }
  }

  onChange(newValue:any) {
    this.onChangeInternal(this.option)
  }

  writeValue(option): void {
    if(option && option[this.key] !== undefined){
      this.option = _.find(this.options, o => o[this.key] === option[this.key]);
      this.onChangeInternal(this.option);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeInternal = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}
}
