import { Injectable } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { DeliveryService } from './deliveries.service';
import { ICheckinMessage, IDeliveryMessage, PubNubService } from './pubnub.service';
import { VisitService } from './visits.service';

@Injectable()
export class MapService {
  constructor(private pubnub: PubNubService, private visits: VisitService, private delivery: DeliveryService) {}

  getCheckinObservable$() {
    return this.pubnub.getMapChannelObservable$().pipe(
      map(data => data.message),
      filter(msg => msg.type === 'VisitCheckedInEvent'),
      switchMap((msg: ICheckinMessage) => {
        const [date, meetingId, visitorId] = msg.visitId.split('/');
        return this.visits.getVisit(msg.companyId, date, meetingId, visitorId);
      })
    );
  }

  getDeliveryObservable$() {
    return this.pubnub.getMapChannelObservable$().pipe(
      map(data => data.message),
      filter(msg => msg.type === 'DeliveryCreatedEvent'),
      switchMap((msg: IDeliveryMessage) => {
        return this.delivery.getDelivery(msg.companyId, msg.deliveryId);
      })
    );
  }
}
