import { Component } from '@angular/core';
import { IOption } from '~/shared/components/input/radio/radio.component';

@Component({
  selector: 'pxc-utilities',
  templateUrl: './utilities.html',
})
export class UtilitiesPageComponent {
  tabs: IOption[] = [
    { label: 'Converter', value: 'convert' },
    { label: 'Batch Email Update', value: 'batch-email-update' },
    { label: 'Update Home Location', value: 'update-home-location' },
  ];
}
