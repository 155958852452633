import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ILanguage } from '@proxyclick/data-model';
import * as _ from 'lodash';
import { IAppState } from '~/store/app-state';
import { controlValueAccessor } from '~/utils/utils';

@Component({
  selector: 'pxc-languages-dropdown',
  templateUrl: './languages-dropdown.html',
  providers: controlValueAccessor(LanguagesDropDownComponent),
})
export class LanguagesDropDownComponent implements ControlValueAccessor, OnInit {
  languages$ = this.store.select('languages', 'value');
  language: ILanguage;
  languages: ILanguage[];
  onChange: (value) => void;

  @Input()
  valueAsCode = false;

  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.languages$.subscribe(languages => {
      this.languages = languages;
    });
  }

  writeValue(language: ILanguage | string): void {
    if (this.valueAsCode) {
      this.language = _.find(this.languages, l => l.code === language);
      this.valueAsCode = true;
    } else {
      this.language = language as ILanguage;
    }
  }

  onChangeInternal(language: ILanguage) {
    this.onChange(this.valueAsCode ? language.code : language);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    return;
  }
  setDisabledState?(isDisabled: boolean): void {
    return;
  }
}
