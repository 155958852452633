<div class="c-card__section">
  <h3 *ngIf="loading">Setting up</h3>
  <hl-spinner *ngIf="loading"></hl-spinner>

  <h3 *ngIf="errors">Errors during setup</h3>
  <div class="u-color-danger" *ngIf="errors">
    <h4>{{ errors.title }}</h4>
    <dl>
      <ng-container *ngFor="let item of errors.errors | keyvalue">
        <dt>{{ item.key }}</dt>
        <dt>
          <ul>
            <li *ngFor="let errorItem of item.value">
              {{ errorItem }}
            </li>
          </ul>
        </dt>
      </ng-container>
    </dl>
    Trace id <code>{{ errors.traceId }}</code>
  </div>

  <div *ngIf="!loading && success">
    <h3 class="u-color-success">Success, access control is setup</h3>
    <button
      type="submit"
      routerLink="/fastpace/companies/{{ setupState.company.id }}"
      class="c-button c-button--primary c-button--s u-margin-top-s"
    >
      View company settings&nbsp;
    </button>
  </div>
</div>
