import { of as observableOf, Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppPartnersService } from '~/services/app-partners.service';
import { ConfigService } from '~/services/config.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';

const defaultPartner = {
  name: '',
  displayName: '',
  alias: '',
  language: { code: 'en', name: 'English', displayName: 'English' },
};

@Component({
  selector: 'pxc-create-app-partner',
  templateUrl: './create-app-partner.html',
})
export class CreateAppPartnerPageComponent implements OnInit {
  partner = defaultPartner;
  baseUrl;
  breadcrumbs$ = observableOf([
    { label: 'Partners', url: '/partners' },
    { label: 'New partner', url: `/partners/create` },
  ]);

  constructor(
    private Partners: AppPartnersService,
    private Config: ConfigService,
    private Notifications: NotificationsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.baseUrl = this.Config.getConfig().app.baseUrl;
  }

  create(partner) {
    const data = {
      name: partner.name,
      displayName: partner.displayName,
      alias: partner.alias,
      language: partner.language.code,
    };
    return this.Partners.createPartner(data).pipe(
      tap(() => this.Notifications.success('Partner created')),
      tap(() => this.router.navigate(['/partners']))
    );
  }
}
