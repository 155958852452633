import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';
import { AuthService } from '~/services/auth.service';
import { NotificationsService } from '~/shared/services/notifications/notifications.service';

// Those API calls should not produce an error message if they fail
const exceptions = [
  /^\/api\/app\/.*\/profile$/,
  /^\/api\/admin\/user\/(US-.*)$/,
  /^\/api\/app\/.*\/vm\/kiosk\/partner$/,
  /^\/api\/app\/(CO-.+)\/integrations\/user-provisioning$/,
  /^\/api\/app\/(CO-.+)\/users\/saml$/,
  /^\/authenticate/,
  /^\/sso/,
  /^\/marketplace\/api\/categories/,
  /^\/marketplace\/api\/regions/,
  /^\/set-password$/,
  /^\/fastpace\//,
  /^\/api\/app\/(CO-.+)\/billing\/subscription$/,
  /^\/api\/app\/(CO-.+)\/billing\/details$/,
  /^\/api\/private\/organisations\/(O-.+)\users/,
  /\\/,
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private notifications: NotificationsService, private Auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(req).pipe(
      tap(
        () => {},
        err => {
          if (
            err &&
            err.error &&
            err.error.error &&
            (err.error.error.type === 'EXPIRED_AUTH' || err.error.error.type === 'INVALID_AUTH')
          ) {
            this.notifications.error('Your access has expired. Please login again');
            this.Auth.logout();
          } else if (!_.some(exceptions, (exception: RegExp) => exception.exec(req.url))) {
            this.notifications.error(this.getErrorMessage(err));
          }
        }
      )
    );
  }

  private getErrorMessage(err) {
    return (
      (err.message && err.error && err.error.message) || (err && err.error && err.error.error && err.error.error.type)
    );
  }
}

function subscriptionNotFoundError(err: HttpErrorResponse) {
  return err && err.status === 404 && err.error && err.error.type === 'SubscriptionNotFoundException';
}
