import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { mapTo, map, mergeMap, switchMap, catchError, filter, take, concatAll, tap, bufferCount } from 'rxjs/operators';
import { OrganisationsService } from '~/services/organisations.service';
import { UserService } from '~/services/users.service';
import { generateAsyncEffect, generateAsyncSaveEffect, PayloadAction } from '~/utils/async-generator';
import { IAppState } from '../app-state';
import {
  OrganisationActions,
  OrganisationCompaniesActions,
  OrganisationCompaniesAddActions,
  OrganisationCompaniesDeleteActions,
  OrganisationDomainsActions,
  OrganisationDomainsAddActions,
  OrganisationDomainsDeleteActions,
  OrganisationDomainsUpdateActions,
  OrganisationLinkUsersAction,
  OrganisationPlatformTenantActions,
  OrganisationPlatformTenantAddActions,
  OrganisationUnlinkUserAction,
  OrganisationUsersActions,
  OrganisationPlatformTenantUserSyncActions,
} from './organisation.actions';

@Injectable()
export class OrganisationEffects {
  constructor(
    private actions$: Actions<PayloadAction>,
    private store: Store<IAppState>,
    private Organisations: OrganisationsService,
    private Users: UserService
  ) {}

  load$ = createEffect(() =>
    generateAsyncEffect(this.actions$, OrganisationActions, id => this.Organisations.getOrganisation(id))
  );

  loadUsers$ = createEffect(() =>
    generateAsyncEffect(this.actions$, OrganisationUsersActions, filterData =>
      this.Users.getUsersForOrganisation(filterData)
    )
  );

  unlinkUser$ = createEffect(() =>
    generateAsyncEffect(this.actions$, OrganisationUnlinkUserAction, data => this.Users.unlinkFromOrganisation(data))
  );

  unlinkUserDone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationUnlinkUserAction.Loaded),
      mergeMap(action => [
        OrganisationUsersActions.Load({
          organisationId: action.payload.organisationId,
          withMetaData: true,
          showAll: true,
          page: 1,
          pageSize: 100,
        }),
      ])
    )
  );

  linkUsers$ = createEffect(() =>
    generateAsyncSaveEffect(this.actions$, OrganisationLinkUsersAction, ({ organisationId, userIds }) =>
      from(userIds.map(userId => this.Organisations.linkUserToOrganisation(organisationId, userId))).pipe(
        concatAll(),
        bufferCount(userIds),
        mapTo({ organisationId, userIds })
      )
    )
  );

  linkUsersReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationLinkUsersAction.Saved),
      map(action =>
        OrganisationUsersActions.Load({
          organisationId: action.payload.organisationId,
          withMetaData: true,
          showAll: true,
          page: 1,
          pageSize: 100,
        })
      )
    )
  );

  loadDomains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationDomainsActions.Load.type),
      switchMap(() => {
        return this.store
          .select(s => s.organisation.organisation.value)
          .pipe(
            filter(o => !!o),
            take(1),
            switchMap(organisation =>
              this.Organisations.getDomainsForOrganisation(organisation.id).pipe(
                map(value => OrganisationDomainsActions.Loaded(value)),
                catchError(err => of(OrganisationDomainsActions.Error(err)))
              )
            )
          );
      })
    )
  );

  updateDomain$ = createEffect(() =>
    generateAsyncSaveEffect(this.actions$, OrganisationDomainsUpdateActions, ({ organisationId, ...domain }) =>
      this.Organisations.updateOrganisationDomain(organisationId, domain).pipe(mapTo(domain))
    )
  );

  deleteDomain$ = createEffect(() =>
    generateAsyncSaveEffect(this.actions$, OrganisationDomainsDeleteActions, ({ organisationId, domain }) =>
      this.Organisations.deleteOrganisationDomain(organisationId, domain).pipe(mapTo({ organisationId, domain }))
    )
  );

  addDomain$ = createEffect(() =>
    generateAsyncSaveEffect(this.actions$, OrganisationDomainsAddActions, ({ organisationId, domains }) =>
      from(
        domains.map(domain =>
          this.Organisations.addOrganisationDomain(organisationId, {
            domain,
            scimEnabled: true,
            samlEnabled: true,
            verified: true,
          })
        )
      ).pipe(concatAll(), bufferCount(domains.length))
    )
  );

  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationCompaniesActions.Load),
      switchMap(() => {
        return this.store
          .select(s => s.organisation.organisation.value)
          .pipe(
            filter(o => !!o),
            take(1),
            switchMap(organisation =>
              this.Organisations.getCompaniesForOrganisation(organisation.id).pipe(
                map(value => OrganisationCompaniesActions.Loaded(value)),
                catchError(err => of(OrganisationCompaniesActions.Error(err)))
              )
            )
          );
      })
    )
  );

  deleteCompany$ = createEffect(() =>
    generateAsyncSaveEffect(this.actions$, OrganisationCompaniesDeleteActions, ({ organisationId, companyId }) =>
      this.Organisations.unlinkCompany(organisationId, companyId)
    )
  );

  addCompanies$ = createEffect(() =>
    generateAsyncSaveEffect(this.actions$, OrganisationCompaniesAddActions, ({ organisationId, companyIds }) =>
      from(companyIds.map(companyId => this.Organisations.addCompany(organisationId, companyId))).pipe(
        concatAll(),
        bufferCount(companyIds),
        mapTo(null)
      )
    )
  );

  addOrDeleteCompanyReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationCompaniesDeleteActions.Saved, OrganisationCompaniesAddActions.Saved),
      mapTo(OrganisationCompaniesActions.Load())
    )
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.Load.type),
      mergeMap(action => [
        OrganisationUsersActions.Load({
          organisationId: action.payload,
          withMetaData: true,
          showAll: true,
          page: 1,
          pageSize: 100,
        }),
      ])
    )
  );

  loadPlatformTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrganisationPlatformTenantActions.Load,
        OrganisationPlatformTenantAddActions.Saved,
        OrganisationPlatformTenantUserSyncActions.Saved
      ),
      switchMap(() => {
        return this.store
          .select(s => s.organisation.organisation.value)
          .pipe(
            filter(o => !!o),
            take(1),
            switchMap(organisation =>
              this.Organisations.getPlatformTenantByOrganisationId(organisation.id).pipe(
                map(value => OrganisationPlatformTenantActions.Loaded(value)),
                catchError(err => of(OrganisationCompaniesActions.Error(err)))
              )
            )
          );
      })
    )
  );

  addPlatformTenant$ = createEffect(() =>
    generateAsyncSaveEffect(
      this.actions$,
      OrganisationPlatformTenantAddActions,
      ({ organisationId, tenantId, locationMappings }) =>
        from(this.Organisations.addPlatformTenant(organisationId, { tenantId, locationMappings }))
    )
  );

  userSyncPlatformTenant$ = createEffect(() =>
    generateAsyncSaveEffect(this.actions$, OrganisationPlatformTenantUserSyncActions, ({ organisationId }) =>
      from(this.Organisations.userSyncPlatformTenant(organisationId))
    )
  );
}
