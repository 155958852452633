<div class="map-page">
  <div class="flex">
    <div class="map-container stats-container">
      <pxc-live-map></pxc-live-map>
    </div>
    <!-- KPI chart -->
    <div class="stats-container ml-4">
      <pxc-kpi-table></pxc-kpi-table>
    </div>
  </div>
  <div class="flex mt-4">
    <!-- PongUp leaderboard-->
    <div class="stats-container">
      <h2 class="mb-4">PongUp Leaderboard</h2>
      <pxc-pongup-leaderboard></pxc-pongup-leaderboard>
    </div>
    <!-- Checkin chart-->
    <div class="stats-container chart-container ml-4">
      <h2>Checkins last 24 hours</h2>
      <div class="chart-wrapper">
        <canvas class="chart" #checkinChart id="checkin-chart"></canvas>
      </div>
    </div>
    <!-- Country chart -->
    <div class="stats-container chart-container ml-4">
      <h2>Checkins per country last 24 hours</h2>
      <div class="chart-wrapper">
        <canvas class="chart" #countryChart id="country-chart"></canvas>
      </div>
    </div>
  </div>
</div>
