import { Injectable } from '@angular/core';

@Injectable()
export class ClipboardService {
  copyToClipboard(value: string) {
    try {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    } catch (err) {
      console.error('Error while copying to clipboard', err);
    }
  }
}
