import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'camelCase',
})
export class CamelCasePipe implements PipeTransform {
  transform(value: string) {
    return _.camelCase(value);
  }
}

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string) {
    return _.capitalize(value);
  }
}

@Pipe({
  name: 'contains',
})
export class ContainsPipe implements PipeTransform {
  transform(array: any[], text: string, field: string) {
    return array && array.filter(value => value[field] && value[field].toLowerCase().includes(text.toLowerCase()));
  }
}
