import { forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { _ } from '../libs';

export function controlValueAccessor(component) {
  return [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => component),
      multi: true,
    },
  ];
}

export function filterNull(input) {
  return _.pickBy(input, f => f !== undefined && f !== null);
}

export async function fetchAll<T>(fn:(page:number)=> Promise<T[]>) : Promise<T[]> {
  var page = 1;
  var result: T[] = [];
  var tempResult: T[] = [];
  do{
    tempResult = await fn(page);
    page ++;
    result = result.concat(tempResult);
  }while(tempResult.length > 0);

  return result;
}
