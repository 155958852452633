import { Component } from '@angular/core';
import { ListBaseComponent } from '~/shared/components/input/list/list-base.component';

@Component({
  selector: 'pxc-checkbox-list',
  templateUrl: './checkbox-list.html',
  styleUrls: ['./checkbox-list.scss'],
})
export class CheckboxListComponent extends ListBaseComponent {
  constructor() {
    super();
  }

  onUpdate() {
    this.onChange.emit(this.items.filter(c => c.checked));
  }
}
